import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/2/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」5分で対象者の条件・要件を理解する！',
    description: 'この記事では、初めて事業再構築補助金を申請する方へ向けて、申請で満たさなければいけない条件・要件について、解説しております。申請枠ごとの要件の概要も解説していますので、補助金が分からない方は、ぜひこの記事をご覧いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」5分で対象者の条件・要件を理解する！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            こんにちは。<strong>事業再構築補助金の申請サポートをしているトランク合同会社</strong>です。<br />
            本記事は、事業再構築補助金を申請したいけど、申請枠が決まらないという方へ各申請枠の要件、条件をまとめました。<br />
            <br />
            事業再構築補助金では、申請枠を1つ選択した上で申請するという流れですので、まだ、申請枠を決めていない方、申請枠の評価を行えていない方は、ぜひ参考にしていただければと思います。<br />
          </p>
          </>

          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業再構築補助金自体の対象事業者</span></li>
              <li><span>申請枠ごとの対象要件</span></li>
              <li><span>申請枠ごとの要件を確認する際の最短方法</span></li>
            </ul>
          </div>

          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">事業再構築補助金の申請の流れ</AnchorLink></li>
                <li><AnchorLink href="#2" offset="100">必須要件</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">申請枠ごとの要件</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>

          <>
          <div id='1' className="media_text_title">
            <h2>1.事業再構築補助金の申請の流れ</h2>
          </div>
          <p className="media_text_text">
            <br />
            申請枠ごとの要件を見ていく前に、「<strong>どのような手順で申請枠を決定するのが良いのか？</strong>」について、説明させていただきます。下記の方法は、あくまで当社内での意見ですが、ぜひ参考にしていただければと思います。<br />
            <br />
            まず、対象者要件は、以下の２つに分類されております。<br />
            <br />
            <strong>(１) 事業再構築補助金自体への申請要件</strong><br />
            事業再構築補助金を申請する全事業者が満たす必要のある要件です。<br />
            この要件を満たしていないと、申請枠に関らず事業再構築補助金への申請はできないため、最初に確認を行いましょう。<br />
            <br />
            <strong>(２) 申請枠ごとの要件</strong><br />
            事業再構築補助金の申請枠ごとに要件が異なるため、１つずつ確認する必要があります。<br />
            <br />
            <strong>本項目のまとめ</strong><br />
            対象者条件の確認方法は、(１) 事業再構築補助金自体への申請要件が適合しているか確認した上で、(２) 申請枠ごとの要件を確認する。という流れがおすすめです。<br />
            <br />
            なお、申請枠の選択は要件の合致状況のみではなく、<strong>補助率、過去の採択率も加味した上で決定した方が適切な選択ができる</strong>と思います。また、本記事では、(１) 事業再構築補助金自体への申請要件、(２) 申請枠ごとの要件の順番で説明します。<br />
          </p>
          </>
          
          <>
          <div id='2' className="media_text_title">
            <h2>2.必須要件</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、上記で説明させていただいた２つの分類の<strong>(１) 事業再構築補助金自体への申請要件</strong>について、説明させていただきます。<br />
            <br />
            なお、本要件は全て、公募開始日時点の会社状況によって判断する必要がございますので、申請を希望される事業者さまは、公募開始日まで遡（さかのぼ）って要件をご確認ください。<br />
            <br />
            では、具体的に要件について解説していきます。<br />
          </p>
          <h3 className='media_text_text_title'>本社を日本に有している</h3><br />
          <p className="media_text_text">
            <strong>日本国内に本社がなくてはいけない</strong>という要件のため、外資系や多国籍企業の事業者さまは、本社所在地のご確認をお願いいたします。<br />
          </p>
          <h3 className='media_text_text_title'>中小企業等であること</h3><br />
          <p className="media_text_text">
            <strong>中小企業等に含まれていなければいけない</strong>というものです。<br />
            <br />
            中小企業の定義は業界によって変化しますので、下記の表で自社の業界を探し、確認してみてください。なお、資本金と従業員数のどちらか一方を満たしていれば中小企業という定義になります。<br />
            <br />
            また、応募申請時点で確定している直近過去3年分の課税所得平均額が15億円/年を超えている場合、中堅企業となりますのでご注意ください。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>業種・組織形態</th><th className='media_table_0001_1_2'>資本金</th><th className='media_table_0001_1_3'>従業員（常勤）</th>
              </tr>
              <tr>
                <td><strong>製造業、建設業、運輸業</strong></td>
                <td>3億円以下</td>
                <td>300人以下</td>
              </tr>
              <tr>
                <td><strong>卸売業</strong></td>
                <td>1億円以下</td>
                <td>100人以下</td>
              </tr>
              <tr>
                <td><strong>サービス業</strong></td>
                <td>5,000万円以下</td>
                <td>100人以下</td>
              </tr>
              <tr>
                <td><strong>小売業</strong></td>
                <td>5,000万円以下</td>
                <td>50人以下</td>
              </tr>
              <tr>
                <td><strong>ゴム製品製造業</strong></td>
                <td>3億円以下</td>
                <td>900人以下</td>
              </tr>
              <tr>
                <td><strong>ソフトウエア業、または情報処理サービス業</strong></td>
                <td>3億円以下</td>
                <td>300人以下</td>
              </tr>
              <tr>
                <td><strong>旅館業</strong></td>
                <td>5,000万円以下</td>
                <td>200人以下</td>
              </tr>
              <tr>
                <td><strong>その他の業種</strong></td>
                <td>3億円以下</td>
                <td>300人以下</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3 className='media_text_text_title'>認定支援機関要件</h3><br />
          <p className="media_text_text">
            認定支援機関要件とは、<strong>国に中小企業支援の知識・経験がある者として認められた認定支援機関に事業計画書を確認してもらわなければいけない</strong>という要件です。<br />
            <br />
            認定支援機関とは、<strong>中小企業支援の知識・経験がある者として、国の認定を受けた機関であり、士業や商工会、金融機関が多く認可されています</strong>。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・認定支援機関と相談したうえで事業計画書を策定する。<br />
            ※認定経営革新等支援機関による確認書は、認定支援機関に相談したことを証明する書類ですので、必ず認定支援機関に作成をお願いしてください。<br />
            <br />
            認定支援機関要件は、下記の記事にて詳しく解説しておりますので、詳細が知りたい方はぜひご参照ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0009' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【どこに依頼する？】事業再構築補助金の認定支援機関要件を機関別に解説！
            </a><br />
            <br />
            おすすめの認定支援機関や認定支援機関別の採択率は、下記の記事でまとめて記載しておりますので、下記リンクよりご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0004' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【採択率はどれくらい？】事業再構築補助金の過去採択を徹底解剖！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>付加価値額要件</h3><br />
          <p className="media_text_text">
            付加価値額要件とは、<strong>付加価値額が上昇する事業計画を策定しなければいけない</strong>という要件です。<br />
            <br />
            付加価値額とは、補助金独自の考え方であり、以下の方法により算出できます。<br />
            <span>付加価値額=営業利益+減価償却費+人件費</span><br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・補助事業終了後3~5年で、付加価値額の年平均率を3.0%以上増加させること<br />
            ・補助事業終了後3~5年で、従業員1人当たりの付加価値額の年平均率3.0%以上増加させること<br />
            ※上記のどちらかを満たす事業計画を策定することが要件となります。<br />
            <br />
            上記の要件を満たした事業計画書を策定することが必要になりますので、認定支援機関と共にご確認をお願いいたします。<br />
          </p>
          <h3 className='media_text_text_title'>事業再構築要件</h3><br />
          <p className="media_text_text">
            事業再構築要件とは、<strong>新分野展開、事業転換、業種転換、業態転換又は事業再編のいずれかに該当する事業計画でなければいけない</strong>という要件です。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・新分野展開、事業転換、業種転換、業態転換又は事業再編のいずれかに該当する事業計画を策定すること<br />
            <br />
            <strong>参考記事</strong><br />
            上記５点の（新分野展開、事業転換、業種転換、業態転換又は事業再編）についての詳しい記事を別途策定させていただきましたので、ぜひご参照いただければと思います。<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0007' target="_blank" rel="nofollow noopener noreferrer">
              【類型・転換って何？】事業再構築補助金の再構築要件を、例を用いて解説！
            </a><br />
            <br />
          </p>
          <h3 className='media_text_text_title'>本項目のまとめ</h3><br />
          <p className="media_text_text">
            ここまで、５つの要件についてご説明させていただきましたが、ご理解いただけましたでしょうか。<br />
            こちらは、事業再構築補助金の必須要件となっておりますので、申請をされる方はお早めにご確認いただければと思います。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>事業再構築補助金に申請するための５つの要件</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>日本に本社を有していること</span></li>
              <li><span>中小企業であること</span></li>
              <li><span>認定支援機関と連携して事業計画を策定していること</span></li>
              <li><span>付加価値額が増加する事業計画を策定していること</span></li>
              <li><span>事業再構築要件に当てはまる事業計画を策定していること</span></li>
            </ul>
          </div>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.申請枠ごとの要件</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、<strong>(２) 申請枠ごとの要件</strong>についてご説明させていただきます。申請枠ごとに満たす要件が異なりますので、申請枠ごとの要件を一覧にしてご説明させていただきます。<br />
          </p>
          <h3 className='media_text_text_title'>申請枠と要件の一覧</h3><br />
          <p className="media_text_text">
            事業再構築補助金では、下記の要件が定められており、その要件が申請枠ごとに当てはめられているという形となっています。<br />
            <br />
            そこで、以下に<strong>申請枠ごとの要件を○×の表にまとめさせていただきました</strong>ので、選択されている申請枠にはどんな要件があるのかをご確認いただければと思います。<br />
            <br />
            また、各要件の具体的な内容や証明するために提出する書類につきましては、要件ごとに記事を記載しておりますので、項目の要件部分をクリックして記事をご確認していただけますと幸いです<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_4_1'>項目</th>
                <th className='media_table_0001_4_2'>成長枠</th>
                <th className='media_table_0001_4_2'>産業構造転換枠</th>
                <th className='media_table_0001_4_2'>グリーン枠</th>
                <th className='media_table_0001_4_2'>物価高騰対策・回復再生応援枠</th>
                <th className='media_table_0001_4_2'>最低賃金枠</th>
              </tr>
              <tr>
                <td><strong>事業再構築要件</strong></td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_o'>○</td>
              </tr>
              <tr>
                <td><strong>認定支援機関要件</strong></td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_o'>○</td>
              </tr>
              <tr>
                <td><strong>付加価値額要件</strong></td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_o'>○</td>
              </tr>
              <tr>
                <td><strong>給与総額増加要件</strong></td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_x'>×</td>
                <td className='media_table_4_x'>×</td>
              </tr>
              <tr>
                <td><strong>市場拡大要件</strong></td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_x'>×</td>
                <td className='media_table_4_x'>×</td>
                <td className='media_table_4_x'>×</td>
                <td className='media_table_4_x'>×</td>
              </tr>
              <tr>
                <td><strong>市場縮小要件</strong></td>
                <td className='media_table_4_x'>×</td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_x'>×</td>
                <td className='media_table_4_x'>×</td>
                <td className='media_table_4_x'>×</td>
              </tr>
              <tr>
                <td><strong>売上高等減少要件</strong></td>
                <td className='media_table_4_x'>×</td>
                <td className='media_table_4_x'>×</td>
                <td className='media_table_4_x'>×</td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_o'>○</td>
              </tr>
              <tr>
                <td><strong>最低賃金要件</strong></td>
                <td className='media_table_4_x'>×</td>
                <td className='media_table_4_x'>×</td>
                <td className='media_table_4_x'>×</td>
                <td className='media_table_4_o'>×</td>
                <td className='media_table_4_o'>○</td>
              </tr>
              <tr>
                <td><strong>グリーン成長要件</strong></td>
                <td className='media_table_4_x'>×</td>
                <td className='media_table_4_x'>×</td>
                <td className='media_table_4_o'>○</td>
                <td className='media_table_4_x'>×</td>
                <td className='media_table_4_x'>×</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3 className='media_text_text_title'>各要件の達成時期</h3><br />
          <p className="media_text_text">
            上記のように要件には様々な種類があり、それぞれに具体的な内容や証拠として提出する書類が定められていますが、要件には、現在までに達成されていないといけない事と今後達成する必要があることの2点があります。<br />
            <br />
            <strong>①申請時に達成していなければいけない要件=今から満たすことは難しい</strong><br />
            <strong>②補助事業実施後に達成する必要がある要件=今から満たすことが可能</strong><br />
            <br />
            具体的には、売上が減少していなければならない場合ですと、過去の実績を提出しなければいけないため、①に属しますが、今後、給料をあげることが要件であれば②に属す、といった具合に分類します。<br />
            <br />
            その上で、以下のような分類が出来ますので、①が要件として含まれる方は、早急に確認し、②が要件として含まれている方は、申請できる可能性が残ってますので、改めてご確認いただければと思います。<br />
            <br />
            <strong>①申請時に達成していなければいけない要件</strong><br />
            ・売上高等減少要件<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0008' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」売上高減少要件は、２つの期間を比較！
            </a><br />
            ・市場縮小要件<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0031' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」市場規模が縮小していれば市場縮小要件！
            </a><br />
            <br />
            <strong>②補助事業実施後に達成する必要がある要件=今から満たすことが可能</strong><br />
            ・事業再構築要件<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0007' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」事業再構築要件を５つの類型ごとに解説！
            </a><br />
            ・認定支援機関要件<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0009' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」認定支援機関要件は、最短１日で完結！
            </a><br />
            ・付加価値額要件<br />
            ・給与総額増加要件<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0033' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」給与総額増加要件は給与支給を年率2%増加
            </a><br />
            ・市場拡大要件<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0032' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」市場規模が拡大していれば市場拡大要件！
            </a><br />
            ・最低賃金要件<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0012' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」最低賃金要件は、最低賃金を45円/年
            </a><br />
            ・グリーン成長要件<br />
          </p>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>4.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、事業再構築補助金を申請したい事業者さまへ向けて、事業再構築補助金自体の申請要件と、各申請枠の要件について記事にさせていただきました。<br />
            <br />
            公募要領を各所に添付させていただきましたが、ご覧いただけば分かる通り難しい内容となっております。<br />
            そこで、もし、本格的に事業再構築補助金の導入を検討されていましたら、弊社含め補助金に詳しい事業者へご相談ください。<br />
            <br />
            ご相談は、画面右上の補助金に関する相談をするから問い合わせにて、お待ちしております。<br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;

