import DocumentMeta from 'react-document-meta';
import './styles.css';
import Nav from './../photo/nav/index.js';
import { useState, useEffect } from "react";
import firebase from "firebase/compat/app"
import shuffle from 'lodash/shuffle'

const Photo = () => {

  const meta = {
    title: '美容学生の作品撮り一覧サイト - memorii photo',
    description: '美容学生の作品撮りを手軽に確認でき、仲間から刺激を受けられるサイト。そして、作成者との繋がりをより深められるマッチング媒体。',
    canonical: 'https://trankllc.com',
    meta: {charset: 'utf-8',}
  };
  
  const [ posts, setPosts ] = useState([]);
  const [ profileMain, setProfileMain ] = useState([]);
  const [ profileSub, setProfileSub ] = useState([]);
  const [ scout, setScout ] = useState(false);
  
  useEffect(() => {
    firebase.firestore().collection("posts")
    .get().then(snapShot => {
      const tmp = [];
      snapShot.forEach(doc => tmp.push({...doc.data(), id: doc.id}));
      setPosts([...tmp]);
    })
  }, [])

  function image(photo) {
    setProfileMain(photo);

    firebase.firestore().collection("posts").where("post_uid", "==", photo.post_uid)
    .get().then(snapShot => {
      const tmp = [];
      snapShot.forEach(doc => tmp.push(doc.data().post_image_500));
      setProfileSub(shuffle(tmp.filter(function(item) {return item !== photo.post_image_500;})));
    });

    setScout(true);
  }

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {scout ?
      <div className='scout_info'>
        <div className='scout_info_box'>
          <div className='scout_info_box_left'>
            <img src={profileMain.post_image_500} alt='' />
            <div>
              <div>
                <h4>名前</h4>
                <h5>{profileMain.post_name === '' ? 'unnamed' : profileMain.post_name}</h5>
              </div>
              <div>
                <h4>学校名</h4>
                <h5>  </h5>
              </div>
              <div>
                <h4>instagram</h4>
                <h5><span>{profileMain.post_instagram}</span></h5>
              </div>
              <div>
                <h4>tiktok</h4>
                <h5><span>{profileMain.post_tiktok}</span></h5>
              </div>
            </div>
          </div>
          <div className='scout_info_box_line'></div>
          <div className='scout_info_box_right'>
            <h4>その他の作品</h4>
            <div>
              {profileSub[0] == null ? <div></div> : <img src={profileSub[0]} alt='' />}
              {profileSub[1] == null ? <div></div> : <img src={profileSub[1]} alt='' />}
            </div>
            <div>
              {profileSub[2] == null ? <div></div> : <img src={profileSub[2]} alt='' />}
              {profileSub[3] == null ? <div></div> : <img src={profileSub[3]} alt='' />}
            </div>
            <div>
              {profileSub[4] == null ? <div></div> : <img src={profileSub[4]} alt='' />}
              {profileSub[5] == null ? <div></div> : <img src={profileSub[5]} alt='' />}
            </div>
          </div>
        </div>
        <div onClick={() => setScout(!scout)} className='scout_register_background'></div>
      </div>
        : <div></div>
      }
      <div className='photolist_header'>
        <div className='photo_header'>
          <h5>美容学生のための作品撮りアプリ</h5>
          <h4>memorii photo</h4>
        </div>
        <div className='photo_list_title'>作品撮り</div>
      </div>
      <div className="scout_box">
        {posts.map((photo, index) =>
          <div className="scout_box_items" onClick={() => image(photo)}  key={index}>
            <img src={photo.post_image_500} alt="美容学生作品撮り" />
          </div>
        )}
      </div>
    </DocumentMeta>
    </>
  );
}

export default Photo;

// 9de1ccf0-c325-11ec-b6a8
// ac656230-ec35-11ec-9a69
// 127b66d0-8450-11ec-b9bb
