import './../topics.css';
import Image from "./../../../../image/icon.png";
import ImageTopic1 from "./../../../../image/media/business/1/top.png";
import ImageTopic2 from "./../../../../image/media/business/2/top.png";
import ImageTopic3 from "./../../../../image/media/business/3/top.png";
import ImageTopic4 from "./../../../../image/media/business/4/top.png";
import ImageTopic5 from "./../../../../image/media/business/5/top.png";
import ImageTopic6 from "./../../../../image/media/business/6/top.png";
import ImageTopic7 from "./../../../../image/media/business/7/top.png";
import ImageTopic8 from "./../../../../image/media/business/8/top.png";
import ImageTopic9 from "./../../../../image/media/business/9/top.png";
import ImageTopic10 from "./../../../../image/media/business/10/top.png";
import ImageTopic11 from "./../../../../image/media/business/11/top.png";
import ImageTopic12 from "./../../../../image/media/business/12/top.png";
import ImageTopic16 from "./../../../../image/media/business/16/top.png";
import ImageTopic17 from "./../../../../image/media/business/17/top.png";
import ImageTopic18 from "./../../../../image/media/business/18/top.png";
import ImageTopic19 from "./../../../../image/media/business/19/top.png";
import ImageTopic20 from "./../../../../image/media/business/20/top.png";
import ImageTopic21 from "./../../../../image/media/business/21/top.png";
import ImageTopic22 from "./../../../../image/media/business/22/top.png";
import ImageTopic23 from "./../../../../image/media/business/23/top.png";
import ImageTopic24 from "./../../../../image/media/business/24/top.png";

const BlogText0001 = () => {


  return (
    <>
    <div className='media_topic'>
      <div className='media_topic_title'>執筆者の紹介</div>
      <div className='media_topic_writer'>
        <div>
          <img src={Image} alt='' />
          <h5>トランク合同会社</h5>
        </div>
        <hr />
        <p>弊社は補助金申請の専門家として補助金申請をサポートしております。ご質問がございましたら、ご気軽にお問い合わせください。</p>
      </div>
      <div className='media_topic_title'>事業再構築補助金 おすすめの記事</div>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0001'>
        <div className='media_topic_content'>
          <img src={ImageTopic1} alt='' />
          <h5>「事業再構築補助金とは？」知識がなくても絶対に理解できる！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0002'>
        <div className='media_topic_content'>
          <img src={ImageTopic2} alt='' />
          <h5>「事業再構築補助金」5分で対象者の条件・要件を理解する</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0003'>
        <div className='media_topic_content'>
          <img src={ImageTopic3} alt='' />
          <h5>「事業再構築補助金」12個の分類を具体例つきで解説！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0004'>
        <div className='media_topic_content'>
          <img src={ImageTopic4} alt='' />
          <h5>「事業再構築補助金」申請枠・業種ごとに過去6回を徹底分析！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0005'>
        <div className='media_topic_content'>
          <img src={ImageTopic5} alt='' />
          <h5>「事業再構築補助金」提出書類の用意を３日で終わらせる方法！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0006'>
        <div className='media_topic_content'>
          <img src={ImageTopic6} alt='' />
          <h5>「事業再構築補助金」採択後の流れは、５ステップで完結！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0007'>
        <div className='media_topic_content'>
          <img src={ImageTopic7} alt='' />
          <h5>「事業再構築補助金」再構築要件を５つの類型ごとに解説！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0008'>
        <div className='media_topic_content'>
          <img src={ImageTopic8} alt='' />
          <h5>「事業再構築補助金」売上高減少要件は、２つの期間を比較！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0009'>
        <div className='media_topic_content'>
          <img src={ImageTopic9} alt='' />
          <h5>「事業再構築補助金」認定支援機関要件は、最短１日で完結！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0010'>
        <div className='media_topic_content'>
          <img src={ImageTopic10} alt='' />
          <h5>【完全初心者向け】事業再構築補助金の賃金引上要件をわかりやすく解説！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0011'>
        <div className='media_topic_content'>
          <img src={ImageTopic11} alt='' />
          <h5>【完全初心者向け】事業再構築補助金の回復・再生要件をわかりやすく解説！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0012'>
        <div className='media_topic_content'>
          <img src={ImageTopic12} alt='' />
          <h5>【完全初心者向け】事業再構築補助金の最低賃金要件をわかりやすく解説！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0016'>
        <div className='media_topic_content'>
          <img src={ImageTopic16} alt='' />
          <h5>「事業再構築補助金」通常枠は、３つの要件だけを確認する！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0017'>
        <div className='media_topic_content'>
          <img src={ImageTopic17} alt='' />
          <h5>「事業再構築補助金」大規模賃金引上枠は、５つの要件を確認！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0018'>
        <div className='media_topic_content'>
          <img src={ImageTopic18} alt='' />
          <h5>「事業再構築補助金」回復・再生応援枠は、４つの要件を確認！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0019'>
        <div className='media_topic_content'>
          <img src={ImageTopic19} alt='' />
          <h5>「事業再構築補助金」最低賃金枠は、４つの要件を確認！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0020'>
        <div className='media_topic_content'>
          <img src={ImageTopic20} alt='' />
          <h5>「事業再構築補助金」緊急対策枠は、３つの要件を確認！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0021'>
        <div className='media_topic_content'>
          <img src={ImageTopic21} alt='' />
          <h5>「事業再構築補助金」事業計画書の作成は、この４点を抑える！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0022'>
        <div className='media_topic_content'>
          <img src={ImageTopic22} alt='' />
          <h5>「事業再構築補助金」10分で取れる加点書類が2つある！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0023'>
        <div className='media_topic_content'>
          <img src={ImageTopic23} alt='' />
          <h5>「事業再構築補助金」交付申請の流れは、全部で５つ！</h5>
        </div>
      </a>
      <a className='media_all_nav_icon' href='https://trankllc.com/media/subsidy/business/0024'>
        <div className='media_topic_content'>
          <img src={ImageTopic24} alt='' />
          <h5>「事業再構築補助金」実績報告は、事前準備が1番大切！</h5>
        </div>
      </a>
    </div>
    </>
  );
}


export default BlogText0001;