import './styles.css';
import point1 from './../../../../../image/adbusiness/point1.png';
import point2 from './../../../../../image/adbusiness/point2.png';
import point3 from './../../../../../image/adbusiness/point3.png';

const Help = () => {
  
  return (
    <>
    <div className='AdBusiness_Strong_point'>
      <div>
        <h6 style={{color : '#F8D166'}}>01</h6>
        <img src={point1} alt='' />
        <h4>高い採択率</h4>
        <p>平均採択率に比べ30%ほど高い採択実績を誇っております。</p>
      </div>
      <div>
        <h6 style={{color : '#81CCE8'}}>02</h6>
        <img src={point2} alt='' />
        <h4>最短の申請フロー</h4>
        <p>お客さまに負担が無いよう簡略化したフローを確立しています。</p>
      </div>
      <div>
        <h6 style={{color : '#89C6C1'}}>03</h6>
        <img src={point3} alt='' />
        <h4>安心のサポート</h4>
        <p>お客さまに合わせたサポート体制を整えております。</p>
      </div>
    </div>
    </>
  );
}

export default Help;
