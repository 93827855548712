import React, { useState, useEffect } from "react";
import firebase from 'firebase/compat/app'
import './styles.css';
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore'

const Help = (props) => {

  // 最初の名前
  const [ firstName, setFirstName ] = useState('');
  
  // シートタイトル
  const [ sheetId, setSheetId ] = useState('シート');
  const [ sheetName, setSheetName ] = useState('シート');
  const [ sheetColor, setSheetColor ] = useState('FF7847');
  const [ sheetColumn, setSheetColumn ] = useState(1);
  const [ sheetBox, setSheetBox ] = useState(false);

  // チェクボックスOFF
  const [ row1Title, setRow1Title ] = useState('列1');
  const [ row2Title, setRow2Title ] = useState('列2');
  const [ row3Title, setRow3Title ] = useState('列3');
  const [ row4Title, setRow4Title ] = useState('列4');
  const [ row5Title, setRow5Title ] = useState('列5');
  const [ row6Title, setRow6Title ] = useState('列6');
  const [ row7Title, setRow7Title ] = useState('列7');
  const [ row8Title, setRow8Title ] = useState('列8');

  // 列１
  const [ row1Column, setRow1Column ] = useState(1);
  const [ row1CheckBox1, setRow1CheckBox1 ] = useState('');
  const [ row1CheckBox2, setRow1CheckBox2 ] = useState('');
  const [ row1CheckBox3, setRow1CheckBox3 ] = useState('');
  const [ row1CheckBox4, setRow1CheckBox4 ] = useState('');
  const [ row1CheckBox5, setRow1CheckBox5 ] = useState('');
  const [ row1CheckBox6, setRow1CheckBox6 ] = useState('');
  const [ row1CheckBox7, setRow1CheckBox7 ] = useState('');
  const [ row1CheckBox8, setRow1CheckBox8 ] = useState('');

  // 列２
  const [ row2Column, setRow2Column ] = useState(1);
  const [ row2CheckBox1, setRow2CheckBox1 ] = useState('');
  const [ row2CheckBox2, setRow2CheckBox2 ] = useState('');
  const [ row2CheckBox3, setRow2CheckBox3 ] = useState('');
  const [ row2CheckBox4, setRow2CheckBox4 ] = useState('');
  const [ row2CheckBox5, setRow2CheckBox5 ] = useState('');
  const [ row2CheckBox6, setRow2CheckBox6 ] = useState('');
  const [ row2CheckBox7, setRow2CheckBox7 ] = useState('');
  const [ row2CheckBox8, setRow2CheckBox8 ] = useState('');

  // 列３
  const [ row3Column, setRow3Column ] = useState(1);
  const [ row3CheckBox1, setRow3CheckBox1 ] = useState('');
  const [ row3CheckBox2, setRow3CheckBox2 ] = useState('');
  const [ row3CheckBox3, setRow3CheckBox3 ] = useState('');
  const [ row3CheckBox4, setRow3CheckBox4 ] = useState('');
  const [ row3CheckBox5, setRow3CheckBox5 ] = useState('');
  const [ row3CheckBox6, setRow3CheckBox6 ] = useState('');
  const [ row3CheckBox7, setRow3CheckBox7 ] = useState('');
  const [ row3CheckBox8, setRow3CheckBox8 ] = useState('');
  
  // 列４
  const [ row4Column, setRow4Column ] = useState(1);
  const [ row4CheckBox1, setRow4CheckBox1 ] = useState('');
  const [ row4CheckBox2, setRow4CheckBox2 ] = useState('');
  const [ row4CheckBox3, setRow4CheckBox3 ] = useState('');
  const [ row4CheckBox4, setRow4CheckBox4 ] = useState('');
  const [ row4CheckBox5, setRow4CheckBox5 ] = useState('');
  const [ row4CheckBox6, setRow4CheckBox6 ] = useState('');
  const [ row4CheckBox7, setRow4CheckBox7 ] = useState('');
  const [ row4CheckBox8, setRow4CheckBox8 ] = useState('');
  
  // 列５
  const [ row5Column, setRow5Column ] = useState(1);
  const [ row5CheckBox1, setRow5CheckBox1 ] = useState('');
  const [ row5CheckBox2, setRow5CheckBox2 ] = useState('');
  const [ row5CheckBox3, setRow5CheckBox3 ] = useState('');
  const [ row5CheckBox4, setRow5CheckBox4 ] = useState('');
  const [ row5CheckBox5, setRow5CheckBox5 ] = useState('');
  const [ row5CheckBox6, setRow5CheckBox6 ] = useState('');
  const [ row5CheckBox7, setRow5CheckBox7 ] = useState('');
  const [ row5CheckBox8, setRow5CheckBox8 ] = useState('');
  
  // 列５
  const [ row6Column, setRow6Column ] = useState(1);
  const [ row6CheckBox1, setRow6CheckBox1 ] = useState('');
  const [ row6CheckBox2, setRow6CheckBox2 ] = useState('');
  const [ row6CheckBox3, setRow6CheckBox3 ] = useState('');
  const [ row6CheckBox4, setRow6CheckBox4 ] = useState('');
  const [ row6CheckBox5, setRow6CheckBox5 ] = useState('');
  const [ row6CheckBox6, setRow6CheckBox6 ] = useState('');
  const [ row6CheckBox7, setRow6CheckBox7 ] = useState('');
  const [ row6CheckBox8, setRow6CheckBox8 ] = useState('');
  
  // 列７
  const [ row7Column, setRow7Column ] = useState(1);
  const [ row7CheckBox1, setRow7CheckBox1 ] = useState('');
  const [ row7CheckBox2, setRow7CheckBox2 ] = useState('');
  const [ row7CheckBox3, setRow7CheckBox3 ] = useState('');
  const [ row7CheckBox4, setRow7CheckBox4 ] = useState('');
  const [ row7CheckBox5, setRow7CheckBox5 ] = useState('');
  const [ row7CheckBox6, setRow7CheckBox6 ] = useState('');
  const [ row7CheckBox7, setRow7CheckBox7 ] = useState('');
  const [ row7CheckBox8, setRow7CheckBox8 ] = useState('');
  
  // 列７
  const [ row8Column, setRow8Column ] = useState(1);
  const [ row8CheckBox1, setRow8CheckBox1 ] = useState('');
  const [ row8CheckBox2, setRow8CheckBox2 ] = useState('');
  const [ row8CheckBox3, setRow8CheckBox3 ] = useState('');
  const [ row8CheckBox4, setRow8CheckBox4 ] = useState('');
  const [ row8CheckBox5, setRow8CheckBox5 ] = useState('');
  const [ row8CheckBox6, setRow8CheckBox6 ] = useState('');
  const [ row8CheckBox7, setRow8CheckBox7 ] = useState('');
  const [ row8CheckBox8, setRow8CheckBox8 ] = useState('');

  useEffect(() => {

    if (props.nav !== undefined) {
      setFirstName(props.nav.name);

      setSheetName(props.nav.name);
      setSheetColor(props.nav.color);
      setSheetColumn(props.nav.count);
      setSheetBox(props.nav.checkBox);
      setSheetId(props.nav.id);
  
      setRow1Title(props.nav.row0[0]);
      setRow2Title(props.nav.row0[1]);
      setRow3Title(props.nav.row0[2]);
      setRow4Title(props.nav.row0[3]);
      setRow5Title(props.nav.row0[4]);
      setRow6Title(props.nav.row0[5]);
      setRow7Title(props.nav.row0[6]);
      setRow8Title(props.nav.row0[7]);
  
      setRow1Column(props.nav.column[0]);
      setRow2Column(props.nav.column[1]);
      setRow3Column(props.nav.column[2]);
      setRow4Column(props.nav.column[3]);
      setRow5Column(props.nav.column[4]);
      setRow6Column(props.nav.column[5]);
      setRow7Column(props.nav.column[6]);
      setRow8Column(props.nav.column[7]);
  
      setRow1CheckBox1(props.nav.column0[0]);
      setRow1CheckBox2(props.nav.column0[1]);
      setRow1CheckBox3(props.nav.column0[2]);
      setRow1CheckBox4(props.nav.column0[3]);
      setRow1CheckBox5(props.nav.column0[4]);
      setRow1CheckBox6(props.nav.column0[5]);
      setRow1CheckBox7(props.nav.column0[6]);
      setRow1CheckBox8(props.nav.column0[7]);
  
      setRow2CheckBox1(props.nav.column1[0]);
      setRow2CheckBox2(props.nav.column1[1]);
      setRow2CheckBox3(props.nav.column1[2]);
      setRow2CheckBox4(props.nav.column1[3]);
      setRow2CheckBox5(props.nav.column1[4]);
      setRow2CheckBox6(props.nav.column1[5]);
      setRow2CheckBox7(props.nav.column1[6]);
      setRow2CheckBox8(props.nav.column1[7]);
  
      setRow3CheckBox1(props.nav.column2[0]);
      setRow3CheckBox2(props.nav.column2[1]);
      setRow3CheckBox3(props.nav.column2[2]);
      setRow3CheckBox4(props.nav.column2[3]);
      setRow3CheckBox5(props.nav.column2[4]);
      setRow3CheckBox6(props.nav.column2[5]);
      setRow3CheckBox7(props.nav.column2[6]);
      setRow3CheckBox8(props.nav.column2[7]);
  
      setRow4CheckBox1(props.nav.column3[0]);
      setRow4CheckBox2(props.nav.column3[1]);
      setRow4CheckBox3(props.nav.column3[2]);
      setRow4CheckBox4(props.nav.column3[3]);
      setRow4CheckBox5(props.nav.column3[4]);
      setRow4CheckBox6(props.nav.column3[5]);
      setRow4CheckBox7(props.nav.column3[6]);
      setRow4CheckBox8(props.nav.column3[7]);
  
      setRow5CheckBox1(props.nav.column4[0]);
      setRow5CheckBox2(props.nav.column4[1]);
      setRow5CheckBox3(props.nav.column4[2]);
      setRow5CheckBox4(props.nav.column4[3]);
      setRow5CheckBox5(props.nav.column4[4]);
      setRow5CheckBox6(props.nav.column4[5]);
      setRow5CheckBox7(props.nav.column4[6]);
      setRow5CheckBox8(props.nav.column4[7]);
  
      setRow6CheckBox1(props.nav.column5[0]);
      setRow6CheckBox2(props.nav.column5[1]);
      setRow6CheckBox3(props.nav.column5[2]);
      setRow6CheckBox4(props.nav.column5[3]);
      setRow6CheckBox5(props.nav.column5[4]);
      setRow6CheckBox6(props.nav.column5[5]);
      setRow6CheckBox7(props.nav.column5[6]);
      setRow6CheckBox8(props.nav.column5[7]);
  
      setRow7CheckBox1(props.nav.column6[0]);
      setRow7CheckBox2(props.nav.column6[1]);
      setRow7CheckBox3(props.nav.column6[2]);
      setRow7CheckBox4(props.nav.column6[3]);
      setRow7CheckBox5(props.nav.column6[4]);
      setRow7CheckBox6(props.nav.column6[5]);
      setRow7CheckBox7(props.nav.column6[6]);
      setRow7CheckBox8(props.nav.column6[7]);
  
      setRow8CheckBox1(props.nav.column7[0]);
      setRow8CheckBox2(props.nav.column7[1]);
      setRow8CheckBox3(props.nav.column7[2]);
      setRow8CheckBox4(props.nav.column7[3]);
      setRow8CheckBox5(props.nav.column7[4]);
      setRow8CheckBox6(props.nav.column7[5]);
      setRow8CheckBox7(props.nav.column7[6]);
      setRow8CheckBox8(props.nav.column7[7]);
    };
  }, [props.nav]);

  function save() {
    const docRef = collection(firebase.firestore(), 'tasks')
    const queryRef = query(docRef, where('tag', '==', firstName))
    const queryRefs = query(queryRef, orderBy('order'))
    const unsub = onSnapshot(queryRefs, snapshot => {
      snapshot.docs.forEach(doc => {
        firebase.firestore().collection('tasks').doc(doc.id).update({ tag: sheetName,})
      })
      return () => unsub();
    })

    firebase.firestore().collection('tasks').doc(sheetId)
    .update({
      name : sheetName,
      color : sheetColor,
      checkBox : sheetBox === 'true' || sheetBox === true ? true : false,
      order : 100,
      tag : 'nav',
      count : sheetColumn === '1' || sheetColumn === 1 ? 1 : sheetColumn === '2' || sheetColumn === 2 ? 2 : sheetColumn === '3' || sheetColumn === 3 ? 3 : sheetColumn === '4' || sheetColumn === 4 ? 4 : sheetColumn === '5' || sheetColumn === 5 ? 5 : sheetColumn === '6' || sheetColumn === 6 ? 6 : sheetColumn === '7' || sheetColumn === 7 ? 7 : 8,

      column : [
        row1Column === '1' || row1Column === 1 ? 1 : row1Column === '2' || row1Column === 2 ? 2 : row1Column === '3' || row1Column === 3 ? 3 : row1Column === '4' || row1Column === 4 ? 4 : row1Column === '5' || row1Column === 5 ? 5 : row1Column === '6' || row1Column === 6 ? 6 : row1Column === '7' || row1Column === 7 ? 7 : 8,
        row2Column === '1' || row2Column === 1 ? 1 : row2Column === '2' || row2Column === 2 ? 2 : row2Column === '3' || row2Column === 3 ? 3 : row2Column === '4' || row2Column === 4 ? 4 : row2Column === '5' || row2Column === 5 ? 5 : row2Column === '6' || row2Column === 6 ? 6 : row2Column === '7' || row2Column === 7 ? 7 : 8,
        row3Column === '1' || row3Column === 1 ? 1 : row3Column === '2' || row3Column === 2 ? 2 : row3Column === '3' || row3Column === 3 ? 3 : row3Column === '4' || row3Column === 4 ? 4 : row3Column === '5' || row3Column === 5 ? 5 : row3Column === '6' || row3Column === 6 ? 6 : row3Column === '7' || row3Column === 7 ? 7 : 8,
        row4Column === '1' || row4Column === 1 ? 1 : row4Column === '2' || row4Column === 2 ? 2 : row4Column === '3' || row4Column === 3 ? 3 : row4Column === '4' || row4Column === 4 ? 4 : row4Column === '5' || row4Column === 5 ? 5 : row4Column === '6' || row4Column === 6 ? 6 : row4Column === '7' || row4Column === 7 ? 7 : 8,
        row5Column === '1' || row5Column === 1 ? 1 : row5Column === '2' || row5Column === 2 ? 2 : row5Column === '3' || row5Column === 3 ? 3 : row5Column === '4' || row5Column === 4 ? 4 : row5Column === '5' || row5Column === 5 ? 5 : row5Column === '6' || row5Column === 6 ? 6 : row5Column === '7' || row5Column === 7 ? 7 : 8,
        row6Column === '1' || row6Column === 1 ? 1 : row6Column === '2' || row6Column === 2 ? 2 : row6Column === '3' || row6Column === 3 ? 3 : row6Column === '4' || row6Column === 4 ? 4 : row6Column === '5' || row6Column === 5 ? 5 : row6Column === '6' || row6Column === 6 ? 6 : row6Column === '7' || row6Column === 7 ? 7 : 8,
        row7Column === '1' || row7Column === 1 ? 1 : row7Column === '2' || row7Column === 2 ? 2 : row7Column === '3' || row7Column === 3 ? 3 : row7Column === '4' || row7Column === 4 ? 4 : row7Column === '5' || row7Column === 5 ? 5 : row7Column === '6' || row7Column === 6 ? 6 : row7Column === '7' || row7Column === 7 ? 7 : 8,
        row8Column === '1' || row8Column === 1 ? 1 : row8Column === '2' || row8Column === 2 ? 2 : row8Column === '3' || row8Column === 3 ? 3 : row8Column === '4' || row8Column === 4 ? 4 : row8Column === '5' || row8Column === 5 ? 5 : row8Column === '6' || row8Column === 6 ? 6 : row8Column === '7' || row8Column === 7 ? 7 : 8,
      ],

      row0 : [ row1Title, row2Title, row3Title, row4Title, row5Title, row6Title, row7Title, row8Title, ],
      column0 : [ row1CheckBox1, row1CheckBox2, row1CheckBox3, row1CheckBox4, row1CheckBox5, row1CheckBox6, row1CheckBox7, row1CheckBox8, ],
      column1 : [ row2CheckBox1, row2CheckBox2, row2CheckBox3, row2CheckBox4, row2CheckBox5, row2CheckBox6, row2CheckBox7, row2CheckBox8, ],
      column2 : [ row3CheckBox1, row3CheckBox2, row3CheckBox3, row3CheckBox4, row3CheckBox5, row3CheckBox6, row3CheckBox7, row3CheckBox8, ],
      column3 : [ row4CheckBox1, row4CheckBox2, row4CheckBox3, row4CheckBox4, row4CheckBox5, row4CheckBox6, row4CheckBox7, row4CheckBox8, ],
      column4 : [ row5CheckBox1, row5CheckBox2, row5CheckBox3, row5CheckBox4, row5CheckBox5, row5CheckBox6, row5CheckBox7, row5CheckBox8, ],
      column5 : [ row6CheckBox1, row6CheckBox2, row6CheckBox3, row6CheckBox4, row6CheckBox5, row6CheckBox6, row6CheckBox7, row6CheckBox8, ],
      column6 : [ row7CheckBox1, row7CheckBox2, row7CheckBox3, row7CheckBox4, row7CheckBox5, row7CheckBox6, row7CheckBox7, row7CheckBox8, ],
      column7 : [ row8CheckBox1, row8CheckBox2, row8CheckBox3, row8CheckBox4, row8CheckBox5, row8CheckBox6, row8CheckBox7, row8CheckBox8, ],
    })

    props.setSetting(false);
  }

  return (
    <>
    <div className="task_setting_save" onClick={() => save()}>保存</div>

    <div className="task_setting">
      <div className="task_setting_item_top">
        <div>
          <p>シート タイトル</p>
          <input type='text' onChange={(e) => setSheetName(e.target.value)} placeholder='シート名' value={sheetName} />
        </div>
        <div>
          <p>色</p>
          <select onChange={(e) => setSheetColor(e.target.value)} value={sheetColor}>
            <option value='FF7847'>赤色</option>
            <option value='68CEEB'>青色</option>
            <option value='FFD04F'>黄色</option>
            <option value='76C8C1'>緑色</option>
          </select>
        </div>
        <div>
          <p>列の数</p>
          <select onChange={(e) => setSheetColumn(e.target.value)} value={sheetColumn}>
            <option value='1'>1つ</option>
            <option value='2'>2つ</option>
            <option value='3'>3つ</option>
            <option value='4'>4つ</option>
            <option value='5'>5つ</option>
            <option value='6'>6つ</option>
            <option value='7'>7つ</option>
            <option value='8'>8つ</option>
          </select>
        </div>
        <div>
          <p>checkBox</p>
          <select onChange={(e) => setSheetBox(e.target.value)} value={sheetBox}>
            <option value={false}>オフ</option>
            <option value={true}>オン</option>
          </select>
        </div>
      </div>

      {sheetBox === true || sheetBox === 'true' ? 
      <>
        {1 <= sheetColumn ?
        <>
          {/* 列1 */}
          <div className="task_setting_item">
            <div>
              <div>
                <p>列1のタイトル</p>
                <input type='text' value={row1Title} onChange={(e) => setRow1Title(e.target.value)} placeholder='1列目のタイトル' />
              </div>
              <div>
                <p>列1の数</p>
                <select onChange={(e) => setRow1Column(e.target.value)} value={row1Column}>
                  <option value='1'>1つ</option>
                  <option value='2'>2つ</option>
                  <option value='3'>3つ</option>
                  <option value='4'>4つ</option>
                  <option value='5'>5つ</option>
                  <option value='6'>6つ</option>
                  <option value='7'>7つ</option>
                  <option value='8'>8つ</option>
                </select>
              </div>
              <div></div>
              <div></div>
            </div>
            <div>
              {1 <= row1Column ?
                <div>
                  <p>checkBox 1</p>
                  <input type='text' value={row1CheckBox1} onChange={(e) => setRow1CheckBox1(e.target.value)} placeholder='チェックボックス1' />
                </div>
                : <div></div>}
              {2 <= row1Column ?
                <div>
                  <p>checkBox 2</p>
                  <input type='text' value={row1CheckBox2} onChange={(e) => setRow1CheckBox2(e.target.value)} placeholder='チェックボックス2' />
                </div>
              : <div></div>}
              {3 <= row1Column ?
                <div>
                  <p>checkBox 3</p>
                  <input type='text' value={row1CheckBox3} onChange={(e) => setRow1CheckBox3(e.target.value)} placeholder='チェックボックス3' />
                </div>
              : <div></div>}
              {4 <= row1Column ?
                <div>
                  <p>checkBox 4</p>
                  <input type='text' value={row1CheckBox4} onChange={(e) => setRow1CheckBox4(e.target.value)} placeholder='チェックボックス4' />
                </div>
              : <div></div>}
            </div>
            <div>
              {5 <= row1Column ?
                <div>
                  <p>checkBox 5</p>
                  <input type='text' value={row1CheckBox5} onChange={(e) => setRow1CheckBox5(e.target.value)} placeholder='チェックボックス5' />
                </div>
              : <div></div>}
              {6 <= row1Column ?
                <div>
                  <p>checkBox 6</p>
                  <input type='text' value={row1CheckBox6} onChange={(e) => setRow1CheckBox6(e.target.value)} placeholder='チェックボックス6' />
                </div>
              : <div></div>}
              {7 <= row1Column ?
                <div>
                  <p>checkBox 7</p>
                  <input type='text' value={row1CheckBox7} onChange={(e) => setRow1CheckBox7(e.target.value)} placeholder='チェックボックス7' />
                </div>
              : <div></div>}
              {8 <= row1Column ?
                <div>
                  <p>checkBox 8</p>
                  <input type='text' value={row1CheckBox8} onChange={(e) => setRow1CheckBox8(e.target.value)} placeholder='チェックボックス8' />
                </div>
              : <div></div>}
            </div>
          </div>
          </> :<></>}
        {2 <= sheetColumn ?
          <>
          {/* 列2 */}
          <div className="task_setting_item">
            <div>
              <div>
                <p>列2のタイトル</p>
                <input type='text' value={row2Title} onChange={(e) => setRow2Title(e.target.value)} placeholder='1列目のタイトル' />
              </div>
              <div>
                <p>列2の数</p>
                <select onChange={(e) => setRow2Column(e.target.value)} value={row2Column}>
                  <option value='1'>1つ</option>
                  <option value='2'>2つ</option>
                  <option value='3'>3つ</option>
                  <option value='4'>4つ</option>
                  <option value='5'>5つ</option>
                  <option value='6'>6つ</option>
                  <option value='7'>7つ</option>
                  <option value='8'>8つ</option>
                  <option value='9'>9つ</option>
                  <option value='10'>10つ</option>
                </select>
              </div>
              <div></div>
              <div></div>
            </div>
            <div>
              {1 <= row2Column ?
                <div>
                  <p>checkBox 1</p>
                  <input type='text' value={row2CheckBox1} onChange={(e) => setRow2CheckBox1(e.target.value)} placeholder='チェックボックス1' />
                </div>
              : <div></div>}
              {2 <= row2Column ?
                <div>
                  <p>checkBox 2</p>
                  <input type='text' value={row2CheckBox2} onChange={(e) => setRow2CheckBox2(e.target.value)} placeholder='チェックボックス2' />
                </div>
              : <div></div>}
              {3 <= row2Column ?
                <div>
                  <p>checkBox 3</p>
                  <input type='text' value={row2CheckBox3} onChange={(e) => setRow2CheckBox3(e.target.value)} placeholder='チェックボックス3' />
                </div>
              : <div></div>}
              {4 <= row2Column ?
                <div>
                  <p>checkBox 4</p>
                  <input type='text' value={row2CheckBox4} onChange={(e) => setRow2CheckBox4(e.target.value)} placeholder='チェックボックス4' />
                </div>
              : <div></div>}
            </div>
            <div>
              {5 <= row2Column ?
                <div>
                  <p>checkBox 5</p>
                  <input type='text' value={row2CheckBox5} onChange={(e) => setRow2CheckBox5(e.target.value)} placeholder='チェックボックス5' />
                </div>
              : <div></div>}
              {6 <= row2Column ?
                <div>
                  <p>checkBox 6</p>
                  <input type='text' value={row2CheckBox6} onChange={(e) => setRow2CheckBox6(e.target.value)} placeholder='チェックボックス6' />
                </div>
              : <div></div>}
              {7 <= row2Column ?
                <div>
                  <p>checkBox 7</p>
                  <input type='text' value={row2CheckBox7} onChange={(e) => setRow2CheckBox7(e.target.value)} placeholder='チェックボックス7' />
                </div>
              : <div></div>}
              {8 <= row2Column ?
                <div>
                  <p>checkBox 8</p>
                  <input type='text' value={row2CheckBox8} onChange={(e) => setRow2CheckBox8(e.target.value)} placeholder='チェックボックス8' />
                </div>
              : <div></div>}
            </div>
          </div>
          </> : <></>}
        {3 <= sheetColumn ?
          <>
          {/* 列3 */}
          <div className="task_setting_item">
            <div>
              <div>
                <p>列3のタイトル</p>
                <input type='text' value={row3Title} onChange={(e) => setRow3Title(e.target.value)} placeholder='1列目のタイトル' />
              </div>
              <div>
                <p>列3の数</p>
                <select onChange={(e) => setRow3Column(e.target.value)} value={row3Column}>
                  <option value='1'>1つ</option>
                  <option value='2'>2つ</option>
                  <option value='3'>3つ</option>
                  <option value='4'>4つ</option>
                  <option value='5'>5つ</option>
                  <option value='6'>6つ</option>
                  <option value='7'>7つ</option>
                  <option value='8'>8つ</option>
                  <option value='9'>9つ</option>
                  <option value='10'>10つ</option>
                </select>
              </div>
              <div></div>
              <div></div>
            </div>
            <div>
              {1 <= row3Column ?
                <div>
                  <p>checkBox 1</p>
                  <input type='text' value={row3CheckBox1} onChange={(e) => setRow3CheckBox1(e.target.value)} placeholder='チェックボックス1' />
                </div>
              : <div></div>}
              {2 <= row3Column ?
                <div>
                  <p>checkBox 2</p>
                  <input type='text' value={row3CheckBox2} onChange={(e) => setRow3CheckBox2(e.target.value)} placeholder='チェックボックス2' />
                </div>
              : <div></div>}
              {3 <= row3Column ?
                <div>
                  <p>checkBox 3</p>
                  <input type='text' value={row3CheckBox3} onChange={(e) => setRow3CheckBox3(e.target.value)} placeholder='チェックボックス3' />
                </div>
              : <div></div>}
              {4 <= row3Column ?
                <div>
                  <p>checkBox 4</p>
                  <input type='text' value={row3CheckBox4} onChange={(e) => setRow3CheckBox4(e.target.value)} placeholder='チェックボックス4' />
                </div>
              : <div></div>}
            </div>
            <div>
              {5 <= row3Column ?
                <div>
                  <p>checkBox 5</p>
                  <input type='text' value={row3CheckBox5} onChange={(e) => setRow3CheckBox5(e.target.value)} placeholder='チェックボックス5' />
                </div>
              : <div></div>}
              {6 <= row3Column ?
                <div>
                  <p>checkBox 6</p>
                  <input type='text' value={row3CheckBox6} onChange={(e) => setRow3CheckBox6(e.target.value)} placeholder='チェックボックス6' />
                </div>
              : <div></div>}
              {7 <= row3Column ?
                <div>
                  <p>checkBox 7</p>
                  <input type='text' value={row3CheckBox7} onChange={(e) => setRow3CheckBox7(e.target.value)} placeholder='チェックボックス7' />
                </div>
              : <div></div>}
              {8 <= row3Column ?
                <div>
                  <p>checkBox 8</p>
                  <input type='text' value={row3CheckBox8} onChange={(e) => setRow3CheckBox8(e.target.value)} placeholder='チェックボックス8' />
                </div>
              : <div></div>}
            </div>
          </div>
          </> : <></>}
        {4 <= sheetColumn ?
          <>
          {/* 列4 */}
          <div className="task_setting_item">
            <div>
              <div>
                <p>列4のタイトル</p>
                <input type='text' value={row4Title} onChange={(e) => setRow4Title(e.target.value)} placeholder='1列目のタイトル' />
              </div>
              <div>
                <p>列4の数</p>
                <select onChange={(e) => setRow4Column(e.target.value)} value={row4Column}>
                  <option value='1'>1つ</option>
                  <option value='2'>2つ</option>
                  <option value='3'>3つ</option>
                  <option value='4'>4つ</option>
                  <option value='5'>5つ</option>
                  <option value='6'>6つ</option>
                  <option value='7'>7つ</option>
                  <option value='8'>8つ</option>
                  <option value='9'>9つ</option>
                  <option value='10'>10つ</option>
                </select>
              </div>
              <div></div>
              <div></div>
            </div>
            <div>
              {1 <= row4Column ?
                <div>
                  <p>checkBox 1</p>
                  <input type='text' value={row4CheckBox1} onChange={(e) => setRow4CheckBox1(e.target.value)} placeholder='チェックボックス1' />
                </div>
              : <div></div>}
              {2 <= row4Column ?
                <div>
                  <p>checkBox 2</p>
                  <input type='text' value={row4CheckBox2} onChange={(e) => setRow4CheckBox2(e.target.value)} placeholder='チェックボックス2' />
                </div>
              : <div></div>}
              {3 <= row4Column ?
                <div>
                  <p>checkBox 3</p>
                  <input type='text' value={row4CheckBox3} onChange={(e) => setRow4CheckBox3(e.target.value)} placeholder='チェックボックス3' />
                </div>
              : <div></div>}
              {4 <= row4Column ?
                <div>
                  <p>checkBox 4</p>
                  <input type='text' value={row4CheckBox4} onChange={(e) => setRow4CheckBox4(e.target.value)} placeholder='チェックボックス4' />
                </div>
              : <div></div>}
            </div>
            <div>
              {5 <= row4Column ?
                <div>
                  <p>checkBox 5</p>
                  <input type='text' value={row4CheckBox5} onChange={(e) => setRow4CheckBox5(e.target.value)} placeholder='チェックボックス5' />
                </div>
              : <div></div>}
              {6 <= row4Column ?
                <div>
                  <p>checkBox 6</p>
                  <input type='text' value={row4CheckBox6} onChange={(e) => setRow4CheckBox6(e.target.value)} placeholder='チェックボックス6' />
                </div>
              : <div></div>}
              {7 <= row4Column ?
                <div>
                  <p>checkBox 7</p>
                  <input type='text' value={row4CheckBox7} onChange={(e) => setRow4CheckBox7(e.target.value)} placeholder='チェックボックス7' />
                </div>
              : <div></div>}
              {8 <= row4Column ?
                <div>
                  <p>checkBox 8</p>
                  <input type='text' value={row4CheckBox8} onChange={(e) => setRow4CheckBox8(e.target.value)} placeholder='チェックボックス8' />
                </div>
              : <div></div>}
            </div>
          </div>
          </> : <></>}
        {5 <= sheetColumn ?
          <>
          {/* 列5 */}
          <div className="task_setting_item">
            <div>
              <div>
                <p>列5のタイトル</p>
                <input type='text' value={row5Title} onChange={(e) => setRow5Title(e.target.value)} placeholder='1列目のタイトル' />
              </div>
              <div>
                <p>列5の数</p>
                <select onChange={(e) => setRow5Column(e.target.value)} value={row5Column}>
                  <option value='1'>1つ</option>
                  <option value='2'>2つ</option>
                  <option value='3'>3つ</option>
                  <option value='4'>4つ</option>
                  <option value='5'>5つ</option>
                  <option value='6'>6つ</option>
                  <option value='7'>7つ</option>
                  <option value='8'>8つ</option>
                  <option value='9'>9つ</option>
                  <option value='10'>10つ</option>
                </select>
              </div>
              <div></div>
              <div></div>
            </div>
            <div>
              {1 <= row5Column ?
                <div>
                  <p>checkBox 1</p>
                  <input type='text' value={row5CheckBox1} onChange={(e) => setRow5CheckBox1(e.target.value)} placeholder='チェックボックス1' />
                </div>
              : <div></div>}
              {2 <= row5Column ?
                <div>
                  <p>checkBox 2</p>
                  <input type='text' value={row5CheckBox2} onChange={(e) => setRow5CheckBox2(e.target.value)} placeholder='チェックボックス2' />
                </div>
              : <div></div>}
              {3 <= row5Column ?
                <div>
                  <p>checkBox 3</p>
                  <input type='text' value={row5CheckBox3} onChange={(e) => setRow5CheckBox3(e.target.value)} placeholder='チェックボックス3' />
                </div>
              : <div></div>}
              {4 <= row5Column ?
                <div>
                  <p>checkBox 4</p>
                  <input type='text' value={row5CheckBox4} onChange={(e) => setRow5CheckBox4(e.target.value)} placeholder='チェックボックス4' />
                </div>
              : <div></div>}
            </div>
            <div>
              {5 <= row5Column ?
                <div>
                  <p>checkBox 5</p>
                  <input type='text' value={row5CheckBox5} onChange={(e) => setRow5CheckBox5(e.target.value)} placeholder='チェックボックス5' />
                </div>
              : <div></div>}
              {6 <= row5Column ?
                <div>
                  <p>checkBox 6</p>
                  <input type='text' value={row5CheckBox6} onChange={(e) => setRow5CheckBox6(e.target.value)} placeholder='チェックボックス6' />
                </div>
              : <div></div>}
              {7 <= row5Column ?
                <div>
                  <p>checkBox 7</p>
                  <input type='text' value={row5CheckBox7} onChange={(e) => setRow5CheckBox7(e.target.value)} placeholder='チェックボックス7' />
                </div>
              : <div></div>}
              {8 <= row5Column ?
                <div>
                  <p>checkBox 8</p>
                  <input type='text' value={row5CheckBox8} onChange={(e) => setRow5CheckBox8(e.target.value)} placeholder='チェックボックス8' />
                </div>
              : <div></div>}
            </div>
          </div>
          </> : <></>}
        {6 <= sheetColumn ?
          <>
          {/* 列6 */}
          <div className="task_setting_item">
            <div>
              <div>
                <p>列6のタイトル</p>
                <input type='text' value={row6Title} onChange={(e) => setRow6Title(e.target.value)} placeholder='1列目のタイトル' />
              </div>
              <div>
                <p>列6の数</p>
                <select onChange={(e) => setRow6Column(e.target.value)} value={row6Column}>
                  <option value='1'>1つ</option>
                  <option value='2'>2つ</option>
                  <option value='3'>3つ</option>
                  <option value='4'>4つ</option>
                  <option value='5'>5つ</option>
                  <option value='6'>6つ</option>
                  <option value='7'>7つ</option>
                  <option value='8'>8つ</option>
                  <option value='9'>9つ</option>
                  <option value='10'>10つ</option>
                </select>
              </div>
              <div></div>
              <div></div>
            </div>
            <div>
              {1 <= row6Column ?
                <div>
                  <p>checkBox 1</p>
                  <input type='text' value={row6CheckBox1} onChange={(e) => setRow6CheckBox1(e.target.value)} placeholder='チェックボックス1' />
                </div>
              : <div></div>}
              {2 <= row6Column ?
                <div>
                  <p>checkBox 2</p>
                  <input type='text' value={row6CheckBox2} onChange={(e) => setRow6CheckBox2(e.target.value)} placeholder='チェックボックス2' />
                </div>
              : <div></div>}
              {3 <= row6Column ?
                <div>
                  <p>checkBox 3</p>
                  <input type='text' value={row6CheckBox3} onChange={(e) => setRow6CheckBox3(e.target.value)} placeholder='チェックボックス3' />
                </div>
              : <div></div>}
              {4 <= row6Column ?
                <div>
                  <p>checkBox 4</p>
                  <input type='text' value={row6CheckBox4} onChange={(e) => setRow6CheckBox4(e.target.value)} placeholder='チェックボックス4' />
                </div>
              : <div></div>}
            </div>
            <div>
              {5 <= row6Column ?
                <div>
                  <p>checkBox 5</p>
                  <input type='text' value={row6CheckBox5} onChange={(e) => setRow6CheckBox5(e.target.value)} placeholder='チェックボックス5' />
                </div>
              : <div></div>}
              {6 <= row6Column ?
                <div>
                  <p>checkBox 6</p>
                  <input type='text' value={row6CheckBox6} onChange={(e) => setRow6CheckBox6(e.target.value)} placeholder='チェックボックス6' />
                </div>
              : <div></div>}
              {7 <= row6Column ?
                <div>
                  <p>checkBox 7</p>
                  <input type='text' value={row6CheckBox7} onChange={(e) => setRow6CheckBox7(e.target.value)} placeholder='チェックボックス7' />
                </div>
              : <div></div>}
              {8 <= row6Column ?
                <div>
                  <p>checkBox 8</p>
                  <input type='text' value={row6CheckBox8} onChange={(e) => setRow6CheckBox8(e.target.value)} placeholder='チェックボックス8' />
                </div>
              : <div></div>}
            </div>
          </div>
          </> : <></>}
        {7 <= sheetColumn ?
          <>
          <div className="task_setting_item">
            <div>
              <div>
                <p>列7のタイトル</p>
                <input type='text' value={row7Title} onChange={(e) => setRow7Title(e.target.value)} placeholder='1列目のタイトル' />
              </div>
              <div>
                <p>列7の数</p>
                <select onChange={(e) => setRow7Column(e.target.value)} value={row7Column}>
                  <option value='1'>1つ</option>
                  <option value='2'>2つ</option>
                  <option value='3'>3つ</option>
                  <option value='4'>4つ</option>
                  <option value='5'>5つ</option>
                  <option value='6'>6つ</option>
                  <option value='7'>7つ</option>
                  <option value='8'>8つ</option>
                  <option value='9'>9つ</option>
                  <option value='10'>10つ</option>
                </select>
              </div>
              <div></div>
              <div></div>
            </div>
            <div>
              {1 <= row7Column ?
                <div>
                  <p>checkBox 1</p>
                  <input type='text' value={row7CheckBox1} onChange={(e) => setRow7CheckBox1(e.target.value)} placeholder='チェックボックス1' />
                </div>
              : <div></div>}
              {2 <= row7Column ?
                <div>
                  <p>checkBox 2</p>
                  <input type='text' value={row7CheckBox2} onChange={(e) => setRow7CheckBox2(e.target.value)} placeholder='チェックボックス2' />
                </div>
              : <div></div>}
              {3 <= row7Column ?
                <div>
                  <p>checkBox 3</p>
                  <input type='text' value={row7CheckBox3} onChange={(e) => setRow7CheckBox3(e.target.value)} placeholder='チェックボックス3' />
                </div>
              : <div></div>}
              {4 <= row7Column ?
                <div>
                  <p>checkBox 4</p>
                  <input type='text' value={row7CheckBox4} onChange={(e) => setRow7CheckBox4(e.target.value)} placeholder='チェックボックス4' />
                </div>
              : <div></div>}
            </div>
            <div>
              {5 <= row7Column ?
                <div>
                  <p>checkBox 5</p>
                  <input type='text' value={row7CheckBox5} onChange={(e) => setRow7CheckBox5(e.target.value)} placeholder='チェックボックス5' />
                </div>
              : <div></div>}
              {6 <= row7Column ?
                <div>
                  <p>checkBox 6</p>
                  <input type='text' value={row7CheckBox6} onChange={(e) => setRow7CheckBox6(e.target.value)} placeholder='チェックボックス6' />
                </div>
              : <div></div>}
              {7 <= row7Column ?
                <div>
                  <p>checkBox 7</p>
                  <input type='text' value={row7CheckBox7} onChange={(e) => setRow7CheckBox7(e.target.value)} placeholder='チェックボックス7' />
                </div>
              : <div></div>}
              {8 <= row7Column ?
                <div>
                  <p>checkBox 8</p>
                  <input type='text' value={row7CheckBox8} onChange={(e) => setRow7CheckBox8(e.target.value)} placeholder='チェックボックス8' />
                </div>
              : <div></div>}
            </div>
          </div>
          </> : <></>}
        {7 <= sheetColumn ?
          <>
          {/* 列8 */}
          <div className="task_setting_item">
            <div>
              <div>
                <p>列8のタイトル</p>
                <input type='text' value={row8Title} onChange={(e) => setRow8Title(e.target.value)} placeholder='1列目のタイトル' />
              </div>
              <div>
                <p>列8の数</p>
                <select onChange={(e) => setRow8Column(e.target.value)} value={row8Column}>
                  <option value='1'>1つ</option>
                  <option value='2'>2つ</option>
                  <option value='3'>3つ</option>
                  <option value='4'>4つ</option>
                  <option value='5'>5つ</option>
                  <option value='6'>6つ</option>
                  <option value='7'>7つ</option>
                  <option value='8'>8つ</option>
                  <option value='9'>9つ</option>
                  <option value='10'>10つ</option>
                </select>
              </div>
              <div></div>
              <div></div>
            </div>
            <div>
              {1 <= row8Column ?
                <div>
                  <p>checkBox 1</p>
                  <input type='text' value={row8CheckBox1} onChange={(e) => setRow8CheckBox1(e.target.value)} placeholder='チェックボックス1' />
                </div>
              : <div></div>}
              {2 <= row8Column ?
                <div>
                  <p>checkBox 2</p>
                  <input type='text' value={row8CheckBox2} onChange={(e) => setRow8CheckBox2(e.target.value)} placeholder='チェックボックス2' />
                </div>
              : <div></div>}
              {3 <= row8Column ?
                <div>
                  <p>checkBox 3</p>
                  <input type='text' value={row8CheckBox3} onChange={(e) => setRow8CheckBox3(e.target.value)} placeholder='チェックボックス3' />
                </div>
              : <div></div>}
              {4 <= row8Column ?
                <div>
                  <p>checkBox 4</p>
                  <input type='text' value={row8CheckBox4} onChange={(e) => setRow8CheckBox4(e.target.value)} placeholder='チェックボックス4' />
                </div>
              : <div></div>}
            </div>
            <div>
              {5 <= row8Column ?
                <div>
                  <p>checkBox 5</p>
                  <input type='text' value={row8CheckBox5} onChange={(e) => setRow8CheckBox5(e.target.value)} placeholder='チェックボックス5' />
                </div>
              : <div></div>}
              {6 <= row8Column ?
                <div>
                  <p>checkBox 6</p>
                  <input type='text' value={row8CheckBox6} onChange={(e) => setRow8CheckBox6(e.target.value)} placeholder='チェックボックス6' />
                </div>
              : <div></div>}
              {7 <= row8Column ?
                <div>
                  <p>checkBox 7</p>
                  <input type='text' value={row8CheckBox7} onChange={(e) => setRow8CheckBox7(e.target.value)} placeholder='チェックボックス7' />
                </div>
              : <div></div>}
              {8 <= row8Column ?
                <div>
                  <p>checkBox 8</p>
                  <input type='text' value={row8CheckBox8} onChange={(e) => setRow8CheckBox8(e.target.value)} placeholder='チェックボックス8' />
                </div>
              : <div></div>}
            </div>
          </div>
          </> : <></>}
      </>
      :
      <>
        {/* 列8 */}
        <div className="task_setting_item">
          <div>
            {1 <= sheetColumn ?
              <div>
                <p>列 1</p>
                <input type='text' value={row1Title} onChange={(e) => setRow1Title(e.target.value)} placeholder='チェックボックス1' />
              </div>
            : <div></div>}
            {2 <= sheetColumn ?
              <div>
                <p>列 2</p>
                <input type='text' value={row2Title} onChange={(e) => setRow2Title(e.target.value)} placeholder='チェックボックス2' />
              </div>
            : <div></div>}
            {3 <= sheetColumn ?
              <div>
                <p>列 3</p>
                <input type='text' value={row3Title} onChange={(e) => setRow3Title(e.target.value)} placeholder='チェックボックス3' />
              </div>
            : <div></div>}
            {4 <= sheetColumn ?
              <div>
                <p>列 4</p>
                <input type='text' value={row4Title} onChange={(e) => setRow4Title(e.target.value)} placeholder='チェックボックス4' />
              </div>
            : <div></div>}
          </div>
          <div>
            {5 <= sheetColumn ?
              <div>
                <p>列 5</p>
                <input type='text' value={row5Title} onChange={(e) => setRow5Title(e.target.value)} placeholder='チェックボックス5' />
              </div>
            : <div></div>}
            {6 <= sheetColumn ?
              <div>
                <p>列 6</p>
                <input type='text' value={row6Title} onChange={(e) => setRow6Title(e.target.value)} placeholder='チェックボックス6' />
              </div>
            : <div></div>}
            {7 <= sheetColumn ?
              <div>
                <p>列 7</p>
                <input type='text' value={row7Title} onChange={(e) => setRow7Title(e.target.value)} placeholder='チェックボックス7' />
              </div>
            : <div></div>}
            {8 <= sheetColumn ?
              <div>
                <p>列 8</p>
                <input type='text' value={row8Title} onChange={(e) => setRow8Title(e.target.value)} placeholder='チェックボックス8' />
              </div>
            : <div></div>}
          </div>
        </div>
      </> }
    </div>
    {/* <div className="task_setting_delete" onClick={() =>  delete()}>削除</div> */}

    {/* <h4>gbiz取得</h4>
    <p>印鑑証明依頼</p>
    <p>印鑑証明取得</p>
    <p>郵送済み</p>
    <p>メール受信</p>

    <h4>決算書</h4>
    <p>共有依頼済み</p>
    <p>内容確認</p>
    <p>完了</p>

    <h4>ヒアリング</h4>
    <p>シート共有依頼</p>
    <p>シート共有済み</p>
    <p>申請確認</p>
    <p>日程調整</p>
    <p>ヒアリング済み</p>

    <h4>計画書作成</h4>
    <p>投資内容</p>
    <p>方向性完了</p>
    <p>完成</p>
    <p>共有済み</p>
    <p>ヒアリング済み</p>

    <h4>申請準備</h4>
    <p>確認証</p>
    <p>ミラサポ</p>
    <p>売上減少書類作成</p>
    <p>申請別書類</p>
    <p>加点書類</p>

    <h4>申請</h4>
    <p>日程調整</p>
    <p>入力済み</p>
    <p>受付番号共有</p>
    <p>連絡済み</p> */}
    </>
  );
};

export default Help;
