import './styles.css';
import Icon from './../../../../image/icon.png';

const Help = () => {

  
  return (
    <>
    <div className='AdBusiness'>
      <div className='AdBusiness_icon'>
        <img src={Icon} alt='' />
        <h4>トランク合同会社</h4>
      </div>
      <div className='AdBusiness_link'>
        <h4>ブログ</h4>
        <h4>会社概要</h4>
        <h4>問い合わせ</h4>
      </div>
    </div>

    {/* <div className='contact_form_bar'>
      <h4>事業再構築補助金の申請に興味がある方は、ぜひお問い合わせください。</h4>
    </div> */}
    </>
  );
}

export default Help;
