// 市場縮小要件

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/36/top.png";
import Image1 from "./../../../../../image/media/business/36/1.png";
import Image2 from "./../../../../../image/media/business/36/2.png";
import Image3 from "./../../../../../image/media/business/36/3.png";
import Image4 from "./../../../../../image/media/business/36/4.png";
import Image5 from "./../../../../../image/media/business/36/5.png";
import Image6 from "./../../../../../image/media/business/36/6.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」市場縮小要件を満たすことの説明書の書き方を解説！',
    description: '本記事では、事業再構築補助金の必須書類である「市場縮小要件を満たすことの説明書」の作成方法を解説しております。さらに、初めての方では分からない最短での用意する方法も解説しておりますので、ぜひご参考にしていただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">事業再構築補助金 市場縮小要件を満たすことの説明書の書き方を解説！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは、トランク合同会社の補助金コンサルタントです。<br />
            <br />
            今回は、産業構造転換枠の新しい申請枠に関する「市場縮小要件を満たすことの説明書」の書き方について、解説いたします。<br />
            <br />
            申請をご検討されている事業者の皆様は、是非最後までご覧いただければと思います。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>本記事でこんな悩みが解決！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>市場縮小要件を満たすことの説明書の書き方</span></li>
              <li><span>ダウンロード方法</span></li>
              <li><span>必要な申請枠</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、<strong>経済産業省から展開されている「事業再構築指針の手引き」を参考にして作成したもの</strong>となっています。<br />
            <a href='https://www.meti.go.jp/covid-19/jigyo_saikoutiku/pdf/shishin_tebiki.pdf' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：「事業再構築指針の手引き」に関する記事
            </a><br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">市場縮小要件を満たすこと説明書の概要</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">必要な申請枠</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">市場縮小要件を満たすこと説明書の書き方</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">ダウンロード方法</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">記載項目の確認</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">補助事業として取り組む事業の業種・業態の記載方法</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">補助事業として取り組む事業の概要の記載方法</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">補助事業として取り組む事業の市場規模が10%以上縮小することの説明の記載方法</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.市場縮小要件を満たすこと説明書の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では市場縮小要件を満たすこと説明書が必要な申請な枠の確認、及びダウンロード方法について解説させていただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>必要な申請枠</h3><br />
          <p className="media_text_text">
            「市場縮小要件を満たすこと説明書」の提出が必要な申請枠は産業構造転換枠という申請枠のみとなりますので、それ以外の事業者様は必要ございません。<br />
            <br />
            産業構造転換枠について詳しく知りたいという方は、以下の記事にて詳しく解説しておりますので、ぜひご覧いただければと思います。<br />
            <a href='https://trankllc.com/media/subsidy/business/0026' target="_blank" rel="nofollow noopener noreferrer">
              詳細記事：市場縮小で悩んでいる企業様は産業構造転換枠！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>産業構造転換枠の概要</h3><br />
          <p className="media_text_text">
            国内市場縮小等の構造的な課題に直面している業種・業態の中小企業等が取り組む事業再構築を支援する枠となっております。<br />
            <br />
            そのため、要件として市場縮小要件という要件があり、過去10年、もしくは、今後10年で市場が縮小している業種・業態に属することが要件とされております。<br />
            <br />
            また、要件を満たす方法として以下の2つがあげられます。<br />
            ・産業構造転換枠の対象となる業種・業態の一覧にあるリストに所属している<br />
            ・自分自身で事業が縮小していることを証明する<br />
            <br />
            産業構造転換枠の対象となる業種・業態の一覧は以下のPDFにて記載がございますので、ご参照いただければと思います。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/tenkanwaku_list.pdf' target="_blank" rel="nofollow noopener noreferrer">
              詳細記事：産業構造転換枠の対象となる業種・業態の一覧
            </a><br />
            <br />
            自分自身で事業が縮小していることを証明するも含めて以下に記載がございますので、最後までご参照いただければと思います。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.ダウンロード方法</h2>
          </div>
          <p className="media_text_text">
            「市場縮小要件を満たすこと説明書」は、以下の事業再構築補助金の電子申請書類よりwordをダウンロードしていただければと思います。<br />
            <br />
            ダウンロードができるサイトのリンクを以下に貼り付けておりますので、ご覧いただければと思います。<br />
            <a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：市場縮小要件を満たすこと説明書のURL
            </a><br />
            <br />
            <span>ダウンロード部分のイメージ画像</span><br />
            <img src={Image1} className='media_img' alt='' /><br />
            <br />
            <span>市場縮小要件を満たすこと説明書のwordのイメージ</span><br />
            <img src={Image2} className='media_img' alt='' /><br />
          </p>
          </>
          
          <>
          <div id='2' className="media_text_title">
            <h2>2.市場縮小要件を満たすこと説明書の書き方</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、市場縮小要件を満たすこと説明書の書き方を説明していきます。<br />
            また、以下の順を追ってご説明させていただければと思います。<br />
            <br />
            <span>ご説明の流れ</span><br />
            <strong>① 既存事業の業種・業態の記載方法</strong><br />
            <strong>② 既存事業の概要の記載方法</strong><br />
            <strong>③ 既存事業の市場規模が10%以上縮小することの説明の記載方法</strong><br />
          </p>
          <h3 className='media_text_text_title'>具体的な事業者様の例</h3><br />
          <p className="media_text_text">
            今回、市場縮小要件をご説明させていただくに当たって、具体例となる事業者像が必要なため、以下に事業者像を記載させていただきます。<br />
            <br />
            <span>具体的な事業者例</span><br />
            既存事業：製本業を経営<br />
            新規事業：電子書籍のプラットフォーム事業<br />
            補足：30年以上もの間、製本業（本を作る会社）を経営していたが、製本業の市場規模が縮小していることを鑑みて、電子書籍事業への転換を検討している。<br />
            <br />
            上記の設定を元に、以下の具体的な記載に入らせていただきます。<br />
          </p>
          <h3 className='media_text_text_title'>既存事業の業種・業態の記載方法</h3><br />
          <p className="media_text_text">
            既存事業の業種・業態の記載方法では、以下の部分に記載する内容を解説いたします。<br />
            <br />
            <img src={Image3} className='media_img' alt='' /><br />
            <br />
            既存事業の業種・業態の記載方法は、以下の2つの記載部分に分かれております。<br />
            <br />
            <span>①を記載する方</span><br />
            事業再構築補助金を使用して転換する事業が、<br /><strong>「産業構造転換枠の対象となる業種・業態の一覧」に含まれている場合に記載。</strong><br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/tenkanwaku_list.pdf' target="_blank" rel="nofollow noopener noreferrer">
              産業構造転換枠の対象となる業種・業態の一覧のURL
            </a><br />
            <br />
            今回の場合、出版業がリストに含まれているため、以下のようにご記載いただければ問題ございません。<br />
            具体例...管理番号：1、産業分類（小分類）：出版業（電子出版のみの事業者は除く）及び書籍・雑誌小売業<br />
            <br />
            <span>②を記載する方</span><br />
            事業再構築補助金を使用して転換する事業が、<br /><strong>「産業構造転換枠の対象となる業種・業態の一覧」に含まれていない場合に記載。</strong><br />
            <br />
            <span>具体的な記載内容</span><br />
            リストに含まれていなかった場合は、製本業は以下の産業分類に含まれるため、以下のようにご記載ください。<br />
            <strong>製造業 ＞ 印刷・同関連業 ＞ 製本業，印刷物加工業</strong><br />
            <br />
            また、製本業は分類コードが1531であるため、以下のように記載します。<br />
            <strong>具体例...分類コード：1531、産業分類（小分類）：製本業</strong><br />
            <br />
            産業分類の見つけ方は以下のサイトのどこに属するかで決まりますので、ご参照いただければと思います。<br />
            <a href='https://www.e-stat.go.jp/classifications/terms/10?search_method=keyword&search_word=&komokuSearchFlg_dummy=1&komokuSearchFlg=1&info1SearchFlg_dummy=1&info1SearchFlg=1&info2SearchFlg_dummy=1&info2SearchFlg=1&revision=03&search_kind=10&base_code=0&form_id=main_form&op=search&searchboxShow1=1&searchboxShow2=0&searchboxShow3=0&page=&srchcndId=' target="_blank" rel="nofollow noopener noreferrer">
              e-Stat 日本標準産業分類サイト
            </a><br />
          </p>
          <h3 className='media_text_text_title'>既存事業の概要</h3><br />
          <p className="media_text_text">
            既存事業の概要では、以下の部分に記載する内容を解説いたします。<br />
            <br />
            <img src={Image4} className='media_img' alt='' /><br />
            <br />
            既存事業の内容をご説明いただき、業種・業態に当てはまることを示せば問題ございません。<br />
            <br />
            こちらの記載項目は、「産業構造転換枠の対象となる業種・業態の一覧」に含まれているかどうかに関わらず、記載が必要となります。<br />
            <br />
            <span>具体的な記載内容</span><br />
            既存事業では出版業界に属しており、電子書類をいただき、本に印字していくという事業を行なっている。コネクションが当社の強みであるため、本の制作会社からの依頼を多く受けている状況ではあるが、出版業界全般の市場推移の減少していることから受注数が減少しており、経営状態が悪化している。<br />
          </p>
          <h3 className='media_text_text_title'>補助事業として取り組む事業の市場規模が10%以上縮小することの説明の記載方法</h3><br />
          <p className="media_text_text">
            補助事業として取り組む事業の市場規模が10%以上縮小することの説明の記載方法では、以下の部分に記載する内容を解説いたします。<br />
            <br />
            <img src={Image5} className='media_img' alt='' /><br />
            <br />
            補助事業として取り組む事業の市場規模が10%以上縮小することの説明では、選択した業種・業態の市場縮小が10%以上であることを説明する部分となっております。<br />
            <br />
            そのため、本項目は「産業構造転換枠の対象となる業種・業態の一覧」に属している業種・業態への転換をしない方（自分自身で市場縮小を証明する必要がある方）は記載が必須となっております。<br />
            <br />
            <span>具体的な記載内容</span><br />
            本事業では製本業から電子書籍業への転換を行うため、出版科学研究所の日本の出版販売額の調査の一部を抜粋する。これによると、本の市場規模が衰退しており、当社の業務範囲である製本の受注数も同時に減退していることがわかる。<br />
            <br />
            これにより2016年20,009億円の数値と2025年：29,321億円の数値が約40%減少しているということができ、また、年率の増加が一定であることから要件を満たすことができると言える。<br />
            <br />
            2010年：約19,000億円<br />
            2011年：約18,000億円<br />
            2012年：約17.000億円<br />
            2013年：約16,000億円<br />
            2014年：約15,000億円<br />
            2015年：約15,100億円<br />
            2016年：約14,500億円<br />
            2017年：約14,000億円<br />
            2018年：約12,500億円<br />
            2019年：約12,000億円<br />
            <br />
            今回、参照したデータ、サイトを以下に添付いたします。<br />
            <a href='https://www.yano.co.jp/press-release/show/press_id/2800' target="_blank" rel="nofollow noopener noreferrer">
              株式会社矢野経済研究所の食品宅配市場規模の調査のURL
            </a><br />
            <br />
            <img src={Image6} className='media_img' alt='' /><br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、産業構造転換枠で必要となる「市場縮小要件を満たすことの説明書」の書き方をご紹介させていただきました。<br />
            <br />
            どのように記載するべきかがわからないという方が多くいると思いますが、既存事業で何をしていて市場が縮小しているという点さえ抑えていれば問題ございません。<br />
            <br />
            もし、記載方法で不安な点等ございましたら、弊社へお申し付けいただければと思います。<br />
            この度は最後まで記事を読んでいただき、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;



