const BlogText0001 = (props) => {

  return(
  <>
  <div className='management_review_content_1'>
    <h4>財務に関する質問（1問）</h4>
    <div>
      <h5>Q1.工事種類別年間平均完成工事高（数字）</h5>
      <p>許可を受けた建設業に係る建設工事の種類別年間平均完成工事高をご記載ください。</p>
      <input onChange={(e) => props.setConstructionCost(e.target.value)} placeholder="100000000" type="number" />
      {/* <p>円</p> */}
    </div>
  </div>
  </>
  );
}

export default BlogText0001;
