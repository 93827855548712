import './styles.css';
import comment from './../../../../image/help/comment.png';
import Service3_1 from './../../../../image/help/service3_1.png';
import Service3 from './../../../../image/help/service3.png';

const Help = () => {
  
  return (
    <>
    <div className='help_service_title'>
      <div className='help_service_title_left'>
        <h6>3</h6>
        <h3>幅広い業種の実績</h3>
      </div>
      <div className='help_service_title_right'>
        <img src={Service3_1} alt='' />
        <div>
          <img src={comment} alt='' />
          <p>お客さまの業種の偏りはなく、幅広い業種で対応させていただいておりますので、ニッチ業界の方も安心してご依頼いただければと思います。</p>
        </div>
      </div>
    </div>
    <p className='help_service_title_text'>お客さまの業種の偏りはなく、幅広い業種で対応させていただいておりますので、ニッチ業界の方も安心してご依頼いただければと思います。</p>
    <div className='help_service1_img'>
      <img src={Service3} alt='' />
    </div>
    </>
  );
}

export default Help;
