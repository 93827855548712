import './styles.css';

const simulatorResult = (props) => {

  return(
  <>
  <div className='simulator_caution_background'>
    <div className='simulator_caution_contact'>
      <h4>ご注意事項</h4>
      <ul>
        <li>本シミュレーターは事業再構築補助金の申請可否を業種単位で判断するものであり、要件の合致を確定するものではありません。</li>
        <li>補助金の受け取り金額は、受け取れる金額の満額を表示します。</li>
        <li>本シミュレーターの結果は、申請時に要件を改めてご確認いただく、もしくは、専門家へご相談ください。</li>
      </ul>
    </div>
  </div>
  </>
  );
}

export default simulatorResult;
