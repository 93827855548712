// 宿泊事業
// https://docs.google.com/document/d/1jmZv_8bvQ3ZsELdNYEuaU22Bf9pbuFsuyBQKcoCtaY8/edit

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "../../nav/index";
import Topic from "../topic";
import TopImage from "./../../../../../image/media/business/40/top.png";
import Image1 from "./../../../../../image/media/business/40/1.png";
import Footer from '../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」宿泊事業の採択事例を大公開！',
    description: '本記事では、事業再構築補助金の飲食業 採択事例を紹介しております。飲食業を営んでいて事業再構築補助金の申請を検討されている方は、本記事をご参考に検討を行っていただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」宿泊事業の採択事例を大公開！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            宿泊業といえば、数ある業種の中でもコロナの影響を最も受けた業種といっても過言ではありません。<br />
            <br />
            そのため、「コロナが落ち着いても客足が戻るか不安」「新たなビジネスモデルを構築して回復を図りたい」など、今後に向けて事業再構築補助金の利用を検討している方も多いのではないでしょうか。<br />
            <br />
            本記事では、事業再構築補助金を検討中の宿泊業を営む方向けに、補助金の概要や採択事例について詳しく解説します。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業再構築補助金の対象となる宿泊業者</span></li>
              <li><span>宿泊業における事業再構築補助金の活用方法</span></li>
              <li><span>過去の宿泊事業者採択例</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、最新の「第10回事業再構築補助金　公募要領」を元に作成しています。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo.pdf' target="_blank" rel="nofollow noopener noreferrer">
              リンク：事業再構築補助金 公募要領
            </a><br />
            <br />
            また、当社では無料相談も行っておりますので、疑問点やご質問等ございましたらお気軽にお問い合わせください。<br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">飲食事業における活用方法</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金の対象となる経費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">飲食事業での事業再構築類型の具体例</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金での申請例</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">虎の巻から見る飲食事業者の特徴</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">o	飲食事業者の申請傾向</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">o	具体的な採択事例</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">o	飲食事業の申請で抑えるべきポイント</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.宿泊事業における活用方法</h2>
          </div>
          <p className="media_text_text">
            <br />
            宿泊事業における事業再構築補助金の活用方法について、<br />
            <strong>1.事業再構築補助金の対象となる経費</strong><br />
            <strong>2.宿泊事業での事業再構築類型の具体例</strong><br />
            <strong>3.事業再構築補助金での申請例</strong><br />
            これら3つの項目に分けて解説していきます。<br />
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金の対象となる経費</h3><br />
          <p className="media_text_text">
            事業再構築補助金の対象経費は以下の11項目となります。<br />
            <br />
            ①建物費②機械装置・システム構築費③技術導入費④専門家経費⑤運搬費⑥クラウドサービス利用費⑦外注費⑧知的財産権等関連経費⑨広告宣伝・販売促進費⑩研修費⑪廃業費（第10回公募要領より抜粋）<br />
            <br />
            上記のなかでも、宿泊事業に関係の深い経費は以下の通り。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>経費項目</th><th>詳細</th><th>具体例</th>
              </tr>
              <tr>
                <td>建物費</td>
                <td>事業で使用する事務所・施設の建設や改修にかかる経費</td>
                <td>・ワーケーション仕様への改装費用<br />・サウナ施設の建設費用</td>
              </tr>
              <tr>
                <td>機械装置・システム費</td>
                <td>事業で使用する機械装置や新たに開発した設備・システムの開発経費</td>
                <td>・予約管理システムの導入<br />・自動チェックイン機</td>
              </tr>
              <tr>
                <td>外注費</td>
                <td>事業に必要な加工・設計等を外部に委託する経費</td>
                <td>・新店舗のデザイン費用<br />・店舗ロゴの作成費用</td>
              </tr>
              <tr>
                <td>広告宣伝・販売促進費</td>
                <td>事業に必要な加工・設計等を外部に委託する経費</td>
                <td>・新店舗のデザイン費用<br />・店舗ロゴの作成費用</td>
              </tr>
              <tr>
                <td>研修費</td>
                <td>事業の教育訓練・講座受講に係る経費</td>
                <td>・従業員の教育費費</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            宿泊業の場合、既存の旅館やホテルなど、施設の一部を改修して新事業に取り組むケースが多いのが特徴。<br />
            そのため、宿泊業で申請される方は、建物費や機械装置・システム構築費に事業再構築補助金を活用されることが多いです。<br />
            <br />
            他にも、集客に欠かせない広告宣伝費も経費の対象となっているため、積極的に活用されることをおすすめします。<br />
            <br />
            <strong>【対象外経費に注意】</strong><br />
            <br />
            事業再構築補助金の経費は多岐に渡りますが、対象外となる項目も多いため、注意が必要です。<br />
            なかでも活用事例の多い建物費については、施設の改修費などは対象となる一方で、不動産（土地・建物）や構築物の購入費は対象になりません。<br />
            <br />
            構築物がイメージしづらいかと思いますが、こちらは「土地の上にある建物以外のもの」を指します。<br />
            <br />
            具体的には、庭園や看板などが該当します。<br />
            <br />
            宿泊業に当てはめると、グランピング施設の改修費（インフラ工事含む）は経費に含まれますが、簡易テントは構築物になるため経費の補助対象外になります。<br />
            <br />
            これは、テントが補助事業以外にも使用できる汎用性があるとみなされるからです。<br />
            <br />
            建物費以外で汎用性があるとみなされるものには、家具やパソコン、タブレットなどが挙げられます。<br />
            <br />
            事業再生補助金を活用する際は、対象外経費について事前にしっかりと確認しておきましょう。<br />
            <br />
            当社では複雑な対象経費の確認や金額算出を初回相談無料で行っておりますので、詳しく知りたい方はお気軽にお問い合わせください。<br />
          </p>
          <h3 className='media_text_text_title'>宿泊事業での事業再構築類型の具体例</h3>
          <p className="media_text_text">
            事業再構築補助金を申請するには公募要領に定められている要件を満たす必要があり、以下3つの類型のいずれか1つに当てはまっていなければなりません。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th>類型を満たすための要件</th><th>産業分類に基づく変更</th>
              </tr>
              <tr>
                <td>新市場進出</td>
                <td>
                  ①製品又は提供方法を大きく変更する<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全体の10%以上となる計画を策定する。
                </td>
                <td>無</td>
              </tr>
              <tr>
                <td>事業転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。<br />
                </td>
                <td>主な事業を変更(中・小・細分類)</td>
              </tr>
              <tr>
                <td>業種転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3～5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。
                </td>
                <td>主な業種を変更<br />(大分類)</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            <strong>【宿泊業での具体例】</strong><br />
            上記3つの類型（新市場進出・事業転換・業種転換）を宿泊業に当てはめてみましょう。<br />
            以下の具体例をご覧ください。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th>類型を満たすための要件</th><th>産業分類に基づく変更</th>
              </tr>
              <tr>
                <td>新市場進出</td>
                <td>
                  751：旅館、<br />ホテル→751：旅館、ホテル<br />
                </td>
                <td>インバウンド需要に頼っていたホテルが、売上回復のためにワーケーション事業に進出する</td>
              </tr>
              <tr>
                <td>事業転換</td>
                <td>
                  751：旅館、<br />ホテル→762：専門料理店<br />
                </td>
                <td>宿泊業から創作料理の技術と立地を活かした飲食事業への事業転換</td>
              </tr>
              <tr>
                <td>業種転換</td>
                <td>
                  M：宿泊業→N生活関連サービス業、<br />娯楽業
                </td>
                <td>宿泊業開始予定の物件を、コロナの影響からフォトスタジオ事業へ転換</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            変更内容によっては宿泊事業者様も事業再構築補助金の申請が可能ですので、是非ご検討ください。
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金での申請例</h3>
          <p className="media_text_text">
            次は、公表されている事業再構築補助金の採択事例をもとに、対象経費や補助金額について解説します。<br />
            補助金額は事業規模や従業員数、申請枠によって変わります。<br />
            <br />
            そのため、申請の際は下記事例を参考に、ご自身の事業に該当する申請枠や補助金額、補助率を入念に確認してから望むようにしましょう。<br />
            <br />
            <strong>【事業内容】</strong><br />
            宿泊業を行っていた事業者が、遊休スペースを活用したサウナ事業を行う。<br />
            密を避けた非接触の個室サウナで、コロナによって減少した売上の改善を目指す。<br />
            <br />
            事業場所：宮城県仙台市<br />
            従業員数：23人<br />
            売上高：9,000万円<br />
            <br />
            投資内容<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <td>建物改修費用</td>
                <td>￥20,000,000</td>
              </tr>
              <tr>
                <td>看板設置費用</td>
                <td>￥1,000,000</td>
              </tr>
              <tr>
                <td>広告費用</td>
                <td>￥3,000,000</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            上記の経費のなかで対象になるのは、建物改修費用と広告費用の2つ。<br />
            看板は建物費に含まれるようにも思えますが、構築物に該当するため対象外経費となります。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>日本産業分類</th><th>大分類</th><th>中分類</th><th>小分類</th><th>細分類</th>
              </tr>
              <tr>
                <td>既存事業</td><td>M：宿泊業，飲食サービス業</td><td>75：宿泊業</td><td>751：旅館・ホテル</td><td>7511：旅館・ホテル</td>
              </tr>
              <tr>
                <td>新規事業</td><td>N：生活関連サービス業・娯楽業</td><td>78：洗濯・理容・美容・浴場業</td><td>785：その他の公衆浴場業</td><td>7851：その他の公衆浴場業</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            再構築類型については、「業種転換」となります。<br />
            なぜなら、上記の通り日本産業分類上の大分類が変わっているからです。<br />
            そのため、本事業については「業種転換」として申請を進めます。<br />
            <br />
            続いて、申請枠についてですが、こちらは下表の売上高をご覧ください。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'></th><th>5月</th><th>6月</th><th>7月</th><th>合計</th>
              </tr>
              <tr>
                <td>2021年</td><td>1,800</td><td>1,600</td><td>1,700</td><td>5,100</td>
              </tr>
              <tr>
                <td>2022年</td><td>750</td><td>800</td><td>750</td><td>2,300</td>
              </tr>
              <tr>
                <td>増減率(%)</td><td>-58%</td><td>-50%</td><td>-55%</td><td>-55%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            2022年の売上高合計は、2021年の同月を比較すると55％も減少しています。<br />
            そのため、本事業は「物価高騰対策・回復再生応援枠」にて申請することとします。<br />
            <br />
            それでは、上記内容をふまえたうえで申請金額と補助金を計算していきましょう。<br />
            <br />
            まず、申請金額ですが、こちらは先述した通り看板費用を除いた建物改修費用と広告費用の合計23,000,000円が申請金額となります。<br />
            <br />
            第10回公募では、中小企業者等の補助率は2/3。<br />
            また、従業員21〜50人の場合は、800万円までが3/4になるため、これらをもとに計算すると以下の通り。<br />
            <br />
            <strong>【計算式】</strong><br />
            （補助率　2/3）15,000,000×2/3＝10,000,000<br />
            （従業員数による補助率　3/4）8,000,000×3/4＝6,000,000<br />
            <br />
            補助金額の最大は16,000,000円となります。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.虎の巻から見る宿泊事業者の特徴</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築補助金については、経済産業省により申請数や採択率をデータ分析した「事業再構築虎の巻」（https://jigyou-saikouchiku.go.jp/pdf/jigyokeikaku_guidebook.pdf）が公表されています。<br />
            <br />
            虎の巻では業種ごとに、<br />
            <br />
            ・事業計画の実現に向けた取り組み方<br />
            ・テーマ別採択率と申請率の詳細<br />
            ・有望度別の採択事業例の詳細<br />
            <br />
            などの内容がわかりやすく記載されているため、申請を検討されている方は一読することをおすすめします。<br />
            <br />
            本記事でも虎の巻をもとに、<br />
            <br />
            1.	宿泊事業者の申請傾向<br />
            2.	具体的な採択事例<br />
            3.	宿泊事業の申請で抑えるべきポイント<br />
            <br />
            について解説します。<br />
          </p>
          <h3 className='media_text_text_title'>宿泊事業者の申請傾向</h3>
          <p className="media_text_text">
            <img src={Image1} className='media_img' alt='' />
            宿泊事業者の申請傾向は上図の通りです。<br />
            表の見方は、縦軸が申請数、横軸が採択率となっています。<br />
            <br />
            つまり右上の事業ほど申請数が多く、採択率も高いと言えます。<br />
            宿泊業の場合、ワーケーション事業や飲食関連事業、アウトドア事業の申請数が多く、採択率も高いのが特徴です。<br />
            <br />
            いずれの事業も、既存の施設やノウハウを活用しやすい点が申請数に反映されていると言えるでしょう。<br />
            また、ワーケーションやアウトドアなど非接触型のサービスは、コロナ禍で市場が伸びたこともあり採択率が高くなっています。<br />
          </p>
          <h3 className='media_text_text_title'>具体的な採択事例</h3>
          <p className="media_text_text">
            宿泊業における事業再構築補助金の採択事例を紹介します。<br />
            <br />
            <strong>①温泉旅館によるワーケーションとサウナ事業開始という採択事例</strong><br />
            <br />
            コロナによる影響から宿泊業ではインバウンド、観光客ともに減少しています。<br />
            そのため、新たなターゲットを獲得したいという宿泊事業者も多いのではないでしょうか。<br />
            <br />
            そこで注目なのがワーケーションです。<br />
            <br />
            リモートワークが普及したことからワーケーション需要は高まっており、事業再構築補助金においても有望度が高い傾向にあります。<br />
            <br />
            単身ビジネスパーソンや家族でのワーケーション旅行に訪れるファミリー層の獲得が狙えることもあり、おすすめ事業のひとつと言えるでしょう。<br />
            <br />
            また、サウナに関しても近年のサウナブームの影響から比較的採用率も高く、狙い目の事業と言えるでしょう。<br />
            <br />
            <strong>②宿泊業からコロナ禍でも需要の高いランドリー事業を開始という採択事例</strong><br />
            <br />
            既存の宿泊事業を維持しつつ、遊休スペースを活用したソーシャルランドリーを設置した事例です。<br />
            <br />
            これまでの宿泊メインの客層から、近隣住民を新たなターゲットにしたサービスを展開しV字回復を狙う事業だと考えることができます。<br />
            <br />
            ウィズコロナ時代に対応した換気設備や作業スペース、自動販売機やWiFiを整備したランドリーで、遊休スペースを活用するため、取り組みやすい事業と言えるでしょう。<br />
            <br />
            事業再構築補助金において、申請数は平均値を下回りますが、採択率は比較的高めとなっています。<br />
            <br />
            ランドリー事業は既存の宿泊事業との相性も良く、相乗効果による売上げアップが期待できますので、申請でのアピールポイントにもなるでしょう。<br />
          </p>
          <h3 className='media_text_text_title'>宿泊事業の申請で抑えるべきポイント</h3>
          <p className="media_text_text">
            <strong>・アフターコロナへの対応</strong><br />
            宿泊業では、コロナを機に団体客から個人客へと顧客層の変化が見られます。<br />
            そのため、コロナ終息後のインバウンドの回復を見据えた対応が申請において重要なポイントとなるでしょう。<br />
            <br />
            団体客メインのサービスから高単価の個人・インバウンド向けに転換するなどのアフターコロナに対応した計画書の作成を心がけましょう。<br />
            <br />
            <strong>・差別化の実現</strong><br />
            宿泊業では、飲食関連への展開も採択率が高くなっています。<br />
            <br />
            とはいえ、差別化が図れていない事業計画では不採択になるケースも多いため、注意が必要です。<br />
            採択に近づくためには、地元企業との提携で地域の名産品の活用・提供し、差別化と地域振興を実現していくなどの計画を記載することが重要になるでしょう。<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築補助金を活用することで、従来の宿泊業からワーケーションやグランピング、飲食関連やサウナなど、様々な事業へチャレンジすることが可能となります。<br />
            <br />
            コロナで苦しい経営が続いた事業者様には、新たな活路を見出すきっかけにもなるでしょう。<br />
            <br />
            とはいえ、事業再構築補助金は申請要件が難しいうえに計画書の作成も一筋縄ではいかず、申請するだけでも一苦労です。<br />
            <br />
            本記事を運営している会社であるトランク合同会社は、飲食事業者様の事業再構築補助金採択例を豊富に有しており、各事業者様の特徴を前面に押し出した申請サポートを行っています。<br />
            <br />
            「できるだけ手続きの負担を減らしたい」「少しでも採択率を上げたい」という方は是非ご相談ください。<br />
            <br />
            この度は、記事を拝見いただき誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


