import './styles.css';
import Header1 from './../../../../image/adbusiness/header_1.png';
import Header2 from './../../../../image/adbusiness/header_2.png';
import Header3 from './../../../../image/adbusiness/header_3.png';
import Header4 from './../../../../image/adbusiness/header_4.png';

const Help = () => {
  
  return (
    <>
    <div className='AdBusiness_header'>
      <div className='AdBusiness_header_img'>
        <img src={Header1} alt='' className='AdBusiness_header_img_side' />
        <img src={Header2} alt='' />
      </div>
      <div className='AdBusiness_header_text'>
        <h1><span className='header_1'>補助金</span><span className='header_2'>申請</span><span className='header_3'>の</span><span className='header_4'>エキスパート</span></h1>
        <h4>事業再構築補助金の申請から</h4>
        <hr />
        <h4>補助金受け取りまでサポート</h4>
        <hr />
        <div>
          <div>
            <h5>顧客満足度</h5>
            <h4>98%</h4>
          </div>
          <div>
            <h5>採択率</h5>
            <h4>90%</h4>
          </div>
          <div>
            <h5>資金調達額</h5>
            <h4>2億円</h4>
          </div>
        </div>
      </div>
      <div className='AdBusiness_header_img'>
        <img src={Header3} alt='' />
        <img src={Header4} alt='' className='AdBusiness_header_img_side' />
      </div>
    </div>
    </>
  );
}

export default Help;
