import DocumentMeta from 'react-document-meta';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './styles.css';
import Nav from './nav/index';
import Header from './header/index';
import HeaderBar from './headerBar/index';
import What from './what/index';
import Example from './example/index';
import Strong from './strong/index';
import QA from './QA/index';
import Contact from './contact/index';
import Footer from './../../tabs/footer/index';
import firebase from 'firebase/compat/app'

const Help = () => {

  const meta = {
    title: '事業再構築補助金|トランク合同会社',
    description: '補助金専門の業者が採択から補助金受取まで徹底的にサポートします。補助金の選定からご相談いただけますので、ご質問がございましたらご気軽にご連絡いただければと思います。',
    canonical: 'https://trankllc.com/help',
    meta: {charset: 'utf-8',}
  };

  var uid = uuidv4();

  useEffect(() => {
    firebase.firestore().collection('analytics').doc(uid)
    .set({
      uid: uid,
      date: `${new Date().getFullYear()}年${new Date().getMonth() + 1}月${new Date().getDate()}日${new Date().getHours()}時${new Date().getMinutes()}分${new Date().getSeconds()}秒`,
      count: 0,
      contact: false,
      button1: false,
      button2: false,
      button3: false,
      button4: false,
      button5: false,
      width: window.innerWidth,
      heigt: document.body.offsetHeight,
      scroll: [],
    }).then(() => {
      setInterval(() => { 
        firebase.firestore().collection('analytics').doc(uid)
        .update({
          count: firebase.firestore.FieldValue.increment(1),
          scroll: firebase.firestore.FieldValue.arrayUnion(`${window.scrollY} : ${new Date().getFullYear()}年${new Date().getMonth() + 1}月${new Date().getDate()}日${new Date().getHours()}時${new Date().getMinutes()}分${new Date().getSeconds()}秒`),
          width: window.innerWidth,
          heigt: document.body.offsetHeight,
        })
      }, 5000);
    })
  }, [uid]);
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <Header/>
      <HeaderBar uid={uid} />
      <What />
      <Strong uid={uid} />
      {/* <Price /> */}
      <QA uid={uid} />
      <Example uid={uid} />
      <Contact uid={uid} />
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default Help;
