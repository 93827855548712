import DocumentMeta from 'react-document-meta';
import './styles.css';
import Header from "./header/index.js";
import Nav from "./nav/index.js";
import Point from "./point/index.js";
import Award from "./award/index.js";
import Details from "./details/index";
import Flow from "./flow/index";
import QA from "./QA/index";
import Results from "./results/index";
import Contact from "./contact/index";
import Footer from './../../tabs/footer/index';
import React, { useState, useEffect } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Help = () => {

  const meta = {
    title: '事業再構築補助金|トランク合同会社',
    description: '補助金専門の業者が採択から補助金受取まで徹底的にサポートします。補助金の選定からご相談いただけますので、ご質問がございましたらご気軽にご連絡いただければと思います。',
    canonical: 'https://trankllc.com/help',
    meta: {charset: 'utf-8',}
  };

  const [ isVisible, setIsVisible ] = useState(false)

  const toggleVisibility = () => {
    window.scrollY > 600
      ? setIsVisible(true)
      : setIsVisible(false)
    }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)
    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav isVisible={isVisible} />
      <Header />
      <Point />
      <Award />
      <Details />
      {/* <div className='hojokin_contact_message'>
        <h4>事業再構築補助金の申請に興味がある方は、ぜひお問い合わせください。</h4>
        <AnchorLink href="#1" offset="170"><div >お問い合わせ</div></AnchorLink>
      </div> */}
      <Flow />
      <QA />
      <Results />
      <Contact />
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default Help;
