import './styles.css';
import image from './../../../../../image/adbusiness/what.png';

const Help = () => {
  
  return (
    <>
    <div className='AdBusiness_what_box1'>
      <div>
        <h4>事業再構築補助金の活用効果</h4>
        <h3>新規事業に要する経費全体の<br />50%~75%が補助される</h3>
        <p>事業再構築補助金は新規事業を行う際に要する経費の一部が国から補助されるものとなっており、要件に適用すれば設備投資額や広告宣伝費等の50%~75%が補助されます。</p>
      </div>
      <img src={image} alt='' />
    </div>
    </>
  );
}

export default Help;
