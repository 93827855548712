import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../../1/topic.js";
import TopImage from "./../../../../../image/media/saveLabor/1/top.png";
import Images1 from "./../../../../../image/media/saveLabor/1/1.png";
import Images2 from "./../../../../../image/media/saveLabor/1/2.png";
import Images3 from "./../../../../../image/media/saveLabor/1/3.jpg";
import Images4 from "./../../../../../image/media/saveLabor/1/4.jpg";
import Images5 from "./../../../../../image/media/saveLabor/1/5.jpg";
import Images6 from "./../../../../../image/media/saveLabor/1/6.jpg";
import Footer from './../../../../tabs/footer/index.js';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '【省力化補助金・省人化補助金】中小企業省力化投資補助金の概要を徹底解説！',
    description: '省力化補助金・省人化補助金(中小企業省力化投資補助金)は、2024年から開始された大型の補助金です。中小企業の簡易で即効性がある設備投資を支援しており、生産性の向上や付加価値額の増加を目的としています。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <div className='media'>
        <div className='media_article'>
          
          <>
          <p className='media_date'>2024/01/11 更新日: 2024/01/11</p>
          <h1 className="media_title">【省力化補助金・省人化補助金】中小企業省力化投資補助金の概要を徹底解説！</h1>
          <img src={TopImage} className="media_title_img" alt='事業再構築補助金' />
          </>

          <>
          <p className="media_text_text">
            <br />
            2023年11月に閣議決定された予算案の中で、最も注目されているのが「省力化投資補助金」です。省力化投資補助金(以下：省力化補助金)は、人手不足に悩む中小企業を支援するものであり、<strong>設備投資を行うことで、人員の削減や生産性の向上を目指す</strong>ものとなっています。<br />
            <br />
            従来の事業再構築補助金が再編されたものであり、2024年は計5,000億円以上が予算として計上されていることから、大型補助金として注目を集めています。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>中小企業省力化投資補助金の概要</span></li>
              <li><span>省力化補助金の特徴</span></li>
              <li><span>申請フロー、スケジュールの全体像</span></li>
            </ul>
          </div>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">事業再構築補助金の概要</AnchorLink></li>
                <li><AnchorLink href="#2" offset="100">全体スケジュール</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">全体の対象者</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">対象になる補助金経費</AnchorLink></li>
                <li><AnchorLink href="#5" offset="100">提出が必要な書類</AnchorLink></li>
                <li><AnchorLink href="#6" offset="100">提出が必要な書類</AnchorLink></li>
                <li><AnchorLink href="#7" offset="100">事業再構築補助金の採択率</AnchorLink></li>
                <li><AnchorLink href="#8" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.省力化補助金の概要・目的</h2>
          </div>
          <h3 className='media_text_text_title'>補助金の概要</h3><br />
          <p className="media_text_text">
            <img src={Images1} className='media_img' alt='' />
            引用：<a href='https://www.meti.go.jp/main/yosan/yosan_fy2023/hosei/index.html' target="_blank" rel="nofollow noopener noreferrer">
              経済産業省関係令和5年度補正予算の事業概要
            </a><br />
            <br />
            現在公表されている省力化補助金の概要は、上図の通りです。<br />
            <strong>申請類型は「カタログ型」のみとなっており、中小企業庁が公表したカタログの中から自社に当てはまる製品を選び、補助金を申請する形になると想定されています。</strong><br />
            <br />
            IT導入補助金に類似した形になると想定されており、他補助金と比較して申請を簡易にすることで、より多くの事業者に活用してもらいたいという意図をくみ取ることが出来ます。<br />
          </p>
          <h3 className='media_text_text_title'>補助金の目的</h3><br />
          <p className="media_text_text">
            省力化補助金の目的は、<strong>簡易で即効性がある設備投資を行うことで生産性を向上し、付加価値額の増加を推進すること</strong>です。<br />
            <br />
            政府は今までも「ものづくり補助金」や「小規模事業者持続化補助金」により設備投資を推進してきましたが、申請の手間が大きい点や申請から設備導入まで長い時間がかかるといった課題がありました。<br />
            <br />
            本補助金で申請を簡易にすることで、申請する事業者を増やして生産性向上の底上げを行うという政府の意図を推察することが出来るため、申請書類や手続きは従来の補助金と比較して簡易になることが見込まれています。<br />
          </p>
          </>
          
          <>
          <div id='2' className="media_text_title">
            <h2>2.省力化補助金の対象者</h2>
          </div>
          <p className="media_text_text">
            <br />
            省力化補助金の対象事業者は、中小企業・小規模事業者になると推察されます。経済産業省が主導する他の補助金と同じように、業種・業態による制限はなく、個人事業主や設立間もない企業も対象になると見込まれています。<br />
            <br />
            なお、中小企業の定義は以下の通りとなっていますので、申請を希望する事業者は一度確認ください。<br />
            <br />
            <img src={Images2} className='media_img' alt='' />
            引用：<a href='https://www.chusho.meti.go.jp/faq/faq/faq01_teigi.htm#q1' target="_blank" rel="nofollow noopener noreferrer">
              中小企業者の範囲及び用語の定義
            </a><br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.省力化補助金の補助上限金額、補助率</h2>
          </div>
          <p className="media_text_text">
            <br />
            省力化補助金の補助上限金額は、従業員数により変動します。また、社内の賃金引上げを行った場合は上限金額が増額されるため、賃上げを行う予定がある事業者はしっかりと要件を確認し、引上げを行った金額での申請を行いましょう。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>従業員数</th><th className='media_table_0001_1_2'>補助上限金額</th><th className='media_table_0001_1_3'>賃金引上げを行った場合</th>
              </tr>
              <tr>
                <td>～5名</td>
                <td>200万円</td>
                <td>300万円</td>
              </tr>
              <tr>
                <td>6～20名</td>
                <td>500万円</td>
                <td>750万円</td>
              </tr>
              <tr>
                <td>21名～</td>
                <td>1,000万円</td>
                <td>1,500万円</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            ※具体的な賃金引上げの要件は発表されていないため、発表され次第解説いたします。<br />
            <br />
            また、省力化補助金の補助率は1/2となっており、設備投資を半額で行うことが出来るという計算になります。具体例も下記に記載していますので、申請予定の事業者は確認しておきましょう。<br />
            <br />
            <strong>・例①：従業員10名の農業事業者が、1,000万円のドローンを購入する場合</strong><br />
            1,000万円(設備投資金額)×1/2(補助率)=500万円の補助金獲得<br />
            <br />
            <strong>・例②：従業員25名の製造業者が、4,000万円の自動製造ロボットを購入する場合</strong><br />
            4,000万円(設備投資金額)×1/2(補助率)=2,000万円<br />
            しかし、補助上限金額が1,500万円のため、最大1,500万円の補助金獲得<br />
            <br />
            補助上限金額を考えると、<strong>省力化補助金は500万円～3,000万円程の設備投資を行う場合に最適な補助金</strong>だと考えられます。それ以外の投資金額では「小規模事業者持続化補助金」や「ものづくり補助金(省力化枠)」の方がいい場合もあるため、該当事業者は他補助金も併せて検討しましょう。<br />
          </p>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>4.省力化補助金の申請フロー</h2>
          </div>
          <p className="media_text_text">
            <br />
            省力化補助金では、以下のような申請のフローになると想定されています。導入する設備をカタログから選択する以外は、経済産業省が管轄する他の補助金と同様になると見込まれています。<br />
          </p>
          <h3 className='media_text_text_title'>①カタログから導入する設備を選定する。</h3><br />
          <p className="media_text_text">
            <img src={Images3} className="media_title_img" alt='西村やすとし元経済産業省大臣　Xでの投稿' />
            引用：<a href='https://twitter.com/nishy03/status/1726900786503045526' target="_blank" rel="nofollow noopener noreferrer">
              西村やすとし元経済産業省大臣　Xでの投稿
            </a><br />
            <br />
            省力化補助金の大きな特徴が、導入する製品をカタログから選ぶ点です。イメージは上記のようになっており、無人システムやロボットといった人手不足解消に効果がある製品を中心に、政府が選定した製品を選ぶことが出来るようになると想定されています。<br />
          </p>
          <h3 className='media_text_text_title'>②申請書類の作成、準備</h3><br />
          <p className="media_text_text">
            補助金の申請書類として、事業計画書や決算書の提出が求められると想定されています。成果目標として「付加価値額の増加」が求められているため、売上の拡大や利益率の改善により、営業利益や人件費が増加する計画を策定することを求められると考えられます。<br />
          </p>
          <h3 className='media_text_text_title'>③電子申請</h3><br />
          <p className="media_text_text">
            経済産業省が実施している従来の補助金と同様に、GBizIDを用いた電子申請にて申請が行われると考えられます。GBizIDの取得には2週間ほどかかる場合もあるため、申請を見込んでいる事業者は早めにGBizIDを取得するようにしましょう。<br />
            参考：<a href='https://www.meti.go.jp/product_safety/gbiz-id.pdf' target="_blank" rel="nofollow noopener noreferrer">
              経済産業省「GBizIDの取得について」
            </a><br />
          </p>
          </>

          <>
          <div id='5' className="media_text_title">
            <h2>5.省力化補助金のスケジュール</h2>
          </div>
          <p className="media_text_text">
            <br />
            令和5年度の補正予算として省力化補助金が閣議決定されているため、令和6年度に公募が開始される見込みとなっております。2024年1月時点ではカタログの公開もされていないため、補助金の公募開始は早くとも2024年4月以降になるでしょう。<br />
            <br />
            他の補助金を見てみると第1回は比較的採択率が高くなっていることが多いため、現在設備導入を検討している事業者は、初回の申請に間に合わせられるよう、迅速な対応ができる体制を整えておくことをお勧めします。<br />
          </p>
          </>

          <>
          <div id='5' className="media_text_title">
            <h2>5.業界別活用イメージ</h2>
          </div>
          <p className="media_text_text">
            <br />
            西村元経産省大臣が公開したイメージをもとに、業界別の省力化補助金活用事例をお伝えします。なお、本記事で記載した製品が必ずカタログに掲載される訳ではないため、参考までにご覧ください。<br />
          </p>
          <h3 className='media_text_text_title'>製造業</h3><br />
          <p className="media_text_text">
            <img src={Images4} className="media_title_img" alt='製造ラインのイメージ' />
            <br />
            製造ラインに自動監視カメラや判別システムを導入することで、製品検査の自動化と品質の向上を実現します。<br />
            <br />
            【製造業者におすすめの製品】<br />
            ・産業用ロボット<br />
            ・センサー付き製品<br />
          </p>
          <h3 className='media_text_text_title'>建設業</h3><br />
          <p className="media_text_text">
            <img src={Images5} className="media_title_img" alt='建設業のイメージ' />
            <br />
            施工時にドローンを導入することで、測量の自動化や施工管理の品質向上を実現します。<br />
            <br />
            【建設業者におすすめの製品】<br />
            ・産業用ドローン<br />
            ・IoT重機<br />
          </p>
          <h3 className='media_text_text_title'>卸売業、小売業</h3><br />
          <p className="media_text_text">
            <img src={Images6} className="media_title_img" alt='建設業のイメージ' />
            <br />
            自動配送ロボットの導入により、在庫管理の省力化を実現します。<br />
            <br />
            【建設業者におすすめの製品】<br />
            ・無人搬送ロボット<br />
            ・3次元ピッキングシステム<br />
          </p>
          </>

          <>
          <div id='6' className="media_text_title">
            <h2>6.省力化補助金のカタログ掲載方法(設備メーカー・ベンダー向け)</h2>
          </div>
          <p className="media_text_text">
            <br />
            省力化補助金のカタログに製品を掲載する方法は現在公開されていませんが、制度が類似しているIT導入補助金のようになると想定されています。現在の情報から想定されるカタログの掲載方法を下記に記載いたしますので、ご参考ください。<br />
            <br />
            <strong>・掲載までの流れ</strong><br />
            ①省力化が期待される機能を有する設備をリストアップする<br />
            ②メーカーとして、事業者情報を登録する<br />
            ③リストアップを行った設備の情報を登録する<br />
            <br />
            省力化補助金を使用することを前提として購入を検討する事業者も多く出てくると考えられるため、カタログに掲載することで製品販売数の増加が見込まれます。2024年1～2月ごろに詳細な情報が発表される見込みになっているため、早めの情報収集を心がけましょう。<br />
          </p>
          </>
          
          <>
          <div id='8' className="media_text_title">
            <h2>8.省力化補助金の特徴(まとめ)</h2>
          </div>
          <h3 className='media_text_text_title'>カタログから製品を選択する</h3><br />
          <p className="media_text_text">
            従来の補助金は投資内容や導入設備を選ぶことが出来ましたが、省力化補助金ではカタログに掲載された製品のみが補助金の対象となります。そのため、購入したい設備が対象となっているかをしっかり確認するようにしましょう。<br />
          </p>
          <h3 className='media_text_text_title'>人手不足の解消を目的としている</h3><br />
          <p className="media_text_text">
            省力化補助金では、人手不足を解消する製品を導入することによる、即効性のある生産性の向上を目標としています。そのため、事業計画書では人員の削減や、作業効率の大幅向上といった計画を策定することが求められます。生産性向上が目的だということを意識した申請を行いましょう。<br />
          </p>
          <h3 className='media_text_text_title'>メーカーやベンダーとの連携が必要</h3><br />
          <p className="media_text_text">
            省力化補助金と制度が類似しているIT導入補助金では、ベンダー側に補助金採択後もフォローを行うことを求めています。省力化補助金も同様に、設備導入や効果測定をメーカーやベンダーと協力して行うことが見込まれているため、設備の導入希望がある場合は、必ずメーカーに連絡したうえで、補助金申請を行いましょう。<br />
          </p>
          </>
          
          <>
          <div id='8' className="media_text_title">
            <h2>8.最後に</h2>
          </div>
          <p className="media_text_text">
            <br />
            当社では、ものづくり補助金やIT導入補助金の支援実績を活かし、事業者様向けの省力化補助金の申請サポートや、メーカー・ベンダー様向けのカタログ掲載サポートを行っております。申請に関する相談や補助金情報のご共有は完全無料で行っておりますので、申請意思やカタログ掲載希望がある事業者様は、是非ご連絡くださいませ。<br />
            <br />
            大変ありがたいことに多くの事業者様からご連絡をいただいており、導入製品によっては既存提携先の製品と競合になり、お断りさせていただく場合もございますので、お早めにご連絡いただけますと幸いです。<br />
            <br />
            省力化補助金のみならず、補助金の活用を通じて貴社の生産性向上・販路拡大に全力でサポートいたしますので、下記フォームよりお気軽にご連絡くださいませ。<br />
          </p>
          </>
          
        </div>
        
        <Topic />
      </div>

      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
