import './styles.css';
import Recruit1 from './../../../image/homepage/recruit/1.png';
import Recruit2 from './../../../image/homepage/recruit/2.png';
import Recruit3 from './../../../image/homepage/recruit/3.png';

const Footer = () => {
  
  return (
    <>
    <div className='test_homeimage_recruit'>
      <div className='test_homeimage_recruit_title'>
        <p>採用</p>
        <h4>recruit</h4>
      </div>
      <div className='test_homeimage_recruit_items'>
        <img src={Recruit1} className='test_homeimage_recruit_items_image'  alt='トランク合同会社' />
        <div className='test_homeimage_recruit_items_text'>
          <h4>営業職</h4>
          <p>当社が展開しているコンサルティング、サービスに関して、事業者様との連絡業務、チームメンバーのマネジメント、マーケティング業務などをご担当いただける方を募集しています。</p>
          <a href='https://www.wantedly.com/projects/1541504' rel="nofollow noopener noreferrer">
            <button style={{backgroundColor : '#479D58'}}>詳しく見る</button>
          </a>
        </div>
      </div>
      <div className='test_homeimage_recruit_items'>
        <img src={Recruit2} className='test_homeimage_recruit_items_image'  alt='トランク合同会社' />
        <div className='test_homeimage_recruit_items_text'>
          <h4>プロジェクトマネージャー</h4>
          <p>当社にご依頼いただいた案件を管理すると共に、進捗管理や工数管理を行っていただける方を募集しています。未経験でもスキルアップを目指している方はぜひご応募ください。</p>
          <a href='https://www.wantedly.com/projects/1540581' rel="nofollow noopener noreferrer">
            <button style={{backgroundColor : '#2E81B0'}}>詳しく見る</button>
          </a>
        </div>
      </div>
      <div className='test_homeimage_recruit_items'>
        <img src={Recruit3} className='test_homeimage_recruit_items_image' alt='トランク合同会社' />
        <div className='test_homeimage_recruit_items_text'>
          <h4>インターン生</h4>
          <p>新規事業における企画・営業職をお任せできる方を募集しています。現在の課題を見つけ施策を考え実際に動くまで、幅広い範囲をお願いするポジションができる方を募集します。</p>
          <a href='https://www.wantedly.com/projects/1541343' rel="nofollow noopener noreferrer">
            <button style={{backgroundColor : '#DA3E35'}}>詳しく見る</button>
          </a>
        </div>
      </div>
    </div>
    </>
  );
}

export default Footer;
