import './styles.css';
import Subsidy1 from './../../../../image/help/subsidy1_2.png';
import Subsidy2 from './../../../../image/help/subsidy2_2.png';
import Subsidy3 from './../../../../image/help/subsidy3_2.png';
import Subsidy4 from './../../../../image/help/subsidy4_2.png';

const Help = () => {

  return (
    <>
    <div className='help_subsidy_title'>
      <h4>対応可能な補助金</h4>
      <a href='https://trankllc.com/contact'>
        <div>補助金に関する相談</div>
      </a>
    </div>
    <div className='help_subsidy'>
      <div>
        <img src={Subsidy1} alt='' />
        <h5>事業再構築補助金</h5>
        <p>ポストコロナに対応するための新たな事業や販売方法を画策している企業への補助金です。</p>
        <div>
          <p>対象経費</p>
          <div></div>
        </div>
        <p>建物費、機械装置・システム構築費、クラウドサービス利用費、外注費、広告宣伝等</p>
        {/* <h6>VIEW MORE</h6> */}
      </div>
      <div>
        <img src={Subsidy2} alt='' />
        <h5>ものづくり補助金</h5>
        <p>継続して安定的な売上を立てる事を目的として、サービス/製品を開発するための補助金です。</p>
        <div>
          <p>対象経費</p>
          <div></div>
        </div>
        <p>機械装置・システム構築費、技術導入費、クラウドサービス利用費、外注費等</p>
        {/* <h6>VIEW MORE</h6> */}
      </div>
      <div>
        <img src={Subsidy3} alt='' />
        <h5>小規模事業者補助金</h5>
        <p>販路開拓や生産性向上に使用する経費の一部を国が負担し、事業を支援するためのものです。</p>
        <div>
          <p>対象経費</p>
          <div></div>
        </div>
        <p>機械装置等費、広報費、ウェブサイト関連費、展示会等出展費開発費等</p>
        {/* <h6>VIEW MORE</h6> */}
      </div>
      <div>
        <img src={Subsidy4} alt='' />
        <h5>IT導入補助金</h5>
        <p>ITツールを導入する経費の一部を補助することで、業務効率化・売上アップをサポートする補助金です。</p>
        <div>
          <p>対象経費</p>
          <div></div>
        </div>
        <p>ソフトウェア費、導入関連費</p>
        {/* <h6>VIEW MORE</h6> */}
      </div>
    </div>
    </>
  );
}

export default Help;
