// all：https://api.jgrants-portal.go.jp/exp/v1/public/subsidies?keyword=%E8%A3%9C%E5%8A%A9%E9%87%91&sort=created_date&order=DESC&acceptance=0
// item：https://api.jgrants-portal.go.jp/exp/v1/public/subsidies/id/a0W5h00000MyJ39EAF,

import DocumentMeta from 'react-document-meta';
import './styles.css';
import Footer from '../../../../tabs/footer/index';
import { useLayoutEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { Link } from 'react-router-dom';

import Image1 from "./../../../../../image/media/business/1/top_mini.jpeg";
import Image2 from "./../../../../../image/media/business/2/top_mini.jpeg";
import Image3 from "./../../../../../image/media/business/3/top_mini.jpeg";
import Image5 from "./../../../../../image/media/business/5/top_mini.jpeg";
import Image6 from "./../../../../../image/media/business/6/top_mini.jpeg";
import Image23 from "./../../../../../image/media/business/23/top_mini.jpeg";
import Image24 from "./../../../../../image/media/business/24/top_mini.jpeg";

const BlogText0001 = () => {

  const [ title, setTitle ] = useState();
  const [ detailPurpose, setDetailPurpose ] = useState();
  const [ recommend, setRecommend ] = useState();
  const [ start, setStart ] = useState("");
  const [ end, setEnd ] = useState("");
  const [ url, setUrl ] = useState();
  const [ industry, setIndustry ] = useState();
  const [ area, settarArea ] = useState();
  const [ rate, settarRate ] = useState();
  const [ limit, settarLimit ] = useState();

  useLayoutEffect(() => {
    var subsidyId = new URL(window.location).href.slice(new URL(window.location).href.indexOf('id=') + 3, new URL(window.location).href.length);

    firebase.firestore().collection("article").doc(subsidyId).get()
    .then((doc) => {
      setDetailPurpose(doc.data().purpose);
      setTitle(doc.data().title);
      setRecommend(doc.data().recommend);
      setStart(doc.data().start);
      setEnd(doc.data().end);
      setUrl(doc.data().url);
      setIndustry(doc.data().industry);
      settarArea(doc.data().area);
      settarRate(doc.data().rate);
      settarLimit(doc.data().limit);
    });
  }, []);

  const meta = {
    title: `${area}の補助金 | ${title}`,
    description: `この記事では、${title}という補助金について、解説しています。この補助金は、${industry}の業種向けとなっており、${recommend}の使用用途がおすすめとなっています。運営：トランク合同会社`,
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  // https://anypoint.mulesoft.com/exchange/portals/meti/497c75e7-5d6a-48b3-a39b-4105316f61f0/exp-public/minor/1.1/console/method/%23710/

  // const initialURL = "https://api.jgrants-portal.go.jp/exp/v1/public/subsidies/id/a0W5h00000MyJ39EAF",
  // const initialURL = "https://api.jgrants-portal.go.jp/exp/v1/public/subsidies?keyword=小規模&sort=created_date&order=DESC&acceptance=0";
  // const initialURL = "https://api.jgrants-portal.go.jp/exp/v1/public/subsidies?keyword=小規模&sort=created_date&order=DESC&acceptance=0&use_purpose=新たな事業を行いたい&target_number_of_employees=20名以下&target_area_search=全国";
  
  // var list = [
  //   "a0W2x000007CqlqEAC",
  //   "a0W2x000007CqlREAS",
  //   "a0W2x000007CqlCEAS",
  //   "a0W2x000007CqkYEAS",
  //   "a0W2x000007CqicEAC",
  //   "a0W2x000007CqcKEAS",
  //   "a0W2x000007CqcFEAS",
  //   "a0W2x000007CqZEEA0",
  //   "a0W2x000007CqVBEA0",
  //   "a0W2x000007CqU3EAK",
  //   "a0W2x000007CqREEA0",
  //   "a0W2x000007CqOjEAK",
  //   "a0W2x000007CqOKEA0",
  //   "a0W2x000007CqICEA0",
  //   "a0W2x000007CqFXEA0",
  //   "a0W2x000007CqEAEA0",
  //   "a0W2x000007CqE5EAK",
  //   "a0W2x000007CqDvEAK",
  //   "a0W2x000007CqDlEAK",
  //   "a0W2x000007CqDbEAK",
  //   "a0W2x000007CqDWEA0",
  //   "a0W2x000007CqDREA0",
  //   "a0W2x000007CqBkEAK",
  //   "a0W2x000007CqBfEAK",
  //   "a0W2x000007Ck7IEAS",
  //   "a0W2x000007CftPEAS",
  // ]

  // var count = 0;

  // function test() {
  //   firebase.firestore().collection("article").get()
  //   .then(snapShot => {
  //     snapShot.forEach((doc) => {
  //       console.log(doc.id);
  //       console.log(doc.data().title);
  //     });
  //   });
  // }
  
  return (
    <>
    <DocumentMeta {...meta}>
      <div className='api_header_bar'></div>
      <div className='api'>
        <div className='api_header_top'>
          <h2>補助金の教科書サイト</h2>
          <div>
            <Link to='/media/subsidy/business/0000'>
            <button style={{backgroundColor : '#CA2E78'}} className="api_header_top_500">記事一覧</button>
            </Link>
            <Link to='/company'>
            <button style={{backgroundColor : '#489545'}} className="api_header_top_500">会社概要</button>
            </Link>
            <Link to='/contact'>
              <button style={{backgroundColor : '#D6452B'}}>お問い合わせ</button>
            </Link>
          </div>
        </div>
        <div className='api_header_left'>
          <div></div>
          <h3>補助金に関する情報を分かりやすく伝える</h3>
        </div>
        <div className='api_media'>
          <h2>{title}</h2>
          <div>
            <p style={{backgroundColor : '#CA2E78'}}>1</p>
            <h3 style={{color : "#CA2E78"}}>この補助金の目的・概要</h3>
          </div>
          <p>
            {detailPurpose}</p>
          <div>
            <p style={{backgroundColor : '#D6452B'}}>2</p>
            <h3 style={{color : "#D6452B"}}>この補助金の詳細</h3>
          </div>
          <table className='api_media_table'>
            <tbody>
              <tr>
                <td className='api_media_q'><p>公募開始</p></td>
                <td className='api_media_a'><p>{start.slice(0, 4)}年{start.slice(5, 7)}月{start.slice(8, 10)}日</p></td>
              </tr>
              <tr>
                <td className='api_media_q'><p>公募終了</p></td>
                <td className='api_media_a'><p>{end.slice(0, 4)}年{end.slice(5, 7)}月{end.slice(8, 10)}日</p></td>
              </tr>
              <tr>
                <td className='api_media_q'><p>補助率</p></td>
                <td className='api_media_a'><p>{rate}</p></td>
              </tr>
              <tr>
                <td className='api_media_q'><p>補助上限</p></td>
                <td className='api_media_a'><p>{limit}</p></td>
              </tr>
              <tr>
                <td className='api_media_q'><p>地域</p></td>
                <td className='api_media_a'><p>{area}</p></td>
              </tr>
            </tbody>
          </table>
          <div>
            <p style={{backgroundColor : '#489545'}}>3</p>
            <h3 style={{color : "#489545"}}>こんな方におすすめ</h3>
          </div>
          <p>{recommend}</p>
          <div>
            <p style={{backgroundColor : '#3975EA'}}>4</p>
            <h3 style={{color : "#3975EA"}}>応募資格</h3>
          </div>
          <p>{industry}</p>
          <a href={url}><button>補助金を確認する</button></a>
        </div>
        <h4 className='api_media_blog'>事業再構築補助金 解説</h4>
        <div className='business_media_box'>
          <a href='https://trankllc.com/media/subsidy/business/0001'>
            <img src={Image1} alt='' />
            <h3>事業再構築補助金とは？ 申請に必要な基礎知識を徹底解説</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0002'>
            <img src={Image2} alt='' />
            <h3>事業再構築補助金 5分で対象者の条件・要件を理解する！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0003'>
            <img src={Image3} alt='' />
            <h3>事業再構築補助金 12個の経費分類を具体例つきで解説！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0004'>
            <img src={Image3} alt='' />
            <h3>事業再構築補助金 申請枠・業種ごとに採択率を徹底分析！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0005'>
            <img src={Image5} alt='' />
            <h3>事業再構築補助金 提出書類の用意を３日で終わらせる方法！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0006'>
            <img src={Image6} alt='' />
            <h3>事業再構築補助金 実績報告と交付申請の概要を10分で理解！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0023'>
            <img src={Image23} alt='' />
            <h3>事業再構築補助金 交付申請の流れは、全部で５つ！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0024'>
            <img src={Image24} alt='' />
            <h3>事業再構築補助金 実績報告は、事前準備が1番大切！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0000'><p></p></a>
        </div>
      </div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
