// https://www.kkj.go.jp/doc/ja/api_guide.pdf
// https://www.kkj.go.jp/api/?Query=%E3%82%BB%E3%83%9F%E3%83%8A%E3%83%BC

import './styles.css';
import DocumentMeta from 'react-document-meta';
import Nav from './../items/nav/index';
import Image from './../../../../../image/bid/strong/2.png';
import Search from './../items/search/index';
import Footer from './../items/footer/index';
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { useHistory } from 'react-router-dom';

const BlogText0001 = () => {

  const history = useHistory();

  const [ list, setList ] = useState([]);
  const [ nowLink, setnowLink ] = useState([]);
  const [ page, setPage ] = useState(0);
  
  const [ pre, setPre ] = useState('');
  const [ word, setWord ] = useState('');

  const meta = {
    title: `${pre === '' ? '' : `${pre} |`}${word === '' ? '' : `${word} |`} 入札情報まとめサイト`,
    description: 'この記事は、国の案件をお探しの方へ向けて、国、地方等の入札情報をまとめているサイトです。掲載されている入札情報は、全部で数千件を超えており、日本でも有数の公官需専門サイトです。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  useEffect(() => {

    console.log('thispage');

    var startDate = new Date('2000-1-1');
    var endDate = new Date();

    var link = new URL(window.location).href;
    setnowLink(new URL(window.location).href);

    var keyWord = decodeURIComponent(link.slice(link.indexOf('keyword=') + 8, link.indexOf('+date'))).split(',');
    var date = link.slice(link.indexOf('date=') + 5, link.indexOf('+prefecture')).split(',');
    var prefecture = decodeURIComponent(link.slice(link.indexOf('prefecture=') + 11, link.indexOf('+work'))).split(',');
    var work = decodeURIComponent(link.slice(link.indexOf('work=') + 5, link.indexOf('+format'))).split(',');
    var format = decodeURIComponent(link.slice(link.indexOf('format=') + 7, link.indexOf('+page')));
    setPage(link.slice(link.indexOf('page=') + 5, link.length));
    setPre(prefecture);

    var makeKeyWord = [ ...keyWord, ...work ];
    makeKeyWord.splice(makeKeyWord.indexOf(''), 1);
    setWord(makeKeyWord);

    if (date[0] !== "") {
      startDate = new Date(date[0]);
    }

    if (date[1] !== "") {
      endDate = new Date(date[1]);
    }

    const isAllIncludes = (arr, target) => arr.some(el => target.includes(el));

    if (prefecture[0] === "") {

      // 都道府県：無し
      console.log('なし');
      firebase.firestore().collection("bid").orderBy("Date", 'asc').startAt(startDate).endAt(endDate)
      .get().then(snapShot => {
        var tmp = [];
        snapShot.forEach((doc) => {
          if (makeKeyWord !== [] && doc.data().ProjectName !== undefined) {
            if (isAllIncludes(makeKeyWord, doc.data().ProjectName)) {
              if (format === '') {
                tmp.push(doc.data());
              } else if (format === doc.data().ProcedureType) {
                tmp.push(doc.data());
              }
            }
          } else {
            if (format === '') {
              tmp.push(doc.data());
            } else if (format === doc.data().ProcedureType) {
              tmp.push(doc.data());
            }
          }
        });
        setList(tmp);
      });
    } else {
      // 都道府県：有り
      firebase.firestore().collection("bid")
      .where('PrefectureName', 'in', prefecture)
      .orderBy("Date", 'asc').startAt(startDate).endAt(endDate)
      .get().then(snapShot => {
        var tmp = [];
        snapShot.forEach((doc) => {
          if (makeKeyWord !== [] && doc.data().ProjectName !== undefined) {
            if (isAllIncludes(makeKeyWord, doc.data().ProjectName)) {
              if (format === '') {
                tmp.push(doc.data());
              } else if (format === doc.data().ProcedureType) {
                tmp.push(doc.data());
              }
            }
          } else {
            if (format === '') {
              tmp.push(doc.data());
            } else if (format === doc.data().ProcedureType) {
              tmp.push(doc.data());
            }
          }
        });
        setList(tmp);
      });
    }
  }, []);

  function nextPage(v) {
    var nextLink = decodeURIComponent(nowLink.slice(nowLink.indexOf('/bid'), nowLink.indexOf('+page=') + 6));
    history.push(`${nextLink + v}`);
    window.location.reload();
  }

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <Search />
      <div className='bid_list_background'>
        <div className='bid_list_search_terms'>
          <h5>{list.length}件 ヒット</h5>
          <h4>{page * 20 + 1}件目~{page * 20 + 20}件目</h4>
        </div>
        {list.map((data, index) =>
          <>
            {page * 20 <= index && index < page * 20 + 20 ? 
              <a href={`http://trankllc.com/bid/article/id=${data.Key.slice(10, data.Key.length - 4)}`}>
                <div className='bid_list_case' key={index}>
                  <img src={Image} alt='' />
                  <div>
                    <p className='bid_list_case_date'>{data.OpeningTendersEvent === '' ? '公告取得日時：-' : `公告取得日時：${data.OpeningTendersEvent.slice(0, 4)}年${data.OpeningTendersEvent.slice(5, 7)}月${data.OpeningTendersEvent.slice(8, 10)}日`}</p>
                    <h3>{data.ProjectName}</h3>
                    <div>
                      <p>
                        {data.PrefectureName === '' ? '' : `場所：${data.PrefectureName}　`}
                        {data.OrganizationName === '' ? '' : `募集機関：${data.OrganizationName}　`}
                        {data.Certification === '' ? '' : `入札資格：${data.Certification}　`}
                        {data.CftIssueDate === '' ? '' : `公告日：${data.CftIssueDate.slice(0, 4)}年${data.CftIssueDate.slice(5, 7)}月${data.CftIssueDate.slice(8, 10)}日　`}
                        {data.Category === '' ? '' : `カテゴリー：${data.Category}　`}
                        {data.ProcedureType === '' ? '' : `入札形式：${data.ProcedureType}　`}
                        {data.PeriodEndTime === '' ? '' : `納入期日：${data.PeriodEndTime}　`}
                        {data.Location === '' ? '' : `履行場所：${data.Location}　`}
                      </p>
                    </div>
                  </div>
                  <h4>＞</h4>
                </div>
              </a>
              : <></>
            }
          </>
        )}
        <div className='bid_list_number'>
          <div style={page * 1 === 0 ? {border : '1px solid #EBEBEB', color: '#EBEBEB'} : {}} 
            onClick={() => page * 1 === 0 ? '' : nextPage(page * 1 - 1)}>前</div>
          <div style={{backgroundColor : '#D70A0A', color: 'white'}} onClick={() => nextPage(page * 1 + 1) }>{page * 1 + 1}</div>
          <div style={list.length / 20 < page * 1 + 1 ? {border : '1px solid #EBEBEB', color: '#EBEBEB'} : {}} 
            onClick={() => list.length / 20 < page * 1 + 1 ? '' : nextPage(page * 1 + 1)}>次</div>
        </div>
      </div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
