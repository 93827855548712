import './styles.css';
import image2 from './../../../../../image/adbusiness/what1.png';
import image3 from './../../../../../image/adbusiness/what2.png';

const Help = () => {
  
  return (
    <>
    <div className='AdBusiness_what_box2'>
      <div>
        <h4>事業再構築補助金の対象となる経費</h4>
        <h3>事業に関わる様々な経費を計上することが出来る</h3>
        <img src={image2} alt='' />
      </div>
    </div>
    <div className='AdBusiness_what_box2'>
      <div>
        <h4>申請されたお客さまの事例</h4>
        <h3>不動産業からコワーキングスペース事業への転換</h3>
        <img src={image3} alt='' />
      </div>
    </div>
    </>
  );
}

export default Help;
