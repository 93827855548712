import './styles.css';
import Icon from "./../../../../image/home/icon.png";

const Home = () => {

  return (
    <>
    <div className="nav">
      <a href='https://trankllc.com/' target="_blank" rel="nofollow noopener noreferrer">
        <div className="nav_icon">
          <img src={Icon} alt='' />
          <p>trankllc</p>
        </div>
      </a>
      <div className="nav_text">
        <a href='https://trankllc.com/media/subsidy/business/0000' target="_blank" rel="nofollow noopener noreferrer">
          <p>ブログ</p>
        </a>
        <a href='https://trankllc.com/company' target="_blank" rel="nofollow noopener noreferrer">
          <p>会社概要</p>
        </a>
        <a href='https://trankllc.com/contact' target="_blank" rel="nofollow noopener noreferrer">
          <p>問い合わせ</p>
        </a>
      </div>
    </div>
    </>
  );
}

export default Home;