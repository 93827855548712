const BlogText0001 = (props) => {

  return(
  <>
  <div className='management_review_content_1'>
    <h4>財務に関する質問（2問）</h4>
    <div>
      <h5>Q1.自己資本額（数字）</h5>
      <p>自己資本の額（＝純資産合計の額）又は平均自己資本額（2 期平均）をご記載ください。</p>
      <input onChange={(e) => props.setNetWorth(e.target.value)} placeholder="100000000" type="number" />
    </div>
    <div>
      <h5>Q2.平均利益額（数字）</h5>
      <p>利払前税引前償却前利益（営業利益＋減価償却実施額）の 2 年平均の額をご記載ください。</p>
      <input onChange={(e) => props.setAverageProfitResult(e.target.value)} placeholder="100000000" type="number" />
    </div>
  </div>
  </>
  );
}

export default BlogText0001;
