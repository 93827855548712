import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import TopImage from "./../../../../../image/media/small/1/top.png";
import Image1 from "./../../../../../image/media/small/1/1.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
// import { Link } from 'react-router-dom';

const BlogText0001 = () => {

  const meta = {
    title: '【初めての補助金】小規模事業者持続化補助金をわかりやすく解説',
    description: '本記事は、小規模事業者持続化補助金を申請したいと思っている事業者さまへ向けて、小規模事業者持続化補助金の概要をまとめさせていただきました。自分で補助金を申請しようと思ったけど、「やっぱり難しい」という方は、是非ご参考にしていただければと思います。本サイトでは、概要ページと詳細ページを用意しておりますので、概要をご確認いただいたのちに、詳細ページもあわせてご確認いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/9/20 更新日: 2022/9/20</p>
          <h1 className="media_title">【初めての補助金】<br />小規模事業者持続化補助金とは？を徹底解説</h1>
          <img src={TopImage} className="media_title_img" alt='小規模事業者持続化補助金' />

          <p className="media_text_text">
            <br />
            <br />
            <span className='media_text_text_box_title'>こんな方へ向けて</span><br />
            <br />
            本記事は、小規模事業者持続化補助金を申請したいと思っている事業者さまへ向けて、小規模事業者持続化補助金の概要をまとめさせていただきました。<br />
            <br />
            自分で補助金を申請しようと思ったけど、「やっぱり難しい」という方は、是非ご参考にしていただければと思います。<br />
            <br />
            <strong>point!</strong><br />
            本サイトでは、概要ページと詳細ページを用意しておりますので、概要をご確認いただいたのちに、詳細ページもあわせてご確認いただければと思います。<br />
            <br />
            それでは、解説に進ませていただきます！<br />
          </p>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">補助金とは？</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">補助金の目的</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金の特徴</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#1" offset="100">小規模事業者持続化補助金とは？</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">申請から給付までの全体スケジュール</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">具体的な申請の流れ</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">対象事業者の確認</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">類型の確認</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">対象になる補助金経費</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">小規模事業者持続化補助金の採択率</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id="1" className="media_text_title">
            <h2>１.補助金とは？</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            補助金申請を検討されている方でも、「<strong>補助金についてあまり理解してない。</strong>」という方がよくいらっしゃいます。<br />
            <br />
            そこで、小規模事業者持続化補助金をみていく前に、まずは「<strong>補助金とは何か？</strong>」について、少し触れさせてください。<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>補助金の目的</h3><br />
          <p className="media_text_text">
            補助金とは、<strong>市場経済をより良くする事業に対して、国が経費の一部を支援する国の施策</strong>です。<br />
            ここで、理解していただきたい点は、"目的がある"という点です。<br />
            <br />
            一見聞くと、当たり前だと感じてしまいますが、この目的に促していない事業計画を申請される方を多く見受けられますので、覚えておいていただければと思います。<br />
            <br />
            ちなみに、具体例をあげると、<br />
            ・<strong>雇用を目的としている補助金なのに、雇用について一切触れていない</strong><br />
            ・<strong>生産性向上を目的としているのに、生産性向上について一切触れていない</strong><br />
            などの状況が過去にございました。<br />
            <br />
            この場合、いくら時間をかけた計画書でも不採択となってしまいます。<br />
            ですので、このような初歩的な部分の認識を間違えないよう、補助金そのものについて、理解を深めましょう。<br />
          </p>
          <h3 className='media_text_text_title'>補助金のよくある質問</h3><br />
          <p className="media_text_text">
            とはいえ、上記の目的が分かっても疑問が沢山残っていると思います。そこで、よくある質問をQ{'&'}A形式でご紹介させていただきます。<br />
            <br />
            <strong>Q.補助金は給付されてから、返済をする必要はありますか？</strong><br />
            補助金に返済義務は一切ございません。もし、事業を失敗されたとしても返済は不要ですのでご安心ください。<br />
            ※実施事業の際に要件に違反した場合は、補助対象外（補助金が降りない）となる場合がございます。<br />
            <br />
            <strong>Q.補助金ってどんなことができるの？</strong><br />
            補助金は、小規模事業者持続化補助金以外にも、大型の設備機器を支援してもらえる補助金や数十万円単位の広告配信に使用される補助金など、さまざまなものがございます。<br />
            <br />
            <strong>Q.補助金は申請するともらえるものですか？</strong><br />
            補助金には審査があるため、100%採択されるという事はありません。ちなみに、助成金の場合は、条件をクリアすると確実に支給されます。<br />
            <br />
            <strong>Q.採択後の報告業務等はありますか？</strong><br />
            補助金は、採択されて計画を実施したのちに振込がされる形式となっております。そのため、実施報告（実施した内容を報告）を行う必要があります。<br />
            <br />
            <strong>Q.補助金はいつ振り込まれますか？</strong><br />
            上記でご説明させていただきました通り、実施報告ののちに振り込まれる形式のため、補助金は完全後払いとなっております。<br />
            <br />
            以上、補助金のQ{'&'}Aになります。<br />
            補助金の概要が少しでもわかっていただけましたら、大変嬉しく思います！<br />
            <br />
            では、ここから小規模事業者持続化補助金について、詳しくご説明させていただきます！<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>２.小規模事業者持続化補助金とは？</h2>
          </div>
          <p className="media_text_text">
            <br />
            では、上記で説明させていただきました目的について、確認していきましょう。<br />
          </p>
          <h3 className='media_text_text_title'>小規模事業者持続化補助金の目的</h3><br />
          <p className="media_text_text">
            小規模事業者持続化補助金は、<strong>小規模事業者等が取り組む販路開拓等の取組の経費の一部を補助することにより、地域の雇用や産業を支える小規模事業者等の生産性向上と持続的発展を図ることを目的</strong>としています。<br />
            <br />
            上記に加えて、小規模事業者持続化補助金の特徴を３点あげさせていただきます。<br />
            ①<strong>50万円~200万円前後の補助があること</strong><br />
            ②<strong>小規模の方も補助されやすいこと</strong><br />
            ③<strong>対象経費が幅広く、いろいろな項目に適用できること</strong><br />
            <br />
            下記に公募要領の引用文を記載させていただきましたので、ご参照いただければと思います。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>小規模事業者持続化補助金の目的</span><br />
            小規模事業者および一定要件を満たす特定非営利活動法人（以下「小規模事業者等」という。）が今後複数年にわたり相次いで直面する制度変更（働き方改革や被用者保険の適用拡大、賃上げ、インボイス制度の導入等）等に対応するため、小規模事業者等が取り組む販路開拓等の取組の経費の一部を補助することにより、地域の雇用や産業を支える小規模事業者等の生産性向上と持続的発展を図ることを目的とします。<br />
            本補助金事業は、小規模事業者自らが作成した持続的な経営に向けた経営計画に基づく、地道な販路開拓等の取組（例：新たな市場への参入に向けた売り方の工夫や新たな顧客層の獲得に向けた商品の改良・開発等）や、地道な販路開拓等と併せて行う業務効率化（生産性向上）の取組を支援するため、それに要する経費の一部を補助するものです。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <br />
          <h3 className='media_text_text_title'>補助金のよくある質問</h3><br />
          <p className="media_text_text">
            とはいえ、上記の補助金のQ{'&'}Aと同様に疑問が沢山残っていると思いますので、よくある質問をQ{'&'}A形式でご紹介させていただきます。<br />
            <br />
            <strong>Q.小規模事業者持続化補助金の実施期間は、どのくらいありますか？</strong><br />
            実施期間は、約1年とされております。<br />
            <br />
            <strong>Q.小規模事業者持続化補助金は、運用コストを費用計上できますか？</strong><br />
            補助金の実施期間中に使用する費用は、計上することができます。<br />
            <br />
            <strong>Q.小規模事業者持続化補助金は、実施した事業も申請できますか？</strong><br />
            事業を既に行なってしまった事業者は、申請することができません。<br />
            <br />
            <strong>Q.採択後の報告業務等はありますか？</strong><br />
            補助金は採択後の実施期間ののち、使用した経費を事務局へ申請しなければいけません。<br />
            <br />
            <strong>Q.小規模事業者持続化補助金はいつ振り込まれますか？</strong><br />
            実施事業後の報告~請求~振込となります。そのため、自己資金、融資等で一時的に資金を補填する必要があります。<br />
            <br />
            以上、小規模事業者持続化補助金のQ{'&'}Aになります。<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>３.申請から給付までの全体スケジュール</h2>
          </div>
          <p className="media_text_text">
            <br />
            では、次に小規模事業者持続化補助金の申請から給付までのスケジュールについて、ご説明させていただきます。<br />
            <br />
            補助金の全体スケジュールは、とても長く1年以上にもなることもあるため、頭の片隅に全体のスケジュールを入れておくと申請から交付、振込完了まで余裕を持って行動することができます。<br />
            <br />
            さらに、今何をするべきなのかを把握する必要があるため、必要要素となっております。<br />
            <br />
            ①<strong>公募</strong><br />
            公募発表から申請までの準備期間となります。<br />
            ↓<br />
            ②<strong>申請（電子申請）</strong><br />
            用意した資料を申請期日までに電子上で申請します。<br />
            ↓<br />
            ③<strong>採択通知</strong><br />
            申請した内容が開示され、採択・不採択を判断します。<br />
            ↓<br />
            ④<strong>交付申請</strong><br />
            必要な費用の見積もり書類等をまとめ、補助金額の決定を行います。<br />
            ↓<br />
            ⑤<strong>補助事業実施期間</strong><br />
            申請した事業計画を実際に実施する期間となります。<br />
            ↓<br />
            ⑥<strong>確定検査（交付額の確定）</strong><br />
            申請し費用が計上されるかを判断される検査となります。<br />
            ↓<br />
            ⑦<strong>補助金の請求</strong><br />
            補助金事務局に対して、請求を行います。
            ↓<br />
            ⑧<strong>補助金の支払</strong><br />
            補助金の請求から1ヶ月前後で振込が完了します。<br />
          </p>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>４.具体的な申請の流れ</h2>
          </div>
          <p className="media_text_text">
            <br />
            上記で全体スケジュールを説明させていただいた次は、小規模事業者持続化補助金に特化した申請までの流れを説明していきます。<br />
            <br />
            ①<strong>目的の確認</strong><br />
            小規模事業者持続化補助金の目的を確認し、適正及び補助金の方向性を確認します。<br />
            ↓<br />
            ②<strong>対象者の確認</strong><br />
            小規模事業者持続化補助金の申請が可能なのかを判断します。<br />
            ↓<br />
            ③<strong>類型の選定</strong><br />
            小規模事業者持続化補助金の6つに類型から1つ選択します。<br />
            ↓<br />
            ④<strong>類型の補助率の確認</strong><br />
            上記の類型選択ののち、補助率を確認します。<br />
            ↓<br />
            ⑤<strong>対象経費の確認</strong><br />
            対象経費も含めて、事業計画の実現性について検討します。<br />
            ↓<br />
            ⑥<strong>資料の確認</strong><br />
            必要書類の用意と加点書類の用意を進めていきます。<br />
            ↓<br />
            ⑦<strong>資料の作成・準備</strong><br />
            上記で必要書類を確認してから、作成に入ります。<br />
            ↓<br />
            ⑧<strong>申請</strong><br />
            電子上で、情報入力、資料の添付を行い申請を行います。<br />
          </p>
          </>

          <>
          <div id='5' className="media_text_title">
            <h2>５.対象事業者の確認</h2>
          </div>
          <p className="media_text_text">
            <br />
            全体の流れ、申請までの流れを確認した次は、対象事業者さまについて、ご説明させていただきます。<br />
            <br />
            対象事業者の確認（本項目）では、主な対象要件を４つあげさせていただきますので、自社の情報と見比べながら確認していただければと思います。<br />
            <br />
            なお、主な３４つではなく、すべての要件（詳細）は、以下のページにて記載がありますので、ご参照いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>小規模事業者であること</h3><br />
          <p className="media_text_text">
            小規模事業者である必要があり、具体的には以下のような通りとなっっております。<br />
            <br />
            業種ごとに従業員に関する上限が違う点も踏まえてご確認いただければと思います。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>業種</th><th>人数</th>
              </tr>
              <tr>
                <td><strong>商業・サービス業（宿泊業・娯楽業除く）</strong></td>
                <td>常時使用する従業員の数5人以下</td>
              </tr>
              <tr>
                <td><strong>サービス業のうち宿泊業・娯楽業</strong></td>
                <td>常時使用する従業員の数20人以下</td>
              </tr>
              <tr>
                <td><strong>製造業その他</strong></td>
                <td>常時使用する従業員の数20人以下</td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>出資会社に関する規定</h3><br />
          <p className="media_text_text">
            株の100%を出資金が５億円以上の法人に保有されていてはいけないというものです。<br />
            直接的、間接的の例は、以下に記載させていただきます。<br />
            <br />
            <img src={Image1} className='media_img' alt='' />
          </p>
          <h3 className='media_text_text_title'>課税所得に関する規定</h3><br />
          <p className="media_text_text">
            確定している（申告済みの）直近過去３年分の「各年」又は「各事業年度」の課税所得の年平均額が 15億円を超えていないこと。<br />
          </p>
          </>

          <>
          <div id='6' className="media_text_title">
            <h2>６.類型の確認</h2>
          </div>
          <p className="media_text_text">
            <br />
            対象事業者の確認が終わったところで、次は類型（枠）について説明していきます。<br />
            <br />
            具体的に類型とは、<strong>「通常枠」</strong>、<strong>「賃金引上げ枠」</strong>、<strong>「卒業枠」</strong>、<strong>「後継者支援枠」</strong>、<strong>「創業枠」</strong>及び<strong>「インボイス枠」</strong>の６つに分かれており、各枠ごとに要件、補助率、上限金額が細かく提示されています。<br />
            <br />
            要件の概要については、以下にてご説明させていただきます。<br />
          </p>
          <h3 className='media_text_text_title'>通常枠</h3><br />
          <p className="media_text_text">
            事業規模の拡大を目指す中小企業等のための枠となっており、具体的には。新分野展開や業態転換、事業・業種転換等の取組、事業再編の取組などが当てはまります。<br />
          </p>
          <h3 className='media_text_text_title'>賃金引上げ枠</h3><br />
          <p className="media_text_text">
            従業員の賃金引き上げ（地域別最低賃金より+30円以上）を行う事業者さまを対象とする枠となっております。<br />
          </p>
          <h3 className='media_text_text_title'>卒業枠</h3><br />
          <p className="media_text_text">
            事業の拡大に意欲的な事業者さまを対象とした枠で、本補助金により小規模事業者等の枠を超えることを求められます。<br />
          </p>
          <h3 className='media_text_text_title'>後継者支援枠</h3><br />
          <p className="media_text_text">
            事業継承を行う事業者さまを対象とする枠となっており、「アトツギ甲子園」のファイナリストになった事業者さまを対象としています。<br />
          </p>
          <h3 className='media_text_text_title'>創業枠</h3><br />
          <p className="media_text_text">
            認定連携創業支援等事業者が実施した特定創業支援等事業による支援を公募締切時から過去３か年の間に受け、かつ、過去３か年の間に開業した事業者を対象としたものです。<br />
          </p>
          <h3 className='media_text_text_title'>インボイス枠</h3><br />
          <p className="media_text_text">
            2021年9月30日から2023年9月30日の期間の内、一度でも免税事業者であった又は免税事業者であることが見込まれる事業者のうち、適格請求書発行事業者に登録した事業者が対象となります。<br />
          </p>
          </>

          <>
          <div id='7' className="media_text_title">
            <h2>７.対象になる補助金経費</h2>
          </div>
          <p className="media_text_text">
            <br />
            類型について確認した次は、対象経費についてご説明させていただきます。<br />
            <br />
            対象経費は、主に以下の11項目が補助経費となっております。軽く目を通していただけると分かる通り想像以上に幅広く経費が計上できる形となっております。<br />
            <br />
            申請を検討される方は、計上したい項目が補助対象になるのかも含めご確認いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>機械装置等費</h3><br />
          <p className="media_text_text">
            補助事業の遂行に必要な機械装置等が計上する項目となります。<br />
            <br />
            <strong>具体例</strong><br />
            レストランの集客力向上のためのベビーチェアや高齢者向けの設備のバリアフリー製品などが一般的です。<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>広報費</h3><br />
          <p className="media_text_text">
            パンフレットやポスター、その他広告媒体の活用に使用される必要を計上できます。<br />
            <br />
            <strong>具体例</strong><br />
            商品説明のためのチラシや郵送DMなどに使用される使用されることが一般的です。<br />
          </p>
          <h3 className='media_text_text_title'>ウェブサイト関連費</h3><br />
          <p className="media_text_text">
            販路拡大のためのwebサイト作成、ECサイト作成、構築を計上することができます。<br />
            <br />
            <strong>具体例</strong><br />
            インターネット広告やSEO対策、LP作成などを計上することが一般的です。<br />
          </p>
          <h3 className='media_text_text_title'>展示会等出展費（オンラインによる展示会・商談会等を含む）</h3><br />
          <p className="media_text_text">
            新商品の展示会や商談会へ参加する費用を計上することができます。<br />
            <br />
            <strong>具体例</strong><br />
            展示会出店の用意にかかる、運搬費、設営費等を計上することが一般的です。<br />
          </p>
          <h3 className='media_text_text_title'>旅費</h3><br />
          <p className="media_text_text">
            補助事業を実施するための旅費（展示会との往復費用も含む）が対象となります。<br />
            <br />
            <strong>具体例</strong><br />
            旅費は、バス、電車、飛行機（エコノミー）の料金等を計上されることが一般的です。<br />
          </p>
          <h3 className='media_text_text_title'>開発費</h3><br />
          <p className="media_text_text">
            新製品のパッケージデザインや試作品開発に伴う費用が対象となります。<br />
            <br />
            <strong>具体例</strong><br />
            試作品の開発にあたってかかる、原材料の購入、設計、改良費を計上されることが一般的です。<br />
          </p>
          <h3 className='media_text_text_title'>資料購入費</h3><br />
          <p className="media_text_text">
            補助事業の遂行に必要な図書等の購入が対象となります。<br />
          </p>
          <h3 className='media_text_text_title'>雑役務費</h3><br />
          <p className="media_text_text">
            販路開拓に伴う業務を行うために必要な一時的な人件費等を対象としています。<br />
            <br />
            <strong>具体例</strong><br />
            臨時のアルバイトや派遣労働者などに支払われるお金を計上されることが一般的です。<br />
          </p>
          <h3 className='media_text_text_title'>借料</h3><br />
          <p className="media_text_text">
            事業遂行に必要な機器、設備等のリース料に必要な経費が対象となります。<br />
          </p>
          <h3 className='media_text_text_title'>設備処分費</h3><br />
          <p className="media_text_text">
            販路拡大の取組の中で設備を現状復帰、または、処分が必要な際に使用される場所にかかる経費を対象とします。<br />
          </p>
          <h3 className='media_text_text_title'>委託・外注費</h3><br />
          <p className="media_text_text">
            上記の10個の経費に含まれない経費であって、第三者に委託されるものが対象となります。<br />
            <br />
            <strong>具体例</strong><br />
            バリアフリーやトイレ等の設備の工事に使用さえる費用が計上されることが一般的です。<br />
          </p>
          </>
          
          <>
          <div id='8' className="media_text_title">
            <h2>８.小規模事業者持続化補助金の採択率</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            小規模事業者持続化補助金の採択率は、比較的最近高くなっております。<br />
            本記事では、【一般型】【低リスク感染型ビジネス枠】の２つを紹介させていただきます。<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>【一般型】</h3><br /> 
          <p className="media_text_text">
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>項目</th><th>申請件数</th><th>採択件数</th><th>採択率</th>
              </tr>
              <tr>
                <td><strong>第１次</strong></td>
                <td>8,044</td>
                <td>7,308</td>
                <td>90.9%</td>
              </tr>
              <tr>
                <td><strong>第２次</strong></td>
                <td>19,154</td>
                <td>12,478</td>
                <td>65.1%</td>
              </tr>
              <tr>
                <td><strong>第３次</strong></td>
                <td>13,642</td>
                <td>7,040</td>
                <td>51.6%</td>
              </tr>
              <tr>
                <td><strong>第４次</strong></td>
                <td>16,126</td>
                <td>7,128</td>
                <td>44.2%</td>
              </tr>
              <tr>
                <td><strong>第５次</strong></td>
                <td>12,738</td>
                <td>6,869</td>
                <td>53.9%</td>
              </tr>
              <tr>
                <td><strong>第６次</strong></td>
                <td>9,914</td>
                <td>6,846</td>
                <td>69.1%</td>
              </tr>
              <tr>
                <td><strong>第７次</strong></td>
                <td>9,339</td>
                <td>6,517</td>
                <td>69.8%</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <h3 className='media_text_text_title'>低リスク感染型ビジネス枠</h3><br /> 
          <p className="media_text_text">
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>項目</th><th>申請件数</th><th>採択件数</th><th>採択率</th>
              </tr>
              <tr>
                <td><strong>第１次</strong></td>
                <td>7,827</td>
                <td>3,512</td>
                <td>44.8%</td>
              </tr>
              <tr>
                <td><strong>第２次</strong></td>
                <td>10,205</td>
                <td>5,361</td>
                <td>52.5%</td>
              </tr>
              <tr>
                <td><strong>第３次</strong></td>
                <td>8,056</td>
                <td>5,022</td>
                <td>62.3%</td>
              </tr>
              <tr>
                <td><strong>第４次</strong></td>
                <td>8,243</td>
                <td>5,780</td>
                <td>70.1%</td>
              </tr>
              <tr>
                <td><strong>第５次</strong></td>
                <td>6,208</td>
                <td>4,138</td>
                <td>66.6%</td>
              </tr>
              <tr>
                <td><strong>第６次</strong></td>
                <td>11,721</td>
                <td>8,040</td>
                <td>68.6%</td>
              </tr>
            </tbody>
          </table>
          </>
          
          <>
          <div id='9' className="media_text_title">
            <h2>９.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            本記事では、小規模事業者持続化補助金を申請したい事業者さまへ向けて、小規模事業者持続化補助金の概要について記事にさせていただきました。<br />
            <br />
            募集要項を各所に添付させていただきましたが、ご覧いただけば分かる通り難しい内容となっております。<br />
            そこで、もし、本格的に小規模事業者持続化補助金の導入を検討されていましたら、弊社含め補助金に詳しい事業者へご相談ください。<br />
            <br />
            ご相談は、画面右上の補助金に関する相談をするから問い合わせにて、お待ちしております。<br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
        </div>

        <div className='media_topic'>
          <div className='media_topic_service_top'>
            <h3>memorii help</h3>
            <h4>補助金/助成金の申請サポート</h4>
            <p>初めての方へも丁寧に補助金/助成金のご説明をさせていただき、さらに、電子申請、交付申請のサポートまで、行わせて頂くサービスとなっております。</p>
          </div>
          <div className='media_recommend_title'>おすすめの記事</div>
          <div className='media_recommend_content'></div>
          <div className='media_recommend_content'></div>
          <div className='media_recommend_content'></div>
          <div className='media_recommend_content'></div>
        </div>
      </div>

      <div className='media_'></div>

      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;