import React, { useState, useEffect } from "react";
import Create from './../create/index';
import './styles.css';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMove } from 'react-sortable-hoc';
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore'
import { AiOutlineHome } from 'react-icons/ai'
import firebase from 'firebase/compat/app'
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { FC, useRef } from "react";
import { useReward } from "react-rewards";

const useInterval = (callback) => {
  const callbackRef = useRef(() => {});

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const timerId = setInterval(() => callbackRef.current(), 2000);
    return () => clearInterval(timerId);
  }, []);
};

const Help = () => {

  const [ disAeords, setDisAeords ] = useState(false);

  const { reward: rewardLeft, isAnimating: isAnimatingLeft } = useReward(
    "rewardLeft",
    "confetti",
    {
      angle: 0,
      position: "absolute",
      elementCount: "200",
      colors :['#8421ED', '#07C6FF', '#30FF00', '#FF4268', '#F6F929'],
      lifetime: 1500,
      startVelocity: 50,
    }
  );
  const { reward: rewardRight, isAnimating: isAnimatingRight } = useReward(
    "rewardRight",
    "confetti",
    {
      angle: 180,
      position: "absolute",
      elementCount: "200",
      colors :['#8421ED', '#07C6FF', '#30FF00', '#FF4268', '#F6F929'],
      lifetime: 1700,
      startVelocity: 50,
    }
  );
  
  useInterval(() => {
    rewardLeft();
    rewardRight();
  }, 2000);

  function displayAword() {
    
    setDisAeords(true);
    setTimeout(() => {
      setDisAeords(false);
    }, 2000);
  }

  const [ data, setData ] = useState();
  const [ prices, setPrices ] = useState();
  
  const [ mytask, setMyTask ] = useState([]);
  const [ isDroppables, setIsDroppables ] = useState(false);
  const [ dropData, setDropData ] = useState('');
  
  const [ doneTaskCount, setDoneTaskCount ] = useState([]);
  const [ graph, setGraph ] = useState(0);

  const [ createInfo, setCreateInfo ] = useState(1000);

  var link = new URL(window.location).href;
  var name = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));
  
  useEffect(() => {
    myTaskList();

    price();
    var now = new Date();
    setData(now.getDate());
    
  }, []);

  function price() {
    const docRef = collection(firebase.firestore(), 'tasks')
    const queryRef = query(docRef, where('tag', '==', '事業再構築_第11回'))
    const queryRefs = query(queryRef, orderBy('order'))
    const unsub = onSnapshot(queryRefs, snapshot => {
      var results = 0;
      snapshot.docs.forEach(doc => {
        results = results + 1;
      })
      setPrices(results);
    })
    return () => unsub();
  }

  function myTaskList() {
    setMyTask([]);
    const docRef = collection(firebase.firestore(), 'tasks')
    const queryRef = query(docRef, where('tag', '==', 'mytask'))
    const queryRefs = query(queryRef, orderBy('name'))
    const unsub = onSnapshot(queryRefs, snapshot => {
      var results = []
      var allData = []
      snapshot.docs.forEach(doc => {
        if (doc.data().person[0] === name) {
          if (!doc.data().status) {
            results.push({...doc.data(), id: doc.id })
          } else {
            allData.push({...doc.data(), id: doc.id })
          }
        }
      })
      setMyTask(results);
      date(allData);
    })
    return () => unsub();
  }

  function createList() {

    firebase.firestore().collection('tasks').doc()
    .set({
      name : 'new task',
      memo : '',
      person : [ name, 'https://ca.slack-edge.com/T03LR3Y18SZ-U03MHQVPG64-e465f85b0cef-512', ],
      order : 100,
      tag : 'mytask',
      status : false,
      time : new Date(),
      done_time : new Date(2025, 1, 1, 0, 0, 0, 0),
      uid : '',
    })
  }

  const onDrop = () => {
    myTaskList();
    firebase.firestore().collection('tasks').doc(dropData.id)
    .update({
      status : true,
      done_time : new Date(),
    })
    setIsDroppables(false);
    setDisAeords(true);
    displayAword();
  };

  function date(v) {

    var now = new Date();
    var years = now.getFullYear();
    var month = now.getMonth();
    var day = now.getDate();

    var doneCount1 = 0;
    var doneCount2 = 0;
    var doneCount3 = 0;
    var doneCount4 = 0;
    var doneCount5 = 0;
    var doneCount6 = 0;
    var doneCount7 = 0;
    var doneCount8 = 0;
    var doneCount9 = 0;
    var doneCount10 = 0;

    for (let i = 0; i < v.length; i++) {
      var array = [];

      // 1
      if (v[i]['done_time'].toDate() > new Date(years, month, day, 0, 0, 0, 0) && 
        v[i]['done_time'].toDate() < new Date(years, month, day + 1, 0, 0, 0, 0)) {
        doneCount1 = doneCount1 + 1;
        setDoneTaskCount([doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,]);
        array = [doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,];
      }
      // 2
      if (v[i]['done_time'].toDate() > new Date(years, month, day - 1, 0, 0, 0, 0) && 
        v[i]['done_time'].toDate() < new Date(years, month, day + 0, 0, 0, 0, 0)) {
        doneCount2 = doneCount2 + 1;
        setDoneTaskCount([doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,]);
        array = [doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,];
      }
      // 3
      if (v[i]['done_time'].toDate() > new Date(years, month, day - 2, 0, 0, 0, 0) && 
        v[i]['done_time'].toDate() < new Date(years, month, day - 1, 0, 0, 0, 0)) {
        doneCount3 = doneCount3 + 1;
        setDoneTaskCount([doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,]);
        array = [doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,];
      }
      // 4
      if (v[i]['done_time'].toDate() > new Date(years, month, day - 3, 0, 0, 0, 0) && 
        v[i]['done_time'].toDate() < new Date(years, month, day - 2, 0, 0, 0, 0)) {
        doneCount4 = doneCount4 + 1;
        setDoneTaskCount([doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,]);
        array = [doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,];
      }
      // 5
      if (v[i]['done_time'].toDate() > new Date(years, month, day - 4, 0, 0, 0, 0) && 
        v[i]['done_time'].toDate() < new Date(years, month, day - 3, 0, 0, 0, 0)) {
        doneCount5 = doneCount5 + 1;
        setDoneTaskCount([doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,]);
        array = [doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,];
      }
      // 6
      if (v[i]['done_time'].toDate() > new Date(years, month, day - 5, 0, 0, 0, 0) && 
        v[i]['done_time'].toDate() < new Date(years, month, day - 4, 0, 0, 0, 0)) {
        doneCount6 = doneCount6 + 1;
        setDoneTaskCount([doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,]);
        array = [doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,];
      }
      // 7
      if (v[i]['done_time'].toDate() > new Date(years, month, day - 6, 0, 0, 0, 0) && 
        v[i]['done_time'].toDate() < new Date(years, month, day - 5, 0, 0, 0, 0)) {
        doneCount7 = doneCount7 + 1;
        setDoneTaskCount([doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,]);
        array = [doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,];
      }
      // 8
      if (v[i]['done_time'].toDate() > new Date(years, month, day - 7, 0, 0, 0, 0) && 
        v[i]['done_time'].toDate() < new Date(years, month, day - 6, 0, 0, 0, 0)) {
        doneCount8 = doneCount8 + 1;
        setDoneTaskCount([doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,]);
        array = [doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,];
      }
      // 9
      if (v[i]['done_time'].toDate() > new Date(years, month, day - 8, 0, 0, 0, 0) && 
        v[i]['done_time'].toDate() < new Date(years, month, day - 7, 0, 0, 0, 0)) {
        doneCount9 = doneCount9 + 1;
        setDoneTaskCount([doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,]);
        array = [doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,];
      }
      // 10
      if (v[i]['done_time'].toDate() > new Date(years, month, day - 9, 0, 0, 0, 0) && 
        v[i]['done_time'].toDate() < new Date(years, month, day - 8, 0, 0, 0, 0)) {
        doneCount10 = doneCount10 + 1;
        setDoneTaskCount([doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,]);
        array = [doneCount1, doneCount2, doneCount3, doneCount4, doneCount5, doneCount6, doneCount7, doneCount8, doneCount9, doneCount10,];
      }
      
      if (0 <= Math.max(...array) && Math.max(...array) <= 6) {
        setGraph(6);
      } else if (7 <= Math.max(...array) && Math.max(...array) <= 12) {
        setGraph(12);
      } else if (13 <= Math.max(...array) && Math.max(...array) <= 18) {
        setGraph(18);
      } else if (19	<= Math.max(...array) && Math.max(...array) <= 24) {
        setGraph(24);
      } else if (25	<= Math.max(...array) && Math.max(...array) <= 30) {
        setGraph(30);
      } else if (31	<= Math.max(...array) && Math.max(...array) <= 36) {
        setGraph(36);
      } else if (37	<= Math.max(...array) && Math.max(...array) <= 42) {
        setGraph(42);
      } else if (43	<= Math.max(...array) && Math.max(...array) <= 48) {
        setGraph(48);
      } else if (49	<= Math.max(...array) && Math.max(...array) <= 54) {
        setGraph(54);
      } else if (55	<= Math.max(...array) && Math.max(...array) <= 60) {
        setGraph(60);
      } else if (61	<= Math.max(...array) && Math.max(...array) <= 66) {
        setGraph(66);
      } else if (67	<= Math.max(...array) && Math.max(...array) <= 72) {
        setGraph(72);
      } else if (73	<= Math.max(...array) && Math.max(...array) <= 78) {
        setGraph(78);
      } else if (79	<= Math.max(...array) && Math.max(...array) <= 84) {
        setGraph(84);
      } else if (85	<= Math.max(...array) && Math.max(...array) <= 90) {
        setGraph(90);
      } else if (91	<= Math.max(...array) && Math.max(...array) <= 96) {
        setGraph(96);
      } else if (97	<= Math.max(...array) && Math.max(...array) <= 102) {
        setGraph(102);
      } else if (103 <= Math.max(...array) && Math.max(...array) <= 108) {
        setGraph(108);
      } else if (109 <= Math.max(...array) && Math.max(...array) <= 114) {
        setGraph(114);
      } else if (115 <= Math.max(...array) && Math.max(...array) <= 120) {
        setGraph(120);
      }
    }
  }

  return (
    <>
    <div className="task_mypage">

      {disAeords ? 
        <header className="task_mypage_awards">
            <span id="rewardLeft" />
            <span id="rewardRight" />
          {/* <div>
            <h4>辻ちゃん Thank you for today too</h4>
          </div> */}
        </header> : <></>
      }
      <div onDragEnd={() => setIsDroppables(false)} className="task_mypage_box">
        <div className="task_mypage_left">
          <div className="task_mypage_left_top">
            <div style={{backgroundColor : '#D1505F'}}>
              <h4>残りのタスク</h4>
              <p>{mytask.length}個</p>
            </div>
            <div style={{backgroundColor : '#F1AE54'}}>
              <h4>着手金</h4>
              <p>{prices * 7}万円</p>
            </div>
            <div style={{backgroundColor : '#5AC47D'}}>
              <h4>成功報酬</h4>
              <p>{Math.floor(prices / 10 * 7 * 80)}万円</p>
            </div>
          </div>

          <div className="task_mypage_left_bottom"
            onDragOver={(e) => {
              e.preventDefault();
              e.type === 'dragover' ? setIsDroppables(true) : setIsDroppables(false);
            }}
            onDrop={onDrop}
          >
            {isDroppables === true ? 
              <div className="task_mypage_left_bottom_drop"></div>
            : <></>}
            <div className="task_mypage_left_bottom_nomal">
              <div className="task_mypage_left_bottom_unit">
                <p>{graph}</p>
                <p>{graph / 6 * 5}</p>
                <p>{graph / 6 * 4}</p>
                <p>{graph / 6 * 3}</p>
                <p>{graph / 6 * 2}</p>
                <p>{graph / 6}</p>
                <p>0</p>
              </div>
              <div className="task_mypage_left_bottom_graph">
                <div className="task_mypage_left_bottom_graph_top">
                  <div style={{height : `calc(300px / ${graph} * ${doneTaskCount[9]})`,
                    marginTop : `calc(300px - 300px / ${graph} * ${doneTaskCount[9]})`}}></div>
                  <div style={{height : `calc(300px / ${graph} * ${doneTaskCount[8]})`,
                    marginTop : `calc(300px - 300px / ${graph} * ${doneTaskCount[8]})`}}></div>
                  <div style={{height : `calc(300px / ${graph} * ${doneTaskCount[7]})`,
                    marginTop : `calc(300px - 300px / ${graph} * ${doneTaskCount[7]})`}}></div>
                  <div style={{height : `calc(300px / ${graph} * ${doneTaskCount[6]})`,
                    marginTop : `calc(300px - 300px / ${graph} * ${doneTaskCount[6]})`}}></div>
                  <div style={{height : `calc(300px / ${graph} * ${doneTaskCount[5]})`,
                    marginTop : `calc(300px - 300px / ${graph} * ${doneTaskCount[5]})`}}></div>
                  <div style={{height : `calc(300px / ${graph} * ${doneTaskCount[4]})`,
                    marginTop : `calc(300px - 300px / ${graph} * ${doneTaskCount[4]})`}}></div>
                  <div style={{height : `calc(300px / ${graph} * ${doneTaskCount[3]})`,
                    marginTop : `calc(300px - 300px / ${graph} * ${doneTaskCount[3]})`}}></div>
                  <div style={{height : `calc(300px / ${graph} * ${doneTaskCount[2]})`,
                    marginTop : `calc(300px - 300px / ${graph} * ${doneTaskCount[2]})`}}></div>
                  <div style={{height : `calc(300px / ${graph} * ${doneTaskCount[1]})`,
                    marginTop : `calc(300px - 300px / ${graph} * ${doneTaskCount[1]})`}}></div>
                  <div style={{height : `calc(300px / ${graph} * ${doneTaskCount[0]})`,
                    marginTop : `calc(300px - 300px / ${graph} * ${doneTaskCount[0]})`}}></div>
                </div>
                <div className="task_mypage_left_bottom_graph_bottom">
                  <p>{data - 9}日</p>
                  <p>{data - 8}日</p>
                  <p>{data - 7}日</p>
                  <p>{data - 6}日</p>
                  <p>{data - 5}日</p>
                  <p>{data - 4}日</p>
                  <p>{data - 3}日</p>
                  <p>{data - 2}日</p>
                  <p>{data - 1}日</p>
                  <p>{data}日</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="task_mypage_right">
          <div className="task_mypage_right_title">
            <p>リスト追加</p>
            <button onClick={() => createList()}>追加</button>
          </div>
          {mytask.map(( data, index ) => (
            <div draggable 
              key={index} onDragStart={() => setDropData({...data, index: index})} id='content'
              className="task_table_mytask" style={{backgroundColor : `#${data.color}`}}
              onClick={() => setCreateInfo(index)}
              >
              {data.name}
            </div>
          ))}
        </div>
      </div>
    </div>

    {createInfo !== 1000 ?
      <Create items={mytask} createInfo={createInfo} setCreateInfo={setCreateInfo} name={name} />
    : <></>}
    </>
  );
};

export default Help;
