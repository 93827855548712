// 給与総額増加要件

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/32/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」給与総額増加要件は給与支給を年率2%増加！',
    description: '本記事では、事業再構築要件の給与総額増加要件について解説させていただきました。要件の詳細、補助率、活用例などの詳細も解説しております。本記事は、「事業再構築の定義が分からない」方や「類型を詳しく知りたい」という方に向けて、執筆しておりますので、補助金の初申請を行う方はぜひご覧いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」給与総額増加要件は給与支給を年率2%増加</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は<strong>事業再構築補助金の給与総額増加要件</strong>について、解説させていただきます。<br />
            <br />
            本記事は、補助金申請が初めての方へ向けた記事となっておりますので、ごゆっくり最後までご一読いただければと思います。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>本記事でこんな悩みが解決！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>給与総額増加要件とは何？</span></li>
              <li><span>給与総額増加要件はどうすれば満たされる？</span></li>
              <li><span>給与総額増加要件で何ができる？</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、<strong>経済産業省から展開されている「事業再構築指針の手引き」を参考にして作成したもの</strong>となっています。<br />
            <a href='https://www.meti.go.jp/covid-19/jigyo_saikoutiku/pdf/shishin_tebiki.pdf' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：「事業再構築指針の手引き」に関する記事
            </a><br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">給与総額増加要件の概要</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">概要と要件</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">要件</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">申請可能な枠</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.給与総額増加要件の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、給与総額増加要件に関する概要から補助率までを解説しておりますので、要件を詳しく知りたい！といった方はぜひご覧ください。<br />
          </p>
          <h3 className='media_text_text_title'>概要と要件</h3><br />
          <p className="media_text_text">
            給与総額増加要件とは、<span>補助事業終了年度の給与支払総額を基準として、3年~5年後の事業計画中に年率2%以上 給与支払総額を引き上げる要件</span>です。<br />
            <br />
            ここで、押さえておきたいポイントは、以下の2点となります。<br />
            ・<strong>補助事業終了年度の給与支払総額とは何か？</strong><br />
            ・<strong>3年~5年とは具体的にいつの時期か？</strong><br />
            <br />
            <span><strong>補助事業終了年度が含まれる年度の給与支払総額が何か？</strong></span><br />
            まず、1つ目の疑問である補助事業終了年度ですが、こちらは<strong>実績報告が終了した年度</strong>と考えていただければ問題ないでしょう。<br />
            <br />
            具体的には、2023年の4月~8月で補助事業を実施し、その会社の決算が10月である場合、2023年度の決算の給与支払総額を基準としていただければ大丈夫です。<br />
            <br />
            <span><strong>3年~5年とは具体的にいつの時期か？</strong></span><br />
            2つ目の疑問ですが、上記の2023年度の決算を基準と、経過はこのようになります。<br />
            1年目：2024年度、2年目：2025年度、3年目：2026年度、4年目：2027年度、5年目：2028年度<br />
            <br />
            その場合、3年~5年ですので、<strong>2026年から2028年度にかけて年率平2%以上の給与総額増加</strong>を行う必要があり、合計で基準年度の<strong>支払総額から4%の増加</strong>を行う必要がございます。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>概要と要件のまとめ</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>実績報告が終了した年度の決算の給与支払総額が基準</span></li>
              <li><span>実績報告終了年度から3年目から5年目までの2年間の増加が要件</span></li>
              <li><span>基準年度から4%の給与支払総額の増加が要件</span></li>
            </ul>
          </div>
          <h3 className='media_text_text_title'>要件を満たすために必要な書類</h3><br />
          <p className="media_text_text">
            この要件は、事前に確認することができない要件のため、3年~5年経った際に以下の書類を提出する形となります。<br />
            <br />
            <strong>法人の場合...法人事業概況説明書</strong><br />
            <strong>個人の場合...所得税青色申告決算書</strong><br />
            <br />
            本要件では事前に補助金の振込が行われ、後々の検査で要件を満たしているか判断されます。<br />
            <br />
            そのため、もし要件を満たしていない場合は補助金の返金を求められるので、<strong>ご利用は計画的に行う必要がある</strong>でしょう。<br />
            <br />
            <strong>満たせなかった場合の罰則は？</strong><br />
            満たせなかった時の罰則は返金のみとなっておりますが、年率平均2%以上の増加ができず、その理由が正当なものではない場合は事業者名が公開されるという罰則が例外的にございます。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>必要な書類のまとめ</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>3年~5年後に審査が行われる要件である</span></li>
              <li><span>要件に満たしていない場合は、返金が必要</span></li>
              <li><span>正当な理由がなく要件不備の場合は事業者名が公開される</span></li>
            </ul>
          </div>
          <h3 className='media_text_text_title'>申請可能な枠</h3><br />
          <p className="media_text_text">
            本要件を必要とする申請枠は、<strong>成長枠</strong>と<strong>グリーン成長枠</strong>の申請を行う方のみとなります。<br />
            <br />
            本要件を確認すると少し厳しい要件かなと感じるかもしれませんが、本要件を選択されている方は市場が拡大しているところで新しく事業を行う方で事業の拡大が前提かと思います。<br />
            <br />
            そのため、<span>実際にどのくらいの従業員を3年後~5年後に増加させるのか等の予測を経てて、ご検討いただければと思います</span>。<br />
            <br />
            また、別途記事で成長枠の詳しい解説を行っておりますので、ぜひご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0025' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：成長枠に関する詳細記事
            </a><br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            最後まで読んでいただき、誠にありがとうございます。<br />
            給与総額増加要件の解説をさせていただきましたがいかがだったでしょうか。<br />
            <br />
            本要件を検討する際は、自身の事業の予測を踏まえて本要件をご検討いただければと思います。<br />
            <br />
            上述で示した通り、弊社は補助金申請の専門家でございますので、本記事に関わらずご不明点等ございましたら、ご連絡いただけますと幸いです。<br />
            <br />
            ご連絡いただけること心よりお待ちしております。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
