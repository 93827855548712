import firebase from "firebase/compat/app"

const BlogText0001 = () => {
  
  // 環境構築
  function first() {
    firebase.firestore().collection('event').doc('yRUUsncHiBF9JqyWPn6J')
    .update({
      entryPeople : [],
      round1 : [],
      round2 : [],
      round3 : [],
      round4 : [],
      round5 : [],
      round6 : [],
    })

    var list = [
      "https://images.pexels.com/photos/3533228/pexels-photo-3533228.png?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/1689731/pexels-photo-1689731.jpeg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/982585/pexels-photo-982585.jpeg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/36469/woman-person-flowers-wreaths.jpg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/1021693/pexels-photo-1021693.jpeg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/2065200/pexels-photo-2065200.jpeg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/1572878/pexels-photo-1572878.jpeg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/2787341/pexels-photo-2787341.jpeg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/1162983/pexels-photo-1162983.jpeg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/1183266/pexels-photo-1183266.jpeg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/620074/pexels-photo-620074.jpeg?auto=compress&cs=tinysrgb&w=800",
    ]

    var listId = [
      "list_01",
      "list_02",
      "list_03",
      "list_04",
      "list_05",
      "list_06",
      "list_07",
      "list_08",
      "list_09",
      "list_10",
      "list_11",
    ]

    for (let i = 0; i < list.length; i++) {
      firebase.firestore().collection('event').doc('yRUUsncHiBF9JqyWPn6J').collection('tournament').doc(listId[i])
      .set({
        image : list[i],
        round1_vote : [],
        round2_vote : [],
        round3_vote : [],
        round4_vote : [],
        round5_vote : [],
        round6_vote : [],
        time : '日程',
        uid : listId[i],
      })
    }

  }

  return (
    <>
    <button onClick={() => first()}>環境構築</button>
    </>
  );
}

export default BlogText0001;