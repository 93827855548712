const BlogText0001 = (props) => {

  return(
  <>
  {/* Z1 */}
  <div className='management_review_content_1'>
    <h4>従業員に関する質問（11問）</h4>
    <div>
      <h5>Q1.1級監理受講者数（数字）</h5>
      <p>会社に属している1級監理受講者の人数を記載してください。</p>
      <input onChange={(e) => props.setOneStudents(e.target.value)} placeholder="12" type="number" />
    </div>
    <div>
      <h5>Q2.1級技術者数（数字）</h5>
      <p>会社に属している1級技術者数の人数を記載してください。</p>
      <input onChange={(e) => props.setOneEngineers(e.target.value)} placeholder="12" type="number" />
    </div>
    <div>
      <h5>Q3.監理技術者補佐（数字）</h5>
      <p>会社に属している監理技術者補佐の人数を記載してください。</p>
      <input onChange={(e) => props.setEngineerAssistant(e.target.value)} placeholder="12" type="number" />
    </div>
    <div>
      <h5>Q4.基幹技能者数（数字）</h5>
      <p>会社に属している基幹技能者数の人数を記載してください。</p>
      <input onChange={(e) => props.setTechnicians(e.target.value)} placeholder="12" type="number" />
    </div>
    <div>
      <h5>Q5.2級技術者数（数字）</h5>
      <p>会社に属している2級技術者数の人数を記載してください。</p>
      <input onChange={(e) => props.setTwoEngineers(e.target.value)} placeholder="12" type="number" />
    </div>
    <div>
      <h5>Q6.その他技術者数（数字）</h5>
      <p>会社に属しているその他技術者数の人数を記載してください。</p>
      <input onChange={(e) => props.setOtherTechnicians(e.target.value)} placeholder="12" type="number" />
    </div>
  </div>

  {/* Z2 */}
  <div className='management_review_content_1'>
    <h4>従業員に関する質問（1問）</h4>
    <div>
      <h5>Q1.元請完成工事高（数字）</h5>
      <input onChange={(e) => props.setBeforeConstructionCost(e.target.value)} placeholder="100000000" type="number" />
    </div>
  </div>
  </>
  );
}

export default BlogText0001;
