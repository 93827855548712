const BlogText0001 = (props) => {

  return(
  <>
  <div className='management_review_content_1'>
    <h4>経営状況分析（1問）</h4>
    <div>
      <h5>Q1.経営状況分析の点数（数字）</h5>
      <input onChange={(e) => props.setAnalysis(e.target.value)} placeholder="700" type="number" />
    </div>
  </div>
  </>
  );
}

export default BlogText0001;
