// 事業計画書

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/21/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」事業計画書の作成は、この４点を抑える！',
    description: '本記事では、事業計画書の作成方法についてまとめさせていただきました。記載必須項目と審査項目の解説もさせていただいております。本記事は、事業再構築補助金の申請を検討されている事業者さまの中で「事業計画書の書き方がわからない」という方へ向けて記事を執筆させていただきました。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」事業計画書の作成は、この４点を抑える！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は、<strong>日本最大級の補助金である事業再構築補助金の申請で必要となる「事業計画書」</strong>について解説させていただきます。<br />
            <br />
            本記事は、事業再構築補助金の申請を検討されている事業者さまの中で「<strong>事業計画書の書き方がわからない</strong>」という方へ向けて記事を執筆させていただきました。<br />
            <br />
            事業再構築補助金の事業計画書の書き方を知りたい方は、ぜひチェックしていただければと思います！<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業計画書とは</span></li>
              <li><span>事業計画書の審査項目</span></li>
              <li><span>事業計画書の書き方</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、<strong>補助金の説明書である「公募要領」</strong>を基盤にして作成したものとなっていますので、内容を理解したうえで公募要領部を読んでみてください！<br />
            <br />
            なお、右上のボタンからお問い合わせをお受けしておりますので、<strong>申請サポートのご依頼や不明点のご相談はお気軽にご連絡ください</strong>。<br />
            <br />
            それでは、解説に進ませていただきます！<br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">事業再構築補助金の事業計画書とは？</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">事業計画書とは</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">事業計画書の要件</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">記載内容</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">１：補助事業の具体的な取組内容</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">２：将来の展望</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">３：本事業で取得する主な資産</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">４：収益計画</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">本項目のまとめ</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">審査項目</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#3" offset="100">補助対象としての適格性</AnchorLink></li>
                  <li><AnchorLink href="#3" offset="100">事業化点</AnchorLink></li>
                  <li><AnchorLink href="#3" offset="100">再構築点</AnchorLink></li>
                  <li><AnchorLink href="#3" offset="100">政策点</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#4" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>１.事業再構築補助金の事業計画書とは？</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、事業計画書の概要とその要件についてご説明させていただきます。<br />
          </p>
          <h3 className='media_text_text_title'>事業計画書とは</h3><br />
          <p className="media_text_text">
            事業再構築補助金における事業計画書とは、<strong>補助金を活用して行いたい事業の内容を最大15ページ（補助金額1,500万円以下の場合は10ページ）以内にまとめた書類</strong>です。<br />
            <br />
            事業再構築補助金では、<strong>思い切った事業を行う事を示さなければいけないほか、既存事業とのシナジー効果についても触れなければいけません</strong>。<br />
          </p>
          <h3 className='media_text_text_title'>事業計画書の要件</h3><br />
          <p className="media_text_text">
            事業計画書には、いくつかの要件がございますので、以下にまとめさせていただきました。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>要件</th><th>書類名</th>
              </tr>
              <tr>
                <td>サイズ</td><td>A4</td>
              </tr>
              <tr>
                <td>型式</td><td>PDF</td>
              </tr>
              <tr>
                <td>ファイル名</td><td>事業計画書（事業者名）</td>
              </tr>
              <tr>
                <td>作成方法</td><td>Wordで作成（推奨）</td>
              </tr>
              <tr>
                <td>記載内容</td>
                <td>
                  １：補助事業の具体的取組内容<br />
                  ２：将来の展望<br />
                  ３：本事業で取得する主な資産<br />
                  ４：収益計画
                </td>
              </tr>
              <tr>
                <td>字体・書式</td><td>問わない</td>
              </tr>
              <tr>
                <td>記載事項</td><td>ページ番号が必須</td>
              </tr>
            </tbody>
          </table>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>２.記載内容</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、実際に「<strong>どのような内容を記載すべきか</strong>」という疑問について、まとめさせていただきましたので、事業計画書を作成する方は、ぜひ確認してみてください。<br />
            <br />
            事業計画書は以下の４点を記載しなければいけないと上述で記載させていただきましたが、この中にもそれぞれ記載すべき内容が多くありますので、解説させていただきます。<br />
            １：<strong>補助事業の具体的な取組内容</strong><br />
            ２：<strong>将来の展望</strong><br />
            ３：<strong>本事業で取得する主な資産</strong><br />
            ４：<strong>収益計画</strong><br />
          </p>
          <h3 className='media_text_text_title'>１：補助事業の具体的な取組内容</h3><br />
          <p className="media_text_text">
            補助事業の具体的な取組内容について、公募要領では、以下のように記載がございます。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>補助事業の具体的な取組内容</span><br />
            ① 現在の事業の状況、強み・弱み、機会・脅威、事業環境、事業再構築の必要性、事業再構 築の具体的内容（提供する製品・サービス、導入する設備、工事等）、今回の補助事業で実施する新分野展開や業態転換、事業・業種転換等の取組、事業再編又はこれらの取組について具体的に記載してください。<br />
            事業実施期間内に投資する建物の建設・改修等の予定、機械装置等の型番、取得時期や技 術の導入や専門家の助言、研修等の時期についても、可能な限り詳細なスケジュールを記載 してください。 ※必要に応じて、図表や写真等を用いて、具体的に記載してください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className='media_text_text'>
            <br />
            <strong>上記の解説</strong><br />
            以下の項目を含める必要がございます。<br />
            ・<strong>現在の事業の状況（事業内容、経営状況など）</strong><br />
            ・<strong>SWOT分析（強み、弱み、機会、脅威、事業環境）</strong><br />
            ・<strong>事業再構築の必要性</strong><br />
            ・<strong>補助金を活用して行いたい事業内容</strong><br />
            ・<strong>投資内容の詳細（機械装置の型番や工事など）</strong><br />
            ・<strong>スケジュール</strong><br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>補助事業の具体的な取組内容</span><br />
            ② 応募申請する枠（通常枠、大規模賃金引上枠、回復・再生応援枠、最低賃金枠、グリーン 成長枠、緊急対策枠）と事業再構築の種類（「事業再編型」、「業態転換型」、「新分野展 開型」、「事業転換型」、「業種転換型」）に応じて、「事業再構築指針」に沿った事業計画を作成してください。どの種類の事業再構築の類型に応募するか、どの種類の再構築なの かについて、事業再構築指針とその手引きを確認して、具体的に記載してください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className='media_text_text'>
            <br />
            <strong>上記の解説</strong><br />
            申請したい事業計画が「事業再構築指針」のどの分野に当てはまるのかをご確認いただき、その分野に当てはまる事業であることを示す必要がございます。<br />
            <br />
            事業再構築指針（事業再構築要件）の記事を別途ご用意させていただきましたので、お決まりでない方、詳しく知りたい方はぜひご参照いただければと思います。<br />
            <a href='https://trankllc.com/media/subsidy/business/0007' target="_blank" rel="nofollow noopener noreferrer">
              参考記事：【類型・転換って何？】事業再構築補助金の再構築要件を、例を用いて解説！
            </a><br />
            <br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>補助事業の具体的な取組内容</span><br />
            ③ 補助事業を行うことによって、どのように他者、既存事業と差別化し競争力強化が実現するかについて、その方法や仕組み、実施体制など、具体的に記載してください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className='media_text_text'>
            <br />
            <strong>上記の解説</strong><br />
            補助事業の競合他社に対して、どのように対策を行うのかを説明しなければいけません。<br />
            例１）<strong>どのように差別化できるのか</strong><br />
            例２）<strong>競争力強化が実現できるのか</strong><br />
            <br />
            一般的には、<strong>事業の手法や仕組み、実施体制を用いた比較を行います</strong>。<br />
            <br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>補助事業の具体的な取組内容</span><br />
            ④ 既存事業の縮小又は廃止、省人化により、従業員の解雇を伴う場合には、再就職支援の計画等の従業員への適切な配慮の取組について具体的に記載してください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className='media_text_text'>
            <br />
            <strong>上記の解説</strong><br />
            補助事業遂行に当たって、既存事業の縮小や廃止、従業員の解雇を行う場合には、その内容を記載し、配慮していることを示さなければなりません。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>補助事業の具体的な取組内容</span><br />
            ⑤ 個々の事業者が連携して遂行する事業である場合、又は、代表となる事業者が複数の事業者の取り組みを束ねて一つの事業計画として申請を行う場合は、事業者ごとの取組内容や補助事業における役割等を具体的に記載してください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className='media_text_text'>
            <br />
            <strong>上記の解説</strong><br />
            申請者が１社ではなく複数社が連携して行う場合は、事業者ごとに行う取組内容を記載する必要があります。<br />
            <br />
            ここまで述べた通り、「１．補助事業の具体的取組内容」にはたくさんの情報を記載する必要がありますので、事業計画書を作成中の方はぜひ確認してみてください。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>補助事業の具体的取組内容に記載する情報</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業の状況（現在の事業内容や経営状況など）</span></li>
              <li><span>SWOT分析（強み・弱み・機会・脅威）</span></li>
              <li><span>事業再構築の必要性</span></li>
              <li><span>補助金を活用して行いたい事業内容</span></li>
              <li><span>投資内容の詳細</span></li>
              <li><span>スケジュール</span></li>
              <li><span>事業再構築指針の分野への適合性</span></li>
              <li><span>競合他社に対する差別化要素、競争力強化</span></li>
              <li><span>事業者ごとに行う取組内容（複数社で申請の場合のみ）</span></li>
            </ul>
          </div>
          <h3 className='media_text_text_title'>２：将来の展望</h3><br />
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>２：将来の展望</span><br />
            ① 本事業の成果が寄与すると想定している具体的なユーザー、マーケット及び市場規模等について、その成果の価格的・性能的な優位性・収益性や課題やリスクとその解決方法などを記載してください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            <strong>上記の解説</strong><br />
            以下の内容を記載する必要があります。<br />
            ・<strong>新規事業の具体的なユーザー</strong><br />
            ・<strong>新規事業のマーケット（市場規模や成長度合い）</strong><br />
            ・<strong>新規事業の優位性（価格的・性能的）</strong><br />
            ・<strong>新規事業を行う上でのリスク・課題</strong><br />
            ・<strong>それに対応する解決方法</strong><br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>２：将来の展望</span><br />
            ② 本事業の成果の事業化見込みについて、目標となる時期・売上規模・量産化時の製品等の価格等について簡潔に記載してください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            <strong>上記の解説</strong><br />
            新規事業を始める時期を明確に示し、その後３～５年間において売上や商品の価格がどうなるかについても記載する必要がございます。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>２：将来の展望</span><br />
            ③ 必要に応じて図表や写真等を用い、具体的に記載してください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            <strong>上記の解説</strong><br />
            図表や写真を用いることで視覚的に理解しやすくなるので、１ページに２枚程度挿入するようにしましょう。<br />
            <br />
            ２：将来の展望の内容を以下にまとめさせていただきます。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>将来の展望に記載すべき内容</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>具体的なユーザー</span></li>
              <li><span>マーケット（市場規模など）</span></li>
              <li><span>優位性（価格的・性能的な）</span></li>
              <li><span>リスクや課題、対応する解決方法</span></li>
              <li><span>収益の見込み（３～５年間）</span></li>
              <li><span>商品の価格</span></li>
            </ul>
          </div>
          <h3 className='media_text_text_title'>３：本事業で取得する主な資産</h3><br />
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>２：将来の展望</span><br />
            ① 本事業により取得する主な資産（単価50万円以上の建物、機械装置・システム等）の名称、 分類、取得予定価格等を記載してください。（補助事業実施期間中に、別途、取得財産管理台帳を整備していただきます。）<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            <strong>上記の解説</strong><br />
            補助事業の投資内容の詳細を記載しなければいけません。<br />
            例）名称や分類、取得価格といった情報など<br />
          </p>
          <h3 className='media_text_text_title'>４：収益計画</h3><br />
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>２：将来の展望</span><br />
            ① 本事業の実施体制、スケジュール、資金調達計画等について具体的に記載してください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            補助金を活用して行う新規事業の実施体制やスケジュールを記載する必要がございます。<br />
            例えば、金融機関等から資金を調達する事業者様は、どの金融機関にいくら借りるつもりなのか等を記載します。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>２：将来の展望</span><br />
            ② 収益計画（表）における「付加価値額」の算出については、算出根拠を記載してください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            収益計画を記載する際に、必ず「付加価値額」の推移を入れなければいけません。<br />
            なお、付加価値額とは補助金独自の項目であり、営業利益＋（※）人件費＋減価償却費の３点の合計値です。<br />
            ※　人件費…従業員に支払う給与や賞与、役員報酬、福利厚生費、法定福利費の合算<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>２：将来の展望</span><br />
            ③ 収益計画（表）で示された数値は、補助事業終了後も、毎年度の事業化状況等報告等において伸び率の達成状況の確認を行います。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            収益計画で記載した数値の確認が補助事業終了後に行われるため、しっかりと計画を練って記載しましょう。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>将来の展望に記載すべき内容</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業の実施体制、スケジュール</span></li>
              <li><span>資金調達計画</span></li>
              <li><span>事業計画期間（３～５年）における収益計画を記載する→売上高だけでなく、営業利益なども</span></li>
              <li><span>「付加価値額」の推移（営業利益＋人件費＋減価償却費）</span></li>
            </ul>
          </div>
          <h3 className='media_text_text_title'>本項目のまとめ</h3><br />
          <p className="media_text_text">
            このように事業計画書には１～４の大項目が設定されており、その大項目の中に様々な項目を作成した上で内容を記載していく必要があります。<br />
            <br />
            ここまでお伝えした情報は最低限必要となる情報であり、記載すれば必ず採択されるわけではないことを予めご了承下さい。<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>３.審査項目</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築補助金では、<strong>上述した記載項目をもとに、中小企業診断士が以下の審査項目で点数をつけ、採択するかどうかを決めています</strong>。<br />
            <br />
            そのため、記載した内容が以下の審査項目を満たしているかをチェックしていきましょう。<br />
          </p>
          <h3 className='media_text_text_title'>補助対象としての適格性</h3><br />
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>補助対象としての適格性</span><br />
            「４．補助対象事業の要件」を満たすか。補助事業終了後３～５年計画で「付加価値額」年 率平均３.0％（（【グリーン成長枠】については 5.0％））以上の増加等を達成する取組みで あるか。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            補助金を活用して行う<strong>新規事業の収益計画において、「付加価値額」が年率平均3%（３年後に9%）伸びているかといった基準です</strong>。<br />
            この点は事業再構築補助金の申請に必須となる要件ですので、必ず満たすようにしましょう。<br />
          </p>
          <h3 className='media_text_text_title'>事業化点</h3><br />
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>事業化点</span><br />
            ① 本事業の目的に沿った事業実施のための体制（人材、事務処理能力等）や最近の財務状 況等から、補助事業を適切に遂行できると期待できるか。また、金融機関等からの十分 な 資金の調達が見込めるか。※複数の事業者が連携して申請する場合は連携体各者の財務状 況等も踏まえ採点します。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            「４：収益計画」に記載した実施体制と資金調達計画が見られると予想できます。<br />
            <strong>実施体制：従業員の能力が発揮され、事業を遂行できるかといった点</strong><br />
            <strong>資金調達計画：借り入れができるのか、自己資金はあるのかといった点</strong><br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>事業化点</span><br />
            ② 事業化に向けて、競合他社の動向を把握すること等を通じて市場ニーズを考慮するとと もに、補助事業の成果の事業化が寄与するユーザー、マーケット及び市場規模が明確か。 市場ニーズの有無を検証できているか。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            「２：将来の展望」に記載したユーザーとマーケットの２点が見られていると予想されます。<br />
            <strong>ユーザー：具体的に特定できているか</strong><br />
            <strong>マーケット：市場規模などが定量的かつ明確になっているか、ニーズの有無</strong><br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>事業化点</span><br />
            ③ 補助事業の成果が価格的・性能的に優位性や収益性を有し、かつ、事業化に至るまでの遂行方法及びスケジュールが妥当か。補助事業の課題が明確になっており、その課題の解決方法が明確かつ妥当か。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            「２：将来の展望」の<br />
            ①優位性<br />
            ②リスク、課題および解決方法<br />
            「４：収益計画」の<br />
            ③スケジュール<br />
            の３点が見られると予想されます。<br />
            <br />
            この３点が妥当であるか、かつ明確になっているかを重点的に確認してください！<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>事業化点</span><br />
            ④ 補助事業として費用対効果（補助金の投入額に対して増額が想定される付加価値額の規 模、生産性の向上、その実現性等）が高いか。その際、現在の自社の人材、技術・ノウハ ウ等の強みを活用することや既存事業とのシナジー効果が期待されること等により、効果 的な取組となっているか。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            「４：収益計画」の収益計画と「１：補助事業の具体的取組内容」の新規事業内容が見られていると予想できるため、<strong>収益計画においては、投資回収期間など費用対効果を示していく必要があります</strong>。<br />
            <br />
            また、新規事業内容に既存事業とのシナジー効果（相乗効果）を記入する必要もあります。<br />
          </p>
          <h3 className='media_text_text_title'>再構築点</h3><br />
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>再構築点</span><br />
            ① 事業再構築指針に沿った取組みであるか。また、全く異なる業種への転換など、リスクの高い、思い切った大胆な事業の再構築を行うものであるか。※複数の事業者が連携し
            て 申請する場合は、連携体構成員が提出する「連携体各者の事業再構築要件についての説明書類」も考慮し採点します。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            「１：補助事業の具体的取組内容」の事業再構築指針との適合性が見られています。<br />
            また、リスクの高い思い切った事業の再構築であることも示す必要があります。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>再構築点</span><br />
            ② 既存事業における売上の減少が著しいなど、新型コロナウイルスや足許の原油価格・物価高騰等の経済環境の変化の影響で深刻な被害が生じており、事業再構築を行う必要性や緊要性が高いか。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            「１：補助事業の具体的取組内容」の事業再構築の必要性が見られています。<br />
            新型コロナウイルスや原油価格高騰といった外部環境変化によって既存事業の売上が減少していることを示す必要があります。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>再構築点</span><br />
            ③ 市場ニーズや自社の強みを踏まえ、「選択と集中」を戦略的に組み合わせ、リソースの 最適化を図る取組であるか。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            この審査項目に関しては、上述した記載項目には無い点になりますので、新たに項目を用意して適合していることを示していきましょう。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>再構築点</span><br />
            ④ 先端的なデジタル技術の活用、新しいビジネスモデルの構築等を通じて、地域のイノベ ーションに貢献し得る事業か。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            投資内容がデジタル技術の活用やビジネスモデルの構築であり、地域のイノベーションに貢献できうる事業であるかを示す必要があります。そのため、投資内容と地域のイノベーションの繋がりを意識して記載しましょう。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>再構築点</span><br />
            ⑤ 本補助金を活用して新たに取り組む事業の内容が、ポストコロナ・ウィズコロナ時代の 経済社会の変化に対応した、感染症等の危機に強い事業になっているか。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            「１：補助事業の具体的取組内容」の新規事業の内容にポストコロナ・ウィズコロナ時代に対応した事業であることを示す必要があります。<br />
          </p>
          <h3 className='media_text_text_title'>政策点</h3><br />
          <p className="media_text_text">
            以下に記載する政策点に関しては、上述した記載項目にはない要素です。<br />
            そのため、「２：将来の展望」の中に「政策点との合致」という項目を設けて、それぞれの内容に適していることを示していきましょう。<br />
            <br />
            ① <strong>ウィズコロナ・ポストコロナ時代の経済社会の変化に伴い、今後より生産性の向上が見 込まれる分野に大胆に事業再構築を図ることを通じて、日本経済の構造転換を促すことに資するか</strong>。<br />
            <br />
            ② <strong>先端的なデジタル技術の活用、低炭素技術の活用、経済社会にとって特に重要な技術の 活用等を通じて、我が国の経済成長を牽引し得るか</strong>。<br />
            <br />
            ③ <strong>新型コロナウイルスが事業環境に与える影響を乗り越えて V 字回復を達成するために有 効な投資内容となっているか</strong>。<br />
            <br />
            ④ <strong>ニッチ分野において、適切なマーケティング、独自性の高い製品・サービス開発、厳格 な品質管理などにより差別化を行い、グローバル市場でもトップの地位を築く潜在性を有 しているか</strong>。<br />
            <br />
            ⑤ <strong>地域の特性を活かして高い付加価値を創出し、地域の事業者等に対する経済的波及効果を及ぼすことにより、雇用の創出や地域の経済成長（大規模災害からの復興等を含む）を牽引する事業となることが期待できるか</strong>。<br />
            <br />
            ⑥ <strong>異なるサービスを提供する事業者が共通のプラットフォームを構築してサービスを提供 するような場合など、単独では解決が難しい課題について複数の事業者が連携して取組む ことにより、高い生産性向上が期待できるか。また、異なる強みを持つ複数の企業等（大 学等を含む）が共同体を構成して製品開発を行うなど、経済的波及効果が期待できるか</strong>。<br />
          </p>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>４.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            ここまで事業再構築補助金の事業計画書に関する記事を拝見いただき、ありがとうございました。<br />
            <br />
            初めて事業計画書を書く方にとっては大変難しい業務の一つであり、人によっては100時間かかってしまったという事業者様もいらっしゃいます。<br />
            <br />
            そのため、当社を含む民間コンサルティング会社に事業計画書の作成を依頼する事業者様も多く、是非一度ご相談いただければと思います。<br />
            <br />
            この度は、最後まで記事を拝見してくださり、誠にありがとうございました。<br />
            {/* 当社では事業計画書のテンプレートを公開しておりますので、気になる方は以下のリンクをクリックいただきますようお願い致します！<br /> */}
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


