import './styles.css';
import Recruit1_1 from './../../../../../../image/bid/header/2000/1.jpg';
import Recruit2_1 from './../../../../../../image/bid/header/2000/2.jpg';
import Recruit3_1 from './../../../../../../image/bid/header/2000/3.jpg';
import Recruit4_1 from './../../../../../../image/bid/header/2000/4.jpg';
import Recruit1_2 from './../../../../../../image/bid/header/1200/1.jpg';
import Recruit2_2 from './../../../../../../image/bid/header/1200/2.jpg';
import Recruit3_2 from './../../../../../../image/bid/header/1200/3.jpg';
import Recruit4_2 from './../../../../../../image/bid/header/1200/4.jpg';
import Recruit1_3 from './../../../../../../image/bid/header/700/1.jpg';
import Recruit2_3 from './../../../../../../image/bid/header/700/2.jpg';
import Recruit3_3 from './../../../../../../image/bid/header/700/3.jpg';
import Recruit4_3 from './../../../../../../image/bid/header/700/4.jpg';
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Help = () => {

  // state = {
  //   slideIndex: 0,
  //   updateCount: 0
  // };

  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 4000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // afterChange: () => this.setState(state => ({ updateCount: state.updateCount + 1 })),
    // beforeChange: (current, next) => this.setState({ slideIndex: next })
  };

  return (
    <>
    <div className='bid_slider_box'>
      <Slider {...settings} className='bid_slider_2000'>
        <img src={Recruit1_1} className='bid_slider_image' alt='' />
        <img src={Recruit2_1} className='bid_slider_image' alt='' />
        <img src={Recruit3_1} className='bid_slider_image' alt='' />
        <img src={Recruit4_1} className='bid_slider_image' alt='' />
      </Slider>
      <Slider {...settings} className='bid_slider_1200'>
        <img src={Recruit1_2} className='bid_slider_image' alt='' />
        <img src={Recruit2_2} className='bid_slider_image' alt='' />
        <img src={Recruit3_2} className='bid_slider_image' alt='' />
        <img src={Recruit4_2} className='bid_slider_image' alt='' />
      </Slider>
      <Slider {...settings} className='bid_slider_700'>
        <img src={Recruit1_3} className='bid_slider_image' alt='' />
        <img src={Recruit2_3} className='bid_slider_image' alt='' />
        <img src={Recruit3_3} className='bid_slider_image' alt='' />
        <img src={Recruit4_3} className='bid_slider_image' alt='' />
      </Slider>
    </div>
    </>
  );
}

export default Help;