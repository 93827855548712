import './styles.css';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import firebase from "firebase/compat/app"

const Help = (props) => {

  function btn() {
    firebase.firestore().collection('analytics').doc(props.uid)
    .update({
      button3: true,
    })
  }
  
  return (
    <>
    <div className='hojokin_QA'>
      <h4>よくあるご質問</h4>
      <div className='hojokin_QA_box'>
        <div className='hojokin_QA_q'>
          <h3>Q</h3>
          <h4>ご相談に費用はかかりますか？</h4>
        </div>
        <div className='hojokin_QA_a'>
          <h3>A</h3>
          <h4>初回相談は完全無料で承っております。全員10件以上の事業再構築補助金採択実績を有している弊社の専門コンサルタントが対応いたしますので、是非１度お気軽にご連絡ください。</h4>
        </div>
        <div className='hojokin_QA_q'>
          <h3>Q</h3>
          <h4>補助金はいくら程度もらえますか？</h4>
        </div>
        <div className='hojokin_QA_a'>
          <h3>A</h3>
          <h4>事業再構築補助金で補助される金額は、投資金額や貴社の従業員数、申請枠により変動します。当社では初回相談時に貴社の既存事業や新規事業の内容をお伺いしたうえで、最適な申請枠のご提案を行います。その際に対象となる経費の金額や大まかな補助金額もお伝えいたします。</h4>
        </div>
        <div className='hojokin_QA_q'>
          <h3>Q</h3>
          <h4>既に支払っている経費は対象になりますか？</h4>
        </div>
        <div className='hojokin_QA_a'>
          <h3>A</h3>
          <h4>補助金が採択される前に支払っている経費金額は、基本的に対象にはなりません。しかし、2022年12月2日以降の経費金額であれば事前着手制度を用いることで対象となりえます。弊社では事前着手制度のサポートも行っておりますので、上記日付以降に事業を開始されたお客様であれば、事業再構築補助金をご活用いただくことが可能です。</h4>
        </div>
        <div className='hojokin_QA_q'>
          <h3>Q</h3>
          <h4>一度不採択になってしまったのですが、申請は可能でしょうか？</h4>
        </div>
        <div className='hojokin_QA_a'>
          <h3>A</h3>
          <h4>一度不採択になってしまったお客様や他社様にお断りされたお客様へも、弊社では申請サポートを行っております。不採択理由や計画書の完成具合に応じた値引き等へも対応しておりますので、弊社と共に採択をつかみ取りましょう。</h4>
        </div>
      </div>
      <AnchorLink href="#1" offset="170"><div className='hojokin_QA_btn' onClick={() => btn()}>相談する</div></AnchorLink>
    </div>
    </>
  );
}

export default Help;
