import './styles.css';
import React from "react";
import { Link } from 'react-router-dom';
import Icon from "./../../../../image/photo/icon.png";

const PhotoNav = () => {

  return (
    <>
    <div className='photo_nav'>
      <Link to="/" className='photo_nav_icon'>
        <img src={Icon} loading="lazy" title="memoriiのアイコン" alt='画像のurl' />
        <h2>memorii</h2>
      </Link>
      <div className="photo_nav_items">
        <Link to="/company" className='photo_nav_items_company'>会社概要</Link>
        <Link to="/contact" className='photo_nav_items_contact'>問い合わせ</Link>
      </div>
    </div>
    </>
  );
}

export default PhotoNav;
