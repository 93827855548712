import './styles.css';
import SliderPc from './pc/index';
import SliderTablet from './tablet/index';

const Footer = () => {
  
  return (
    <>
    <div className='test_homepage_pc'>
      <SliderPc />
    </div>
    <div className='test_homepage_tablet'>
      <SliderTablet />
    </div>
    </>
  );
}

export default Footer;
