import React from "react";
import './styles.css';
import Icon from './../../../../image/icon.png';
import { useState, useEffect } from "react";

const Taskheader = (props) => {

  const weekday = ['日', '月', '火', '水', '木', '金', '土'];

  const [date, setDate] = useState([]);
  const [time, setTime] = useState([]);

  useEffect(() => {
    setInterval(() => {
      let d = new Date();
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let day = d.getDate();
      let dayofweek = d.getDay();
      setDate(year + '年' + month + '月' + day + '日' + '（' + weekday[dayofweek] + '）');
      let hour = d.getHours().toString().padStart(2, '0');
      let minute = d.getMinutes().toString().padStart(2, '0');
      setTime(hour + ':' + minute);
    }, 1000);
  }, []);

  return (
    <>
    <div className="task_nav">
      <img src={Icon} alt='' onClick={() => props.setMyPage(true)} />
      <h4>{date}<span>{time}</span></h4>
      <div>
        <img src='https://images.wantedly.com/i/Dj4Gxg4?w=64&h=64' alt='' />
        <p>大林 亜治英</p>
      </div>
    </div>
    </>
  );
};

export default Taskheader;
