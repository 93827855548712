// 教育・学習支援業
// https://docs.google.com/document/d/1r_OBs_PD0M1Y1zYOVYES0l8G7eKoKQWIkOxHHziUfx0/edit#heading=h.qpm84vb03llk

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "../../nav/index";
import Topic from "../topic";
import TopImage from "./../../../../../image/media/business/49/top.png";
import Image1 from "./../../../../../image/media/business/49/1.png";
import Footer from '../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」教育、学習支援業の採択事例を大公開！',
    description: '本記事では、事業再構築補助金の教育・学習支援業 採択事例を紹介しております。飲食業を営んでいて事業再構築補助金の申請を検討されている方は、本記事をご参考に検討を行っていただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」教育、学習支援業の採択事例を大公開！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            学習塾をはじめ各種教室運営など、対面形式でのサービス提供が多い教育、学習支援業は新型コロナウイルスの影響を大きく受けた事業のひとつです。<br />
            <br />
            減少した売上を回復しようと、事業再構築補助金を検討している方も多いのではないでしょうか。<br />
            <br />
            本記事では、事業再構築補助金を検討中の方向けに以下の3つのポイントについて解説します。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業再構築補助金の対象となる教育・学習支援業者</span></li>
              <li><span>教育・学習支援業における事業再構築補助金の活用方法</span></li>
              <li><span>過去の教育・学習支援業者採択例</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            ぜひ参考にしてみてください。<br />
            <br />
            本記事は最新の事業再構築補助金の公募要領に基づいて作成しています。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo.pdf' target="_blank" rel="nofollow noopener noreferrer">
              リンク：事業再構築補助金 公募要領
            </a><br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">教育、学習支援業における活用方法</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金の対象となる経費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">教育、学習支援業での事業再構築類型の具体例</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金での申請例</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">虎の巻から見る教育、学習支援業者の特徴</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">o	教育、学習支援業者の申請傾向</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">o	具体的な採択事例</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">o	教育、学習支援業の申請で抑えるべきポイント</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.教育・学習支援業における活用方法</h2>
          </div>
          <p className="media_text_text">
            <br />
            まずは、教育、学習支援業における活用方法について以下のポイントに分けて解説します。<br />
            <strong>1.事業再構築補助金の対象となる経費</strong><br />
            <strong>2.教育・学習支援業での事業再構築類型の具体例</strong><br />
            <strong>3.事業再構築補助金での申請例</strong><br />
            それぞれ詳しく見ていきましょう。<br />
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金の対象となる経費</h3><br />
          <p className="media_text_text">
            まずは経費について。<br />
            事業再構築補助金の対象経費は細かく分類されており、具体的には以下の11種類に分類されます。<br />
            <br />
            ①建物費<br />
            ②機械装置・システム構築費<br />
            ③技術導入費<br />
            ④専門家経費<br />
            ⑤運搬費<br />
            ⑥クラウドサービス利用費<br />
            ⑦外注費<br />
            ⑧知的財産権等関連経費<br />
            ⑨広告宣伝・販売促進費<br />
            ⑩研修費<br />
            ⑪廃業費<br />
            <br />
            教育、学習支援業の場合、既存事業をオンラインを活用した事業へ転換したり、既存事業で使用している物件を改装して新たな事業を開始したりするケースが多く、<br />
            ・建物費<br />
            ・機械装置・システム構築費<br />
            ・広告費<br />
            これらの経費が主に活用されています。<br />
            <br />
            <strong>【経費の注意点】</strong><br />
            事業再構築補助金の経費は様々な用途に活用できる反面、対象外経費の種類も非常に多くあります。<br />
            <br />
            たとえば、建物費の場合。<br />
            <br />
            建物費に関しては、改装や建物を新築する場合には経費として認められます。<br />
            しかし、単なる物件の購入や賃貸は対象外経費になるため注意が必要です。<br />
            <br />
            ほかにも、既存事業にも使用できるようなパソコンやタブレット、スマホなど汎用性の高いものも対象外経費となるため注意が必要です。<br />
            <br />
            事業再構築補助金の経費は新事業でのみ活用できるものでなければなりません。<br />
            <br />
            対象経費と思っていたものが実は対象外経費だったということが多々ありますので、申請の際は事前にしっかりと経費について調べておくことが重要です。<br />
            <br />
            もしも、経費のことでお困りの場合は当社にご相談下さい。<br />
            当社では現在、初回無料で経費の相談を行っております。<br />
            「経費のことを調べてみたけれど、複雑でよくわからない」「間違っていないか不安」という方は、ご相談いただければ対象経費の確認はもちろんのこと、金額の算出まで当社がお手伝いいたします。<br />
          </p>
          <h3 className='media_text_text_title'>教育・学習支援業での事業再構築類型の具体例</h3>
          <p className="media_text_text">
            続いては、教育、学習支援業での事業再構築類型の具体例について解説します。<br />
            <br />
            事業再構築補助金では、申請にあたり要件が定められており、その要件のひとつに「類型」というものがあります。<br />
            「類型」は、「新市場進出」「事業転換」「業種転換」の3種類あり、いずれかに該当することで要件を満たし、申請が可能になります。<br />
            3種類の類型の詳細については以下のとおりです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th>類型を満たすための要件</th><th>産業分類に基づく変更</th>
              </tr>
              <tr>
                <td>新市場進出</td>
                <td>
                  ①製品又は提供方法を大きく変更する<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全体の10%以上となる計画を策定する。
                </td>
                <td>無</td>
              </tr>
              <tr>
                <td>事業転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。<br />
                </td>
                <td>主な事業を変更(中・小・細分類)</td>
              </tr>
              <tr>
                <td>業種転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3～5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。
                </td>
                <td>主な業種を変更<br />(大分類)</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            これらの類型を教育、学習支援業に当てはめると次のとおりです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th>日本産業分類での変更</th><th>変更内容 </th>
              </tr>
              <tr>
                <td>新市場進出</td>
                <td>分類は「823：学習塾→823：学習塾」のままで変更なし。</td>
                <td>既存の対面での学習塾運営から、オンライン授業サービスを開始し販路を拡大する。</td>
              </tr>
              <tr>
                <td>事業転換</td>
                <td>小分類が「823：学習塾→824：教養・技能教授業」へと変更。<br /></td>
                <td>学習塾から社会人向けのオンライン英会話教室へ事業転換。</td>
              </tr>
              <tr>
                <td>業種転換</td>
                <td>大分類が「O：教育、学習支援業→M：宿泊、飲食サービス業」へと変更。</td>
                <td>茶道教室の経営が苦しくなったため、抹茶をメインとしたカフェ経営を開始。</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            上記を参考に、ご自身の事業がどの類型に該当するのか事前にしっかり確認しておきましょう。<br />
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金での申請例</h3>
          <p className="media_text_text">
            事業再構築補助金の経費や類型を理解していただいたところで、続いては具体的な補助金額の算出方法を申請例をもとに解説します。<br />
            <br />
            なお、申請枠や従業員数によって補助金額は変わりますので、ご自身が申請する際は事前確認をしっかり行ったうえで臨むようにしましょう。<br />
            <br />
            <strong>【事業内容】</strong><br />
            小中学生を対象とした学習塾を経営してきたが、新型コロナウイルスの影響により入塾者数が減少。長年培ってきたノウハウを活かし、幼児〜小学校低学年を対象とした学童保育サービスを開始する。<br />
            <br />
            ・事業場所：埼玉県さいたま市<br />
            ・従業員数：7名<br />
            ・売上高：2,000万円<br />
            <br />
            <strong>【日本産業分類から類型を決定】</strong><br />
            学習塾経営から学童保育サービス事業へ進出する場合、日本産業分類は以下のように変化します。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>日本産業分類</th><th>大分類</th><th>中分類</th><th>小分類</th><th>細分類</th>
              </tr>
              <tr>
                <td>既存事業</td><td>O：教育、学習支援業</td><td>82：その他の教育、学習支援業</td><td>823：学習塾</td><td>8231：学習塾</td>
              </tr>
              <tr>
                <td>新規事業</td><td>P：医療、福祉</td><td>85：社会保険・社会福祉・介護事業</td><td>853：児童福祉事業</td><td>8539：その他の児童福祉事業</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            日本産業分類の大分類が「O：教育、学習支援業→P：医療、福祉」へと変わったことから、本事業は「業種転換」として申請を進めます。<br />
            <br />
            <strong>【売上高から申請枠を決定】</strong>
            下表は、2021年と2022年の同月の売上高を比較したものです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'></th><th>6月</th><th>7月</th><th>8月</th><th>合計</th>
              </tr>
              <tr>
                <td>2021年</td><td>210</td><td>220</td><td>200</td><td>630</td>
              </tr>
              <tr>
                <td>2022年</td><td>170</td><td>170</td><td>150</td><td>490</td>
              </tr>
              <tr>
                <td>増減率(%)</td><td>-20%</td><td>-23%</td><td>-25%</td><td>-23%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            事業再構築補助金では、2022年1月以降の連続する6か月間のうち、任意の3か月の合計売上高が対2019～2021年の同3か月の合計売上高と比較して10％減少していれば「物価高騰対策・回復再生応援枠」という申請枠での申請が可能です。<br />
            <br />
            上記の売上高を比較すると、2022年は前年より23％も減少しており、要件を満たしています。<br />
            <br />
            そのため、本事業は「物価高騰対策・回復再生応援枠」で申請をします。<br />
            <br />
            <strong>【補助金額の計算】</strong><br />
            本事業の申請枠が決定したので、補助金額の計算へと進みます。<br />
            <br />
            なお、本事業の経費については以下のとおり。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>建物の改修費</th><th>￥5,000,000</th>
              </tr>
              <tr>
                <td>設備費</td><td>￥1,000,000</td>
              </tr>
              <tr>
                <td>広告費</td><td>￥900,000</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            本事業の場合、上記の経費はすべて対象となるため、申請金額は合計で6,900,000円になります。<br />
            <br />
            補助金額については、中小企業の場合、申請金額の2/3が補助されます。<br />
            また、従業員数が6〜20人の場合には、600万円までは3/4が補助されます。<br />
            <br />
            以上の条件をもとに計算を行うと補助金額は以下のとおりです。<br />
            <br />
            <strong>【補助金額の計算式】</strong><br />
            （補助率　2/3）900,000×2/3＝600,000（1）<br />
            （従業員数による補助率　3/4）6,000,000×3/4＝4,500,000（2）<br />
            <br />
            （1）＋（2）＝5,100,000円<br />
            <br />
            本事業の場合、申請金額6,900,000円に対し、最大5,100,000円が補助されます。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.虎の巻から見る教育、学習支援業者の特徴</h2>
          </div>
          <p className="media_text_text">
            <br />
            虎の巻とは、経済産業省が公表する事業再構築補助金に関する情報です。<br />
            <br />
            事業検討の考え方<br />
            有望な事業に共通する特徴<br />
            <br />
            など、公募要領だけでは得られない情報がたくさん掲載されています。<br />
            <br />
            動画やデータを用いて事業再構築補助金についてわかりやすく解説されているので、申請を検討中の方はぜひご覧ください。<br />
            <br />
            本記事でも、虎の巻をもとに、<br />
            教育、学習支援業者の申請傾向<br />
            具体的な採択事例<br />
            教育、学習支援業の申請で抑えるべきポイント<br />
            について解説します。<br />
          </p>
          <h3 className='media_text_text_title'>教育、学習支援業者の申請傾向</h3>
          <p className="media_text_text">
            <img src={Image1} className='media_img' alt='' />
            上図は虎の巻に掲載されている教育、学習支援業の採択傾向に関するデータです。<br />
            縦軸が申請数、横軸が採択率を表しており、右側のオレンジ部分にいくほど有望度が高い事業ということになります。<br />
            <br />
            教育、学習支援業の場合、<br />
            <br />
            オンライン技術の活用<br />
            英語教育関連事業<br />
            音楽関連事業<br />
            <br />
            などが、有望度の高い事業に分類されていることがわかります。<br />
            <br />
            いずれも、オンライン設備を導入した非対面のサービスへ展開する事業が申請件数、採択率ともに高いのが特徴です。<br />
            <br />
            学習塾や英会話教室、音楽教室など、既存の対面形式のサービスから非対面のオンラインサービスへと新型コロナウイルスへの対応が採択率の高さに反映されていると言えるでしょう。<br />
          </p>
          <h3 className='media_text_text_title'>具体的な採択事例</h3>
          <p className="media_text_text">
            教育、学習支援業の採択事例を2つ紹介します。<br />
            <br />
            <strong>①小人数・個別指導の学習塾が授業のオンライン配信を開始し、新型コロナウイルスに配慮した事業を行う</strong><br />
            これまで少人数・個別指導の塾経営を行ってきたが、新型コロナウイルスの影響で生徒数が減少し、売上が低迷。<br />
            非対面でサービスを提供できるオンライン授業を開始し、新型コロナウイルスに配慮した事業を行うとして採択された事例です。<br />
            <br />
            オンラインサービスを開始することで、販路を拡大できることはもちろん非対面で新型コロナウイルスにも配慮している点も審査において高く評価されたと言えるでしょう。<br />
            <br />
            <strong>②美容教育業からオンライン診断を入り口とした脱毛・アートメイク事業を展開</strong><br />
            美容教育事業として美容資格スクールを運営してきたが、新型コロナウイルスの影響により受講希望者が減少。<br />
            有資格者によるオンライン診断を入り口とした脱毛・アートメイク事業へ進出し、売上げ回復を目指すとして採択された事例です。<br />
            <br />
            本事業に関しては、先ほど紹介した事例と同様にオンラインサービスの導入による新型コロナウイルス対策が講じられている点が評価されたと言えるでしょう。<br />
            <br />
            ほかにも、本事業では事前に既存顧客へのアンケートも実施しており、好意的な回答が多く得られたこともアピールしています。<br />
            <br />
            事業再構築補助金では、事前の市場調査をしっかり行って売上げ計画の根拠を説明できることも重要なため、本事業のようにしっかりと準備をしておくことも大切です。<br />
          </p>
          <h3 className='media_text_text_title'>宿泊事業の申請で抑えるべきポイント</h3>
          <p className="media_text_text">
            <strong>①新型コロナウイルスへの対応</strong><br />
            <br />
            教育、学習支援業の場合、サービスの性質上、人と人との物理的な接触が多い傾向にあります。<br />
            事業再構築補助金では、新型コロナウイルスへの対応が重視されるため、採択を目指す場合はこれまでの対面サービスから非対面サービスへの切り替えなどが重要となります。<br />
            <br />
            たとえば、<br />
            対面式の学習塾→オンライン授業<br />
            対面式の英会話教室→オンライン授業<br />
            などが挙げられます。<br />
            <br />
            ただし、教育、学習支援業の場合、オンラインサービスへの進出は人気のテーマであるため採択を目指すのであれば、他のサービスと差別化を図るなど工夫が必要です。<br />
            <br />
            しっかり計画を練ったうえで申請をしましょう。<br />
            <br />
            <strong>②既存事業のノウハウを活かす</strong><br />
            新事業の見通しの立てやすさ、取り組みやすさという点から、既存事業のノウハウを活かせる事業へ進出することが重要です。<br />
            <br />
            たとえば、料理教室から飲食業へ展開する場合。<br />
            <br />
            このケースであれば、すでに料理のスキルがあるため既存事業のノウハウを新事業に活かすことが可能でしょう。<br />
            <br />
            一方で、学習塾経営から飲食業へ展開する場合。<br />
            <br />
            このケースだと、これまでのノウハウを活かせず新事業を開始する場合はすべて一から取り組まなければなりません。<br />
            市場についても詳しくないため、審査段階で売上の見通しは大丈夫なのだろうかと判断され不採択になる可能性があります。<br />
            <br />
            事業再構築補助金に申請する場合、既存事業のノウハウを活かすことも採択されるための重要なポイントのひとつと言えるでしょう。<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            教育、学習支援業の場合、既存事業のサービスをオンラインで提供できる事業へ進出する傾向が高いのが特徴です。<br />
            <br />
            新型コロナウイルスも徐々に落ち着きを見せ始めていますが、以前に比べて非対面のサービスは需要が高くなっています。<br />
            <br />
            そのため、事業再構築補助金においてもまだまだ狙い目の分野であると言えるでしょう。<br />
            <br />
            事業再構築補助金に興味のある方はぜひ本記事を参考に申請を検討してみてください。<br />
            <br />
            ただし、事業再構築補助金は非常に複雑で、ご自身で計画から申請まですべてを行うのは非常に大変です。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;



