// 物価高騰対策・回復再生応援枠

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "../../nav/index";
import Topic from "../topic";
import TopImage from "./../../../../../image/media/business/28/top.png";
import Image1 from "./../../../../../image/media/business/20/2.png";
import Image2 from "./../../../../../image/media/business/20/3.png";
import Footer from '../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」物価高騰対策・回復再生応援枠',
    description: '本記事では、物価高騰対策・回復再生応援枠の申請要件、補助率、補助金額について解説をしています。何を満たせば申請できるのか？や物価高騰対策・回復再生応援枠の特徴を知りたいという方は向けの記事となっておりますので、補助金を申請したいという方はぜひいご覧いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」物価高騰対策・回復再生応援枠</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。<strong>経済産業省関連の補助金申請を支援しているトランク合同会社</strong>です。<br />
            <br />
            主に弊社では4大補助金と言われている<strong>「事業再構築補助金」「ものづくり補助金」「小規模事業者持続化補助金」「IT導入補助金」の申請をサポート</strong>をさせていただいております。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>物価高騰対策・回復再生応援枠とは？</span></li>
              <li><span>物価高騰対策・回復再生応援枠の要件がわからない！</span></li>
              <li><span>物価高騰対策・回復再生応援枠の要件、補助率など詳しい情報が知りたい！</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事では<strong>事業再構築補助金の公募要領を参考に執筆しております</strong>ので、本記事をご一読いただきました後に、改めて公募要領に目を通していただければと思います。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo.pdf' target="_blank" rel="nofollow noopener noreferrer">
              リンク：事業再構築補助金 公募要領
            </a><br />
            <br />
            初めて補助金を知った方は読解に時間がかかると思いますが、申請まで行っていただければと思います。<br />
            <br />
            また、上述で記載した通り、当社でも申請のサポートを行っておりますので、ぜひご不明点がござましたら、ご連絡いただければ思います。<br />
            <br />
            では、本題である物価高騰対策・回復再生応援枠の解説へ進めさせていただきます！<br />
            <br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">物価高騰対策・回復再生応援枠の概要</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">物価高騰対策・回復再生応援枠の概要</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">物価高騰対策・回復再生応援枠の補助金額</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">物価高騰対策・回復再生応援枠の補助率</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">物価高騰対策・回復再生応援枠の補助対象経費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">物価高騰対策・回復再生応援枠の要件</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">各要件の解説</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">事業再構築要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">認定支援機関要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">付加価値額要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">市場拡大要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">給与総額増加要件</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">物価高騰対策・回復再生応援枠のポイント</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.物価高騰対策・回復再生応援枠枠の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            <strong>物価高騰対策・回復再生応援枠とは主に売上高が減少している事業者</strong>、もしくは、<strong>事業再生を行うために中小企業活性化協議会等において再生計画を作成している事業者</strong>へ向けた申請枠となります。<br />
            <br />
            具体的な要件については、次章で詳しく解説させていただいておりますので、ご確認いただけますと幸いです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>項目</th><th>要件</th>
              </tr>
              <tr>
                <td>概要</td>
                <td>業況が厳しい事業者や事業再生に取り組む中小企業等、原油価格・物価高騰等の影響を受ける中小企業等の事業再構築を支援。</td>
              </tr>
              <tr>
                <td>補助金額</td>
                <td>
                【従業員数 5 人以下】 100 万円 ～ 1,000 万円<br />
                【従業員数６~20 人】 100 万円 ～ 1,500 万円<br />
                【従業員数 21~50 人】 100 万円 ～ 2,000 万円<br />
                【従業員 51 人～】 100 万円 ～ 3,000 万円
                </td>
              </tr>
              <tr>
                <td>補助率</td>
                <td>
                中小企業者等 2/3<br />
                中堅企業等 1/2
                </td>
              </tr>
              <tr>
                <td>補助対象経費</td>
                <td>
                  建物費、機械装置・システム構築費（リース料を含む）、技術導入費、専門家経費、運搬費、クラウドサービス利用費、外注費、知的財産権等関連経費、広告宣伝・販売促進費、研修費<br />
                </td>
              </tr>
              <tr>
                <td>要件</td>
                <td>
                  ・事業再構築要件<br />
                  ・認定支援機関要件<br />
                  ・付加価値額要件<br />
                  ・売上高等減少要件 or 再生要件
                </td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>物価高騰対策・回復再生応援枠の概要</h3><br />
          <p className="media_text_text">
            物価高騰対策・回復再生応援枠枠は、<strong>業況が厳しい事業者や事業再生に取り組む中小企業等、原油価格・物価高騰等の影響を受ける中小企業等の事業再構築を支援。</strong>となっております。<br />
            <br />
            そのため、<strong>物価高騰対策・回復再生応援枠枠では売上の減少を示す要件、もしくは新型コロナウイルス・物価高騰の影響を受けていることを証明することが要件</strong>となっております。<br />
            <br />
            原油価格・物価高騰の影響の影響を受けている事業者様は多くいらっしゃると思いますので、思い当たる節がある方はぜひ一度要件をご確認いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>物価高騰対策・回復再生応援枠枠の補助金額</h3><br />
          <p className="media_text_text">
            物価高騰対策・回復再生応援枠枠の補助金額は上記の表で記載した通り、以下のように定義されております。<br />
            <strong>【従業員数 5 人以下】 100 万円 ～ 1,000 万円</strong><br />
            <strong>【従業員数６~20 人】 100 万円 ～ 1,500 万円</strong><br />
            <strong>【従業員数 21~50 人】 100 万円 ～ 2,000 万円</strong><br />
            <strong>【従業員 51 人～】 100 万円 ～ 3,000 万円</strong><br />
            <br />
            この際に、確認していただきたい点は、<strong>従業員数の算出方法</strong>になります。<br />
            例えば、役員は従業員に含まれない。アルバイトは含まれる。など算出方法がさまざまですので、お間違いないよう計算いただければと思います。<br />
            <a href='https://www.chusho.meti.go.jp/faq/faq/faq01_teigi.htm#q3' target="_blank" rel="nofollow noopener noreferrer">
              参考サイト：従業員数の算出方法
            </a><br />
          </p>
          <h3 className='media_text_text_title'>物価高騰対策・回復再生応援枠枠の補助率</h3><br />
          <p className="media_text_text">
            補助率は上述に示している通り、以下のようなものとなっております。<br />
            <strong>中小企業者等 2/3</strong><br />
            <strong>中堅企業等 1/2</strong><br />
            <br />
            <strong>１）補助金額の計算例：従業員数5名、投資金額1,000万円の場合、補助金額は666万円</strong><br />
            物価高騰対策・回復再生応援枠枠を申請した事業者様が従業員数5名、投資金額1,000万円の場合、中小企業のため、補助率は2/3であるため、1,000万円の2/3で補助金額は666万円、自己負担額は334万円となります。<br />
            <br />
            <strong>２）補助金額の計算例：従業員数5名、投資金額3,000万円の場合、補助金額は1,000万円</strong><br />
            物価高騰対策・回復再生応援枠枠を申請した事業者様が従業員数5名、投資金額3,000万円の場合、中小企業のため、補助率は2/3であるため、3,000万円の2/3で補助金額は2,000万円のはずですが、従業員数 5 人以下の場合1,000万円が上限とされているため、補助金額は1,000万円となり、自己負担額は2,000万円となります。<br />
            <br />
            <strong>上限金額以上金額（投資金額）については補助されません</strong>ので、補助率だけでなく補助上限の確認も行った上で申請、及び財務計画を立てていただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>物価高騰対策・回復再生応援枠枠の補助対象経費</h3><br />
          <p className="media_text_text">
            物価高騰対策・回復再生応援枠枠の補助対象経費は、物価高騰対策・回復再生応援枠枠の概要で示した通り、以下のものとなっております。<br />
            <strong>建物費、機械装置・システム構築費（リース料を含む）、技術導入費、専門家経費、運搬費、クラウドサービス利用費、外注費、知的財産権等関連経費、広告宣伝・販売促進費、研修費</strong><br />
            <br />
            上記の費用が具体的に何に使えるのか？については、以下の記事にて詳しく記載しておりますので、ご参照いただければと思います。<br />
            <a href='https://trankllc.com/media/subsidy/business/0003' target="_blank" rel="nofollow noopener noreferrer">
              参考：事業再構築補助金 12個の経費分類を具体例つきで解説！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>物価高騰対策・回復再生応援枠枠の要件</h3><br />
          <p className="media_text_text">
            物価高騰対策・回復再生応援枠枠の要件は、物価高騰対策・回復再生応援枠枠の概要で示した通り、以下のものとなっております。<br />
            <strong>・事業再構築要件</strong><br />
            <strong>・認定支援機関要件</strong><br />
            <strong>・付加価値額要件</strong><br />
            <strong>・売上高等減少要件</strong><br />
            <br />
            各要件については、次章で1つずつ解説しておりますので、ご確認いただければと思います。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.各要件の解説</h2>
          </div>
          <p className="media_text_text">
            <br />
            上述の表で、物価高騰対策・回復再生応援枠枠の要件を示した通り、本枠での申請を検討されている方は、以下の5点の要件を満たす必要がございます。<br />
            <br />
            下記にて、1つずつ説明しておりますので、ご確認いただければと思います。<br />
            <br />
            <strong>①	事業再構築要件</strong><br />
            <strong>②	認定支援機関要件</strong><br />
            <strong>③	付加価値額要件</strong><br />
            <strong>④	売上高等減少要件</strong><br />
            <strong>⑤	再生事業者</strong><br />
          </p>
          <h3 className='media_text_text_title'>事業再構築要件</h3><br />
          <p className="media_text_text">
            事業再構築要件とは、<strong>新分野展開、事業転換、業種転換、業態転換又は事業再編のいずれかに該当する事業計画でなければいけない</strong>という要件です。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・新分野展開、事業転換、業種転換、業態転換又は事業再編のいずれかに該当する事業計画を策定すること<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・事業計画書<br />
            <br />
            <strong>参考記事</strong><br />
            上記５点の（新分野展開、事業転換、業種転換、業態転換又は事業再編）についての詳しい記事を別途作成させていただきましたので、ぜひご参照いただければと思います。<br />
            <a href='https://trankllc.com/media/subsidy/business/0007' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【類型・転換って何？】事業再構築補助金の再構築要件を、例を用いて解説！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>認定支援機関要件</h3><br />
          <p className="media_text_text">
            認定支援機関要件とは、<strong>国に中小企業支援の知識・経験がある者として認められた認定支援機関に事業計画書を確認してもらわなければいけない</strong>という要件です。<br />
            <br />
            認定支援機関とは、<strong>中小企業支援の知識・経験がある者として、国の認定を受けた機関であり、士業や商工会、金融機関が多く認可されています</strong>。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・認定支援機関と相談したうえで事業計画書を策定する。<br />
            ※認定経営革新等支援機関による確認書は、認定支援機関に相談したことを証明する書類ですので、必ず認定支援機関に作成をお願いしてください。<br />
            <br />
            <strong>【要件を満たすために必要な書類】</strong><br />
            ・認定経営革新等支援機関による確認書<br />
            ・金融機関による確認書(補助金額が3,000万円を超える場合)<br />
            <br />
            認定支援機関要件は、下記の記事にて詳しく解説しておりますので、詳細が知りたい方はぜひご参照ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0009' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【どこに依頼する？】事業再構築補助金の認定支援機関要件を機関別に解説！
            </a><br />
            <br />
            おすすめの認定支援機関や認定支援機関別の採択率は、下記の記事でまとめて記載しておりますので、下記リンクよりご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0004' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【採択率はどれくらい？】事業再構築補助金の過去採択を徹底解剖！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>付加価値額要件</h3><br />
          <p className="media_text_text">
            付加価値額要件とは、<strong>付加価値額が上昇する事業計画を策定しなければいけない</strong>という要件です。<br />
            <br />
            付加価値額とは、補助金独自の考え方であり、以下の方法により算出できます。<br />
            <span>付加価値額=営業利益+減価償却費+人件費</span><br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・補助事業終了後3~5年で、付加価値額の年平均率を3.0%以上増加させること<br />
            ・補助事業終了後3~5年で、従業員1人当たりの付加価値額の年平均率3.0%以上増加させること<br />
            ※上記のどちらかを満たす事業計画を策定することが要件となります。<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・事業計画書<br />
            <br />
            上記の要件を満たした事業計画書を策定することが必要になりますので、認定支援機関と共にご確認をお願いいたします。<br />
          </p>
          <h3 className='media_text_text_title'>売上等減少要件要件</h3><br />
          <p className="media_text_text">
            売上等減少要件要件とは、<strong>2022年1月以降の連続する6か月の内、任意の3か月(物価高騰後)と2019年～2021年の同月(物価高騰前)で比較した際、総売上高が10%以上減少していること</strong>で要件を満たすことできます。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・2022年1月以降の連続する6か月の内、任意の3か月(物価高騰後)と2019年～2021年の同月(物価高騰前)で比較した際、総売上高が10%以上減少していること<br />
            <br />
            売上減少を計算する際の具体的な手順は、下記の記事でまとめて記載しておりますので、下記リンクよりご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0008' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【10%・15%】事業再構築補助金の売上高等減少要件を具体例で解説！
            </a><br />
            <br />
            <strong>【法人の場合 要件を満たすために提出する書類】</strong><br />
            <br />
            ・物価高騰前の、選択した任意3か月の売上が分かる年度確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の法人事業概況説明書の控え(両面)<br />
            ・受信通知(1枚)(e-Taxで申告している場合のみ)<br />
            ・物価高騰後の、選択した任意3か月の売上が分かる確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の法人事業概況説明書の控え(両面)<br />
            <br />
            なお、上記書類の図例を下記に記載いたしますので、こちらも参考にしてご準備ください。<br />
            <br />
            <strong>【個人の場合 要件を満たすために提出する書類】</strong><br />
            <br />
            ・物価高騰前の、選択した任意3か月の売上が分かる年度確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の月別売上の記載がある所得税青色申告決算書の控え(両面)<br />
            (白色申告の方は、対象月の売上が分かる売上台帳や、確定申告の基礎となる書類)<br />
            ・受信通知(1枚)(e-Taxで申告している場合のみ)<br />
            ・物価高騰後の、選択した任意3か月の売上が分かる年度確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の月別売上の記載がある所得税青色申告決算書の控え(両面)<br />
            (白色申告の方は、対象月の売上が分かる売上台帳や、確定申告の基礎となる書類)<br />
            <br />
            <img src={Image1} className='media_img' alt='' />
            <br />
            所得税青色申告決算書の控え<br />
            <img src={Image2} className='media_img' alt='' /><br />
            <br />
            ・原油価格・物価高騰等の影響を受けている宣誓書<br />
            なお、上記宣誓書に関しましては、下記URLより「足許で原油価格・物価高騰等の経済環境の変化の影響を受けていることの宣誓書」をダウンロードいただき、作成を行ってください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              リンク：足許で原油価格・物価高騰等の経済環境の変化の影響を受けていることの宣誓書のダウンロード
            </a><br />
          </p>
          <h3 className='media_text_text_title'>再生要件</h3><br />
          <p className="media_text_text">
            再生要件とは中小企業活性化協議会等から支援を受けており、以下のいずれかに該当している必要がございます。<br />
            <br />
            1.中小企業活性化協議会（旧：中小企業再生支援協議会）が策定を支援した再生計画<br />
            2.独立行政法人中小企業基盤整備機構が策定を支援した再生計画<br />
            3.産業復興相談センターが策定を支援した再生計画<br />
            4.株式会社整理回収機構が策定を支援した再生計画<br />
            5.「私的整理に関するガイドライン」に基づいて策定した再建計画<br />
            6.中小企業の事業再生等のための私的整理手続（中小企業版私的整理手続）に基づいて策定した再生計画（令和４年４月１５日から適用開始）<br />
            7.産業競争力強化法に基づき経済産業大臣の認定を受けた認証紛争解決事業者（事業再生ＡＤＲ事業者）が策定を支援した事業再生計画<br />
            8.独立行政法人中小企業基盤整備機構が出資した中小企業再生ファンドが策定を支援した再生計画<br />
            9.株式会社東日本大震災事業者再生支援機構が同機構法第 19 条の規定による支援決定を行った事業再生計画<br />
            10.株式会社地域経済活性化支援機構が株式会社地域経済活性化支援機構法第 25条の規定による再生支援決定を行った事業再生計画<br />
            11.特定調停法に基づく調停における調書（同法第 17 条第１項の調停条項によるものを除く。）又は同法第 20 条に規定する決定において特定された再生計画<br />
            <br />
            本要件に適合する事業者様は少ないかと思いますが、売上高減少要件が満たされていれば本要件は満たす必要がございませんので、本要件に満たしていない方は売上高減少要件をご確認いただければと思います。<br />
          </p>
          </>

          <>
          <div id='5' className="media_text_title">
            <h2>5.物価高騰対策・回復再生応援枠のポイント</h2>
          </div>
          <p className="media_text_text">
            <br />
            上述でも示した通り、物価高騰対策・回復再生応援枠の要件を満たすには、<strong>「事業再構築要件」「認定支援機関要件」「付加価値額要件」</strong>の要件を満たすこと、そして、<strong>「売上高等減少要件」「再生要件」のどちらかの要件を満たすこと</strong>で達成しますので、「売上高等減少要件」と「再生要件」のどちらかを満たすようご確認いただければと思います。<br />
            <br />
            もし、申請枠の選択にお悩みの方がいましたら、ご連絡いただければ相談させていただきますので、ご気軽にご連絡いただければと思います。<br />
          </p>
          </>

          <>
          <div id='5' className="media_text_title">
            <h2>5.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、物価高騰対策・回復再生応援枠の解説を行わせていただきました。<br />
            本記事は上述でも書かせていただきました通り、<strong>公募要領を元に執筆させていただいております</strong>ので、改めて公募要領に目を通していただければと思います。<br />
            <br />
            申請枠だけでなく、事業再構築補助金に関する知見を深まっておりましたら幸いです。<br />
            <br />
            上記の記事の内容だけでなく、事業再構築全般のご質問を受け付けておりますので、ご気軽にご連絡いただければと思います。<br />
            <br />
            <strong>最後まで記事を拝見してくださり、誠にありがとうございました。</strong><br />
            <strong>ご連絡の程、大変お待ちしております。</strong><br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


