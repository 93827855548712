import './styles.css';
import Photo1 from './../../../../image/photo/slider1.png';
import Photo2 from './../../../../image/photo/slider2.png';
import Photo3 from './../../../../image/photo/slider3.png';
import Photo4 from './../../../../image/photo/slider4.png';
import Photo5 from './../../../../image/photo/slider5.png';
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AiOutlineFileSearch } from 'react-icons/ai';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { BsFillPersonFill } from 'react-icons/bs';
import { AiOutlineZoomIn } from 'react-icons/ai';

export default class SlickGoTo extends React.Component {
  state = {
    slideIndex: 0,
    updateCount: 0
  };

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      arrows: false,
      slidesToScroll: 1,
      vertical: true,
      autoplay: true,
      afterChange: () => this.setState(state => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next })
    };

    return (
      <>
        <div className='photo_display'>
          <div className='photo_display_text'>
            <h4 className='photo_display_text_title' onClick={() => console.log(this.state.slideIndex)}>画面イメージ</h4>
            <div onClick={() => this.slider.slickGoTo(0)}>
              <div style={this.state.slideIndex === 0 ? {backgroundColor: 'white'} : {backgroundColor: '#FFDDDC'}}>
                <AiOutlineFileSearch className='photo_display_text_icon' />
              </div>
              <h4 style={this.state.slideIndex === 0 ? {color: 'white'} : {color: '#FFDDDC'}}>作品を検索/閲覧する画面</h4>
            </div>
            <div onClick={() => this.slider.slickGoTo(1)}>
              <div style={this.state.slideIndex === 1 ? {backgroundColor: 'white'} : {backgroundColor: '#FFDDDC'}}>
                <AiOutlinePlusSquare className='photo_display_text_icon' /></div>
              <h4 style={this.state.slideIndex === 1 ? {color: 'white'} : {color: '#FFDDDC'}}>作品を投稿する画面</h4>
            </div>
            <div onClick={() => this.slider.slickGoTo(2)}>
              <div style={this.state.slideIndex === 2 ? {backgroundColor: 'white'} : {backgroundColor: '#FFDDDC'}}>
                <BsFillPersonFill className='photo_display_text_icon' /></div>
              <h4 style={this.state.slideIndex === 2 ? {color: 'white'} : {color: '#FFDDDC'}}>自身のプロフィール画面</h4>
            </div>
            <div onClick={() => this.slider.slickGoTo(3)}>
              <div style={this.state.slideIndex === 3 ? {backgroundColor: 'white'} : {backgroundColor: '#FFDDDC'}}>
                <AiOutlineZoomIn className='photo_display_text_icon' /></div>
              <h4 style={this.state.slideIndex === 3 ? {color: 'white'} : {color: '#FFDDDC'}}>選択画像を拡大する画面</h4>
            </div>
          </div>
          <div className='slider_box'>
            <img src={Photo1} className='slider_cover' alt='' />
            <Slider ref={slider => (this.slider = slider)} {...settings} className='slider'>
              <div>
                <img src={Photo2} className='slider_image' alt='' />
              </div>
              <div>
                <img src={Photo3} className='slider_image' alt='' />
              </div>
              <div>
                <img src={Photo4} className='slider_image' alt='' />
              </div>
              <div>
                <img src={Photo5} className='slider_image' alt='' />
              </div>
            </Slider>
          </div>
        </div>
      </>
    );
  }
}