import './styles.css';
import Blog1 from "./../../../../image/media/business/1/top.png";
import Blog2 from "./../../../../image/media/business/2/top.png";
import Blog3 from "./../../../../image/media/business/3/top.png";
import Blog4 from "./../../../../image/media/business/4/top.png";
import Blog5 from "./../../../../image/media/business/5/top.png";
import Blog6 from "./../../../../image/media/business/6/top.png";

const Home = () => {

  return (
    <>
    <h4 className='help_bolg_title'>memorii media</h4>
    <p className='help_bolg_desc'>補助金に関する解説記事を掲載しているメディア</p>
    <div className='help_bolg'>
      <a href='https://trankllc.com/media/subsidy/business/0001' target="_blank" rel="nofollow noopener noreferrer">
        <div>
          <img src={Blog1} alt='' />
          <p>「事業再構築補助金とは？」知識がなくても絶対に理解できる！</p>
        </div>
      </a>
      <a href='https://trankllc.com/media/subsidy/business/0002' target="_blank" rel="nofollow noopener noreferrer">
        <div>
          <img src={Blog2} alt='' />
          <p>「事業再構築補助金」5分で対象者の条件・要件を理解する</p>
        </div>
      </a>
      <a href='https://trankllc.com/media/subsidy/business/0003' target="_blank" rel="nofollow noopener noreferrer">
        <div>
          <img src={Blog3} alt='' />
          <p>「事業再構築補助金」12個の分類を具体例つきで解説！</p>
        </div>
      </a>
    </div>
    <div className='help_bolg'>
      <a href='https://trankllc.com/media/subsidy/business/0004' target="_blank" rel="nofollow noopener noreferrer">
        <div>
          <img src={Blog4} alt='' />
          <p>「事業再構築補助金」申請枠・業種ごとに過去6回を徹底分析！</p>
        </div>
      </a>
      <a href='https://trankllc.com/media/subsidy/business/0005' target="_blank" rel="nofollow noopener noreferrer">
        <div>
          <img src={Blog5} alt='' />
          <p>「事業再構築補助金」提出書類の用意を３日で終わらせる方法！</p>
        </div>
      </a>
      <a href='https://trankllc.com/media/subsidy/business/0006' target="_blank" rel="nofollow noopener noreferrer">
        <div>
          <img src={Blog6} alt='' />
          <p>「事業再構築補助金」採択後の流れは、５ステップで完結！</p>
        </div>
      </a>
    </div>
    </>
  );
}

export default Home;