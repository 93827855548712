import './styles.css';
import Image from "./../../../../image/icon_white.png";
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Help = (props) => {
  
  return (
    <>
    <div className='hojokin_nav' style={props.isVisible === true ? {backgroundColor : '#19347A'} : {}}>
      <a href='https://trankllc.com/'>
        <div className='hojokin_nav_left'>
          <img src={Image} alt='' />
          <h5 className='hojokin_nav_left_name'>補助金サポート</h5>
        </div>
      </a>
      <div className='hojokin_nav_right'>
        <a href='https://trankllc.com/'><h5 className='hojokin_nav_right_topPage'>トップページ</h5></a>
        <a href='https://trankllc.com/media/subsidy/business/0000'><h5 className='hojokin_nav_right_blog'>補助金コラム</h5></a>
        <a href='https://trankllc.com/company'><h5 className='hojokin_nav_right_company'>会社情報</h5></a>
        <AnchorLink href="#1" offset="170"><button style={props.isVisible === true ?{backgroundColor : 'white', color : '#19347A'} : {}}>お問い合わせ</button></AnchorLink>
      </div>
    </div>

    <div className='hojokin_form_bar'>
      <div></div>
      <AnchorLink href="#1" offset="170"><div >業界最安水準 着手金3万円~ 成功報酬3%~</div></AnchorLink>
    </div>
    </>
  );
}

export default Help;
