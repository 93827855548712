import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Topic from "./../topic";
import Nav from "./../../nav/index";
import TopImage from "./../../../../../image/media/manufacturing/2/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'react-router-dom';

const BlogText0001 = () => {

  const meta = {
    title: '【初めての補助金】ものづくり補助金の対象条件を徹底解説',
    description: '本記事は、ものづくり補助金を申請したいけど、「対象事業者に含まれているかわからない！」という方へ向けて、ものづくり補助金の対象者の条件について解説させていただきます。本サイトでは、初めての方でも理解ができるようわかりやすくまとめさせていただきましたので、ぜひご参考にいただけますと大変助かります。加えて、本記事は公募要領を用いて説明させていただいておりますので、本記事を理解していただいた方は実際に公募要領をご一読いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2022/07/21</p>
          <h1 className="media_title">【初めての補助金】<br />ものづくり補助金の対象条件を徹底解説</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <p className="media_text_text">
            <br />
            <br />
            <span className='media_text_text_box_title'>こんな方へ向けて</span><br />
            <br />
            本記事は、ものづくり補助金を申請したいけど、「対象事業者に含まれているかわからない！」という方へ向けて、ものづくり補助金の対象者の条件について解説させていただきます。<br />
            <br />
            本サイトでは、初めての方でも理解ができるようわかりやすくまとめさせていただきましたので、ぜひご参考にいただけますと大変助かります。<br />
            <br />
            加えて、本記事は公募要領を用いて説明させていただいておりますので、本記事を理解していただいた方は実際に公募要領をご一読いただければと思います。<br />
            <br />
            それでは、解説に進ませていただきます！<br />
          </p>

          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">対象事業者の概要</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">ものづくり補助金の対象事業者</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">類型の概要</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">類型の詳細及び補助率</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">ものづくり補助金の類型要件</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>

          <>
          <div id='1' className="media_text_title">
            <h2>１.対象事業者の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            対象事業者の判断をしていただく前に、どんな条件があるのかをざっくり説明させていただきます。<br />
            <br />
            まず、対象事業者の要件は、ものづくり補助金 全体の要件と類型ごとの要件の２つに分かれており、以下のような違いがあります。<br />
            <br />
            ①<strong>ものづくり補助金 全体の要件</strong><br />
            ものづくり補助金 全体の要件は、こちらの要件が適合されていないと類型に関わらず申請することができないというものです。<br />
            <br />
            ②<strong>類型ごとの要件</strong><br />
            類型ごとの要件は、各類型に即した要件となっており、その類型で申請する場合に適合しなければいけないものとなっております。<br />
            <br />
            そのため、①ものづくり補助金の要件が適合しているか確認した上で、選択した類型の要件に適合するか確認するという２段階の確認が必要になるということです。<br />
            <br />
            本記事では、①ものづくり補助金の要件について説明させていただきますが、別記事にて②類型ごとの要件をご用意させていただきましたので、そちらもご参考にいただければと思います。<br />
          </p>
          </>
          
          <>
          <div id='2' className="media_text_title">
            <h2>２.ものづくり補助金の対象事業者</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            全体の流れ、申請までの流れを確認した次は、対象事業者さまについて、ご説明させていただきます。<br />
            <br />
            対象事業者の確認（本項目）では、主な対象要件を３つあげさせていただきますので、自社の情報と見比べながら確認していただければと思います。<br />
            <br />
            なお、主な３つではなく、すべての要件（詳細）は、以下のページにて記載がありますので、ご参照いただければと思います。<br />
            <br />
            <Link to='/media/1002'>
              <span className='media_text_text_link'>=＞【徹底解説】対象者要件に関する詳細</span>
            </Link>
            <br />
            <br />
          </p>
          <h3 className='media_text_text_title'>中小企業等であること</h3><br />
          <p className="media_text_text">
            こちら中小企業等を判断するために以下の表が公開されておりますので、申請を希望されている事業者さまの情報と見比べてご確認いただければと思います。<br />
            <br />
            なお、中小企業等等に含まれない場合でも例外的に申請が可能な事業者さまがおりますので、詳しくはこちらをご参照ください。<br />
            <br />
            <br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>項目</th><th className='media_table_0001_1_2'>資本金</th><th className='media_table_0001_1_3'>常勤従業員数</th>
              </tr>
              <tr>
                <td><strong>製造業、建設業、運輸業、旅行業 </strong></td>
                <td>3億円</td>
                <td>300人</td>
              </tr>
              <tr>
                <td><strong>卸売業</strong></td>
                <td>1億円</td>
                <td>100人</td>
              </tr>
              <tr>
                <td><strong>サービス業</strong></td>
                <td>5,000万円</td>
                <td>100人</td>
              </tr>
              <tr>
                <td><strong>小売業</strong></td>
                <td>5,000万円</td>
                <td>50人</td>
              </tr>
              <tr>
                <td><strong>ゴム製品製造業</strong></td>
                <td>3億円</td>
                <td>900人</td>
              </tr>
              <tr>
                <td><strong>ソフトウェア業又は情報処理サービス業</strong></td>
                <td>3億円</td>
                <td>300人</td>
              </tr>
              <tr>
                <td><strong>旅館業</strong></td>
                <td>5,000万円</td>
                <td>200人</td>
              </tr>
              <tr>
                <td><strong>その他の業種</strong></td>
                <td>3億円</td>
                <td>300人</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <h3 className='media_text_text_title'>中小企業者（組合関連）であること</h3><br />
          <p className="media_text_text">
            中小企業でなくても、以下に属す組合も対象者に含まれます。<br />
            <br />
            対象者判定のために以下の表が公開されておりますので、ご確認いただければと思います。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>組織形態</th>
              </tr>
              <tr>
                <td><strong>企業組合 </strong></td>
              </tr>
              <tr>
                <td><strong>協業組合</strong></td>
              </tr>
              <tr>
                <td><strong>事業協同組合、事業協同小組合、協同組合連合会</strong></td>
              </tr>
              <tr>
                <td><strong>商工組合、商工組合連合会</strong></td>
              </tr>
              <tr>
                <td><strong>商店街振興組合、商店街振興組合連合会</strong></td>
              </tr>
              <tr>
                <td><strong>水産加工業協同組合、水産加工業協同組合連合会</strong></td>
              </tr>
              <tr>
                <td><strong>生活衛生同業組合、生活衛生同業小組合、生活衛生同業組合連合会</strong></td>
              </tr>
              <tr>
                <td><strong>酒造組合、酒造組合連合会、酒造組合中央会</strong></td>
              </tr>
              <tr>
                <td><strong>内航海運組合、内航海運組合連合会</strong></td>
              </tr>
              <tr>
                <td><strong>技術研究組合</strong></td>
              </tr>
            </tbody>
          </table>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>３.類型の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            対象事業者の確認が終わったところで、次は実際どの類型（枠）で申請するかを検討します。<br />
            <br />
            と、その前に類型の説明になりますが、簡単にいうと<strong>補助金を細分化し適正な要件を課すもの</strong>だと思ってください。<br />
            <br />
            具体的には、こういう事業者さまはこの枠、これを目的にしている事業者さまはこの枠という形で分けられており、そこで選択された類型によって要件、補助率、上限金額が細かく提示されています。<br />
            <br />
            そのため、申請するに当たって、どの枠なのか、どの要件を満たす必要があるかなどを判断するために理解しなくてはいけない要素となっております。<br />
            <br />
            本補助金の類型は、<strong>「通常枠」</strong>、<strong>「回復型賃上げ・雇用拡大枠」</strong>、<strong>「デジタル枠」</strong>、<strong>「グリーン枠」</strong>、<strong>「グローバル展開型」</strong>の５つございます。<br />
            <br />
            ここでは、類型の概要を説明させていただきます。<br />
            <br />
            上記で触れさせていただきました類型ごとの詳しい要件、補助率、上限金額については、以下の記事にて解説をしておりますので、ご確認いただけばと思います。<br />
            <br />
            <Link to='/media/1002'>
              <span className='media_text_text_link'>=＞【徹底解説】類型の要件と補助率に関する詳細</span>
            </Link>
            <br />
            <br />
            <br />
          </p>
          <h3 className='media_text_text_title'>通常枠</h3><br />
          <p className="media_text_text">
            革新的な製品・サービス開発又は生産プロセス・サービス提供方法の改善に必要な設備・システム投資等を支援<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>回復型賃上げ・雇用拡大枠</h3><br />
          <p className="media_text_text">
            業況が厳しいながら賃上げ・雇用拡大に取り組む事業者（※）が行う、革新的な製品・サービス開発又は生産プロセス・サービス提供方法の改善に必要な設備・システム投資等を支援<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>デジタル枠</h3><br />
          <p className="media_text_text">
            DX（デジタルトランスフォーメーション）に資する革新的な製品・サービス開発又はデジタル技術を活用した生産プロセス・サービス提供方法の改善による生産性向上に必要な設備・システム投資等を支援<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>グリーン枠</h3><br />
          <p className="media_text_text">
            温室効果ガスの排出削減に資する革新的な製品・サービス開発又は炭素生産性向上を伴う生産プロセス・サービス提供方法の改善による生産性向上に必要な設備・システム投資等を支援<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>グローバル展開型</h3><br />
          <p className="media_text_text">
            海外事業の拡大・強化等を目的とした「革新的な製品・サービス開発」又は「生産プロセス・サービス提供方法の改善」に必要な設備・システム投資等を支援（①海外直接投資、②海外市場開拓、③インバウンド市場開拓、④海外事業者との共同事業のいずれかに合致するもの）<br />
          </p>
          </>
          
          <>
          <div id='4' className="media_text_title">
            <h2>４.類型の詳細及び補助率</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            以上を踏まえて、ものづくり補助金の類型の詳細と補助率についてご説明させていただきます。<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>ものづくり補助金の補助率</h3><br />
          <p className='media_text_text'>
            次は、補助率についてですが、こちらは公募要領より表が作成されておりますので、そのまま引用させていただきます。<br />
            <br />
            <strong>①通常枠</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>項目</th><th className='media_table_0001_3_2'>要件</th>
              </tr>
              <tr>
                <td><strong>概要</strong></td>
                <td>革新的な製品・サービス開発又は生産プロセス・サービス提供方法の改善に必要な設備・システム投資等を支援</td>
              </tr>
              <tr>
                <td><strong>補助金額</strong></td>
                <td>
                  従業員数 5 人以下 ：１００万円～７５０万円<br />
                  ６人～２０人：１００万円～１，０００万円<br />
                  ２１人以上 ：１００万円～１，２５０万円<br />
                </td>
              </tr>
              <tr>
                <td><strong>補助率</strong></td>
                <td>
                  １／２、小規模企業者・小規模事業者、再生事業者（※）２／３<br />
                  ※ 本事業における再生事業者の定義は、別紙４の通り。<br />
                </td>
              </tr>
              <tr>
                <td><strong>設備投資</strong></td>
                <td>単価５０万円（税抜き）以上の設備投資が必要</td>
              </tr>
              <tr>
                <td><strong>補助対象経費</strong></td>
                <td>機械装置・システム構築費、技術導入費、専門家経費、運搬費、クラウドサービス利用費、原材料費、外注費、知的財産権等関連経費</td>
              </tr>
            </tbody>
          </table>
          <p className='media_text_text'>
            <br />
            <strong>②回復型賃上げ・雇用拡大枠</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>項目</th><th className='media_table_0001_3_2'>要件</th>
              </tr>
              <tr>
                <td><strong>概要</strong></td>
                <td>
                  業況が厳しいながら賃上げ・雇用拡大に取り組む事業者（※）が行う、革新的な製品・サービス開発又は生産プロセス・サービス提供方法の改善に必要な設備・システム投資等を支援<br />
                  ※応募締切時点の前年度の事業年度の課税所得がゼロであり、常時使用する従業員がいる事業者に限る。<br />
                </td>
              </tr>
              <tr>
                <td><strong>補助金額</strong></td>
                <td>
                  従業員数5人以下：100万円~750万円<br />
                  6人~20人：100万円~1,000万円<br />
                  21人以上：100万円~1,250万円<br />
                </td>
              </tr>
              <tr>
                <td><strong>補助率</strong></td>
                <td>
                  2/3<br />
                </td>
              </tr>
              <tr>
                <td><strong>設備投資</strong></td>
                <td>単価５０万円（税抜き）以上の設備投資が必要</td>
              </tr>
              <tr>
                <td><strong>補助対象経費</strong></td>
                <td>機械装置・システム構築費、技術導入費、専門家経費、運搬費、クラウドサービス利用費、原材料費、外注費、知的財産権等関連経費</td>
              </tr>
            </tbody>
          </table>
          <p className='media_text_text'>
            <br />
            <strong>③デジタル枠</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>項目</th><th className='media_table_0001_3_2'>要件</th>
              </tr>
              <tr>
                <td><strong>概要</strong></td>
                <td>DX（デジタルトランスフォーメーション）に資する革新的な製品・サービス開発又はデジタル技術を活用した生産プロセス・サービス提供方法の改善による生産性向上に必要な設備・システム投資等を支援</td>
              </tr>
              <tr>
                <td><strong>補助金額</strong></td>
                <td>
                  従業員数5人以下：100万円~750万円<br />
                  6人~20人：100万円~1,000<br />
                  21人以上：100万円~1,250<br />
                </td>
              </tr>
              <tr>
                <td><strong>補助率</strong></td>
                <td>
                  2/3<br />
                </td>
              </tr>
              <tr>
                <td><strong>設備投資</strong></td>
                <td>単価５０万円（税抜き）以上の設備投資が必要</td>
              </tr>
              <tr>
                <td><strong>補助対象経費</strong></td>
                <td>機械装置・システム構築費、技術導入費、専門家経費、運搬費、クラウドサービス利用費、原材料費、外注費、知的財産権等関連経費</td>
              </tr>
            </tbody>
          </table>
          <p className='media_text_text'>
            <br />
            <strong>④グリーン枠</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>項目</th><th className='media_table_0001_3_2'>要件</th>
              </tr>
              <tr>
                <td><strong>概要</strong></td>
                <td>温室効果ガスの排出削減に資する革新的な製品・サービス開発又は炭素生産性向上を伴う生産プロセス・サービス提供方法の改善による生産性向上に必要な設備・システム投資等を支援</td>
              </tr>
              <tr>
                <td><strong>補助金額</strong></td>
                <td>
                  従業員数5人以下：100万円~1,000万円<br />
                  6人~20人：100万円~1,500<br />
                  21人以上：100万円~2,050<br />
                </td>
              </tr>
              <tr>
                <td><strong>補助率</strong></td>
                <td>
                  2/3<br />
                </td>
              </tr>
              <tr>
                <td><strong>設備投資</strong></td>
                <td>単価５０万円（税抜き）以上の設備投資が必要</td>
              </tr>
              <tr>
                <td><strong>補助対象経費</strong></td>
                <td>機械装置・システム構築費、技術導入費、専門家経費、運搬費、クラウドサービス利用費、原材料費、外注費、知的財産権等関連経費</td>
              </tr>
            </tbody>
          </table>
          <p className='media_text_text'>
            <br />
            <strong>⑤グローバル展開型</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>項目</th><th className='media_table_0001_3_2'>要件</th>
              </tr>
              <tr>
                <td><strong>概要</strong></td>
                <td>海外事業の拡大・強化等を目的とした「革新的な製品・サービス開発」又は「生産プロセス・サービス提供方法の改善」に必要な設備・システム投資等を支援（①海外直接投資、②海外市場開拓、③インバウンド市場開拓、④海外事業者との共同事業のいずれかに合致するもの）</td>
              </tr>
              <tr>
                <td><strong>補助金額</strong></td>
                <td>
                  1,000万円~3,000万円<br />
                </td>
              </tr>
              <tr>
                <td><strong>補助率</strong></td>
                <td>
                  １／２<br />
                  小規模企業者・小規模事業者 ２／３<br />
                </td>
              </tr>
              <tr>
                <td><strong>設備投資</strong></td>
                <td>単価５０万円（税抜き）以上の設備投資が必要</td>
              </tr>
              <tr>
                <td><strong>補助対象経費</strong></td>
                <td>機械装置・システム構築費、技術導入費、専門家経費、運搬費、クラウドサービス利用費、原材料費、外注費、知的財産権等関連経費、海外旅費</td>
              </tr>
            </tbody>
          </table>
          </>

          <>
          <div id='5' className="media_text_title">
            <h2>５.ものづくり補助金の類型要件</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            上記で、類型や補助率を説明させていただきましたが、次はその類型ごとの要件について説明させていただきます。<br />
            <br />
            ご自身の事業者さま（申請したい事業者さま）が選択した類型の要件を確認していただければ問題ございませんので、ご確認ください。<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>①通常枠の要件</h3><br />
          <p className="media_text_text">
            <br />
            ①給与支給総額を年率平均1.5%以上増加させること<br />
            ②従業員の最低時給を地域別最低賃金＋３０円以とすること<br />
            ③申請する事業者さま全体の付加価値がくを年率３％以上増加させること<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>②回復型賃上げ・雇用拡大枠</h3><br />
          <p className="media_text_text">
            <br />
            ①給与支給総額を年率平均1.5%以上増加させること<br />
            ②従業員の最低時給を地域別最低賃金＋３０円以とすること<br />
            ③申請する事業者さま全体の付加価値がくを年率３％以上増加させること<br />
            ④前年度の課税所得がゼロであること<br />
            ⑤常時雇用する従業員がいること<br />
            ⑥補助事業を完了した次の年度の3月時点で、給与支給総額の目標を達成すること<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>③デジタル枠</h3><br />
          <p className="media_text_text">
            <br />
            ①給与支給総額を年率平均1.5%以上増加させること<br />
            ②従業員の最低時給を地域別最低賃金＋３０円以とすること<br />
            ③申請する事業者さま全体の付加価値がくを年率３％以上増加させること<br />
            ④DX化するために用いる革新的な製品・サービスの開発をすること<br />
            ⑤デジタル技術を活用した生産プロセス・サービス提供方法の改善<br />
            ⑥独立行政法人情報処理推進機構（IPA）に対して書類を提出すること<br />
            ⑦独立行政法人情報処理推進機構（IPA）が実施する「SECURITY ACTION」の「★ 一つ星」または「★★ 二つ星」いずれかの宣言を行っていること。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>④グリーン枠</h3><br />
          <p className="media_text_text">
            <br />
            ①給与支給総額を年率平均1.5%以上増加させること<br />
            ②従業員の最低時給を地域別最低賃金＋３０円以とすること<br />
            ③申請する事業者さま全体の付加価値がくを年率３％以上増加させること<br />
            ④前年度の課税所得がゼロであること<br />
            ⑤常時雇用する従業員がいること<br />
            ⑥補助事業を完了した次の年度の3月時点で、給与支給総額の目標を達成すること<br />
          </p>
          </>

          <>
          <div id='6' className="media_text_title">
            <h2>６.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            本記事では、ものづくり補助金を申請したい事業者さまへ向けて、ものづくり補助金の概要について記事にさせていただきました。<br />
            <br />
            公募要領を各所に添付させていただきましたが、ご覧いただけば分かる通り難しい内容となっております。<br />
            そこで、もし、本格的にものづくり補助金の導入を検討されていましたら、弊社含め補助金に詳しい事業者へご相談ください。<br />
            <br />
            ご相談は、画面右上の補助金に関する相談をするから問い合わせにて、お待ちしております。<br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />

      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;