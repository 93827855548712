import './styles.css';
import Service1 from './../../../image/homepage/service/1.png';
import Service2 from './../../../image/homepage/service/2.png';
import Service3 from './../../../image/homepage/service/3.png';
import Service4 from './../../../image/homepage/service/4.png';
import Service5 from './../../../image/homepage/service/5.png';
import Service6 from './../../../image/homepage/service/6.png';

const Footer = () => {
  
  return (
    <>
    <div className='test_homeimage_service'>
      <div className='test_homeimage_service_title'>
        <p>提供しているサービス</p>
        <h4>service</h4>
      </div>
      <div className='test_homeimage_service_items'>
        <div>
          <img src={Service1} alt='' />
          <h4>補助金申請サポート</h4>
          <p>新しい挑戦を行うに当たって必要な資金調達を事業者様に合わせてご提案し、資金調達の完了までをサポートします。</p>
          <a href='https://trankllc.com/help' rel="nofollow noopener noreferrer">
            <button style={{backgroundColor : '#479D58'}}>詳しく見る</button>
          </a>
        </div>
        <div>
          <img src={Service2} alt='' />
          <h4>カルテ管理アプリ</h4>
          <p>美容業界のDX化が深刻化されている中で、当社では美容師様一人一人がスマホ1つで顧客リストを管理できるサービスを展開しています。</p>
          <a href='https://memorii.jp/' rel="nofollow noopener noreferrer">
            <button style={{backgroundColor : '#2E81B0'}}>詳しく見る</button>
          </a>
        </div>
        <div>
          <img src={Service3} alt='' />
          <h4>省力化製品一覧サイト</h4>
          <p>人材不足が各業界で問題視されている中、補助金を使用して購入できる省力化製品を探す事ができるサイトです。</p>
          <a href='https://shoryokuka.com/' rel="nofollow noopener noreferrer">
            <button style={{backgroundColor : '#DA3E35'}}>詳しく見る</button>
          </a>
        </div>
      </div>
      <div className='test_homeimage_service_items'>
        <div>
          <img src={Service4} alt='' />
          <h4>作品撮り投稿アプリ</h4>
          <p>美容学生が自分自身のレベルアップのために行う作品撮りの制作を美容学生同士が見れ、刺激し合えるプラットフォームです。</p>
          <a href='https://trankllc.com/photo' rel="nofollow noopener noreferrer">
            <button style={{backgroundColor : '#CD445B'}}>詳しく見る</button>
          </a>
        </div>
        <div>
          <img src={Service5} alt='' />
          <h4>webサイト開発</h4>
          <p>事業者様のご要望と当社の治験を混ぜ合わせながら、より良いホームページを制作するお手伝いをしています。</p>
          <a href='https://trankllc.com/contact' rel="nofollow noopener noreferrer">
            <button style={{backgroundColor : '#373198'}}>詳しく見る</button>
          </a>
        </div>
        <div>
          <img src={Service6} alt='' />
          <h4>写真撮影サービス</h4>
          <p>美容師兼フォトグラファーの経験を持つ担当者が、髪の毛のセットから撮影、レタッチまでを一括でサポートします。</p>
          <a href='https://trankllc.com/masato' rel="nofollow noopener noreferrer">
            <button style={{backgroundColor : '#3C3C3C'}}>詳しく見る</button>
          </a>
        </div>
      </div>

      <div className='test_homeimage_service_items_phone'>
        <div>
          <img src={Service1} alt='' />
          <h4>補助金申請サポート</h4>
          <p>新しい挑戦を行うに当たって必要な資金調達を事業者様に合わせてご提案し、資金調達の完了までをサポートします。</p>
          <a href='https://trankllc.com/help' rel="nofollow noopener noreferrer">
            <button style={{backgroundColor : '#479D58'}}>詳しく見る</button>
          </a>
        </div>
        <div>
          <img src={Service2} alt='' />
          <h4>カルテ管理アプリ</h4>
          <p>美容業界のDX化が深刻化されている中で、当社では美容師様一人一人がスマホ1つで顧客リストを管理できるサービスを展開しています。</p>
          <a href='https://memorii.jp/' rel="nofollow noopener noreferrer">
            <button style={{backgroundColor : '#2E81B0'}}>詳しく見る</button>
          </a>
        </div>
      </div>
      <div className='test_homeimage_service_items_phone'>
        <div>
          <img src={Service3} alt='' />
          <h4>公共工事入札サービス</h4>
          <p>国・省庁・市町村から公開されている入札情報を事業者様がご自身で入手出来るプラットフォームを提供しています。</p>
          <a href='https://trankllc.com/bid/home' rel="nofollow noopener noreferrer">
            <button style={{backgroundColor : '#DA3E35'}}>詳しく見る</button>
          </a>
        </div>
        <div>
          <img src={Service4} alt='' />
          <h4>作品撮り投稿アプリ</h4>
          <p>美容学生が自分自身のレベルアップのために行う作品撮りの制作を美容学生同士が見れ、刺激し合えるプラットフォームです。</p>
          <a href='https://trankllc.com/photo' rel="nofollow noopener noreferrer">
            <button style={{backgroundColor : '#CD445B'}}>詳しく見る</button>
          </a>
        </div>
      </div>
      <div className='test_homeimage_service_items_phone'>
        <div>
          <img src={Service5} alt='' />
          <h4>webサイト開発</h4>
          <p>事業者様のご要望と当社の治験を混ぜ合わせながら、より良いホームページを制作するお手伝いをしています。</p>
          <a href='https://trankllc.com/contact' rel="nofollow noopener noreferrer">
            <button style={{backgroundColor : '#373198'}}>詳しく見る</button>
          </a>
        </div>
        <div>
          <img src={Service6} alt='' />
          <h4>写真撮影サービス</h4>
          <p>美容師兼フォトグラファーの経験を持つ担当者が、髪の毛のセットから撮影、レタッチまでを一括でサポートします。</p>
          <a href='https://trankllc.com/masato' rel="nofollow noopener noreferrer">
            <button style={{backgroundColor : '#3C3C3C'}}>詳しく見る</button>
          </a>
        </div>
      </div>
    </div>
    </>
  );
}

export default Footer;
