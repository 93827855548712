import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/11/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」再生要件は、中小企業活性化協議会の支援の有無！',
    description: '本記事では、再生要件の類型を詳しく解説しております。その他、具体例や判断に必要な考え方についても説明しております。本記事は、「回復・再生応援枠での申請を考えており、要件を詳しく知りたい」という方に向けて、執筆させていただきました。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」再生要件は、中小企業活性化協議会の支援の有無！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            こんにちは。この記事では、物価高騰・回復再生応援枠の売上高等減少要件が満たせなかった場合の要件である、再生要件について、解説していきます。<br />
            <br />
            再生要件は比較的、分かりやすい要件の1つでもございますので、物価高騰・回復再生応援枠での申請を検討されている方は、ぜひ確認していただければと思います。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>再生要件の対象者</span></li>
              <li><span>要件を満たす考え方</span></li>
              <li><span>要件を満たすために提出する書類</span></li>
            </ul>
          </div>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">再生要件とは</AnchorLink></li>
                <li><AnchorLink href="#2" offset="100">再生要件を満たすための代替手段</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>１.再生要件とは</h2>
          </div>
          <p className="media_text_text">
            <br />
            再生要件とは、経済産業省から定められている機関から支援を受けているかどうかで、判断する事ができる簡単な要件です。<br />
            <br />
            過去3年間の支援も含まれるため、代表者様に確認してみると結果がすぐに分かるでしょう。<br />
          </p>
          <h3 className='media_text_text_title'>要件の対象者</h3><br />
          <p className="media_text_text">
            再生要件を満たす必要があるのは、「物価高騰・回復再生応援枠」のみとなっております。<br />
            <br />
            物価高騰・回復再生応援の対象者や他要件は、別記事で記載しておりますので、下記よりご確認ください。<br />
            詳細記事：<a href='https://trankllc.com/media/subsidy/business/0028' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」物価高騰対策・回復再生応援枠
            </a>
          </p>
          <h3 className='media_text_text_title'>再生要件を満たす方法</h3><br />
          <p className="media_text_text">
            再生要件は、公募要領では下記の様に定義されています。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>事業再構築補助金の目的</span><br />
            ア．中小企業活性化協議会（旧：中小企業再生支援協議会）等から支援を受けており（※１）、<br />
            応募申請時において以下のいずれかに該当している必要があります。<br />
            （１） 再生計画等を「策定中」の者※２<br />
            （２） 再生計画等を「策定済」かつ応募締切日から遡って３年以内（令和２年7月1日以<br />
            降）に再生計画等が成立等した者<br />
            ※１ 以下に掲げる計画に関する支援を受けている者（同計画に基づき事業譲渡を受け<br />
            る（又は受けた）者を含む）。<br />
            <br />
            １.中小企業活性化協議会（旧：中小企業再生支援協議会）が策定を支援した再生計画<br />
            ２.独立行政法人中小企業基盤整備機構が策定を支援した再生計画<br />
            ３.産業復興相談センターが策定を支援した再生計画<br />
            ４.株式会社整理回収機構が策定を支援した再生計画<br />
            ５.「私的整理に関するガイドライン」に基づいて策定した再建計画<br />
            ６.中小企業の事業再生等のための私的整理手続（中小企業版私的整理手続）に基づいて策定した再生計画（令和４年４月１５日から適用開始）<br />
            ７.産業競争力強化法に基づき経済産業大臣の認定を受けた認証紛争解決事業者（事業再生ＡＤＲ事業者）が策定を支援した事業再生計画<br />
            ８.独立行政法人中小企業基盤整備機構が出資した中小企業再生ファンドが策定を支援した再生計画<br />
            ９.株式会社東日本大震災事業者再生支援機構が同機構法第 19 条の規定による支援決定を行った事業再生計画<br />
            10.株式会社地域経済活性化支援機構が株式会社地域経済活性化支援機構法第 25条の規定による再生支援決定を行った事業再生計画<br />
            11.特定調停法に基づく調停における調書（同法第 17 条第１項の調停条項によるものを除く。）又は同法第 20 条に規定する決定において特定された再生計画<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a><br />
          </div>
          <p className="media_text_text">
            <br />
            上記により、以下の再生計画の作成を行っている方が対象となる要件です。以下に確認項目を改めて記載しましたので、ご確認いただければと思います。<br />
            <br />
            中小企業活性化協議会（旧：中小企業再生支援協議会）等から支援を受けており、応募申請時において以下のいずれかに該当している必要があります。<br />
            （１） 再生計画等を「策定中」の者<br />
            （２） 再生計画等を「策定済」かつ応募締切日から遡って３年以内<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.再生要件を満たすための代替手段</h2>
          </div>
          <p className="media_text_text">
            中小企業活性化協議会以外で再生計画の支援を受けている場合も、上記の要件を満たしたと認められます。<br />
            <br />
            以下のように支援された再生計画でも要件を満たすと認定されますので、再生計画を策定された経験のある事業者様は、ご確認ください。<br />
            ・独立行政法人中小企業基盤整備機構が策定を支援したもの<br />
            ・産業復興相談センターが策定を支援したもの<br />
            ・株式会社整理回収機構が策定を支援したもの<br />
            ・「私的整理に関するガイドライン」に基づいて策定されたもの<br />
            ・中小企業の事業再生等のための、私的整理手続(中小企業版私的整理手続)に基づいて策定されたもの<br />
            ・産業競争力強化法に基づき経済産業大臣の認定を受けた、認証紛争解決事業者(事業再生ADR事業者) が策定を支援したもの<br />
            ・独立行政法人中小企業基盤整備機構が出資した、中小企業再生ファンドが策定を支援したもの<br />
            ・株式会社東日本大震災事業者再生支援機構が、同機構法第19条による支援決定を行ったもの<br />
            ・株式会社地域経済活性化支援機構が、株式会社地域経済活性化支援機構法第25条の規定による再生支援決定を行ったもの<br />
            ・特定調停法に基づく調停における調書or同法第20条に規定する決定において特定されたもの<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、再生要件について解説をさせていただきました。<br />
            <br />
            やや複雑なものとはなっておりますが、少しずつ進めていくと意外と簡単なものですので、諦めずに申請まで頑張ってください。<br />
            <br />
            ご不明点、疑問点につきましては、右上の問い合わせからお待ちしておりますので、ご気軽にご連絡いただければと思います。<br />
            <br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


