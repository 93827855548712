import { Link } from 'react-router-dom';
import './styles.css';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const simulatorResult = (props) => {

  return(
  <>
  <div className='simulator_contact'>
    <h4>もっと詳しく知りたい方</h4>
    <p>事業再構築の申請をサポートしてほしい方、もっと詳しく補助金について知りたい方、<br />補助金に関するご不明点がありましたらご気軽にご連絡いただければと思います。</p>
    <AnchorLink href="#1" offset="200">
      <button>専門家へ相談</button>
    </AnchorLink>
  </div>
  </>
  );
}

export default simulatorResult;
