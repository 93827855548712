import './styles.css';
import React, { useState } from "react";
import { BsChevronDown } from 'react-icons/bs';

const PhotoQa = () => {

  const [ select1open, setselect1Open] = useState(true);
  const [ select2open, setselect2Open] = useState(true);
  const [ select3open, setselect3Open] = useState(true);
  const [ select4open, setselect4Open] = useState(true);

  return (
    <>
    <h4 className='photo_FA_title'>よくあるご質問</h4>
    <div className='photo_question'>
      <div className={select1open ? "photo_question_open" : "photo_question_close"} onClick={() => setselect1Open(prev => !prev)}>
        <div className='photo_question_Q'>
          <div className='photo_question_Q_div'>
            <h4>Q</h4>
            <h5>アプリを使うのにお金は、かかりますか？</h5>
          </div>
          <BsChevronDown className='photo_question_icon' />
        </div>
        <div className='photo_question_hr'></div>
        <div className='photo_question_A'>
          <div className='photo_question_A_div'>
            <h4>A</h4>
            <h5>アプリは完全無料です。</h5>
          </div>
        </div>
      </div>
      <div className={select3open ? "photo_question_open" : "photo_question_close"}  onClick={() => setselect3Open(prev => !prev)}>
        <div className='photo_question_Q'>
          <div className='photo_question_Q_div'>
            <h4>Q</h4>
            <h5>作品撮りコンテストは行なっていますか？</h5>
          </div>
          <BsChevronDown className='photo_question_icon' />
        </div>
        <div className='photo_question_hr'></div>
        <div className='photo_question_A'>
          <div className='photo_question_A_div'>
            <h4>A</h4>
            <h5>大体3ヶ月に1回、開催してます。</h5>
          </div>
        </div>
      </div>
      <div className={select4open ? "photo_question_open" : "photo_question_close"}  onClick={() => setselect4Open(prev => !prev)}>
        <div className='photo_question_Q'>
          <div className='photo_question_Q_div'>
            <h4>Q</h4>
            <h5>アプリの登録は、時間かかりますか？</h5>
          </div>
          <BsChevronDown className='photo_question_icon' />
        </div>
        <div className='photo_question_hr'></div>
        <div className='photo_question_A'>
          <div className='photo_question_A_div'>
            <h4>A</h4>
            <h5>メールアドレス登録のみです。</h5>
          </div>
        </div>
      </div>
      <div className={select2open ? "photo_question_open" : "photo_question_close"}  onClick={() => setselect2Open(prev => !prev)}>
        <div className='photo_question_Q'>
          <div className='photo_question_Q_div'>
            <h4>Q</h4>
            <h5>美容院の求人を閲覧することはできますか？</h5>
          </div>
          <BsChevronDown className='photo_question_icon' />
        </div>
        <div className='photo_question_hr'></div>
        <div className='photo_question_A'>
          <div className='photo_question_A_div'>
            <h4>A</h4>
            <h5>現在、開発中でございます。</h5>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default PhotoQa;
