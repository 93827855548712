import './styles.css';

const Help = (props) => {
  
  return (
    <>
    <div className='bid_QA_box'>
      <div className='bid_QA_q'>
        <h3>Q</h3>
        <h4>サービス利用に関して、費用はかかりますか？</h4>
      </div>
      <div className='bid_QA_a'>
        <h3>A</h3>
        <h4>サービス利用は、完全無料としておりますので、ご安心してご活用いただければと思います。</h4>
      </div>
      <div className='bid_QA_q'>
        <h3>Q</h3>
        <h4>どのような魅力がありますか？</h4>
      </div>
      <div className='bid_QA_a'>
        <h3>A</h3>
        <h4>当サービスの魅力は、①最新の情報公開、②案件数が多数、③完全無料である3つです。</h4>
      </div>
      <div className='bid_QA_q'>
        <h3>Q</h3>
        <h4>どのような案件がありますか？</h4>
      </div>
      <div className='bid_QA_a'>
        <h3>A</h3>
        <h4>公共工事が多いですが、ソフト、ハードの開発や卸売など沢山のジャンルの案件があります。</h4>
      </div>
      <div className='bid_QA_q'>
        <h3>Q</h3>
        <h4>補助金申請もやってるんですか？</h4>
      </div>
      <div className='bid_QA_a'>
        <h3>A</h3>
        <h4>弊社では補助金申請も行っております。新しい設備の購入や新規事業を行う際の投資などで補助金を活用されたい方がおりましたら、ぜひご連絡いただければと思います。</h4>
      </div>
    </div>
    </>
  );
}

export default Help;
