// 再構築要件

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/7/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」再構築要件を5つの類型ごとに解説！',
    description: '本記事では、事業再構築要件の類型を詳しく解説しております。その他、具体例や判断に必要な考え方についても説明しております。本記事は、「事業再構築の定義が分からない」方や「類型を詳しく知りたい」という方に向けて、執筆させていただきました',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」再構築要件を5つの類型ごとに解説！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は、<strong>日本最大級の補助金である「事業再構築補助金」</strong>について解説させていただきます。<br />
            <br />
            本記事は、「<strong>事業再構築の定義が分からない</strong>」方や「<strong>類型を詳しく知りたい</strong>」という方に向けて、執筆させていただきました。<br />
            <br />
            そのため、事業再構築補助金の再構築要件について知りたい方は、最後までご覧ください！<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>本記事でこんな悩みが解決！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>再構築要件が理解できない…</span></li>
              <li><span>事業再構築の類型をどれにすればいいか分からない…</span></li>
              <li><span>事業再構築に新規事業に当てはまっているか判断できない…</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、<strong>経済産業省から展開されている「事業再構築指針の手引き」を参考にして作成したもの</strong>となっています。<br />
            <a href='https://www.meti.go.jp/covid-19/jigyo_saikoutiku/pdf/shishin_tebiki.pdf' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：「事業再構築指針の手引き」に関する記事
            </a><br />
            <br />
            なお、右上のボタンからお問い合わせをお受けしておりますので、<strong>申請サポートのご依頼や不明点のご相談はお気軽にご連絡ください</strong>。<br />
            <br />
            それでは、解説に進ませていただきます！<br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#2" offset="100">事業再構築の定義</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">再構築要件類型の説明</AnchorLink></li>
                <li><AnchorLink href="#2" offset="100">各要件を満たす考え方</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">各類型の具体例</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>１.事業再構築の定義</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築要件は、事業再構築補助金の申請要件となっておりますので、事業再構築要件という言葉の定義や、種類をご説明いたします。<br />
            <br />
            事業再構築要件は、4つの類型に分かれており、いずれか1つを満たしていることが定義としてあげられています。<br />
            <br />
            <strong>① 新市場進出</strong><br />
            <strong>② 事業転換</strong><br />
            <strong>③ 業種転換</strong><br />
            <strong>④ 事業再編</strong><br />
            <strong>⑤ 国内回帰</strong><br />
            <br />
            なお、事業再編は他の類型と大きく異なる形態となっており複雑なため、１つずつゆっくりお読みいただければと思います！<br />
          </p>
          </>
          
          <>
          <div id='2' className="media_text_title">
            <h2>２.再構築要件類型の説明</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、事業再構築の5類型を１つずつ説明させていただきます。具体例も記載してありますので、自社事業に最も沿うものを重点的に、読み込んでいただければと思います！<br />
          </p>
          <h3 className='media_text_text_title'>新分野展開</h3><br />
          <p className="media_text_text">
            <strong>定義</strong><br />
            「新市場進出」とは主たる業種又は主たる事業を変更することなく、新たな製品等を製造等し、新たな市場に進出すること<br />
            引用：https://www.meti.go.jp/covid-19/jigyo_saikoutiku/pdf/shishin_tebiki.pdf<br />
            <br />
            上記の内容をまとめると、以下の3点が新分野展開の定義を満たすための事項となります。<br />
            ・日本標準産業分類上での産業分類に変更はない<br />
            ・新たな製品等の製造等を行う<br />
            ・新製品により、新たな市場(ターゲット層)の獲得を行う<br />
            <br />
            なお、日本標準産業分類については、本記事の下部にて詳細な解説を行っています。<br />
            <br />
            また、満たすべき要件の３要件を事業計画内で示すことで、新分野展開に該当すると認められますので、下記の事項に注意して、事業計画書の記載を行いましょう。<br />
            <br />
            <strong>満たすべき要件</strong><br />
            ① 新たな製品等を製造等すること(製品等の新規性要件)<br />
            ② 新たな市場に進出すること(市場の新規性要件)<br />
            ③ 3～5年の事業計画期間が終了後、新製品の売上高が総売上高の10%以上となる計画を策定すること(新事業売上高10%等要件)<br />
          </p>
          <h3 className='media_text_text_title'>事業転換</h3><br />
          <p className="media_text_text">
          <strong>定義</strong><br />
            中小企業等が新たな製品等を製造等することにより、主たる業種を変更することなく、主たる 事業を変更すること<br />
            引用：https://www.meti.go.jp/covid-19/jigyo_saikoutiku/pdf/shishin_tebiki.pdf<br />
            <br />
            上記の内容をまとめると、以下の3点が事業転換の定義を満たすための事項となります。<br />
            <br />
            ・日本標準産業分類上の、大分類には変更がない<br />
            ・日本標準産業分類上の、中分類・小分類・細分類のいずれかを変更する。<br />
            ・新製品等の製造等により、新たな市場(ターゲット層)を獲得する。<br />
            <br />
            なお、日本標準産業分類については、本記事の下部で詳細な解説を行っています。<br />
            <br />
            また、以下の３要件を事業計画内で示すことで、事業転換に該当すると認められます。そのため、下記の事項に注意して、事業計画書の記載を行いましょう。<br />
            <br />
            <strong>満たすべき要件</strong><br />
            ①新たな製品等を製造等すること(製品等の新規性要件)<br />
            ②新たな市場に進出すること(市場の新規性要件)<br />
            ③3～5年の事業計画期間が終了後、新製品事業の売上高が総売上高の最も高い比率を占める計画を策定すること(売上高構成比要件)<br />
          </p>
          <h3 className='media_text_text_title'>業種転換</h3><br />
          <p className="media_text_text">
            <strong>定義</strong><br />
            中小企業等が新たな製品を製造することにより、主たる業種を変更すること<br />
            引用：https://www.meti.go.jp/covid-19/jigyo_saikoutiku/pdf/shishin_tebiki.pdf<br />
            <br />
            上記の内容をまとめると、以下の2点が業種転換の定義を満たすための事項となります。<br />
            <br />
            ・日本標準産業分類上の、大分類を変更する。<br />
            ・新製品等の製造等により、新たな市場(ターゲット層)を獲得する。<br />
            <br />
            また、以下の3要件を事業計画内で示すことで、業種転換に該当すると認められます。そのため、下記の事項に注意して、事業計画書の記載を行いましょう。<br />
            <br />
            <strong>満たすべき要件</strong><br />
            ①新たな製品等を製造等すること(製品等の新規性要件)<br />
            ②新たな市場に進出すること(市場の新規性要件)<br />
            ③3～5年の事業計画期間が終了後、新製品事業の売上高が総売上高の最も高い比率を占める計画を策定すること(売上高構成比要件)<br />
          </p>
          <h3 className='media_text_text_title'>事業再編</h3><br />
          <p className="media_text_text">
            <strong>定義</strong><br />
            会社法上の組織再編行為（合併、会社分割、株式交換、株式移転、事業譲渡）等を補助事 業開始後に行い、新たな事業形態のもとに、新分野展開、事業転換、業種転換又は業態転換のいずれかを行うこと<br />
            引用：https://www.meti.go.jp/covid-19/jigyo_saikoutiku/pdf/shishin_tebiki.pdf<br />
            <br />
            上記の内容をまとめると、以下の2点が業態転換の定義を満たすための事項となります。<br />
            <br />
            ・会社法上の組織再編行為(※1)を行うこと<br />
            ・事業再編を除いた、事業再構築要件のいずれか(※2)を行うこと<br />
            <br />
            ※1：合併、会社分割、株式交換、株式移転、事業壌渡<br />
            ※2：新分野展開、事業転換、業種転換、業態転換<br />
            <br />
            また、以下の２要件を事業計画内で示すことで、事業再編に該当すると認められます。そのため、下記の事項に注意して、事業計画書の記載を行いましょう。<br />
            <br />
            <strong>満たすべき要件</strong><br />
            ①会社法上の組織再編行為等を行うこと(組織再編要件)<br />
            ②事業再編以外の事業再構築要件を満たすこと (その他の事業再構築要件)<br />
            <br />
            なお、事業再構築の売上高基準等の該当性判断は、以下の場合によって変化しますので、事業再編での申請を考えている方はご覧ください。<br />
          </p>
          <h3 className='media_text_text_title'>国内回帰</h3><br />
          <p className="media_text_text">
            <strong>定義</strong><br />
            中小企業等が海外で製造等する製品について、その製造方法が先進性を有する国内生産拠点を整備すること<br />
            引用：https://www.meti.go.jp/covid-19/jigyo_saikoutiku/pdf/shishin_tebiki.pdf<br />
            <br />
            上記の内容をまとめると、以下の2点が国内回帰の定義を満たすための事項となります。<br />
            ・海外で製造・調達している製品について、国内で生産拠点を整備する<br />
            ・事業による製品の製造方法が先進性を有するものである<br />
            <br />
            なお、日本標準産業分類については、本記事の下部にて詳細な解説を行っています。<br />
            <br />
            また、満たすべき要件の３要件を事業計画内で示すことで、国内回帰に該当すると認められますので、下記の事項に注意して、事業計画書の記載を行いましょう。<br />
            <br />
            <strong>満たすべき要件</strong><br />
            ① 海外で製造・調達している製品を、国内に生産拠点を整備すること(海外製造等要件)<br />
            ② 既存設備より先進的な設備で製造を行い、導入効果を証明すること(導入設備の先進性要件)<br />
            ③ 3～5年の事業計画期間が終了後、新製品の売上高が総売上高の10%以上となる計画を策定すること(新事業売上高10%等要件)<br />
          </p>
          </>
          
          <>
          <div id='3' className="media_text_title">
            <h2>３.各要件を満たす考え方</h2>
          </div>
          <h3 className='media_text_text_title'>日本標準産業分類上について</h3><br />
          <p className="media_text_text">
            日本標準産業分類とは、総務省が定めている業種・事業別の区分となっています。以下の４分類に分かれており、下に行くにつれて細かくなっていきます。
            <br />
            ・大分類<br />
            ・中分類<br />
            ・小分類<br />
            ・細分類<br />
            <br />
            例）<br />
            なお、事業再構築補助金指針の説明文では<br />
            業種…大分類の項目<br />
            事業…中分類・小分類・細分類の項目<br />
            として捉えることで、簡単に理解することが出来ると思います。<br />
            <br />
            下記リンクに産業分類のHPを記載いたしましたので、まずは既存事業と新規事業の産業分類を調べるところから始めてみましょう！<br />
            参照先：https://www.soumu.go.jp/toukei_toukatsu/index/seido/sangyo/02toukatsu01_03000023.html<br />
          </p>
          <h3 className='media_text_text_title'>新規性・売上高要件について</h3><br />
          <p className="media_text_text">
          本部分では、上記に記載されている新規性・撤去要件を満たすために、記載する具体的な項目をお伝えしていきます。<br />
          <br />
            本部分を事業計画に記載することで、要件や類型を満たしていると判断されるため、こちらの部分は特に丁寧に読み込んでいただくことをオススメします！<br />
            <br />
            <strong>製品等の新規性要件</strong><br />
            <br />
            ①過去に製造等を行った実績がないこと<br />
            →過去に製造を行った経験がないという旨をご記載ください。<br />
            <br />
            ②定量的に性能又は効能が異なること<br />
            →軽さや強度等、新製品と既存製品の違いを定量的にご記載ください。<br />
            (※定量的に比較することが難しい場合は、その旨を伝えることで要件を満たすことが出来ます。)<br />
            <br />
            <strong>市場の新規性要件</strong><br />
            ・既存事業と新規事業の顧客層が異なること<br />
            →既存事業の対象顧客を明らかにして、新規事業の顧客と明確に異なることを事業計画で説明してください。<br />
            <br />
            <strong>新事業売上高10%等要件</strong><br />
            ・3～5年間の事業計画期間終了後、新規事業の売上高が総売上高の10%(又は総付加価値額の15%)以上となる計画を策定すること<br />
            →事業計画書にて、条件を満たす売上計画を作成ください。<br />
            <br />
            <strong>売上高構成比要件</strong><br />
            ・3～5年間の事業計画期間終了後、新たな製品の属する業種が、売上高構成比の最も高い業種となる計画を策定すること<br />
            →事業計画書にて、条件を満たす売上計画を作成ください。<br />
            <br />
            <strong>組織再編要件</strong><br />
            ①会社法上の組織再編行為を行うこと<br />
            →交付決定後に、合併・会社分割・株式交換・株式移転・事業譲渡のいずれかを行ってください。<br />
            <br />
            ②その他の事業再構築のいずれかの類型の要件を満たすこと<br />
            →新市場進出、事業転換又は業種転換を満たす事業計画書の作成を行ってください。<br />
            <br />
            <strong>海外製造等要件</strong><br />
            ①当事業で生産する製品が、海外で製造・調達している製品であること<br />
            →2020年1月以降における海外からの調達実績や、海外から納品された事実が証明できる書類を準備してください。<br />
            <br />
            ②国内に生産拠点を整備する計画であること<br />
            →国内生産拠点の概要（整備場所、面積等）及び当該国内生産拠点において製造する製品の生産計画を示してください。<br />
            <br />
            <strong>導入設備の先進性要件</strong><br />
            ①先進的な設備を導入すること<br />
            →導入する全ての設備が特注品又は製造機器メーカーの最新カタログに掲載されているもの（またはこれに相当するもの）であることを示してください。<br />
            <br />
            ②導入設備の導入効果を証明すること<br />
            →性能や効能を定量的に説明することで、生産性や付加価値向上等の導入効果があることを示してください。<br />
          </p>
          </>
          
          <>
          <div id='4' className="media_text_title">
            <h2>４.各類型の具体例</h2>
          </div>
          <p className="media_text_text">
            <br />
            ここでは、事業再構築の類型を、具体例を含めて解説していきます。各類型と要件の対比表も記載していますので、ここで各類型とそれを満たす要件をセットで理解しましょう！<br />
            <br />
            <strong>①新市場進出</strong><br />
            <br />
            【飲食サービス業の例】<br />
            若者向けのアイスクリームを販売していた事業者が、冬季の売上を上昇させるために、新たな製造機器を導入してお汁粉を新販売する。<br />
            <br />
            ・日本標準産業分類での変遷<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>事業</th><th>大分類</th><th>中分類</th><th>小分類</th><th>細分類</th>
              </tr>
              <tr>
                <td><strong>既存事業</strong></td>
                <td>M：宿泊・飲食サービス業</td>
                <td>76：飲食店</td>
                <td>769：その他の飲食店</td>
                <td>7699：ほかに分類されない飲食店</td>
              </tr>
              <tr>
                <td><strong>新規事業</strong></td>
                <td>M：宿泊・飲食サービス業</td>
                <td>76：飲食店</td>
                <td>769：その他の飲食店</td>
                <td>7699：ほかに分類されない飲食店</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            要件との一致
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th colspan="2">要件</th><th>要件を満たす考え方</th>
              </tr>
              <tr>
                <td rowspan="2"><strong>製品等の新規性要件</strong></td>
                <td>①過去に製造した実績がないこと</td>
                <td>過去にお汁粉を製造・販売した実績がないため、要件を満たす</td>
              </tr>
              <tr>
                <td>②定量的に性能・効能が異なること</td>
                <td>アイスクリームとお汁粉は定量的に比較することが困難なため、要件を満たす</td>
              </tr>
              <tr>
                <td><strong>市場の新規性要件</strong></td>
                <td>既存顧客と新規顧客の顧客層が異なること</td>
                <td>既存顧客は冷たいものを求める若年層であるが、新規顧客は暖かいものを求める高齢者層をターゲットとしているため、顧客層が異なり、要件を満たす</td>
              </tr>
              <tr>
                <td><strong>新事業売上高10%等要件</strong></td>
                <td>事業計画期間が終了後、新製品の売上高が総売上高の10%以上となる計画を策定すること</td>
                <td>事業計画期間終了後の売上予測によると、お汁粉の売上が総売上高の20%となるため、要件を満たす</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            <strong>②事業転換</strong><br />
            【製造業の例】<br />
            乳牛の畜産産業を行っていた事業者が、畜産時に生じる処理物を肥料として、新たに野菜漬物の製造を行う。<br />
            <br />
            ・日本標準産業分類での変遷<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>事業</th><th>大分類</th><th>中分類</th><th>小分類</th><th>細分類</th>
              </tr>
              <tr>
                <td><strong>既存事業</strong></td>
                <td>E：製造業</td>
                <td>09：食料品製造業</td>
                <td>091：畜産食料品製造業</td>
                <td>0912：肉加工品製造業</td>
              </tr>
              <tr>
                <td><strong>新規事業</strong></td>
                <td>E：製造業</td>
                <td>09：食料品製造業</td>
                <td>093：野菜缶詰・果実缶詰・農産保存食料品製造業</td>
                <td>0932：野菜漬物製造業</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            要件との一致<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th colspan="2">要件</th><th>要件を満たす考え方</th>
              </tr>
              <tr>
                <td rowspan="2"><strong>製品等の新規性要件</strong></td>
                <td>①過去に製造した実績がないこと</td>
                <td>過去に野菜漬物を製造した実績がないため、要件を満たす</td>
              </tr>
              <tr>
                <td>②定量的に性能・効能が異なること</td>
                <td>肉加工品と野菜漬物は定量的に性能・効能を比較することが困難なため、要件を満たす</td>
              </tr>
              <tr>
                <td><strong>市場の新規性要件</strong></td>
                <td>既存顧客と新規顧客の顧客層が異なること</td>
                <td>肉加工品は地域の小売業者に卸しており、地域住民がターゲットだが、野菜漬物はEC販売を行うため全国の人々がターゲットであり、顧客層が異なる</td>
              </tr>
              <tr>
                <td><strong>売上高構成比要件</strong></td>
                <td>事業計画期間が終了後、新製品の売上高が総売上高の中で最も高い比率となる計画を策定すること</td>
                <td>事業計画期間終了後の売上予測によると、野菜漬物の売上は総売上高の60%を占める予定になっているため、要件を満たす。</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            <strong>③業種転換</strong><br />
            <br />
            【専門サービス業の例】<br />
            公認会計士事務所を行っていた事業者が、公認会計士を目指している社会人に向けて教育塾を行う<br />
            <br />
            ・日本標準産業分類での変遷<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>事業</th><th>大分類</th><th>中分類</th><th>小分類</th><th>細分類</th>
              </tr>
              <tr>
                <td><strong>既存事業</strong></td>
                <td>L：学術研究、専門・技術サービス業</td>
                <td>72：専門サービス業</td>
                <td>724：公認会計士事務所、税理士事務所</td>
                <td>7241：公認会計士事務所</td>
              </tr>
              <tr>
                <td><strong>新規事業</strong></td>
                <td>T：分類不能の産業</td>
                <td>99：分類不能の産業</td>
                <td>999：分類不能の産業</td>
                <td>9999：分類不能の産業</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            要件との一致
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th colspan="2">要件</th><th>要件を満たす考え方</th>
              </tr>
              <tr>
                <td rowspan="2"><strong>製品等の新規性要件</strong></td>
                <td>①過去に製造した実績がないこと</td>
                <td>過去に教育事業を行った実績がないため、要件を満たす</td>
              </tr>
              <tr>
                <td>②定量的に性能・効能が異なること</td>
                <td>士業業務と教育事業は定量的に比較することが困難なため、要件を満たす</td>
              </tr>
              <tr>
                <td><strong>市場の新規性要件</strong></td>
                <td>既存顧客と新規顧客の顧客層が異なること</td>
                <td>士業は法人が顧客であるが、教育事業は一般消費者が顧客層となるため、明確に異なると言え、要件を満たす</td>
              </tr>
              <tr>
                <td><strong>売上高構成比要件</strong></td>
                <td>事業計画期間が終了後、新製品の売上高が総売上高の中で最も高い比率となる計画を策定すること</td>
                <td>事業計画期間終了後の売上予測によると、教育事業の売上は総売上高の52%を占める予定になっているため、要件を満たす。</td>
              </tr>
            </tbody>
          </table>
          </>

          <>
          <div id='5' className="media_text_title">
            <h2>５.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、事業再構築補助金を申請したい事業者さまへ向けて、事業再構築要件の概要について記事にさせていただきました。<br />
            <br />
            募集要項を各所に添付させていただきましたが、ご覧いただけば分かる通り難しい内容となっております。<br />
            そこで、もし、本格的に事業再構築補助金の導入を検討されていましたら、弊社含め補助金に詳しい事業者へご相談ください。<br />
            <br />
            ご相談は、画面右上の補助金に関する相談を問い合わせにて、お待ちしております。<br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


