import './styles.css';

const Help = () => {
  
  return (
    <>
    <div className='help_result_contact'>
      <h4>ご相談、疑問点がございましたら、ご気軽にお問い合わせください。</h4>
      <a href='https://trankllc.com/contact'>
        <div>お問い合わせ</div>
      </a>
    </div>
    </>
  );
}

export default Help;
