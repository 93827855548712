// 認定支援機関要件

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/9/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」認定支援機関要件は、最短１日で完結！',
    description: '本記事では、認定支援機関要件の類型を詳しく解説しております。その他、具体例や判断に必要な考え方についても説明しております。本記事は、「認定支援機関要件がよく分からない」方や「どこの認定支援機関がいいか知りたい」という方に向けて、執筆させていただきまた。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」<br />認定支援機関要件は、最短１日で完結！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は、<strong>日本最大級の補助金である「事業再構築補助金」</strong>について解説させていただきます。<br />
            <br />
            本記事は、「<strong>認定支援機関要件がよく分からない</strong>」方や「<strong>どこの認定支援機関がいいか知りたい</strong>」という方に向けて、執筆させていただきまた。<br />
            <br />
            認定支援機関要件について、知りたい方はぜひご覧ください！<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>本記事でこんな悩みが解決！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>認定支援機関要件を満たす方法</span></li>
              <li><span>認定支援機関についての概要</span></li>
              <li><span>認定支援機関の選び方、よくある質問</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、補助金の説明書である「公募要領」を参考にして作成されたものとなっていますので、本記事の内容を理解したうえで公募要領部を読んでみてください！<br />
            <br />
            なお、<strong>右上のボタンからお問い合わせをお受けしております</strong>ので、申請サポートのご依頼や不明点のご相談はお気軽にご連絡ください。<br />
            <br />
            それでは、解説に進ませていただきます！<br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">認定支援機関要件とは</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#3" offset="100">要件の対象者</AnchorLink></li>
                  <li><AnchorLink href="#3" offset="100">認定支援機関要件を満たす方法</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#1" offset="100">認定支援機関の概要</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#3" offset="100">認定支援機関要件を満たす方法</AnchorLink></li>
                  <li><AnchorLink href="#3" offset="100">認定支援機関の種類</AnchorLink></li>
                  <li><AnchorLink href="#3" offset="100">おススメの認定支援機関</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#1" offset="100">よくある質問</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#3" offset="100">認定支援機関要件についての質問</AnchorLink></li>
                  <li><AnchorLink href="#3" offset="100">認定支援機関についての質問</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#4" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>１.認定支援機関要件とは</h2>
          </div>
          <p className="media_text_text">
            <br />
            認定支援機関要件とは、「<strong>中小企業が新規事業を滞りなく遂行できるように、中小企業支援の専門家からアドバイスを受ける</strong>」という要件となります。<br />
            <br />
            また、認定経営革新等支援機関の略語が認定支援機関となっておりますので、本記事では基本的に認定支援機関という言葉を使わせていただきます。<br />
          </p>
          <h3 className='media_text_text_title'>要件の対象者</h3><br />
          <p className="media_text_text">
            売上高減少要件を満たす必要があるのは、以下の申請枠の方となっており、全ての申請枠で必要な要件となっております。<br />
            <br />
            ・<strong>通常枠</strong><br />
            ・<strong>大規模賃金引上枠</strong><br />
            ・<strong>回復・再生応援枠</strong><br />
            ・<strong>最低賃金枠</strong><br />
            ・<strong>グリーン枠</strong><br />
            ・<strong>緊急対策枠</strong><br />
            <br />
            そのため、本記事の内容は、事業再構築補助金の申請を考えている、全ての事業者様にお読みいただきたい内容となっています。<br />
            <br />
            内容はあまり難しくないものとなっていますので、しっかりと確認していきましょう！<br />
          </p>
          <h3 className='media_text_text_title'>認定支援機関要件を満たす方法</h3><br />
          <p className="media_text_text">
            まずは、公募要領内に記載されている認定支援機関の定義を記載いたします。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>事業再構築補助金の目的</span><br />
            ア．事業計画は、認定経営革新等支援機関とご相談の上策定してください。 <br />
            <br />
            イ．事業計画を認定経営革新等支援機関と策定し、「認定経営革新等支援機関による確認書」を提出してください。 <br />
            <br />
            ウ．補助金額が3,000万円以下の事業計画は、認定経営革新等支援機関（地域金融機関、税理士等）と、補助金額が3,000万円を超える事業計画は、金融機関及び認定経営革新等支援機関（金融機関が認定経営革新等支援機関であれば当該金融機関のみでも可）と共同で策定する必要があります。3,000万円を超える事業計画は、「金融機関による確認書」を提出してください。<br />
            <br />
            エ．新型コロナウイルスの感染拡大防止の観点から、認定経営革新機関等にご相談される際は、 オンライン会議で行うことや、事前に電話等で問い合わせをした上でご訪問されることを推奨します。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            以上の公募要領をまとめると、売上等減少要件の定義は以下の3点になります。<br />
            <br />
            ①：<strong>事業計画書は、認定支援機関と相談の上策定すること</strong><br />
            ②：<strong>①の証拠として、「認定経営革新等支援機関による確認書」を提出すること</strong><br />
            ③：<strong>補助金額が3,000万円を超える場合は、「金融機関による確認書」を追加で提出する必要がある。</strong><br />
            <br />
            なお、認定経営革新等支援機関による確認書は下記リンクよりダウンロードしていただき、ご相談していただいた認定支援機関様に作成をお願いしてください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              リンク：認定経営革新等支援機関による確認書のダウンロード
            </a>
            <br />
            <br />
            <strong>注意ポイント！</strong><br />
            ・事業計画書は認定支援機関に相談するもの(作成は事業者様が行う)<br />
            ・認定経営革新等支援機関による確認書は、認定支援機関が作成するもの<br />
            <br />
            以上の2点は混同しやすくなっておりますので、ご確認ください。事業者様が認定経営革新等支援機関による確認書を行うことは出来ませんので、ご注意ください。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>認定支援機関要件を満たす方法</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>「認定経営革新等支援機関による確認書」を作成してもらい、提出する。</span></li>
              <li><span>補助金額が3,000万円を超える場合、追加で「金融機関による確認書」を提出する。</span></li>
            </ul>
          </div>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>２.認定支援機関とは</h2>
          </div>
          <p className="media_text_text">
            <br />
            こちらでは、<strong>「認定経営革新等支援機関による確認書」を発行することの出来る機関</strong>についてまとめていきます。<br />
            <br />
            事業再構築補助金では認定支援機関と強固な関係性を築くことが求められるため、しっかりと確認しておきましょう！<br />
          </p>
          <h3 className='media_text_text_title'>認定支援機関要件を満たす方法</h3><br />
          <p className="media_text_text">
            認定支援機関については、日本政府の公式サイトである「ミラサポplus」にて下記のような記載がされております。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>認定支援機関とは？</span><br />
            認定経営革新等支援機関（認定支援機関）とは、中小企業支援に関する専門的知識や実務経験が一定レベル以上にある者として、国の認定を受けた支援機関（税理士、税理士法人、公認会計士、中小企業診断士、商工会・商工会議所、金融機関等）です。<br />
            <a href='https://mirasapo-plus.go.jp/supporter/certification/#:~:text=%E8%AA%8D%E5%AE%9A%E7%B5%8C%E5%96%B6%E9%9D%A9%E6%96%B0%E7%AD%89%E6%94%AF%E6%8F%B4,%E3%80%81%E9%87%91%E8%9E%8D%E6%A9%9F%E9%96%A2%E7%AD%89%EF%BC%89%E3%81%A7%E3%81%99%E3%80%82' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：ミラサポplus</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            上記の記載をまとめると、認定支援機関には以下の特徴があると考えられます。<br />
            <br />
            ・<strong>中小企業支援の専門的知識や、実務経験を持っている機関</strong><br />
            ・<strong>上記について、国(中小企業庁)の認定を受けている機関</strong><br />
            ・<strong>士業や金融機関といった、様々な法人・個人が認定されている</strong><br />
            <br />
            上記より、認定支援機関は事業再構築を行う中小企業にとって、大きな力になると考えられます。<br />
          </p>
          <h3 className='media_text_text_title'>認定支援機関の種類</h3><br />
          <p className="media_text_text">
            認定支援機関を、大まかに分類わけいたしましたので、どの認定支援機関にアドバイスを行うか検討いただければと思います。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>認定支援機関の種類</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>特別認可法人(商工会・商工中金・商工会議所)</span></li>
              <li><span>金融機関(銀行・信用金庫・信用組合等)</span></li>
              <li><span>士業・士業法人(税理士・公認会計士・中小企業診断士等)</span></li>
              <li><span>その他法人(民間コンサルティング会社・一般社団法人等)</span></li>
            </ul>
          </div>
          <h3 className='media_text_text_title'>おススメの認定支援機関</h3><br />
          <p className="media_text_text">
            本部分では、<strong>数ある認定支援機関の中で、おすすめの認定支援機関をご紹介させていただきます</strong>。<br />
            <br />
            ※本記事はあくまでおススメであり、事業再構築補助金の目的や事業内容によって大きく異なるため、あくまで参考としてご覧ください。<br />
            <br />
            まずは、事業再構築補助金の申請を行う上で、おすすめの機関を下記にてご紹介させていただきます。<br />
            <br />
            ・<strong>既存取引がある士業</strong><br />
            ＋：自社の情報を既に取得していることから、理念などの共有が簡単<br />
            　　付属業務として、無料で確認書の発行を行ってくれることが多い<br />
            　　スケジュール調整に関しても、融通を聞かせてくれることが多い<br />
            <br />
            －：士業によっては、採択率があまり高くないところもある。<br />
            <br />
            ・<strong>取引がある銀行</strong><br />
            ＋：自社の情報を既に取得していることから、理念などの共有が簡単<br />
            　　付属業務として、無料で確認書の発行を行ってくれることがほとんど<br />
            　　採択率も高く、丁寧なアドバイスを受けられる可能性が高い<br />
            <br />
            －：比較的スケジュール調整が難しく、確認書の提出に2週間ほどかかることがある<br />
            <br />
            ・<strong>民間コンサルティング会社</strong><br />
            ＋：採択率が高く、事業計画書の精度を大幅に上げてくれる可能性が高い<br />
            　　申請件数が非常に多く、豊富なノウハウを保持している<br />
            　　事業計画書のみならず、申請書類や加点書類の提出までサポートしてくれることが多い<br />
            <br />
            －：ほとんどの場合、サポート代金を請求される。<br />
            <br />
            以上おすすめの認定支援機関となっております。上記の情報をもとに、申請までの時間や、事業再構築補助金に対して割ける工数を考慮してご判断ください。<br />
            <br />
            なお、他記事にて認定支援機関別の採択率を別途記載しておりますので、ご興味がございましたら、是非ご覧ください！<br />
            <a href='https://trankllc.com/media/subsidy/business/0004' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【採択率はどれくらい？】事業再構築補助金の過去採択を徹底解剖！
            </a><br />
            <br />
            また、認定支援機関に関しましては弊社からのご紹介も承っておりますので、ご不明点等ございましたら、是非右上のお問い合わせフォームよりご連絡ください！<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>３.よくある質問</h2>
          </div>
          <p className="media_text_text">
            <br />
            ここでは、認定支援機関要件に関係する質問をまとめてあります。<br />
            <br />
            当社がよく受ける質問が記載されていますので、認定支援機関要件について疑問がある際は、まずこちらを確認したうえで、右上の問い合わせフォームから弊社にお問い合わせください！<br />
          </p>
          <h3 className='media_text_text_title'>認定支援機関要件についての質問</h3><br />
          <p className="media_text_text">
            Q：<strong>「認定経営革新等支援機関の確認書」は多く出すと加点になりますか？</strong><br />
            A：<strong>枚数による加点等は存在しないため、1枚のみの提出でお願いします。</strong><br />
            <br />
            Q：<strong>どのくらいの時間で「認定経営革新等支援機関の確認書」は発行してもらえますか？</strong><br />
            A：<strong>認定支援機関にもよりますが、平均は3日前後となっています。</strong><br />
            <br />
            Q：<strong> 「認定経営革新等支援機関の確認書」は無料で発行してもらえますか？</strong><br />
            A：<strong> 認定支援機関によって定められております。顧問税理士等であれば、無料であることが多いです。</strong><br />
          </p>
          <h3 className='media_text_text_title'>認定支援機関についての質問</h3><br />
          <p className="media_text_text">
            Q：<strong>認定支援機関に依頼する際はどのように行えばいいですか？</strong><br />
            A：<strong>まずは、顧問契約を結んでいる士業様にご相談することをオススメします。当てがない場合は、認定支援機関様のHPからお問い合わせください。</strong><br />
            <br />
            Q：<strong>認定支援機関は事業計画にどのくらい携わってくれますか？</strong><br />
            A：<strong>基本的には、完成した計画書をみてアドバイスする形で携わることが多いです。作成から関与してもらいたい場合は、弊社のような民間コンサルティン</strong>グ会社がおススメです。<br />
            <br />
            Q：<strong>認定支援機関によって採択率は変わりますか？</strong><br />
            A：<strong>認定支援機関の名前で採択率が変わることはありません。ただ、アドバイスの質が高いために採択率が高い認定支援機関も存在します。</strong><br />
          </p>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>４.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、<strong>認定支援機関要件について解説をさせていただきました</strong>。<br />
            <br />
            やや複雑なものとはなっておりますが、少しずつ進めていくと意外と簡単なものですので、諦めずに申請まで頑張ってください。<br />
            <br />
            <strong>ご不明点、疑問点につきましては、右上の問い合わせからお待ちしております</strong>ので、ご気軽にご連絡いただければと思います。<br />
            <br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


