import './styles.css';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const BidHome = () => {

  const [ prefectures, setPrefectures ] = useState('');
  const [ keyword, setKeyword ] = useState('');

  const history = useHistory();

  useEffect(() => {
    
  }, []);

  function search() {
    var data = new Date().toISOString().slice(0, 10);
    history.push(`/bid/results/keyword=${keyword}+date=2000-01-01,${data}+prefecture=${prefectures}+work=+format=+page=0`);
  }

  return (
    <>
    <div className='bid_header_search'>
      <select onChange={(e) => setPrefectures(e.target.value)}>
        <option value=''>都道府県</option>
        <option value='北海道'>北海道地方</option>
        <option value='青森県,岩手県,宮城県,秋田県,山形県,福島県'>東北地方</option>
        <option value='東京都,茨城県,栃木県,群馬県,埼玉県,千葉県,神奈川県'>関東地方</option>
        <option value='新潟県,富山県,石川県,福井県,山梨県,長野県,岐阜県,静岡県,愛知県'>中部地方</option>
        <option value='京都府,大阪府,三重県,滋賀県,兵庫県,奈良県,和歌山県'>近畿地方</option>
        <option value='鳥取県,島根県,岡山県,広島県,山口県'>中国地方</option>
        <option value='徳島県,香川県,愛媛県,高知県'>四国地方</option>
        <option value='福岡県,佐賀県,長崎県,大分県,熊本県,宮崎県,鹿児島県,沖縄県'>九州地方</option>
      </select>
      <input placeholder='キーワード（土木、施工、システム開発など）' onChange={(e) => setKeyword(e.target.value)} />
      <button onClick={() => search()}>検索</button>
    </div>
    </>
  );
}

export default BidHome;
