const BlogText0001 = (props) => {

  return(
  <div className='management_review_content_1'>
    <h4>自社の状況に関する質問（1問）</h4>
    <div>
      <h5>Q1.研究開発費の状況</h5>
      <p>研究開発費の額の平均の額をご記載ください。</p>
      <input onChange={(e) => props.Research(e.target.value)} placeholder="10000000" type="number" />
    </div>
  </div>
  );
}

export default BlogText0001;
