import './styles.css';
import { useState } from 'react';
import firebase from "firebase/compat/app"

const Help = (props) => {

  const [ name, setName ] = useState('');
  const [ company, setCompany ] = useState('');
  const [ mailAddress, setMailAddress ] = useState('');
  const [ tellPhone, setTellPhone ] = useState('');
  const [ content, setContent ] = useState('');

  const [ alert, setAlert ] = useState(0);

  function confirmation() {
    if (company === '') {
      setAlert(1);
    } else if (name === '') {
      setAlert(2);
    } else if (tellPhone === '') {
      setAlert(3);
    } else if (mailAddress === '') {
      setAlert(4);
    } else {
      setAlert(0);
      update();
    }
  }

  function update() {
    firebase.firestore().collection('mails').doc()
    .set({
      name: name,
      company: company,
      mailAddress: mailAddress,
      tellPhone: tellPhone,
      content: `事業再構築補助金のランディングページより問い合わせ。 ${content}`,
      time: `${new Date().getFullYear()}年${new Date().getMonth() + 1}月${new Date().getDate()}日${new Date().getHours()}時${new Date().getMinutes()}分${new Date().getSeconds()}秒`,
    }).then(() => {
      setName('');
      setCompany('');
      setMailAddress('');
      setTellPhone('');
      setContent('');
      setAlert(0);
    })
  }
  
  return (
    <>
    <div className='hojokin_contact'>
      <div className='hojokin_contact_form'>
        <div id="1">
          <h4>お問い合わせ</h4>
          <p>事業再構築補助金に当てはまるか等の質問も受け付けておりますので、ご気軽にご連絡いただければと思います。</p>
          <div className='hojokin_contact_form_input'>
            <h5>会社名</h5>
            <input value={company} onChange={e => setCompany(e.target.value)} />
            <div></div>
            {alert === 1 ? <p>※会社名をご記載ください。</p> : <p>　</p>}
          </div>
          <div className='hojokin_contact_form_input'>
            <h5>氏名</h5>
            <input value={name} onChange={e => setName(e.target.value)}  />
            <div></div>
            {alert === 2 ? <p>※氏名をご記載ください。</p> : <p>　</p>}
          </div>
          <div className='hojokin_contact_form_input'>
            <h5>電話番号</h5>
            <input value={tellPhone} onChange={e => setTellPhone(e.target.value)}  />
            <div></div>
            {alert === 3 ? <p>※電話番号をご記載ください。</p> : <p>　</p>}
          </div>
          <div className='hojokin_contact_form_input'>
            <h5>メールアドレス</h5>
            <input value={mailAddress} onChange={e => setMailAddress(e.target.value)}  />
            <div></div>
            {alert === 4 ? <p>※メールアドレスをご記載ください。</p> : <p>　</p>}
          </div>
          <div className='hojokin_contact_form_context'>
            <h5>問い合わせ内容</h5>
            <textarea value={content} onChange={e => setContent(e.target.value)}  />
            <div></div>
            {alert === 4 ? <p>※メールアドレスをご記載ください。</p> : <p>　</p>}
          </div>
          <div className='hojokin_contact_form_btn' onClick={() => confirmation()}>送信</div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Help;
