// 賃金引上枠

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "../../nav/index";
import Topic from "../topic";
import TopImage from "./../../../../../image/media/business/27/top.png";
import Image1 from "./../../../../../image/media/business/20/2.png";
import Image2 from "./../../../../../image/media/business/20/3.png";
import Footer from '../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」賃金引上枠は従業員の10%の賃金を確認！',
    description: '本記事では、賃金引上枠の申請要件、補助率、補助金額について解説をしています。何を満たせば申請できるのか？>の特徴を知りたいという方は向けの記事となっておりますので、補助金を申請したいという方はぜひいご覧いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」賃金引上枠は従業員の10%の賃金を確認！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。<strong>補助金の申請を支援しているトランク合同会社</strong>です。<br />
            <br />
            本記事では事業再構築補助金という日本で最も大きい<strong>事業再構築補助金の最低賃金枠について解説</strong>させていただきました。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>最低賃金枠とは？</span></li>
              <li><span>最低賃金枠の要件がわからない！</span></li>
              <li><span>最低賃金枠の要件、補助率など詳しい情報が知りたい！</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事では<strong>事業再構築補助金の公募要領を元に執筆しております</strong>ので、本記事での解説を理解した後に改めて公募要領をご覧いただければと思います。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo.pdf' target="_blank" rel="nofollow noopener noreferrer">
              リンク：事業再構築補助金 公募要領
            </a><br />
            <br />
            もし、本記事を最後まで読んだけど、わからない。という方は、ぜひご気軽にお声掛けいただければと思います。<br />
            <br />
            では、本題である最低賃金枠の解説へ進めさせていただきます！<br />
            <br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">最低賃金枠の概要</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">最低賃金枠の概要</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">最低賃金枠の補助金額</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">最低賃金枠の補助率</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">最低賃金枠の補助対象経費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">最低賃金枠の要件</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">各要件の解説</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">事業再構築要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">認定支援機関要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">付加価値額要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">市場拡大要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">給与総額増加要件</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">最低賃金枠のポイント</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.最低賃金枠の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            最低賃金枠は<strong>国が推し進めている最低賃金の増加を推進するための枠</strong>となっております。<br />
            <br />
            まず、<strong>最低賃金枠の概要を公募要領より引用いたしました</strong>ので、下記の表より概要、補助金額、補助率等についてご確認いただければと思います。<br />
            <br />
            以下に記載があります各要件に付きましては、次章にて解説しております。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>項目</th><th>要件</th>
              </tr>
              <tr>
                <td>概要</td>
                <td>最低賃金引上げの影響を受け、その原資の確保が困難な特に業況の厳しい中小企業等の事業再構築を支援。</td>
              </tr>
              <tr>
                <td>補助金額</td>
                <td>
                【従業員数 5 人以下】 100 万円 ～ 500 万円<br />
                【従業員数６~20 人】 100 万円 ～ 1,000 万円<br />
                【従業員数 21 人以上】 100 万円 ～ 1,500 万円<br />
                </td>
              </tr>
              <tr>
                <td>補助率</td>
                <td>
                  中小企業者等 3/4<br />
                  中堅企業等 2/3<br />
                </td>
              </tr>
              <tr>
                <td>補助対象経費</td>
                <td>
                  建物費、機械装置・システム構築費（リース料を含む）、技術導入費、専門 家経費、運搬費、クラウドサービス利用費、外注費、知的財産権等関連経費、 広告宣伝・販売促進費、研修費<br />
                </td>
              </tr>
              <tr>
                <td>要件</td>
                <td>
                  ・事業再構築要件<br />
                  ・認定支援機関要件<br />
                  ・付加価値額要件<br />
                  ・売上高等減少要件<br />
                  ・最低賃金要件
                </td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>最低賃金枠の概要</h3><br />
          <p className="media_text_text">
            最低賃金枠は、<strong>最低賃金引上げの影響を受け、その原資の確保が困難な特に業況の厳しい中小企業等の事業再構築を支援する枠</strong>となっております。<br />
            <br />
            そのため、最低賃金枠では売上の減少を示す要件と最低賃金の要件が追加されております。<br />
            <br />
            最低賃金の引上によって、影響が出ている事業者様、売上が減少している事業者様はぜひ最低賃金枠で申請してみてはいかがでしょうか。<br />
          </p>
          <h3 className='media_text_text_title'>最低賃金枠の補助金額</h3><br />
          <p className="media_text_text">
            最低賃金枠の補助金額は、最低賃金枠の概要の表で記載した通り、以下のように定義されております。<br />
            <strong>【従業員数 5 人以下】 100 万円 ～ 500 万円</strong><br />
            <strong>【従業員数６~20 人】 100 万円 ～ 1,000 万円</strong><br />
            <strong>【従業員数 21 人以上】 100 万円 ～ 1,500 万円</strong><br />
            <br />
            この際に、確認していただきたい点は、<strong>従業員数の算出方法</strong>になります。<br />
            例えば、役員は従業員に含まれない。アルバイトは含まれる。など算出方法がさまざまですので、お間違いないよう計算いただければと思います。<br />
            <a href='https://www.chusho.meti.go.jp/faq/faq/faq01_teigi.htm#q3' target="_blank" rel="nofollow noopener noreferrer">
              参考サイト：従業員数の算出方法
            </a><br />
          </p>
          <h3 className='media_text_text_title'>最低賃金枠の補助率</h3><br />
          <p className="media_text_text">
            補助率は上述に示している通り、以下のようなものとなっております。<br />
            <strong>中小企業者等 3/4</strong><br />
            <strong>中堅企業等 2/3</strong><br />
            <br />
            この補助率と補助上限金額によって、補助金額を計算することができますので、以下の例として算出いただければと思います。<br />
            <br />
            <strong>１）補助金額の計算例：従業員数5名、投資金額600万円の場合、補助金額は450万円</strong><br />
            産業構造転換枠を申請した事業者様が従業員数5名、投資金額600万円の場合、中小企業のため、補助率は3/4であるため、600万円の3/4で補助金額は450万円、自己負担額は250万円となります。<br />
            <br />
            <strong>２）補助金額の計算例</strong><br />
            産業構造転換枠を申請した事業者様が従業員数5名、投資金額2,000万円の場合、中小企業のため、補助率は3/4であるため、2,000万円の3/4で補助金額は1,500万円のはずですが、従業員数 5 人以下の場合500万円が上限とされているため、補助金額は500万円となり、自己負担額は1,500万円となります。<br />
            <br />
            <strong>上限金額以上の投資金額については補助されません</strong>ので、補助率だけでなく補助上限の確認も行った上で申請、及び財務計画を立てていただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>最低賃金枠の補助対象経費</h3><br />
          <p className="media_text_text">
            産業構造転換枠の補助対象経費は、産業構造転換枠の概要で示した通り、以下のものとなっております。<br />
            <strong>建物費、機械装置・システム構築費（リース料を含む）、技術導入費、専門 家経費、運搬費、クラウドサービス利用費、外注費、知的財産権等関連経費、 広告宣伝・販売促進費、研修費、廃業費</strong><br />
            <br />
            上記の費用が具体的に何に使えるのか？については、以下の記事にて詳しく記載しておりますので、ご参照いただければと思います。<br />
            <a href='https://trankllc.com/media/subsidy/business/0003' target="_blank" rel="nofollow noopener noreferrer">
              参考：事業再構築補助金 12個の経費分類を具体例つきで解説！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>最低賃金枠の要件</h3><br />
          <p className="media_text_text">
            最低賃金枠の要件は、最低賃金枠の概要で示した通り、以下のものとなっております。<br />
            <strong>・事業再構築要件</strong><br />
            <strong>・認定支援機関要件</strong><br />
            <strong>・付加価値額要件</strong><br />
            <strong>・売上高等減少要件</strong><br />
            <strong>・最低賃金要件</strong><br />
            <br />
            各要件については、次章で1つずつ解説しておりますので、ご確認いただければと思います。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.各要件の解説</h2>
          </div>
          <p className="media_text_text">
            <br />
            上述の表で、最低賃金枠の要件を示した通り、本枠での申請を検討されている方は、以下の４点の要件を満たす必要がございますので、1つずつご確認いただければと考えております。<br />
            <br />
            ご不明点等ございましたら、右上のお問い合わせよりご連絡いただければご質問にお答えさせていただきますので、ご連絡いただければと思います。<br />
            <br />
            <strong>①	事業再構築要件</strong><br />
            <strong>②	認定支援機関要件</strong><br />
            <strong>③	付加価値額要件</strong><br />
            <strong>④	売上高等減少要件</strong><br />
            <strong>⑤	最低賃金要件</strong>
          </p>
          <h3 className='media_text_text_title'>事業再構築要件</h3><br />
          <p className="media_text_text">
            事業再構築要件とは、<strong>新分野展開、事業転換、業種転換、業態転換又は事業再編のいずれかに該当する事業計画でなければいけない</strong>という要件です。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・新分野展開、事業転換、業種転換、業態転換又は事業再編のいずれかに該当する事業計画を策定すること<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・事業計画書<br />
            <br />
            <strong>参考記事</strong><br />
            上記５点の（新分野展開、事業転換、業種転換、業態転換又は事業再編）についての詳しい記事を別途作成させていただきましたので、ぜひご参照いただければと思います。<br />
            <a href='https://trankllc.com/media/subsidy/business/0007' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【類型・転換って何？】事業再構築補助金の再構築要件を、例を用いて解説！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>認定支援機関要件</h3><br />
          <p className="media_text_text">
            認定支援機関要件とは、<strong>国に中小企業支援の知識・経験がある者として認められた認定支援機関に事業計画書を確認してもらわなければいけない</strong>という要件です。<br />
            <br />
            認定支援機関とは、<strong>中小企業支援の知識・経験がある者として、国の認定を受けた機関であり、士業や商工会、金融機関が多く認可されています</strong>。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・認定支援機関と相談したうえで事業計画書を策定する。<br />
            ※認定経営革新等支援機関による確認書は、認定支援機関に相談したことを証明する書類ですので、必ず認定支援機関に作成をお願いしてください。<br />
            <br />
            <strong>【要件を満たすために必要な書類】</strong><br />
            ・認定経営革新等支援機関による確認書<br />
            ・金融機関による確認書(補助金額が3,000万円を超える場合)<br />
            <br />
            認定支援機関要件は、下記の記事にて詳しく解説しておりますので、詳細が知りたい方はぜひご参照ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0009' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【どこに依頼する？】事業再構築補助金の認定支援機関要件を機関別に解説！
            </a><br />
            <br />
            おすすめの認定支援機関や認定支援機関別の採択率は、下記の記事でまとめて記載しておりますので、下記リンクよりご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0004' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【採択率はどれくらい？】事業再構築補助金の過去採択を徹底解剖！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>付加価値額要件</h3><br />
          <p className="media_text_text">
            付加価値額要件とは、<strong>付加価値額が上昇する事業計画を策定しなければいけない</strong>という要件です。<br />
            <br />
            付加価値額とは、補助金独自の考え方であり、以下の方法により算出できます。<br />
            <span>付加価値額=営業利益+減価償却費+人件費</span><br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・補助事業終了後3~5年で、付加価値額の年平均率を3.0%以上増加させること<br />
            ・補助事業終了後3~5年で、従業員1人当たりの付加価値額の年平均率3.0%以上増加させること<br />
            ※上記のどちらかを満たす事業計画を策定することが要件となります。<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・事業計画書<br />
            <br />
            上記の要件を満たした事業計画書を策定することが必要になりますので、認定支援機関と共にご確認をお願いいたします。<br />
          </p>
          <h3 className='media_text_text_title'>売上等減少要件要件</h3><br />
          <p className="media_text_text">
            売上等減少要件要件とは、<strong>2022年1月以降の連続する6か月の内、任意の3か月(物価高騰後)と2019年～2021年の同月(物価高騰前)で比較した際、総売上高が10%以上減少していること</strong>で要件を満たすことできます。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・2022年1月以降の連続する6か月の内、任意の3か月(物価高騰後)と2019年～2021年の同月(物価高騰前)で比較した際、総売上高が10%以上減少していること<br />
            <br />
            売上減少を計算する際の具体的な手順は、下記の記事でまとめて記載しておりますので、下記リンクよりご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0008' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【10%・15%】事業再構築補助金の売上高等減少要件を具体例で解説！
            </a><br />
            <br />
            <strong>【法人の場合 要件を満たすために提出する書類】</strong><br />
            <br />
            ・物価高騰前の、選択した任意3か月の売上が分かる年度確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の法人事業概況説明書の控え(両面)<br />
            ・受信通知(1枚)(e-Taxで申告している場合のみ)<br />
            ・物価高騰後の、選択した任意3か月の売上が分かる確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の法人事業概況説明書の控え(両面)<br />
            <br />
            なお、上記書類の図例を下記に記載いたしますので、こちらも参考にしてご準備ください。<br />
            <br />
            <strong>【個人の場合 要件を満たすために提出する書類】</strong><br />
            <br />
            ・物価高騰前の、選択した任意3か月の売上が分かる年度確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の月別売上の記載がある所得税青色申告決算書の控え(両面)<br />
            (白色申告の方は、対象月の売上が分かる売上台帳や、確定申告の基礎となる書類)<br />
            ・受信通知(1枚)(e-Taxで申告している場合のみ)<br />
            ・物価高騰後の、選択した任意3か月の売上が分かる年度確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の月別売上の記載がある所得税青色申告決算書の控え(両面)<br />
            (白色申告の方は、対象月の売上が分かる売上台帳や、確定申告の基礎となる書類)<br />
            <br />
            <img src={Image1} className='media_img' alt='' />
            <br />
            所得税青色申告決算書の控え<br />
            <img src={Image2} className='media_img' alt='' /><br />
            <br />
            ・原油価格・物価高騰等の影響を受けている宣誓書<br />
            なお、上記宣誓書に関しましては、下記URLより「足許で原油価格・物価高騰等の経済環境の変化の影響を受けていることの宣誓書」をダウンロードいただき、作成を行ってください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              リンク：足許で原油価格・物価高騰等の経済環境の変化の影響を受けていることの宣誓書のダウンロード
            </a><br />
          </p>
          <h3 className='media_text_text_title'>最低賃金要件</h3><br />
          <p className="media_text_text">
            最低賃金要件値とは、国の最低賃金引き上げを推進するための要件となっており、<strong>従業員の10%以上が最低賃金+30円以内であることが要件</strong>とされております。<br />
            <br />
            本要件を満たす方法として、賃金台帳の提出が必要となります。従業員の定義は以下のwebサイトにて国から公開されておりますので、ご確認ください。<br />
            <a href='https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/koyou_roudou/roudoukijun/minimumichiran' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：最低賃金の確認サイト
            </a><br />
            <br />
            <strong>従業員数の計算方法</strong><br />
            <br />
            従業員の計算方法は、従業員に10%をかけた人数の小数点を切り上げて計算します。<br />
            <span>従業員が25人いた場合 =＞ 24 × 10% = 2.4人のなる場合、小数点を繰上げで3人</span><br />
            <br />
            計算方法で気になる点がございましたら、弊社を含む補助金専門企業へご相談いただければと思います。また、最低賃金は以下のサイトから確認できますので、まずは最低賃金からご確認いただければと思います。<br />
          </p>
          </>

          <>
          <div id='5' className="media_text_title">
            <h2>5.最低賃金枠のポイント</h2>
          </div>
          <p className="media_text_text">
            <br />
            以下に最低賃金枠のポイントをまとめましたので、記事の振り返りも兼ねてご確認いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>補助上限金額が他の申請枠より小さい</h3><br />
          <p className="media_text_text">
            上記の表をご確認していただくと分かるとおり、<strong>補助上限金額が他の申請枠に比べて低く設定されております</strong>。<br />
            <br />
            そのため、1000万円を超えてくるような大きな投資を行う際は、<strong>最低賃金枠以外の申請枠も一緒にご検討いただければ幸いです</strong>。<br />
            <br />
            弊社では他の申請枠に関する記事も同時にアップロードしておりますので、そちらも拝見していただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>最低賃金の計算は繰り上げ</h3><br />
          <p className="media_text_text">
            <strong>最低賃金枠の計算では10%をかけて出てきた数値を繰上げる必要がございます</strong>。<br />
            <br />
            この点は弊社へご依頼いただくお客さまもよくお間違いになっている点ですので、お気をつけいただければと思います。<br />
            <br />
            その他にも<strong>最低賃金の認識の齟齬、従業員数の数値がずれている場合もよく見受けられますので、同時にご確認いただければと思います</strong>。<br />
          </p>
          </>

          <>
          <div id='6' className="media_text_title">
            <h2>６.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、最低賃金枠の解説を行わせていただきました。<br />
            本記事は上述でも書かせていただきました通り、<strong>公募要領を元に執筆させていただいておりますので、改めて公募要領に目を通していただければと思います</strong>。<br />
            <br />
            申請枠だけでなく、事業再構築補助金に関する知見を深まっておりましたら幸いです。<br />
            上記の記事の内容だけでなく、<strong>事業再構築全般のご質問を受け付けておりますので、ご気軽にご連絡いただければと思います</strong>。<br />
            <br />
            <strong>最後まで記事を拝見してくださり、誠にありがとうございました。</strong><br />
            <strong>ご連絡の程、大変お待ちしております。</strong><br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


