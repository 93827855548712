import './styles.css';
import Strong1 from './../../../../image/adbusiness/strong1.png';
import Strong2 from './../../../../image/adbusiness/strong2.png';
import Strong3 from './../../../../image/adbusiness/strong3.png';
import Items from './items/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import firebase from "firebase/compat/app"

const Help = (props) => {

  function btn() {
    firebase.firestore().collection('analytics').doc(props.uid)
    .update({
      button2: true,
    })
  }
  
  return (
    <>
    <div className='AdBusiness_Strong'>
      <h3>当社を選ぶメリット</h3>
      <Items />
      <div className='AdBusiness_Strong_box'>
        <div className='AdBusiness_Strong_text'>
          <div>
            <h6 style={{color : '#F8D166'}}>01</h6>
            <h5>高い採択率</h5>
          </div>
          <p>弊社では入念なヒアリングとリサーチを行うことで、論理的な説明を行いながらも分かりやすい計画書作成のサポートを行うことで高い採択率を有しております。<br />また、お客様の事業理念や新規事業に対する思いを計画書内に記載することで、お客様ならではの事業計画書作成をサポートしております。</p>
        </div>
        <div className='AdBusiness_Strong_img'><img src={Strong1} alt='' /></div>
      </div>
      <div className='AdBusiness_Strong_box'>
        <div className='AdBusiness_Strong_text AdBusiness_Strong_text_media_small'>
          <div>
            <h6 style={{color : '#81CCE8'}}>02</h6>
            <h5>最短の申請フロー</h5>
          </div>
          <p>弊社はお客様に対して専属コンサルタントをつけることにより、お客様の負担を最小限に抑えながら、補助金申請を可能にする体制構築を行っております。<br />また、専属コンサルタントをつけることで申請完了までの時間を平均の1ヶ月から2週間にまで短縮しているため、締切間近のご依頼にも対応が可能です。</p>
        </div>
        <div className='AdBusiness_Strong_img'><img src={Strong2} alt='' /></div>
        <div className='AdBusiness_Strong_text AdBusiness_Strong_text_media_big'>
          <div>
            <h6 style={{color : '#81CCE8'}}>02</h6>
            <h5>最短の申請フロー</h5>
          </div>
          <p>弊社はお客様に対して専属コンサルタントをつけることにより、お客様の負担を最小限に抑えながら、補助金申請を可能にする体制構築を行っております。<br />また、専属コンサルタントをつけることで申請完了までの時間を平均の1ヶ月から2週間にまで短縮しているため、締切間近のご依頼にも対応が可能です。</p>
        </div>
      </div>
      <div className='AdBusiness_Strong_box'>
        <div className='AdBusiness_Strong_text'>
          <div>
            <h6 style={{color : '#89C6C1'}}>03</h6>
            <h5>お客さまに合わせたサポート</h5>
          </div>
            <p>弊社ではお客さまのご負担にならないよう、お客さまに合わせたサポート体制をご用意しております。<br />チャットツール（slack、チャットワーク、メールなど）、電話等の対応も可能となっておりますので、不安な点がございましたら、お申し付けいただけますと幸いです。</p>
        </div>
        <div className='AdBusiness_Strong_img'><img src={Strong3} alt='' /></div>
      </div>
      <div className='AdBusiness_Strong_contact'>
        <h4>サービスについて詳しく知りたい方はお問い合わせください。</h4>
        <AnchorLink href="#1" offset="170"><div onClick={() => btn()}>お問い合わせ</div></AnchorLink>
      </div>
    </div>
    </>
  );
}

export default Help;
