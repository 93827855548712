import './../styles.css';
import Image from "./../../../../image/icon.png";
import ImageTopic1 from "./../../../../image/media/manufacturing/1/top.png";
import ImageTopic2 from "./../../../../image/media/manufacturing/2/top.png";
import ImageTopic3 from "./../../../../image/media/manufacturing/3/top.png";
import ImageTopic4 from "./../../../../image/media/manufacturing/4/top.png";
import ImageTopic5 from "./../../../../image/media/manufacturing/5/top.png";
import { Link } from 'react-router-dom';

const BlogText0001 = () => {

  return (
    <>
    <div className='media_topic'>
      <div className='media_topic_title'>執筆者の紹介</div>
      <div className='media_topic_writer'>
        <div>
          <img src={Image} alt='' />
          <h5>トランク合同会社</h5>
        </div>
        <p>
          執筆もとである弊社は、補助金申請の専門家として補助金申請をサポートしております。<br />
          <br />
          ・補助金に関する疑問点<br />
          ・サポートの依頼<br />
          などございましたら、ご連絡いただければと思います。<br />
        </p>
      </div>
      <div className='media_topic_title'>おすすめの記事</div>
      <Link to='/media/subsidy/facility/0001'>
        <div className='media_topic_content'>
          <img src={ImageTopic1} alt='' />
          <h5>【初めての補助金】<br />ものづくり補助金とは？を徹底解説</h5>
        </div>
      </Link>
      <Link to='/media/subsidy/facility/0002'>
        <div className='media_topic_content'>
          <img src={ImageTopic2} alt='' />
          <h5>【初めての補助金】<br />ものづくり補助金の対象者条件を徹底解説</h5>
        </div>
      </Link>
      <Link to='/media/subsidy/facility/0003'>
        <div className='media_topic_content'>
          <img src={ImageTopic3} alt='' />
          <h5>【初めての補助金】<br />ものづくり補助金の対象経費を徹底解説</h5>
        </div>
      </Link>
      <Link to='/media/subsidy/facility/0004'>
        <div className='media_topic_content'>
          <img src={ImageTopic4} alt='' />
          <h5>【初めての補助金】<br />ものづくり補助金の採択率を徹底解説</h5>
        </div>
      </Link>
      <Link to='/media/subsidy/facility/0005'>
        <div className='media_topic_content'>
          <img src={ImageTopic5} alt='' />
          <h5>【初めての補助金】<br />ものづくり補助金の必要書類を徹底解説</h5>
        </div>
      </Link>
    </div>
    </>
  );
}

export default BlogText0001;
