import firebase from "firebase/compat/app";
import './styles.css';

const Footer = () => {

  // ハッシュタグ
  async function updateHashTags()  {
    var tagsCount = [];
    var lastTags = [];

    function f1() {
      return new Promise((resolve, reject) => {
        var all = [];
        firebase.firestore().collection('posts').get()
        .then(snapShot => {
          snapShot.forEach((doc) => {
            if (doc.data().post_tags[0] !== '') { all.push(doc.data().post_tags[0]); }
            if (doc.data().post_tags[1] !== '') { all.push(doc.data().post_tags[1]); }
            if (doc.data().post_tags[2] !== '') { all.push(doc.data().post_tags[2]); }
            if (doc.data().post_tags[3] !== '') { all.push(doc.data().post_tags[3]); }
            if (doc.data().post_tags[4] !== '') { all.push(doc.data().post_tags[4]); }
          });
          resolve(all);
        })
      });
    }

    function f2(value){
      return new Promise((resolve, reject) => {
        
        var items = [...new Set(value)]

        for (var index = 0; index < items.length; index++) {
          var count = 0;
          for (var i = 0; i < value.length; i++) {
            if (items[index] == value[i]) {
              count = count + 1;
            }
          }
          tagsCount.push({'count': count, 'tags': items[index]});
          lastTags =  tagsCount.sort((x, y) =>  y.count - x.count);
        }
        console.log(lastTags);

        resolve(lastTags);
      })
    }

    // for (var index = 0; index < items.length; index++) {
    //   var count = 0;
    //   for (var i = 0; i < all.length; i++) {
    //     if (items[index] == all[i]) {
    //       count = count + 1;
    //     }
    //   }
    //   await tagsCount.push({'count': count, 'tags': items[index]});
    //   lastTags = await tagsCount.sort((x, y) =>  y.count - x.count);
    // }
    // console.log('lastTags' + lastTags);
    // console.log('all' + all);

    // await firebase.firestore().collection('info').doc('jd93SYMviASVPpzhUtez').update({'0_serch' : [ 'a', 'a', 'a' ],})

    return f1().then(f2).then((response) => {
      console.log("全部終わったよ！");

      firebase.firestore().collection('info').doc('jd93SYMviASVPpzhUtez').update({'0_serch' : response}).then(() => console.log('donedone'));

      // console.log(response);
      // resolve();
    })
  }
  
  // 投稿数
  function postCount() {

    var duplication_list = [];
    var duplication_sort_list = {};

    const sinceAtDate = new Date('2023/01/01 00:00:00');

    firebase.firestore().collection('posts').get()
    .then(snapShot => {
      snapShot.forEach((doc) => {
        if (sinceAtDate < doc.data().post_time.toDate()) {
          duplication_list.push(doc.data().post_uid);
        }
      });
      // console.log(duplication_list);

      for (var i = 0; i < duplication_list.length; i++) {
        var v = duplication_list[i];
        duplication_sort_list[v] = (duplication_sort_list[v] || 0) + 1;
      }

      var array = Object.keys(duplication_sort_list).map((k) => ({ id: k, count: duplication_sort_list[k] }));
      array.sort((a, b) => b.count - a.count);
      var getList = array.splice(0, 20);

      console.log(getList);

      for (var i = 0; i < getList.length; i++) {
        firebase.firestore().collection("users").doc(getList[i]['id']).get()
        .then((doc) => {
          firebase.firestore().collection("info").doc('Ranking')
          .update({
            '0_post_count': firebase.firestore.FieldValue.arrayUnion({id: doc.id, name: doc.data().user_name, image: doc.data().user_image_500, count: duplication_sort_list[doc.id]}),
          })
        });
      }
    })
  }

  // いいね数_作品
  function postLikes() {
    console.log('start')
    firebase.firestore().collection('posts').get()
    .then(snapShot => {
      var tmp = [];
      snapShot.forEach((doc) => {
        if (doc.data().post_liker != undefined) {
          firebase.firestore().collection("posts").doc(doc.id)
          .update({
            post_count: doc.data().post_liker.length,
          });
          tmp.push({id: doc.id, name: doc.data().post_name, image: doc.data().post_image_500, count: doc.data().post_liker.length});
        }
      });
      tmp.sort((a, b) => b.count - a.count);
      var getList = tmp.splice(0, 20);
  
      firebase.firestore().collection("info").doc('Ranking')
      .update({
        '0_post_like': getList,
      })
    });
  }

  // いいね数_アカウント_準備
  function accountBefore() {
    console.log('start')
    firebase.firestore().collection('users').get()
    .then(snapShot => {
      snapShot.forEach((doc) => {
        firebase.firestore().collection("posts").where("post_uid", "==", doc.id).get()
        .then(snapShot => {
          var count = 0;
          if (doc.data().post_liker !== undefined) {
            snapShot.forEach((doc) => {
              count = count + doc.data().post_liker.length;
            });
            console.log(`${count} + ${doc.id}`)
            firebase.firestore().collection("users").doc(doc.id)
            .update({
              user_like_count: count,
            }).then(() => console.log(doc.id));
          }
        });
      });
    });
  }

  // いいね数_アカウント
  function accountLikes() {
    firebase.firestore().collection("info").doc('Ranking')
    .update({
      account_like: [],
    })

    firebase.firestore().collection('users').orderBy("user_like_count", "desc").limit(20).get()
    .then(snapShot => {
      snapShot.forEach((doc) => {
        firebase.firestore().collection("info").doc('Ranking')
        .update({
          account_like: firebase.firestore.FieldValue.arrayUnion({id: doc.id, name: doc.data().user_name, image: doc.data().user_image_500, count: doc.data().user_like_count}),
        })
      });
    });
  }

  // 繋がり多いカウント_準備
  function connection_beafore() {
    firebase.firestore().collection('users').get()
    .then(snapShot => {
      snapShot.forEach((doc) => {
        firebase.firestore().collection("users").doc(doc.id)
        .update({
          user_connection_count: doc.data().user_connection.length,
        })
      });
    });
  }

  // 繋がり多いカウント
  function connectionCount() {
    firebase.firestore().collection("info").doc('Ranking')
    .update({
      connection_count: [],
    })

    firebase.firestore().collection('users').orderBy("user_connection_count", "desc").limit(20).get()
    .then(snapShot => {
      snapShot.forEach((doc) => {
        console.log(doc.id);
        firebase.firestore().collection("info").doc('Ranking')
        .update({
          connection_count: firebase.firestore.FieldValue.arrayUnion({id: doc.id, name: doc.data().user_name, image: doc.data().user_image_500, count: doc.data().user_like_count}),
        })
      });
    });
  }

  // memorii score_準備
  function memoriiScoreBefore() {
    firebase.firestore().collection('users').get()
    .then(snapShot => {
      snapShot.forEach((doc) => {
  
        firebase.firestore().collection("users").doc(doc.id)
        .update({
          memorii_score: 
          (doc.data().user_post_count === 0 ? 1 : doc.data().user_post_count) * 
          (doc.data().user_connection_count === 0 ? 1 : doc.data().user_connection_count) * 
          (doc.data().user_login.length === 0 ? 1 : doc.data().user_login.length) * 
          (doc.data().user_post_like_count === 0 ? 1 : doc.data().user_post_like_count),
        })
      });
    })
  }

  // memorii score
  function memoriiScore() {

    firebase.firestore().collection("info").doc('Ranking')
    .update({
      memorii_score: [],
    })

    firebase.firestore().collection('users').orderBy("memorii_score", "desc").limit(20).get()
    .then(snapShot => {
      snapShot.forEach((doc) => {
        firebase.firestore().collection("info").doc('Ranking')
        .update({
          memorii_score: firebase.firestore.FieldValue.arrayUnion({id: doc.id, name: doc.data().user_name, image: doc.data().user_image_500, count: doc.data().memorii_score}),
        })
      });
    });
  }
  
  return (
    <>
    <div className="memorii_photo_update">
      {/* ハッシュタグ */}
      <button onClick={() => updateHashTags()}>ハッシュタグ</button>
      {/* 投稿数の準備 */}
      {/* <button onClick={() => postUser()}>投稿数の準備</button> */}
      <button onClick={() => postCount()}>投稿数ランキング</button>
      {/* いいね数_作品 */}
      <button onClick={() => postLikes()}>いいね数_作品</button>
      {/* いいね数_アカウントの準備 */}
      <button onClick={() => accountBefore()}>いいね数_アカウントの準備</button>
      <button onClick={() => accountLikes()}>いいね数_アカウント</button>
      {/* 繋がりの準備 */}
      <button onClick={() => connection_beafore()}>繋がりの準備</button>
      <button onClick={() => connectionCount()}>繋がり</button>
      {/* memorii scoreの準備 */}
      <button onClick={() => memoriiScoreBefore()}>繋がり</button>
      <button onClick={() => memoriiScore()}>memorii score</button>
    </div>
    </>
  );
}

export default Footer;
