const BlogText0001 = (props) => {

  return(
    <div className='management_review_content_1'>
      <h4>自社の状況に関する質問（1問）</h4>
      <div>
        <h5>Q1.建設機械の保有状況</h5>
        <p>建設機械抵当法施行令別表に規定するショベル系掘削機、ブルドーザー、トラクターショベル及びモーターグレーダー、土砂等を運搬する貨物自動車であって自動車検査証のシャチあの形状欄に「ダンプ」、「ダンプトレーラ」又は「ダンプセミトレーラ」と記載されているもの並びに労働安全衛生法施行令第１２条第１項第４号に掲げるつり上げ荷重が３トン以上の移動式クレーン、同令第１３条第３項第３４号に掲げる作業床の高さが２メートル以上の高所作業車、同令別表第７第４号に掲げる締め固めよう機械及び同表第６号に掲げる解体用機械をいうものである。</p>
        <input onChange={(e) => props.setConstructionMachinery(e.target.value)} placeholder="5" type="number" />
      </div>
    </div>
  );
}

export default BlogText0001;
