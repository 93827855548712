// 製造業
// https://docs.google.com/document/d/1N7Rg42QdlxWH74gfQ8cK8PZk8rx1JBLb0iO-eGOiwpI/edit

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "../../nav/index";
import Topic from "../topic";
import TopImage from "./../../../../../image/media/business/41/top.png";
import Image2 from "./../../../../../image/media/business/41/2.png";
import Image3 from "./../../../../../image/media/business/41/3.png";
import Image4 from "./../../../../../image/media/business/41/4.png";
import Image5 from "./../../../../../image/media/business/41/5.png";
import Footer from '../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」製造業の採択事例を大公開！',
    description: '本記事では、事業再構築補助金の製造業 採択事例を紹介しております。製造業を営んでいて事業再構築補助金の申請を検討されている方は、本記事をご参考に検討を行っていただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」製造業の採択事例を大公開！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            新型コロナウイルスの影響をはじめ、材料価格の高騰など、ここ数年のあいだに製造業者を取り巻く環境は大きく変化しました。<br />
            <strong>「なんとかコロナ以前の経営状況に戻したい」</strong><br />
            <strong>「減少した売上を取り戻すために新たな事業にチャレンジしたい」</strong><br />
            このように、苦境に立たされた事業者の中にはV字回復を目指して事業再構築補助金を検討している方も多いのではないでしょうか。<br />
            <br />
            本記事では、事業再構築補助金を検討中の製造業者向けに以下の疑問について解説します。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業再構築補助金の対象となる製造業者</span></li>
              <li><span>製造業における事業再構築補助金の活用方法</span></li>
              <li><span>過去の製造事業者採択例</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            <strong>製造業における活用方法</strong><br />
            事業再構築補助金の活用方法は業種によって様々です。<br />
            ここでは、製造業における活用方法について以下の3つのポイントに分けて解説します。<br />
          </p>
          <div className='media_introduction'>
            <ul>
              <li><span>事業再構築補助金の対象となる経費</span></li>
              <li><span>製造業での事業再構築類型の具体例</span></li>
              <li><span>事業再構築補助金での申請例</span></li>
            </ul>
          </div>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">事業再構築補助金の対象となる経費</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金の対象となる経費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">飲食事業での事業再構築類型の具体例</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金での申請例</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">製造業での事業再構築類型の具体例</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">o	飲食事業者の申請傾向</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">o	具体的な採択事例</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">o	飲食事業の申請で抑えるべきポイント</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">事業再構築補助金での申請例</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.事業再構築補助金の対象となる経費</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築補助金を活用できる経費の種類は以下の11項目となります。<br />
            ①<strong>建物費</strong><br />
            ②<strong>機械装置・システム構築費</strong><br />
            ③<strong>技術導入費</strong><br />
            ④<strong>専門家経費</strong><br />
            ⑤<strong>運搬費</strong><br />
            ⑥<strong>クラウドサービス利用費</strong><br />
            ⑦<strong>外注費</strong><br />
            ⑧<strong>知的財産権等関連経費</strong><br />
            ⑨<strong>広告宣伝・販売促進費</strong><br />
            ⑩<strong>研修費</strong><br />
            ⑪<strong>廃業費</strong><br />
            <br />
            これらの項目をひとつずつ深掘りすると膨大な数になるため、ここでは製造業に関係の深い経費について紹介します。<br />
            <br />
            <strong>【製造業に関係の深い経費】</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>経費項目</th><th>詳細</th><th>具体例</th>
              </tr>
              <tr>
                <td>建物費</td>
                <td>新事業を開始するための事務所や工場、倉庫の建設や改修にかかる経費</td>
                <td>・新事業のための新しい工場の建設<br />・既存工場の改修</td>
              </tr>
              <tr>
                <td>機械装置・システム構築費</td>
                <td>新事業に必要な産業機器や工作機器の導入費。<br />システムの開発経費など。</td>
                <td>・新事業のために新しい製造機器を購入する。<br />・新事業のために業務簡略化システムを導入する。</td>
              </tr>
              <tr>
                <td>外注費</td>
                <td>事業に必要な製品の加工や設計など、外部に委託する費用</td>
                <td>・機械加工費<br />・特殊加工費<br />・設計費</td>
              </tr>
              <tr>
                <td>研修費</td>
                <td>新事業に必要な教育訓練や講座受講にかかる経費</td>
                <td>従業員の教育費</td>
              </tr>
              <tr>
                <td>広告宣伝・販売促進費</td>
                <td>製品やサービスを宣伝する広告費用</td>
                <td>インターネット広告を利用して新製品の広告を行う</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            なかでも建物費と機械装置・システム構築費は、事業再構築補助金の製造業におけるメインの経費と言ってもいいでしょう。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.製造業での事業再構築類型の具体例</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築補助金を申請するには公募要領に定められている要件を満たす必要があり、以下3つの類型のいずれか1つに当てはまっていなければなりません。
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th>類型を満たすための要件</th><th>産業分類に基づく変更</th>
              </tr>
              <tr>
                <td>新市場進出</td>
                <td>
                  ①製品又は提供方法を大きく変更する<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全体の10%以上となる計画を策定する。
                </td>
                <td>無</td>
              </tr>
              <tr>
                <td>事業転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。
                </td>
                <td>主な事業を変更(中・小・細分類)</td>
              </tr>
              <tr>
                <td>業種転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。
                </td>
                <td>主な業種を変更<br />(大分類)</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            これら要件はすべての業種に該当します。<br />
            それぞれの要件を具体例を用いて製造業に当てはめてみましょう。<br />
            <br />
            以下の具体例をご覧ください。<br />
            <br />
            <strong>【新市場進出の具体例】</strong><br />
            新市場進出は、産業分類の変更をせずに新商品を開発したり、新たな販売方法を構築したりする場合に該当します。<br />
            たとえば、「自社工場で生産しているチョコレート菓子を販売するECサイトを構築し、販路を拡大する」といったケースです。<br />
            日本産業分類は「0979：その他のパン・菓子製造業」のままですが、新たな販売方法を構築することで要件を満たしています。<br />
            <br />
            <strong>【事業転換の具体例】</strong><br />
            事業転換は、製造業という業界自体は変えずに別ジャンルに進出するケースのことです。<br />
            具体的には、「自動車部品を製造している事業者が医療用機器の製造を開始」などが該当します。<br />
            日本産業分類で見てみると「31：輸送用機械器具製造業→27：業務用機械器具製造業」へと変更されているのがわかります。<br />
            このように、同じ製造業界のまま別のビジネスにチャレンジするケースが事業転換に該当します。<br />
            <br />
            <strong>【業種転換の具体例】</strong><br />
            製造業界から別業種に進出するのが業種転換です。<br />
            例えば、「伝統工芸品工房をリノベーションし、陶芸教室を開く」といったケース。<br />
            日本産業分類では「E:製造業→O：教育・学習支援業」へと大胆な方向転換が見られます。<br />
            このように製造業とは全く別ジャンルの業種へ進出するのが業種転換となります。
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>３.事業再構築補助金での申請例</h2>
          </div>
          <p className="media_text_text">
            <br />
            続いては、事業再構築補助金の採択例から対象経費や補助金額について解説します。<br />
            従業員数や事業規模によって補助金額は変わりますので、下記例はあくまでも参考としていただき、申請の際はご自身で計画を立てたうえで対象経費・補助金額を確認してから申請に臨んでください。<br />
            <br />
            <strong>【事業内容】</strong><br />
            大手企業の下請けとしてプラント製品を製造してきたが、新型コロナウイルスや材料費の価格高騰の影響から売上が減少。元請け依存脱却を目指して、廃材を再利用したアイアン家具を製造開始する。<br />
            一般顧客へ販売するためのECサイトを構築し、新たな売上の柱を確立する。<br />
            <br />
            事業場所：長野県長野市<br />
            従業員数：40名<br />
            売上高：11億円<br />
            <br />
            <strong>【日本産業分類から類型を決定】</strong><br />
            既存事業と新事業の日本産業分類を比較すると以下のとおり。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>日本産業分類</th><th>大分類</th><th>中分類</th><th>小分類</th><th>細分類</th>
              </tr>
              <tr>
                <td>既存事業</td><td>E:製造業</td><td>25：はん用機械器具製造業</td><td>251：ボイラ・言動機器製造業</td><td>2511：ボイラ製造業</td>
              </tr>
              <tr>
                <td>新規事業</td><td>I：卸売業・小売業</td><td>60：その他の小売業</td><td>601：家具・建具・畳小売業</td><td>6011：家具小売業</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            既存事業の「製造業」から「卸売業・小売業」へと大分類が変更しているため、本事業の事業再構築類型は「業種転換」として申請を進めます。<br />
            <br />
            <strong>【売上高から申請枠を決定】</strong><br />
            下表は2021年と2022年の売上を比較したものとなります。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'></th><th>6月</th><th>7月</th><th>8月</th><th>合計</th>
              </tr>
              <tr>
                <td>2021年</td><td>115,000</td><td>125,000</td><td>120,000</td><td>360,000</td>
              </tr>
              <tr>
                <td>2022年</td><td>91,500</td><td>92,500</td><td>91,500</td><td>275,500</td>
              </tr>
              <tr>
                <td>増減率(%)</td><td>-26%</td><td>-24%</td><td>-24%</td><td>-24%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            2022年の売上合計が2021年の合計に比べて24％も減少していることから、本事業は「物価高騰対策・回復再生応援枠」にて申請します。<br />
            <br />
            <strong>【補助金額の計算】</strong><br />
            ここまでの情報を元に補助金額を計算していきます。<br />
            なお、本事業にかかる経費は以下のとおりです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>在庫保管用の倉庫建築費</th><th>￥7,000,000</th>
              </tr>
              <tr>
                <td>新事業のための工場改修費</td><td>￥1,000,000</td>
              </tr>
              <tr>
                <td>ECサイト構築費</td><td>￥3,500,000</td>
              </tr>
              <tr>
                <td>インターネット広告費</td><td>￥2,500,000</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            これらの経費はすべて補助対象に含まれます。<br />
            そのため、本事業の申請金額は合計24,000,000円となります。<br />
            <br />
            なお、第10回公募要領によると中小企業の補助率は2/3となります。<br />
            加えて、従業員が21〜50人の場合、800万円までは補助率が3/4になるため、これらの条件に当てはめて計算すると補助金額は以下の通りです。<br />
            <br />
            <span>（従業員数の補助率）8,000,000×3/4＝①6,000,000</span><br />
            <span>（中小企業の補助率）15,000,000×2/3＝②10,000,000</span><br />
            <span>①+②＝16,000,000</span><br />
            <br />
            本事業の最大補助金額は16,000,000円になります。<br />
          </p>
          <h3 className='media_text_text_title'>虎の巻から見る製造業者の特徴</h3>
          <p className="media_text_text">
            「虎の巻」とは、経済産業省が公表している事業再構築補助金に関する過去のデータをまとめた資料のことで、<br />
            <strong>・事業の決め方</strong><br />
            <strong>・事業計画の立て方</strong><br />
            <strong>・各事業における採択率と申請率の傾向</strong><br />
            <strong>・業種別の事例紹介</strong><br />
            <br />
            など、事業再構築へのチャレンジを検討している方にとって有益な情報が網羅されています。<br />
            <br />
            申請において非常に役立つ資料ですので、事業再構築補助金を検討中の方はぜひご一読ください。<br />
            ・「事業再構築虎の巻」(https://jigyou-saikouchiku.go.jp/pdf/jigyokeikaku_guidebook.pdf)<br />
            <br />
            本記事でも、虎の巻をもとに<br />
            <strong>１.製造業者の申請傾向</strong><br />
            <strong>２.具体的な採択事例</strong><br />
            <strong>３.製造業の申請で抑えるべきポイント</strong><br />
            について詳しく解説します。<br />
            <br />
            1.製造業者の申請傾向<br />
            まずは、製造業者の申請傾向について。<br />
            製造業と一言でいってもその範囲は広く、事業再構築補助金においては以下の4つにカテゴリーされています。<br />
            <br />
            <strong>１.製造業（機械）</strong><br />
            <strong>２.製造業（金属）</strong><br />
            <strong>３.製造業（化学・繊維）</strong><br />
            <strong>４.製造業（その他）</strong><br />
            <br />
            それぞれの申請傾向について解説します。<br />
            <br />
            <strong>1.製造業（機械）</strong><br />
            <img src={Image2} className='media_img' alt='' />
            <br />
            有望度が高い事業の中でも、EV関連事業は申請件数だけでなく採択率の高さも目立ちます。<br />
            これは、世界全体で自動車のEV市場が伸びていることが影響していると言えるでしょう。<br />
            <br />
            他には、リサイクル関連事業も採択率が高い傾向にあります。<br />
            既存事業の廃棄物を活用した新事業への進出は、SDGsに貢献する取り組みとして高い評価を受けやすく、事業再構築補助金においても採用されやすいと考えられるでしょう。<br />
            <br />
            ただし、有望度の高い分野は、ライバルが多く競争が激しいため、採択されたものの思うような結果が出ないおそれもあります。人気の分野に進出する際は、その事業が自社の強みを活かせる分野なのかをしっかり検討することが重要です。<br />
            <br />
            <strong>2.製造業（金属）</strong><br />
            <img src={Image3} className='media_img' alt='' />
            この分野では、医療事業への進出が最も申請件数の多いテーマとなっています。<br />
            具体例として、これまで精密部品加工を行ってきた事業者が医療装置事業へ進出するなどの事例が挙げられます。<br />
            新型コロナによって医療分野は非常に注目を集めており、今後も需要が期待できる分野と言えるでしょう。<br />
            <br />
            また、EV関連や半導体関連も有望度の高いテーマとなっています。<br />
            先述した通り、EV市場は今後さらに成長が見込まれる分野のひとつです。<br />
            加えて、EV関連に付随して需要が拡大する半導体分野も成長が期待されていることから、この2つのテーマは事業再構築補助金において狙い目であると言えるでしょう。<br />
            <br />
            <strong>3.製造業（化学・繊維）</strong><br />
            <img src={Image4} className='media_img' alt='' />
            化学・繊維の分野では、リサイクルをテーマとした新事業への進出が注目されています。<br />
            たとえば、有望度の高い「プラスチック・樹脂」、「SDGsリサイクル」、「アパレル関連事業」、「容器の開発・製造」など。<br />
            <br />
            いずれも再生原料の製造や、リサイクル素材の活用、低酸素素材を活用した製品の製造など、SDGsの実現に向けた取り組みに進出する事業が数多く採択されています。<br />
            <br />
            <strong>4.製造業（その他）</strong><br />
            <img src={Image5} className='media_img' alt='' />
            この分野では、既存の技術を活かした新事業への進出が目立ちます。<br />
            <br />
            たとえば、<br />
            ・伝統工芸品工房をリノベーションし、陶芸体験や滞在空間を提供（体験消費サービス）<br />
            ・既存の製造方法を現代風にアレンジした和菓子の製造（伝統工芸技術）<br />
            ・紙加工技術を活かしたEC需要に対応するレターパックの製造（段ボール製造・販売）<br />
            などの採択事例が挙げられます。<br />
            <br />
            既存事業との相乗効果の高いサービスをしっかり計画することが、採択されるためのポイントになるでしょう。<br />
          </p>
          <h3 className='media_text_text_title'>具体的な採択事例</h3>
          <p className="media_text_text">
            続いては、製造業の採択事例を紹介します。<br />
            ご自身の事業を計画する際の参考にしてみてください。<br />
            <br />
            <strong>①元請業者への依存脱却を目指し、建築用金物から車両部品および小物金物製造へ新分野展開</strong><br />
            <br />
            建築用金物を製造していた事業者がコロナ禍で売上が減少。<br />
            これまで元請け業者に依存していた体質を見直し、自社で培ってきた技術とノウハウを活かしたステンレス製車両部品の製造、取付け、仕上げまでの車両修理におけるサービスを展開した採択事例です。<br />
            <br />
            製造業全体がコロナ禍や材料費の高騰による売り上げ減少の影響を受けています。<br />
            そのため、元請けだけの仕事に頼った経営では先の見通しは暗いでしょう。<br />
            既存事業のノウハウを活かし、新たな売上の軸を構築することが今後、製造業で生き残っていくためには重要となります。<br />
            <br />
            事業再構築においても、既存事業のノウハウを活かした新事業への取り組みは事業実施の確実性が高いとして採用されやすい傾向にあるので、申請でのアピールポイントになるでしょう。<br />
            <br />
            <strong>②樹脂加工のノウハウを活かし、医療機器分野へ新分野展開</strong><br />
            <br />
            新型コロナに加えて原油高騰の影響を受けたことから、売上高が減少。<br />
            新たな事業の柱を構築すべく、樹脂加工のノウハウを活かせる医療機器分野へ新分野展開をした事例です。<br />
            <br />
            医療機器分野は事業再構築補助金において採択率の高いテーマのひとつです。<br />
            <br />
            安定した売上や今後の成長が見込まれるため、狙い目の分野であると言えます。<br />
            <br />
            ただし、医療機器は人命にもかかわる分野であり、法規制が厳しく、安全の確保も徹底しなければならないなど、製造業の中でもとくに注意を払って取り組まなければなりません。<br />
            <br />
            魅力的な分野ではありますが、事業計画を立てる際は、慎重に検討を重ねる必要があるでしょう。<br />
          </p>
          <h3 className='media_text_text_title'>製造業の申請で抑えるべきポイント</h3>
          <p className="media_text_text">
            最後に、申請で押さえるべきポイントを2つ解説します。<br />
            <br />
            <strong>①差別化を意識する</strong><br />
            <br />
            事業再構築補助金において他社との差別化は申請における重要なポイントのひとつと言えます。<br />
            事業再構築補助金は補助額が他の補助金に比べて高額なこともあり、採択率が低い傾向にあります。<br />
            <br />
            そのため、採択率が高い事業を狙っても、他社と同じような事業計画では差別化ができていないとして不採択になることも。<br />
            とくに製造業の場合、「EV関連事業」「半導体関連事業」「医療分野」など有望度の高いテーマになると、たくさんの応募者の中に埋もれてしまう可能性があります。<br />
            仮に採択されたとしても、差別化ができていない事業は採択後に苦戦が強いられるでしょう。<br />
            <br />
            以上を踏まえると、申請にあたっては、他社と比較して強みといえる要素をしっかり盛り込み、差別化を図ることが重要となります。<br />
            <br />
            <strong>②自社の強みを活かせる事業を選ぶ</strong><br />
            <br />
            事業再構築補助金で採択されるためには、自社でこれまで築いてきた販路や技術、ノウハウなどの強みを活かすことができる事業にチャレンジすることが重要です。<br />
            <br />
            採択率が高いからといって流行りの事業に手を広げても、何のノウハウもなく販路や技術を一から築き上げるとなると、仮に採択されたとしても事業の継続が難しくなるおそれがあります。<br />
            <br />
            自社の強みや弱みをしっかり分析し、緻密な計画を立て、自社にとって有望な事業を見定めることが申請において重要なポイントとなるでしょう。<br />
          </p>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>４.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、製造業における事業再構築補助金の活用方法や申請時例について説明させていただきました。<br />
            <br />
            これから事業再構築補助金を活用しようと検討されている事業者様は本記事や、記事内で紹介した「虎の巻」などを参考に入念な事業計画を立てて、新たな事業にチャレンジしてみてください。<br />
            <br />
            とはいえ、事業再構築補助金は申請要件や計画書の作成が非常に複雑なため、自力で申請を進めていくのは膨大な時間と労力がかかります。<br />
            <br />
            本記事を運営している会社であるトランク合同会社は、製造業者様の事業再構築補助金採択例を豊富に有しており、各事業者様の特徴を前面に押し出した申請サポートを行っています。現在は無料相談も承っておりますので、事業再構築補助金申請について興味のある事業者様は、是非下記フォームよりお気軽にご連絡くださいませ。<br />
            <br />
            この度は、記事を拝見いただき誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


