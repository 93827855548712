// 市場拡大要件を満たすことの説明書

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/35/top.png";
import Image1 from "./../../../../../image/media/business/35/1.png";
import Image2 from "./../../../../../image/media/business/35/2.png";
import Image3 from "./../../../../../image/media/business/35/3.png";
import Image4 from "./../../../../../image/media/business/35/4.png";
import Image5 from "./../../../../../image/media/business/35/5.png";
import Image6 from "./../../../../../image/media/business/35/6.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」市場拡大要件を満たすことの説明書の書き方を解説！',
    description: '本記事では、事業再構築補助金の必須書類である「市場拡大要件を満たすことの説明書」の作成方法を解説しております。さらに、初めての方では分からない最短での用意する方法も解説しておりますので、ぜひご参考にしていただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">事業再構築補助金 市場拡大要件を満たすことの説明書の書き方を解説！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。<strong>補助金コンサルタントのトランク合同会社</strong>です。<br />
            今回は、新しい申請枠である成長枠の要件に必要な「<strong>市場拡大要件を満たすことの説明書</strong>」の書き方について解説させていただければと思います。<br />
            <br />
            成長枠への申請を検討されている事業者様は、ぜひ最後までご覧いただければと思います。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>本記事でこんな悩みが解決！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>市場拡大要件を満たすことの説明書の書き方</span></li>
              <li><span>市場拡大要件を満たすことの説明書のダウンロード方法</span></li>
              <li><span>市場拡大要件を満たすことの説明書が必要な申請枠</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、<strong>経済産業省から展開されている「事業再構築指針の手引き」を参考にして作成したもの</strong>となっています。<br />
            <a href='https://www.meti.go.jp/covid-19/jigyo_saikoutiku/pdf/shishin_tebiki.pdf' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：「事業再構築指針の手引き」に関する記事
            </a><br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">市場拡大要件を満たすこと説明書の概要</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">必要な申請枠</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">市場拡大要件を満たすこと説明書の書き方</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">ダウンロード方法</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">記載項目の確認</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">補助事業として取り組む事業の業種・業態の記載方法</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">補助事業として取り組む事業の概要の記載方法</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">補助事業として取り組む事業の市場規模が10%以上拡大することの説明の記載方法</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.市場拡大要件を満たすこと説明書の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            この項目では、<strong>市場拡大要件を満たすこと説明書が必要な申請枠</strong>、及び<strong>本書類のダウンロード方法</strong>について解説させていただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>必要な申請枠</h3><br />
          <p className="media_text_text">
            <strong>市場拡大要件を満たすこと説明書の提出が必要な申請枠は成長枠</strong>のみとなります。<br />
            <strong>※成長枠以外で申請する事業者様は本書類を用意する必要はございません</strong>。<br />
            <br />
            成長枠について詳しく知りたいという方は、以下の記事にて詳しく解説しておりますので、ぜひご覧いただければと思います。<br />
            <a href='https://trankllc.com/media/subsidy/business/0025' target="_blank" rel="nofollow noopener noreferrer">
              詳細記事：成長枠の補助率、補助上限、必要経費等を詳しく解説している記事
            </a><br />
          </p>
          <h3 className='media_text_text_title'>成長枠の概要</h3><br />
          <p className="media_text_text">
            成長枠は、<strong>成長分野への大胆な事業再構築に取り組む中小企業等を支援する枠</strong>です。<br />
            <br />
            そのため、<strong>新規事業を行う分野の業種・業態が、過去10年~今後10年で市場が拡大していることが要件</strong>とされております。<br />
            <br />
            <span>市場拡大要件は以下のいずれかを満たす必要がある</span><br />
            <strong>①成長枠の対象となる業種・業態の一覧に含まれていること</strong><br />
            <strong>②市場が拡大していることを証明する書類を作成すること</strong><br />
            <br />
            <span>①成長枠の対象となる業種・業態の一覧に含まれていること</span><br />
            以下が「成長枠の対象となる業種・業態の一覧」のPDFになります。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/seichowaku_list.pdf' target="_blank" rel="nofollow noopener noreferrer">
              詳細書類：成長枠の対象となる業種・業態の一覧のURL
            </a><br />
            <br />
            <span>②市場が拡大していることを証明する書類を作成すること</span><br />
            成長枠の対象となる業種・業態の一覧以外で、市場が拡大している業種・業態を自分自身で見つけて証明するというものとなっており、この場合、自分自身でデータを見つけて証明する必要があります。<br />
            <br />
            ①②のどちらに含まれるかで、書類の記載方法が変わってきますので、まず、上記の2点のどちらに含まれるかを確認していただければと思います。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.ダウンロード方法</h2>
          </div>
          <p className="media_text_text">
            「市場拡大要件を満たすこと説明書」は、以下の事業再構築補助金の電子申請書類のサイトより、wordをダウンロードしていただければと思います。<br />
            <br />
            ダウンロードができるサイトのURLを以下に貼り付けましたので、以下のサイトよりご取得いただければと思います。<br />
            <a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：市場拡大要件を満たすこと説明書のURL
            </a><br />
            <br />
            <span>ダウンロード部分のイメージ画像</span><br />
            <img src={Image1} className='media_img' alt='' /><br />
            <br />
            <span>市場拡大要件を満たすこと説明書のwordのイメージ</span><br />
            取得したwordは以下のようなものとなっておりますので、念のため、wordの中身に間違いがないかをご確認いただければと思います。<br />
            <br />
            <img src={Image2} className='media_img' alt='' /><br />
            <br />
            では、用意が整ったところで、ご説明に入らせていただきます。<br />
          </p>
          </>
          
          <>
          <div id='2' className="media_text_title">
            <h2>2.市場拡大要件を満たすこと説明書の書き方</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、市場拡大要件を満たすこと説明書の書き方を説明していきます。<br />
            また、以下の順を追ってご説明させていただければと思います。<br />
            <br />
            <span>ご説明の流れ</span><br />
            <strong>② 補助事業として取り組む事業の業種・業態の記載方法</strong><br />
            <strong>③ 補助事業として取り組む事業の概要の記載方法</strong><br />
            <strong>④ 補助事業として取り組む事業の市場規模が10</strong><br />
          </p>
          <h3 className='media_text_text_title'>具体的な事業者様の例</h3><br />
          <p className="media_text_text">
            今回、市場拡大要件を記載させていただくに当たって、具体的な事業者像を固める必要がございますので、以下のような設定の場合、どのように記載するかをご説明させていただきます。<br />
            <br />
            <span>具体的な事業者例</span><br />
            既存事業：中華料理屋を経営<br />
            新規事業：中華のフードデリバリー<br />
            補足：現在、中華料理屋を経営しているが、フードデリバリー事業の市場が拡大していること。また、現在のノウハウが転用できる点を鑑みて、本事業を行うことを決意した。<br />
            <br />
            上記の設定を元に、以下の具体的な記載に入らせていただきます。<br />
          </p>
          <h3 className='media_text_text_title'>補助事業として取り組む事業の業種・業態の記載方法</h3><br />
          <p className="media_text_text">
            補助事業として取り組む事業の業種・業態の記載方法では、以下の部分に記載する内容を解説いたします。<br />
            <br />
            <img src={Image3} className='media_img' alt='' /><br />
            <br />
            補助事業として取り組む事業の業種・業態の記載方法は、以下の2つの記載部分に分かれております。<br />
            <br />
            <span>①を記載する方</span><br />
            事業再構築補助金を使用して転換する事業が、<br /><strong>「成長枠の対象となる業種・業態の一覧」に含まれている場合に記載。</strong><br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/seichowaku_list.pdf' target="_blank" rel="nofollow noopener noreferrer">
              成長枠の対象となる業種・業態の一覧のURL
            </a><br />
            <br />
            成長枠の対象となる業種・業態の一覧のURLに含まれている方は、PDFに記載されている分類名と産業分類（小分類）をご記載ください。<br />
            具体例...分類コード：91、産業分類（小分類）：畜産食料品製造業<br />
            <br />
            <span>②を記載する方</span><br />
            事業再構築補助金を使用して転換する事業が、<br /><strong>「成長枠の対象となる業種・業態の一覧」に含まれていない場合に記載。</strong><br />
            <br />
            <span>具体的な記載内容</span><br />
            今回の場合、飲食のデリバリーを行うため、産業分類の中で以下に属します。<br />
            <strong>宿泊業，飲食サービス業 ＞ 持ち帰り・配達飲食サービス業 ＞ 配達飲食サービス業</strong><br />
            <br />
            また、配達飲食サービス業は分類コードが7721であるため、以下のように記載します。<br />
            具体例...分類コード：7721、産業分類（小分類）：配達飲食サービス業<br />
            <br />
            産業分類の見つけ方は以下のサイトのどこに属するかで決まりますので、ご参照いただければと思います。<br />
            <a href='https://www.e-stat.go.jp/classifications/terms/10?search_method=keyword&search_word=&komokuSearchFlg_dummy=1&komokuSearchFlg=1&info1SearchFlg_dummy=1&info1SearchFlg=1&info2SearchFlg_dummy=1&info2SearchFlg=1&revision=03&search_kind=10&base_code=0&form_id=main_form&op=search&searchboxShow1=1&searchboxShow2=0&searchboxShow3=0&page=&srchcndId=' target="_blank" rel="nofollow noopener noreferrer">
              e-Stat 日本標準産業分類サイト
            </a><br />
          </p>
          <h3 className='media_text_text_title'>補助事業として取り組む事業の概要の記載方法</h3><br />
          <p className="media_text_text">
            補助事業として取り組む事業の概要の記載方法では、以下の部分に記載する内容を解説いたします。<br />
            <br />
            <img src={Image4} className='media_img' alt='' /><br />
            <br />
            補助事業として取り組む事業の概要の記載方法は、既存事業では何をしてて、新規事業は何をするかという説明を行えば問題ございません。<br />
            <br />
            また、本項目は成長枠の対象となる業種・業態の一覧に属している業種・業態への転換をしているかに関わらず記載が必須となっております。<br />
            <br />
            <span>具体的な記載内容</span><br />
            本事業では、当社の飲食経験のノウハウとフードデリバリー市場の増加の機会を捉えた、中華料理のフードデリバリー事業を展開する。さらに、フードデリバリー市場へ参入するため、予約受付サイトの構築、プラットフォームでの広告宣伝を行っていき、事業の拡大、売上の増加を見込む。<br />
          </p>
          <h3 className='media_text_text_title'>補助事業として取り組む事業の市場規模が10%以上拡大することの説明の記載方法</h3><br />
          <p className="media_text_text">
            補助事業として取り組む事業の市場規模が10%以上拡大することの説明の記載方法では、以下の部分に記載する内容を解説いたします。<br />
            <br />
            <img src={Image5} className='media_img' alt='' /><br />
            <br />
            補助事業として取り組む事業の市場規模が10%以上拡大することの説明では、選択した業種・業態の市場拡大が10%以上であることを説明する部分となっております。<br />
            <br />
            そのため、本項目は「成長枠の対象となる業種・業態の一覧」に属している業種・業態への転換をしない方（自分自身で市場拡大を証明する必要がある方）は記載が必須となっております。<br />
            <br />
            <span>具体的な記載内容</span><br />
            本事業では飲食店の経営から飲食のフードデリバリー市場への転換を行うため、株式会社矢野経済研究所の食品宅配市場規模の調査の一部を抜粋する。これによると、食品宅配市場規模は以下のような推移を辿って増加しており、10%以上の増加を見込むことができる。<br />
            <br />
            これにより2016年20,009億円の数値と2025年：29,321億円の数値が約1.7倍に増えているということができ、また、年率の増加が一定であることから要件を満たすことができると言える。<br />
            <br />
            2016年：20,009億円<br />
            2017年：20,456億円<br />
            2018年：21.029億円<br />
            2019年：21,848億円<br />
            2020年：24,969億円<br />
            2021年：25,170億円<br />
            2022年：26,565億円<br />
            2023年：27,587億円<br />
            2024年：28,518億円<br />
            2025年：29,321億円<br />
            <br />
            今回、参照したデータ、サイトを以下に添付いたします。<br />
            <a href='https://www.yano.co.jp/press-release/show/press_id/2800' target="_blank" rel="nofollow noopener noreferrer">
              株式会社矢野経済研究所の食品宅配市場規模の調査のURL
            </a><br />
            <br />
            <img src={Image6} className='media_img' alt='' /><br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、産業構造転換枠で必要となる「市場拡大要件を満たすことの説明書」の書き方をご紹介させていただきました。<br />
            <br />
            どのように記載するべきかがわからないという方が多くいると思いますが、既存事業で何をしていて、市場が拡大する事業へ転換することを証明していれば問題ございません。<br />
            <br />
            もし、記載方法で不安な点等ございましたら、弊社へお申し付けいただければと思います。<br />
            この度は最後まで記事を読んでいただき、誠にありがとうございました。
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;

