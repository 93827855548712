import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/1/top.png";
import Images1 from "./../../../../../image/media/business/1/1.png";
import Images2 from "./../../../../../image/media/business/1/2.png";
import Images3 from "./../../../../../image/media/business/1/3.png";
import Footer from './../../../../tabs/footer/index.js';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '事業再構築補助金とは？ 申請に必要な基礎知識を徹底解説',
    description: 'この記事は、事業再構築補助金を初めて申請する方へ向けて、補助金の目的から全体のスケジュール、採択事例まで幅広く解説しています。新規事業やることは決まっているが、公募要領が難しくて申請準備が進んでいない方、これから申請を検討される方は、ぜひご覧いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <div className='media'>
        <div className='media_article'>
          
          <>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">事業再構築補助金とは？ 申請に必要な基礎知識を徹底解説</h1>
          <img src={TopImage} className="media_title_img" alt='事業再構築補助金' />
          </>

          <>
          <p className="media_text_text">
            <br />
            <strong>はじめに</strong><br />
            本記事は、<strong>補助金申請の専門家であるトランク合同会社</strong>が執筆しています。<br />
            初めて事業再構築補助金の申請を行う方へ向けて、申請サポートを行うための記事となっていますので、ぜひ最後までご一読いただければと思います。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業再構築補助金の概要</span></li>
              <li><span>申請にはどのような要件があるのか？</span></li>
              <li><span>実際の採択事例</span></li>
            </ul>
          </div>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">事業再構築補助金の概要</AnchorLink></li>
                <li><AnchorLink href="#2" offset="100">全体スケジュール</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">全体の対象者</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">対象になる補助金経費</AnchorLink></li>
                <li><AnchorLink href="#5" offset="100">提出が必要な書類</AnchorLink></li>
                <li><AnchorLink href="#6" offset="100">提出が必要な書類</AnchorLink></li>
                <li><AnchorLink href="#7" offset="100">事業再構築補助金の採択率</AnchorLink></li>
                <li><AnchorLink href="#8" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.事業再構築補助金の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築補助金の各情報（対象者、類型、補助率など）を説明させていただく前に、<strong>事業再構築補助金の目的</strong>を説明します。<br />
            <br />
            目的を知ると、「事業再構築が自分に当てはまっているのか？」「どのような事業が採択されるのか？」の理解をスムーズに行うことが出来ますので、おすすめです！<br />
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金の目的</h3><br />
          <p className="media_text_text">
            事業再構築補助金の目的は、「<strong>新型コロナウイルスの影響により、売上が減少した中小企業等の思い切った取り組みを支援すること</strong>」です。そのため、計画書の内容もポストコロナへの対応や売上の増加が見込まれることを説明することが重要な要素となっています。<br />
            <br />
            事業再構築補助金に申請する際は、以下の公募要領を元に申請を行いますので、本記事で概要を理解したのちに、公募要領を読んでみると申請への理解が深まるのではないかと思います。<br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              事業再構築補助金 公募要領|中小企業庁
            </a><br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>事業再構築補助金の目的</span><br />
            新型コロナウイルス感染症の影響が長期化し、当面の需要や売上の回復が期待し難い中、ウィズコロナ・ポストコロナの時代の経済社会の変化に対応するために新分野展開、業態転換、事業・業種転換、事業再編又はこれらの取組を通じた規模の拡大等、思い切った事業再構築に意欲を有する中小企業等の挑戦を支援することで、日本経済の構造転換を促すことを目的とします。
          </div>
          <h3 className='media_text_text_title'>事業再構築補助金の特徴</h3><br />
          <p className="media_text_text">
            事業再構築補助金以外にも、さまざまな補助金が国、地域から出ていますが、事業再構築補助金ならではの特徴は以下の点になります。<br />
            <br />
            <strong>1点目：思い切った事業・製造方法の変換を行う必要がある</strong><br />
            事業再構築の採択事例を以下に記載しておりますが、事業再構築補助金は、名の通り事業を再構築するための補助金となっています。<br />
            <br />
            具体的には、飲食店からキャンピングカーや不動産からグランピング、運送屋からラーメン屋などガラッと事業を転換するものがメインとなる補助金です。<br />
            <br />
            <strong>２点目：補助上限が1.5億円と、金額の大きい補助金であること</strong><br />
            上記にも記載した通り、補助金は国、地域など様々な媒体が募集していますが、基本的には300万円程度が相場となっています。しかし、この事業再構築補助金では、上限が1.5億円という大きな金額が補填されるケースもあるなど、他の補助金と比べて圧倒的に規模が大きいです。<br />
            <br />
            <strong>３点目：さまざまな経費を計上できること</strong><br />
            一般的な補助金では、webサイト作成、展示会の費用だけなど、限られた項目でのみ経費の計上が出来るものが一般的ですが、この補助金では、建物費、研修費、広告宣伝・販売促進費、.外注費、クラウドサービス、専門家経費など様々な項目を計上することが出来ます。<br />
            <br />
            そのため、新しく事業を行う方で補助金の申請を行う方は、金額も大きく、経費が計上しやすい事業再構築補助金での申請を考えてみては、いかがでしょうか。<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0003' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」12個の経費分類を具体例つきで解説！
            </a><br />
            リンク：<a href='https://seido-navi.mirasapo-plus.go.jp/?_ga=2.87744283.2093072206.1690101256-778833913.1687151297' target="_blank" rel="nofollow noopener noreferrer">
              国が公開している補助金検索サイト
            </a><br />
          </p>
          </>
          
          <>
          <div id='2' className="media_text_title">
            <h2>2.全体スケジュール</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、全体の流れをご説明させていただくのですが、とても長く複雑なため、以下の２つに分けて流れを説明させていただきます。<br />
            <br />
            (1) 公募要領の発表~補助金の振込完了<br />
            (2) 公募要領の発表~申請<br />
            <br />
            以下は、(1) と(2)の図解です。<br />
            <br />
            <img src={Images1} className='media_img' alt='' />
          </p>
          <br />
          <h3 className='media_text_text_title'>公募要領の発表~補助金の振込完了</h3><br />
          <p className="media_text_text">
            公募要領の発表〜補助金の振込完了は、以下のような流れとなっておりますので、補助金を活用される方は予め確認いただければと思います。<br />
            <br />
            <strong>① 公募：公募発表から申請までの準備期間</strong><br />
            ↓<br />
            <strong>② 申請/電子申請：用意した資料を電子上で申請</strong><br />
            ↓<br />
            <strong>③ 採択通知：補助金の採択・不採択を確認</strong><br />
            ※採択された場合は、以下のフローへ、不採択の場合は再申請が可能<br />
            ↓<br />
            <strong>④ 交付申請：申請した経費の見積もり書類等をまとめ事務局に提出</strong><br />
            ↓<br />
            <strong>⑤ 交付決定：申請した経費項目が事務局で承認</strong><br />
            ↓<br />
            <strong>⑥ 補助事業実施期間：申請した事業計画を実施する期間</strong><br />
            ↓<br />
            <strong>⑦ 実績報告：補助事業で行った内容をまとめ、事務局へ報告</strong><br />
            ↓<br />
            <strong>⑧ 確定検査/交付額の確定：事務局の担当者による、現地での補助事業実績の確認</strong><br />
            ↓<br />
            <strong>⑨ 補助金の請求：補助金事務局に対して、補助金の請求</strong><br />
            ↓<br />
            <strong>⑩ 補助金の振込：補助金の請求を事務局から振込</strong><br />
            <br />
            以上となりますが、いかがでしょうか。<br />
            補助金は採択されて終わりではなく、申請後もやらなければいけないことがありますので、補助金を申請される方は、申請してからトラブルにならないよう、この点を抑えていただければと思います。<br />
            <br />
            <img src={Images2} className='media_img' alt='' />
          </p>
          <br />
          <h3 className='media_text_text_title'>具体的な申請の流れ</h3><br />
          <p className="media_text_text">
            公募要領の発表〜申請は、以下のような流れとなっております。こちらの流れは順番を前後させても問題はありませんが、スムーズな申請を行うための理想的な例として記載しております。<br />
            <br />
            <strong>①目的の確認：事業再構築補助金の目的を確認</strong><br />
            ↓<br />
            <strong>②対象者の確認：事業再構築補助金の申請可否を確認</strong><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0002' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」5分で対象者の条件・要件を理解する！
            </a><br />
            ↓<br />
            <strong>④類型と補助率の確認：事業再構築の申請枠を選択し、補助率を確認</strong><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0017' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」大規模賃金引上枠は、５つの要件を確認！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0019' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」最低賃金枠は、４つの要件を確認！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0025' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」市場規模が拡大してれば成長枠！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0026' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」市場縮小で悩んでいる企業様は産業構造転換枠！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0028' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」物価高騰対策・回復再生応援枠
            </a><br />
            ↓<br />
            <strong>⑤対象経費の確認：事業にかかる経費の金額・内容を検討</strong><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0003' target="_blank" rel="nofollow noopener noreferrer">
             「事業再構築補助金」12個の経費分類を具体例つきで解説！
            </a><br />
            ↓<br />
            <strong>⑥必要資料の確認/作成：申請に必要な書類を確認</strong><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0005' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」提出書類の用意を３日で終わらせる方法！
            </a><br />
            ↓<br />
            <strong>⑦資料の作成・準備：申請書類の作成</strong><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0021' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」事業計画書の作成は、この４点を抑える！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0022' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」10分で取れる加点書類が2つある！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0034' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」ミラサポを15分で作成する方法！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0035' target="_blank" rel="nofollow noopener noreferrer">
              事業再構築補助金 市場拡大要件を満たすことの説明書の書き方を解説！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0036' target="_blank" rel="nofollow noopener noreferrer">
              事業再構築補助金 市場縮小要件を満たすことの説明書の書き方を解説！
            </a><br />
            ↓<br />
            <strong>⑧申請：電子申請システムを用いて情報入力・作成資料の添付、申請</strong><br />
            <br />
            以上となりますが、いかがでしょうか。<br />
            全体スケジュールと同じように、長いなという印象をもたれたのではないかと思いますが、全体スケジュールと違い1つ1つを確認するとすごいやることが多いというわけではありませんので、1つずつ着実に進めていきましょう。<br />
            <br />
            <img src={Images3} className='media_img' alt='' />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>4.全体の対象者</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、事業再構築補助金の対象者についてご説明させていただくのですが、とても長く複雑なため、<strong>事業再構築補助金申請に当たって全体の対象者要件</strong>に絞ってご説明させていただきます。<br />
            <br />
            詳細な情報を知りたい方は、要件について詳しい記事を別途ご用意しておりますので、以下のリンクからご確認いただければと思います。<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0002' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」5分で対象者の条件・要件を理解する！
            </a><br />
            <br />
            その他に、申請枠ごとの要検討もございますので、もし、専門家へ相談したいという方は、ご気軽にご連絡いただければと思います。<br />
            リンク：<a href='http://trankllc.com/contact' target="_blank" rel="nofollow noopener noreferrer">
              弊社への問い合わせ
            </a><br />
          </p>
          <h3 className='media_text_text_title'>公募開始日時点で要件を満たしている</h3><br />
          <p className="media_text_text">
            これは前提条件になりますが、事業再構築補助金を提出する際の要件は、「<strong>公募開始日時点で要件を満たさなければいけない</strong>」というものです。<br />
            <br />
            そのため、申請を希望されている事業者さまは、公募開始日時点まで遡（さかのぼ）って要件をご確認ください。<br />
            <br />
            反対に、<strong>補助金の上限を上げるために人を申請までに雇うという形でも上限を引き上げることができます</strong>ので、その点も検討いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>日本に本社がある中小企業等である</h3><br />
          <p className="media_text_text">
            事業再構築補助金の対象は、あくまでも<strong>日本に本社（所在地）がある企業となっています</strong>ので、外資系の事業者さまや本社が他国にある場合は、念の為、ご確認いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>中小企業等であること</h3><br />
          <p className="media_text_text">
            本要件は、<strong>中小企業等に含まれていなければいけない</strong>ということです。<br />
            こちらは中小企業を判断するために以下の表が公開されておりますので、申請を希望されている事業者さまの情報と見比べてご確認いただければと思います。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>業種・組織形態</th><th className='media_table_0001_1_2'>資本金</th><th className='media_table_0001_1_3'>従業員（常勤）</th>
              </tr>
              <tr>
                <td><strong>製造業、建設業、運輸業</strong></td>
                <td>3億円</td>
                <td>300人</td>
              </tr>
              <tr>
                <td><strong>卸売業</strong></td>
                <td>1億円</td>
                <td>100人</td>
              </tr>
              <tr>
                <td><strong>サービス業</strong></td>
                <td>5,000万円</td>
                <td>100人</td>
              </tr>
              <tr>
                <td><strong>小売業</strong></td>
                <td>5,000万円</td>
                <td>50人</td>
              </tr>
              <tr>
                <td><strong>ゴム製品製造業</strong></td>
                <td>3億円</td>
                <td>900人</td>
              </tr>
              <tr>
                <td><strong>ソフトウエア業、または情報処理サービス業</strong></td>
                <td>3億円</td>
                <td>300人</td>
              </tr>
              <tr>
                <td><strong>旅館業</strong></td>
                <td>5,000万円</td>
                <td>200人</td>
              </tr>
              <tr>
                <td><strong>その他の業種</strong></td>
                <td>3億円</td>
                <td>300人</td>
              </tr>
            </tbody>
          </table>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>4.対象になる補助金経費</h2>
          </div>
          <p className="media_text_text">
            <br />
            対象経費は、主に以下の10項目が補助経費となっております。<br />
            <br />
            ご覧いただくと分かる通り、想像以上に幅広く経費が計上できる形となっておりますので、申請を検討される方はご確認いただければと思います。<br />
            <br />
            以下にて、対象経費に関する詳細を解説させていただきました。<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0003' target="_blank" rel="nofollow noopener noreferrer">
              【初めての補助金】事業再構築補助金の対象経費を徹底解説
            </a>
          </p>
          <h3 className='media_text_text_title'>建物費</h3><br />
          <p className="media_text_text">
            <strong>補助事業のみに使用する建物に関しての経費となります</strong>。<br />
            <br />
            なお、ここでいう建物の定義は「減価償却資産の耐用年数等に関する省令」中の「建物」と「建物付属設備」となっており、構築物は対象外となりますのでご注意ください。<br />
          </p>
          <h3 className='media_text_text_title'>機械装置費・システム構築費（リース料を含む）</h3><br />
          <p className="media_text_text">
            <strong>補助事業に使用される設備・ソフトウェアに係る経費となります</strong>。<br />
            <br />
            なお、設備の定義は「減価償却資産の耐用年数等に関する省令」中の「機械及び装置」、「器具及び備品」、「工具」となっており、船舶や航空機、車両及び運搬具は対象外となります。<br />
          </p>
          <h3 className='media_text_text_title'>技術導入費（知的財産権導入に要する経費）</h3><br />
          <p className="media_text_text">
            <strong>補助事業を行うために他者から知的財産権等を導入する必要がある際、その導入に係る経費となります</strong>。<br />
          </p>
          <h3 className='media_text_text_title'>専門家経費</h3><br />
          <p className="media_text_text">
            <strong>補助事業を行うために専門家に技術指導やアドバイスを依頼する必要がある場合、依頼費や旅費に係る経費となります</strong>。<br />
            <br />
            なお、本補助事業に関る専門家の依頼費用は以下のように定められております。<br />
            <br />
            ・大学教授、弁護士、弁理士、公認会計士、医師等：1日5万円以下<br />
            ・准教授、技術士、中小企業診断士、ITコーディネータ等：1日4万円以下<br />
          </p>
          <h3 className='media_text_text_title'>運搬費</h3><br />
          <p className="media_text_text">
            <strong>補助事業を遂行するために必要な、材料や設備等の運搬に係る経費となります</strong>。<br />
          </p>
          <h3 className='media_text_text_title'>クラウドサービス利用費</h3><br />
          <p className="media_text_text">
            <strong>補助事業に利用するクラウドサービス・webプラットフォーム等に係る経費となります</strong>。<br />
          </p>
          <h3 className='media_text_text_title'>外注費</h3><br />
          <p className="media_text_text">
            <strong>補助事業遂行のために、外部に一部業務を委託する経費となります</strong>。<br />
          </p>
          <h3 className='media_text_text_title'>知的財産権等関連経費</h3><br />
          <p className="media_text_text">
            <strong>補助事業で新製品・サービスを開始する際に必要となる、特許権をはじめとした知的財産権を取得するために係る経費となります</strong>。<br />
          </p>
          <h3 className='media_text_text_title'>広告宣伝・販売促進費</h3><br />
          <p className="media_text_text">
            <strong>補助事業で新製品・サービスを販売する際に行う、広告を含めたプロモーション活動に係る経費となります</strong>。<br />
          </p>
          <h3 className='media_text_text_title'>研修費</h3><br />
          <p className="media_text_text">
            <strong>補助事業を遂行するために必須となる、社員に向けた教育訓練や講座受講等に係る経費となります</strong>。<br />
          </p>
          </>

          <>
          <div id='5' className="media_text_title">
            <h2>5.提出が必要な書類</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築補助金には、<strong>大きく分けて5つの申請枠に分かれており、以下の中で1つの申請枠を選択して、申請を行う必要があります</strong>。各申請枠の概要をまとめましたので、それぞれの傾向を掴んでいただければと思います。<br />
            <br />
            各申請枠は、補助金額の上限、補助率などが異なりますので、要件として適合するか。その他に、投資金額とも比べた上で申請枠をご選択いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>物価高騰対策・回復再生応援枠</h3><br />
          <p className="media_text_text">
            物価高騰対策・回復再生応援枠は、<strong>昨今の物価高騰等により、売上減少等の影響を強く受けている事業者を支援する枠</strong>となっており、具体的な要件としては、2022年以降の売上が2019～2021年と比較して10%以上減少していることなどがあります。<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0028' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」物価高騰対策・回復再生応援枠
            </a>
          </p>
          <h3 className='media_text_text_title'>成長枠</h3><br />
          <p className="media_text_text">
            成長枠は、<strong>成長している業種への転換を行うことで、売上増加を目指す事業者を支援する枠</strong>です。具体的な要件としては、経済産業省から公開されている下記のリストに新規事業が含まれること、もしくは、過去10年〜今後10年の間で10%以上、新規事業の市場規模が拡大していることがあります。<br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/pdf/seichowaku_list.pdf' target="_blank" rel="nofollow noopener noreferrer">
              成長枠の対象となる業種・業態の一覧
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0025' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」市場規模が拡大してれば成長枠！
            </a>
          </p>
          <h3 className='media_text_text_title'>産業構造転換枠</h3><br />
          <p className="media_text_text">
            産業構造転換枠は、<strong>既存事業の市場が大きく縮小しており、そこからの脱却を図る事業者を支援する枠</strong>となっており、成長枠の反対という認識で問題ないです。具体的な要件としては、経済産業省から公開されている下記のリストに既存事業が含まれること、もしくは、過去10年〜今後10年の間で10%以上、新規事業の市場規模が縮小していることがあげられます。<br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/pdf/tenkanwaku_list.pdf' target="_blank" rel="nofollow noopener noreferrer">
              産業構造転換枠の対象となる業種・業態の一覧
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0026' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」市場縮小で悩んでいる企業様は産業構造転換枠！
            </a>
          </p>
          <h3 className='media_text_text_title'>成長枠</h3><br />
          <p className="media_text_text">
            グリーン枠は、<strong>温暖化への対応等、経済環境へ寄与する事業の転換により成長を図る事業者を支援する枠</strong>となっており、カーボンニュートラルなどの事業がこの枠に該当します。具体的な要件としては、グリーン成長戦略に資する研究・技術開発/人材育成を行うこととされています。以下にPDFを添付いたしましたので、ご確認いただければと思います。<br />
            リンク：<a href='https://www.meti.go.jp/policy/energy_environment/global_warming/ggs/pdf/green_koho_r2.pdf' target="_blank" rel="nofollow noopener noreferrer">
              グリーン成長戦略（概要）
            </a>
          </p>
          </>

          <>
          <div id='6' className="media_text_title">
            <h2>6.提出が必要な書類</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築補助金は、上述に記載した通り、採択・不採択が発生するものですので、各業種でどのようなものが申請されているのかをご紹介します。<br />
            <br />
            この補助金の目的でもある、「<strong>売上が減少した中小企業等の思い切った取り組みを支援する補助金</strong>」という点に注目して、確認していただければと思います。<br />
            <br />
            <strong>飲食事業の採択事例</strong><br />
            ・地元に根付いた韓国料理店から料理の素を自社で開発し販売する事業へ転換<br />
            ・駅の近くの若い層へ向けた地元料理店から地元料理を提供する宿泊業<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0039' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」飲食事業の採択事例を大公開！
            </a><br />
            <br />
            <strong>宿泊事業の採択事例を大公開！</strong><br />
            ・宿泊事業から温泉旅館によるワーケーションとサウナ事業の転換<br />
            ・宿泊業からコロナ禍でも需要の高いランドリー事業の転換<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0040' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」宿泊事業の採択事例を大公開！
            </a><br />
            <br />
            <strong>製造業の採択事例を大公開！</strong><br />
            ・元請業者への依存脱却を目指し、建築用金物から車両部品および小物金物製造へ新分野展開<br />
            ・樹脂加工のノウハウを活かし、医療機器分野へ新分野展開<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0041' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」製造業の採択事例を大公開！
            </a><br />
            <br />
            他にも下記の業種ごとに採択事例、傾向を解説していますので、申請を行う方は、ぜひ自社が属する業種の事例を参考にした事業展開を検討いただければと思います。<br />
            <br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0039' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」飲食事業の採択事例を大公開！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0040' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」宿泊事業の採択事例を大公開！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0041' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」製造業の採択事例を大公開！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0042' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」卸売業の採択事例を大公開！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0043' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」小売事業の採択事例を大公開！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0044' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」生活関連事業の採択事例を大公開！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0045' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」建設業の採択事例を大公開！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0046' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」情報通信業の採択事例を大公開！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0047' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」不動産業の採択事例を大公開！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0049' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」教育、学習支援業の採択事例を大公開！
            </a><br />
          </p>
          </>
          
          <>
          <div id='7' className="media_text_title">
            <h2>7.事業再構築補助金の採択率</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築補助金は、第10回公募より大幅に変化しており、各申請枠ごとの採択率がまだ公開されていない状況ですので、第7回までの全体の採択率を以下にてまとめました。<br />
            <br />
            全体的な傾向ですが、<strong>採択率は第1回から中盤にかけて落ち込んでいるものの近年は増加傾向にあること</strong>がわかります。また、2023年の経済産業省の予算は500億円を超えており、今後も中小企業の再構築へ投資を続けることが分かりますので、申請を検討されているか迷っている方も、ぜひ挑戦してみましょう。<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0004' target="_blank" rel="nofollow noopener noreferrer">
              【採択率はどれくらい？】事業再構築補助金の過去採択を徹底解剖！
            </a>
          </p>
          <h3 className='media_text_text_title'>通常枠</h3>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>項目</th><th>申請件数</th><th>採択件数</th><th>採択率</th>
              </tr>
              <tr>
                <td><strong>第１次</strong></td>
                <td>16,968</td>
                <td>5,104</td>
                <td>30.1%</td>
              </tr>
              <tr>
                <td><strong>第２次</strong></td>
                <td>14,859</td>
                <td>5,388</td>
                <td>36.3%</td>
              </tr>
              <tr>
                <td><strong>第３次</strong></td>
                <td>15,423</td>
                <td>5,713</td>
                <td>37.0%</td>
              </tr>
              <tr>
                <td><strong>第４次</strong></td>
                <td>15,036</td>
                <td>5,700</td>
                <td>37.9%</td>
              </tr>
              <tr>
                <td><strong>第５次</strong></td>
                <td>16,185</td>
                <td>6,441</td>
                <td>39.8%</td>
              </tr>
              <tr>
                <td><strong>第６次</strong></td>
                <td>11,653</td>
                <td>5,297</td>
                <td>45.5%</td>
              </tr>
            </tbody>
          </table>
          </>
          
          <>
          <div id='8' className="media_text_title">
            <h2>8.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            いかがでしたでしょうか。本記事では、事業再構築補助金の申請を行いたい事業者様へ向けて、事業再構築補助金の概要について執筆させていただきました。<br />
            記事を読む前より補助金に詳しくなっていただけましたら、大変うれしく思います。<br />
            <br />
            なお、本記事はあくまで概要であり、詳細は文中にてご案内させていただいている別途記事にて解説がありますのでご確認ください。<br />
            <br />
            申請のご依頼やご不明点等ございましたら、ぜひ弊社へお問い合わせいただければと思います。<br />
            この度は、最後まで記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>
        
        <Topic />
      </div>

      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
