import './styles.css';
import Nav from './../nav/index';
import Header from './header/index';
import Simulator from './simulator/index';
import Footer from '../footer/index';
import Caution from './caution/index';
import DocumentMeta from 'react-document-meta';

const simulatorTop = (props) => {

  const meta = {
    title: '事業再構築補助金の申請可否を３分で診断！',
    description: '初めて補助金を申請する方へ向けて、簡単に事業再構築補助金に申請出来るかを診断していただけるサイトです。専門家へ相談することも出来るので、お困りの方はぜひサイトを活用していただければと思います。',
    canonical: 'https://trankllc.com/help',
    meta: {charset: 'utf-8',}
  };

  return(
  <>
  <DocumentMeta {...meta}>
    <Nav />
    <Header />
    <Simulator />
    <Caution />
    <Footer />
  </DocumentMeta>
  </>
  );
}

export default simulatorTop;
