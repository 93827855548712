import './styles.css';
import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app"
import { Link } from 'react-router-dom';

const PhotoHeader = () => {

  const [ posts, setPosts ] = useState([]);

  useEffect(() => {
    firebase.firestore().collection("posts").orderBy("post_count", "desc").limit(10)
    .get().then(snapShot => {
      const tmp = [];
      snapShot.forEach(doc => tmp.push({...doc.data(), id: doc.id}));
      setPosts([...tmp]);
    })
  }, [])

  return (
    <>
    {/* fixed */}
    <Link to='recruit'>
      <div className='photo_fixed_company'>企業の方はこちら</div>
    </Link>
    <Link to='photoList'>
      <div className='photo_fixed_photoList'>作品撮り一覧</div>
    </Link>
    <a href='https://apps.apple.com/jp/app/memorii-photo/id1578278781' rel="noreferrer">
      <div className='photo_fixed_download'>ダウンロード</div>
    </a>

    {/* header */}
    <div className='photo_header'>
      <h5>美容学生のための作品撮りアプリ</h5>
      <h4>memorii photo</h4>
    </div>
    <Link to='/photoList'>
      <div className='photo_list_title'>作品撮り</div>
    </Link>
    <div className="photo_list_box">
      {posts.map((photo, index) =>
        <Link to='/photoList'>
          <div className="photo_list_box_items" key={index}>
            <img src={photo.post_image_500} alt="美容学生作品撮り" />
          </div>
        </Link>
      )}
    </div>
    <div className='Photo_student_name'></div>
    </>
  );
}

export default PhotoHeader;
