// 成長枠

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "../../nav/index";
import Topic from "../topic";
import TopImage from "./../../../../../image/media/business/25/top.png";
import Image1 from "./../../../../../image/media/business/25/1.png";
import Image2 from "./../../../../../image/media/business/25/2.png";
import Footer from '../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」市場規模が拡大してれば成長枠！',
    description: '本記事では、成長枠の申請要件、補助率、補助金額について解説をしています。何を満たせば申請できるのか？や成長枠の特徴を知りたいという方は向けの記事となっておりますので、補助金を申請したいという方はぜひいご覧いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」市場規模が拡大してれば成長枠！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。<strong>補助金コンサルタントのトランク合同会社</strong>です。<br />
            本記事は、補助金のサポートしている専門家が事業再構築補助金について執筆いたしました。<br />
            <br />
            日本最大級の補助金である事業再構築補助金の「成長枠」について、わかりやすく解説させていただきましたので、申請を考えている方はぜひ最後までご一読ください。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業再構築 成長枠とは？</span></li>
              <li><span>成長枠を申請するにはどんな要件があるか？</span></li>
              <li><span>具体的な事例は何があるのか？</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、事業再構築補助金の「公募要領」を参考にして作成されたものとなっておりますので、<strong>本記事をご理解いただいた上で公募要領を改めて目を通していただければと思います</strong>。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo.pdf' target="_blank" rel="nofollow noopener noreferrer">
              参考書類：事業再構築補助金 公募要領
            </a><br />
            <br />
            補助金についてわからない！とサジを投げかけている方もお電話でのご質問もお受けしておりますので、<strong>ご気軽にお問い合わせいただければと思います</strong>。<br />
            <br />
            それでは、本記事の内容へ入らせていただきます。<br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">成長枠の概要</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">成長枠の概要</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">成長枠の補助金額</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">成長枠の補助率</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">成長枠の補助対象経費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">成長枠の要件</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">各要件の解説</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">事業再構築要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">認定支援機関要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">付加価値額要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">市場拡大要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">給与総額増加要件</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">成長枠で申請する際に必要な書類</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">市場拡大要件を満たすことを説明する書類</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">給与総額増加要件を満たすことを説明する書類</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">補助率引上要件を満たすことを説明する書類（加点）</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">成長枠のポイント</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#3" offset="100">成長が見込まれる業種への転換が必須</AnchorLink></li>
                  <li><AnchorLink href="#3" offset="100">事業拡大を見込んでいる事業者様へおすすめの枠</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#4" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.成長枠の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            成長枠とは、<strong>これから行う新規事業が過去10年、もしくは、今後10年で事業を行った業種・業態の市場が成長している場合に申請できる枠</strong>となっておりますので、市場が拡大している可能性がある方はぜひ申請をご検討ください。<br />
            <br />
            成長枠の概要を公募要領より引用して記載いたしました。<br />
            詳しい説明を下記でしておりますので、合わせてご覧いただければと思います。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>項目</th><th>要件</th>
              </tr>
              <tr>
                <td>概要</td>
                <td>成長分野への大胆な事業再構築に取り組む中小企業等を支援。</td>
              </tr>
              <tr>
                <td>補助金額</td>
                <td>
                  【従業員数 20 人以下】 100 万円 ～ 2,000 万円<br />
                  【従業員数 21~50 人】 100 万円 ～ 4,000 万円<br />
                  【従業員数 51～100 人】 100 万円 ～ 5,000 万円<br />
                  【従業員数 101 人以上】 100 万円 ～ 7,000 万円<br />
                </td>
              </tr>
              <tr>
                <td>補助率</td>
                <td>
                  中小企業者等 1/2（大規模な賃上げ（※）を行う場合は 2/3）<br />
                  中堅企業等 1/3（大規模な賃上げ（※）を行う場合は 1/2）
                </td>
              </tr>
              <tr>
                <td>補助対象経費</td>
                <td>
                  建物費、機械装置・システム構築費（リース料を含む）、技術導入費、専門家経費、運搬費、クラウドサービス利用費、外注費、知的財産権等関連経費、広告宣伝・販売促進費、研修費
                </td>
              </tr>
              <tr>
                <td>要件</td>
                <td>
                  ・事業再構築要件<br />
                  ・認定支援機関要件<br />
                  ・付加価値額要件<br />
                  ・市場拡大要件<br />
                  ・給与総額増加要件
                </td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>成長枠の概要</h3><br />
          <p className="media_text_text">
            <strong>成長分野への大胆な事業再構築に取り組む中小企業等を支援</strong>する枠となっております。<br />
            <br />
            そのため、要件として<strong>市場拡大要件</strong>という要件があり、<strong>過去10年、もしくは、今後10年で市場が拡大している業種・業態に属すること</strong>が要件とされております。<br />
            <br />
            具体的な判断方法等の詳しい解説については、次章の市場拡大要件にて記載いたしましたので、ご確認いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>成長枠の補助金額</h3><br />
          <p className="media_text_text">
            成長枠の補助金額は、成長枠の概要の表で記載した通り、以下のように定義されております。<br />
            <strong>【従業員数 20 人以下】 100 万円 ～ 2,000 万円</strong><br />
            <strong>【従業員数 21~50 人】 100 万円 ～ 4,000 万円</strong><br />
            <strong>【従業員数 51～100 人】 100 万円 ～ 5,000 万円</strong><br />
            <strong>【従業員数 101 人以上】 100 万円 ～ 7,000 万円</strong><br />
            <br />
            ここで、ポイントとなるのは、<strong>従業員数の算出方法</strong>になりますが、以下のような方法で算出いたしますので、お間違いなく算出いただければと思います。<br />
            <a href='https://www.chusho.meti.go.jp/faq/faq/faq01_teigi.htm#q3' target="_blank" rel="nofollow noopener noreferrer">
              参考サイト：従業員数の算出方法
            </a><br />
          </p>
          <h3 className='media_text_text_title'>成長枠の補助率</h3><br />
          <p className="media_text_text">
            補助率とは投資金額の何割を国が補助してくれるかを示したものですが、その割合と別に上限金額の設定もされておりますので、<strong>補助率と上限金額の2点により計算したものが補助額</strong>となります。<br />
            <br />
            上述の表に記載されています通り、以下のようになっております。<br />
            <strong>中小企業者等 3/4</strong><br />
            <strong>中堅企業等 2/3</strong><br />
            <br />
            以下に具体例を記載いたしたので、ご確認ください。<br />
            <br />
            <strong>①	具体例：従業員数5名、投資金額が2000万円の場合、補助金額は1,000万円</strong><br />
            ・成長枠を申請した事業者様が従業員数5名、投資金額2,000万円の場合、中小企業のため、補助率は1/2であるため、1,000万円の1/2で補助金額は1,000万円、自己負担額は1,000万円となります。<br />
            <br />
            <strong>②	具体例：従業員数5名、投資金額6,000万円の場合、補助金額は2,000万円</strong><br />
            ・成長枠を申請した事業者様が従業員数5名、投資金額6,000万円の場合、中小企業のため、補助率は1/2であるため、6,000万円の1/2で補助金額は3,000万円のはずですが、従業員数 20 人以下の場合2,000万円が上限とされているため、補助金額は2,000万円となり、自己負担額は4000万円となります。<br />
            <br />
            <strong>上限金額以上は補助されないという決まり</strong>ですので、上限金額を確認した上で申請を行なっていただく事で円滑に事業の推進が行えると考えております。<br />
          </p>
          <h3 className='media_text_text_title'>成長枠の補助対象経費</h3><br />
          <p className="media_text_text">
            成長枠の補助対象経費は、成長枠の概要で示した通り、以下のものとなっております。<br />
            <strong>建物費、機械装置・システム構築費（リース料を含む）、技術導入費、専門家経費、運搬費、クラウドサービス利用費、外注費、知的財産権等関連経費、広告宣伝・販売促進費、研修費</strong><br />
            <br />
            上記の費用が具体的に何に使えるのか？については、以下の記事にて詳しく記載しておりますので、ご参照いただければと思います。<br />
            <a href='https://trankllc.com/media/subsidy/business/0003' target="_blank" rel="nofollow noopener noreferrer">
              参考：事業再構築補助金 12個の経費分類を具体例つきで解説！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>成長枠の要件</h3><br />
          <p className="media_text_text">
            成長枠の要件は、成長枠の概要で示した通り、以下のものとなっております。<br />
            <strong>・事業再構築要件</strong><br />
            <strong>・認定支援機関要件</strong><br />
            <strong>・付加価値額要件</strong><br />
            <strong>・市場拡大要件</strong><br />
            <strong>・給与総額増加要件</strong><br />
            <br />
            各要件については、次章で1つずつ解説しておりますので、ご確認いただければと思います。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.各要件の解説</h2>
          </div>
          <p className="media_text_text">
            <br />
            成長枠の概要の表でまとめさせていただきました通り、成長枠で申請するためには以下の５点の要件を満たす必要がございます。<br />
            <br />
            本項目では1つずつ解説していきますので、成長枠で申請する方は各要件を1つずつご確認いただければと思います。<br />
            <br />
            <strong>①	事業再構築要件</strong><br />
            <strong>②	認定支援機関要件</strong><br />
            <strong>③	付加価値額要件</strong><br />
            <strong>④	市場拡大要件</strong><br />
            <strong>⑤	給与総額増加要件</strong><br />
          </p>
          <h3 className='media_text_text_title'>事業再構築要件</h3><br />
          <p className="media_text_text">
            事業再構築要件とは、<strong>新分野展開、事業転換、業種転換、業態転換又は事業再編のいずれかに該当する事業計画でなければいけない</strong>という要件です。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・新分野展開、事業転換、業種転換、業態転換又は事業再編のいずれかに該当する事業計画を策定すること<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・事業計画書<br />
            <br />
            <strong>参考記事</strong><br />
            上記５点の（新分野展開、事業転換、業種転換、業態転換又は事業再編）についての詳しい記事を別途作成させていただきましたので、ぜひご参照いただければと思います。<br />
            <a href='https://trankllc.com/media/subsidy/business/0007' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【類型・転換って何？】事業再構築補助金の再構築要件を、例を用いて解説！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>認定支援機関要件</h3><br />
          <p className="media_text_text">
            認定支援機関要件とは、<strong>国に中小企業支援の知識・経験がある者として認められた認定支援機関に事業計画書を確認してもらわなければいけない</strong>という要件です。<br />
            <br />
            認定支援機関とは、<strong>中小企業支援の知識・経験がある者として、国の認定を受けた機関であり、士業や商工会、金融機関が多く認可されています</strong>。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・認定支援機関と相談したうえで事業計画書を策定する。<br />
            ※認定経営革新等支援機関による確認書は、認定支援機関に相談したことを証明する書類ですので、必ず認定支援機関に作成をお願いしてください。<br />
            <br />
            <strong>【要件を満たすために必要な書類】</strong><br />
            ・認定経営革新等支援機関による確認書<br />
            ・金融機関による確認書(補助金額が3,000万円を超える場合)<br />
            <br />
            認定支援機関要件は、下記の記事にて詳しく解説しておりますので、詳細が知りたい方はぜひご参照ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0009' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【どこに依頼する？】事業再構築補助金の認定支援機関要件を機関別に解説！
            </a><br />
            <br />
            おすすめの認定支援機関や認定支援機関別の採択率は、下記の記事でまとめて記載しておりますので、下記リンクよりご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0004' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【採択率はどれくらい？】事業再構築補助金の過去採択を徹底解剖！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>付加価値額要件</h3><br />
          <p className="media_text_text">
            付加価値額要件とは、<strong>付加価値額が上昇する事業計画を策定しなければいけない</strong>という要件です。<br />
            <br />
            付加価値額とは、補助金独自の考え方であり、以下の方法により算出できます。<br />
            <span>付加価値額=営業利益+減価償却費+人件費</span><br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・補助事業終了後3~5年で、付加価値額の年平均率を3.0%以上増加させること<br />
            ・補助事業終了後3~5年で、従業員1人当たりの付加価値額の年平均率3.0%以上増加させること<br />
            ※上記のどちらかを満たす事業計画を策定することが要件となります。<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・事業計画書<br />
            <br />
            上記の要件を満たした事業計画書を策定することが必要になりますので、認定支援機関と共にご確認をお願いいたします。<br />
          </p>
          <h3 className='media_text_text_title'>市場拡大要件</h3><br />
          <p className='media_text_text'>
            市場拡大要件とは思い切った事業再構築による売上拡大という事業再構築補助金 本来の目的を達成するために追加された要件となっています。<br />
            <br />
            成長枠で申請を行う際に必要となる要件となっておりますので、成長枠での申請を希望されている事業者様は確実にこの点を抑えていただければと思います。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・成長枠の対象となる業種・業態の一覧に含まれていること<br />
            ・市場が拡大していることを証明する書類を作成すること<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・市場拡大要件を満たすことの説明書<br />
            <br />
            要件を満たす方法は、上記の２点になります。各方法の具体例を以下にてまとめましたので、ご確認いただければと思います。<br />
            <br />
            <strong>1.従来の判断方法</strong><br />
            ○ 判断例：申請できる場合の例<br />
            畜産食料品製造業 から 飲食店 への転換であれば、畜産食料品製造業が成長枠の対象となる業種・業態に含まれているため、成長枠での申請が可能。<br />
            <br />
            × 判断例：申請できない場合の例<br />
            不動産業から コワーキングスペース への転換であれば、不動産業が成長枠の対象となる業種・業態に含まれていないため、成長枠での申請が出来ない。<br />
            <br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/seichowaku_list.pdf' target="_blank" rel="nofollow noopener noreferrer">
              参考書類：成長枠の要件を満たすことができる業種・業態の一覧
            </a><br />
            <br />
            <strong>2.例外の場合</strong><br />
            ○ 以下のような場合は、例外的に成長枠の対象となります。<br />
            <img src={Image1} className='media_img' alt='' />
            ①2019 年の市場規模が 2009 年に比べて 10％以上拡大しており、一貫して上昇トレンドにある。 <br />
            ②2019 年の市場規模が 2009 年に比べて 10％以上拡大しており、前年に比して縮小している年もあるが、全体と して上昇トレンドにあると認められる。<br />
            <br />
            × 以下のような場合は、対象外となりますので、ご注意ください。<br />
            <img src={Image2} className='media_img' alt='' />
            ③2019 年の市場規模が 2009 年に比べて 10％以上拡大しているが、2019 年だけ何かしらの事情で拡大している だけである可能性があり、上昇トレンドにあるとは判断できない。<br />
            ④2019 年の市場規模が 2009 年に比べて 10％以上拡大しているが、近年（2015 年から 2019 年にかけて）下降 トレンドにある。<br />
            ⑤2019 年の市場規模が 2009 年に比べて 10％以上拡大していない。<br />
          </p>
          <h3 className='media_text_text_title'>給与総額増加要件</h3><br />
          <p className='media_text_text'>
            給与総額増加要件とは事業再構築補助金の雇用創出、賃金引き上げを達成するための要件となっており、<strong>成長枠を申請するためには必要な要件</strong>ですので必ずご確認ください。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・給与支給総額を年率平均で2％（賃上げ加点を受ける事業者は3～5％。)以上増加させる計画を作成すること、そして、それを実行することの2点になります。<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・法人であれば法人事業概況説明書<br />
            ・個人であれば所得税青色申告決算書の提出<br />
            <br />
            もし、達成されていない場合は、補助金額の返金が求められるものとなっておりますので、事業計画書の作成時、財務を見直す際に慎重に検討を進めていただければと思います。<br />
            <br />
            ※事業計画書策定時に財務に関するご質問や要件に関するご不明点等ございましたら、当社を含む専門の機関へご相談いただく事を推奨しております。<br />
          </p>
          </>

          <div id='3' className="media_text_title">
            <h2>3.成長枠で申請する際に必要な書類</h2>
          </div>
          <h3 className='media_text_text_title'>市場拡大要件を満たすことを説明する書類</h3><br />
          <p className="media_text_text">
            成長枠の要件である<strong>市場拡大要件を満たしていることを証明するための書類</strong>となっております。<br />
            <br />
            市場拡大要件とは、市場が拡大する業種・業態へ事業を展開することで要件を満たします。具体的には以下の２点も満たす事で可能となります。<br />
            <br />
            本書類は<strong>以下のURLの「市場拡大要件を満たすことの説明書 word」を取得する事で可能</strong>となります。本書類の中身で要件を満たしているかが判断されますので、丁寧にご記載いただければと思います。<br />
            <a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              リンク：「組合特例に関する確認書」のダウンロード
            </a><br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>市場拡大要件の満たし方</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>成長枠の対象となる業種・業態の一覧に含まれる業種への転換</span></li>
              <li><span>市場拡大していることを証明</span></li>
            </ul>
          </div>
          <p className='media_text_text'>
            <span>成長枠の対象となる業種・業態の一覧に含まれる業種への転換</span><br />
            成長枠の対象となる業種・業態は以下の書類にて経済産業省より発表されていますので、ご確認ください。<br />
            <br />
            以下の<strong>成長枠の対象となる業種・業態の一覧に業種・業態が含まれている方は、市場拡大要件を満たすことの説明書にてその旨をご説明いただければ問題ございません</strong>。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/seichowaku_list.pdf' target="_blank" rel="nofollow noopener noreferrer">
              リンク：成長枠の対象となる業種・業態の一覧
            </a><br />
            <br />
            <span>市場拡大していることを証明</span><br />
            <strong>成長枠の対象となる業種・業態の一覧に含まれておらず、自分自身で市場の拡大を証明する場合は、公的データで市場拡大しているデータを見つけ、それを市場拡大要件を満たすことの説明書で証明する必要がございます</strong>。<br />
          </p>
          <h3 className='media_text_text_title'>給与総額増加要件を満たすことを説明する書類</h3><br />
          <p className="media_text_text">
            給与総額増加要件と、<strong>は基準年度（実績報告を行った際の決算）の給与総額を基準として、3年~5年後に年率2%以上の給与支払総額を増加させるというもの</strong>となっております。<br />
            <br />
            そのため、申請段階では達成が判断できないものとなっておりますので、申請段階では以下の２点を示す事で要件を満たす形となります。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>給与総額増加要件を満たすための提出書類</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>賃金引上げ計画の誓約書</span></li>
              <li><span>賃金台帳の写し</span></li>
            </ul>
          </div>
          <p className="media_text_text">
            <span>賃金引上げ計画の誓約書</span><br />
            上記でご説明した通り、本要件は現段階では証明することができないものとなっておりますので、賃金引上げ計画の誓約書を提出します。<br />
            <br />
            賃金引上げ計画の誓約書は以下のURlから「賃金引上げ計画の誓約書 word」を取得し、記載を行う事で要件を満たすことができます。<br />
            <a href='https://trankllc.com/media/subsidy/business/0033' target="_blank" rel="nofollow noopener noreferrer">
              リンク：賃金引上げ計画の誓約書
            </a><br />
            <br />
            <span>賃金台帳の写し</span><br />
            上記の書類とは別に賃金台帳の写しを提出することも必要となっておりますので、本書類も上記の書類と同様にご用意いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>補助率引上要件を満たすことを説明する書類（加点）</h3><br />
          <p className="media_text_text">
            補助率引上要件を満たすことを説明する書類は、成長枠で定義されている補助率を引き上げたい事業者様が提出必須となる書類となっております。<br />
            <br />
            具体的には投資金額の1/6が追加で補填されるものとなっており、とても大きい加点ですので、ぜひ申請をご検討いただければと思います。<br />
            <br />
            大規模な賃上げに取り組むための計画書は以下のURlから「大規模な賃上げに取り組むための計画書 word」を取得し、記載を行う事で要件を満たすことができます。<br />
            <a href='https://trankllc.com/media/subsidy/business/0033' target="_blank" rel="nofollow noopener noreferrer">
              リンク：大規模な賃上げに取り組むための計画書
            </a><br />
            <br />
            補足となりますが、補助率引上要件は以下の2点を満たす事で達成すること要件を満たすことが出来ます。<br />
            ① 補助事業期間内に給与支給総額を年平均 6％以上増加させること<br />
            ② 補助事業期間内に事業場内最低賃金を年額 45 円以上の水準で引上げること<br />
            <br />
            補助率引上要件に関する詳しい解説は以下の記事にて行なっておりますので、ぜひご参照ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0029' target="_blank" rel="nofollow noopener noreferrer">
              リンク：補助率引上要件に関する記事
            </a><br />
          </p>

          <>
          <div id='4' className="media_text_title">
            <h2>4.成長枠のポイント</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では成長枠のポイントをまとめさせていただきますので、本記事の振り返りとして、目を通していただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>成長が見込まれる業種への転換が必須</h3><br />
          <p className="media_text_text">
            上記でも記載させていただきました通り、<strong>経済産業省により認められた業種、もしくは、公的な資料によって過去10年間の成長を示すことができる業種の場合のみ申請できる枠</strong>となっております。<br />
            <br />
            <strong>市場規模拡大が見込まれる業種への転換を考えている方</strong>は、成長枠に該当する可能性が十分にありますので、ぜひ一度ご確認いただければと思います。<br />
            <br />
            市場規模の確認方法は、「業種名 市場規模 推移」などの検索していただければ確認できるかと思います。また、<strong>国が出展している資料が望ましい</strong>ため、国の資料を重点的にお探しいただければと思います。<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>事業拡大を見込んでいる事業者様へおすすめの枠</h3><br />
          <p className="media_text_text">
            この事業を気に事業全体の<strong>雇用拡大を検討している方、大幅な売上拡大を検討されている方</strong>は、ぜひご検討いただければと考えております。<br />
            <br />
            具体的には、<strong>飲食店を新しく開業される方、新サービスの立ち上げなどの事業で再構築を行う方は</strong>雇用が生まれやすく要件を達成しやすいという点で、成長枠を推奨sております。<br />
          </p>
          </>

          <>
          <div id='5' className="media_text_title">
            <h2>5.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、成長枠要件に関する説明をさせていただきました。<br />
            少しでも成長枠のみならず、事業再構築補助金について知って貰えましたら幸いです。<br />
            <br />
            やや複雑な点もあると思いますが、事業再構築補助金の公募要領も一緒に見返しながら申請まで進めていただければと思います。<br />
            <br />
            <strong>当社では記事に関する疑問点の解消のみならず、申請のサポートも行なっておりますので、右上のお問い合わせからご気軽にご連絡いただければと思います。</strong><br />
            <br />
            <strong>この度は記事を拝見してくださり誠にありがとうございました。</strong><br />
            ご連絡の程、大変お待ちしております。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


