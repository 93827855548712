import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/6/top.png";
import Image1 from "./../../../../../image/media/business/6/1.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」実績報告と交付申請の概要を１０分で理解！',
    description: '本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に関する解説も行っております。本記事は、「この後、どのように進めれば良いかわからない。」という方に向けて、記事を執筆させていただきました。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」実績報告と交付申請の概要を10分で理解！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <p className="media_text_text">
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は、<strong>日本最大級の補助金である「事業再構築補助金」</strong>について解説させていただきます。<br />
            <br />
            本記事は、採択されたけど「<strong>この後、どのように進めれば良いかわからない。</strong>」「<strong>申請に当たって、補助金採択後の流れを知りたい。</strong>」という方に向けて、記事を執筆させていただきました。<br />
          </p>

          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>採択後の流れ</span></li>
              <li><span>必要となる書類</span></li>
              <li><span>注意すべきポイント</span></li>
            </ul>
          </div>

          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">採択後の流れ</AnchorLink></li>
                <li><AnchorLink href="#2" offset="100">交付申請</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">交付決定</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">実績報告</AnchorLink></li>
                <li><AnchorLink href="#5" offset="100">確定検査</AnchorLink></li>
                <li><AnchorLink href="#6" offset="100">補助金の確定、精算払請求</AnchorLink></li>
                <li><AnchorLink href="#7" offset="100">事業化状況報告（５年間）</AnchorLink></li>
                <li><AnchorLink href="#8" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>

          <>
          <div id='1' className="media_text_title">
            <h2>1.採択後の流れ</h2>
          </div>
          <p className="media_text_text">
            <br />
            採択後の流れを詳細から解説してしまうと、量が多くなってしまうため、本項目では採択後の大まかな流れを説明させていただき、その後の項目にて詳細を解説させていただきます。<br />
            <br />
            全体の流れをまとめさせていただきましたので、ご確認いただければと思います。<br />
            <br />
            <strong>① 交付申請：事業内容に必要な金額、経費内容を事務局へ申請</strong><br />
            ↓<br />
            <strong>② 交付決定：事務局より交付申請内容の許可通知</strong><br />
            ↓<br />
            <strong>③ 実績報告：補助事業に関する報告</strong><br />
            ↓<br />
            <strong>④ 確定検査：事務局の方が実際に現場に来て補助事業の推進を確認</strong><br />
            ↓<br />
            <strong>⑤ 補助金の確定、精算払請求：補助金額が確定し、補助金額の請求、国からの振込を確認</strong><br />
            ↓<br />
            <strong>⑥ 事業化状況報告（５年間）：補助事業の報告</strong><br />
            <br />
            <img src={Image1} className='media_img' alt='' />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.交付申請</h2>
          </div>
          <p className="media_text_text">
            <br />
            交付申請とは、<strong>採択された事業計画書に記載されている経費項目や金額を改めて精査し、実態を書類にまとめて国に報告すること</strong>をいいます。<br />
            <br />
            <strong>よく、補助金が採択された時点で補助金額が確定していると思われている方がいますが、誤解ですのでお気をつけください</strong>。<br />
            <br />
            具体的に提出する項目を以下にまとめましたので、ご確認いただければと思います。<br />
            <br />
            なお、交付申請の流れ、ポイント、必要書類についてまとめている別途記事がございますので、詳細情報が知りたい方はご参照ください。<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0023' target="_blank" rel="nofollow noopener noreferrer">
              【交付申請とは】事業再構築補助金の交付申請を徹底解剖！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>交付申請における必須資料</h3><br />
          <p className="media_text_text">
            交付申請では、<strong>必須書類</strong>と<strong>事業者さまによって必要になる書類</strong>がございますので、順を追って説明させていただきます。<br />
            <br />
            本項目では、必須書類について以下にまとめさせていただきました。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_5_1'>No.</th><th className='media_table_0001_5_1'>必要書類</th><th className='media_table_0001_1_3'>詳細</th>
              </tr>
              <tr>
                <td><strong>1</strong></td>
                <td>交付申請別紙①</td>
                <td>申請時の内容をまとめたファイル。電子申請システム「J-grants」よりダウンロードします。</td>
              </tr>
              <tr>
                <td><strong>2</strong></td>
                <td>履歴事項全部証明書</td>
                <td>提出日より過去３ヶ月以内に取得したことが要件となるため、新たに取得してください。</td>
              </tr>
              <tr>
                <td><strong>3</strong></td>
                <td>決算書</td>
                <td>直近決算分の決算書（貸借対照表、損益計算書など）が必要となります。</td>
              </tr>
              <tr>
                <td><strong>4</strong></td>
                <td>見積依頼書および見積書</td>
                <td>補助事業に必要となる経費に対する見積書をメーカーなどに依頼をして作成してもらいましょう。受け取った見積書をベースに見積依頼書も作成しましょう。</td>
              </tr>
              <tr>
                <td><strong>5</strong></td>
                <td>参考様式２１取得財産に係る誓約書</td>
                <td>公式HPよりダウンロードいただき、取得する資産をその他の事業では使わないという誓約を書面でしていただく書類となります。</td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>事業者さまによって必要になる書類</h3><br />
          <p className="media_text_text">
            事業者さまによって、必要となる書類を以下にまとめさせていただきました。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_5_1'>No.</th><th className='media_table_0001_5_1'>必要書類</th><th className='media_table_0001_1_3'>詳細</th>
              </tr>
              <tr>
                <td><strong>1</strong></td>
                <td>交付申請書別紙２</td>
                <td>技術導入費、専門家経費、クラウドサービス利用費、外注費、知的財産権等関連経費を計上する場合に必要となる書類です。</td>
              </tr>
              <tr>
                <td><strong>2</strong></td>
                <td>海外旅費の詳細</td>
                <td>海外旅費を計上する補助事業者は提出が必要です。</td>
              </tr>
              <tr>
                <td><strong>3</strong></td>
                <td>事前着手承認のお知らせメールデータ</td>
                <td>事前着手制度を活用された事業者様のみ必要となる書類です。</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3 className='media_text_text_title'>交付申請の注意点</h3><br />
          <p className="media_text_text">
            最後に交付申請の際に気をつけていただきたいポイントをまとめさせていただきましたので、実際に交付申請を行う方はぜひご確認いただければと思います。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>ポイント</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>交付申請後に設備の発注や工事が可能となるため、交付申請のタイミングでは事業が進まない</span></li>
              <li><span>申請時の内容と詳細や金額に違いがある場合は地域事務局より連絡がある</span></li>
              <li><span>交付決定後に金額や内容の修正は原則できないため、このタイミングで内容を決定させる必要がある</span></li>
            </ul>
          </div>
          <p className="media_text_text">
            加えて、文中でもご案内させていただきましたが、交付申請に関する詳しい記事は以下にてご参照いただけますので、ぜひご確認ください。<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0023' target="_blank" rel="nofollow noopener noreferrer">
              【交付申請とは】事業再構築補助金の交付申請を徹底解剖！
            </a><br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>３.交付決定</h2>
          </div>
          <p className="media_text_text">
            <br />
            交付決定とは、<strong>国より「交付決定通知書」が出されること</strong>をいい、この通知を受けたのち補助事業期間に入り、必要となる設備の発注や工事の着手などが可能となります。<br />
            <br />
            ここでは、<strong>交付決定から実施報告までの期間の注意点を３つご紹介させていただきます</strong>。<br />
            実施期間は、実績報告の準備期間でもあるため、実績報告で必要な書類を頭に入れておくと、その後の処理がスムーズに進められます。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>実施期間のポイント</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>時間のかかるタスクから進めていく</span></li>
              <li><span>発注→納品→支払いの順番になるように進めていく</span></li>
              <li><span>代金の支払いは絶対に交付決定以降に行うこと</span></li>
            </ul>
          </div>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>4.実績報告</h2>
          </div>
          <p className="media_text_text">
            <br />
            実績報告とは、<strong>補助事業期間で実施した内容を書類にまとめ、国の審査を受ける</strong>手続きとなります。<br />
            <br />
            実績報告では、たくさんの書類を作成する必要があります。そこで、本記事では、以下の２つに分けてご説明させていただきます。<br />
            <br />
            <strong>① 必須書類</strong><br />
            <strong>② 経費の区分ごとに必要な書類</strong><br />
          </p>
          <h3 className='media_text_text_title'>必須書類</h3><br />
          <p className="media_text_text">
            本項目では、必須書類について以下にまとめさせていただきました。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_5_1'>必要書類</th><th className='media_table_0001_1_3'>詳細</th>
              </tr>
              <tr>
                <td>様式第６ 実績報告書</td>
                <td>電子申請システム「J-grants」よりダウンロードします。</td>
              </tr>
              <tr>
                <td>様式第６の別紙１及び別紙４</td>
                <td>電子申請システム「J-grants」よりダウンロードします。</td>
              </tr>
              <tr>
                <td>様式第６の別紙２及び別紙３</td>
                <td>電子申請システム「J-grants」よりダウンロードします。</td>
              </tr>
              <tr>
                <td>様式第７取得財産等管理台帳</td>
                <td>電子申請システム「J-grants」よりダウンロードします。</td>
              </tr>
              <tr>
                <td>出納帳のコピー</td>
                <td>現金の流れを示す出納帳を作成する必要があります。フォーマットも国で用意されています。（参考様式19）</td>
              </tr>
              <tr>
                <td>通帳のコピー</td>
                <td>表紙・中表紙・取引明細が必要となります。</td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>経費の区分ごとに必要となる書類</h3><br />
          <p className="media_text_text">
            本項目では、経費の区分ごとに必要となる書類について以下にまとめさせていただきました。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_5_1'>必要書類</th><th className='media_table_0001_1_3'>詳細</th>
              </tr>
              <tr>
                <td>見積書</td>
                <td>交付申請で提出したものと同じです。</td>
              </tr>
              <tr>
                <td>売買契約書</td>
                <td>購入時に発行される契約書です。</td>
              </tr>
              <tr>
                <td>納品書</td>
                <td>納品したことを示す書類です。</td>
              </tr>
              <tr>
                <td>請求書</td>
                <td>外部機関から発行される書類です。</td>
              </tr>
              <tr>
                <td>支払い証書</td>
                <td>金融機関から発行される支払い時の書類です。</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3 className='media_text_text_title'>本項目のまとめ</h3><br />
          <p className="media_text_text">
            最後に実績報告において、すべきポイントをまとめましたので、ご確認ください。<br />
            <br />
            なお、交付申請同様に、詳細な情報は別の記事を用意させていただきましたので、ぜひご参照いただければと思います。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>ポイント</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>必要書類が多いため、何が必要かを予めリストアップする</span></li>
              <li><span>リストアップした書類の雛形をダウンロードしておき、必要となる情報をピックアップ</span></li>
              <li><span>判明した情報から入力していくことで時短を目指す</span></li>
              <li><span>地域事務局より修正の連絡が複数回来る可能性があるが、補助金の受取額などには影響はない</span></li>
            </ul>
          </div>
          <p className="media_text_text">
            加えて、本完了は以下の手順を踏んでのちになりますので、お間違いないようによろしくお願いします。<br />
            <br />
            <strong>① 採択発表後に連絡があった地域事務局の担当に書類を提出</strong><br />
            <strong>② 担当者の承諾</strong><br />
            <strong>③ 「j-grants」より書類のアップロード</strong><br />
          </p>
          </>

          <>
          <div id='5' className="media_text_title">
            <h2>5.確定検査</h2>
          </div>
          <p className="media_text_text">
            <br />
            確定検査とは、<strong>実績報告が完了後、地域事務局の担当者が書類の内容に基づいた審査を行うこと</strong>です。<br />
            <br />
            担当者は、建物の改修や機械装置の入手・支払いが実際に行われているかの確認がメインとなりますので、実績報告のファイル/書類等が手元あるとスムーズに進めることができます。<br />
            <br />
            以下に注意すべきポイントをまとめます。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>ポイント</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>実績報告の際に提出した書類を手元に用意しておくこと（ファイルがオススメ）</span></li>
              <li><span>地域事務局からのメールに気づくようにしておくこと</span></li>
              <li><span>実地検査の際に機械装置が動くかどうか確認があるので、電源を入れておくこと</span></li>
            </ul>
          </div>
          </>

          <>
          <div id='6' className="media_text_title">
            <h2>6.補助金の確定、精算払請求</h2>
          </div>
          <p className="media_text_text">
            <br />
            <strong>確定検査が完了後、ようやく補助金の確定および支払いが行われます</strong>。<br />
            <br />
            具体的な流れとしまししては、以下のような流れとなります。<br />
            <strong>① 実地報告完了</strong><br />
            <strong>② 地域事務局より「様式第８ 補助金確定通知書」が発行</strong><br />
            <strong>③ 電子申請システム「J-grants」に支払い用の情報を入力</strong><br />
            <strong>③ 金額受け取り</strong><br />
            <br />
            以下に注意すべきポイントをまとめます。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>ポイント</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>補助金確定通知書の発行があって初めて受け取れる補助金の金額が確定すること</span></li>
              <li><span>補助金の振込用の通帳をご用意いただくこと</span></li>
              <li><span>請求後、支払いまでに２週間～２ヶ月</span></li>
            </ul>
          </div>
          </>

          <>
          <div id='7' className="media_text_title">
            <h2>7.事業化状況報告（５年間）</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築補助金は補助金を受け取って終わりではなく、実は<strong>５年間の定期報告が必要となります</strong>。<br />
            <br />
            この定期報告は事業化状況報告と呼ばれており、<strong>補助事業の事業化状況、知的財産権等の状況報告等について、書類を提出する必要があります</strong>。<br />
            <br />
            必要となる書類としては以下のものが挙げられます。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_5_1'>必要書類</th><th className='media_table_0001_1_3'>詳細</th>
              </tr>
              <tr>
                <td>様式第１３ 事業化状況・知的財産権報告書</td>
                <td>公式HPよりダウンロードしてください。</td>
              </tr>
              <tr>
                <td>事業化状況等の実態把握調査票</td>
                <td>公式HPよりダウンロードしてください。</td>
              </tr>
              <tr>
                <td>決算書</td>
                <td>その時点での直近決算分の決算書（損益計算書、貸借対照表、製造原価報告書、販売費及び一般管理費明細表 等）</td>
              </tr>
              <tr>
                <td>労働者名簿</td>
                <td>その時点での労働者の人数や氏名、役職など基本情報が揃っている名簿が必要となります。</td>
              </tr>
              <tr>
                <td>賃金台帳</td>
                <td>「賃金引き上げ枠」で申請された事業者さまのみ。</td>
              </tr>
            </tbody>
          </table>
          </>

          <>
          <div id='8' className="media_text_title">
            <h2>8.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、事業再構築補助金に採択された事業者様に向けて、今後の流れについて記事にさせていただきました。難しい部分が多い印象かと思いますが、補助金受け取りまで、1つずつ進めていきましょう。<br />
            <br />
            書類の多さや書き方など煩雑な手続きが大変多いものとなっておりますが、弊社を含めた民間コンサルティング企業は採択後のサポートもしておりますので、ぜひ一度ご相談ください。<br />
            <br />
            ご相談は、画面側のお問い合わせにて、お待ちしております。<br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


