import './styles.css';
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header1_1400 from './../../../../image/homepage/header/pc/1.png';
import Header2_1400 from './../../../../image/homepage/header/pc/2.png';
import Header3_1400 from './../../../../image/homepage/header/pc/3.png';
import Header4_1400 from './../../../../image/homepage/header/pc/4.png';
import Header5_1400 from './../../../../image/homepage/header/pc/5.png';
import Header6_1400 from './../../../../image/homepage/header/pc/6.png';
import Header1_1200 from './../../../../image/homepage/header/1200/1200_1.png';
import Header2_1200 from './../../../../image/homepage/header/1200/1200_2.png';
import Header3_1200 from './../../../../image/homepage/header/1200/1200_3.png';
import Header4_1200 from './../../../../image/homepage/header/1200/1200_4.png';
import Header5_1200 from './../../../../image/homepage/header/1200/1200_5.png';
import Header6_1200 from './../../../../image/homepage/header/1200/1200_6.png';
import Header1_1000 from './../../../../image/homepage/header/1000/1000_1.png';
import Header2_1000 from './../../../../image/homepage/header/1000/1000_2.png';
import Header3_1000 from './../../../../image/homepage/header/1000/1000_3.png';
import Header4_1000 from './../../../../image/homepage/header/1000/1000_4.png';
import Header5_1000 from './../../../../image/homepage/header/1000/1000_5.png';
import Header6_1000 from './../../../../image/homepage/header/1000/1000_6.png';
import Header1_800 from './../../../../image/homepage/header/800/800_1.png';
import Header2_800 from './../../../../image/homepage/header/800/800_2.png';
import Header3_800 from './../../../../image/homepage/header/800/800_3.png';
import Header4_800 from './../../../../image/homepage/header/800/800_4.png';
import Header5_800 from './../../../../image/homepage/header/800/800_5.png';
import Header6_800 from './../../../../image/homepage/header/800/800_6.png';
import Header1_600 from './../../../../image/homepage/header/600/600_1.png';
import Header2_600 from './../../../../image/homepage/header/600/600_2.png';
import Header3_600 from './../../../../image/homepage/header/600/600_3.png';
import Header4_600 from './../../../../image/homepage/header/600/600_4.png';
import Header5_600 from './../../../../image/homepage/header/600/600_5.png';
import Header6_600 from './../../../../image/homepage/header/600/600_6.png';

export default class SlickGoTo extends React.Component {

  state = {
    slideIndex: 0,
    updateCount: 0
  };

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 4000,
      slidesToShow: 1,
      arrows: false,
      slidesToScroll: 1,
      autoplay: true,
      afterChange: () => this.setState(state => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next })
    };
  
    return (
      <>
      <div className='test_homeimage_image_box_tablet'>
        <div className='homepage_image_img_tablet_1400px'>
          <Slider ref={slider => (this.slider = slider)} {...settings} className='test_homeimage_image_tablet'>
            <img src={Header1_1400} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header2_1400} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header3_1400} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header4_1400} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header5_1400} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header6_1400} className='test_homeimage_image_img_tablet' alt='' />
          </Slider>
        </div>
        <div className='homepage_image_img_tablet_1200px'>
          <Slider ref={slider => (this.slider = slider)} {...settings} className='test_homeimage_image_tablet'>
            <img src={Header1_1200} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header2_1200} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header3_1200} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header4_1200} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header5_1200} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header6_1200} className='test_homeimage_image_img_tablet' alt='' />
          </Slider>
        </div>
        <div className='homepage_image_img_tablet_1000px'>
          <Slider ref={slider => (this.slider = slider)} {...settings} className='test_homeimage_image_tablet'>
            <img src={Header1_1000} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header2_1000} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header3_1000} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header4_1000} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header5_1000} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header6_1000} className='test_homeimage_image_img_tablet' alt='' />
          </Slider>
        </div>
        <div className='homepage_image_img_tablet_800px'>
          <Slider ref={slider => (this.slider = slider)} {...settings} className='test_homeimage_image_tablet'>
            <img src={Header1_800} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header2_800} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header3_800} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header4_800} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header5_800} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header6_800} className='test_homeimage_image_img_tablet' alt='' />
          </Slider>
        </div>
        <div className='homepage_image_img_tablet_600px'>
          <Slider ref={slider => (this.slider = slider)} {...settings} className='test_homeimage_image_tablet'>
            <img src={Header1_600} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header2_600} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header3_600} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header4_600} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header5_600} className='test_homeimage_image_img_tablet' alt='' />
            <img src={Header6_600} className='test_homeimage_image_img_tablet' alt='' />
          </Slider>
        </div>
      </div>

      <div className='test_homeimage_bar_tablet'>
        <div style={{backgroundColor : '#479D58'}} onClick={() => this.slider.slickGoTo(0)} className='test_homeimage_bar_tablet_600'>
          <p>コンサルティング部がドローン企業と提携</p>
        </div>
        <div style={{backgroundColor : '#2E81B0'}} onClick={() => this.slider.slickGoTo(1)} className='test_homeimage_bar_tablet_600'>
          <p>公開から1年！ユーザー数が2000人を突破</p>
        </div>
        <div style={{backgroundColor : '#DA3E35'}} onClick={() => this.slider.slickGoTo(2)} className='test_homeimage_bar_tablet_600'>
          <p>2023年10月にサービス提供開始</p>
        </div>
        <div style={{backgroundColor : '#CD445B'}} onClick={() => this.slider.slickGoTo(3)} className='test_homeimage_bar_tablet_800'>
          <p>美容学生に向けキャンペーン実施中</p>
        </div>
        <div style={{backgroundColor : '#373198'}} onClick={() => this.slider.slickGoTo(4)} className='test_homeimage_bar_tablet_1000'>
          <p>コーポレートサイトをリニューアル</p>
        </div>
        <div style={{backgroundColor : '#3C3C3C'}} onClick={() => this.slider.slickGoTo(5)} className='test_homeimage_bar_tablet_1200'>
          <p>Shake Shackの展示会に作品が出典</p>
        </div>
      </div>
      </>
    );
  }
}

