import React from "react";
import { useState, useEffect } from "react";
import './styles.css';
import firebase from 'firebase/compat/app'

const Taskheader = (props) => {

  const [ createName, setCreateName ] = useState('');
  const [ createMemo, setCreateMemo ] = useState('');
  const [ createPerson, setCreatePerson ] = useState('辻');

  useEffect(() => {


    if (props.createInfo !== 500) {
      setCreateName(props.items[props.createInfo].name);
      setCreateMemo(props.items[props.createInfo].memo);
      setCreatePerson(props.items[props.createInfo].person[0]);
    }
  }, [props.items, props.createInfo]);

  function create() {
    props.setCreateInfo(1000);

    firebase.firestore().collection('tasks').doc(props.items[props.createInfo].id)
    .update({
      name : createName,
      memo : createMemo,
      person : [ props.name, 'https://ca.slack-edge.com/T03LR3Y18SZ-U03MHQVPG64-e465f85b0cef-512', ],
    });
  }

  function deletes() {
    props.setCreateInfo(1000);
    console.log(props.items[props.createInfo].id);
    firebase.firestore().collection('tasks').doc(props.items[props.createInfo].id).delete();
  }

  return (
    <>
    <div className="task_create">
      <div className="task_create_background" onClick={() => props.setCreateInfo(1000)}></div>
      <div className='task_content'>
        <div className='task_content_title'>
          <input placeholder="案件名" onChange={(e) => setCreateName(e.target.value)} onKeyDown={(e) => e.keyCode === 13 ? create() : ''} defaultValue={createName} />
          {createPerson === '辻' ?
            <img src='https://ca.slack-edge.com/T03LR3Y18SZ-U03MHQVPG64-e465f85b0cef-512' onClick={() => setCreatePerson('伊藤')} alt='' />
            :
            <img src='https://ca.slack-edge.com/T03LR3Y18SZ-U03LXL4C9JQ-16b421d1759b-512' onClick={() => setCreatePerson('辻')} alt='' />
          }
        </div>
        <textarea placeholder='メモ' onChange={(e) => setCreateMemo(e.target.value)} defaultValue={createMemo} />
        <div className='task_content_btn'>
          <button onClick={() => create()}>追加</button>
          <button onClick={() => deletes()}>削除</button>
        </div>
      </div>
    </div>
    </>
  );
};

export default Taskheader;
