import './styles.css';
import Icon from './../../../image/homepage/icon.png';

const Footer = () => {
  
  return (
    <>
    <div className='test_homepage_bottom_nav'>
      <div className='test_homepage_bottom_nav_top'>
        <div className='test_homepage_bottom_nav_top_left'>
          <img src={Icon} alt='トランク合同会社' />
          <h4>トランク合同会社</h4>
          <p>ニッチ分野の課題を解決する会社</p>
        </div>
        <div className='test_homepage_bottom_nav_top_right'>
          <div className='test_homepage_bottom_nav_top_right_600'>
            <h4>各種サービス</h4>
            <a href='https://trankllc.com/help' rel="nofollow noopener noreferrer">
              <p>補助金申請サポート</p>
            </a>
            <a href='https://memorii.jp/' rel="nofollow noopener noreferrer">
              <p>カルテ管理アプリ</p>
            </a>
            <a href='https://trankllc.com/bid/home' rel="nofollow noopener noreferrer">
              <p>公共工事入札サービス</p>
            </a>
            <a href='https://trankllc.com/photo' rel="nofollow noopener noreferrer">
              <p>作品撮り投稿アプリ</p>
            </a>
            <a href='https://trankllc.com/contact' rel="nofollow noopener noreferrer">
              <p>webサイト開発</p>
            </a>
            <a href='https://trankllc.com/masato' rel="nofollow noopener noreferrer">
              <p>写真撮影サービス</p>
            </a>
          </div>
          <div className='test_homepage_bottom_nav_top_right_800'>
            <h4>メディア</h4>
            <a href='https://trankllc.com/media/subsidy/business/0000' rel="nofollow noopener noreferrer">
              <p>事業再構築の教科書</p>
            </a>
            <a href='https://trankllc.com/bid/home' rel="nofollow noopener noreferrer">
              <p>入札情報の窓口</p>
            </a>
          </div>
          <div className='test_homepage_bottom_nav_top_right_1000'>
            <h4>求人情報</h4>
            <a href='https://trankllc.com/company' rel="nofollow noopener noreferrer">
              <p>営業職</p>
            </a>
            <a href='https://trankllc.com/company' rel="nofollow noopener noreferrer">
              <p>プロマネ（PM）職</p>
            </a>
            <a href='https://trankllc.com/company' rel="nofollow noopener noreferrer">
              <p>インターン生</p>
            </a>
            <a href='https://trankllc.com/company' rel="nofollow noopener noreferrer">
              <p>社員ブログ</p>
            </a>
          </div>
          <div className='test_homepage_bottom_nav_top_right_600'>
            <h4>基本情報</h4>
            <a href='https://trankllc.com/company' rel="nofollow noopener noreferrer">
              <p>会社情報</p>
            </a>
            <a href='https://trankllc.com/contact' rel="nofollow noopener noreferrer">
              <p>お問い合わせ</p>
            </a>
          </div>
        </div>
      </div>
      <div className='test_homepage_bottom_nav_bottom'>
        <div>
          <a href='https://trankllc.com/privacy' rel="nofollow noopener noreferrer">
            <p>プライバシーポリシー</p>
          </a>
          <a href='https://trankllc.com/company' rel="nofollow noopener noreferrer">
            <p>運営会社</p>
          </a>
        </div>
        <p>© Trankllc. All Rights Reserved.</p>
      </div>
    </div>
    </>
  );
}

export default Footer;
