// 売上高減少要件

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/8/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」売上高減少要件は、２つの期間を比較！',
    description: '本記事では、売上高減少要件の類型を詳しく解説しております。その他、具体例や判断に必要な考え方についても説明しております。本記事は、「事業再構築補助金に申請できるか知りたい」方や「売上減少の計算方法を知りたい」という方に向けて、記事を執筆させていただきました。<br />',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」売上高減少要件は、２つの期間を比較！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            こんにちは。事業再構築補助金の申請サポートをしているトランク合同会社です。この記事では、物価高騰・回復再生応援枠の要件の1つである売上高等減少要件について、解説します。<br />
            <br />
            このサイトは、全体を通して、初めての方にも伝わる記事を目指していますので、挫折しかけている方も是非この記事をご覧いただければと思います。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>本記事でこんな悩みが解決！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>売上減少要件を満たす必要がある申請枠</span></li>
              <li><span>売上減少要件の計算方法</span></li>
              <li><span>付加価値額減少要件の計算方法</span></li>
            </ul>
          </div>
          </>

          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">売上高等減少要件の概要</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">売上高等減少要件の計算方法</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">付加価値額を用いる場合</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>

          <>
          <div id='1' className="media_text_title">
            <h2>１.売上高等減少要件の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            売上高等減少要件とは、「物価高騰による売上の減少を証明するための要件」となっております。<br />
            <br />
            なお、売上高減少要件は、売上高の減少割合で判断するのですが、売上減少での判断が出来なかった場合は、付加価値額での減少を示すことで要件を満たすことができます。<br />
            <br />
            そのため、売上高の減少割合が基準を満たさなかった事業者様も諦めず、本記事で付加価値額の算出方法を理解してみてください！<br />
          </p>
          <h3 className='media_text_text_title'>要件の対象者</h3><br />
          <p className="media_text_text">
            売上高減少要件を満たす必要がある事業者さまは、物価高騰・回復再生応援枠で申請する方のみとなっております。<br />
          </p>
          <h3 className='media_text_text_title'>売上高減少要件の定義</h3><br />
          <p className="media_text_text">
            売上高減少要件の定義は、以下のようになっています。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>事業再構築補助金の目的</span><br />
            ア．「2022年1月以降の連続する６か月間のうち、任意の３か月の合計売上高が、2019~2021年の同３か月の合計売上高と比較して10％以上減少していること」を満たさない場合には、以下の要件を満たすことでも申請可能です。「2022年1月以降の連続する６か月間のうち、任意の３か月の合計付加価値額が、2019~2021年の同３か月の合計付加価値額と比較して15％以上減少していること」<br />
            イ.「任意の３か月」とは「2022年1月以降の連続する６か月間」の範囲内であれば連続した3か月である必要はありません。<br />
            ウ．付加価値額の算出方法は付加価値額要件における算出方法に準じます。また、期中に購入した設備等の減価償却費については、購入した日から決算日までを月数で按分した金額に基づき算出することが必要です。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            上記の公募要領をまとめると、売上等減少要件の定義は以下の3点となります。<br />
            <br />
            ①：2019年1月～2021年12月の連続する6か月の内、任意の3か月(物価高騰以前)と2022年1月以降の同月(物価高騰以後)で比較を行う<br />
            <br />
            ②：物価高騰以前と比較して、物価高騰以後の総売上が15%以上減少している場合、要件を満たす。<br />
            <br />
            ③：②の要件を満たさない場合、総付加価値額が15%以上減少していることで要件を満たす。<br />
          </p>
          <h3 className='media_text_text_title'>具体例</h3><br />
          <p className="media_text_text">
            以下にて、①「<strong>連続する6か月の内の任意の3か月</strong>」の具体例をご説明させていただきます。<br />
            <br />
            <strong>要件を満たしている考え方</strong><br />
            4月～9月という連続する6か月の中から同月を選択しているため、要件を満たします。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <td>2019年</td><td>4月</td><td>6月</td><td>9月</td>
              </tr>
              <tr>
                <td>2022年</td><td>4月</td><td>6月</td><td>9月</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            <strong>要件を満たしていない考え方</strong><br />
            異なる月を選択しているため、要件を満たしません。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <td>2019年</td><td>4月</td><td>6月</td><td>9月</td>
              </tr>
              <tr>
                <td>2022年</td><td>4月</td><td>5月</td><td>9月</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            <strong>要件を満たしていない考え方</strong><br />
            4月から10月は7か月の期間が開いているため、要件を満たしません。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <td>2019年</td><td>4月</td><td>7月</td><td>10月</td>
              </tr>
              <tr>
                <td>2021年</td><td>4月</td><td>7月</td><td>10月</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            では、次からは具体的な事例を交えて、②番の要件である、「15%以上総売上が減少している」という文章の意味を理解していきましょう！<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>２.売上高等減少要件の計算方法</h2>
          </div>
          <p className="media_text_text">
            <br />
            この項目では、②「物価高騰以後の総売上が15%以上減少している場合」を具体例を用いて解説いたします。なお、申請枠や業種によって計算方法の違いはありません。<br />
          </p>
          <h3 className='media_text_text_title'>具体例</h3><br />
          <p className="media_text_text">
            今回は一例として、以下の売上を用いて減少要件の計算を行っていきます。手順としては、以下のような手順がおすすめです。<br />
            <br />
            ①年別の売上を記載して、縦列で同じ月の比較が行えるようにする。<br />
            ②増減率を計算し、下記に記載する。公式は、（（物価高騰後の売上/物価高騰前の売上）-1）<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th></th><th>7月</th><th>8月</th><th>9月</th><th>10月</th><th>11月</th><th>12月</th>
              </tr>
              <tr>
                <td>2021年(物価高騰後)</td><td>400,000</td><td>500,000</td><td>600,000</td><td>400,000</td><td>500,000</td><td>500,000</td>
              </tr>
              <tr>
                <td>2022年(物価高騰後)</td><td>600,000</td><td>400,000</td><td>400,000</td><td>450,000</td><td>450,000</td><td>550,000</td>
              </tr>
              <tr>
                <td>増減率(%)</td><td>50.0%</td><td>-20.0%</td><td>-33.3%</td><td>12.5%</td><td>-10.0%</td><td>10.0%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            ③<strong>連続する6か月の中から減少率が大きい3か月を抜き取り、物価高騰前・物価高騰後の総売上高を計算する。</strong><br />
            ④<strong>総売上高の増減率を計算する</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th></th><th>8月</th><th>9月</th><th>11月</th><th>計</th>
              </tr>
              <tr>
                <td>2021年(物価高騰後)</td><td>500,000</td><td>600,000</td><td>500,000</td><td>1,600,000</td>
              </tr>
              <tr>
                <td>2022年(物価高騰後)</td><td>400,000</td><td>400,000</td><td>450,000</td><td>1,250,000</td>
              </tr>
              <tr>
                <td>増減率(%)</td><td>-20.0%</td><td>-33.3%</td><td>-10.0%</td><td>-21.9%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            ⑤<strong>上記の総売上高減少率が10%を超えていた場合、要件を満たす。</strong><br />
          </p>
          <h3 className='media_text_text_title'>売上高等減少要件のよくある質問</h3><br />
          <p className="media_text_text">
            ここまでの段階をQ&A形式にて、ご紹介させていただきます。<br />
            <br />
            Q.<strong>売上高等減少要件を調べるうえで、必要な書類はありますか？</strong><br />
            月別の売上表を用いて算出します。特に、決算が確定している年度の月別売上は、法人事業概況説明書の控えに記載されている数値を基に計算してください。<br />
            <br />
            Q.<strong>売上高等減少要件を満たしていない場合は、申請できませんか？</strong><br />
            売上減少、付加価値額の両方で満たさない場合、「成長枠」「産業構造転換枠」「最低賃金枠」「グリーン枠」での申請をお考えください。<br />
            <br />
            Q.<strong>専門家等に確認してもらう必要は、ありますか？</strong><br />
            基本的には事業者様のみで確認することが可能ですが、不安な場合は顧問税理士の方や、右上の問い合わせから弊社にご相談ください。<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>３.付加価値額を用いる場合</h2>
          </div>
          <p className="media_text_text">
            <br />
            ここでは、総売上高での要件を満たさない場合に使用する、<strong>付加価値額での算出方法を記載いたします</strong>。売上高減少要件を満たさなかった事業者様は、是非ご覧ください。<br />
          </p>
          <h3 className='media_text_text_title'>付加価値額とは</h3><br />
          <p className="media_text_text">
            付加価値額とは、補助金独自の算出額となっております。具体的には、以下の公式から算出されるものとなっています。<br />
            <br />
            <span>付加価値額=営業利益額+人件費+減価償却費</span><br />
            <br />
            付加価値額は事業再構築補助金の他部分でも使用する考え方となっているため、上記の公式は覚えておくことをおススメします！<br />
          </p>
          <h3 className='media_text_text_title'>付加価値額の求め方</h3><br />
          <p className="media_text_text">
            それでは、上記の公式を用いて実際に付加価値額を求めていきましょう。<br />
            <br />
            なお、付加価値額の算出を行うためには、月別の営業利益額・人件費・減価償却費が必要となりますので、社内でのご確認をお願いいたします。<br />
            <br />
            詳細につきましては、契約されている税理士の方にお尋ねください。<br />
            <br />
            なお、具体的な付加価値額の求め方に関しましては、下記に具体例を記載いたしましたので、そちらを参考にしながら算出をしてみてください。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th></th><th></th><th>7月</th><th>8月</th><th>9月</th><th>10月</th><th>11月</th><th>12月</th>
              </tr>
              <tr>
                <td>2019年</td><td>営業利益額</td><td>400,000</td><td>500,000</td><td>600,000</td><td>400,000</td><td>550,000</td><td>500,000</td>
              </tr>
              <tr>
                <td>2019年</td><td>営業利益額</td><td>100,000</td><td>100,000</td><td>100,000</td><td>100,000</td><td>150,000</td><td>150,000</td>
              </tr>
              <tr>
                <td>2019年</td><td>減価償却費</td><td>50,000</td><td>50,000</td><td>50,000</td><td>50,000</td><td>50,000</td><td>50,000</td>
              </tr>
              <tr>
                <td>付加価値額</td><td></td><td>550,000</td><td>650,000</td><td>750,000</td><td>550,000</td><td>750,000</td><td>700,000</td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>付加価値額による、売上高減少要件を満たす具体例</h3><br />
          <p className="media_text_text">
            ここでは、上記の数値を基にして売上減少要件を満たす具体例を記載いたします。<br />
            <br />
            物価高騰以前の付加価値額が求まっている状態から計算を行っていきますので、下記の手順を踏んで付加価値額による売上減少要件の満たし方を理解しましょう！<br />
            <br />
            ①<strong>物価高騰後の月別付加価値額を算出する。(月別の営業利益額+人件費+減価償却費)</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th></th><th></th><th>7月</th><th>8月</th><th>9月</th><th>10月</th><th>11月</th><th>12月</th>
              </tr>
              <tr>
                <td>2022年</td><td>営業利益額</td><td>500,000</td><td>300,000</td><td>500,000</td><td>450,000</td><td>400,000</td><td>600,000</td>
              </tr>
              <tr>
                <td>2022年</td><td>営業利益額</td><td>150,000</td><td>150,000</td><td>150,000</td><td>100,000</td><td>100,000</td><td>100,000</td>
              </tr>
              <tr>
                <td>2022年</td><td>減価償却費</td><td>35,000</td><td>35,000</td><td>35,000</td><td>35,000</td><td>35,000</td><td>35,000</td>
              </tr>
              <tr>
                <td>付加価値額</td><td></td><td>685,000</td><td>485,000</td><td>685,000</td><td>585,000</td><td>535,000</td><td>735,000</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            ②<strong>それぞれの付加価値額を抜き取った表を作成し、増減率を計算する。</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th></th><th>7月</th><th>8月</th><th>9月</th><th>10月</th><th>11月</th><th>12月</th>
              </tr>
              <tr>
                <td>物価高騰前の付加価値額</td><td>550,000</td><td>650,000</td><td>750,000</td><td>550,000</td><td>750,000</td><td>700,000</td>
              </tr>
              <tr>
                <td>物価高騰後の付加価値額</td><td>685,000</td><td>485,000</td><td>685,000</td><td>585,000</td><td>535,000</td><td>735,000</td>
              </tr>
              <tr>
                <td>増減率</td><td>24.5%</td><td>-25.4%</td><td>-8.7%</td><td>6.4%</td><td>-28.7%</td><td>5.0%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            ③<strong>連続する6か月の中から最も減少率が大きい3か月を抜き出し、付加価値額の合計と、増減率を算出する。</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th></th><th>8月</th><th>9月</th><th>11月</th><th>計</th>
              </tr>
              <tr>
                <td>物価高騰前の付加価値額</td><td>650,000</td><td>750,000</td><td>750,000</td><td>2,150,000</td>
              </tr>
              <tr>
                <td>物価高騰後の付加価値額</td><td>485,000</td><td>685,000</td><td>535,000</td><td>1,705,000</td>
              </tr>
              <tr>
                <td>増減率</td><td>-25.4%</td><td>-8.7%</td><td>-28.7%</td><td>-20.7%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            ④<strong>合計の減少率が15%以上の場合、要件を満たす。</strong><br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>売上高等減少要件を満たす方法</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>物価高騰後の、連続する6か月中の任意の3か月の売上高合計が、物価高騰前と比較して10%以上減少していること</span></li>
              <li><span>上記を満たさない場合、付加価値額が15%以上減少していること</span></li>
            </ul>
          </div>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>４.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、<strong>売上高等減少要件について解説をさせていただきました</strong>。<br />
            <br />
            やや複雑なものとはなっておりますが、少しずつ進めていくと意外と簡単なものですので、諦めずに申請まで頑張ってください。<br />
            <br />
            <strong>ご不明点、疑問点につきましては、右上の問い合わせからお待ちしております</strong>ので、ご気軽にご連絡いただければと思います。<br />
            <br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


