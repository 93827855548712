// 卸売業
// https://docs.google.com/document/d/192XcDfJMzSxdyBgeFOlNiE13dmBKri8ZB_QJOfHzEcI/edit?pli=1#heading=h.psmmpjvrwz5q

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "../../nav/index";
import Topic from "../topic";
import TopImage from "./../../../../../image/media/business/44/top.png";
import Image1 from "./../../../../../image/media/business/42/1.png";
import Footer from '../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」卸売業の採択事例を大公開！',
    description: '本記事では、事業再構築補助金の卸売業 採択事例を紹介しております。卸売業を営んでいて事業再構築補助金の申請を検討されている方は、本記事をご参考に検討を行っていただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」卸売業の採択事例を大公開！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            外出自粛や休業要請など、新型コロナウイルスによる影響を受けた飲食店や小売店。<br />
            その影響は食材や商品を卸す卸売業にも広がり、多くの中小企業が苦境に立たされました。<br />
            こうした状況をなんとか打開しようと事業再構築補助金を検討している方も多いのではないでしょうか。<br />
            <br />
            本記事では、卸売業における事業再構築補助金の活用方法や申請傾向、採択事例など押さえておくべきポイントについて詳しく解説します。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業再構築補助金の対象となる卸売業者</span></li>
              <li><span>卸売業における事業再構築補助金の活用方法</span></li>
              <li><span>過去の卸売事業者採択例</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            <br />
            本記事は、最新の「第10回事業再構築補助金　公募要領」を元に作成しています。<br />
            <a href='https://www.meti.go.jp/covid-19/jigyo_saikoutiku/pdf/shishin_tebiki.pdf' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：「事業再構築指針の手引き」に関する記事
            </a><br />
            事業再構築について、より詳しい情報を知りたい方は当社にて無料相談を実施していますので、お気軽にお問い合わせください。<br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">事業再構築補助金の対象となる経費</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金の対象となる経費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">飲食事業での事業再構築類型の具体例</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金での申請例</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">卸売業での事業再構築類型の具体例</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">o	飲食事業者の申請傾向</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">o	具体的な採択事例</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">o	飲食事業の申請で抑えるべきポイント</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">事業再構築補助金での申請例</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.事業再構築補助金の対象となる経費</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築補助金の対象となる経費は以下の11種類に分類されます。<br />
            <br />
            <strong>①建物費②機械装置・システム構築費③技術導入費④専門家経費⑤運搬費⑥クラウドサービス利用費⑦外注費⑧知的財産権等関連経費⑨広告宣伝・販売促進費⑩研修費⑪廃業費（第10回公募要領より抜粋）</strong><br />
            <br />
            卸売業の場合、新事業として製造業や飲食業、小売業に進出するケースが多く見受けられるため、関係の深い経費としては以下のものが挙げられます。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>経費項目</th><th>詳細</th><th>具体例</th>
              </tr>
              <tr>
                <td>建物費</td>
                <td>事業で使用する事務所・施設の建設や改修にかかる経費。</td>
                <td>自社商品を製造するための工場建設費用。</td>
              </tr>
              <tr>
                <td>機械装置・システム構築費</td>
                <td>新事業に必要な産業機器や工作機器の導入費。システムの開発経費など。。</td>
                <td>・製造機器の導入費<br />・アナログ業務の負担軽減のためのシステム導入費<br />・ECサイトの構築費</td>
              </tr>
              <tr>
                <td>研修費</td>
                <td>新事業に必要な教育訓練や講座受講にかかる経費。</td>
                <td>従業員の教育費。</td>
              </tr>
              <tr>
                <td>広告宣伝・販売促進費</td>
                <td>事業で提供する製品・サービスに係る広告宣伝に要する経費</td>
                <td>飲食業や小売業に進出した際に必要な広告費。</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            事業再構築補助金の対象経費は種類が多く、複雑です。<br />
            また、対象外経費も細かく分類されているため、事前確認を入念に行い見落としがないよう注意が必要です。<br />
            <br />
            「経費が複雑でわかりにくい」「自社のサービスに該当する経費を詳しく知りたい」という方はぜひ当社までお問い合わせください。<br />
            <br />
            経費の確認や金額の算出を初回相談無料で対応させていただきます。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.卸売業での事業再構築類型の具体例</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築補助金を申請するためには、公募要領に定められている要件を満たす必要があります。<br />
            要件は、「新市場進出」「事業転換」「業種転換」の3つ類型があり、いずれかに当てはまっていれば要件を満たします。<br />
            <br />
            詳細は以下のとおりです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th>類型を満たすための要件</th><th>産業分類に基づく変更</th>
              </tr>
              <tr>
                <td>新市場進出</td>
                <td>
                  ①製品又は提供方法を大きく変更する<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全体の10%以上となる計画を策定する。
                </td>
                <td>無</td>
              </tr>
              <tr>
                <td>事業転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。
                </td>
                <td>主な事業を変更(中・小・細分類)</td>
              </tr>
              <tr>
                <td>業種転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。
                </td>
                <td>主な業種を変更<br />(大分類)</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            これらの3類型を卸売業に当てはめてみると次のとおりです。<br />
            <br />
            <strong>【卸売業：新市場進出の具体例】</strong><br />
            <br />
            新市場進出は、卸売業という業種はそのままで新たな事業を開始する場合に該当します。<br />
            たとえば、「個人事業者や小口の新規取引先を増やすためのECサイトを構築する」といったケースです。<br />
            <br />
            日本産業分類は卸売業のままで変化はありませんが、新たな販路拡大という点で要件を満たしています。<br />
            <br />
            <strong>【卸売業：事業転換の具体例】</strong><br />
            <br />
            卸売業という業界はそのままで、主な事業を変更する場合に該当します。<br />
            たとえば、「企業へ卸していた青果を一般消費者向けに販売する小売業への転換」などが挙げられます。<br />
            <br />
            日本産業分類では「52：飲食料品卸売業→58：飲食料品小売業」へと中分類が変更されています。<br />
            <br />
            大分類の業種はそのままで、事業を変更して取り組むケースが事業転換に該当します。<br />
            <br />
            <strong>【卸売業：業種転換の具体例】</strong><br />
            <br />
            卸売業から別業種に進出するのが業種転換です。<br />
            <br />
            たとえば、「食肉卸売業が自社製品を活用してレストラン経営を開始する」などが該当します。<br />
            <br />
            日本産業分類では、「I：卸売業、小売業→M：宿泊業、飲食サービス業」へと大分類が変更されています。<br />
            <br />
            このように別業界へ進出するケースが業種転換に該当します。<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>３.事業再構築補助金での申請例</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築の経費と要件が理解できたところで、続いては、卸売業の申請の流れや対象経費、補助金額について具体例を用いて解説します。<br />
            <br />
            事業規模や従業員数によって補助金額は変わりますので、これから解説する具体例はあくまでも参考としてご覧ください。<br />
            実際に申請する際は、しっかりと計画を立てたうえで対象経費、補助金額を算出してください。<br />
            <br />
            【事業内容】<br />
            <br />
            地元の飲食店をメインに食肉卸売業者として取引を行っていたが、新型コロナウイルスの影響で売上が大幅に減少。<br />
            販路拡大のため、自社で調理したオリジナル弁当の配食事業を開始し、売上の回復を目指す。<br />
            <br />
            事業場所：奈良県生駒市<br />
            従業員数：28名<br />
            売上高：20億円<br />
            <br />
            【日本産業分類から類型を決定】<br />
            <br />
            食肉卸売業から配食事業へ進出する今回のケースを日本産業分類で比較すると以下のとおりです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>日本産業分類</th><th>大分類</th><th>中分類</th><th>小分類</th><th>細分類</th>
              </tr>
              <tr>
                <td>既存事業</td><td>I：卸売業、小売</td><td>52：飲食料品卸売業</td><td>521：農畜産物、水産物卸売業</td><td>5215：食肉卸売業</td>
              </tr>
              <tr>
                <td>新規事業</td><td>M：宿泊業、飲食サービス業</td><td>77：持ち帰り、配達飲食サービス業</td><td>772：配達飲食サービス業</td><td>7721：配達飲食サービス業</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            大分類が「I：卸売業、小売→M：宿泊業、飲食サービス業」へと変更しているため、本事業の事業再構築類型は「業種転換」として申請を進めます。<br />
            <br />
            <strong>【売上高から申請枠を決定】</strong><br />
            <br />
            下表は2021年と2022年の売上を比較したものとなります。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'></th><th>7月</th><th>8月</th><th>9月</th><th>合計</th>
              </tr>
              <tr>
                <td>2021年</td><td>200,000</td><td>210,000</td><td>210,000</td><td>620,000</td>
              </tr>
              <tr>
                <td>2022年</td><td>170,000</td><td>175,000</td><td>170,000</td><td>515,000</td>
              </tr>
              <tr>
                <td>増減率(%)</td><td>-15%</td><td>-17%</td><td>-19%</td><td>-17%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            2022年の売上合計が前年より17％減少しています。<br />
            そのため、本事業は「物価高騰対策・回復再生応援枠」にて申請します。<br />
            <br />
            <strong>【補助金額の計算】</strong><br />
            <br />
            申請枠が決定したので、補助金額の計算へと進みます。<br />
            なお、本事業にかかる経費は以下のとおりです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>製造設備の導入費用</th><th>￥20,000,000</th>
              </tr>
              <tr>
                <td>配達用の車両購入費</td><td>￥5,000,000</td>
              </tr>
              <tr>
                <td>広告費</td><td>￥3,300,000</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            上記経費のうち、車両購入費は対象外となります。<br />
            そのため、本事業の申請金額は設備導入費と広告費を合わせた23,300,000円になります。<br />
            <br />
            第10回の公募要領によると中小企業の補助率は2/3。<br />
            加えて、従業員21〜50人の場合は800万円までが3/4になるため（本事業の従業員数は28人）、これらをもとに計算すると補助金額は以下のとおりです。<br />
            <br />
            （補助率　2/3）15,300,000×2/3＝10,200,000<br />
            （従業員数による補助率　3/4）8,000,000×3/4＝6,000,000<br />
            <br />
            補助金額の最大は16,200,000円になります。<br />
          </p>
          <h3 className='media_text_text_title'>虎の巻から見る卸売業業者の特徴</h3>
          <p className="media_text_text">
            虎の巻とは、事業再構築補助金に関する「事業検討の考え方」や「有望な事業に共通する特徴」を動画やガイドブックでまとめた資料です。<br />
            <br />
            事業再構築補助金について疑問や悩みを抱える経営者のために役立つ情報が盛り込まれていますので、ぜひご一読することをおすすめします。<br />
            <br />
            本記事においても虎の巻をもとに、<br />
            ・小売事業者の申請傾向<br />
            ・具体的な採択事例<br />
            ・小売事業の申請で抑えるべきポイント<br />
            について解説します。<br />
          </p>
          <h3 className='media_text_text_title'>１.卸売業の申請傾向</h3>
          <p className="media_text_text">
            <img src={Image1} className='media_img' alt='' /><br /> 
            卸売業の申請傾向は上図のとおりです。<br />
            表は縦軸が申請件数、横軸が採択率となっており、右上のオレンジ部分が有望度の高い事業ということになります。<br />
            <br />
            卸売業の場合、<br />
            ・飲食店や食品販売への展開<br />
            ・飲食業への展開<br />
            ・精肉・加工肉の製造販売への展開<br />
            ・青果の新規販売方法展開<br />
            <br />
            など、企業から一般消費者を対象とした事業への展開が多く見受けられ、いずれも申請数・採択率ともに高い傾向にあります。<br />
            新たな販路拡大が期待できることはもちろんのこと、既存の商品とノウハウを用いて事業展開ができる点が有望度の高さに反映されていると言えるでしょう。<br />
          </p>
          <h3 className='media_text_text_title'>２.具体的な採択事例</h3>
          <p className="media_text_text">
            卸売業における具体的な採択事例を紹介します。<br />
            <br />
            <strong>①青果卸業から、地元野菜を使った食品加工業への業態転換</strong><br />
            コロナ禍での外出自粛や休業要請によって、取引先の飲食店からの受注が減少。<br />
            地産地消に特化した調理済み食材の製造販売ができる環境を整備し、地元経済の活性化に貢献するとともに新たな販路の確保を目指した青果卸業者の採択事例です。<br />
            <br />
            卸売業は、販売先の小売店や飲食店が新型コロナウイルスの影響をダイレクトに受けたため、そのあおりを受けて苦境に立たされている事業者が数多くあります。<br />
            <br />
            そのため、事業再構築補助金では企業から一般消費者向けのサービスへチャレンジする卸売業者の申請が目立ちます。<br />
            <br />
            <strong>②水産物卸売業から水産食品製造業へのチャレンジ</strong><br />
            <br />
            水産物卸売業を営んでいた企業が工場設備を設けて、魚介類を加工する水産食品製造業を開始。<br />
            仕入から販売まで自社で完結する事業を確立し、収益アップを目指すために事業再構築補助金に申請して採択された事例です。<br />
            <br />
            自社で取り扱う商品を用いて新たな製造事業へチャレンジする事業者の採択事例が多いのも卸売業の特徴です。<br />
            知識やノウハウを活かすことができる新事業への進出は事業再構築補助金で採択されやすい傾向にあるため、申請を検討中の方は参考にしてみるといいでしょう。<br />
          </p>
          <h3 className='media_text_text_title'>３.具体的な採択事例</h3>
          <p className="media_text_text">
            <strong>①自社で取り扱う商品やノウハウを活かす</strong><br />
            <br />
            事業再構築補助金は、自社で取り扱う商品やノウハウを活かせる事業計画を立てて申請するのが重要なポイントのひとつです。<br />
            <br />
            なぜなら実現性が高く、採択されやすいから。<br />
            たとえば、「食品卸売業から美容室経営を始める」このような事業展開の場合、未知の分野であるため何の経験やノウハウもないため、すべてを一から築き上げていかなければなりません。<br />
            相当な手間と時間がかかるうえ、申請しても「本当に実現できる事業なのだろうか」と判断され、採択されない可能性が非常に高くなります。<br />
            <br />
            一方、「自社で取り扱う商品の販路を拡大するため、ECサイトを構築して全国展開する」といったケース。<br />
            この場合であれば、自社で取り扱う商品やノウハウをそのまま活かせるため、事業の見通しも立てやすく実現性も高いことから採択されやすくなります。<br />
            <br />
            <strong>②倒産のリスクを考えておく</strong><br />
            <br />
            事業再構築補助金は後払いが基本です。<br />
            そのため、新事業への投資はまず、自己資金もしくは融資で立て替えなければなりません。<br />
            <br />
            もし、手元資金がない状態で事業再構築補助金を申請し、採択されてしまうと、補助金が交付されるまでに資金繰りが厳しくなり倒産してしまう可能性があります。<br />
            事業再構築補助金を申請しても倒産してしまっては元も子もありません。<br />
            単に、補助金が欲しいからという安易な理由で申請するのは危険です。<br />
            <br />
            融資を受けるのであれば申請前に金融機関から内諾を得ておくこと<br />
            資金繰りは問題ないか確認すること<br />
            新事業に勝算があるのかしっかり計画を立てること<br />
            <br />
            申請の際はこれらのポイントをしっかり押さえておくことが重要です。<br />
          </p>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>４.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、卸売業における事業再構築補助金の活用方法や申請事例について解説しました。<br />
            <br />
            事業再構築補助金は、新型コロナウイルスによる売上減少から経営を立て直しを目指す方にぜひ活用していただきたい補助金のひとつです。<br />
            <br />
            一方で、事業再構築補助金の仕組みは複雑で、自力で申請するのはかなり困難です。<br />
            <br />
            分厚い公募要領を読んだうえで、事業計画を立てるのは膨大な時間と労力を要します。<br />
            <br />
            そのため、事業再構築補助金の申請に自信がないという方はぜひ当社にご相談ください。<br />
            <br />
            本記事を運営している会社であるトランク合同会社は現在、無料相談を実施しております。<br />
            <br />
            「複雑な手続きは専門家に任せたい」「専門家のアドバイスを聞きたい」という方は下記フォームよりお気軽にお問い合わせください。<br />
            <br />
            この度は、記事を拝見いただき誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


