import './styles.css';
import React from 'react-router-dom';

// import DocumentMeta from 'react-document-meta';
// import { FaCheck } from "react-icons/fa";
// import { BsFillSquareFill } from "react-icons/bs";

const BlogText0001 = () => {

  return (
    <>

    <div className="blog-body">

      <div className="blog-text-top"></div>

      <div className="blog-content">

        <h2 className="blog-title">利用規約</h2>
        
        <div className="blog-text">
          <p className="blog-text-text">
            memorii photo(メモリー フォト)（以下「当社」といいます。）は、顧客管理アプリケーションソフト「memorii photo」（以下「本サービス」といいます。）の運営において、利用企業・提携企業・団体等(以下「利用企業等」といいます)および本サービスのご利用になる方(以下「ユーザー」といいます)のプライバシーを尊重し、ユーザーの個人情報(以下の定義に従います)の管理に細心の注意を払い、これを取り扱うものとします。<br />
            <br />
            <strong>１. 個人情報の定義</strong><br />
            個人情報とは、生存する個人に関する情報であって、当該情報を構成する氏名、住所、生年月日その他の記述等により特定の個人を識別することができるものをいいます。また、その情報のみでは識別できない場合でも、他の情報と容易に照合することができ、それにより特定の個人を識別できるものも個人情報に含まれます。<br />
            <br />
            <strong>２. 個人情報の利用目的</strong><br />
            個人情報の利用目的は以下の通りです。利用目的を超えて利用することはありません。<br />
            <br />
            A. ユーザーの個人認証及びユーザー向け本サービスの提供<br />
            B. 本サービスの利用に伴う連絡・メールマガジン・DM・各種お知らせ等の配信・送付<br />
            C. ユーザーの承諾・申込みに基づく、本サービス利用企業等への個人情報の提供<br />
            D. 属性情報･端末情報・位置情報・行動履歴等に基づく広告・コンテンツ等の配信・表示、本サービスの提供<br />
            E. 本サービスの改善・新規サービスの開発・ポイント利用状況の調査・分析およびマーケティング<br />
            F. キャンペーン・アンケート・モニター・取材等の実施<br />
            G. 空メール送信者に対するURL情報の配信<br />
            H. 本サービスに関するご意見、お問い合わせ、クチコミ投稿内容の確認・回答<br />
            I. 利用規約等で禁じている、商用・転用目的での各種申込行為、各種多重申込、権利譲渡、虚偽情報登録などの調査と、それに基づく当該申込内容の詳細確認<br />
            <br />
            <strong>３. 個人情報の第三者への提供</strong><br />
            当社は、原則として、ユーザー本人の同意を得ずに個人情報を第三者に提供しません。提供先・提供情報内容を特定したうえで、ユーザーの同意を得た場合に限り提供します。<br />
            <br />
            (1)提供先および目的について<br />
            資料請求・応募・予約・購入等のサービス提供のため、本サービス利用企業等への提供<br />
            (2)提供する個人情報の項目<br />
            ユーザーから取得した情報（サービス利用履歴ほか、閲覧・検索・ブックマーク等あらゆる行動履歴に該当する情報を含む）のうち、利用目的の達成に必要な範囲の情報項目とします。<br />
            (3)提供の手段又は方法<br />
            書面もしくは電磁的な方法による送付または送信。<br />
            ただし、以下の場合は、関係法令に反しない範囲で、ユーザーの同意なく個人情報を提供することがあります。<br />
            <br />
            A. ユーザーが第三者に不利益を及ぼすと判断した場合<br />
            B. 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ユーザー本人の承諾を得ることが困難である場合<br />
            C. 国の機関若しくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合で、ユーザー本人の同意を得ることによりその事務の遂行に支障を及ぼすおそれがある場合<br />
            D. 裁判所、検察庁、警察またはこれらに準じた権限を有する機関から、個人情報についての開示を求められた場合<br />
            E. ユーザー本人から明示的に第三者への開示または提供を求められた場合<br />
            F. 法令により開示または提供が許容されている場合<br />
            G. 合併その他の事由による事業の承継に伴い個人情報を提供する場合であって、承継前の利用目的の範囲で取り扱われる場合<br />
            <br />
            <strong>４．第三者提供に関する免責事項</strong><br />
            以下の場合は、第三者による個人情報の取得に関し、当社は何らの責任を負いません。<br />
            <br />
            A. ユーザー自らが本サービスの機能または別の手段を用いて利用企業等に個人情報を明らかにする場合（なお、利用企業等における個人情報の取扱いについては、各利用企業に直接お問合せください）<br />
            B. 本サービスに入力した情報により、期せずして本人が特定できてしまった場合<br />
            C. 本サービスからリンクされる外部サイトにおいて、ユーザーより個人情報が提供され、またそれが利用された場合<br />
            D. ユーザー本人以外がユーザー個人を識別できる情報（ID・パスワード等）を入手した場合<br />
            <br />
            <strong>５．個人情報処理の外部委託</strong><br />
            当社は、個人情報取扱い業務の一部または全部を外部委託することがあります。なお、委託先における個人情報の取扱いについては当社が責任を負います。<br />
            <br />
            <strong>６．統計処理されたデータの利用</strong><br />
            当社は、提供を受けた個人情報をもとに、個人を特定できないよう加工した統計データを作成することがあります。個人を特定できない統計データについては、当社は何ら制限なく利用することができるものとします。<br />
            <br />
            <strong>７．個人情報の変更等</strong><br />
            原則としてユーザー本人に限り、「個人情報の利用目的」の通知、登録した個人情報の開示、訂正、追加または削除、利用停止、ならびに第三者への提供の停止（以下「個人情報の変更等」といいます）を求めることができるものとします。具体的な方法については下記の個人情報管理の問い合わせ先にご連絡ください｡ただし、以下の場合は個人情報の変更等に応じないことがあります。<br />
            <br />
            A. ユーザー本人または第三者の生命、身体、財産その他の権利・利益を害するおそれがある場合<br />
            B. 本サービスの適正な実施に著しい支障を及ぼすおそれがある場合<br />
            C. 他の法令に違反することとなる場合<br />
            また、当該個人情報の変更等に多額の費用を要する場合､その他の、個人情報の変更等を行うことが困難な場合であって、ユーザーの権利・利益を保護するため必要なこれに代わるべき措置をとるときは、個人情報の変更等に応じないことがあります。<br />
            <br />
            <strong>８．個人情報の正確性</strong><br />
            当社は、ご提供いただいた個人情報を正確にデータ処理するように努めます。ただし、ご提供いただいた個人情報の内容が正確かつ最新であることについては、ユーザーが責任を負うものとします。<br />
            <br />
            <strong>９．機微な個人情報の取得制限</strong><br />
            当社は、次に示す内容を含む個人情報の取得は原則として行いません。ただし、ユーザーが自ら提供した場合は、この限りではありません。<br />
            <br />
            A. 思想、信条及び宗教に関する事項<br />
            B. 人種、民族、門地、本籍地（所在都道府県に関する情報を除く）、身体・精神障害、犯罪歴、その他社会的差別の原因となる事項<br />
            C. 勤労者の団結権、団体交渉及びその他団体行動の行為に関する事項<br />
            D. 集団示威行為への参加、請願権の行使、及びその他政治的権利の行使に関する事項<br />
            E. 保健医療及び性生活<br />
            <br />
            <strong>１０.法令、国が定める方針及びその他の規範について</strong><br />
            当社は、個人情報の保護に関係する日本の法令、国が定める方針及びその他の規範を遵守します。<br />
            <br />
            <strong>１１.個人情報保護マネジメントシステムの継続的改善</strong><br />
            当社は、社会情勢の変化を踏まえ、個人情報保護マネジメントシステムを定期的に見直し、継続的に改善します。<br />
            <br />
            <strong>１２.本ポリシーについて</strong><br />
            本ポリシーは、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて本ポリシーを変更することがあります。<br />
          </p>
        </div>

        <br />
        <br />
        <br />

      </div>

      <div className="blog-space-30"></div>

    </div>
    </>
  );
}

export default BlogText0001;

// Colfax-Bold, Helvetica, Arial, sans-serif;