import './styles.css';

const BlogText0001 = () => {

  return(
  <>
  <div className='management_review_header'>
    <h3>simulator</h3>
    <h4>経営事項審査『ケイシン』</h4>
    <p>従来であれば経営審査の申請を行うことで結果を見ることが出来ますが、このサイトでは40問の質問・10分で経営審査の結果を調べることが出来ます。</p>
  </div>
  </>
  );
}

export default BlogText0001;
