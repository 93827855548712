// export PATH="$HOME/.npm-global/bin:$PATH"
// import "./styles/style.scss"
// gsutil cors set cors.json gs://trankllc-com.appspot.com/
// https://console.firebase.google.com/u/0/project/photo-beauty-24f63/firestore/data/~2F?hl=ja

import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';

import Home from './component/home/index';
import Company from './component/company/index';
import Contact from './component/contact/index';

import Privacy from './component/memorii/app/privacy/index';
import Terms from './component/memorii/app/terms/index';

import Help from './component/subsidy/help/index';
import Photo from './component/memorii/photo/index';
import PhotoList from './component/memorii/photolist/index';

import Task from './component/memorii/task/index';
import Hojokin from './component/subsidy/saikoutiku/index';
import Saikoutiku from './component/subsidy/hojokin/index';
import simulator from './component/subsidy/simulator/onePage/index';
import simulatorResult from './component/subsidy/simulator/twoPage/index';
import Tournament from './component/memorii/tournament/index';
import ApiArticleSubsidy from './component/media/api/subsidy/article/index';
import ApiPotalSubsidy from './component/media/api/subsidy/portal/index';

import ApisRegistration from './component/media/api/construction/registration/index';
import bidHome from './component/media/api/construction/home/index';
import bidResults from './component/media/api/construction/results/index';
import bidArticle from './component/media/api/construction/article/index';

import managementReview from './component/media/api/construction/management/index';

// bussiness
import Bussiness from './component/media/subsidy/1/index';
import Bussiness0001 from './component/media/subsidy/1/article/0001';
import Bussiness0002 from './component/media/subsidy/1/article/0002';
import Bussiness0003 from './component/media/subsidy/1/article/0003';
import Bussiness0004 from './component/media/subsidy/1/article/0004';
import Bussiness0005 from './component/media/subsidy/1/article/0005';
import Bussiness0006 from './component/media/subsidy/1/article/0006';
import Bussiness0007 from './component/media/subsidy/1/article/0007';
import Bussiness0008 from './component/media/subsidy/1/article/0008';
import Bussiness0009 from './component/media/subsidy/1/article/0009';
import Bussiness0010 from './component/media/subsidy/1/article/0010';
import Bussiness0011 from './component/media/subsidy/1/article/0011';
import Bussiness0012 from './component/media/subsidy/1/article/0012';
import Bussiness0016 from './component/media/subsidy/1/article/0016';
import Bussiness0017 from './component/media/subsidy/1/article/0017';
import Bussiness0018 from './component/media/subsidy/1/article/0018';
import Bussiness0019 from './component/media/subsidy/1/article/0019';
import Bussiness0020 from './component/media/subsidy/1/article/0020';
import Bussiness0021 from './component/media/subsidy/1/article/0021';
import Bussiness0022 from './component/media/subsidy/1/article/0022';
import Bussiness0023 from './component/media/subsidy/1/article/0023';
import Bussiness0024 from './component/media/subsidy/1/article/0024';
import Bussiness0025 from './component/media/subsidy/1/article/0025';
import Bussiness0026 from './component/media/subsidy/1/article/0026';
import Bussiness0027 from './component/media/subsidy/1/article/0027';
import Bussiness0028 from './component/media/subsidy/1/article/0028';
import Bussiness0029 from './component/media/subsidy/1/article/0029';
import Bussiness0030 from './component/media/subsidy/1/article/0030';
import Bussiness0031 from './component/media/subsidy/1/article/0031';
import Bussiness0032 from './component/media/subsidy/1/article/0032';
import Bussiness0033 from './component/media/subsidy/1/article/0033';
import Bussiness0034 from './component/media/subsidy/1/article/0034';
import Bussiness0035 from './component/media/subsidy/1/article/0035';
import Bussiness0036 from './component/media/subsidy/1/article/0036';
import Bussiness0039 from './component/media/subsidy/1/article/0039';
import Bussiness0040 from './component/media/subsidy/1/article/0040';
import Bussiness0041 from './component/media/subsidy/1/article/0041';
import Bussiness0042 from './component/media/subsidy/1/article/0042';
import Bussiness0043 from './component/media/subsidy/1/article/0043';
import Bussiness0044 from './component/media/subsidy/1/article/0044';
import Bussiness0045 from './component/media/subsidy/1/article/0045';
import Bussiness0046 from './component/media/subsidy/1/article/0046';
import Bussiness0047 from './component/media/subsidy/1/article/0047';
import Bussiness0049 from './component/media/subsidy/1/article/0049';

// ものづくり
import Facility0001 from './component/media/subsidy/2/article/0001';
import Facility0002 from './component/media/subsidy/2/article/0002';
import Facility0003 from './component/media/subsidy/2/article/0003';
import Facility0004 from './component/media/subsidy/2/article/0004';
import Facility0005 from './component/media/subsidy/2/article/0005';

// 小規模
import Small0001 from './component/media/subsidy/3/article/0001';
import Small0002 from './component/media/subsidy/3/article/0002';
import Small0003 from './component/media/subsidy/3/article/0003';
import Small0004 from './component/media/subsidy/3/article/0004';

// 省力化
import saveLabor0001 from './component/media/subsidy/4/article/0001';

// memorii
import memorii0001 from './component/media/memorii/article/0001';
import memoriiUpdate from './component/memorii/update/index';

// 社員ブログ
import Blog0001 from './component/media/company/0001';
import Blog0002 from './component/media/company/0002';
import Blog0003 from './component/media/company/0003';

import masato from './component/masato/index';
import test from './component/test/index';

// 美容室向けサービス
// import Recruit from './component/memorii/recruit/index';
// import SalonContent from './component/memorii/salon/content/index';
// import SalonLogin from './component/memorii/salon/login/index';

function App() {
  return (
    <>
    <BrowserRouter>

      <Route exact path="/masato" component={masato} />
      <Route exact path="/test" component={test} />
      <Route exact path="/memoriiUpdate" component={memoriiUpdate} />

      <Route exact path="/" component={Home} />
      <Route path='/company' component={Company} />
      <Route path='/contact' component={Contact} />

      <Route path='/privacy' component={Privacy} />
      <Route path='/terms' component={Terms} />

      <Route path='/help' component={Help} />
      <Route path='/photo' component={Photo} />
      <Route path='/photolist' component={PhotoList} />
      
      {/* <Route path='/recruit' component={Recruit} /> */}
      {/* <Route path='/salon' component={SalonContent} /> */}
      {/* <Route path='/salonLogin' component={SalonLogin} /> */}
      <Route path='/task/:id' component={Task} />
      <Route path='/saikoutiku' component={Saikoutiku} />
      <Route path='/hojokin' component={Hojokin} />
      <Route path='/simulator' component={simulator} />
      <Route path='/simulatorResult/:data' component={simulatorResult} />
      <Route path='/tournament' component={Tournament} />

      <Route path='/media/subsidies/:id' component={ApiArticleSubsidy} />
      <Route path='/media/potal/subsidy/:prefecture' component={ApiPotalSubsidy} />

      <Route path='/bid/home' component={bidHome} />
      <Route path='/bid/results/:keyword' component={bidResults} />
      <Route path='/bid/article/:id' component={bidArticle} />
      <Route path='/media/registration' component={ApisRegistration} />
      <Route path='/management/review' component={managementReview} />

      {/* 社員ブログ */}
      <Route path='/media/company/0001' component={Blog0001} />
      <Route path='/media/company/0002' component={Blog0002} />
      <Route path='/media/company/0003' component={Blog0003} />

      {/* media */}
      <Route path='/media/subsidy/business/0000' component={Bussiness} />
      <Route path='/media/subsidy/business/0001' component={Bussiness0001} />
      <Route path='/media/subsidy/business/0002' component={Bussiness0002} />
      <Route path='/media/subsidy/business/0003' component={Bussiness0003} />
      <Route path='/media/subsidy/business/0004' component={Bussiness0004} />
      <Route path='/media/subsidy/business/0005' component={Bussiness0005} />
      <Route path='/media/subsidy/business/0006' component={Bussiness0006} />
      <Route path='/media/subsidy/business/0007' component={Bussiness0007} />
      <Route path='/media/subsidy/business/0008' component={Bussiness0008} />
      <Route path='/media/subsidy/business/0009' component={Bussiness0009} />
      <Route path='/media/subsidy/business/0010' component={Bussiness0010} />
      <Route path='/media/subsidy/business/0011' component={Bussiness0011} />
      <Route path='/media/subsidy/business/0012' component={Bussiness0012} />
      <Route path='/media/subsidy/business/0016' component={Bussiness0016} />
      <Route path='/media/subsidy/business/0017' component={Bussiness0017} />
      <Route path='/media/subsidy/business/0018' component={Bussiness0018} />
      <Route path='/media/subsidy/business/0019' component={Bussiness0019} />
      <Route path='/media/subsidy/business/0020' component={Bussiness0020} />
      <Route path='/media/subsidy/business/0021' component={Bussiness0021} />
      <Route path='/media/subsidy/business/0022' component={Bussiness0022} />
      <Route path='/media/subsidy/business/0023' component={Bussiness0023} />
      <Route path='/media/subsidy/business/0024' component={Bussiness0024} />
      <Route path='/media/subsidy/business/0025' component={Bussiness0025} />
      <Route path='/media/subsidy/business/0026' component={Bussiness0026} />
      <Route path='/media/subsidy/business/0027' component={Bussiness0027} />
      <Route path='/media/subsidy/business/0028' component={Bussiness0028} />
      <Route path='/media/subsidy/business/0029' component={Bussiness0029} />
      <Route path='/media/subsidy/business/0030' component={Bussiness0030} />
      <Route path='/media/subsidy/business/0031' component={Bussiness0031} />
      <Route path='/media/subsidy/business/0032' component={Bussiness0032} />
      <Route path='/media/subsidy/business/0033' component={Bussiness0033} />
      <Route path='/media/subsidy/business/0034' component={Bussiness0034} />
      <Route path='/media/subsidy/business/0035' component={Bussiness0035} />
      <Route path='/media/subsidy/business/0036' component={Bussiness0036} />
      <Route path='/media/subsidy/business/0039' component={Bussiness0039} />
      <Route path='/media/subsidy/business/0040' component={Bussiness0040} />
      <Route path='/media/subsidy/business/0041' component={Bussiness0041} />
      <Route path='/media/subsidy/business/0042' component={Bussiness0042} />
      <Route path='/media/subsidy/business/0043' component={Bussiness0043} />
      <Route path='/media/subsidy/business/0044' component={Bussiness0044} />
      <Route path='/media/subsidy/business/0045' component={Bussiness0045} />
      <Route path='/media/subsidy/business/0046' component={Bussiness0046} />
      <Route path='/media/subsidy/business/0047' component={Bussiness0047} />
      <Route path='/media/subsidy/business/0049' component={Bussiness0049} />
      
      <Route path='/media/subsidy/facility/0001' component={Facility0001} />
      <Route path='/media/subsidy/facility/0002' component={Facility0002} />
      <Route path='/media/subsidy/facility/0003' component={Facility0003} />
      <Route path='/media/subsidy/facility/0004' component={Facility0004} />
      <Route path='/media/subsidy/facility/0005' component={Facility0005} />

      <Route path='/media/subsidy/small/0001' component={Small0001} />
      <Route path='/media/subsidy/small/0002' component={Small0002} />
      <Route path='/media/subsidy/small/0003' component={Small0003} />
      <Route path='/media/subsidy/small/0004' component={Small0004} />
      
      <Route path='/media/subsidy/saveLabor/0001' component={saveLabor0001} />

      <Route path='/media/memorii/0001' component={memorii0001} />

    </BrowserRouter>
    </>
  );
}

export default App;


// // "start": "react-scripts start functions-framework --target=updateHashTags",