import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/5/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」提出書類の用意を３日で終わらせる方法！',
    description: '本記事は、申請に必要な書類とその書類の説明をまとめております。その他、申請枠別の必要資料もまとめております。本記事は、「事業再構築補助金の申請に必要な書類が知りたい」という方へ向けて、提出書類一覧をまとめさせていただきました。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」提出書類の用意を３日で終わらせる方法！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            こんにちは。<strong>事業再構築補助金の申請サポートをしているトランク合同会社</strong>です。<br />
            このサイトでは、「初めての方が補助金を申請できるようになること」を目的として作成していますので、もし、この記事や補助金に関するご相談がございましたら、ぜひ気軽にご連絡いただければと思います。<br />
            <br />
            この記事では、<strong>が絶対に提出しなければいけない提出書類と申請枠ごとに必要となる提出書類の2つに分けて、解説していきます</strong>ので、申請するに当たって、漏れがないか、申請準備を今から行う方は、ぜひ参考にしていただければと思います。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業再構築補助金の申請に必要な書類</span></li>
              <li><span>特別枠で申請するために必要な書類</span></li>
              <li><span>加点を得るために必要な書類</span></li>
            </ul>
          </div>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">申請書類の概要</AnchorLink></li>
                <li><AnchorLink href="#2" offset="100">事業再構築補助金の申請に必要な書類</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">その他提出の必要が生じる書類</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">その他提出の必要が生じる書類</AnchorLink></li>
                <li><AnchorLink href="#5" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.申請書類の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築補助金の申請書類は、大きく分けて以下の3つに分類されます。<br />
            <strong>① 事業再構築補助金の申請に必要な書類</strong><br />
            <strong>② 特別枠での提出に必要な追加書類</strong><br />
            <strong>③ 加点項目のために提出する書類</strong><br />
            <br />
            その他、適宜追加書類が必要になる場合もございますが、まずは上記３つの書類を抑えていくことが必要になります。<br />
            <br />
            申請までのスケジュールにもよりますが、<strong>①→②→③の順番で書類準備を行うことをおすすめしております</strong>ので、申請を行う際には参考にしてください。<br />
            <br />
            それでは、申請書類の具体的な説明を行わせていただきます！<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.事業再構築補助金の申請に必要な書類</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、<strong>事業再構築補助金の申請に必要な書類</strong>は、以下の7つになります。<br />
            <br />
            <strong>申請に必要な書類</strong><br />
            ・事業計画書<br />
            ・認定経営革新等支援機関・金融機関 による確認書<br />
            ・決算書等<br />
            ・経済産業省ミラサポ plus「電子申請 サポート」により作成した事業財務 情報<br />
            ・従業員数を示す書類<br />
            ・収益事業を行っていることを説明する書類<br />
            ・建物の新築が必要であることを説明する書類（条件付き）<br />
            ・リース会社と共同申請を行う場合の提出書類（条件付き）<br />
            ・複数の事業者が連携して事業に取り組む場合の提出書類（条件付き）<br />
            ・組合特例を用いる場合の提出書類（条件付き）<br />
            <br />
            上記の順に説明していきますので、順を追ってご確認いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>事業計画書</h3><br />
          <p className="media_text_text">
            事業計画書とは、補助金を使用して行いたい事業内容やそれに基づく投資内容、事業の売上見込み等をまとめた書類です。<br />
            また、提出した事業計画書を元に補助金の採択/不採択を審査いたします。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>事業計画書に関するポイント</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>補助金額1500万円以上であれば、15ページ以内。補助金額1500万円以下であれば、10ページ以内。</span></li>
              <li><span>電子申請を行う際は、PDFにて添付。</span></li>
              <li><span>認定経営革新等支援機関とご相談のうえ、作成する必要がある。</span></li>
            </ul>
          </div>
          <p className="media_text_text">
            ※認定経営革新等支援機関とご相談のうえ、作成するというのは、認定経営革新等支援機関の確認証の提出が必要となるためですので、作成を自分自身で行い、確認証の発行を依頼するという形でも問題ございません。（次項で詳しく説明）<br />
            <br />
            下記の記事にて、<strong>事業計画書作成の詳細な作成方法を解説しております</strong>ので、補助金の計画書を行ったことのない方は、是非ご参照ください！<br />
            詳細記事：<a href='https://trankllc.com/media/subsidy/business/0021' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」事業計画書の作成は、この４点を抑える！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>認定経営革新等支援機関・金融機関による確認書</h3><br />
          <p className="media_text_text">
            認定経営革新等支援機関・金融機関による確認書とは、<strong>認定支援機関が作成に関与した事実を証明するもの</strong>となっております。<br />
            <br />
            なお、認定支援機関等による確認書を作成する際には複数の注意点がございますので、下記の注意点をご確認のうえ、依頼をお願いします。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>事業計画書に関するポイント</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>補助金額が3,000万円を超える場合は、追加で「金融機関による確認書」を作成する必要がある。</span></li>
              <li><span>複数の事業者様が共同で申請を行う場合は、「認定支援機関による確認書」の提出は任意となるが、「金融機関による確認書」の提出は必要。</span></li>
            </ul>
          </div>
          <p className="media_text_text">
            ※金融機関が認定支援機関を兼ねている場合は、金融機関に発行を依頼することで省略することが出来ますので、金融機関に依頼することをオススメします。<br />
            <br />
            認定経営革新等支援機関による確認書は以下のURlの<strong>「認定経営革新等支援機関による確認書」のwordを認定経営革新等支援機関に発行していただく</strong>事で要件を満たすことができます。<br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              「認定経営革新等支援機関による確認書」をダウンロード
            </a><br />
          </p>
          <h3 className='media_text_text_title'>決算書</h3><br />
          <p className="media_text_text">
            事業者の財務情報を確認するために、決算書一式が必要になります。具体的な決算書類としては、過去2年間分の以下書類が必要になります。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>決算書に含む必要がある書類</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>貸借対照表</span></li>
              <li><span>損益計算書（特定非営利活動法人の場合、活動計算書）</span></li>
              <li><span>製造原価報告書（※）</span></li>
              <li><span>販売管理費明細（※）</span></li>
              <li><span>個別注記表</span></li>
            </ul>
          </div>
          <p className='media_text_text'>
            ※がついている書類は、従来から作成している場合のみ提出をお願いします。<br />
            <br />
            <strong>創業したての事業者さま</strong><br />
            また、過去2年分の書類が提出できない事業者の方は、以下の場合に準じて提出書類を準備してください。<br />
            <br />
            <strong>設立から1年以上2年未満の場合…1期分の決算書一式を提出してください。</strong><br />
            <strong>設立から1年未満の場合…法人全体の事業計画書及び収支予算書を提出してください。</strong><br />
          </p>
          <h3 className='media_text_text_title'>ミラサポplus「電子申請サポート」の事業財務情報</h3><br />
          <p className="media_text_text">
            本書類は、<strong>ミラサポplusという補助金支援サイトで作成する必要があります</strong>。下記にURLを添付いたしますので、申請を考えている事業者様はご確認ください。<br />
            <br />
            なお、<strong>サイトで作成した事業財務情報を、ブラウザの印刷機能を用いてPDF出力し、それを添付することで提出完了となります</strong>。<br />
            ※作成方法や出力方法に関しては、下記URLの「ミラサポplusの操作マニュアル」をご参考ください。<br />
            <br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0034' target="_blank" rel="nofollow noopener noreferrer">
              ミラサポplusサイト
            </a><br />
            リンク：<a href='https://mirasapo-plus.go.jp/' target="_blank" rel="nofollow noopener noreferrer">
              ミラサポplusサイト
            </a><br />
            リンク：<a href='https://mirasapo-plus.go.jp/wordpress/wp-content/uploads/2021/07/30151414/mirasapo-manual0730.pdf' target="_blank" rel="nofollow noopener noreferrer">
              ミラサポplusの操作マニュアル
            </a><br />
          </p>
          <h3 className='media_text_text_title'>従業員数を示す書類（労働者名簿）</h3><br />
          <p className="media_text_text">
            事業再構築補助金は、従業員数によって補助上限金額や補助率が変化します。そのため、<strong>従業員数を示す書類である、労働者名簿の提出が必要となります</strong>。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>労働者名簿の記載必須事項</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>労働者名簿作成日</span></li>
              <li><span>氏名（漢字・カナ）</span></li>
              <li><span>生年月日</span></li>
              <li><span>雇い入れ月日</span></li>
              <li><span>年齢</span></li>
              <li><span>性別</span></li>
              <li><span>取り組む業務（営業・開発等）</span></li>
              <li><span>雇用形態</span></li>
            </ul>
          </div>
          <p className="media_text_text">
            また、以下の場合は異なる対応が必要になりますので、下記に該当する事業者様はご確認のうえ、提出をお願いします。<br />
            <br />
            ・<strong>従業員がいない場合…従業員がいない旨を説明する書類を作成してください。</strong><br />
            ・<strong>最低賃金枠に申請する場合…最低賃金要件の対象となる間の労働者名簿を追加で作成してください。（変更がない場合は必要ございません）</strong><br />
          </p>
          <h3 className='media_text_text_title'>収益事業を行っていることを説明する書類</h3><br />
          <p className="media_text_text">
            決算書のほとんど同一のものとなりますが、収益事業を行なっていることの書類として以下の書類を提出する必要がございます。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>法人の場合の必要書類</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>直近の確定申告書別表一の控え</span></li>
              <li><span>法人事業概況説明書の控え</span></li>
            </ul>
          </div>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>個人の場合の必要書類</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>直近の確定申告書第一表の控え</span></li>
              <li><span>所得税青色申告決算書の控え</span></li>
            </ul>
          </div>
          <p className="media_text_text">
            本書類も決算書と同様に顧問税理士がいる方は税理士へご相談いただくことでスムーズに用意できるかと思います。<br />
          </p>
          <h3 className='media_text_text_title'>建物の新築が必要であることを説明する書類</h3><br />
          <p className="media_text_text">
            本書類は上記に示した通り、例外的に必要な書類となっており、<strong>経費に新築を含ませる場合に必要なもの</strong>となっております。<br />
            <br />
            新築の必要性に関する説明書は以下のURlの<strong>「新築の必要性に関する説明書」のwordをご記載いただきご提出すること</strong>で要件を満たすことができます。<br />
            <br />
            本書類にて新築の経費計上が認められるかが判断されますので、丁寧に記載することをオススメします。<br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              「新築の必要性に関する説明書」をダウンロード
            </a><br />
          </p>
          <h3 className='media_text_text_title'>リース会社と共同申請を行う場合の提出書類</h3><br />
          <p className="media_text_text">
            機械装置・システム構築費を計上する際、中小企業等がリース会社と共同申請することが可能となっており、リース会社を対象に補助金が交付される場合があります。その際、以下2点の追加提出書類が必要となります。<br />
            <br />
            ① <strong>リース料軽減計算書</strong><br />
            →共同申請の証拠として、（公社）リース事業協会が確認した上記書類の提出が必要となります。詳しくは、リース会社にお問い合わせください。<br />
            <br />
            ② <strong>リース取引に係る宣誓書</strong><br />
            →リース会社が適切に取引を行うことについての宣誓を行うものとなります。宣誓書は下記リンクの「リース取引に係る宣誓書」をダウンロードして作成ください。<br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              「リース取引に係る宣誓書」のダウンロード
            </a><br />
          </p>
          <h3 className='media_text_text_title'>複数の事業者が連携して事業に取り組む場合の提出書類</h3><br />
          <p className="media_text_text">
            事業再構築に取り組むうえで、連携帯を構成して複数の事業者で事業推進を行う場合、以下2点の書類が必要となります。<br />
            <br />
            ① <strong>連携の必要性を示す書類</strong><br />
            →各事業者の名前や役割、必要不可欠性を記載する書類となっています。なお、書類は下記リンクの「連携の必要性を示す書類（代表申請者用）」をダウンロードして作成ください。<br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              「連携の必要性を示す書類（代表申請者用）」のダウンロード
            </a><br />
            <br />
            ② <strong>連携体の構成員それぞれが事業再構築要件を満たすことを説明する書類</strong><br />
            →連携体の構成員が作成する書類となっています。なお、書類は下記リンクの「連携体各者の事業再構築要件についての説明書類（連携体構成員用）」をダウンロードして作成ください。<br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              「連携体各者の事業再構築要件についての説明書類（連携体構成員用）」のダウンロード
            </a><br />
          </p>
          <h3 className='media_text_text_title'>組合特例を用いる場合の提出書類</h3><br />
          <p className="media_text_text">
            事業再構築に組合特例を用いて申請する場合は、以下の書類が必要となります。<br />
            <br />
            ・ 組合特例に関する確認書<br />
            →所属する組合が作成する書類となっています。なお、書類は下記リンクの「組合特例に関する確認書」をダウンロードして作成ください。<br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              「組合特例に関する確認書」のダウンロード
            </a><br />
          </p>
          <h3 className='media_text_text_title'>提出書類の一覧</h3><br />
          <p className="media_text_text">
            事業再構築補助金の申請では以下の書類が必要となりますので、提出前には必ず書類が揃っているかを確認し申請を行なっていただければと思います。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>事業再構築補助金の申請に必要な書類（法人の場合）</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業計画書</span></li>
              <li><span>認定経営革新等支援機関・金融機関による確認書</span></li>
              <li><span>決算書等</span></li>
              <li><span>経済産業省ミラサポ plus「電子申請 サポート」により作成した事業財務情報</span></li>
              <li><span>従業員数を示す書類</span></li>
              <li><span>収益事業を行っていることを説明する書類</span></li>
              <li><span>建物の新築が必要であることを説明する書類（条件付き）</span></li>
              <li><span>リース会社と共同申請を行う場合の提出書類（条件付き）</span></li>
              <li><span>複数の事業者が連携して事業に取り組む場合の提出書類（条件付き）</span></li>
              <li><span>組合特例を用いる場合の提出書類（条件付き）</span></li>
            </ul>
          </div>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.その他提出の必要が生じる書類</h2>
          </div>
          <p className="media_text_text">
            <br />
            申請枠ごとに必要となる提出書類をまとめましたので、ご覧いただければと思います。<br />
            どの申請枠にするか迷っている方は、以下の記事にて申請枠をご確認したのちに本記事を確認いただければと思います。<br />
            詳しい記事<a href='https://trankllc.com/media/subsidy/business/0002' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」5分で対象者の条件・要件を理解する！
            </a><br />
            <br />
            各申請枠ごとに必要な提出書類は以下の通りです。<br />
            <br />
            <strong>成長枠</strong><br />
            ・給与総額増加要件を満たすことを説明する書類<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0033' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」給与総額増加要件は給与支給を年率2%増加
            </a><br />
            ・市場拡大要件を満たすことの説明書<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0025' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」市場規模が拡大してれば成長枠！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0032' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」市場規模が拡大していれば市場拡大要件！
            </a><br />
            <br />
            <strong>産業構造転換枠</strong><br />
            ・給与総額増加要件を満たすことを説明する書類<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0033' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」給与総額増加要件は給与支給を年率2%増加
            </a><br />
            ・市場縮小要件を満たすことの説明書<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0026' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」市場縮小で悩んでいる企業様は産業構造転換枠！
            </a><br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0026' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」市場規模が縮小していれば市場縮小要件！
            </a><br />
            <br />
            <strong>グリーン枠</strong><br />
            ・給与総額増加要件を満たすことを説明する書類<br />
            ・研究開発・技術開発計画書又は人材育成計画書<br />
            詳しい記事：<a href='https://trankllc.com/media/subsidy/business/0033' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」給与総額増加要件は給与支給を年率2%増加
            </a><br />
            <br />
            <strong>物価高騰対策・回復再生応援枠</strong><br />
            ・売上高等減少を示す書類（決算書、事業概況書など）<br />
            <br />
            <strong>最低賃金枠</strong><br />
            ・賃金台帳<br />
            ・売上高等減少を示す書類（決算書、事業概況書など）<br />
            <br />
            この提出書類には、各申請枠に課せられている要件の理解が同時に必要になります。以下に申請枠ごとの要件をまとめましたので、申請枠と提出書類を見比べていただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>通常枠</h3>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_3'>項目</th><th className='media_table_0001_5_1'>成長枠</th><th className='media_table_0001_5_1'>産業構造転換枠</th><th className='media_table_0001_5_1'>グリーン枠</th><th className='media_table_0001_5_1'>物価高騰対策・回復再生応援枠</th><th className='media_table_0001_5_1'>グリーン枠</th><th className='media_table_0001_5_1'>最低賃金枠</th>
              </tr>
              <tr>
                <td><strong>給与総額増加要件</strong></td>
                <td>○</td>
                <td>○</td>
                <td>○</td>
                <td>×</td>
                <td>×</td>
                <td>×</td>
              </tr>
              <tr>
                <td><strong>市場拡大要件</strong></td>
                <td>○</td>
                <td>×</td>
                <td>×</td>
                <td>×</td>
                <td>×</td>
                <td>×</td>
              </tr>
              <tr>
                <td><strong>市場縮小要件</strong></td>
                <td>×</td>
                <td>○</td>
                <td>×</td>
                <td>×</td>
                <td>×</td>
                <td>×</td>
              </tr>
              <tr>
                <td><strong>売上高等減少要件</strong></td>
                <td>×</td>
                <td>×</td>
                <td>×</td>
                <td>×</td>
                <td>○</td>
                <td>○</td>
              </tr>
              <tr>
                <td><strong>最低賃金要件</strong></td>
                <td>×</td>
                <td>×</td>
                <td>×</td>
                <td>×</td>
                <td>×</td>
                <td>○</td>
              </tr>
              <tr>
                <td><strong>グリーン成長要件</strong></td>
                <td>×</td>
                <td>×</td>
                <td>×</td>
                <td>○</td>
                <td>×</td>
                <td>×</td>
              </tr>
            </tbody>
          </table>
          </>
          
          <>
          <div id='4' className="media_text_title">
            <h2>4.その他提出の必要が生じる書類</h2>
          </div>
          <p className="media_text_text">
            <br />
            <strong>加点関係の追加提出書類を以下にまとめさせていただきました</strong>。<br />
            また、類型によって提出書類も変わるため、必須書類に加えて類型ごとの書類の確認もお願いできればと思います。<br />
          </p>
          <h3 className='media_text_text_title'>加点項目を獲得するときに提出する書類</h3><br />
          <p className="media_text_text">
            加点項目①：<strong>2021年10月以降の、いずれかの月の売上高が2020年又は2019年同月比で30%以上減少していること(付加価値額の場合45%)</strong><br />
            →具体的な書類としては、上記の売上高or付加価値額の減少を示す書類と同じものをご準備ください。<br />
            （※同様の書類を用いて、回復・再生枠に応募する事業者の提出は不要です。)<br />
            <br />
            加点項目②：<strong>中小企業活性化協議会等から支援を受け</strong><br />
            （1）再生計画を策定している事業者<br />
            （2）再生計画を「策定済」であり応募締切日からさかのぼって3年以降に再生計画が成立した事業者<br />
            →中小企業活性化協議会等による確認書をご準備ください。<br />
            （※同様の書類を用いて、回復・再生枠に応募する事業者の提出は不要です。）<br />
            <br />
            なお、加点項目は他にもございます。他加点項目の内容・必要な対応事項は他記事にて詳細に説明しておりますので、下記リンクよりご確認ください。<br />
            参考記事：<a href='https://trankllc.com/media/subsidy/business/0022' target="_blank" rel="nofollow noopener noreferrer">
              「事業再構築補助金」10分で取れる加点書類が2つある！
            </a><br />
          </p>
          </>

          <>
          <div id='5' className="media_text_title">
            <h2>5.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、事業再構築補助金を申請したい事業者さまへ向けて、<strong>事業再構築補助金の提出書類ついて記事にさせていただきました</strong>。<br />
            <br />
            公募要領を各所に添付させていただきましたが、ご覧いただけば分かる通り難しい内容となっております。<br />
            <br />
            そこで、もし、本格的に事業再構築補助金の導入を検討されていましたら、<strong>弊社含め補助金に詳しい事業者へご相談ください</strong>。<br />
            <br />
            ご相談は、画面右側の補助金に関する相談をするから問い合わせにて、お待ちしております。<br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


