import DocumentMeta from 'react-document-meta';
import './styles.css';
import Nav from "./../../home/nav/index";
import TopImage from "./../../../image/company/0002.png";
import Footer from './../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: 'インターンでは、どんな仕事をしてる？ vol.2 | トランク合同会社',
    description: 'この記事ではトランク合同会社にインターン生として入社した方の感想や今後入社する予定のインターン生に向けたメッセージをインタビュー形式で紹介しています。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />

      <div className='media_company'>
        <div className='media_company_article'>
          <p className='media_company_date'>更新日: 2023/10/24</p>
          <h1 className="media_company_title">インターン生へのインタビュー記事 vol.2 | 松下 励</h1>
          <img src={TopImage} className="media_company_title_img" alt='ものづくり補助金' />

          <p className="media_company_text_text">
            <br />
            社会に出て経験をたくさん積みたいインターン生を募集していますので、興味のある方、話を聞きたい方は、右上の問い合わせより、ご連絡ください。<br />
          </p>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>こんな事が書いてあります！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>トランク合同会社で出来ること</span></li>
              <li><span>どんなやりがいがある？</span></li>
              <li><span>インターン生同士の交流</span></li>
            </ul>
          </div>
          </>

          <p className="media_company_text_text">
            この記事では、弊社で勤務しているインターン生がどんな業務を行っているのかをインタビュー形式で紹介しています！<br />
          </p>
          
          <>
          <div id="1" className="media_company_text_title">
            <h2>１.自己紹介</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            インターン生リーダーの松下 励です！<br />
            私は2024年に卒業予定の法政大学生であり、就職先は株式会社ベイカレント・コンサルティングです。<br />
            <br />
            トランク合同会社はメリハリのある社風であり、コンサルティングについて学ばせておりました。<br />
            <br />
            インターン生リーダーとして、社員の方とインターン生の橋になれるように積極的にコミュニケーションを取っています。<br />
          </p>
          </>
      
          <>
          <div id="2" className="media_company_text_title">
            <h2>２.どういった仕事をしているの？</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            私の最近の業務は、主に事業者様へのヒアリング、事業計画書の策定業務です。<br />
            <br />
            事業計画書とは中小企業の方が新規事業を立ち上げるため必要な書類となります。<br />
            策定に当たり、顧客の方に対してヒアリングを行うことで顧客の方の強みを把握し、市場の推移を考慮しながら新規事業を考案します。考案した新規事業をもとに資料を作成します。<br />
            <br />
            トランク合同会社では、顧客のヒアリングから事業計画書作成まで一気通貫したコンサルティング業務を行うことが出来ます。<br />
          </p>
          </>
         
          <>
          <div id="3" className="media_company_text_title">
            <h2>３.仕事のやりがいは何？</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            仕事のやりがいは、感謝されることです。<br />
            <br />
            インターン生でもヒアリングから提案まで一気通貫したサポートを行うため、顧客と二人三脚で事業を進めている感覚を日々味わっています。<br />
            <br />
            最終的な提案・提出物に対して「ありがとう」と言われることは学生生活では味わえない達成感と感動を得ることが出来ます。<br />
          </p>
          </>
          
          <>
          <div id="4" className="media_company_text_title">
            <h2>４.インターン生同士の会話</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            インターン生同士はとても仲が良いです！<br />
            <br />
            インターン生だけで仕事終わりに夕食に行って、仕事の効率化や就職活動についての相談など活発に情報交換を行います。また、インターン生用のマニュアルをみんなで作成し、何が必要かを議論しあいながら真剣に楽しく仕事をしています。<br />
          </p>
          </>
          
          <>
          <div id="5" className="media_company_text_title">
            <h2>５.インターンを通して何を得ましたか？</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            インターンを通して得られたことは、財務知識と図表の作り方です。<br />
            <br />
            今後の財務計画は事業者様の財務状況に応じて変わってきます。事業計画書を書く前に、上長の方に財務について教えていただき、大学生活では触れてこなかった財務知識を得ることが出来ます。<br />
            <br />
            また、事業計画書を顧客の方に見やすい資料を作る必要があり、見やすい図表とは何かを、学ぶことが出来ます。見やすい図表を作る中で、ショートカットなどのパソコンの使い方まで教えていただけます。<br />
            <br />
            同時に、業務推進時に教えて貰えるショートカットキーやパソコン作業の効率化は社会人になった時に大きなアドバンテージとなると考えています。<br />
          </p>
          </>
          
          <>
          <div id="6" className="media_company_text_title">
            <h2>６.インターンを希望する学生に向けて一言お願いします</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            トランク合同会社でのインターンでは、ヒアリングから事業計画書作成まで一気通貫したサポートを行えます。<br />
            <br />
            コンサルティングの基礎を学びながら、社会人への準備が出来ます。<br />
            コンサルティング業界志望の方におすすめです！<br />
          </p>
          </>
          
        </div>
        
      </div>

      <div className='media_company_'></div>

      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
