import './styles.css';
import Logo from './../../../../../../image/simulator/company.png';

const bid = (props) => {

  return(
  <>
  <div className='bid_footer'>
    <div className='bid_footer_info'>
      <a href='https://trankllc.com/'>
        <div>
          <img src={Logo} alt='' />
          <h4>トランク合同会社</h4>
        </div>
      </a>
      <h5>記憶に残る会社<br />be impresse by one's memory</h5>
      <p>
        〒222 - 0011<br />
        神奈川県横浜市港北区菊名4-8-25
      </p>
    </div>
    <div className='bid_footer_link'>
      <h4>会社情報</h4>
      <div>
        <a href='https://trankllc.com/'>
          <p>トップページ</p>
        </a>
        <a href='https://trankllc.com/company'>
          <p>会社情報</p>
        </a>
        <a href='https://trankllc.com/contact'>
          <p>お問い合わせ</p>
        </a>
      </div>
      <h4>サービス</h4>
      <div>
        <a href='https://trankllc.com/help'>
          <p>補助金事業</p>
        </a>
        <a href='https://trankllc.com/photo'>
          <p>美容学生向け事業</p>
        </a>
        <a href='https://trankllc.com/recruit'>
          <p>美容師向け事業</p>
        </a>
        <a href='https://trankllc.com/media/subsidy/business/0000'>
          <p>ブログ</p>
        </a>
      </div>
    </div>
  </div>
  </>
  );
}

export default bid;
