import './styles.css';
import point1 from './../../../../../image/adbusiness/point1.png';
import point2 from './../../../../../image/adbusiness/point2.png';
import point3 from './../../../../../image/adbusiness/point3.png';
import Form from './../form/index';
// import Contact from './../contact/index';

const Help = (props) => {

  return (
    <>
    <div className='simulator_Strong'>
      <h3>補助金の申請は<span> まるっと </span>専門家へ相談</h3>
      <p>シミュレーターより正確な情報、採択事例、補助金の受け取り金額は、<br />補助金の専門家へ相談すると詳細な情報が確認できます。</p>
      
      <div className='Simulator_Strong_point'>
        <div>
          {/* <h6 style={{color : '#F8D166'}}>01</h6> */}
          <img src={point1} alt='' />
          <h4>申請枠</h4>
          <p>どの申請枠だと採択率、補助金額が高くなるのかを診断します</p>
        </div>
        <div>
          {/* <h6 style={{color : '#81CCE8'}}>02</h6> */}
          <img src={point2} alt='' />
          <h4>受け取りまでの流れ</h4>
          <p>補助金の受け取り時期や申請の流れを詳細にご紹介します</p>
        </div>
        <div>
          {/* <h6 style={{color : '#89C6C1'}}>03</h6> */}
          <img src={point3} alt='' />
          <h4>類似案件の紹介</h4>
          <p>申請の類似案件を踏まえて、近年の採択傾向をお伝えします</p>
        </div>
      </div>

      <Form />

      {/* <a href='https://trankllc.com/contact'>
        <button className='Simulator_strong_button'>補助金 専門家へ相談</button>
      </a> */}
    </div>
    </>
  );
}

export default Help;
