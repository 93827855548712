import './styles.css';
import Icon from './../../../../../../../image/bid/icon.jpeg';

const BlogText0001 = () => {

  return(
  <>
  <div className='management_review_nav'>
    <a href='management_review_home'>
      <div className='management_review_nav_icon'>
        <img src={Icon} alt='' />
        <div>
          <h1>入札情報の窓口</h1>
          <h2>produce by trankllc</h2>
        </div>
      </div>
    </a>
    <div className='management_review_nav_link'>
      <p>入札情報検索 <span>↓</span></p>
      <p>サービスの魅力 <span>↓</span></p>
      <p>質問と答え <span>↓</span></p>
      <a href='/contact'>
        <button>お問い合わせ</button>
      </a>
    </div>
  </div>
  </>
  );
}

export default BlogText0001;
