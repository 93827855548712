// 従業員増員要件

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/30/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」従業員増員要件は年1.5%以上の増員！',
    description: '本記事では、事業再構築要件の従業員増員要件について解説させていただきました。要件の詳細、補助率、活用例などの詳細も解説しております。本記事は、「事業再構築の定義が分からない」方や「類型を詳しく知りたい」という方に向けて、執筆しておりますので、補助金の初申請を行う方はぜひご覧いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」従業員増員要件は年1.5%以上の増員！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は<strong>事業再構築補助金の従業員増員要件</strong>について、解説させていただきます。<br />
            <br />
            本記事は補助金申請 初心者へ向けた記事となっておりますので、初めてで「補助金 難しい」という印象の方はぜひ最後までご一読いただければと思います。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>本記事でこんな悩みが解決！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>賃金引上要件とは何？</span></li>
              <li><span>賃金引上要件はどうすれば満たされる？</span></li>
              <li><span>賃金引上要件で何ができる？</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、<strong>経済産業省から展開されている「事業再構築指針の手引き」を参考にして作成したもの</strong>となっています。<br />
            <a href='https://www.meti.go.jp/covid-19/jigyo_saikoutiku/pdf/shishin_tebiki.pdf' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：「事業再構築指針の手引き」に関する記事
            </a><br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">従業員増員要件の概要</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">概要</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">要件</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">申請可能な枠</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.従業員増員要件の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では概要、要件、補助金額と基本的な知識を順を追って解説しておりますので、要件を理解したい方はぜひご覧いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>概要</h3><br />
          <p className="media_text_text">
            従業員増員要件は大規模賃金引上促進枠へ申請する場合の要件の1つとなっており、年間の従業員数を1.5%以上増加させるという要件です。<br />
          </p>
          <h3 className='media_text_text_title'>要件</h3><br />
          <p className="media_text_text">
            従業員増員要件は上述した通り年率1.5%以上の従業員数の増加が必要となります。<br />
            <br />
            本要件の期間は補助事業終了年度となっておりますので、2年間 補助事業を行う場合は1.5%+1.5%で3%の増加が必要となります。<br />
            <br />
            また、従業員増加の基準は申請時としておりますので、申請時の従業員数を基準として目標値を算出していただければと思います。<br />
            <br />
            <strong>具体例）</strong><br />
            補助事業実施期間の終了時点を含む事業年度の終了時点で従業員数が20人、事業計画期間３年の企業の場合<br />
            20（従業員数）×4.5％（事業計画期間年率平均1.5%×3年）＝0.9＜３（事業計画期間）<br />
            ⇒事業計画期間3年の場合、最低3人の増員が必要<br />
            <br />
            ・補助事業実施期間の終了時点を含む事業年度の終了時点で従業員数が150人、事業計画期間5年の企業の場合<br />
            150（従業員数）×7.5％（事業計画期間年率平均1.5％×5年）＝11.25<br />
            ⇒12人以上の増員が必要<br />
          </p>
          <h3 className='media_text_text_title'>申請可能な枠</h3><br />
          <p className="media_text_text">
            上述でも示した通り、申請可能な枠は大規模賃金引上促進枠のみとなります。<br />
            <br />
            しかし、大規模賃金引上促進枠の要件は以下のものとなるため、以下の点を満たす必要がございます。<br />
            ① 成長枠又はグリーン成長枠に申請する事業者であること<br />
            ② 成長枠又はグリーン成長枠の補助事業終了後３～５年の間、事業場内最低賃金を年額 45 円以上の水準で引上げること【賃金引上要件】<br />
            ③ 成長枠又はグリーン成長枠の補助事業終了後３～５年の間、従業員数を年率平均 1.5％以上増員させること【従業員増員要件】<br />
            <br />
            上記の要件にある要件について、記事を作成しておりますので、詳しくは以下のご参考にいただければと思います。<br />
            <br />
            <strong>成長枠</strong><br />
            <a href='https://trankllc.com/media/subsidy/business/0025' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：成長枠に関する解説記事
            </a><br />
            <strong>賃金引上要件</strong><br />
            <a href='https://trankllc.com/media/subsidy/business/0010' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：賃金引上要件に関する解説記事
            </a><br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では従業員増員要件に関する解説を行わせていただきました。<br />
            <br />
            少し要件が厳しいという印象ございますが、従業員数が少ない場合、増加人数も比例して少なくなるというものとなっておりますので、成長枠での申請を検討されている方は同時に本申請枠での申請もご検討いただければと思います。<br />
            <br />
            弊社は上述で示した通り、補助金専門の会社となっておりますので、記事でのご不明点や補助金に関するご相談がございましたら、ご連絡いただければ思います。<br />
            <br />
            最後まで記事を拝見してくださり、誠にありがとうございました。<br />
            ご連絡の程、大変お待ちしております。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
