// 建設業
// https://docs.google.com/document/d/1eVQnO2jgHYWPGR-7xV2VniCzWqm4pvtxb8QQtP1XcDw/edit#heading=h.j2z1muxxu721

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "../../nav/index";
import Topic from "../topic";
import TopImage from "./../../../../../image/media/business/45/top.png";
import Image1 from "./../../../../../image/media/business/45/1.png";
import Footer from '../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」建設業の採択事例を大公開！',
    description: '本記事では、事業再構築補助金の建設業 採択事例を紹介しております。建設業を営んでいて事業再構築補助金の申請を検討されている方は、本記事をご参考に検討を行っていただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」建設業の採択事例を大公開！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            新型コロナウイルスの影響を受けた中小企業の新たな事業への進出をサポートする事業再構築補助金。<br />
            売上のV字回復を目指して、申請を検討している方も多いのではないでしょうか。<br />
            <br />
            本記事では、建設業における事業再構築補助金の活用方法をはじめ、過去の採択事例や申請のポイントについて詳しく解説します。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業再構築補助金の対象となる建設業者</span></li>
              <li><span>建設業における事業再構築補助金の活用方法</span></li>
              <li><span>過去の建設業者採択例</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            ※本記事は、最新の事業再構築補助金公募要領を参考に作成しております。<br />
            <br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo.pdf' target="_blank" rel="nofollow noopener noreferrer">
              リンク：事業再構築補助金 公募要領
            </a><br />
            <br />
            当社では事業再構築補助金に関する無料相談を実施しています。<br />
            「もっと詳しく知りたい」「疑問点について質問したい」という方はお気軽にお声掛けください。<br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">建設業における活用方法</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金の対象となる経費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">建設業での事業再構築類型の具体例</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金での申請例</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#1" offset="100">虎の巻から建設業者の特徴</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">建設業者の申請傾向</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">具体的な採択事例</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">建設業の申請で抑えるべきポイント</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#1" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.建設業における活用方法</h2>
          </div>
          <p className="media_text_text">
            <br />
            まずは、建設業における事業再構築補助金の活用方法について、<br />
            <br />
            <strong>事業再構築補助金の対象となる経費</strong>
            <strong>建設業での事業再構築類型の具体例</strong>
            <strong>事業再構築補助金での申請例</strong>
            の3項目に分けて解説します。<br />
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金の対象となる経費</h3><br />
          <p className="media_text_text">
            事業再構築補助金の対象経費は、主に以下の11項目があります。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>経費項目</th><th>詳細</th><th>具体例</th>
              </tr>
              <tr>
                <td>①建物費</td>
                <td>補助事業にかかる建物の建設や改修にかかる経費。</td>
                <td>・建物の建設、改修費<br />・建物の撤去、原状回復費</td>
              </tr>
              <tr>
                <td>②機械装置・システム構築費</td>
                <td>補助事業で使用する機械装置やシステム開発にかかる経費。</td>
                <td>・製造設備の導入費・専用ソフト購入費<br />・機械装置のリース費</td>
              </tr>
              <tr>
                <td>③技術導入費</td>
                <td>補助事業の遂行に必要な知的財産権等の導入に要する経費。</td>
                <td>・知的財産権等の導入費</td>
              </tr>
              <tr>
                <td>④専門家経費</td>
                <td>補助事業遂行のために専門家に支払われる経費。</td>
                <td>・専門家へのコンサルティング業務の依頼費<br />・それに伴う旅費など</td>
              </tr>
              <tr>
                <td>⑤運搬費</td>
                <td>運搬料、宅配・郵送料等に要する経費。</td>
                <td>・運搬料<br />・郵送料など</td>
              </tr>
              <tr>
                <td>⑥クラウドサービス利用費</td>
                <td>クラウドサービスの利用に関する経費。</td>
                <td>・クラウドサービス利用費<br />・WEBプラットフォーム利用費</td>
              </tr>
              <tr>
                <td>⑦外注費</td>
                <td>本事業に必要な加工や設計・検査等の一部を外注する際にかかる経費。</td>
                <td>・製品開発に要する加工費<br />・設計費など</td>
              </tr>
              <tr>
                <td>⑧知的財産権等関連経費</td>
                <td>特許権等の知的財産権等の取得に要する経費。</td>
                <td>・弁理士の手続代行費<br />・外国特許出願のための翻訳料など</td>
              </tr>
              <tr>
                <td>⑨広告宣伝・販売促進費</td>
                <td>製品・サービスに関する広告の作成や媒体掲載にかかる経費。</td>
                <td>・広告媒体の掲載費<br />・WEB広告費など</td>
              </tr>
              <tr>
                <td>⑩研修費</td>
                <td>教育訓練や講座受講等に係る経費。</td>
                <td>・従業員の教育費費</td>
              </tr>
              <tr>
                <td>⑪廃業費（産業構造転換枠に申請し、既存事業の廃止を行う場合のみ）</td>
                <td>既存事業の廃止にかかる経費</td>
                <td>・廃止手続費<br />・解体費<br />・移転・移設費用など</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            対象経費はさまざまな事業に活用できるよう広く設けられていますが、一方で対象外経費も細かく分類されているため注意が必要です。<br />
            <br />
            たとえば建物費の場合、建物を新たに建築したり回収したりする費用は対象経費として認められます。<br />
            しかし、単なる建物の購入や賃貸の費用は対象経費として認められません。<br />
            <br />
            微妙な違いですが、建物費ひとつとっても細かく分類されています。<br />
            <br />
            申請直前になって「経費に含まれていると思っていたのに対象外だった」ということにならないよう、対象外経費については事前にしっかり確認しておく必要があります。<br />
            <br />
            とはいえ、対象経費と対象外経費を見極めるのは非常に難しいことです。<br />
            そのため、「公募要領を確認したけれど経費についてあまり理解ができていない」「専門家の意見が聞きたい」とお困りの方は、ぜひ当社にご相談ください。<br />
            <br />
            今なら初回相談無料で経費についての相談を行っております。<br />
            対象経費の確認から、金額の計算のお手伝いをさせていただいておりますので、お気軽にお問い合わせください。<br />
          </p>
          <h3 className='media_text_text_title'>建設業での事業再構築類型の具体例</h3>
          <p className="media_text_text">
            事業再構築補助金に申請するためには、要件を満たさなければなりません。<br />
            要件のひとつに「事業再構築類型」という項目があり、事業再構築類型は「新市場進出」「事業転換」「業種転換」の3つに分類されます。<br />
            <br />
            各類型の詳細については以下のとおりです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th>類型を満たすための要件</th><th>産業分類に基づく変更</th>
              </tr>
              <tr>
                <td>新市場進出</td>
                <td>
                  ①製品又は提供方法を大きく変更する<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全体の10%以上となる計画を策定する。
                </td>
                <td>無</td>
              </tr>
              <tr>
                <td>事業転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。<br />
                </td>
                <td>主な事業を変更(中・小・細分類)</td>
              </tr>
              <tr>
                <td>業種転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3～5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。
                </td>
                <td>主な業種を変更<br />(大分類)</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            事業再構築補助金に申請するためには、これら要件のいずれかに該当している必要があります。<br />
            <br />
            各類型をわかりやすく建設業に当てはめてみると以下のとおりです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th>類型を満たすための要件</th><th>産業分類に基づく変更</th>
              </tr>
              <tr>
                <td>新市場進出</td>
                <td>
                  日本産業分類の変更なし
                </td>
                <td>若年層向けの低価格住宅から、最新技術と設備を整えた高級住宅販売へ販路を拡大。</td>
              </tr>
              <tr>
                <td>事業転換</td>
                <td>
                  小分類が「065：木造建築工事業→066：建築リフォーム工事業」へと変更。<br />
                </td>
                <td>新築住宅から低価格で提供できる中古物件のリノベーション事業に進出。</td>
              </tr>
              <tr>
                <td>業種転換</td>
                <td>
                  大分類が「D：建設業→E：製造業」へと変更。
                </td>
                <td>住宅建築事業の廃材を活用して、家具の製造・販売事業へ進出。</td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>事業再構築補助金での申請例</h3>
          <p className="media_text_text">
            対象経費と類型の理解が深まったところで、続いては建設業における事業再構築補助金の具体的な申請例について解説します。<br />
            <br />
            補助金額の計算方法についても詳しく解説しておりますので、申請する際の参考にしてみてください。<br />
            <br />
            <strong>【事業内容】</strong>
            地域密着型の工務店として住宅建築に携わってきたが、新型コロナウイルスの影響で売上が減少。<br />
            既存事業の木材仕入れルートを活用し、オーダーメイド家具の製造・販売事業へ進出する。<br />
            全国展開できるよう、ECサイトを構築して販路を拡大する。<br />
            <br />
            ・事業場所：石川県金沢市<br />
            ・従業員数：13人<br />
            ・売上高：5億円<br />
            <br />
            【日本産業分類から類型を決定】<br />
            <br />
            既存事業から新事業へ進出する際の日本産業分類の変化は以下のとおりです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>日本産業分類</th><th>大分類</th><th>中分類</th><th>小分類</th><th>細分類</th>
              </tr>
              <tr>
                <td>既存事業</td><td>D：建設業</td><td>06：総合工事業</td><td>065：木造建築工事業</td><td>0651：木造建築工</td>
              </tr>
              <tr>
                <td>新規事業</td><td>E：製造業</td><td>13：家具・装備品製造業</td><td>131：家具製造業</td><td>1311：木製家具製造業（漆塗りを除く）</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            大分類が「D：建設業→E：製造業」へと変化していることから、本事業は「業種転換」の要件を満たしていることがわかります。<br />
            <br />
            <strong>【売上高から申請枠を決定】</strong><br />
            <br />
            本事業の売上高について、下表をご覧ください。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'></th><th>1月</th><th>2月</th><th>3月</th><th>合計</th>
              </tr>
              <tr>
                <td>2021年</td><td>53,000</td><td>54,000</td><td>53,000</td><td>160,000</td>
              </tr>
              <tr>
                <td>2022年</td><td>41,000</td><td>42,000</td><td>42,000</td><td>125,000</td>
              </tr>
              <tr>
                <td>増減率(%)</td><td>-23%</td><td>-23%</td><td>-21%</td><td>-22%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            2021年の売上高に比べて2022年の同月の売上は22％も減少しています。<br />
            事業再構築補助金は、2022年1月以降の連続する6か月間のうち、任意の3か月の合計売上高が対2019～2021年の同3か月の合計売上高と比較して10％減少している場合、「物価高騰対策・回復再生応援枠」での申請が可能です。<br />
            <br />
            そのため、本事業は売上高の要件を満たしている「物価高騰対策・回復再生応援枠」にて申請します。<br />
            <br />
            <strong>【補助金額の計算】</strong><br />
            類型と申請枠それぞれの要件を満たしていますので、本事業は事業再構築補助金の申請が可能です。<br />
            <br />
            それでは、経費の計算と補助金額の計算をしていきましょう。<br />
            <br />
            なお、本事業にかかる経費は以下のとおりです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>新事業用の工場建築費</th><th>￥30,000,000</th>
              </tr>
              <tr>
                <td>ECサイト構築費</td><td>￥3,000,000</td>
              </tr>
              <tr>
                <td>広告費</td><td>￥3,000,000</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            上記の経費はすべて対象経費に含まれるため、本事業の申請金額は合計で36,000,000円になります。<br />
            <br />
            補助率についてですが、中小企業の場合2/3が補助されます。<br />
            加えて、従業員が6〜20人の場合は600万円までの金額については3/4が補助されます。<br />
            <br />
            これらの補助率を当てはめて計算すると補助金額は以下のとおりです。<br />
            <br />
            （従業員数による補助率　3/4）6,000,000×3/4＝4,500,000（1）<br />
            （補助率　2/3）30,000,000×2/3＝20,000,000（2）<br />
            <br />
            （1）＋（2）＝24,500,000円<br />
            <br />
            本事業は、申請金額36,000,000円に対して最大24,500,000円が補助されます。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.虎の巻から建設業者の特徴</h2>
          </div>
          <p className="media_text_text">
            <br />
            「虎の巻」とは、経済産業省により公表されている事業再構築補助金に関する情報をわかりやすくまとめた資料のことです。<br />
            <br />
            「公募要領の内容が難しくて補助金のことが理解ができない」<br />
            「事業計画の立て方がわからない」<br />
            「採択率の高い事業を知りたい」<br />
            <br />
            など、皆さんが事業再構築補助金に対して抱いている疑問に対する答えが、動画やガイドブックにわかりやすくまとめられています。<br />
            <br />
            事業再構築補助金に関するより深い知識が得られるため、申請までにぜひご覧いただきたい資料のひとつです。<br />
            <br />
            本記事においても「虎の巻」をもとに、<br />
            ・建設業者の申請傾向<br />
            ・具体的な採択事例<br />
            ・建設業の申請で抑えるべきポイント<br />
            について、以下にまとめています。<br />
          </p>
          <h3 className='media_text_text_title'>１.建設業者の申請傾向</h3>
          <p className="media_text_text">
            <img src={Image1} className='media_img' alt='' />
            上図は、虎の巻のガイドブックに掲載されている建設業の申請傾向をまとめたデータです。<br />
            縦軸が申請件数を、横軸が採択率を表しており、右側のオレンジ部分に位置するほど有望度が高い事業ということになります。<br />
            <br />
            建設業では、<br />
            産業廃棄物処理・エコリサイクル（SDGsに関わる再利用全般での事業展開など）<br />
            ドローンの活用（ドローンを活用した点検業務の推進、ドローンスクールの運営など）<br />
            デジタル活用・DX推進（デジタル技術を活用した製造プロセスの合理化など）<br />
            <br />
            などが、有望度の高い事業に分類されていることがわかります。<br />
            <br />
            いずれも近年需要が高まっている分野であるという点で共通しています。<br />
            事業再構築補助金では、こうしたニーズのある分野への進出は採択されやすい傾向にあります。<br />
            そのため、事業計画を立てる際はどの分野が今後伸びていくのかという点も考慮しながら進めていくといいでしょう。<br />
          </p>
          <h3 className='media_text_text_title'>具体的な採択事例</h3>
          <p className="media_text_text">
            <strong>①既存事業から事業領域を広げるため、キッチンカー・キャンピングカーの制作事業へ進出</strong><br />
            <br />
            既存の建設事業だけでは売上の回復が困難なことから、事業領域を広げる必要性を痛感。<br />
            コロナ禍で需要が高まったキッチンカーとキャンピングカーの制作事業へ進出し、飲食事業とともにV字回復を目指すとして採択された事例です。<br />
            <br />
            事業再構築補助金において、近年需要が高まっているキッチンカー関連の事業は採択されやすい傾向にあります。<br />
            本事業は、ニーズのある分野への進出と既存のノウハウを活かせる点が評価され採択された事例と言えるでしょう。<br />
            <br />
            <stonrg>②宮大工が手作りする升組をつかった家具の製作・販売</stonrg><br />
            これまで廃棄されていた端材を利用し、低コストで宮大工が手作りする升組をつかった家具の製作・販売事業へ進出。<br />
            厳しい経営環境に対応し、売上のV字回復を実現するとして採択された事例です。<br />
            本事業は、既存事業の強みである高い木材加工技術を活かしている点や、リサイクルへの取り組みが高く評価され採択されたと考えられます。<br />
          </p>
          <h3 className='media_text_text_title'>建設業の申請で抑えるべきポイント</h3>
          <p className="media_text_text">
            <strong>①競合他社の分析をする</strong><br />
            事業再構築補助金では、競合他社の分析が重要です。<br />
            競合他社の分析ができていなければ、たとえ採択されたとしても事業の成功につながらない可能性があるからです。<br />
            <br />
            ニーズの高い事業へ進出するのであれば、競合他社をしっかり分析し、自社の強みがどう活かせるかなどを考えなければなりません。<br />
            <br />
            競合他社との明確な差別化をすることが、事業再構築の申請では重要なポイントになります。<br />
            <br />
            <strong>②過去の採択事例や傾向を参考にする</strong><br />
            事業再構築補助金は、業種によって採択されやすい事業と採択されにくい事業があります。<br />
            建設業の場合であれば、デジタル技術関連やリサイクル関連は採択されやすい一方、美容や飲食業は採択されにくい傾向にあります。<br />
            <br />
            採択事例や傾向については、「虎の巻」にたくさんの情報が掲載されていますので、ぜひ活用してみてください。<br />
            過去のデータを活用して事業計画を立てることも、事業再構築補助金の申請において重要なポイントのひとつです。<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            建設業の場合、最新のデジタル技術を活用した事業やリサイクル事業への進出などが採択されやすい傾向にあります。<br />
            ほかにも、コロナ禍で人気が高まっているアウトドア事業への進出なども有望度が高く狙い目の事業と言えるでしょう。<br />
            <br />
            事業再構築補助金を活用することで、新たな事業へのチャレンジと売上の回復を実現できる可能性が広がります。<br />
            今の状況をなんとかしたいという方は、ぜひ事業再構築補助金の活用を検討してみてください。<br />
            <br />
            ただし、事業再構築補助金は非常に複雑で、事業計画の策定から申請まですべてをご自身で行うのは大変です。<br />
            本記事を掲載しているトランク合同会社は、事業再構築補助金における豊富な実績がありますので、お困りの方はご相談ください。<br />
            <br />
            事業計画書の作成ポイントや採択されるためのコツについて熟知しておりますので、皆様のお役に立てます。<br />
            <br />
            今なら初回無料でご相談を受け付けていますので、興味がある方はお気軽にお声掛けください。<br />
            最後までご覧いただきありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


