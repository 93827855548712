// 市場縮小要件

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/31/top.png";
import Image1 from "./../../../../../image/media/business/31/0.png";
import Image2 from "./../../../../../image/media/business/31/1.png";
import Image3 from "./../../../../../image/media/business/31/2.png";
import Image4 from "./../../../../../image/media/business/36/1.png";
import Image5 from "./../../../../../image/media/business/36/2.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」市場規模が縮小していれば市場縮小要件！',
    description: '本記事では、事業再構築要件の市場縮小要件について解説させていただきました。要件の詳細、補助率、活用例などの詳細も解説しております。本記事は、「事業再構築の定義が分からない」方や「類型を詳しく知りたい」という方に向けて、執筆しておりますので、補助金の初申請を行う方はぜひご覧いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」市場規模が縮小していれば市場縮小要件！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は<strong>事業再構築補助金の市場縮小要件</strong>について、解説させていただきます。<br />
            <br />
            本記事は、補助金申請が初めての方へ向けた記事となっておりますので、ごゆっくり最後までご一読いただければと思います。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>本記事でこんな悩みが解決！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>市場縮小要件とは何？</span></li>
              <li><span>市場縮小要件はどうすれば満たされる？</span></li>
              <li><span>市場縮小要件で何ができる？</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、<strong>経済産業省から展開されている公募要領、及び事業再構築指針の手引きを参考にして作成したもの</strong>となっています。<br />
            <a href='https://www.meti.go.jp/covid-19/jigyo_saikoutiku/pdf/shishin_tebiki.pdf' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：「事業再構築指針の手引き」に関する記事
            </a><br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">市場縮小要件</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">市場縮小要件の概要</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">市場縮小要件の要件</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">市場縮小要件の申請枠</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">提出書類のダウンロード</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">市場縮小要件を満たすこと説明書の書き方</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#3" offset="100">既存事業の業種・業態の記載方法</AnchorLink></li>
                  <li><AnchorLink href="#3" offset="100">既存事業の概要の記載方法</AnchorLink></li>
                  <li><AnchorLink href="#3" offset="100">既存事業の市場規模が10%以上縮小することの説明の記載方法</AnchorLink></li>
                  <li><AnchorLink href="#3" offset="100">詳細記事</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.市場縮小要件の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            市場縮小要件は産業構造転換枠を申請する際に必要な要件となっております。<br />
            <br />
            本項目では概要、要件、補助金額に沿って、ご説明していきますのでご確認いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>概要</h3><br />
          <p className="media_text_text">
            市場縮小要件は、<strong>現在営んでいる事業が過去~今後の間の10年で市場規模が10%以上減少している業態・業種であるという要件</strong>になります。<br />
            <br />
            具体的には、出版業界等が市場縮小に該当しておりますので、以下のような形をご想定していただければと思います。<br />
            <br />
            <img src={Image1} className='media_img' alt='' /><br />
            <br />
            既存事業が上記のような推移をしている方は、ぜひ産業構造転換枠でのご申請を検討していただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>要件</h3><br />
          <p className="media_text_text">
            事業縮小要件の要件は、<strong>現在営んでいる事業の業態・業種が縮小している</strong>というものとなっております。<br />
            <br />
            本要件を満たすためには、<strong>公的データを使用して事業が縮小していることを証明する必要があります</strong>ので、以下のような市場縮小推移を辿っている市場であれば問題ございません。<br />
            <br />
            <span>認められる例 ）</span><br />
            <img src={Image2} className='media_img' alt='' />
            ①2019 年の市場規模が 2009 年に比べて 10％以上縮小しており、一貫して下降トレンドにある。<br />
            ②2019 年の市場規模が 2009 年に比べて 10％以上縮小しており、前年に比して縮小している年もあるが、全体として下降トレンドにあると認められる。<br />
            <br />
            <span>認められない例 ）</span><br />
            <img src={Image3} className='media_img' alt='' />
            ③2019 年の市場規模が 2009 年に比べて 10％以上縮小しているが、2019 年だけ何かしらの事情で縮小しているだけである可能性があり、下降トレンドにあるとは判断できない。<br />
            ④2019 年の市場規模が 2009 年に比べて 10％以上縮小しているが、近年（2015 年から 2019 年にかけて）上昇トレンドにある。<br />
            ⑤2019 年の市場規模が 2009 年に比べて 10％以上縮小していない。<br />
            <br />
            例外的に以下のような場合でも本要件を満たす場合がございます。<br />
            <strong>① 市町村の総生産の10%以上が失われること</strong><br />
            <strong>② 当該基幹大企業との直接取引額が売上高の10％以上を占めていること</strong><br />
            <br />
            本要件を満たしているかを確認するため、弊社を含む専門家の助言が必要という方はぜひお問い合わせよりご連絡いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>申請に必要な書類</h3><br />
          <p className="media_text_text">
            上記の②を国へ提出するための書類として、<strong>「市場縮小要件を満たすことの説明書」</strong>という書類がございます。<br />
            <br />
            本書類のダウンロード方法に付きましては、次章にて解説しております。また、<strong>ダウンロード可能なリンクを以下に貼り付けております</strong>ので、ご参照いただければと思います。<br />
            <a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：市場縮小要件を満たすことの説明書の取得
            </a><br />
            <br />
            また、認められるケース、認められないケースのご紹介をさせていただいた通り、認められないケースもございますので、<strong>ご記載は十分にご確認しながら行なっていただければと思います</strong>。<br />
          </p>
          <h3 className='media_text_text_title'>申請可能な枠</h3><br />
          <p className="media_text_text">
            上述に記載いたしました通り、<strong>市場縮小要件は産業構造転換枠での申請を検討されている方の方のみが必要なもの</strong>となっております。<br />
            <br />
            事業再構築補助金に申請する方で申請枠がまだ確定していない方は、ぜひ一度下記の<strong>産業構造転換枠の対象となる業種・業態の一覧のPDFをご覧いただければと思います</strong>。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/tenkanwaku_list.pdf' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：産業構造転換枠に関する詳細記事
            </a><br />
            <br />
            また、別途記事で産業構造転換枠の要件、補助率等に関する解説を行っておりますので、以下の記事よご参照いただければと思います。<br />
            <a href='https://trankllc.com/media/subsidy/business/0025' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：産業構造転換枠に関する詳細記事
            </a><br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.提出書類のダウンロード</h2>
          </div>
          <p className="media_text_text">
            <br />
            市場縮小要件を満たすには、<strong>「市場縮小要件を満たすこと説明書」という書類のご提出が必要となります</strong>ので、その書類のダウンロード方法を解説させていただきます。<br />
            <br />
            「市場縮小要件を満たすこと説明書」は、以下の事業再構築補助金の電子申請書類よりwordをダウンロードしていただければと思います。<br />
            <br />
            ダウンロードができるサイトのリンクを以下に貼り付けておりますので、ご覧いただければと思います。<br />
            <a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：市場縮小要件を満たすこと説明書のURL
            </a><br />
            <br />
            <span>ダウンロード部分のイメージ画像</span><br />
            <img src={Image4} className='media_img' alt='' /><br />
            <br />
            <span>市場縮小要件を満たすこと説明書のwordのイメージ</span><br />
            <img src={Image5} className='media_img' alt='' /><br />
          </p>
          </>
          
          <>
          <div id='3' className="media_text_title">
            <h2>3.市場縮小要件を満たすこと説明書の書き方</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、「市場縮小要件を満たすこと説明書」の記載内容以下の3点となります。<br />
            <strong>① 既存事業の業種・業態の記載方法</strong><br />
            <strong>② 既存事業の概要の記載方法</strong><br />
            <strong>③ 既存事業の市場規模が10%以上縮小することの説明の記載方法</strong><br />
            <br />
            本項目では実際どのようなことを記載するのかの概要について、触れさせていただきましたので、ご参照ください。<br />
          </p>
          <h3 className='media_text_text_title'>既存事業の業種・業態の記載方法</h3><br />
          <p className="media_text_text">
            本項目では既存事業で何を行なっているのかを産業分類に基づいて、記載するという項目になっており、具体的な記載方法は以下の通りとなります。<br />
            <br />
            <strong>具体例</strong><br />
            管理番号：1、産業分類（小分類）：出版業（電子出版のみの事業者は除く）及び書籍・雑誌小売業<br />
            <br />
            市場縮小要件は、以下のリストに含まれている業種であるか、もしくは、自分自身で市場の縮小を示すかの2つの方法があるとお伝えいたしました。<br />
            <br />
            リストに含まれている方は、リスト情報をご記載ください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/tenkanwaku_list.pdf' target="_blank" rel="nofollow noopener noreferrer">
              産業構造転換枠の対象となる業種・業態の一覧のURL
            </a><br />
            <br />
            リストに含まれていない方は、以下のサイトにて自分自身が所属する産業分類をお探しいただければと思います。<br />
            <a href='https://www.e-stat.go.jp/classifications/terms/10?search_method=keyword&search_word=&komokuSearchFlg_dummy=1&komokuSearchFlg=1&info1SearchFlg_dummy=1&info1SearchFlg=1&info2SearchFlg_dummy=1&info2SearchFlg=1&revision=03&search_kind=10&base_code=0&form_id=main_form&op=search&searchboxShow1=1&searchboxShow2=0&searchboxShow3=0&page=&srchcndId=' target="_blank" rel="nofollow noopener noreferrer">
              e-Stat 日本標準産業分類サイト
            </a><br />
          </p>
          <h3 className='media_text_text_title'>既存事業の概要の記載方法</h3><br />
          <p className="media_text_text">
            産業分類によって、記載した項目の中で具体的にどのような事業を行なっているかを記載するものとなっております。<br />
            <br />
            <strong>具体例</strong><br />
            既存事業では出版業界に属しており、電子書類をいただき、本に印字していくという事業を行なっている。コネクションが当社の強みであるため、本の制作会社からの依頼を多く受けている状況ではあるが、出版業界全般の市場推移の減少していることから受注数が減少しており、経営状態が悪化している。<br />
          </p>
          <h3 className='media_text_text_title'>既存事業の市場規模が10%以上縮小することの説明の記載方法</h3><br />
          <p className="media_text_text">
            既存事業が縮小していることを公的な書類によって、市場の縮小を説明する必要がございます。本要件については以下の記事にて詳しくご参照いただけますと幸いです。<br />
          </p>
          <h3 className='media_text_text_title'>詳細記事</h3><br />
          <p className="media_text_text">
            上記の3点についての具体的な記載内容につきましては、書類の画像付きで記事を作成しておりますので、以下の記事をご参照いただければと思います。
            <br />
            <a href='https://trankllc.com/media/subsidy/business/0036' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：事業再構築補助金 市場縮小要件を満たすことの説明書の書き方を解説！
            </a><br />
          </p>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>4.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            最後までご一読いただき、誠にありがとうございます。<br />
            産業構造転換枠の解説をさせていただきましたがいかがだったでしょうか。<br />
            <br />
            要件自体は複雑なものではございませんので、要件の手順を沿って要件を満たすことができるのかをまずご確認いただければと思います。<br />
            <br />
            上述で示した通り、弊社は補助金申請の専門家でございますので、本記事に関わらずご不明点等ございましたら、ご連絡いただけますと幸いです。<br />
            ご連絡いただけること心よりお待ちしております。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
