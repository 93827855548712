// 情報通信業

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "../../nav/index";
import Topic from "../topic";
import TopImage from "./../../../../../image/media/business/46/top.png";
import Image1 from "./../../../../../image/media/business/46/1.png";
import Footer from '../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」情報通信業の採択事例を大公開！',
    description: '本記事では、事業再構築補助金の情報通信業 採択事例を紹介しております。飲食業を営んでいて事業再構築補助金の申請を検討されている方は、本記事をご参考に検討を行っていただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」情報通信業の採択事例を大公開！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            「新型コロナウイルスの影響で売上が減少してしまった」「新事業にチャレンジして売上を回復したい」など、事業再構築補助金の活用を検討している方は多いのではないでしょうか。<br />
            <br />
            本記事では、事業再構築補助金を検討中の情報通信業者様向けに補助金の活用方法や採択事例、申請のポイントについて詳しく解説します。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業再構築補助金の対象となる情報通信業者</span></li>
              <li><span>情報通信業における事業再構築補助金の活用方法</span></li>
              <li><span>過去の情報通信業者の採択例</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            ※本記事は「第10回事業再構築補助金　公募要領」をもとに作成しています。
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo.pdf' target="_blank" rel="nofollow noopener noreferrer">
              リンク：事業再構築補助金 公募要領
            </a><br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">情報通信業における活用方法</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金の対象となる経費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">情報通信業での事業再構築類型の具体例</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金での申請例</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">虎の巻から見る情報通信業者の特徴</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">o	情報通信業者の申請傾向</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">o	具体的な採択事例</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">o	情報通信業の申請で抑えるべきポイント</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.情報通信業における活用方法</h2>
          </div>
          <p className="media_text_text">
            <br />
            まずは事業再構築補助金の情報通信業における活用方法について、<br />
            <strong>1.事業再構築補助金の対象となる経費</strong><br />
            <strong>2.情報通信業での事業再構築類型の具体例</strong><br />
            <strong>3.事業再構築補助金での申請例</strong><br />
            これら3つのポイントに分けて解説します。<br />
            <br />
            具体的な補助金の計算方法を事例を用いて解説しておりますので、事業計画でお悩みの方はぜひ参考にしてみてください。<br />
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金の対象となる経費</h3><br />
          <p className="media_text_text">
            まずは経費について。<br />
            事業再構築補助金の対象経費は細かく分類されており、具体的には以下の11種類に分類されます。<br />
            <br />
            ①建物費<br />
            ②機械装置・システム構築費<br />
            ③技術導入費<br />
            ④専門家経費<br />
            ⑤運搬費<br />
            ⑥クラウドサービス利用費<br />
            ⑦外注費<br />
            ⑧知的財産権等関連経費<br />
            ⑨広告宣伝・販売促進費<br />
            ⑩研修費<br />
            ⑪廃業費<br />
            <br />
            情報通信業と関係の深いものとしては以下の経費が挙げられます。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>経費項目</th><th>詳細</th><th>具体例</th>
              </tr>
              <tr>
                <td>建物費</td>
                <td>補助事業で使用する事務所や施設、倉庫などの建設や改修にかかる経費。</td>
                <td>・店舗の改修費用</td>
              </tr>
              <tr>
                <td>機械装置・システム費</td>
                <td>補助事業で使用する機械装置や工具、器具の購入。専用ソフトウェアや情報システム等の購入、構築。</td>
                <td>・デジタル技術の導入<br />・ECシステムの構築費</td>
              </tr>
              <tr>
                <td>外注費</td>
                <td>事業に必要な加工・設計等を外部に委託する経費</td>
                <td>・新店舗のデザイン費用<br />・店舗ロゴの作成費用</td>
              </tr>
              <tr>
                <td>広告宣伝・販売促進費</td>
                <td>事業で提供する製品、サービスに係る広告宣伝に要する経費。</td>
                <td>・チラシの作成・配布費用<br />・インターネットの広告掲載費</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            事業再構築補助金の経費は公募の度に若干の変化があります。<br />
            そのため、申請の際は最新の情報をしっかり把握しておく必要があります。<br />
            <br />
            とはいえ、事業再構築補助金の経費の種類は多く、経費に含まれると思っていたものが実は対象外だったということも起こりかねません。<br />
            そのため、経費の見極めに自信がないという方はぜひ当社にご相談ください。<br />
            <br />
            対象経費の確認から金額の算出までお手伝いさせていただきます。<br />
            初回相談無料で行っておりますので、お気軽にお問い合わせください。<br />
          </p>
          <h3 className='media_text_text_title'>情報通信業での事業再構築類型の具体例</h3>
          <p className="media_text_text">
            事業再構築補助金に申請するには要件を満たす必要があり、要件のひとつに「事業再構築類型」というものがあります。<br />
            <br />
            事業再構築類型は「新市場進出」「事業転換」「業種転換」の3つに分類されており、詳細については以下のとおりです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th>類型を満たすための要件</th><th>産業分類に基づく変更</th>
              </tr>
              <tr>
                <td>新市場進出</td>
                <td>
                  ①製品又は提供方法を大きく変更する<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全体の10%以上となる計画を策定する。
                </td>
                <td>無</td>
              </tr>
              <tr>
                <td>事業転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。<br />
                </td>
                <td>主な事業を変更(中・小・細分類)</td>
              </tr>
              <tr>
                <td>業種転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3～5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。
                </td>
                <td>主な業種を変更<br />(大分類)</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            事業再構築補助金は、これらいずれかの類型に該当することで申請が可能です。<br />
            <br />
            <strong>【情報通信業での具体例】</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th>類型を満たすための要件</th><th>産業分類に基づく変更</th>
              </tr>
              <tr>
                <td>新市場進出</td>
                <td>3911：受託開発ソフトウェア業→3911：受託開発ソフトウェア業</td>
                <td>顧客管理システムを提供していた事業者が、企業と求職者のマッチングシステムを開発し販路拡大を狙う。</td>
              </tr>
              <tr>
                <td>事業転換</td>
                <td>412：音声情報制作業→416：映像、音声、文字情報制作に附帯するサービス業</td>
                <td>レコード制作事業から市場が拡大している配信型スタジオ事業へ進出。</td>
              </tr>
              <tr>
                <td>業種転換</td>
                <td>G：情報通信業→O：教育、学習支援業</td>
                <td>企業向けのアプリ開発を行っていた事業者が、個人向けアプリ開発のオンライン教室を開始。</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            上図は各類型に情報通信業の具体例を当てはめたものです。<br />
            ご自身の事業がどの類型に該当するかを事前に確認しておきましょう。
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金での申請例</h3>
          <p className="media_text_text">
            続いては、情報通信業者の申請例を用いて具体的な申請の流れを解説します。
            対象経費や類型、補助金額の計算方法に関する一連の流れが把握できますので、ぜひ参考にしてみてください。<br />
            <br />
            【事業内容】<br />
            <br />
            企業向けのホームページ制作事業を行ってきたが、新型コロナウイルスの影響により売上が減少。<br />
            個人を対象にしたホームページ制作のオンラインスクール事業へ進出し、売上の回復を目指す。<br />
            <br />
            事業場所：神奈川県厚木市<br />
            従業員数：10名<br />
            売上高：7,000万円<br />
            <br />
            【日本産業分類から類型を決定】<br />
            まずは本事業がどの類型に該当するかを確認します。<br />
            既存事業と新事業の日本産業分類を比較すると以下のとおりです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>日本産業分類</th><th>大分類</th><th>中分類</th><th>小分類</th><th>細分類</th>
              </tr>
              <tr>
                <td>既存事業</td><td>G：情報通信業</td><td>39：情報サービス業</td><td>391：ソフトウェア業</td><td>3911：受託開発ソフトウェア業</td>
              </tr>
              <tr>
                <td>新規事業</td><td>O：教育、学習支援業</td><td>82：その他の教育、学習支援業</td><td>824：教養、技能教授業</td><td>8249：その他の教養、技能教授業</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            両者を比較すると、大分類が「G：情報通信業→O：教育、学習支援業」へと変更されていることがわかります。<br />
            大分類の変更は、「事業転換」に該当するため本事業は要件を満たしており、事業再構築補助金の申請が可能です。<br />
            <br />
            【売上高から申請枠を決定】<br />
            続いては売上高から申請枠を決定します。<br />
            <br />
            事業再構築補助金は、2022年1月以降の連続する6か月間のうち、任意の3か月の合計売上高が対2019～2021年の同3か月の合計売上高と比較して10％減少していれば「物価高騰対策・回復再生応援枠」での申請が可能です。<br />
            <br />
            本事業の2021年と2022年の売上高を比較すると以下のとおりです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'></th><th>6月</th><th>7月</th><th>8月</th><th>合計</th>
              </tr>
              <tr>
                <td>2021年</td><td>8,000</td><td>7,500</td><td>7,500</td><td>23,000</td>
              </tr>
              <tr>
                <td>2022年</td><td>6,000</td><td>5,500</td><td>6,000</td><td>17,500</td>
              </tr>
              <tr>
                <td>増減率(%)</td><td>-25%</td><td>-27%</td><td>-20%</td><td>-24%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            2021年と2022年の同月の売上を比較すると24％も売上が減少しています。<br />
            本事業は、10％減少の要件を満たしているため「物価高騰対策・回復再生応援枠」にて申請します。<br />
            <br />
            【補助金額の計算】<br />
            類型の要件を満たし、申請枠が決定したので経費の計算および補助金額の計算を行います。<br />
            なお、本事業にかかる経費は以下のとおりです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>建物の改装工事費用（オンラインスクール用に建物を改装）</th><th>￥2,000,000</th>
              </tr>
              <tr>
                <td>オンラインスクールシステムの開発費</td><td>￥3,500,000</td>
              </tr>
              <tr>
                <td>広告費</td><td>￥2,000,000</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            上記はすべて対象経費に含まれるため、合計した7,500,000円全額が本事業の申請金額になります。<br />
            <br />
            補助率についてですが、最新の公募要領によると中小企業の場合申請金額の2/3が補助されます。<br />
            また、従業員が6人〜20人の場合は600万円までが補助率3/4になるため、それぞれの補助率に当てはめて計算すると次のとおりです。<br />
            <br />
            （従業員数による補助率 3/4）6,000,000×3/4 = ①4,500,000<br />
            （補助率 2/3）1,500,000×2/3 = ②1,000,000<br />
            <br />
            ① + ② = 5,500,000<br />
            <br />
            本事業は申請金額7,500,000円に対し、最大5,500,000円が補助されます。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.虎の巻から見る情報通信業者の特徴</h2>
          </div>
          <p className="media_text_text">
            <br />
            虎の巻とは、経済産業省より公表されている事業再構築補助金に関する資料です。<br />
            <br />
            「事業再構築を考えるハードルが高い」<br />
            「良い事業計画書の作り方が分からない」<br />
            <br />
            このような悩みを解決するための事業検討の考え方と、有望な事業に共通する特徴が動画とガイドブックにてまとめられています。<br />
            <br />
            事業再構築補助金を検討されている方にはぜひご一読いただきたい資料のひとつです。<br />
            <br />
            本記事でも虎の巻にもとづいて、<br />
            情報通信業者の申請傾向<br />
            具体的な採択事例<br />
            情報通信業の申請で抑えるべきポイント<br />
            について解説します。<br />
          </p>
          <h3 className='media_text_text_title'>情報通信業者の申請傾向</h3>
          <p className="media_text_text">
            <img src={Image1} className='media_img' alt='' />
            上図は虎の巻に掲載されている情報通信業の傾向分析結果をまとめたものです。<br />
            縦軸が申請率を表し、横軸が採択率を表します。<br />
            右側のオレンジ部分に行くほど申請率、採択率が高く有望度の高い事業になります。<br />
            申請の際は、有望度の高い事業の中から自社で取り入れられるものがないか検討してみましょう。<br />
            <br />
            情報通信業の傾向としては、<br />
            ・デジタル技術活用・DX推進（デジタル技術の導入による業務フローの合理化や新たなサービスの立案など）<br />
            ・AI活用システム・サービス展開（AI技術を活用した既存事業の拡張や新サービスの展開など）<br />
            ・オンライン配信関連事業（コロナ禍でニーズが増した動画や音楽のライブ配信に関連する事業など）<br />
            など、既存事業と関連の深いIT関連の事業が申請率、採択率ともに高い傾向にあります。<br />
            <br />
            いずれも今後の成長が見込める分野であり、事業再構築補助金において評価されやすい事業と言えます。<br />
          </p>
          <h3 className='media_text_text_title'>具体的な採択事例</h3>
          <p className="media_text_text">
            ①レコード制作事業から、ライブコマースを配信できる「配信型スタジオ事業」への進出した事例<br />
            <br />
            新型コロナウイルスや物価高騰の影響でレコード制作事業の売上が減少。<br />
            市場が拡大しているライブコマースに着目し、配信型スタジオ事業へ進出し事業の回復を目指すとして採択された事業です。<br />
            <br />
            ライブコマースは新型コロナウイルスの緊急事態宣言を機に多くの企業が取り組みだした事業です。<br />
            市場が拡大している分野であり、今後の成長が見込まれることから採択されたと考えられます。<br />
            <br />
            ②メタバースを活用した英会話システムの運営企業への転換<br />
            <br />
            小学生を対象にした、メタバース（仮想空間）で英語学習ができる英会話システムの運営事業へ進出するとして採択された事例です。<br />
            <br />
            事業再構築補助金では、成長が見込まれる分野への進出や新型コロナウイルスに対応したサービスの提供などが評価されやすい傾向にあります。<br />
            <br />
            本事業は、市場が拡大しているメタバースの導入と、非対面によるサービスの提供といずれも満たしていることから評価を受け採択されたと考えられます。<br />
          </p>
          <h3 className='media_text_text_title'>宿泊事業の申請で抑えるべきポイント</h3>
          <p className="media_text_text">
            <strong>①市場ニーズのリサーチ</strong><br />
            <br />
            事業再構築補助金で採択されるためには、市場ニーズのリサーチが重要なポイントです。<br />
            なぜなら、市場ニーズのない事業は売上につながらないため評価されないからです。<br />
            どれだけ素晴らしい内容の事業計画書を作成しても、市場規模が小さければ投資回収ができないとの理由から不採択になる可能性があります。<br />
            <br />
            事業計画を立てる際は、進出する市場のデータや既存業者に関するリサーチを徹底する必要があります。<br />
            リサーチ内容をもとに、市場規模や将来の動向を根拠づけて論理的に説明できるようにしなければなりません。<br />
            非常に重要なポイントであるため、市場ニーズのリサーチは徹底的に行いましょう。<br />
            <br />
            <strong>②差別化を図る</strong><br />
            <br />
            事業再構築補助金では他社との差別化も重要なポイントのひとつです。<br />
            情報通信業の場合、<br />
            <br />
            デジタル技術・DX推進<br />
            AI活用システム・サービス展開<br />
            オンライン配信関連事業<br />
            <br />
            これらの事業が採択されやすい傾向にありますが、単に採択率が高いからという理由で申請しても不採択になる可能性が高いです。<br />
            なぜなら、有望度の高い事業はその分ライバルも多いからです。<br />
            <br />
            他社との差別化ができていなければ、魅力のない事業と評価されかねません。<br />
            既存事業の強みをどのように活かせるか、他社にはないサービスをどのようにして提供できるかなど、事業計画の段階から見つめ直すことが重要です。<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            情報通信業では、既存事業の強みを活かした事業への進出が採択されやすい傾向にあります。<br />
            <br />
            事業再構築補助金を活用することで売上の回復をはじめ、新たな事業へチャレンジできるなど、たくさんのメリットが得られます。<br />
            <br />
            事業再構築補助金を検討している方は、本記事や虎の巻を参考に事業計画を立ててみてください。<br />
            <br />
            【事業再構築補助金でお困りの方へ】<br />
            本記事を運営するトランク合同会社では、事業再構築補助金のサポートを行っております。<br />
            「内容が複雑で理解ができない」「補助金を活用したいけれど、申請が難しそう」など、お悩みを抱えている方はぜひご相談ください。<br />
            <br />
            当社は豊富な実績を有しており、採択に向けた適切なアドバイスが可能です。<br />
            今なら初回無料相談を実施しておりますので、興味がある方はお気軽にお問い合わせください。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


