import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import TopImage from "./../../../../../image/media/small/4/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const BlogText0001 = () => {

  const meta = {
    title: '【初めての補助金】小規模事業者持続化補助金の採択率を徹底解説',
    description: '本記事は、小規模事業者持続化補助金を申請したいけど、「対象事業者に含まれているかわからない！」という方へ向けて、小規模事業者持続化補助金の対象者の条件について解説させていただきます。本サイトでは、初めての方でも理解ができるようわかりやすくまとめさせていただきましたので、ぜひご参考にいただけますと大変助かります。加えて、本記事は公募要領を用いて説明させていただいておりますので、本記事を理解していただいた方は実際に公募要領をご一読いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2022/07/21</p>
          <h1 className="media_title">【初めての補助金】<br />小規模事業者持続化補助金の採択率を徹底解説</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <p className="media_text_text">
            <br />
            <br />
            <span className='media_text_text_box_title'>こんな方へ向けて</span><br />
            <br />
            本記事は、小規模事業者持続化補助金を申請したいけど、「対象事業者に含まれているかわからない！」という方へ向けて、小規模事業者持続化補助金の対象者の条件について解説させていただきます。<br />
            <br />
            本サイトでは、初めての方でも理解ができるようわかりやすくまとめさせていただきましたので、ぜひご参考にいただけますと大変助かります。<br />
            <br />
            加えて、本記事は公募要領を用いて説明させていただいておりますので、本記事を理解していただいた方は実際に公募要領をご一読いただければと思います。<br />
            <br />
            それでは、解説に進ませていただきます！<br />
          </p>
          
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">対象事業者の概要</AnchorLink></li>
                <li><AnchorLink href="#2" offset="100">小規模事業者持続化補助金の対象事業者</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">類型の概要</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">類型の詳細及び補助率</AnchorLink></li>
                <li><AnchorLink href="#5" offset="100">小規模事業者持続化補助金の類型要件</AnchorLink></li>
                <li><AnchorLink href="#6" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>

          <>
          <div id='6' className="media_text_title">
            <h2>６.類型の確認</h2>
          </div>
          <p className="media_text_text">
            <br />
            対象事業者の確認が終わったところで、次は類型（枠）について説明していきます。<br />
            <br />
            具体的に類型とは、<strong>「通常枠」</strong>、<strong>「賃金引上げ枠」</strong>、<strong>「卒業枠」</strong>、<strong>「後継者支援枠」</strong>、<strong>「創業枠」</strong>及び<strong>「インボイス枠」</strong>の６つに分かれており、各枠ごとに要件、補助率、上限金額が細かく提示されています。<br />
            <br />
            要件の概要については、以下にてご説明させていただきます。<br />
          </p>
          <h3 className='media_text_text_title'>通常枠</h3><br />
          <p className="media_text_text">
            事業規模の拡大を目指す中小企業等のための枠となっており、具体的には。新分野展開や業態転換、事業・業種転換等の取組、事業再編の取組などが当てはまります。<br />
          </p>
          <h3 className='media_text_text_title'>賃金引上げ枠</h3><br />
          <p className="media_text_text">
            従業員の賃金引き上げ（地域別最低賃金より+30円以上）を行う事業者さまを対象とする枠となっております。<br />
          </p>
          <h3 className='media_text_text_title'>卒業枠</h3><br />
          <p className="media_text_text">
            事業の拡大に意欲的な事業者さまを対象とした枠で、本補助金により小規模事業者等の枠を超えることを求められます。<br />
          </p>
          <h3 className='media_text_text_title'>後継者支援枠</h3><br />
          <p className="media_text_text">
            事業継承を行う事業者さまを対象とする枠となっており、「アトツギ甲子園」のファイナリストになった事業者さまを対象としています。<br />
          </p>
          <h3 className='media_text_text_title'>創業枠</h3><br />
          <p className="media_text_text">
            認定連携創業支援等事業者が実施した特定創業支援等事業による支援を公募締切時から過去３か年の間に受け、かつ、過去３か年の間に開業した事業者を対象としたものです。<br />
          </p>
          <h3 className='media_text_text_title'>インボイス枠</h3><br />
          <p className="media_text_text">
            2021年9月30日から2023年9月30日の期間の内、一度でも免税事業者であった又は免税事業者であることが見込まれる事業者のうち、適格請求書発行事業者に登録した事業者が対象となります。<br />
          </p>
          </>
          
          <>
          <div id='8' className="media_text_title">
            <h2>８.小規模事業者持続化補助金の採択率</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            小規模事業者持続化補助金の採択率は、比較的最近高くなっております。<br />
            本記事では、【一般型】【低リスク感染型ビジネス枠】の２つを紹介させていただきます。<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>【一般型】</h3><br /> 
          <p className="media_text_text">
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>項目</th><th>申請件数</th><th>採択件数</th><th>採択率</th>
              </tr>
              <tr>
                <td><strong>第１次</strong></td>
                <td>8,044</td>
                <td>7,308</td>
                <td>90.9%</td>
              </tr>
              <tr>
                <td><strong>第２次</strong></td>
                <td>19,154</td>
                <td>12,478</td>
                <td>65.1%</td>
              </tr>
              <tr>
                <td><strong>第３次</strong></td>
                <td>13,642</td>
                <td>7,040</td>
                <td>51.6%</td>
              </tr>
              <tr>
                <td><strong>第４次</strong></td>
                <td>16,126</td>
                <td>7,128</td>
                <td>44.2%</td>
              </tr>
              <tr>
                <td><strong>第５次</strong></td>
                <td>12,738</td>
                <td>6,869</td>
                <td>53.9%</td>
              </tr>
              <tr>
                <td><strong>第６次</strong></td>
                <td>9,914</td>
                <td>6,846</td>
                <td>69.1%</td>
              </tr>
              <tr>
                <td><strong>第７次</strong></td>
                <td>9,339</td>
                <td>6,517</td>
                <td>69.8%</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <h3 className='media_text_text_title'>低リスク感染型ビジネス枠</h3><br /> 
          <p className="media_text_text">
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>項目</th><th>申請件数</th><th>採択件数</th><th>採択率</th>
              </tr>
              <tr>
                <td><strong>第１次</strong></td>
                <td>7,827</td>
                <td>3,512</td>
                <td>44.8%</td>
              </tr>
              <tr>
                <td><strong>第２次</strong></td>
                <td>10,205</td>
                <td>5,361</td>
                <td>52.5%</td>
              </tr>
              <tr>
                <td><strong>第３次</strong></td>
                <td>8,056</td>
                <td>5,022</td>
                <td>62.3%</td>
              </tr>
              <tr>
                <td><strong>第４次</strong></td>
                <td>8,243</td>
                <td>5,780</td>
                <td>70.1%</td>
              </tr>
              <tr>
                <td><strong>第５次</strong></td>
                <td>6,208</td>
                <td>4,138</td>
                <td>66.6%</td>
              </tr>
              <tr>
                <td><strong>第６次</strong></td>
                <td>11,721</td>
                <td>8,040</td>
                <td>68.6%</td>
              </tr>
            </tbody>
          </table>
          </>

          <>
          <div id='6' className="media_text_title">
            <h2>６.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            本記事では、小規模事業者持続化補助金を申請したい事業者さまへ向けて、小規模事業者持続化補助金の概要について記事にさせていただきました。<br />
            <br />
            公募要領を各所に添付させていただきましたが、ご覧いただけば分かる通り難しい内容となっております。<br />
            そこで、もし、本格的に小規模事業者持続化補助金の導入を検討されていましたら、弊社含め補助金に詳しい事業者へご相談ください。<br />
            <br />
            ご相談は、画面右上の補助金に関する相談をするから問い合わせにて、お待ちしております。<br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
        </div>

        <div className='media_topic'>
          <div className='media_topic_service_top'>
            <h3>memorii help</h3>
            <h4>補助金/助成金の申請サポート</h4>
            <p>初めての方へも丁寧に補助金/助成金のご説明をさせていただき、さらに、電子申請、交付申請のサポートまで、行わせて頂くサービスとなっております。</p>
          </div>
          <div className='media_recommend_title'>おすすめの記事</div>
          <div className='media_recommend_content'></div>
          <div className='media_recommend_content'></div>
          <div className='media_recommend_content'></div>
          <div className='media_recommend_content'></div>
        </div>
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


