// 活用事例：飲食事業

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "../../nav/index";
import Topic from "../topic";
import TopImage from "./../../../../../image/media/business/39/top.png";
import Image1 from "./../../../../../image/media/business/29/1.png";
import Footer from '../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」飲食事業の採択事例を大公開！',
    description: '本記事では、事業再構築補助金の飲食業 採択事例を紹介しております。飲食業を営んでいて事業再構築補助金の申請を検討されている方は、本記事をご参考に検討を行っていただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」飲食事業の採択事例を大公開！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            本記事は、「新たに飲食事業を始めることを考えている」方や「現在行っている飲食事業のキッチンカー販売等を検討している」という方に向けて、事業再構築補助金の概要や採択事例をまとめたものになっています。<br />
            <br />
            当社では無料相談も行っておりますので、ご質問点等ございましたらお気軽にご連絡くださいませ。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業再構築補助金の対象となる飲食事業者</span></li>
              <li><span>飲食店における事業再構築補助金の活用方法</span></li>
              <li><span>過去の飲食事業者採択例</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            なお、本記事は「第10回事業再構築補助金 公募要領」を参考に作成しております。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo.pdf' target="_blank" rel="nofollow noopener noreferrer">
              リンク：事業再構築補助金 公募要領
            </a><br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">飲食事業における活用方法</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金の対象となる経費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">飲食事業での事業再構築類型の具体例</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金での申請例</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">虎の巻から見る飲食事業者の特徴</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">o	飲食事業者の申請傾向</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">o	具体的な採択事例</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">o	飲食事業の申請で抑えるべきポイント</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.飲食事業における活用方法</h2>
          </div>
          <p className="media_text_text">
            <br />
            ここからは、飲食事業にて新たな事業展開を検討されている皆様に向けた、具体的な事業再構築補助金の活用方法を記載いたします。<br />
            <br />
            具体的な飲食事業での展開事例等も紹介しておりますので、新たな事業展開で悩まれている方も含め、是非ご覧ください。<br />
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金の対象となる経費</h3><br />
          <p className="media_text_text">
            事業再構築補助金の対象となる主な経費は、以下の項目となっております。第10回公募ですと2022年12月2日以降に発注を行った経費が対象となりますので、過去の発注も含めてご確認ください。なお、補助金の対象となる経費は補助事業のみに使用する必要がございますので、ご注意ください。<br />
            <br />
            <strong>【事業再構築補助金の対象となる主な経費】</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>経費項目</th><th>詳細</th><th>具体例</th>
              </tr>
              <tr>
                <td>建物費</td>
                <td>事業で使用する事務所・施設の建設や改修にかかる経費</td>
                <td>・製造工場の建設費用<br />・キッチンカーへの改装費用</td>
              </tr>
              <tr>
                <td>機械装置・システム費</td>
                <td>事業で使用する機械装置や新たに開発した設備・システムの開発経費</td>
                <td>・厨房設備にかかる費用<br />・EC販売管理サイトの開発費</td>
              </tr>
              <tr>
                <td>技術導入費</td>
                <td>知的財産権等の導入に要する経費</td>
                <td>・FC店出店時のロゴ貸与費</td>
              </tr>
              <tr>
                <td>外注費</td>
                <td>事業に必要な加工・設計等を外部に委託する経費</td>
                <td>・新店舗のデザイン費用<br />・店舗ロゴの作成費用</td>
              </tr>
              <tr>
                <td>広告宣伝・販売促進費</td>
                <td>事業で提供する製品・サービスに係る広告宣伝に要する経費</td>
                <td>・チラシの作成・配布費用<br />・インターネットの広告掲載費</td>
              </tr>
              <tr>
                <td>研修費</td>
                <td>事業の教育訓練・講座受講に係る経費</td>
                <td>・パンの製造研修費</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            上記の項目が、主な対象経費となっております。飲食事業で補助金を申請されるお客様は建物費や機械装置・システム費、広告宣伝費をご活用されることが多く、新店舗出店時の内装工事や厨房設備を購入される際にご活用いただくことが多いです。<br />
            <br />
            一方で、FC店加盟時のロイヤリティや運搬車等は対象経費とならず、採択された場合でも後の審査で認められない場合がございます。そのため、申請を行う前に経費が対象かどうかということはしっかり確認するようにしましょう。<br />
            <br />
            また、当社では対象経費の確認や金額の算出を初回相談無料で行っておりますので、対象経費の確認等ございましたら、お気軽にご連絡ください。<br />
          </p>
          <h3 className='media_text_text_title'>飲食事業での事業再構築類型の具体例</h3>
          <p className="media_text_text">
            上述したとおり、事業再構築補助金の申請を行うためには「事業再構築要件」に合致する必要があり、以下類型のいずれか1つに当てはまる場合要件を満たします。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th>類型を満たすための要件</th><th>産業分類に基づく変更</th>
              </tr>
              <tr>
                <td>新市場進出</td>
                <td>
                  ①製品又は提供方法を大きく変更する<br />
                  ②新たな市場への進出を行う<br />
                  ③3～5年の事業計画期間終了後に、新製品の売上高が全体の10%以上となる計画を策定する。
                </td>
                <td>無</td>
              </tr>
              <tr>
                <td>事業転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3～5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。
                </td>
                <td>主な事業を変更(中・小・細分類)</td>
              </tr>
              <tr>
                <td>業種転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3～5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。
                </td>
                <td>主な業種を変更<br />(大分類)</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            事業再構築類型は主に上記の3種類が存在しており、新市場進出型は製品又は提供方法の変更、事業転換は事業内容の変更、業種転換は業種の変更が必要となっております。<br />
            <br />
            また、上記の業種や事業内容の変更は日本産業分類(https://www.soumu.go.jp/toukei_toukatsu/index/seido/sangyo/02toukatsu01_03000023.html)にて審査されます。<br />
            <br />
            <strong>【飲食業での具体例】</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th>日本産業分類での変更</th><th>変更内容</th>
              </tr>
              <tr>
                <td>新市場進出</td>
                <td>
                  7631：そば・うどん店<br />
                  →そば・うどん店
                </td>
                <td>店舗でそばを提供していた事業者様が、接触の少ないキッチンカーでのテイクアウト販売を開始</td>
              </tr>
              <tr>
                <td>事業転換</td>
                <td>
                  765：酒場、ビヤホール<br />
                  →762：専門料理店
                </td>
                <td>居酒屋を行っていた店舗が、人気メニューであるラーメンの昼提供を行い、売上を増加させる</td>
              </tr>
              <tr>
                <td>業種転換</td>
                <td>
                  L：専門サービス業<br />
                  →M：飲食サービス業
                </td>
                <td>飲食コンサルティング事業を行っていた事業者が実店舗での飲食店舗運営を新しく開始する</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            飲食事業に関係する事業再構築類型の変更例は上記の通りとなっております。今まで飲食事業を行っていた事業者様も、内容によっては事業再構築補助金に申請を行える可能性がございますので、是非ご検討ください。
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金における申請例</h3>
          <p className="media_text_text">
            ここでは、事業再構築補助金事務局により公表されている例を基に、事業再構築補助金申請時の補助金額や対象経費の説明を行います。<br />
            <br />
            申請枠や従業員数により補助金額の上限や補助率は変わってきますので、申請枠の補助金額及び補助率はしっかりと確認したうえで、申請に望んでください。<br />
            <br />
            <strong>【事業内容】</strong><br />
            お米の卸事業を行っていた事業者が、業績回復を目指して小売販売を行う。また、小売販売ではEC販売及びキッチンカー販売を活用し、収益性の改善を見込む<br />
            <br />
            事業場所：秋田県大仙市<br />
            日本産業分類：卸売業・小売業(大分類I)→飲食料品卸売業(中分類52)→農畜産物・水産物卸売業(521)→米麦卸売業(5211)<br />
            従業員数：25名<br />
            売上高：1億円<br />
            <br />
            投資内容<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>ECサイト構築費用</th><th>￥8,000,000</th>
              </tr>
              <tr>
                <td>車両購入費用</td>
                <td>￥4,000,000</td>
              </tr>
              <tr>
                <td>キッチンカー内装工事費用</td>
                <td>￥4,000,000</td>
              </tr>
              <tr>
                <td>キッチンカー内装工事費用</td>
                <td>￥4,000,000</td>
              </tr>
              <tr>
                <td>インターネット広告費用</td>
                <td>￥3,500,000</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            上記の場合、米の卸売業から小売業への展開となるため、下表を参考にすると日本産業分類上の中分類が変化していることが分かります。そのため、本事業の再構築類型は事業転換として申請を進めます。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>日本産業分類</th><th>大分類</th><th>中分類</th><th>小分類</th><th>細分類</th>
              </tr>
              <tr>
                <td>既存事業</td><td>I：卸売業・小売業</td><td>52：飲食料品卸売業</td><td>521：農畜産物・水産物卸売業</td><td>5211：米麦卸売業</td>
              </tr>
              <tr>
                <td>新規事業</td><td>I：卸売業・小売業</td><td>58：飲食料品小売業</td><td>589：その他の飲食料品小売業</td><td>5896：米穀物小売業</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            また、月別の売上を見てみると、2022年の5～7月の売上高合計は2021年同月と比較すると44%減少していることが分かります。そのため、本事業は物価高騰対策・回復再生応援枠にて申請する事とします。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'></th><th>5月</th><th>6月</th><th>7月</th><th>合計</th>
              </tr>
              <tr>
                <td>2021年</td><td>1,500</td><td>1,000</td><td>2,000</td><td>4,500</td>
              </tr>
              <tr>
                <td>2022年</td><td>1,000</td><td>1,000</td><td>500</td><td>2,500</td>
              </tr>
              <tr>
                <td>増減率(%)</td><td>-33%</td><td>0%</td><td>-75%</td><td>-44%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            上記の内容を踏まえたうえで投資内容を確認すると、対象になる経費がECサイト構築費・キッチンカー改装費・広告費となるため、本事業の申請金額は15,500,000円となります。下記の計算より、補助金額の最大は11, 222,222円となります。<br />
            <br />
            【計算式】<br />
            10,666,666×3/4+(15,500,000-10,666, 666)×2/3=11,222,222<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.虎の巻から見る飲食事業の特徴</h2>
          </div>
          <p className="media_text_text">
            <br />
            ここからは、経済産業省が事業再構築補助金の過去データを分析した「事業再構築虎の巻」(https://jigyou-saikouchiku.go.jp/pdf/jigyokeikaku_guidebook.pdf)を用いて、事業再構築補助金に申請・採択された飲食事業者の特徴を記載していきます。<br />
            <br />
            虎の巻では業種別テーマの分析のみならず、事業計画書記載についての注意点等も記載しているため、事業再構築補助金の申請を検討している方は是非ご一読ください。<br />
          </p>
          <h3 className='media_text_text_title'>飲食事業者の申請傾向</h3>
          <p className="media_text_text">
            <img src={Image1} className='media_img' alt='' />
            事業再構築補助金における飲食事業者の申請傾向は、上図の様になっています。縦軸が申請率、横軸が採択率となっており、右上の事業が多く申請されており、採択率も高い事業となっております。<br />
            <br />
            多く申請されている項目としては新たな事業への転換である観光・宿泊や食肉・焼肉事業や、新たな販売方法である通信・ECや冷凍食品事業の開始が読み取れる。その中でも新たな販売方法は採択率も高いものとなっており、事業再構築補助金の目的に沿った事業展開だということが出来る。<br />
            <br />
            また、ビール製造・販売や自販機、中食事業への展開は平均採択率が非常に高くなっている。理由としては、地元ブランドのオリジナルビール製造による地域活性化や自販機、中食といったポストコロナ時代への対応があげられ、事業を通じた地域への貢献や新たなニーズの獲得を求められていることが分かる。<br />
          </p>
          <h3 className='media_text_text_title'>具体的な申請例</h3>
          <p className="media_text_text">
            ここからは、第8回事業再構築補助金で採択された飲食事業者の事例を紹介していきます。採択事例は事業再構築補助金採択結果ページ(https://jigyou-saikouchiku.go.jp/result.php)にて公開されておりますので、気になる方は地区別・業種別での採択例をお確かめください。<br />
            <br />
            <strong>①老舗焼き肉店によるEC・テイクアウト販売の開始及び販路開拓の採択事例</strong><br />
            <br />
            新型コロナウイルスにより人々が集まって食事をする回数が減少する中、テイクアウトやお持ち帰りといった中食産業への事業展開は、ご検討されている飲食事業者の皆様も多いと思われます。<br />
            <br />
            事業再構築補助金においても、通信・ECや中食産業の事業は採択率が平均と比較して大幅に高くなっており、比較的採択されやすい事業内容であると考えられます。<br />
            <br />
            採択率が高くなっている理由としては、テイクアウト事業等の販売により下記の審査項目で高得点を貰える可能性が高いことが考えられるため、上記の事業展開にて再構築補助金を申請される事業者様は、以下のポイントに注意して記載を行うことをオススメします。<br />
            <br />
            ・事業実施のための体制を有している<br />
            →既存事業にて調理方法等のノウハウを有しているため、事業実施の確実性が高い<br />
            <br />
            ・新型コロナウイルスが与える影響を乗り越えて、V字回復を達成するために有効な投資<br />
            →市場が縮小している外食産業から拡大している中食産業に変更するため、ポストコロナ時代の需要を捉えた変更ということが出来る<br />
            <br />
            <strong>②カフェによるサウナ付きグランピング事業の開始という採択事例</strong><br />
            <br />
            現在、新型コロナウイルスの影響も相まって人々の健康意識は高まっています。さらに接触を避けたいという人が増えたことから、大自然の中で過ごすグランピング人気は高まりを見せています。その中にサウナを設置することで、健康意識というニーズを獲得できる事業だと考えることが出来ます。<br />
            <br />
            実際に飲食事業者様が新たに観光・宿泊事業を始める取り組みは非常に人気になっており、各テーマの中で最も高い採択率となっています。しかし、採択率は平均と比較して高くはないので、自社ならではの強みや新規事業の差別化要素をどのように伝えるかが、採択の分かれ道となりそうです。<br />
            <br />
            ここでは、過去の採択例等を踏まえて記載すると差別化できるポイントを記載いたしますので、多くの事業者が申請しているようなテーマの事業転換を行う事業者様は、以下のポイントを踏まえた作成を考えてみてください。<br />
            <br />
            ・特産品や地形等、地域の特色を活かした事業展開を行うことで差別化している<br />
            →事業の優位性が伝わりやすいうえ、地域の事業者に対する波及効果が高く大きな経済成長が見込まれるため、高得点になりやすい<br />
            <br />
            ・実施体制や実施スケジュール、課題の解決方法を明記する<br />
            →全く異なる事業への転換になるため、実際に事業実施が行えるかという審査官の疑問に対して、事業実施方法をしっかりと記載することで、実現性を担保した高得点を狙う<br />
          </p>
          <h3 className='media_text_text_title'>飲食事業の申請で抑えるべきポイント</h3>
          <p className="media_text_text">
            <strong>・新型コロナウイルスへの対応</strong><br />
            　飲食事業者が申請したテーマの中で、新型コロナウイルスに関する事業展開は軒並み高い採択率となっております。例としてはECの活用やキッチンカー事業の展開による顧客層拡大の他、健康食品事業への転換やランチ事業の開始等ポストコロナ時代のニーズをくみ取った事業展開も高い採択率を有しています。<br />
            <br />
            また、採択率があまり高くない観光・宿泊事業やカフェ・イートインビジネスへの展開事業でもポストコロナ時代の課題及び解決策が記載されているか非常に重要であり、採択を分けるポイントであると考えられるため、コロナへの対応へ注力した計画書の作成を心がけましょう。<br />
            <br />
            <strong>・独自性の発揮</strong><br />
            　飲食事業者が申請したテーマの中で、特に採択率が低くなっているのがフランチャイズ展開やキッチン事業といった、事業者の独自性を出すのが難しい事業展開となっています。このような事業は実現性で高い評価を得ることが出来るものの、新規性や再構築感を発揮することが難しく、不採択になっていると考えられます。<br />
            <br />
            そのため、上述した事業展開以外でも、事業再構築補助金のメインテーマである新規性や自社ならではのリソース活用を記載することで、採択に近づくことが出来ると思われます。<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、飲食事業での事業再構築補助金申請を検討されている事業者様へ向けて、飲食事業の採択例や申請方法を説明させていただきました。<br />
            <br />
            しかし、事業再構築補助金は申請要件が非常に難解になっているうえ、各事業者様の特徴を活かした計画書の作成が求められます。<br />
            <br />
            本記事を運営している会社であるトランク合同会社は、飲食事業者様の事業再構築補助金採択例を豊富に有しており、各事業者様の特徴を前面に押し出した申請サポートを行っています。現在は無料相談も承っておりますので、事業再構築補助金申請について興味のある事業者様は、是非下記フォームよりお気軽にご連絡くださいませ。<br />
            <br />
            この度は、記事を拝見いただき誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


