import firebase from "firebase/compat/app"

const BlogText0001 = () => {

  // 完了
  function entry() {
    firebase.firestore().collection('event').doc('info').get()
    .then(async (doc) => {
      var present = doc.data().present;
      
      var entryPeople = [];
      await firebase.firestore().collection('event').doc(doc.data().present).collection('tournament').get()
      .then(snapShot => {
        snapShot.forEach((doc) => {
          entryPeople.push(doc.id);
        });
      })
      
      firebase.firestore().collection('event').doc(doc.data().present)
      .update({
        round1: entryPeople.length % 2 === 0 ? entryPeople : entryPeople.slice(0, entryPeople.length - 1),
        round2: entryPeople.length % 2 === 0 ? [] : entryPeople.slice(entryPeople.length - 1, entryPeople.length),
        entryPeople : entryPeople,
      });

      firebase.firestore().collection('event').doc('info')
      .update({
        max_round: entryPeople.length <= 2 ? 1 : entryPeople.length <= 4 ? 2 : entryPeople.length <= 8 ? 3 : entryPeople.length <= 16 ? 4 : entryPeople.length <= 32 ? 5 : entryPeople.length <= 64 ? 6 : 0,
        now_round: 1,
        status: [ false, true, false ],
      });

    });
  }

  return (
    <>
    <button onClick={() => entry()}>参加者の集計 {'=>'} 1回戦の用意</button>
    </>
  );
}

export default BlogText0001;