import './styles.css';
import React from "react";
import Service from "./../../../../image/photo/service.png";

const PhotoStructure = () => {
  
  return (
    <>
    <div className='photo_structure_title'>memorii photoの仕組み</div>
    <div className='photo_structure'>
      <img src={Service} alt='' />
    </div>
    </>
  );
}

export default PhotoStructure;
