// https://www.kkj.go.jp/doc/ja/api_guide.pdf
// https://www.kkj.go.jp/api/?Query=%E3%82%BB%E3%83%9F%E3%83%8A%E3%83%BC
// https://kkj.go.jp/api/?Query=1&CFT_Issue_Date=2023-08-19/2023-08-20&Count=1000

import './styles.css';
import firebase from "firebase/compat/app";
import { useState } from "react";

const BlogText0001 = () => {

  const [ count,  ] = useState(0);
  const [ text, settext ] = useState();
  const [ textChange, settextChange ] = useState();
  const [ dane, setDane ] = useState(false);

  // useEffect(() => {
  //   firebase.firestore().collection("bid")
  //   .get().then(snapShot => {
  //     var count = 0;
  //     snapShot.forEach((doc) => {
  //       count = count + 1;
  //     });
  //     setCount(count);
  //   });
  // }, []);
  
  var array = [];

  function line() {
    settextChange(text.replace(/\r?\n/g, ''));
  }
  
  function add() {
    
    var count = 0;

    for (let i = 0; i < 36; i++) {
      array.push(textChange.slice(count, textChange.indexOf("</SearchResult>", count) + 15))
      count = textChange.indexOf("</SearchResult>", count) + 15;
    }
    
    if (array.length === 36) {
      for (let i = 0; i < 36; i++) {
        
        var id = "";

        var ResultId = "";
        var Key = "";
        var ExternalDocumentURI = "";
        var ProjectName = "";
        var Date = "";
        var FileType = "";
        var FileSize = "";
        var LgCode = "";
        var PrefectureName = "";
        var CityCode = "";
        var CityName = "";
        var OrganizationName = "";
        var Certification = "";
        var CftIssueDate = "";
        var PeriodEndTime = "";
        var Category = "";
        var ProcedureType = "";
        var Location = "";
        var TenderSubmissionDeadline = "";
        var OpeningTendersEvent = "";
        var ItemCode = "";
        var ProjectDescription = "";

        if (array[i].includes('<ResultId>')) {
          ResultId = array[i].slice(array[i].indexOf("<ResultId>") + 10, array[i].indexOf("</ResultId>"));
        }

        if (array[i].includes('<Key>')) {
          Key = array[i].slice(array[i].indexOf("<Key>") + 5, array[i].indexOf("</Key>"));
          id = array[i].slice(array[i].indexOf("<Key>") + 15, array[i].indexOf("</Key>") - 4);
        }

        if (array[i].includes('<ExternalDocumentURI>')) {
          ExternalDocumentURI = array[i].slice(array[i].indexOf("<ExternalDocumentURI>") + 21, array[i].indexOf("</ExternalDocumentURI>"));
        }

        if (array[i].includes('<ProjectName>')) {
          ProjectName = array[i].slice(array[i].indexOf("<ProjectName>") + 13, array[i].indexOf("</ProjectName>"))
        }

        if (array[i].includes('<Date>')) {
          Date = array[i].slice(array[i].indexOf("<Date>") + 6, array[i].indexOf("</Date>"))
        }

        if (array[i].includes('<FileType>')) {
          FileType = array[i].slice(array[i].indexOf("<FileType>") + 10, array[i].indexOf("</FileType>"))
        }

        if (array[i].includes('<FileSize>')) {
          FileSize = array[i].slice(array[i].indexOf("<FileSize>") + 10, array[i].indexOf("</FileSize>"))
        }

        if (array[i].includes('<LgCode>')) {
          LgCode = array[i].slice(array[i].indexOf("<LgCode>") + 8, array[i].indexOf("</LgCode>"))
        }

        if (array[i].includes('<PrefectureName>')) {
          PrefectureName = array[i].slice(array[i].indexOf("<PrefectureName>") + 16, array[i].indexOf("</PrefectureName>"))
        }

        if (array[i].includes('<CityCode>')) {
          CityCode =  array[i].slice(array[i].indexOf("<CityCode>") + 10, array[i].indexOf("</CityCode>"))
        }

        if (array[i].includes('<CityName>')) {
          CityName = array[i].slice(array[i].indexOf("<CityName>") + 10, array[i].indexOf("</CityName>"))
        }

        if (array[i].includes('<OrganizationName>')) {
          OrganizationName = array[i].slice(array[i].indexOf("<OrganizationName>") + 18, array[i].indexOf("</OrganizationName>"))
        }

        if (array[i].includes('<Certification>')) {
          Certification = array[i].slice(array[i].indexOf("<Certification>") + 15, array[i].indexOf("</Certification>"))
        }

        if (array[i].includes('<CftIssueDate>')) {
          CftIssueDate = array[i].slice(array[i].indexOf("<CftIssueDate>") + 14, array[i].indexOf("</CftIssueDate>"))
        }

        if (array[i].includes('<PeriodEndTime>')) {
          PeriodEndTime = array[i].slice(array[i].indexOf("<PeriodEndTime>") + 15, array[i].indexOf("</PeriodEndTime>"))
        }

        if (array[i].includes('<Category>')) {
          Category = array[i].slice(array[i].indexOf("<Category>") + 10, array[i].indexOf("</Category>"))
        }

        if (array[i].includes('<ProcedureType>')) {
          ProcedureType = array[i].slice(array[i].indexOf("<ProcedureType>") + 15, array[i].indexOf("</ProcedureType>"))
        }

        if (array[i].includes('<Location>')) {
          Location = array[i].slice(array[i].indexOf("<Location>") + 10, array[i].indexOf("</Location>"))
        }

        if (array[i].includes('<TenderSubmissionDeadline>')) {
          TenderSubmissionDeadline = array[i].slice(array[i].indexOf("<TenderSubmissionDeadline>") + 26, array[i].indexOf("</TenderSubmissionDeadline>"))
        }

        if (array[i].includes('<OpeningTendersEvent>')) {
          OpeningTendersEvent = array[i].slice(array[i].indexOf("<OpeningTendersEvent>") + 21, array[i].indexOf("</OpeningTendersEvent>"))
        }

        if (array[i].includes('<ItemCode>')) {
          ItemCode = array[i].slice(array[i].indexOf("<ItemCode>") + 10, array[i].indexOf("</ItemCode>"))
        }

        if (array[i].includes('<ProjectDescription>')) {
          ProjectDescription = array[i].slice(array[i].indexOf("<ProjectDescription>") + 20, array[i].indexOf("</ProjectDescription>"))
        }

        var Attachment_exist = (array[i].match(/<Attachment>/g) || []).length;
        var Attachment_pick = array[i].slice(array[i].indexOf("<Attachments>") + 13, array[i].indexOf("</Attachments>"));
        var Attachment_pick_item = [];

        var Attachment_array = [];

        if (Attachment_exist !== 0) {

          Attachment_pick_item = Attachment_pick.split('</Uri>');

          for (let i = 0; i < Attachment_pick_item.length - 1; i++) {

            var Name1 = (Attachment_pick_item[i].indexOf("<Name>", 0) + 6);
            var Name2 = (Attachment_pick_item[i].indexOf("</Name>", 0));
            var Http = (Attachment_pick_item[i].indexOf("http", 0));

            Attachment_array.push({name: Attachment_pick_item[i].slice(Name1, Name2), uri: Attachment_pick_item[i].slice(Http, - 4)})
          }
        }

        firebase.firestore().collection('bid').doc(id)
        .set({
          ResultId : ResultId,
          Key : Key,
          ExternalDocumentURI : ExternalDocumentURI,
          ProjectName : ProjectName,
          Date : Date,
          FileType : FileType,
          FileSize : FileSize,
          LgCode : LgCode,
          PrefectureName : PrefectureName,
          CityCode : CityCode,
          CityName : CityName,
          OrganizationName : OrganizationName,
          Certification : Certification,
          CftIssueDate : CftIssueDate,
          PeriodEndTime : PeriodEndTime,
          Category : Category,
          ProcedureType : ProcedureType,
          Location : Location,
          TenderSubmissionDeadline : TenderSubmissionDeadline,
          OpeningTendersEvent : OpeningTendersEvent,
          ItemCode : ItemCode,
          ProjectDescription : ProjectDescription,
          Attachment : Attachment_array,
        }).then(() => setDane(true));
      }
    }
  }

  function confirm() {
    firebase.firestore().collection("bid")
    .get().then(snapShot => {
      snapShot.forEach((doc) => {
        if (typeof doc.data().Date === 'string') {
          firebase.firestore().collection("bid").doc(doc.id)
          .update({
            Date : new Date(doc.data().Date),
          }) 
        }
      });
    });

  }

  return (
    <>
    <div className='case_add'>
      <div className='case_add_title'>
        <h4 onClick={() => confirm()}>「案件の窓口」の案件追加_{count}件</h4>
        <h5>{dane ? "完了" : "不完全"}</h5>
      </div>
      <div className='case_add_text'>
        <textarea onChange={(e) => settext(e.target.value)}></textarea>
        <div>{textChange}</div>
      </div>
      <div className='case_add_btn'>
        <button onClick={() => line()}>改行</button>
        <button onClick={() => add()}>追加</button>
      </div>
    </div>
    </>
  );
}

export default BlogText0001;