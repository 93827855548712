import DocumentMeta from 'react-document-meta';
import './styles.css';
import Nav from './nav/index';
import Header from './header/index';
import Subsidy from './subsidy/index';
import Service1s from './service1/index';
import Service2s from './service2/index';
import Service3s from './service3/index';
import Service4s from './service4/index';
import Slider from './slider/index';
import Blog from './blog/index';
import Contact from './contact/index';
import Footer from './footer/index';

const Help = () => {

  const meta = {
    title: '美容業界専門の補助金申請サポート|memorii help',
    description: '美容室に特化した補助金申請サポート。弊社のサービスでは、補助金の手続きを簡略化し、「申請まで３つの手順」のみで申請まで行う事が出来ます。相談対応も可能ですので、ご気軽にお問い合わせください。',
    canonical: 'https://trankllc.com/help',
    meta: {charset: 'utf-8',}
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <Header />
      <Subsidy />
      <Service1s />
      <Service2s />
      <Service3s />
      <Service4s />
      <div className='help_result_back'>
        <div>
          <Slider />
        </div>
      </div>
      <Contact />
      <div className='help_result_blog'>
        <Blog />
      </div>
      <div className='help_footer'>
        <Footer />
      </div>
      {/* 
       */}
    </DocumentMeta>
    </>
  );
}

export default Help;
