import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Image1 from "./../../../../../image/media/small/1/1.png";
import TopImage from "./../../../../../image/media/small/2/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const BlogText0001 = () => {

  const meta = {
    title: '【初めての補助金】小規模事業者持続化補助金の対象条件を徹底解説',
    description: '本記事は、小規模事業者持続化補助金を申請したいけど、「対象事業者に含まれているかわからない！」という方へ向けて、小規模事業者持続化補助金の対象者の条件について解説させていただきます。本サイトでは、初めての方でも理解ができるようわかりやすくまとめさせていただきましたので、ぜひご参考にいただけますと大変助かります。加えて、本記事は公募要領を用いて説明させていただいておりますので、本記事を理解していただいた方は実際に公募要領をご一読いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2022/07/21</p>
          <h1 className="media_title">【初めての補助金】<br />小規模事業者持続化補助金の対象条件を徹底解説</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <p className="media_text_text">
            <br />
            <br />
            <span className='media_text_text_box_title'>こんな方へ向けて</span><br />
            <br />
            本記事は、小規模事業者持続化補助金を申請したいけど、「対象事業者に含まれているかわからない！」という方へ向けて、小規模事業者持続化補助金の対象者の条件について解説させていただきます。<br />
            <br />
            本サイトでは、初めての方でも理解ができるようわかりやすくまとめさせていただきましたので、ぜひご参考にいただけますと大変助かります。<br />
            <br />
            加えて、本記事は公募要領を用いて説明させていただいておりますので、本記事を理解していただいた方は実際に公募要領をご一読いただければと思います。<br />
            <br />
            それでは、解説に進ませていただきます！<br />
          </p>

          {/* <div className='media_contents'>
            <span>本記事の構成</span><br /><br />
            <ol>
              <li><AnchorLink href="#1" offset="100">対象事業者の概要</AnchorLink></li>
              <li><AnchorLink href="#2" offset="100">小規模事業者持続化補助金の対象事業者</AnchorLink></li>
              <li><AnchorLink href="#3" offset="100">類型の概要</AnchorLink></li>
              <li><AnchorLink href="#4" offset="100">類型の詳細及び補助率</AnchorLink></li>
              <li><AnchorLink href="#5" offset="100">小規模事業者持続化補助金の類型要件</AnchorLink></li>
              <li><AnchorLink href="#6" offset="100">まとめ</AnchorLink></li>
            </ol>
          </div> */}

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">対象事業者の概要</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">小規模事業者持続化補助金の対象事業者</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">類型の概要</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">類型の詳細及び補助率</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">小規模事業者持続化補助金の類型要件</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>１.対象事業者の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            対象事業者の判断をしていただく前に、どんな条件があるのかをざっくり説明させていただきます。<br />
            <br />
            まず、対象事業者の要件は、小規模事業者持続化補助金 全体の要件と類型ごとの要件の２つに分かれており、以下のような違いがあります。<br />
            <br />
            ①<strong>小規模事業者持続化補助金 全体の要件</strong><br />
            小規模事業者持続化補助金 全体の要件は、こちらの要件が適合されていないと類型に関わらず申請することができないというものです。<br />
            <br />
            ②<strong>類型ごとの要件</strong><br />
            類型ごとの要件は、各類型に即した要件となっており、その類型で申請する場合に適合しなければいけないものとなっております。<br />
            <br />
            そのため、①小規模事業者持続化補助金の要件が適合しているか確認した上で、選択した類型の要件に適合するか確認するという２段階の確認が必要になるということです。<br />
            <br />
            本記事では、①小規模事業者持続化補助金の要件について説明させていただきますが、別記事にて②類型ごとの要件をご用意させていただきましたので、そちらもご参考にいただければと思います。<br />
          </p>
          </>
          
          <>
          <div id='2' className="media_text_title">
            <h2>２.対象事業者の確認</h2>
          </div>
          <p className="media_text_text">
            <br />
            全体の流れ、申請までの流れを確認した次は、対象事業者さまについて、ご説明させていただきます。<br />
            <br />
            対象事業者の確認（本項目）では、主な対象要件を４つあげさせていただきますので、自社の情報と見比べながら確認していただければと思います。<br />
            <br />
            なお、主な３４つではなく、すべての要件（詳細）は、以下のページにて記載がありますので、ご参照いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>小規模事業者であること</h3><br />
          <p className="media_text_text">
            小規模事業者である必要があり、具体的には以下のような通りとなっっております。<br />
            <br />
            業種ごとに従業員に関する上限が違う点も踏まえてご確認いただければと思います。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>業種</th><th>人数</th>
              </tr>
              <tr>
                <td><strong>商業・サービス業（宿泊業・娯楽業除く）</strong></td>
                <td>常時使用する従業員の数5人以下</td>
              </tr>
              <tr>
                <td><strong>サービス業のうち宿泊業・娯楽業</strong></td>
                <td>常時使用する従業員の数20人以下</td>
              </tr>
              <tr>
                <td><strong>製造業その他</strong></td>
                <td>常時使用する従業員の数20人以下</td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>出資会社に関する規定</h3><br />
          <p className="media_text_text">
            株の100%を出資金が５億円以上の法人に保有されていてはいけないというものです。<br />
            直接的、間接的の例は、以下に記載させていただきます。<br />
            <br />
            <img src={Image1} className='media_img' alt='' />
          </p>
          <h3 className='media_text_text_title'>課税所得に関する規定</h3><br />
          <p className="media_text_text">
            確定している（申告済みの）直近過去３年分の「各年」又は「各事業年度」の課税所得の年平均額が 15億円を超えていないこと。<br />
          </p>
          </>

          <>
          <div id='6' className="media_text_title">
            <h2>６.類型の確認</h2>
          </div>
          <p className="media_text_text">
            <br />
            対象事業者の確認が終わったところで、次は類型（枠）について説明していきます。<br />
            <br />
            具体的に類型とは、<strong>「通常枠」</strong>、<strong>「賃金引上げ枠」</strong>、<strong>「卒業枠」</strong>、<strong>「後継者支援枠」</strong>、<strong>「創業枠」</strong>及び<strong>「インボイス枠」</strong>の６つに分かれており、各枠ごとに要件、補助率、上限金額が細かく提示されています。<br />
            <br />
            要件の概要については、以下にてご説明させていただきます。<br />
          </p>
          <h3 className='media_text_text_title'>通常枠</h3><br />
          <p className="media_text_text">
            事業規模の拡大を目指す中小企業等のための枠となっており、具体的には。新分野展開や業態転換、事業・業種転換等の取組、事業再編の取組などが当てはまります。<br />
          </p>
          <h3 className='media_text_text_title'>賃金引上げ枠</h3><br />
          <p className="media_text_text">
            従業員の賃金引き上げ（地域別最低賃金より+30円以上）を行う事業者さまを対象とする枠となっております。<br />
          </p>
          <h3 className='media_text_text_title'>卒業枠</h3><br />
          <p className="media_text_text">
            事業の拡大に意欲的な事業者さまを対象とした枠で、本補助金により小規模事業者等の枠を超えることを求められます。<br />
          </p>
          <h3 className='media_text_text_title'>後継者支援枠</h3><br />
          <p className="media_text_text">
            事業継承を行う事業者さまを対象とする枠となっており、「アトツギ甲子園」のファイナリストになった事業者さまを対象としています。<br />
          </p>
          <h3 className='media_text_text_title'>創業枠</h3><br />
          <p className="media_text_text">
            認定連携創業支援等事業者が実施した特定創業支援等事業による支援を公募締切時から過去３か年の間に受け、かつ、過去３か年の間に開業した事業者を対象としたものです。<br />
          </p>
          <h3 className='media_text_text_title'>インボイス枠</h3><br />
          <p className="media_text_text">
            2021年9月30日から2023年9月30日の期間の内、一度でも免税事業者であった又は免税事業者であることが見込まれる事業者のうち、適格請求書発行事業者に登録した事業者が対象となります。<br />
          </p>
          </>
          
          <>
          <div id='4' className="media_text_title">
            <h2>４.類型の詳細及び補助率</h2>
          </div>
          <p className="media_text_text">
            <br />
            以上を踏まえて、小規模事業者持続化補助金の類型の詳細と補助率についてご説明させていただきます。<br />
          </p>
          <h3 className='media_text_text_title'>小規模事業者持続化補助金の補助率</h3><br />
          <p className='media_text_text'>
            次は、補助率についてですが、こちらは公募要領より表が作成されておりますので、そのまま引用させていただきます。<br />
          </p>
          <h3 className='media_text_text_title'>①類型の補助率・補助上限</h3><br />
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th className='media_table_0001_1_2'>補助率</th><th className='media_table_0001_1_3'>補助上限</th>
              </tr>
              <tr>
                <td><strong>通常枠</strong></td>
                <td>2/3</td>
                <td>50万円</td>
              </tr>
              <tr>
                <td><strong>賃金引上げ枠</strong></td>
                <td>2/3<br />（赤字事業者については３／４）</td>
                <td>200万円</td>
              </tr>
              <tr>
                <td><strong>卒業枠</strong></td>
                <td>2/3</td>
                <td>200万円</td>
              </tr>
              <tr>
                <td><strong>後継者支援枠</strong></td>
                <td>2/3</td>
                <td>200万円</td>
              </tr>
              <tr>
                <td><strong>創業枠</strong></td>
                <td>2/3</td>
                <td>200万円</td>
              </tr>
              <tr>
                <td><strong>インボイス枠</strong></td>
                <td>2/3</td>
                <td>100万円</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3 className='media_text_text_title'>②賃金引上げ枠</h3><br />
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>項目</th>
                <th className='media_table_0001_3_2'>内容</th>
              </tr>
              <tr>
                <td><strong>概要</strong></td>
                <td>
                  最低賃金の引き上げが行われた中、それに加えて更なる賃上げを行い、従業員に成長の果実を分配する意欲的な小規模事業者に対し政策支援をするため、補助事業実施期間に事業場内最低賃金を地域別最低賃金より＋３０円以上とした事業者に対して、補助上限額を２００万円へ引き上げ。<br />
                </td>
              </tr>
              <tr>
                <td><strong>概要</strong></td>
                <td>
                  補助事業の終了時点において、事業場内最低賃金（※１）が申請時の地域別最低賃金より＋３０円以上であること。ただし、この要件を満たさない場合は、交付決定後であっても、補助金の交付は行いません。なお、すでに事業場内最低賃金が地域別最低賃金より＋３０円以上を達成している場合は、現在支給している（※２）事業場内最低賃金より＋３０円以上とする必要があります。<br />
                </td>
              </tr>
              <tr>
                <td><strong>補助金額</strong></td>
                <td>
                  ＜申請時＞<br />
                  ・ 「経営計画書」（様式２）の「賃金引上げ枠」欄にチェック。<br />
                  ・ 補助事業計画②（様式３）の「Ⅱ.経費明細表」の「賃金引上げ枠」欄にチェック。<br />
                  ・ 労働基準法に基づく、直近１か月分の賃金台帳（※１）の写しを提出。<br />
                  ・ 「賃金引上げ枠の申請に係る誓約書」（様式７）に記入の上、原本を提出。<br />
                  <br />
                  ＜実績報告書の提出時＞<br />
                  ・ 実績報告書提出時点における直近１か月分の、労働基準法に基づく賃金台帳（※１）の写しを提出。<br />
                  ※１：労働基準法に基づく賃金台帳は、別紙「参考資料」P.７の記載内容を満たしている必要があります。<br />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3 className='media_text_text_title'>③賃金引上げ枠（業績が赤字の事業者に対する要件）</h3><br />
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>項目</th>
                <th className='media_table_0001_3_2'>内容</th>
              </tr>
              <tr>
                <td><strong>追加要件</strong></td>
                <td>
                  「賃金引上げ枠（赤字）」に取り組む事業者のうち、直近１期または直近１年間の課税所得金額（※１）がゼロ以下である事業者。<br />
                  ※１：課税所得金額は以下のことを指します。<br />
                  <br />
                  ＜法人の場合＞<br />
                  直近１期分の法人税申告書の別表一・別表四「所得金額又は欠損金額」欄の金額。<br />
                  <br />
                  ＜個人事業主の場合＞<br />
                  直近１年間の「所得税および復興特別所得税」の「確定申告書」第一表の「課税される所得金額」欄の金額。<br />
                </td>
              </tr>
              <tr>
                <td><strong>必要な追加手続</strong></td>
                <td>
                  上記「賃金引上げ枠」において必要な手続に追加して、申請時に以下の手続が必要となります。<br />
                  ・ 「経営計画書」（様式２）の「赤字事業者」欄にチェック。<br />
                  ・ 補助事業計画②（様式３）の「Ⅱ.経費明細表」の「赤字事業者」欄にチェック。<br />
                  <br />
                  ＜法人の場合＞<br />
                  ・ 直近１期に税務署へ提出した税務署受付印のある、法人税申告書の別表一・別表四の写しを申請書に添付して提出。電子申告（e-Tax）で申告した場合は、受付印の代用として「受付結果（受信通知）」を印刷したものを申請書に添付して提出。法人税申告書を書面提出した方で表紙に受付印がない場合には、税務署が発行する、「納税証明書（その２：所得金額の証明書）」（原本）を追加で提出。<br />
                  <br />
                  ＜個人事業主の場合＞<br />
                  ・ 直近１年に税務署へ提出した税務署受付印のある、「所得税および復興特別所得税」の「確定申告書」第一表の写しを申請書に添付して提出。電子申告（eTax）で申告した場合は、受付印の代用として「受付結果（受信通知）」を印刷したものを申請書に添付して提出。確定申告書を書面提出した方で表紙に受付印がない場合には、税務署が発行する、「納税証明書（その２：所得金額の証明書）」（原本）を追加で提出。<br />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3 className='media_text_text_title'>④後継者</h3><br />
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>項目</th>
                <th className='media_table_0001_3_2'>内容</th>
              </tr>
              <tr>
                <td><strong>概要</strong></td>
                <td>
                  将来的に事業承継を行う予定があり、新たな取組を行う後継者候補として、「アトツギ甲子園」のファイナリストになった事業者を対象に政策支援をするため、以下の要件を満たす事業者に対して、補助上限額を２００万円へ引き上げ。<br />
                </td>
              </tr>
              <tr>
                <td><strong>要件</strong></td>
                <td>
                  申請時において、「アトツギ甲子園（※１）」のファイナリストになった事業者であること。<br />
                </td>
              </tr>
              <tr>
                <td><strong>必要な手続</strong></td>
                <td>
                ＜申請時＞<br />
                ・ 「経営計画書」（様式２）の「後継者支援枠」欄にチェックし、ファイナリストに選出された年度を記入してください。<br />
                ・ 補助事業計画②（様式３）の「Ⅱ.経費明細表」の「後継者支援枠」欄にチェック。<br />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3 className='media_text_text_title'>④創業枠</h3><br />
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>項目</th>
                <th className='media_table_0001_3_2'>内容</th>
              </tr>
              <tr>
                <td><strong>概要</strong></td>
                <td>
                  創業した事業者を重点的に政策支援するため、産業競争力強化法に基づく「認定市区町村」または「認定市区町村」と連携した「認定連携創業支援等事業者」が実施した「特定創業支援等事業」による支援を公募締切時から起算して過去３か年の間に受け、かつ、過去３か年の間に開業した事業者に対して、補助上限額を２００万円へ引き上げ。<br />
                </td>
              </tr>
              <tr>
                <td><strong>要件</strong></td>
                <td>
                  産業競争力強化法に基づく「認定市区町村」または「認定市区町村」と連携した「認定連携創業支援等事業者」が実施した「特定創業支援等事業」による支援を公募締切時から起算して過去３か年の間に受け（※１）、かつ、過去３か年の間に開業した事業者（※２、３）であること。<br />
                </td>
              </tr>
              <tr>
                <td><strong>必要な手続</strong></td>
                <td>
                  ＜申請時＞<br />
                  ・ 「経営計画書」（様式２）の「創業枠」欄にチェック。<br />
                  ・ 補助事業計画②（様式３）の「Ⅱ.経費明細表」の「創業枠」欄にチェック。<br />
                  ・ 産業競争力強化法に基づく「認定市区町村」または「認定市区町村」と連携した「認定連携創業支援等事業者」が実施した「特定創業支援等事業」による支援を受けたことの証明書（※）の写しを申請書に添付して提出。<br />
                  <br />
                  ＜法人の場合＞<br />
                  ・ 現在事項全部証明書または履歴事項全部証明書（原本）を申請書に添付して提出（申請書の提出日から３か月以内の日付のものに限ります）。<br />
                  <br />
                  ＜個人事業主の場合＞<br />
                  ・ 開業届（税務署受付印のあるもの）の写しを申請書に添付して提出。電子申告した方は、「受付結果（受信通知）」を印刷したものを受付印の代用として添付してください。<br />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3 className='media_text_text_title'>④インボイス枠</h3><br />
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>項目</th>
                <th className='media_table_0001_3_2'>内容</th>
              </tr>
              <tr>
                <td><strong>概要</strong></td>
                <td>
                  免税事業者が適格請求書発行事業者への転換に伴う事業環境変化に対応することに対し政策支援をするため、2021 年 9 月 30 日から 2023 年 9 月 30 日の属する課税期間で一度でも免税事業者であった又は免税事業者であることが見込まれる事業者のうち、適格請求書発行事業者に登録した事業者に対して、補助上限額を１００万円へ引き上げ。<br />
                </td>
              </tr>
              <tr>
                <td><strong>要件</strong></td>
                <td>
                  2021 年 9 月 30 日から 2023 年 9 月 30 日の属する課税期間で一度でも免税事業者であった又は免税事業者であることが見込まれる事業者のうち、適格請求書発行事業者の登録が確認できた事業者であること。ただし、補助事業の終了時点でこの要件を満たさない場合は、交付決定後であっても、補助金の交付は行いません。（注）共同申請の場合は、本枠で申請はできません（通常枠のみ申請可）。<br />
                </td>
              </tr>
              <tr>
                <td><strong>必要な手続</strong></td>
                <td>
                ＜申請時＞<br />
                ・ 「経営計画書」（様式２）の「インボイス枠」欄にチェック。<br />
                ・ 補助事業計画②（様式３）の「Ⅱ.経費明細表」の「インボイス枠」欄にチェック。<br />
                ・ 「インボイス枠の申請に係る宣誓・同意書」（様式９）に記入の上、原本を提出。様式９は法人用・個人事業主用いずれかを使用してください。<br />
                <br />
                ＜実績報告書の提出時＞
                ・ 適格請求書発行事業者の登録通知書の写しを提出。<br />
                </td>
              </tr>
            </tbody>
          </table>
          </>

          <>
          <div id='6' className="media_text_title">
            <h2>６.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            本記事では、小規模事業者持続化補助金を申請したい事業者さまへ向けて、小規模事業者持続化補助金の概要について記事にさせていただきました。<br />
            <br />
            公募要領を各所に添付させていただきましたが、ご覧いただけば分かる通り難しい内容となっております。<br />
            そこで、もし、本格的に小規模事業者持続化補助金の導入を検討されていましたら、弊社含め補助金に詳しい事業者へご相談ください。<br />
            <br />
            ご相談は、画面右上の補助金に関する相談をするから問い合わせにて、お待ちしております。<br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <div className='media_topic'>
          <div className='media_topic_service_top'>
            <h3>memorii help</h3>
            <h4>補助金/助成金の申請サポート</h4>
            <p>初めての方へも丁寧に補助金/助成金のご説明をさせていただき、さらに、電子申請、交付申請のサポートまで、行わせて頂くサービスとなっております。</p>
          </div>
          <div className='media_recommend_title'>おすすめの記事</div>
          <div className='media_recommend_content'></div>
          <div className='media_recommend_content'></div>
          <div className='media_recommend_content'></div>
          <div className='media_recommend_content'></div>
        </div>
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;