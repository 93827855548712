import './styles.css';
import React from "react";
import Photo_student_1 from "./../../../../image/photo/student1.png";
import Photo_student_2 from "./../../../../image/photo/student2.png";
import Photo_student_3 from "./../../../../image/photo/student3.png";
import Photo_student_4 from "./../../../../image/photo/student4.png";
import Photo_student_5 from "./../../../../image/photo/student5.png";
import Photo_student_6 from "./../../../../image/photo/student6.png";

const PhotoOpinion = () => {

  return (
    <>
    <h4 className='photo_blog_title'>美容学生の声</h4>
    <div className='photo_blog'>
      <div>
        <img src={Photo_student_1} alt='' />
        <div>
          <h4>神原 宗輝</h4>
        </div>
        <p>レベルの高い作品が見れて、刺激になりました！さらに、memoriiがきっかけで、憧れの方と繋がることも出来ました！</p>
      </div>
      <div>
        <img src={Photo_student_2} alt='' />
        <div>
          <h4>白鳥 ルナ</h4>
        </div>
        <p>美容業界の方とコラボした作品撮り、ショーに出演したいので、参考にしています！繋がりも作れて嬉しいです！</p>
      </div>
      <div>
        <img src={Photo_student_3} alt='' />
        <div>
          <h4>長谷川 優光</h4>
        </div>
        <p>作品撮りの参考やイメージ作りにいつも拝見してます！色んな凄い方々の作品撮りを見て、刺激をもらっています！</p>
      </div>
      <div>
        <img src={Photo_student_4} alt='' />
        <div>
          <h4>五十嵐 陽向</h4>
        </div>
        <p>素敵な作品がたくさんあり、刺激をもらえます！さらに、写真の撮り方の勉強にもなるため、活用しています！</p>
      </div>
      <div>
        <img src={Photo_student_5} alt='' />
        <div>
          <h4>松本晴斗</h4>
        </div>
        <p>様々な作品が簡単に見れて、考えの幅がとても広がりました！作品投稿も出来るので、モチベーション向上にも繋がります！</p>
      </div>
      <div>
        <img src={Photo_student_6} alt='' />
        <div>
          <h4>池内日菜</h4>
        </div>
        <p>レベルの高い作品を見ることができ刺激になります！その他、ヘアメイク、作品イメージの勉強にもなってます！</p>
      </div>
    </div>
    </>
  );
}

export default PhotoOpinion;
