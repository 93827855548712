import './styles.css';
import Service1 from './../../../../image/help/service1.png';
import comment from './../../../../image/help/comment.png';
import Service1_1 from './../../../../image/help/service1_1.png';

const Help = () => {
  
  return (
    <>
    <div className='help_service_title'>
      <div className='help_service_title_left'>
        <h6>1</h6>
        <h3>最短かつ、シンプル</h3>
      </div>
      <div className='help_service_title_right'>
        <img src={Service1_1} alt='' />
        <div>
          <img src={comment} alt='' />
          <p>お客さまの通常の業務に影響がでないような体制を弊社で構築しておりますので、忙しい事業者さまでも申請が可能となっています。</p>
        </div>
      </div>
    </div>
    <p className='help_service_title_text'>お客さまの通常の業務に影響がでないような体制を弊社で構築しておりますので、忙しい事業者さまでも申請が可能となっています。</p>
    <div className='help_service1_img'>
      <img src={Service1} alt='' />
    </div>
    </>
  );
}

export default Help;
