// https://www.kkj.go.jp/doc/ja/api_guide.pdf
// https://www.kkj.go.jp/api/?Query=%E3%82%BB%E3%83%9F%E3%83%8A%E3%83%BC

import './styles.css';
import Icon from './../../../../../../image/subsidy/icon.jpg';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useEffect, useState } from "react";

const BlogText0001 = () => {

  const [ , setList ] = useState(false);

  useEffect(() => {
    setList(new URL(window.location).href.includes('home'));
  }, []);

  return (
    <>
    <div className='subsidy_results_nav'>
      <a href='/bid/home'>
        <div className='subsidy_results_nav_icon'>
          <img src={Icon} alt='' />
          <div>
            <h1>補助金の教科書</h1>
            <h2>produce by trankllc</h2>
          </div>
        </div>
      </a>
      <div className='subsidy_results_nav_link'>
        <AnchorLink href="#1" offset="100"><p>トップページ <span>↓</span></p></AnchorLink>
        <AnchorLink href="#2" offset="100"><p>補助金サポート <span>↓</span></p></AnchorLink>
        <AnchorLink href="#3" offset="100"><p>運営会社 <span>↓</span></p></AnchorLink>
        <a href='/contact'>
          <button>お問い合わせ</button>
        </a>
      </div>
    </div>
    </>
  );
}

export default BlogText0001;
