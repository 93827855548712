import './styles.css';

const Help = () => {
  
  return (
    <>
    <div className='hojokin'>
      <div>
        <h4>中小企業を全力で支える<br />補助金専門のコンサルティング会社</h4>
      </div>
    </div>
    </>
  );
}

export default Help;
