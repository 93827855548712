// 大規模賃金引上枠

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/17/top.png";
import Image1 from "./../../../../../image/media/business/17/1.png";
import Image2 from "./../../../../../image/media/business/17/2.png";
import Image3 from "./../../../../../image/media/business/17/3.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」大規模賃金引上枠は、５つの要件を確認！',
    description: '本記事では、大規模賃金引上枠について解説をしております。満たさなければいけない要件の解説、及びポイントについてもまとめさせていただきました。本記事は、「大規模賃金引上枠での申請を考えているけど、要件や補助率などがわからない」という方へ向けて、記事を執筆させていただきました。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」大規模賃金引上枠は、５つの要件を確認！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は、<strong>日本最大級の補助金である「事業再構築補助金」</strong>について解説いたします。<br />
            <br />
            本記事は、「<strong>大規模賃金引上枠での申請を考えているけど、要件や補助率などがわからない</strong>」という方へ向けて、記事を執筆させていただきました。<br />
            <br />
            事業再構築補助金の大規模賃金引上枠申請を検討されている方はぜひご覧ください！<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>大規模賃金引上枠で受け取れる金額</span></li>
              <li><span>大規模賃金引上枠の過去採択率</span></li>
              <li><span>大規模賃金引上枠を申請する際の提出書類</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、補助金の説明書である「公募要領」を参考にして作成されたものとなっていますので、<strong>本記事の内容を理解したうえで公募要領部を読んでみてください</strong>！<br />
            <br />
            最初は難しいと感じるかもしれませんが、わかりやすく解説しておりますので、本記事を読んで、補助金の申請に必要な書類を把握しましょう！<br />
            <br />
            なお、右上のボタンからお問い合わせをお受けしておりますので、<strong>申請サポートのご依頼や不明点のご相談はお気軽にご連絡ください</strong>。<br />
            <br />
            それでは、解説に進ませていただきます！<br />
            <br />
            なお、<strong>申請枠が決まってない方は、別記事にて申請枠ごと情報を解説させていただきましたので、以下のリンクからご参照ください</strong>。<br />
            <a href='https://trankllc.com/media/subsidy/business/0002' target="_blank" rel="nofollow noopener noreferrer">
              参考記事：【初めての方へ】事業再構築補助金の対象者条件を徹底解説
            </a>
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">大規模賃金引上枠とは</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">大規模賃金引上枠の対象者</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">大規模賃金引上枠の概要</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">大規模賃金引上枠の過去採択率</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">大規模賃金引上枠に申請するための要件</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">事業再構築要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">売上高等減少要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">認定支援機関要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">付加価値額要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">賃金引上要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">従業員増員要件</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">大規模賃金引上枠のポイント</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">大規模賃金引上枠で不採択でも、通常枠で再審査が可能</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">目標未達により、補助金の返還が求められる。</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#4" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>１.大規模賃金引上枠とは</h2>
          </div>
          <p className="media_text_text">
            <br />
            大規模賃金引上枠とは、1回あたりの申請件数が9～20件と、<strong>他の申請枠と比較して非常に少ない枠</strong>となっています。<br />
            <br />
            理由として、従業員数が101人以上いることや、補助金額が8,000万円からといった条件が存在することがあげられます。<br />
          </p>
          <h3 className='media_text_text_title'>大規模賃金引上枠の対象者</h3><br />
          <p className="media_text_text">
            大規模賃金引上枠の対象者は、「<strong>従業員数が101人以上の、日本国内に本社を置く中小・中堅企業</strong>」となっております。<br />
            <br />
            そのため、従業員数が100人以下の事業者様は、他の申請枠での提出をお考え下さい。<br />
          </p>
          <h3 className='media_text_text_title'>大規模賃金引上枠の概要</h3><br />
          <p className="media_text_text">
            本部分では、補助金額の上限等を含めた、大規模賃金引上枠の基本情報を記載いたします。<br />
            自社情報と照らし合わせて、補助金額の概要や対象経費をご確認ください！<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>要件</th><th>書類名</th>
              </tr>
              <tr>
                <td>補助金額</td><td>【従業員101人以上】　8,000万円超～1憶円</td>
              </tr>
              <tr>
                <td>補助率(※)</td>
                <td>
                  中小企業者等　2/3以内(6,000万円を超える部分は1/2)<br />
                  中堅企業等　1/2以内(4,000万円を超える部分は1/3)<br />
                </td>
              </tr>
              <tr>
                <td>補助事業実施期間</td><td>交付決定日から12か月以内か、採択発表日から14か月後の日の短い方</td>
              </tr>
              <tr>
                <td>補助対象経費</td>
                <td>
                  建築費、機械装置・システム構築費(リース料を含む)、技術導入費、専門家経費、運搬費、クラウドサービス利用費、外注費、知的財産権等関連経費、広告宣伝・販売促進費、研修費<br />
                </td>
              </tr>
              <tr>
                <td>申請要件</td>
                <td>
                  ・事業再構築要件<br />
                  ・売上等減少要件<br />
                  ・認定支援機関要件<br />
                  ・付加価値額要件<br />
                  ・賃金引上要件<br />
                  ・従業員増員要件<br />
                </td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            (※)補助率…事業にかかる全ての経費のうち、補助金額で補填することの出来る割合<br />
            <br />
            <img src={Image1} className='media_img' alt='' />
            <br />
            上記のように、補助金額が8,000万円となる場合、<strong>最低でも補助対象になる経費が1憶3,000万円以上になることが必要になります</strong>。<br />
            <br />
            <strong>参考記事</strong><br />
            なお、補助対象経費は以下の記事にて説明させていただいておりますので、詳細に知りたい方はこちらの記事をご参照ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0003' target="_blank" rel="nofollow noopener noreferrer">
              参考記事：【何に使えるの？】事業再構築補助金の対象経費をわかりやすく解説！
            </a>
          </p>
          <h3 className='media_text_text_title'>大規模賃金引上枠の過去採択率</h3><br />
          <p className="media_text_text">
            本部分では、<strong>通常枠の直近採択率と過去の平均採択率</strong>を記載いたします。<br />
            <br />
            大規模賃金引上枠の採択率は、従業員数が101人以上であり、補助金額が8,000万円以上という条件があるため、<strong>申請件数は他の申請枠と比較して非常に少なくなっております</strong>。<br />
            <br />
            しかし、採択率は50%～60%で推移しており、通常枠と比較すると高い採択率を誇っているということが出来ます。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>項目</th><th>申請件数</th><th>採択件数</th><th>採択率</th>
              </tr>
              <tr>
                <td>第6次</td>
                <td>9</td>
                <td>5</td>
                <td>55.6%</td>
              </tr>
              <tr>
                <td>合計(第3次～6次)</td>
                <td>54</td>
                <td>31</td>
                <td>57.4%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            <strong>参考記事</strong>
            なお、大規模賃金引上枠の詳細な採択率や他申請枠の採択率は、下記の記事にて記載しておりますので、興味のある方は是非ご覧ください！<br />
            <a href='https://trankllc.com/media/subsidy/business/0004' target="_blank" rel="nofollow noopener noreferrer">
              参考記事：【採択率はどれくらい？】事業再構築補助金の過去採択を徹底解剖！
            </a>
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>事業再構築補助金の大規模賃金引上枠とは？</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>従業員数が101人以上いることが条件</span></li>
              <li><span>補助金額は8,000万～1億円</span></li>
              <li><span>直近の採択率は45.5%</span></li>
            </ul>
          </div>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>２.大規模賃金引上枠に申請するための要件</h2>
          </div>
          <p className="media_text_text">
            <br />
            大規模賃金引上枠に申請するためには、以下の計６要件を満たす必要があります。<br />
            ・<strong>事業再構築要件</strong><br />
            ・<strong>売上等減少要件</strong><br />
            ・<strong>認定支援機関要件</strong><br />
            ・<strong>付加価値額要件</strong><br />
            ・<strong>賃金引上要件</strong><br />
            ・<strong>従業員増員要件</strong><br />
            <br />
            本部分では、要件の内容と満たすために必要な書類一覧を記載いたしましたので、こちらを確認して大規模賃金引上枠への申請を行いましょう！<br />
          </p>
          <h3 className='media_text_text_title'>事業再構築要件</h3><br />
          <p className="media_text_text">
            事業再構築要件とは、<strong>新分野展開、事業転換、業種転換、業態転換又は事業再編のいずれかに該当する事業計画でなければいけない</strong>という要件です。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・新分野展開、事業転換、業種転換、業態転換又は事業再編のいずれかに該当する事業計画を策定すること<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・事業計画書<br />
            <br />
            <strong>参考記事</strong><br />
            上記５点の（新分野展開、事業転換、業種転換、業態転換又は事業再編）についての詳しい記事を別途作成させていただきましたので、ぜひご参照いただければと思います。<br />
            <a href='https://trankllc.com/media/subsidy/business/0007' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【類型・転換って何？】事業再構築補助金の再構築要件を、例を用いて解説！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>売上高等減少要件</h3><br />
          <p className="media_text_text">
            売上等減少要件とは、<strong>新型コロナウイルスの影響によって、売上が減少していることを証明する要件</strong>となっております。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・2019年1月～2020年3月の連続する6か月の内、任意の3か月(コロナ以前)と2020年4月以降の同月(コロナ以後)で比較した際、総売上高が10%以上減少していること<br />
            <br />
            <strong>法人の場合【要件を満たすために提出する書類】</strong><br />
            ・コロナ以前の、選択した任意3か月の売上が分かる年度確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の法人事業概況説明書の控え(両面)<br />
            ・受信通知(1枚)(e-Taxで申告している場合のみ)<br />
            ・コロナ以後の、選択した任意3か月の売上が分かる確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の法人事業概況説明書の控え(両面)<br />
            <br />
            <strong>個人の場合【要件を満たすために提出する書類】</strong><br />
            ・コロナ以前の、選択した任意3か月の売上が分かる年度確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の月別売上の記載がある所得税青色申告決算書の控え(両面)<br />
            (白色申告の方は、対象月の売上が分かる売上台帳や、確定申告の基礎となる書類)<br />
            ・受信通知(1枚)(e-Taxで申告している場合のみ)<br />
            ・コロナ以前の、選択した任意3か月の売上が分かる年度確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の月別売上の記載がある所得税青色申告決算書の控え(両面)<br />
            (白色申告の方は、対象月の売上が分かる売上台帳や、確定申告の基礎となる書類)<br />
            <br />
            なお、上記書類の図例を下記に記載いたしますので、こちらも参考にしてご準備ください。<br />
            <br />
            <img src={Image2} className='media_img' alt='' /><br />
            <br />
            所得税青色申告決算書の控え<br />
            <img src={Image3} className='media_img' alt='' />
            <br />
            売上減少を計算する際の具体的な手順は、下記の記事でまとめて記載しておりますので、下記リンクよりご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0007' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【10%・15%】事業再構築補助金の売上高等減少要件を具体例で解説！
            </a><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>要件</th><th>書類名</th>
              </tr>
              <tr>
                <td>事業再構築要件<br />付加価値額要件</td><td>事業計画書</td>
              </tr>
              <tr>
                <td>売上等減少要件</td>
                <td>
                  ・確定申告書別表一(1枚)<br />
                  ・法人事業概況説明書の控え(両面)<br />
                  ・受信通知(1枚)(e-Taxで申請している場合)<br />
                </td>
              </tr>
              <tr>
                <td>認定支援機関要件</td><td>認定経営革新等支援機関による確認書</td>
              </tr>
              <tr>
                <td>要件以外の提出書類</td>
                <td>
                  ・決算書<br />
                  ・「ミラサポplus」で作成した事業財務情報のPDF<br />
                </td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            なお、枠ごとの申請書類につきましては、下記の記事にて詳しく説明しておりますので、ご参照ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0007' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【何を出せばいいの？】事業再構築補助金の提出書類一覧表を大公開！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>認定支援機関要件</h3><br />
          <p className="media_text_text">
            認定支援機関要件とは、<strong>国に中小企業支援の知識・経験がある者として認められた認定支援機関に事業計画書を確認してもらわなければいけない</strong>という要件です。<br />
            <br />
            認定支援機関とは、<strong>中小企業支援の知識・経験がある者として、国の認定を受けた機関であり、士業や商工会、金融機関が多く認可されています</strong>。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・認定支援機関と相談したうえで事業計画書を策定する。<br />
            ※認定経営革新等支援機関による確認書は、認定支援機関に相談したことを証明する書類ですので、必ず認定支援機関に作成をお願いしてください。<br />
            <br />
            <strong>【要件を満たすために必要な書類】</strong><br />
            ・認定経営革新等支援機関による確認書<br />
            ・金融機関による確認書(補助金額が3,000万円を超える場合)<br />
            <br />
            <strong>参考記事</strong><br />
            認定支援機関要件は、下記の記事にて詳しく解説しておりますので、詳細が知りたい方はぜひご参照ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0009' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【どこに依頼する？】事業再構築補助金の認定支援機関要件を機関別に解説！
            </a><br />
            <br />
            おすすめの認定支援機関や認定支援機関別の採択率は、下記の記事でまとめて記載しておりますので、下記リンクよりご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0004' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【採択率はどれくらい？】事業再構築補助金の過去採択を徹底解剖！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>付加価値額要件</h3><br />
          <p className="media_text_text">
            付加価値額要件とは、<strong>付加価値額が上昇する事業計画を策定しなければいけない</strong>という要件です。<br />
            <br />
            付加価値額とは、補助金独自の考え方であり、以下の方法により算出できます。<br />
            <span>付加価値額=営業利益+減価償却費+人件費</span><br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・補助事業終了後3~5年で、付加価値額の年平均率を3.0%以上増加させること<br />
            ・補助事業終了後3~5年で、従業員1人当たりの付加価値額の年平均率3.0%以上増加させること<br />
            ※上記のどちらかを満たす事業計画を策定することが要件となります。<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・事業計画書<br />
            <br />
            上記の要件を満たした事業計画書を策定することが必要になりますので、認定支援機関と共にご確認をお願いいたします。<br />
            <br />
            <strong>【通常枠に申請するための要件と必要書類一覧表】</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>要件</th>
                <th>書類名</th>
              </tr>
              <tr>
                <td>
                  事業再構築要件<br />
                  付加価値額要件
                </td>
                <td>
                  ・事業計画書
                </td>
              </tr>
              <tr>
                <td>
                  緊急対策要件
                </td>
                <td>
                  ・確定申告書別表一(1枚)<br />
                  ・法人事業概況説明書の控え(両面)<br />
                  ・受信通知(1枚)(e-Taxで申請している場合)<br />
                  ・原油価格・物価高騰等の経済環境の変化の影響を受けていることの宣誓書
                </td>
              </tr>
              <tr>
                <td>認定支援機関要件</td><td>・認定経営革新等支援機関による確認書</td>
              </tr>
              <tr>
                <td>要件以外の提出書類</td>
                <td>
                  ・決算書<br />
                  ・「ミラサポplus」で作成した事業財務情報のPDF
                </td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            なお、こちらの申請書類は以下の記事でさらに詳しく解説しておりますので、下記URLの記事もご確認のうえ、参考程度としてご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0004' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【何を出せばいいの？】事業再構築補助金の提出書類一覧表を大公開！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>賃金引上要件</h3><br />
          <p className="media_text_text">
            賃金引上要件とは、<strong>事業計画期間終了(3～5年)の間、事業内最低賃金を1年の換算で45円以上引き上げる</strong>という要件です。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・補助事業期間の事業年度から、事業計画期間終了(3～5年)までの間、事業内最低賃金を1年の換算で45円以上引き上げること<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・賃上げ表明書<br />
            →申請時点の直近月における事業内最低賃金が明記され、事業内最低賃金を45円以上引き上げる計画を従業員に表明したもの<br />
            <br />
            ・賃金台帳の写し<br />
            →申請支店の直近月における事業内最低賃金で雇用している従業員全てがわかるもの<br />
            <br />
            書類は下記リンクより「賃上げ表明書」をダウンロードしてご使用ください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              リンク：賃上げ表明書のダウンロード
            </a><br />
          </p>
          <h3 className='media_text_text_title'>従業員増員要件</h3><br />
          <p className="media_text_text">
            従業員増員要件とは、<strong>事業計画期間終了(3～5年)までの間、従業員数を年率平均1.5%以上(初年度は1.0%)増員させるという要件</strong>となっております。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・補助事業期間の事業年度から、事業計画期間終了(3～5年)までの間、従業員数を年率平均1.5%以上(初年度は1.0%)増員させること<br />
            <br />
            ※小数点以下の数字は繰り上げとなります。<br />
            <br />
            例１）<br />
            従業員数が101人で、事業計画終了後3年間の計画を策定している場合<br />
            101(人)×5.5%(初年度1.0%+事業計画期間年率平均1.5%×3年) =5.555<br />
            ⇒6人以上の増員が必要<br />
            <br />
            例２）<br />
            従業員数が200人で、事業計画終了後5年間の計画を策定している場合<br />
            200(人)×8.5%(初年度1.0%+事業計画期間年率平均1.5%×5年)=17<br />
            ⇒17人以上の増員が必要<br />
            <br />
            また、ここでいう従業員とは中小企業基本法上の「常時使用する従業員」となっており、契約社員や使用期間中の者は含まれませんので、ご注意ください。<br />
            <br />
            <strong>【通常枠に申請するための要件と必要書類一覧表】</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>要件</th><th>書類名</th>
              </tr>
              <tr>
                <td>事業再構築要件<br />付加価値額要件</td>
                <td>事業計画書</td>
              </tr>
              <tr>
                <td>売上等減少要件</td>
                <td>
                  ・確定申告書別表一(1枚)<br />
                  ・法人事業概況説明書の控え(両面)<br />
                  ・受信通知(1枚)(e-Taxで申請している場合)<br />
                </td>
              </tr>
              <tr>
                <td>認定支援機関要件</td>
                <td>
                  ・認定経営革新等支援機関による確認書
                </td>
              </tr>
              <tr>
                <td>賃金引上要件</td>
                <td>
                  ・賃上げ表明書<br />
                  ・賃金台帳の写し<br />
                </td>
              </tr>
              <tr>
                <td>従業員増員要件</td>
                <td></td>
              </tr>
              <tr>
                <td>要件以外の提出書類</td>
                <td>
                  ・決算書<br />
                  ・「ミラサポplus」で作成した事業財務情報のPDF
                </td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            <strong>参考記事</strong><br />
            なお、こちらの申請書類は以下の記事でさらに詳しく解説しておりますので、下記URLの記事もご確認のうえ、参考程度としてご覧ください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              参考記事：【何を出せばいいの？】事業再構築補助金の提出書類一覧表を大公開！
            </a><br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>３.大規模賃金引上枠のポイント</h2>
          </div>
          <p className="media_text_text">
            <br />
            ここでは、大規模賃金引上枠の特徴を記載しておりますので、大規模賃金引上枠での申請を考えている事業者様や、他の申請枠と迷っている事業者様はぜひご参考にしてください！<br />
          </p>
          <h3 className='media_text_text_title'>大規模賃金引上枠で不採択でも、通常枠で再審査が可能</h3><br />
          <p className="media_text_text">
            大規模賃金引上枠は、<strong>申請する際に通常枠の申請要件も満たしている必要があります</strong>。<br />
            <br />
            そのため、<strong>大規模賃金引上枠で不採択になった場合でも、追加書類なしで通常枠の審査を受けることが出来ます</strong>。<br />
            <br />
            申請する際に「通常枠での再審査を望みますか」というチェックボックスが表示されますので、追加の審査を望まれる事業者様は忘れずにチェックしましょう！<br />
            <br />
            ただし、<strong>通常枠で採択された場合は、補助金額・補助率は通常枠に沿ったものになりますので、ご注意ください</strong>。<br />
          </p>
          <h3 className='media_text_text_title'>目標未達により、補助金の返還が求められる。</h3><br />
          <p className="media_text_text">
            大規模賃金引上枠は、「<strong>中小企業の雇用を促進する</strong>」という目的があります。そのため、<strong>賃金引上要件や従業員増員要件を満たすことが出来なかった場合、返還を求められる場合がございます</strong>。<br />
            <br />
            < strong>賃金引上計画を従業員に表明していなかった場合</strong><br />
            →補助金額全額の返還が求められます。<br />
            <br />
            ・正当な理由がなく、事業計画期間終了時点で、事業場内最低賃金を年額４５円以上の水準で引き上げることが出来なかった場合<br />
            ・正当な理由がなく、事業計画期間終了時点にで、従業員数を年率平均1.5％以上（初年度は1.0％以上）増加させることが出来なかった場合<br />
            →通常枠の補助上限金額(8,000万円)との、差額分を変換することが求められます。<br />
          </p>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>４.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、大規模賃金引上枠について解説させていただきました。<br />
            <br />
            やや複雑なものとはなっておりますが、少しずつ確認していただければと思います。諦めずに申請まで頑張ってください！<br />
            <br />
            なお、ご不明点、疑問点につきましては、<strong>画面右側の問い合わせからお待ちしておりますので、ご気軽にご連絡いただければと思います</strong>。<br />
            <br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


