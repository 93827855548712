// 市場拡大要件

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/32/top.png";
import Image1 from "./../../../../../image/media/business/32/1.png";
import Image2 from "./../../../../../image/media/business/32/2.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」市場規模が拡大していれば市場拡大要件！',
    description: '本記事では、事業再構築要件の市場拡大要件について解説させていただきました。要件の詳細、補助率、活用例などの詳細も解説しております。本記事は、「事業再構築の定義が分からない」方や「類型を詳しく知りたい」という方に向けて、執筆しておりますので、補助金の初申請を行う方はぜひご覧いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」市場規模が拡大していれば市場拡大要件！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は<strong>事業再構築補助金の市場拡大要件</strong>について、解説させていただきます。<br />
            <br />
            本記事は、補助金申請が初めての方へ向けた記事となっておりますので、ごゆっくり最後までご一読いただければと思います。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>本記事でこんな悩みが解決！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>市場拡大要件とは何？</span></li>
              <li><span>市場拡大要件はどうすれば満たされる？</span></li>
              <li><span>市場拡大要件で何ができる？</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、<strong>経済産業省から展開されている「事業再構築指針の手引き」を参考にして作成したもの</strong>となっています。<br />
            <a href='https://www.meti.go.jp/covid-19/jigyo_saikoutiku/pdf/shishin_tebiki.pdf' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：「事業再構築指針の手引き」に関する記事
            </a><br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">市場拡大要件の概要</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">概要</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">要件</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">申請可能な枠</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.市場拡大要件の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では概要、要件、補助金額と基本的な知識を順を追って解説しておりますので、要件を理解したい方はぜひご覧いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>概要</h3><br />
          <p className="media_text_text">
            市場拡大要件は、事業再構築補助金を使って行う事業が過去10年~今後10年で10％以上の市場規模増加が見込まれる業種・業態であるという要件となります。<br />
            <br />
            事業再構築補助金では事業再構築補助金を使用して事業者様の売上増加を見込んでいるため、本要件が追加されております。<br />
            <br />
            また、本要件は申請枠全てに課せられているものではなく、成長枠という枠を申請するためのものとなっております。<br />
          </p>
          <h3 className='media_text_text_title'>要件</h3><br />
          <p className="media_text_text">
            上記でもご説明いたしました通り、市場規模拡大要件というのは、取り組む新規事業の業種・業態の市場規模が10%以上増加しているというものです。<br />
            <br />
            具体的な方法は以下の２点となっておりますので、1つずつ解説させていただきます。<br />
            <br />
            <strong>具体的な判断方法</strong><br />
            ① 経済産業省より開示されている「成長枠の対象となる業種・業態の一覧」に含まれていること<br />
            ② 市場規模の増加を公的データを使用して証明する<br />
            <br />
            <strong>① 経済産業省より開示されている「成長枠の対象となる業種・業態の一覧」に含まれていること</strong><br />
            成長枠の対象となる業種・業態の一覧とは以下のPDFに記載されている業種・業態となっております。<br />
            <br />
            こちらは既存事業ではなく今後行う新規事業がこちらに含まれているかを確認ものというものですので、勘違いしないよう気をつけていただければと思います。<br />
            <br />
            事業再構築補助金を使用して新規事業を行いたいが業種・業態がまだ確定していないという方は、ぜひこのPDFを参考に検討していただければと思います。<br />
            <br />
            成長枠の対象となる業種・業態の一覧のPDFは以下の通りとなります。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/seichowaku_list.pdf' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：成長枠の対象となる業種・業態の一覧
            </a><br />
            <br />
            <strong>② 市場規模の増加を公的データを使用して証明する</strong><br />
            2点目は、公的データを使用して証明するというものとなっております。<br />
            <br />
            こちらは①と異なり、自分の希望する市場規模のデータを事業者様ご自身で探していただき、国へ提出するというものとなります。<br />
            <br />
            データ算出については、下記に公募要領に記載されている具体例を添付いたしましたので、ご確認いただければと思います。<br />
            <br />
            認められる例）<br />
            <img src={Image1} className='media_img' alt='' />
            ①2019 年の市場規模が 2009 年に比べて 10％以上拡大しており、一貫して上昇トレンドにある。<br />
            ②2019 年の市場規模が 2009 年に比べて 10％以上拡大しており、前年に比して縮小している年もあるが、全体として上昇トレンドにあると認められる。<br />
            <br />
            認められない例）<br />
            <img src={Image2} className='media_img' alt='' />
            ③2019 年の市場規模が 2009 年に比べて 10％以上拡大しているが、2019 年だけ何かしらの事情で拡大しているだけである可能性があり、上昇トレンドにあるとは判断できない。<br />
            ④2019 年の市場規模が 2009 年に比べて 10％以上拡大しているが、近年（2015 年から 2019 年にかけて）下降トレンドにある。<br />
            ⑤2019 年の市場規模が 2009 年に比べて 10％以上拡大していない。<br />
          </p>
          <h3 className='media_text_text_title'>申請に必要な書類</h3><br />
          <p className="media_text_text">
            上記の②を国へ提出するための書類として、「市場拡大要件を満たすことの説明書」という書類がございます。<br />
            <br />
            本書類は以下のurlの「市場拡大要件を満たすことの説明書 word」という書類を取得していただいて記載する形となります。<br />
            <a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：市場拡大要件を満たすことの説明書の取得
            </a><br />
            <br />
            成長枠へ申請する際は本書類も同時に必要になりますので、ご用意いただければと思います。<br />
            <br />
            また、認められるケース、認められないケースのご紹介をさせていただいた通り、認められないケースもございますので、記載の際は丁寧に行なっていただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>申請可能な枠</h3><br />
          <p className="media_text_text">
            申請可能な枠は上述に記載いたしました通り、成長枠のみとなります。<br />
            <br />
            事業再構築補助金に申請する方で申請枠がまだ確定していない方は、ぜひ一度上記の成長枠の対象となる業種・業態の一覧のPDFをご覧いただければと思います。<br />
            <br />
            また、別途記事で成長枠の要件、補助率等に関する解説を行っておりますので、以下の記事よご参照いただければと思います。<br />
            <a href='https://trankllc.com/media/subsidy/business/0025' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：成長枠に関する詳細記事
            </a><br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            最後までご一読いただき、誠にありがとうございます。<br />
            市場拡大要件の解説をさせていただきましたがいかがだったでしょうか。<br />
            <br />
            要件自体は複雑なものではございませんので、要件の①、②の手順を沿って要件を満たすことができるのかをまずご確認いただければと思います。<br />
            <br />
            上述で示した通り、弊社は補助金申請の専門家でございますので、本記事に関わらずご不明点等ございましたら、ご連絡いただけますと幸いです。<br />
            ご連絡いただけること心よりお待ちしております。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
