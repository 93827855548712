import './styles.css';
import Result1 from './../../../../image/help/result1.png';
import Result2 from './../../../../image/help/result2.png';
import Result3 from './../../../../image/help/result3.png';
import Result4 from './../../../../image/help/result4.png';
import Result5 from './../../../../image/help/result5.png';
import Result6 from './../../../../image/help/result6.png';
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class SlickGoTo extends React.Component {

  state = {
    slideIndex: 0,
    updateCount: 0
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      arrows: false,
      slidesToScroll: 1,
      autoplay: true,
      afterChange: () => this.setState(state => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next })
    };

    return (
      <>
      <div className='help_result'>
        <h4>補助金サポート実績</h4>
        <Slider ref={slider => (this.slider = slider)} {...settings} className='help_result_slider' >
          <div className='help_result_itme'>
            <img src={Result1} className='help_result_img' alt='' />
            <p>
              <span><strong>事業再構築補助金 卸売業</strong></span><br />
              飲食店のFC展開で事業再構築補助金を採択しました。煩雑な手続きをやっていただき通常業務に影響も通常通り行えました。<br />
            </p>
          </div>
          <div className='help_result_itme'>
            <img src={Result2} className='help_result_img' alt='' />
            <p>
              <span><strong>ものづくり補助金 卸売業</strong></span><br />
              お客さまのCRM（顧客管理システム）の導入でものづくり補助金を申請し、採択されました。スムーズな対応で大変助かりました。<br />
            </p>
          </div>
          <div className='help_result_itme'>
            <img src={Result3} className='help_result_img' alt='' />
            <p>
              <span><strong>事業再構築補助金 卸売業</strong></span><br />
              コンサルからITサービスへの展開で事業再構築補助金を使用したのですが、補助金受給までサポートいただけて大変助かりました。<br />
            </p>
          </div>
          <div className='help_result_itme'>
            <img src={Result4} className='help_result_img' alt='' />
            <p>
              <span><strong>ものづくり 卸売業</strong></span><br />
              製造業の生産工程における機械装置導入をするため、ものづくり補助金を使用しました。柔軟に対応いただき申請まで行うことができました。<br />
            </p>
          </div>
          <div className='help_result_itme'>
            <img src={Result5} className='help_result_img' alt='' />
            <p>
              <span><strong>小規模事業者補助金 卸売業</strong></span><br />
              美容院の広告・宣伝費を小規模事業者補助金で申請しました。LINEで対応していただくなど当社環境に合わせた対応をいただき助かりました。<br />
            </p>
          </div>
          <div className='help_result_itme'>
            <img src={Result6} className='help_result_img' alt='' />
            <p>
              <span><strong>IT導入補助金 卸売業</strong></span><br />
              営業の管理サービス導入のためにIT導入補助金を使用しました。サービス開発会社との書類やり取りもご対応いただけて大変助かりました。<br />
            </p>
          </div>
        </Slider>
      </div>
      </>
    );
  }
}