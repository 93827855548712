import DocumentMeta from 'react-document-meta';
import './../index.css';
import Nav from "./../nav/index";
import Footer from './../../../tabs/footer/index';
import Image1 from "./../../../../image/media/business/1/top_mini.jpeg";
import Image2 from "./../../../../image/media/business/2/top_mini.jpeg";
import Image3 from "./../../../../image/media/business/3/top_mini.jpeg";
// import Image4 from "./../../../../image/media/business/4/top_mini.jpeg";
import Image5 from "./../../../../image/media/business/5/top_mini.jpeg";
import Image6 from "./../../../../image/media/business/6/top_mini.jpeg";
import Image7 from "./../../../../image/media/business/7/top_mini.jpeg";
import Image8 from "./../../../../image/media/business/8/top_mini.jpeg";
import Image9 from "./../../../../image/media/business/9/top_mini.jpeg";
import Image10 from "./../../../../image/media/business/10/top_mini.jpeg";
import Image11 from "./../../../../image/media/business/11/top_mini.jpeg";
import Image12 from "./../../../../image/media/business/12/top_mini.jpeg";
// import Image16 from "./../../../../image/media/business/30/top_mini.jpeg";
import Image17 from "./../../../../image/media/business/17/top_mini.jpeg";
// import Image18 from "./../../../../image/media/business/32/top_mini.jpeg";
import Image19 from "./../../../../image/media/business/19/top_mini.jpeg";
// import Image20 from "./../../../../image/media/business/20/top_mini.jpeg";
import Image21 from "./../../../../image/media/business/21/top_mini.jpeg";
import Image22 from "./../../../../image/media/business/22/top_mini.jpeg";
import Image23 from "./../../../../image/media/business/23/top_mini.jpeg";
import Image24 from "./../../../../image/media/business/24/top_mini.jpeg";
import Image25 from "./../../../../image/media/business/25/top_mini.jpeg";
import Image26 from "./../../../../image/media/business/26/top_mini.jpeg";
// import Image27 from "./../../../../image/media/business/27/top_mini.jpeg";
import Image28 from "./../../../../image/media/business/28/top_mini.jpeg";
import Image29 from "./../../../../image/media/business/29/top_mini.jpeg";
import Image30 from "./../../../../image/media/business/30/top_mini.jpeg";
import Image31 from "./../../../../image/media/business/31/top_mini.jpeg";
import Image32 from "./../../../../image/media/business/32/top_mini.jpeg";
import Image33 from "./../../../../image/media/business/33/top_mini.jpeg";
import Image34 from "./../../../../image/media/business/34/top_mini.jpeg";
import Image35 from "./../../../../image/media/business/35/top_mini.jpeg";
// import Image36 from "./../../../../image/media/business/36/top_mini.jpeg";
// import Image37 from "./../../../../image/media/business/37/top_mini.jpeg";
// import Image38 from "./../../../../image/media/business/38/top_mini.jpeg";
import Image39 from "./../../../../image/media/business/39/top_mini.jpeg";
import Image40 from "./../../../../image/media/business/40/top_mini.jpeg";
import Image41 from "./../../../../image/media/business/41/top_mini.jpeg";
import Image42 from "./../../../../image/media/business/42/top_mini.jpeg";
import Image43 from "./../../../../image/media/business/43/top_mini.jpeg";
import Image44 from "./../../../../image/media/business/44/top_mini.jpeg";
import Image45 from "./../../../../image/media/business/45/top_mini.jpeg";
import Image46 from "./../../../../image/media/business/46/top_mini.jpeg";
import Image47 from "./../../../../image/media/business/47/top_mini.jpeg";
// import Image48 from "./../../../../image/media/business/48/top_mini.jpeg";
import Image49 from "./../../../../image/media/business/49/top_mini.jpeg";
// import Image50 from "./../../../../image/media/business/50/top_mini.jpeg";
// import Image51 from "./../../../../image/media/business/51/top_mini.jpeg";

const BlogText0001 = () => {

  const meta = {
    title: '事業再構築補助金の教科書',
    description: '本記事は、「初めて事業再構築補助金を申請したい」方や「公募要領を読んで、諦めかけている」方へ向けて、執筆させ事業再構築補助金 申請のファーストステップとして、本記事に目を通していただければと思います。この記事でこの3点が分かる！「事業再構築補助金を受け取るための方法・流れ」「事業再構築補助金はどのような経費が対象になるのか」「事業再構築補助金に申請できる対象者」本記事は、補助金の説明書である「公募要領」を基盤にして作成したものとなっていますので、内容を理解したうえで公募要領部を読んでみてください！',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <div className='business_media_color'>
        <Nav />

        <div className='business_media_speace'></div>

        <div className='business_media_title'>
          <div></div>
          <h3>事業再構築補助金の概要「レベル１」</h3>
        </div>

        <div className='business_media_box'>
          <a href='https://trankllc.com/media/subsidy/business/0001'>
            <img src={Image1} alt='' />
            <h3>事業再構築補助金とは？ 申請に必要な基礎知識を徹底解説</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0002'>
            <img src={Image2} alt='' />
            <h3>事業再構築補助金 5分で対象者の条件・要件を理解する！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0003'>
            <img src={Image3} alt='' />
            <h3>事業再構築補助金 12個の経費分類を具体例つきで解説！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0004'>
            <img src={Image3} alt='' />
            <h3>事業再構築補助金 申請枠・業種ごとに採択率を徹底分析！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0005'>
            <img src={Image5} alt='' />
            <h3>事業再構築補助金 提出書類の用意を３日で終わらせる方法！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0006'>
            <img src={Image6} alt='' />
            <h3>事業再構築補助金 実績報告と交付申請の概要を10分で理解！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0023'>
            <img src={Image23} alt='' />
            <h3>事業再構築補助金 交付申請の流れは、全部で５つ！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0024'>
            <img src={Image24} alt='' />
            <h3>事業再構築補助金 実績報告は、事前準備が1番大切！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0000'><p></p></a>
        </div>

        <div className='business_media_title'>
          <div></div>
          <h3>事業再構築補助金の申請枠「レベル2」</h3>
        </div>

        <div className='business_media_box'>
          <a href='https://trankllc.com/media/subsidy/business/0028'>
            <img src={Image28} alt='' />
            <h3>事業再構築補助金 物価高騰対策・回復再生応援枠</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0025'>
            <img src={Image25} alt='' />
            <h3>事業再構築補助金 市場規模が拡大してれば成長枠！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0026'>
            <img src={Image26} alt='' />
            <h3>事業再構築補助金 市場縮小で悩んでいる企業様は産業構造転換枠！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0017'>
            <img src={Image17} alt='' />
            <h3>事業再構築補助金 大規模賃金引上枠は、５つの要件を確認！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0019'>
            <img src={Image19} alt='' />
            <h3>事業再構築補助金 最低賃金枠は、４つの要件を確認！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0000'><p></p></a>
        </div>

        <div className='business_media_title'>
          <div></div>
          <h3>事業再構築補助金の要件「レベル3」</h3>
        </div>

        <div className='business_media_box'>
          <a href='https://trankllc.com/media/subsidy/business/0007'>
            <img src={Image7} alt='' />
            <h3>再構築要件</h3>
            <h4>本記事では、事業再構築の概要について解説しています。全体の流れ、要件、書類、対象費用...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0008'>
            <img src={Image8} alt='' />
            <h3>売上高減少要件</h3>
            <h4>本記事では、申請で満たさなければいけない条件・要件について、解説させていただており...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0009'>
            <img src={Image9} alt='' />
            <h3>認定支援機関要件</h3>
            <h4>本記事では、補助対象となる経費を公募要領と見比べながら解説しております。具体例や注意点...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0010'>
            <img src={Image10} alt='' />
            <h3>賃金引上要件</h3>
            <h4>本記事では、申請枠ごとの採択率について解説させていただいております。その他、分析として...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0011'>
            <img src={Image11} alt='' />
            <h3>再生要件</h3>
            <h4>本記事は、申請に必要な書類とその書類の説明をまとめております。その他、申請枠別の必要資料...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0012'>
            <img src={Image12} alt='' />
            <h3>最低賃金要件</h3>
            <h4>本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0032'>
            <img src={Image32} alt='' />
            <h3>市場拡大要件</h3>
            <h4>本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0031'>
            <img src={Image31} alt='' />
            <h3>市場縮小要件</h3>
            <h4>本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0033'>
            <img src={Image33} alt='' />
            <h3>給与総額増加要件</h3>
            <h4>本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0029'>
            <img src={Image29} alt='' />
            <h3>補助率引上要件</h3>
            <h4>本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0030'>
            <img src={Image30} alt='' />
            <h3>従業員増員要件</h3>
            <h4>本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0000'><p></p></a>
        </div>

        <div className='business_media_title'>
          <div></div>
          <h3>事業再構築補助金の申請書類「レベル４」</h3>
        </div>

        <div className='business_media_box'>
          <a href='https://trankllc.com/media/subsidy/business/0021'>
            <img src={Image21} alt='' />
            <h3>事業再構築補助金 事業計画書の作成は、この４点を抑える！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0034'>
            <img src={Image34} alt='' />
            <h3>事業再構築補助金 ミラサポを15分で作成する方法！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0035'>
            <img src={Image35} alt='' />
            <h3>市場拡大要件を満たすことの説明書</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0022'>
            <img src={Image22} alt='' />
            <h3>事業再構築補助金 10分で取れる加点書類が2つある！</h3>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0000'><p></p></a>
          <a href='https://trankllc.com/media/subsidy/business/0000'><p></p></a>
        </div>
        
        <div className='business_media_title'>
          <div></div>
          <h3>事業再構築補助金の活用例「レベル5」</h3>
        </div>

        <div className='business_media_box'>
          <a href='https://trankllc.com/media/subsidy/business/0039'>
            <img src={Image39} alt='' />
            <h3>活用事例：飲食業編</h3>
            <h4>本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0040'>
            <img src={Image40} alt='' />
            <h3>活用事例：宿泊業編</h3>
            <h4>本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0041'>
            <img src={Image41} alt='' />
            <h3>活用事例：製造業編</h3>
            <h4>本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0042'>
            <img src={Image42} alt='' />
            <h3>活用事例：卸売業編</h3>
            <h4>本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0043'>
            <img src={Image43} alt='' />
            <h3>活用事例：小売業編</h3>
            <h4>本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0044'>
            <img src={Image44} alt='' />
            <h3>活用事例：生活関連サービス業編</h3>
            <h4>本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0045'>
            <img src={Image45} alt='' />
            <h3>活用事例：製造業編</h3>
            <h4>本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0046'>
            <img src={Image46} alt='' />
            <h3>活用事例：情報通信業編</h3>
            <h4>本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0047'>
            <img src={Image47} alt='' />
            <h3>活用事例：不動産業・物品賃貸業</h3>
            <h4>本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
          <a href='https://trankllc.com/media/subsidy/business/0049'>
            <img src={Image49} alt='' />
            <h3>活用事例：教育・学習支援業編</h3>
            <h4>本記事は、補助金全体の流れと採択後の流れについて解説しております。交付申請、実績報告の書類に...</h4>
            <p>更新日：2023年4月15日</p>
          </a>
        </div>

      </div>
      
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;

