import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Topic from "./../topic";
import Nav from "./../../nav/index";
import TopImage from "./../../../../../image/media/manufacturing/1/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
// import { Link } from 'react-router-dom';

const BlogText0001 = () => {

  const meta = {
    title: '【初めての補助金】ものづくり補助金をわかりやすく解説',
    description: '本記事は、ものづくり補助金を申請したいと思っている事業者さまへ向けて、ものづくり補助金の概要をまとめさせていただきました。自分で補助金を申請しようと思ったけど、「やっぱり難しい」という方は、是非ご参考にしていただければと思います。本サイトでは、概要ページと詳細ページを用意しておりますので、概要をご確認いただいたのちに、詳細ページもあわせてご確認いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/9/20 更新日: 2022/9/20</p>
          <h1 className="media_title">【初めての補助金】<br />ものづくり補助金とは？を徹底解説</h1>
          <img src={TopImage} className="media_title_img" alt='ものづくり補助金' />

          <p className="media_text_text">
            <br />
            <br />
            <span className='media_text_text_box_title'>こんな方へ向けて</span><br />
            <br />
            本記事は、ものづくり補助金を申請したいと思っている事業者さまへ向けて、ものづくり補助金の概要をまとめさせていただきました。<br />
            <br />
            自分で補助金を申請しようと思ったけど、「やっぱり難しい」という方は、是非ご参考にしていただければと思います。<br />
            <br />
            <strong>point!</strong><br />
            本サイトでは、概要ページと詳細ページを用意しておりますので、概要をご確認いただいたのちに、詳細ページもあわせてご確認いただければと思います。<br />
            <br />
            それでは、解説に進ませていただきます！<br />
          </p>

          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">補助金とは？</AnchorLink></li>
                <li><AnchorLink href="#2" offset="100">ものづくり補助金とは？</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">申請から給付までの全体スケジュール</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">具体的な申請の流れ</AnchorLink></li>
                <li><AnchorLink href="#5" offset="100">対象事業者の確認</AnchorLink></li>
                <li><AnchorLink href="#6" offset="100">類型の確認</AnchorLink></li>
                <li><AnchorLink href="#7" offset="100">対象になる補助金経費</AnchorLink></li>
                <li><AnchorLink href="#8" offset="100">ものづくり補助金の採択率</AnchorLink></li>
                <li><AnchorLink href="#9" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          
          <>
          <div id="1" className="media_text_title">
            <h2>１.補助金とは？</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            補助金申請を検討されている方でも、「そもそも補助金についてあまり理解してないんですよね。」という方がよくいらっしゃいます。<br />
            <br />
            そこで、ものづくり補助金をみていく前に、まずは「補助金とは何か？」について、少し触れさせてください。<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>補助金とは</h3><br />
          <p className="media_text_text">
            <strong>市場経済をより良くする事業に対して、国が経費の一部を支援する施策</strong>です。<br />
            ここで、理解していただきたい点は、"目的がある"という点です。<br />
            <br />
            一見聞くと、当たり前だと感じてしまいますが、意外と目的に促していない事業計画を申請される方を多く見受けられますので、覚えておいていただければと思います。<br />
            <br />
            ちなみに、具体例をあげると、<br />
            ・雇用を目的としているのに、雇用について一切触れていない<br />
            ・生産性向上を目的としているのに、生産性向上について一切触れていない<br />
            などの状況が過去にございました。<br />
            <br />
            この場合、いくら時間をかけた計画書でも不採択となってしまいます。<br />
            ですので、このような初歩的な部分の認識を間違えないよう、まずその補助金について、理解を深めましょう。<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>補助金のよくある質問</h3><br />
          <p className="media_text_text">
            とはいえ、上記の目的が分かっても疑問が沢山残っていると思います。そこで、よくある質問をQ{'&'}A形式でご紹介させていただきます。<br />
            <br />
            <strong>Q.補助金をいただいたのちに、返済する必要はありますか？</strong><br />
            補助金に返済義務は一切ございません。もし、事業を失敗されたとしても返済は不要ですのでご安心ください。<br />
            ※実施事業の際に要件に違反した場合は、補助対象外となる場合がございます。<br />
            <br />
            <strong>Q.補助金ってどんなことができるの？</strong><br />
            補助金は、ものづくり補助金以外にも、大型の設備機器を支援してもらえる補助金や数十万円単位の広告配信に使用される補助金など、さまざまなものがございます。<br />
            <br />
            <strong>Q.補助金は申請するともらえるものですか？</strong><br />
            補助金には審査があるため、100%採択されるという事はありません。ちなみに、助成金の場合は、条件をクリアすると確実に支給されます。<br />
            <br />
            <strong>Q.採択後の報告業務等はありますか？</strong><br />
            補助金は、採択されて計画を実施したのちに振込がされる形式となっております。そのため、実施報告（実施した内容を報告）を行う必要があります。<br />
            <br />
            <strong>Q.補助金はいつ振り込まれますか？</strong><br />
            上記でご説明させていただきました通り、実施報告ののちに振り込まれる形式のため、補助金は完全後払いとなっております。<br />
            <br />
            以上、補助金のQ{'&'}Aになります。<br />
            補助金の概要が少しでもわかっていただけましたら、大変嬉しく思います！<br />
            <br />
            では、ここからものづくり補助金について、詳しくご説明させていただきます！<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>２.ものづくり補助金とは？</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            では、上記で説明させていただきました目的について、確認していきましょう。<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>ものづくり補助金の目的</h3><br />
          <p className="media_text_text">
            中小企業・小規模事業者等が生産性を向上させるために行う、革新的サービス開発・試作品開発・生産プロセスの改善を支援する補助金となっています。<br />
            <br />
            上記に加えて、ものづくり補助金の特徴を３点あげさせていただきます。<br />
            ①補助上限金額が3000万円と比較的大きい金額の補助金であること<br />
            ②新規事業ではなく、生産性向上がメインであること<br />
            ③設備以外にもクラウドサービス等も計上できること<br />
            <br />
            下記に<span className="tooltip"><span className="tooltip_text">国がまとめた補助金に対するルール・規則を定めた資料のこと</span>公募要領</span>の引用文を記載させていただきましたので、ご参照いただければと思います。<br />
            <br />
            補助金を扱うプロも皆さまと同じように<span className="tooltip"><span className="tooltip_text">国がまとめた補助金に対するルール・規則を定めた資料のこと</span>公募要領</span>を確認しながら、申請書類の作成を行なっておりますので、ご自身での申請を検討されている方は、<span className="tooltip"><span className="tooltip_text">国がまとめた補助金に対するルール・規則を定めた資料のこと</span>公募要領</span>を読めるようになりましょう。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>ものづくり補助金の目的</span><br />
              中小企業・小規模事業者等が今後複数年にわたり相次いで直面する制度変更（働き方改革や被用者保険の適用拡大、賃上げ、インボイス導入等）等に対応するため、中小企業・小規模事業者等が取り組む革新的サービス開発・試作品開発・生産プロセスの改善を行い生産性を向上させるための設備投資等を支援します。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <br />
          <br />
          <h3 className='media_text_text_title'>補助金のよくある質問</h3><br />
          <p className="media_text_text">
            とはいえ、上記の補助金のQ{'&'}Aと同様に不明点が多々残っていると思いますので、よくある質問をQ{'&'}A形式でご紹介させていただきます。<br />
            <br />
            <strong>Q.ものづくり補助金の実施期間は、どのくらいありますか？</strong><br />
            実施期間は、事業者さまにもよりますが、基本的には約1年とされております。<br />
            <br />
            <strong>Q.ものづくり補助金は、運用コストを費用計上できますか？</strong><br />
            補助金の実施期間中に使用する費用は、計上することができます。<br />
            <br />
            <strong>Q.ものづくり補助金は、実施した事業も申請できますか？</strong><br />
            事業を既に行なってしまった事業者は、申請することができません。<br />
            <br />
            <strong>Q.採択後の報告業務等はありますか？</strong><br />
            補助金は採択後の実施期間ののち、使用した経費を事務局へ申請しなければいけません。<br />
            <br />
            <strong>Q.ものづくり補助金はいつ振り込まれますか？</strong><br />
            実施事業後の報告~請求~振込となります。そのため、自己資金、融資等で一時的に資金を補填する必要があります。<br />
            <br />
            以上、ものづくり補助金のQ{'&'}Aになります。<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>３.申請から給付までの全体スケジュール</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            では、次にものづくり補助金の申請から給付までのスケジュールについて、ご説明させていただきます。<br />
            <br />
            補助金の全体スケジュールは、とても長く1年以上にもなることもあるため、頭の片隅に全体のスケジュールを入れておくと申請から交付、振込完了まで余裕を持って行動することができます。<br />
            <br />
            さらに、今何をするべきなのかを把握する必要があるため、必要要素となっております。<br />
            <br />
            ①<strong>公募</strong><br />
            公募発表から申請までの準備期間となります。<br />
            ↓<br />
            ②<strong>申請（電子申請）</strong><br />
            用意した資料を申請期日までに電子上で申請します。<br />
            ↓<br />
            ③<strong>採択通知</strong><br />
            申請した内容が開示され、採択・不採択を判断します。<br />
            ↓<br />
            ④<strong>交付申請</strong><br />
            必要な費用の見積もり書類等をまとめ、補助金額の決定を行います。<br />
            ↓<br />
            ⑤<strong>補助事業実施期間</strong><br />
            申請した事業計画を実際に実施する期間となります。<br />
            ↓<br />
            ⑥<strong>確定検査（交付額の確定）</strong><br />
            申請し費用が計上されるかを判断される検査となります。<br />
            ↓<br />
            ⑦<strong>補助金の請求</strong><br />
            補助金事務局に対して、請求を行います。
            ↓<br />
            ⑧<strong>補助金の支払</strong><br />
            補助金の請求から1ヶ月前後で振込が完了します。<br />
            ↓<br />
            ⑨<strong>事業化状況報告・知的財産権等報告</strong><br />
            {/* after */}
          </p>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>４.具体的な申請の流れ</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            上記で全体スケジュールを説明させていただいた次は、ものづくり補助金に特化した申請までの流れを説明していきます。<br />
            <br />
            ①<strong>目的の確認</strong><br />
            ものづくり補助金の目的を確認し、適正及び補助金の方向性を確認します。<br />
            ↓<br />
            ②<strong>対象者の確認</strong><br />
            ものづくり補助金の申請が可能なのかを判断します。<br />
            ↓<br />
            ③<strong>類型の選定</strong><br />
            ものづくり補助金の6つに類型から1つ選択します。<br />
            ↓<br />
            ④<strong>類型の補助率の確認</strong><br />
            上記の類型選択ののち、補助率を確認します。<br />
            ↓<br />
            ⑤<strong>対象経費の確認</strong><br />
            対象経費も含めて、事業計画の実現性について検討します。<br />
            ↓<br />
            ⑥<strong>資料の確認</strong><br />
            必要書類の用意と加点書類の用意を進めていきます。<br />
            ↓<br />
            ⑦<strong>資料の作成・準備</strong><br />
            上記で必要書類を確認してから、作成に入ります。<br />
            ↓<br />
            ⑧<strong>申請</strong><br />
            電子上で、情報入力、資料の添付を行い申請を行います。<br />
          </p>
          </>

          <>
          <div id='5' className="media_text_title">
            <h2>５.対象事業者の確認</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            全体の流れ、申請までの流れを確認した次は、対象事業者さまについて、ご説明させていただきます。<br />
            <br />
            対象事業者の確認（本項目）では、主な対象要件を３つあげさせていただきますので、自社の情報と見比べながら確認していただければと思います。<br />
            <br />
            なお、主な３つではなく、すべての要件（詳細）は、以下のページにて記載がありますので、ご参照いただければと思います。<br />
            <br />
            {/* <Link to='/media/1002'>
              <span className='media_text_text_link'>=＞【徹底解説】対象者要件に関する詳細</span>
            </Link> */}
            <br />
            <br />
          </p>
          <h3 className='media_text_text_title'>中小企業等であること</h3><br />
          <p className="media_text_text">
            こちら中小企業等を判断するために以下の表が公開されておりますので、申請を希望されている事業者さまの情報と見比べてご確認いただければと思います。<br />
            <br />
            なお、中小企業等等に含まれない場合でも例外的に申請が可能な事業者さまがおりますので、詳しくはこちらをご参照ください。<br />
            <br />
            <br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>項目</th><th className='media_table_0001_1_2'>資本金</th><th className='media_table_0001_1_3'>常勤従業員数</th>
              </tr>
              <tr>
                <td><strong>製造業、建設業、運輸業、旅行業 </strong></td>
                <td>3億円</td>
                <td>300人</td>
              </tr>
              <tr>
                <td><strong>卸売業</strong></td>
                <td>1億円</td>
                <td>100人</td>
              </tr>
              <tr>
                <td><strong>サービス業</strong></td>
                <td>5,000万円</td>
                <td>100人</td>
              </tr>
              <tr>
                <td><strong>小売業</strong></td>
                <td>5,000万円</td>
                <td>50人</td>
              </tr>
              <tr>
                <td><strong>ゴム製品製造業</strong></td>
                <td>3億円</td>
                <td>900人</td>
              </tr>
              <tr>
                <td><strong>ソフトウェア業又は情報処理サービス業</strong></td>
                <td>3億円</td>
                <td>300人</td>
              </tr>
              <tr>
                <td><strong>旅館業</strong></td>
                <td>5,000万円</td>
                <td>200人</td>
              </tr>
              <tr>
                <td><strong>その他の業種</strong></td>
                <td>3億円</td>
                <td>300人</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <h3 className='media_text_text_title'>中小企業者（組合関連）であること</h3><br />
          <p className="media_text_text">
            中小企業でなくても、以下に属す組合も対象者に含まれます。<br />
            <br />
            対象者判定のために以下の表が公開されておりますので、ご確認いただければと思います。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>組織形態</th>
              </tr>
              <tr>
                <td><strong>企業組合 </strong></td>
              </tr>
              <tr>
                <td><strong>協業組合</strong></td>
              </tr>
              <tr>
                <td><strong>事業協同組合、事業協同小組合、協同組合連合会</strong></td>
              </tr>
              <tr>
                <td><strong>商工組合、商工組合連合会</strong></td>
              </tr>
              <tr>
                <td><strong>商店街振興組合、商店街振興組合連合会</strong></td>
              </tr>
              <tr>
                <td><strong>水産加工業協同組合、水産加工業協同組合連合会</strong></td>
              </tr>
              <tr>
                <td><strong>生活衛生同業組合、生活衛生同業小組合、生活衛生同業組合連合会</strong></td>
              </tr>
              <tr>
                <td><strong>酒造組合、酒造組合連合会、酒造組合中央会</strong></td>
              </tr>
              <tr>
                <td><strong>内航海運組合、内航海運組合連合会</strong></td>
              </tr>
              <tr>
                <td><strong>技術研究組合</strong></td>
              </tr>
            </tbody>
          </table>
          </>

          <>
          <div id='6' className="media_text_title">
            <h2>６.類型の確認</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            対象事業者の確認が終わったところで、次は実際どの類型（枠）で申請するかを検討します。<br />
            <br />
            と、その前に類型の説明になりますが、簡単にいうと<strong>補助金を細分化し適正な要件を課すもの</strong>だと思ってください。<br />
            <br />
            具体的には、こういう事業者さまはこの枠、これを目的にしている事業者さまはこの枠という形で分けられており、そこで選択された類型によって要件、補助率、上限金額が細かく提示されています。<br />
            <br />
            そのため、申請するに当たって、どの枠なのか、どの要件を満たす必要があるかなどを判断するために理解しなくてはいけない要素となっております。<br />
            <br />
            本補助金の類型は、<strong>「通常枠」</strong>、<strong>「回復型賃上げ・雇用拡大枠」</strong>、<strong>「デジタル枠」</strong>、<strong>「グリーン枠」</strong>、<strong>「グローバル展開型」</strong>の５つございます。<br />
            <br />
            ここでは、類型の概要を説明させていただきます。<br />
            <br />
            上記で触れさせていただきました類型ごとの詳しい要件、補助率、上限金額については、以下の記事にて解説をしておりますので、ご確認いただけばと思います。<br />
            <br />
            {/* <Link to='/media/1002'>
              <span className='media_text_text_link'>=＞【徹底解説】類型の要件と補助率に関する詳細</span>
            </Link> */}
            <br />
            <br />
            <br />
          </p>
          <h3 className='media_text_text_title'>通常枠</h3><br />
          <p className="media_text_text">
            革新的な製品・サービス開発又は生産プロセス・サービス提供方法の改善に必要な設備・システム投資等を支援<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>回復型賃上げ・雇用拡大枠</h3><br />
          <p className="media_text_text">
            業況が厳しいながら賃上げ・雇用拡大に取り組む事業者（※）が行う、革新的な製品・サービス開発又は生産プロセス・サービス提供方法の改善に必要な設備・システム投資等を支援<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>デジタル枠</h3><br />
          <p className="media_text_text">
            DX（デジタルトランスフォーメーション）に資する革新的な製品・サービス開発又はデジタル技術を活用した生産プロセス・サービス提供方法の改善による生産性向上に必要な設備・システム投資等を支援<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>グリーン枠</h3><br />
          <p className="media_text_text">
            温室効果ガスの排出削減に資する革新的な製品・サービス開発又は炭素生産性向上を伴う生産プロセス・サービス提供方法の改善による生産性向上に必要な設備・システム投資等を支援<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>グローバル展開型</h3><br />
          <p className="media_text_text">
            海外事業の拡大・強化等を目的とした「革新的な製品・サービス開発」又は「生産プロセス・サービス提供方法の改善」に必要な設備・システム投資等を支援（①海外直接投資、②海外市場開拓、③インバウンド市場開拓、④海外事業者との共同事業のいずれかに合致するもの）<br />
          </p>
          </>

          <>
          <div id='7' className="media_text_title">
            <h2>７.対象になる補助金経費</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            類型について確認した次は、対象経費についてご説明させていただきます。<br />
            <br />
            対象経費は、主に以下の11項目が補助経費となっております。軽く目を通していただけると分かる通り想像以上に幅広く経費が計上できる形となっております。<br />
            <br />
            申請を検討される方は、計上したい項目が補助対象になるのかも含めご確認いただければと思います。<br />
            <br />
            {/* <Link to='/media/1002'>
              <span className='media_text_text_link'>=＞【徹底解説】類型の要件と補助率に関する詳細</span><br />
            </Link> */}
            <br />
            <br />
          </p>
          <h3 className='media_text_text_title'>機械装置・システム構築費</h3><br />
          <p className="media_text_text">
            補助事業実施に使用される機械・装置、工具・器具、その他ソフトウェアに使用する経費<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>技術導入費</h3><br />
          <p className="media_text_text">
            本事業の実施に必要な知的財産権等の導入に要する経費<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>専門家経費</h3><br />
          <p className="media_text_text">
            本事業の実施のために依頼した専門家に支払われる経費<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>運搬費</h3><br />
          <p className="media_text_text">
            運搬料、宅配・郵送料等に要する経費<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>クラウドサービス利用費</h3><br />
          <p className="media_text_text">
            クラウドサービスの利用に関する経費<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>原材料費</h3><br />
          <p className="media_text_text">
            試作品の開発に必要な原材料及び副資材の購入に要する経費<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>外注費</h3><br />
          <p className="media_text_text">
            新製品・サービスの開発に必要な加工や設計（デザイン）・検査等の一部を外注（請負、委託等）する場合の経費<br />
            <br />
          </p>
          {/* ここから */}
          <h3 className='media_text_text_title'>知的財産権等関連経費</h3><br />
          <p className="media_text_text">
            新製品・サービスの事業化に当たって必要となる特許権等の知的財産権等の取得に要する弁理士の手続代行費用、外国特許出願のための翻訳料等の知的財産権等取得に関連する経費<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>海外旅費（グローバル型のみ）</h3><br />
          <p className="media_text_text">
            海外事業の拡大・強化等を目的とした、本事業に必要不可欠な海外渡航及び宿泊等に要する経費<br />
            <br />
          </p>
          </>
          
          <>
          <div id='8' className="media_text_title">
            <h2>８.ものづくり補助金の採択率</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            ものづくり補助金の採択率は、比較的最近高くなっております。<br />
            本記事では、【一般型】【低リスク感染型ビジネス枠】の２つを紹介させていただきます。<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>【一般型】</h3><br /> 
          <p className="media_text_text">
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>項目</th><th>申請件数</th><th>採択件数</th><th>採択率</th>
              </tr>
              <tr>
                <td><strong>第１次</strong></td>
                <td>8,044</td>
                <td>7,308</td>
                <td>90.9%</td>
              </tr>
              <tr>
                <td><strong>第２次</strong></td>
                <td>19,154</td>
                <td>12,478</td>
                <td>65.1%</td>
              </tr>
              <tr>
                <td><strong>第３次</strong></td>
                <td>13,642</td>
                <td>7,040</td>
                <td>51.6%</td>
              </tr>
              <tr>
                <td><strong>第４次</strong></td>
                <td>16,126</td>
                <td>7,128</td>
                <td>44.2%</td>
              </tr>
              <tr>
                <td><strong>第５次</strong></td>
                <td>12,738</td>
                <td>6,869</td>
                <td>53.9%</td>
              </tr>
              <tr>
                <td><strong>第６次</strong></td>
                <td>9,914</td>
                <td>6,846</td>
                <td>69.1%</td>
              </tr>
              <tr>
                <td><strong>第７次</strong></td>
                <td>9,339</td>
                <td>6,517</td>
                <td>69.8%</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <h3 className='media_text_text_title'>低リスク感染型ビジネス枠</h3><br /> 
          <p className="media_text_text">
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>項目</th><th>申請件数</th><th>採択件数</th><th>採択率</th>
              </tr>
              <tr>
                <td><strong>第１次</strong></td>
                <td>7,827</td>
                <td>3,512</td>
                <td>44.8%</td>
              </tr>
              <tr>
                <td><strong>第２次</strong></td>
                <td>10,205</td>
                <td>5,361</td>
                <td>52.5%</td>
              </tr>
              <tr>
                <td><strong>第３次</strong></td>
                <td>8,056</td>
                <td>5,022</td>
                <td>62.3%</td>
              </tr>
              <tr>
                <td><strong>第４次</strong></td>
                <td>8,243</td>
                <td>5,780</td>
                <td>70.1%</td>
              </tr>
              <tr>
                <td><strong>第５次</strong></td>
                <td>6,208</td>
                <td>4,138</td>
                <td>66.6%</td>
              </tr>
              <tr>
                <td><strong>第６次</strong></td>
                <td>11,721</td>
                <td>8,040</td>
                <td>68.6%</td>
              </tr>
            </tbody>
          </table>
          </>
          
          <>
          <div id='9' className="media_text_title">
            <h2>９.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            本記事では、ものづくり補助金を申請したい事業者さまへ向けて、ものづくり補助金の概要について記事にさせていただきました。<br />
            <br />
            募集要項を各所に添付させていただきましたが、ご覧いただけば分かる通り難しい内容となっております。<br />
            そこで、もし、本格的にものづくり補助金の導入を検討されていましたら、弊社含め補助金に詳しい事業者へご相談ください。<br />
            <br />
            ご相談は、画面右上の補助金に関する相談をするから問い合わせにて、お待ちしております。<br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
        
      </div>

      <div className='media_'></div>

      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
