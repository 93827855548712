import './styles.css';
import Nav from './items/nav/index';
import Header from './items/header/index';
import Footer from './items/footer/index';

// Y
import Y from './y/index';

// X
import X1 from './x/x1';
import X2 from './x/x2';

// Z
import Z from './z/index';

// W
import W1_1 from './w/w1_1';
import W1_2 from './w/w1_2';
import W2 from './w/w2';
import W3 from './w/w3';
import W4 from './w/w4';
import W5 from './w/w5';
import W6 from './w/w6';
import W7 from './w/w7';
import W8 from './w/w8';

// import Icon from './../../../../../image/bid/icon.jpeg';
import Progress1 from './../../../../../image/bid/management/progress/1.png';
// import Progress2 from './../../../../../image/bid/management/progress/2.png';
// import Progress3 from './../../../../../image/bid/management/progress/3.png';
// import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useState } from "react";

const BlogText0001 = () => {

  const [ count, setCount ] = useState(1);

  // 経営状況分析
  const [ , setAnalysis ] = useState(0);

  // 工事種類別年間平均完成工事高
  const [ , setConstructionCost ] = useState(0);
  // const [ , setConstructionCostResult ] = useState(0);

  // 自己資本額
  const [ , setNetWorth ] = useState(0);
  // const [ , setNetWorthResult ] = useState(0);
  // 平均利益額
  // const [ averageProfit, setAverageProfit ] = useState(0);
  const [ , setAverageProfitResult ] = useState(0);

  // 1級監理受講者数
  // const [ , setZ1Result ] = useState(0);  
  // 1級監理受講者数
  const [ , setOneStudents ] = useState(0);
  // 1級技術者数
  const [ , setOneEngineers ] = useState(0);
  // 監理技術者補佐
  const [ , setEngineerAssistant ] = useState(0);
  // 基幹技能者数
  const [ , setTechnicians ] = useState(0);
  // 2級技術者数
  const [ , setTwoEngineers ] = useState(0);
  // その他技術者数
  const [ , setOtherTechnicians ] = useState(0);

  // 元請完成工事高
  const [ , setBeforeConstructionCost ] = useState(0);
  
  // 技術者数
  const [ , setEngineers ] = useState(0);
  // 技能者数
  const [ , setSkilledWorkers ] = useState(0);
  // CPD
  const [ , setCPD ] = useState(0);
  // １以上上位であった者
  const [ , setOneMore ] = useState(0);
  // 最上位の区分に該当するとされた者の数
  const [ , setTopPerson ] = useState(0);

  // 建設業退職金共済制度加入の有無
  const [ , setAidSystem ] = useState(0);
  // 退職一時金制度若しくは企業年金制度導入の有無
  const [ , setPensionSystem ] = useState(0);
  // 法定外労働災害補償制度加入の有無
  const [ , setCompensationSystem ] = useState(0);
  // 若年の技術者及び技能労働者の育成及び確保の状況（Ⅰ）
  // const, setNurturing ] = useState(0);
  // 知識及び技術又は技能の向上に関する取組の状況（Ⅱ）
  // const, setImprovement ] = useState(0);
  // ワーク・ライフ・バランスに関する取組の状況（Ⅲ）
  const [ , setWorkLife ] = useState(0);
  // 建設工事に従事する者の就業規則を蓄積するために必要な措置の実施状況（Ⅳ）
  const [ , setRule ] = useState(0);
  // 雇用保険加入の有無
  const [ , setEmployee ] = useState(0);
  // 健康保険加入の有無
  const [ , setHealth ] = useState(0);
  // 厚生年金保険加入の有無
  const [ , setPension ] = useState(0);
  // 厚生年金保険加入の有無
  const [ , setOperationYears ] = useState(0);

  // 技術職員名簿に記載された若年技術職員の人数
  const [ , setYoungTechnical ] = useState(0);
  // 技術職員名簿に記載された技術職員の人数の合計
  const [ , setTechnicalSum ] = useState(0);
  // 若年技術職員のうち審査対象年において新規に技術職員となった人数
  const [ , setNewTechnicalSum ] = useState(0);
  
  // 監査の受審状況
  const [ , setAudit ] = useState(0);
  // 公認会計士等
  const [ , setCpa ] = useState(0);
  // 登録経理試験
  const [ , setRegisteredExamination ] = useState(0);
  
  // 建築機会
  const [ , setConstructionMachinery ] = useState(0);
  const [ , setCertification ] = useState(0);

  // 民事再生法又は会社更生法の適用の有無
  const [ , setLaw ] = useState();

  // 防災活動への貢献の状況
  const [ , setDisasterAgreement ] = useState();
  // 法令遵守の状況（Ｗ4）
  const [ , setLegalCompliance ] = useState();
  
  // 研究開発費
  const [ , setResearch ] = useState();

  // function test() {

  //   console.log(analysis)

  //   // X1
  //   if (constructionCost >= 100000000000) {
  //     // １，０００億円以上 2，309
  //     setConstructionCostResult(2309);
  //   } else if (100000000000 > constructionCost && constructionCost >= 80000000000) {
  //     // ８００億円以上 １，０００億円未満 114×（年間平均完成工事高）÷20,000,000＋1,739
  //     setConstructionCostResult(114*constructionCost/20000000+1739);
  //   } else if (80000000000 > constructionCost && constructionCost >= 6000000000) {
  //     // ６００億円以上 ８００億円未満 101×（年間平均完成工事高）÷20,000,000＋1,791
  //     setConstructionCostResult(101*constructionCost/20000000+1791);
  //   } else if (6000000000 > constructionCost && constructionCost >= 5000000000) {
  //     // ５００億円以上 ６００億円未満 88×（年間平均完成工事高）÷10,000,000＋1,566
  //     setConstructionCostResult(88*constructionCost/10000000+1566);
  //   } else if (5000000000 > constructionCost && constructionCost >= 4000000000) {
  //     // ４００億円以上 ５００億円未満 89×（年間平均完成工事高）÷10,000,000＋1,561
  //     setConstructionCostResult(89*constructionCost/10000000+1561);
  //   } else if (4000000000 > constructionCost && constructionCost >= 3000000000) {
  //     // ３００億円以上 ４００億円未満 89×（年間平均完成工事高）÷10,000,000＋1,561
  //     setConstructionCostResult(89*constructionCost/10000000+1561);
  //   } else if (3000000000 > constructionCost && constructionCost >= 2500000000) {
  //     // ２５０億円以上 ３００億円未満 75×（年間平均完成工事高）÷5,000,000＋1,378
  //     setConstructionCostResult(75*constructionCost/5000000+1378);
  //   } else if (2500000000 > constructionCost && constructionCost >= 20000000000) {
  //     // ２００億円以上 ２５０億円未満 76×（年間平均完成工事高）÷5,000,000＋1,373
  //     setConstructionCostResult(76*constructionCost/5000000+1373);
  //   } else if (20000000000 > constructionCost && constructionCost >= 15000000000) {
  //     // １５０億円以上 ２００億円未満 76×（年間平均完成工事高）÷5,000,000＋1,373
  //     setConstructionCostResult(76*constructionCost/5000000+1373);
  //   } else if (15000000000 > constructionCost && constructionCost >= 12000000000) {
  //     // １２０億円以上 １５０億円未満 64×（年間平均完成工事高）÷3,000,000＋1,281
  //     setConstructionCostResult(64*constructionCost/3000000+1281);
  //   } else if (12000000000 > constructionCost && constructionCost >= 10000000000) {
  //     // １００億円以上 １２０億円未満 62×（年間平均完成工事高）÷2,000,000＋1,165
  //     setConstructionCostResult(62*constructionCost/2000000+1165);
  //   } else if (10000000000 > constructionCost && constructionCost >= 8000000000) {
  //     // ８０億円以上 １００億円未満 64×（年間平均完成工事高）÷2,000,000＋1,155
  //     setConstructionCostResult(64*constructionCost/2000000+1155);
  //   } else if (8000000000 > constructionCost && constructionCost >= 6000000000) {
  //     // ６０億円以上 ８０億円未満 50×（年間平均完成工事高）÷2,000,000＋1,211
  //     setConstructionCostResult(50*constructionCost/2000000+1211);
  //   } else if (6000000000 > constructionCost && constructionCost >= 5000000000) {
  //     // ５０億円以上 ６０億円未満 51×（年間平均完成工事高）÷1,000,000＋1,055
  //     setConstructionCostResult(51*constructionCost/1000000+1055);
  //   } else if (5000000000 > constructionCost && constructionCost >= 4000000000) {
  //     // ４０億円以上 ５０億円未満 51×（年間平均完成工事高）÷1,000,000＋1,055
  //     setConstructionCostResult(51*constructionCost/1000000+1055);
  //   } else if (4000000000 > constructionCost && constructionCost >= 3000000000) {
  //     // ３０億円以上 ４０億円未満 50×（年間平均完成工事高）÷1,000,000＋1,059
  //     setConstructionCostResult(50*constructionCost/1000000+1373);
  //   } else if (3000000000 > constructionCost && constructionCost >= 2500000000) {
  //     // ２５億円以上 ３０億円未満 51×（年間平均完成工事高）÷500,000＋903
  //     setConstructionCostResult(51*constructionCost/500000+1373);
  //   } else if (2500000000 > constructionCost && constructionCost >= 2000000000) {
  //     // ２０億円以上 ２５億円未満 39×（年間平均完成工事高）÷500,000＋963
  //     setConstructionCostResult(39*constructionCost/500000+1373);
  //   } else if (2000000000 > constructionCost && constructionCost >= 1500000000) {
  //     // １５億円以上 ２０億円未満 36×（年間平均完成工事高）÷500,000＋975
  //     setConstructionCostResult(36*constructionCost/500000+1373);
  //   } else if (1500000000 > constructionCost && constructionCost >= 1200000000) {
  //     // １２億円以上 １５億円未満 38×（年間平均完成工事高）÷300,000＋893
  //     setConstructionCostResult(38*constructionCost/300000+1373);
  //   } else if (1200000000 > constructionCost && constructionCost >= 1000000000) {
  //     // １０億円以上 １２億円未満 39×（年間平均完成工事高）÷200,000＋811
  //     setConstructionCostResult(39*constructionCost/200000+1373);
  //   } else if (1000000000 > constructionCost && constructionCost >= 800000000) {
  //     // ８億円以上 １０億円未満 38×（年間平均完成工事高）÷200,000＋816
  //     setConstructionCostResult(38*constructionCost/200000+1373);
  //   } else if (800000000 > constructionCost && constructionCost >= 600000000) {
  //     // ６億円以上 ８億円未満 25×（年間平均完成工事高）÷200,000＋868
  //     setConstructionCostResult(25*constructionCost/200000+1373);
  //   } else if (600000000 > constructionCost && constructionCost >= 500000000) {
  //     // ５億円以上 ６億円未満 25×（年間平均完成工事高）÷100,000＋793
  //     setConstructionCostResult(25*constructionCost/100000+1373);
  //   } else if (500000000 > constructionCost && constructionCost >= 400000000) {
  //     // ４億円以上 ５億円未満 34×（年間平均完成工事高）÷100,000＋748
  //     setConstructionCostResult(34*constructionCost/100000+1373);
  //   } else if (400000000 > constructionCost && constructionCost >= 300000000) {
  //     // ３億円以上 ４億円未満 42×（年間平均完成工事高）÷100,000＋716
  //     setConstructionCostResult(42*constructionCost/100000+1373);
  //   } else if (300000000 > constructionCost && constructionCost >= 250000000) {
  //     // ２億５，０００万円以上 ３億円未満 24×（年間平均完成工事高）÷50,000＋698
  //     setConstructionCostResult(24*constructionCost/50000+1373);
  //   } else if (250000000 > constructionCost && constructionCost >= 200000000) {
  //     // ２億円以上 ２億５，０００万円未満 28×（年間平均完成工事高）÷50,000＋678
  //     setConstructionCostResult(28*constructionCost/50000+1373);
  //   } else if (200000000 > constructionCost && constructionCost >= 150000000) {
  //     // １億５，０００万円以上 ２億円未満 34×（年間平均完成工事高）÷50,000＋654
  //     setConstructionCostResult(34*constructionCost/50000+1373);
  //   } else if (150000000 > constructionCost && constructionCost >= 120000000) {
  //     // １億２，０００万円以上 １億５，０００万円未満 26×（年間平均完成工事高）÷30,000＋626
  //     setConstructionCostResult(26*constructionCost/30000+1373);
  //   } else if (120000000 > constructionCost && constructionCost >= 100000000) {
  //     // １億円以上 １億２，０００万円未満 19×（年間平均完成工事高）÷20,000＋616
  //     setConstructionCostResult(19*constructionCost/20000+1373);
  //   } else if (100000000 > constructionCost && constructionCost >= 80000000) {
  //     // ８，０００万円以上 １億円未満 22×（年間平均完成工事高）÷20,000＋601
  //     setConstructionCostResult(22*constructionCost/20000+1373);
  //   } else if (80000000 > constructionCost && constructionCost >= 60000000) {
  //     // ６，０００万円以上 ８，０００万円未満 28×（年間平均完成工事高）÷20,000＋577
  //     setConstructionCostResult(28*constructionCost/20000+1373);
  //   } else if (60000000 > constructionCost && constructionCost >= 50000000) {
  //     // ５，０００万円以上 ６，０００万円未満 16×（年間平均完成工事高）÷10,000＋565
  //     setConstructionCostResult(16*constructionCost/10000+1373);
  //   } else if (50000000 > constructionCost && constructionCost >= 40000000) {
  //     // ４，０００万円以上 ５，０００万円未満 19×（年間平均完成工事高）÷10,000＋550
  //     setConstructionCostResult(19*constructionCost/10000+1373);
  //   } else if (40000000 > constructionCost && constructionCost >= 30000000) {
  //     // ３，０００万円以上 ４，０００万円未満 24×（年間平均完成工事高）÷10,000＋530
  //     setConstructionCostResult(24*constructionCost/10000+1373);
  //   } else if (30000000 > constructionCost && constructionCost >= 25000000) {
  //     // ２，５００万円以上 ３，０００万円未満 13×（年間平均完成工事高）÷5,000＋524
  //     setConstructionCostResult(13*constructionCost/5000+1373);
  //   } else if (25000000 > constructionCost && constructionCost >= 20000000) {
  //     // ２，０００万円以上 ２，５００万円未満 16×（年間平均完成工事高）÷5,000＋509
  //     setConstructionCostResult(16*constructionCost/5000+1373);
  //   } else if (20000000 > constructionCost && constructionCost >= 15000000) {
  //     // １，５００万円以上 ２，０００万円未満 20×（年間平均完成工事高）÷5,000＋493
  //     setConstructionCostResult(20*constructionCost/5000+1373);
  //   } else if (15000000 > constructionCost && constructionCost >= 12000000) {
  //     // １，２００万円以上 １，５００万円未満 14×（年間平均完成工事高）÷3,000＋483
  //     setConstructionCostResult(14*constructionCost/3000+1373);
  //   } else if (12000000 > constructionCost && constructionCost >= 10000000) {
  //     // １，０００万円以上 １，２００万円未満 11×（年間平均完成工事高）÷2,000＋473
  //     setConstructionCostResult(11*constructionCost/2000+473);
  //   } else if (10000000 > constructionCost) {
  //     // １，０００万円未満 131×（年間平均完成工事高）÷10,000＋397
  //     setConstructionCostResult(131*constructionCost/10000+397);
  //   }

  //   // X2：自己資本額
  //   if (netWorth >= 30000000000) {
  //     // ３００億円以上 2447
  //     setNetWorthResult(2447);
  //   } else if (30000000000 > netWorth && netWorth >= 25000000000) {
  //     // ２５０億円以上 ３００億円未満 134×（平均利益額）÷5,000,000＋1,643
  //     setNetWorthResult(134*netWorth/5000000+1643);
  //   } else if (25000000000 > netWorth && netWorth >= 20000000000) {
  //     // ２００億円以上 ２５０億円未満 151×（平均利益額）÷5,000,000＋1,558
  //     setNetWorthResult(151*netWorth/5000000+1558);
  //   } else if (20000000000 > netWorth && netWorth >= 15000000000) {
  //     // １５０億円以上 ２００億円未満 175×（平均利益額）÷5,000,000＋1,462
  //     setNetWorthResult(175*netWorth/5000000+1462);
  //   } else if (15000000000 > netWorth && netWorth >= 12000000000) {
  //     // １２０億円以上 １５０億円未満 123×（平均利益額）÷3,000,000＋1,372
  //     setNetWorthResult(123*netWorth/3000000+1372);
  //   } else if (12000000000 > netWorth && netWorth >= 10000000000) {
  //     // １００億円以上 １２０億円未満 93×（平均利益額）÷2,000,000＋1,306
  //     setNetWorthResult(93*netWorth/2000000+1306);
  //   } else if (10000000000 > netWorth && netWorth >= 8000000000) {
  //     // ８０億円以上 １００億円未満 104×（平均利益額）÷2,000,000＋1,251
  //     setNetWorthResult(104*netWorth/2000000+1251);
  //   } else if (8000000000 > netWorth && netWorth >= 6000000000) {
  //     // ６０億円以上 ８０億円未満 122×（平均利益額）÷2,000,000＋1,179
  //     setNetWorthResult(122*netWorth/2000000+1179);
  //   } else if (6000000000 > netWorth && netWorth >= 5000000000) {
  //     // ５０億円以上 ６０億円未満 70×（平均利益額）÷1,000,000＋1,125
  //     setNetWorthResult(70*netWorth/1000000+1125);
  //   } else if (5000000000 > netWorth && netWorth >= 4000000000) {
  //     // ４０億円以上 ５０億円未満 79×（平均利益額）÷1,000,000＋1,080
  //     setNetWorthResult(79*netWorth/1000000+1080);
  //   } else if (4000000000 > netWorth && netWorth >= 3000000000) {
  //     // ３０億円以上 ４０億円未満 92×（平均利益額）÷1,000,000＋1,028
  //     setNetWorthResult(92*netWorth/1000000+1028);
  //   } else if (3000000000 > netWorth && netWorth >= 2500000000) {
  //     // ２５億円以上 ３０億円未満 54×（平均利益額）÷500,000＋980
  //     setNetWorthResult(54*netWorth/500000+980);
  //   } else if (2500000000 > netWorth && netWorth >= 2000000000) {
  //     // ２０億円以上 ２５億円未満 60×（平均利益額）÷500,000＋950
  //     setNetWorthResult(60*netWorth/500000+950);
  //   } else if (2000000000 > netWorth && netWorth >= 1500000000) {
  //     // １５億円以上 ２０億円未満 70×（平均利益額）÷500,000＋910
  //     setNetWorthResult(70*netWorth/500000+910);
  //   } else if (1500000000 > netWorth && netWorth >= 1200000000) {
  //     // １２億円以上 １５億円未満 48×（平均利益額）÷300,000＋880
  //     setNetWorthResult(48*netWorth/300000+880);
  //   } else if (1200000000 > netWorth && netWorth >= 1000000000) {
  //     // １０億円以上 １２億円未満 37×（平均利益額）÷200,000＋850
  //     setNetWorthResult(37*netWorth/200000+850);
  //   } else if (1000000000 > netWorth && netWorth >= 800000000) {
  //     // ８億円以上 １０億円未満 42×（平均利益額）÷200,000＋825
  //     setNetWorthResult(42*netWorth/200000+825);
  //   } else if (800000000 > netWorth && netWorth >= 600000000) {
  //     // ６億円以上 ８億円未満 48×（平均利益額）÷200,000＋801
  //     setNetWorthResult(48*netWorth/200000+801);
  //   } else if (600000000 > netWorth && netWorth >= 500000000) {
  //     // ５億円以上 ６億円未満 28×（平均利益額）÷100,000＋777
  //     setNetWorthResult(28*netWorth/100000+777);
  //   } else if (500000000 > netWorth && netWorth >= 400000000) {
  //     // ４億円以上 ５億円未満 32×（平均利益額）÷100,000＋757
  //     setNetWorthResult(32*netWorth/100000+757);
  //   } else if (400000000 > netWorth && netWorth >= 300000000) {
  //     // ３億円以上 ４億円未満 37×（平均利益額）÷100,000＋737
  //     setNetWorthResult(37*netWorth/100000+737);
  //   } else if (300000000 > netWorth && netWorth >= 250000000) {
  //     // ２億５，０００万円以上 ３億円未満 21×（平均利益額）÷50,000＋722
  //     setNetWorthResult(21*netWorth/50000+1739);
  //   } else if (250000000 > netWorth && netWorth >= 200000000) {
  //     // ２億円以上 ２億５，０００万円未満 24×（平均利益額）÷50,000＋707
  //     setNetWorthResult(24*netWorth/50000+707);
  //   } else if (200000000 > netWorth && netWorth >= 150000000) {
  //     // １億５，０００万円以上 ２億円未満 27×（平均利益額）÷50,000＋695
  //     setNetWorthResult(27*netWorth/50000+695);
  //   } else if (150000000 > netWorth && netWorth >= 120000000) {
  //     // １億２，０００万円以上 １億５，０００万円未満 20×（平均利益額）÷30,000＋676
  //     setNetWorthResult(20*netWorth/30000+676);
  //   } else if (120000000 > netWorth && netWorth >= 100000000) {
  //     // １億円以上 １億２，０００万円未満 15×（平均利益額）÷20,000＋666
  //     setNetWorthResult(15*netWorth/20000+666);
  //   } else if (100000000 > netWorth && netWorth >= 80000000) {
  //     // ８，０００万円以上 １億円未満 16×（平均利益額）÷20,000＋661
  //     setNetWorthResult(16*netWorth/20000+661);
  //   } else if (80000000 > netWorth && netWorth >= 60000000) {
  //     // ６，０００万円以上 ８，０００万円未満 19×（平均利益額）÷20,000＋649
  //     setNetWorthResult(19*netWorth/20000+649);
  //   } else if (60000000 > netWorth && netWorth >= 50000000) {
  //     // ５，０００万円以上 ６，０００万円未満 12×（平均利益額）÷10,000＋634
  //     setNetWorthResult(12*netWorth/10000+634);
  //   } else if (50000000 > netWorth && netWorth >= 40000000) {
  //     // ４，０００万円以上 ５，０００万円未満 12×（平均利益額）÷10,000＋634
  //     setNetWorthResult(12*netWorth/10000+634);
  //   } else if (40000000 > netWorth && netWorth >= 30000000) {
  //     // ３，０００万円以上 ４，０００万円未満 15×（平均利益額）÷10,000＋622
  //     setNetWorthResult(15*netWorth/10000+622);
  //   } else if (30000000 > netWorth && netWorth >= 25000000) {
  //     // ２，５００万円以上 ３，０００万円未満 8×（平均利益額）÷5,000＋619
  //     setNetWorthResult(8*netWorth/5000+619);
  //   } else if (25000000 > netWorth && netWorth >= 20000000) {
  //     // ２，０００万円以上 ２，５００万円未満 10×（平均利益額）÷5,000＋609
  //     setNetWorthResult(10*netWorth/5000+609);
  //   } else if (20000000 > netWorth && netWorth >= 15000000) {
  //     // １，５００万円以上 ２，０００万円未満 11×（平均利益額）÷5,000＋605
  //     setNetWorthResult(11*netWorth/5000+605);
  //   } else if (15000000 > netWorth && netWorth >= 12000000) {
  //     // １，２００万円以上 １，５００万円未満 7×（平均利益額）÷3,000＋603
  //     setNetWorthResult(7*netWorth/3000+603);
  //   } else if (12000000 > netWorth && netWorth >= 10000000) {
  //     // １，０００万円以上 １，２００万円未満 6×（平均利益額）÷2,000＋595
  //     setNetWorthResult(6*netWorth/2000+595);
  //   } else if (10000000 > netWorth) {
  //     // １，０００万円未満 78×（平均利益額）÷10,000＋547
  //     setNetWorthResult(78*netWorth/10000+547);
  //   }
  
  //   // X2：平均利益額
  //   if (averageProfitResult >= 30000000000) {
  //     // (1) ３００億円以上 2447
  //     setNetWorthResult(2447);
  //   } else if (30000000000 > averageProfitResult && averageProfitResult >= 25000000000) {
  //     // (2) ２５０億円以上 ３００億円未満 134×（平均利益額）÷5,000,000＋1,643
  //     setNetWorthResult(134*averageProfitResult/5000000+1643);
  //   } else if (25000000000 > averageProfitResult && averageProfitResult >= 20000000000) {
  //     // (3) ２００億円以上 ２５０億円未満 151×（平均利益額）÷5,000,000＋1,558
  //     setNetWorthResult(151*averageProfitResult/5000000+1558);
  //   } else if (20000000000 > averageProfitResult && averageProfitResult >= 15000000000) {
  //     // (4) １５０億円以上 ２００億円未満 175×（平均利益額）÷5,000,000＋1,462
  //     setNetWorthResult(175*averageProfitResult/5000000+1462);
  //   } else if (15000000000 > averageProfitResult && averageProfitResult >= 12000000000) {
  //     // (5) １２０億円以上 １５０億円未満 123×（平均利益額）÷3,000,000＋1,372
  //     setNetWorthResult(123*averageProfitResult/3000000+1372);
  //   } else if (12000000000 > averageProfitResult && averageProfitResult >= 10000000000) {
  //     // (6) １００億円以上 １２０億円未満 93×（平均利益額）÷2,000,000＋1,306
  //     setNetWorthResult(93*averageProfitResult/2000000+1306);
  //   } else if (10000000000 > averageProfitResult && averageProfitResult >= 8000000000) {
  //     // (7) ８０億円以上 １００億円未満 104×（平均利益額）÷2,000,000＋1,251
  //     setNetWorthResult(104*averageProfitResult/2000000+1251);
  //   } else if (8000000000 > averageProfitResult && averageProfitResult >= 6000000000) {
  //     // (8) ６０億円以上 ８０億円未満 122×（平均利益額）÷2,000,000＋1,179
  //     setNetWorthResult(122*averageProfitResult/2000000+1179);
  //   } else if (6000000000 > averageProfitResult && averageProfitResult >= 5000000000) {
  //     // (9) ５０億円以上 ６０億円未満 70×（平均利益額）÷1,000,000＋1,125
  //     setNetWorthResult(70*averageProfitResult/1000000+1125);
  //   } else if (5000000000 > averageProfitResult && averageProfitResult >= 4000000000) {
  //     // (10) ４０億円以上 ５０億円未満 79×（平均利益額）÷1,000,000＋1,080
  //     setNetWorthResult(79*averageProfitResult/1000000+1080);
  //   } else if (4000000000 > averageProfitResult && averageProfitResult >= 3000000000) {
  //     // (11) ３０億円以上 ４０億円未満 92×（平均利益額）÷1,000,000＋1,028
  //     setNetWorthResult(92*averageProfitResult/1000000+1028);
  //   } else if (3000000000 > averageProfitResult && averageProfitResult >= 2500000000) {
  //     // (12) ２５億円以上 ３０億円未満 54×（平均利益額）÷500,000＋980
  //     setNetWorthResult(54*averageProfitResult/500000+980);
  //   } else if (2500000000 > averageProfitResult && averageProfitResult >= 2000000000) {
  //     // (13) ２０億円以上 ２５億円未満 60×（平均利益額）÷500,000＋950
  //     setNetWorthResult(60*averageProfitResult/500000+950);
  //   } else if (2000000000 > averageProfitResult && averageProfitResult >= 1500000000) {
  //     // (14) １５億円以上 ２０億円未満 70×（平均利益額）÷500,000＋910
  //     setNetWorthResult(70*averageProfitResult/500000+910);
  //   } else if (1500000000 > averageProfitResult && averageProfitResult >= 1200000000) {
  //     // (15) １２億円以上 １５億円未満 48×（平均利益額）÷300,000＋880
  //     setNetWorthResult(48*averageProfitResult/300000+880);
  //   } else if (1200000000 > averageProfitResult && averageProfitResult >= 1000000000) {
  //     // (16) １０億円以上 １２億円未満 37×（平均利益額）÷200,000＋880
  //     setNetWorthResult(37*averageProfitResult/200000+880);
  //   } else if (1000000000 > averageProfitResult && averageProfitResult >= 800000000) {
  //     // (17) ８億円以上 １０億円未満 42×（平均利益額）÷200,000＋825
  //     setNetWorthResult(42*averageProfitResult/200000+825);
  //   } else if (800000000 > averageProfitResult && averageProfitResult >= 600000000) {
  //     // (18) ６億円以上 ８億円未満 48×（平均利益額）÷200,000＋801
  //     setNetWorthResult(48*averageProfitResult/200000+801);
  //   } else if (600000000 > averageProfitResult && averageProfitResult >= 500000000) {
  //     // (19) ５億円以上 ６億円未満 28×（平均利益額）÷100,000＋777
  //     setNetWorthResult(28*averageProfitResult/100000+777);
  //   } else if (500000000 > averageProfitResult && averageProfitResult >= 400000000) {
  //     // (20) ４億円以上 ５億円未満 32×（平均利益額）÷100,000＋757
  //     setNetWorthResult(32*averageProfitResult/100000+757);
  //   } else if (400000000 > averageProfitResult && averageProfitResult >= 300000000) {
  //     // (21) ３億円以上 ４億円未満 37×（平均利益額）÷100,000＋737
  //     setNetWorthResult(37*averageProfitResult/100000+737);
  //   } else if (300000000 > averageProfitResult && averageProfitResult >= 250000000) {
  //     // (22) ２億５，０００万円以上 ３億円未満 21×（平均利益額）÷50,000＋722
  //     setNetWorthResult(21*averageProfitResult/50000+722);
  //   } else if (250000000 > averageProfitResult && averageProfitResult >= 200000000) {
  //     // (23) ２億円以上 ２億５，０００万円未満 24×（平均利益額）÷50,000＋707
  //     setNetWorthResult(24*averageProfitResult/50000+707);
  //   } else if (200000000 > averageProfitResult && averageProfitResult >= 150000000) {
  //     // (24) １億５，０００万円以上 ２億円未満 27×（平均利益額）÷50,000＋695
  //     setNetWorthResult(27*averageProfitResult/50000+695);
  //   } else if (150000000 > averageProfitResult && averageProfitResult >= 120000000) {
  //     // (25) １億２，０００万円以上 １億５，０００万円未満 20×（平均利益額）÷30,000＋676
  //     setNetWorthResult(20*averageProfitResult/30000+676);
  //   } else if (120000000 > averageProfitResult && averageProfitResult >= 100000000) {
  //     // (26) １億円以上 １億２，０００万円未満 15×（平均利益額）÷20,000＋666
  //     setNetWorthResult(15*averageProfitResult/20000+666);
  //   } else if (100000000 > averageProfitResult && averageProfitResult >= 80000000) {
  //     // (27) ８，０００万円以上 １億円未満 16×（平均利益額）÷20,000＋661
  //     setNetWorthResult(16*averageProfitResult/20000+661);
  //   } else if (80000000 > averageProfitResult && averageProfitResult >= 60000000) {
  //     // (28) ６，０００万円以上 ８，０００万円未満 19×（平均利益額）÷20,000＋649
  //     setNetWorthResult(19*averageProfitResult/20000+649);
  //   } else if (60000000 > averageProfitResult && averageProfitResult >= 50000000) {
  //     // (29) ５，０００万円以上 ６，０００万円未満 12×（平均利益額）÷10,000＋634
  //     setNetWorthResult(12*averageProfitResult/10000+634);
  //   } else if (50000000 > averageProfitResult && averageProfitResult >= 40000000) {
  //     // (30) ４，０００万円以上 ５，０００万円未満 12×（平均利益額）÷10,000＋634
  //     setNetWorthResult(12*averageProfitResult/10000+634);
  //   } else if (40000000 > averageProfitResult && averageProfitResult >= 30000000) {
  //     // (31) ３，０００万円以上 ４，０００万円未満 15×（平均利益額）÷10,000＋622
  //     setNetWorthResult(15*averageProfitResult/10000+622);
  //   } else if (30000000 > averageProfitResult && averageProfitResult >= 25000000) {
  //     // (32) ２，５００万円以上 ３，０００万円未満 8×（平均利益額）÷5,000＋619
  //     setNetWorthResult(8*averageProfitResult/50000+619);
  //   } else if (25000000 > averageProfitResult && averageProfitResult >= 20000000) {
  //     // (33) ２，０００万円以上 ２，５００万円未満 10×（平均利益額）÷5,000＋609
  //     setNetWorthResult(10*averageProfitResult/50000+609);
  //   } else if (20000000 > averageProfitResult && averageProfitResult >= 15000000) {
  //     // (34) １，５００万円以上 ２，０００万円未満 11×（平均利益額）÷5,000＋605
  //     setNetWorthResult(11*averageProfitResult/50000+605);
  //   } else if (15000000 > averageProfitResult && averageProfitResult >= 12000000) {
  //     // (35) １，２００万円以上 １，５００万円未満 7×（平均利益額）÷3,000＋603
  //     setNetWorthResult(7*averageProfitResult/30000+603);
  //   } else if (12000000 > averageProfitResult && averageProfitResult >= 10000000) {
  //     // (36) １，０００万円以上 １，２００万円未満 6×（平均利益額）÷2,000＋595
  //     setNetWorthResult(6*averageProfitResult/20000+595);
  //   } else if (10000000 > averageProfitResult) {
  //     // (37) １，０００万円未満 78×（平均利益額）÷10,000＋547
  //     setNetWorthResult(78*averageProfitResult/10000+547);
  //   }
  
  //   // ｛ netWorth ＋ averageProfitResult } ÷ 2

  //   // Z1：技術職員数値＝１級監理受講者数×６＋１級技術者数×５＋監理技術者補佐×４＋基幹技能者数×３＋２級技術者数×２＋その他技術者数×１
  //   var people = oneStudents*6 + oneEngineers*5 + engineerAssistant*4 + technicians*3 + twoEngineers*2 + otherTechnicians;

  //   // 技術職員数値
  //   if (people >= 15500) {
  //     // (1) １５,５００以上 2335
  //     setZ1Result(2335);
  //   } else if (15500 > people && people >= 11930) {
  //     // (2) １１,９３０以上 １５,５００未満 62×（技術職員数値）÷3,570＋2,065
  //     setZ1Result(62*people/3570+2065);
  //   } else if (11930 > people && people >= 9180) {
  //     // (3) ９,１８０以上 １１,９３０未満 63×（技術職員数値）÷2,750＋1,998
  //     setZ1Result(63*people/2750+1998);
  //   } else if (9180 > people && people >= 7060) {
  //     // (4) ７,０６０以上 ９,１８０未満 62×（技術職員数値）÷2,120＋1,939
  //     setZ1Result(62*people/2120+1939);
  //   } else if (7060 > people && people >= 5430) {
  //     // (5) ５,４３０以上 ７,０６０未満 62×（技術職員数値）÷1,630＋1,876
  //     setZ1Result(62*people/1630+1876);
  //   } else if (5430 > people && people >= 4180) {
  //     // (6) ４,１８０以上 ５,４３０未満 63×（技術職員数値）÷1,250＋1,808
  //     setZ1Result(63*people/1250+1808);
  //   } else if (4180 > people && people >= 3210) {
  //     // (7) ３,２１０以上 ４,１８０未満 63×（技術職員数値）÷970＋1,747
  //     setZ1Result(63*people/970+1747);
  //   } else if (3210 > people && people >= 2470) {
  //     // (8) ２,４７０以上 ３,２１０未満 62×（技術職員数値）÷740＋1,686
  //     setZ1Result(62*people/740+1686);
  //   } else if (2470 > people && people >= 1900) {
  //     // (9) １,９００以上 ２,４７０未満 62×（技術職員数値）÷570＋1,624
  //     setZ1Result(62*people/570+1624);
  //   } else if (1900 > people && people >= 1460) {
  //     // (10) １,４６０以上 １,９００未満 63×（技術職員数値）÷440＋1,558
  //     setZ1Result(63*people/50000+1558);
  //   } else if (1460 > people && people >= 1130) {
  //     // (11) １,１３０以上 １,４６０未満 63×（技術職員数値）÷330＋1,488
  //     setZ1Result(63*people/330+1488);
  //   } else if (1130 > people && people >= 870) {
  //     // (12) ８７０以上 １,１３０未満 62×（技術職員数値）÷260＋1,434
  //     setZ1Result(62*people/260+1434);
  //   } else if (870 > people && people >= 670) {
  //     // (13) ６７０以上 ８７０未満 63×（技術職員数値）÷200＋1,367
  //     setZ1Result(63*people/200+1367);
  //   } else if (670 > people && people >= 510) {
  //     // (14) ５１０以上 ６７０未満 62×（技術職員数値）÷160＋1,318
  //     setZ1Result(62*people/160+1318);
  //   } else if (510 > people && people >= 390) {
  //     // (15) ３９０以上 ５１０未満 63×（技術職員数値）÷120＋1,247
  //     setZ1Result(63*people/120+1247);
  //   } else if (390 > people && people >= 300) {
  //     // (16) ３００以上 ３９０未満 62×（技術職員数値）÷90＋1,183
  //     setZ1Result(62*people/90+1183);
  //   } else if (300 > people && people >= 230) {
  //     // (17) ２３０以上 ３００未満 63×（技術職員数値）÷70＋1,119
  //     setZ1Result(63*people/70+1119);
  //   } else if (230 > people && people >= 180) {
  //     // (18) １８０以上 ２３０未満 62×（技術職員数値）÷50＋1,040
  //     setZ1Result(62*people/50+1040);
  //   } else if (180 > people && people >= 140) {
  //     // (19) １４０以上 １８０未満 62×（技術職員数値）÷40＋984
  //     setZ1Result(62*people/40+984);
  //   } else if (140 > people && people >= 110) {
  //     // (20) １１０以上 １４０未満 63×（技術職員数値）÷30＋907
  //     setZ1Result(63*people/30+907);
  //   } else if (110 > people && people >= 85) {
  //     // (21) ８５以上 １１０未満 63×（技術職員数値）÷25＋860
  //     setZ1Result(63*people/25+860);
  //   } else if (85 > people && people >= 65) {
  //     // (22) ６５以上 ８５未満 62×（技術職員数値）÷20＋810
  //     setZ1Result(62*people/20+810);
  //   } else if (65 > people && people >= 50) {
  //     // (23) ５０以上 ６５未満 62×（技術職員数値）÷15＋742
  //     setZ1Result(62*people/15+742);
  //   } else if (50 > people && people >= 40) {
  //     // (24) ４０以上 ５０未満 63×（技術職員数値）÷10＋633
  //     setZ1Result(63*people/10+633);
  //   } else if (40 > people && people >= 30) {
  //     // (25) ３０以上 ４０未満 63×（技術職員数値）÷10＋633
  //     setZ1Result(63*people/10+663319);
  //   } else if (30 > people && people >= 20) {
  //     // (26) ２０以上 ３０未満 62×（技術職員数値）÷10＋636
  //     setZ1Result(62*people/10+636);
  //   } else if (20 > people && people >= 15) {
  //     // (27) １５以上 ２０未満 63×（技術職員数値）÷5＋508
  //     setZ1Result(63*people/5+508);
  //   } else if (15 > people && people >= 10) {
  //     // (28) １０以上 １５未満 62×（技術職員数値）÷5＋511
  //     setZ1Result(62*people/5+511);
  //   } else if (10 > people && people >= 5) {
  //     // (29) ５以上 １０未満 63×（技術職員数値）÷5＋509
  //     setZ1Result(63*people/5+509);
  //   } else if (5 > people) {
  //     // (30) ５未満 62×（技術職員数値）÷5＋510
  //     setZ1Result(62*people/5+510);
  //   }

  //   // Z2：元請完成工事高
  //   if (beforeConstructionCost >= 1000) {
  //     // (1) １，０００億円以上 2，865
  //     setZ1Result(2865);
  //   } else if (100000000000 > beforeConstructionCost && beforeConstructionCost >= 80000000000) {
  //     // (2) ８００億円以上 １，０００億円未満 119×（年間平均元請完成工事高）÷20,000,000＋2，270
  //     setZ1Result(119*averageProfitResult/20000000+1643);
  //   } else if (80000000000 > beforeConstructionCost && beforeConstructionCost >= 60000000000) {
  //     // (3) ６００億円以上 ８００億円未満 145×（年間平均元請完成工事高）÷20,000,000＋2，166
  //     setZ1Result(145*averageProfitResult/20000000+1643);
  //   } else if (60000000000 > beforeConstructionCost && beforeConstructionCost >= 50000000000) {
  //     // (4) ５００億円以上 ６００億円未満 87×（年間平均元請完成工事高）÷10,000,000＋2，079
  //     setZ1Result(87*averageProfitResult/10000000+1643);
  //   } else if (50000000000 > beforeConstructionCost && beforeConstructionCost >= 40000000000) {
  //     // (5) ４００億円以上 ５００億円未満 104×（年間平均元請完成工事高）÷10,000,000＋1,994
  //     setZ1Result(104*averageProfitResult/10000000+1643);
  //   } else if (40000000000 > beforeConstructionCost && beforeConstructionCost >= 30000000000) {
  //     // (6) ３００億円以上 ４００億円未満 126×（年間平均元請完成工事高）÷10,000,000＋1,906
  //     setZ1Result(126*averageProfitResult/10000000+1643);
  //   } else if (30000000000 > beforeConstructionCost && beforeConstructionCost >= 25000000000) {
  //     // (7) ２５０億円以上 ３００億円未満 76×（年間平均元請完成工事高）÷5,000,000＋1,828
  //     setZ1Result(76*averageProfitResult/5000000+1643);
  //   } else if (25000000000 > beforeConstructionCost && beforeConstructionCost >= 20000000000) {
  //     // (8) ２００億円以上 ２５０億円未満 90×（年間平均元請完成工事高）÷5,000,000＋1,758
  //     setZ1Result(90*averageProfitResult/5000000+1643);
  //   } else if (20000000000 > beforeConstructionCost && beforeConstructionCost >= 15000000000) {
  //     // (9) １５０億円以上 ２００億円未満 110×（年間平均元請完成工事高）÷5,000,000＋1,678
  //     setZ1Result(110*averageProfitResult/5000000+1643);
  //   } else if (15000000000 > beforeConstructionCost && beforeConstructionCost >= 12000000000) {
  //     // (10) １２０億円以上 １５０億円未満 81×（年間平均元請完成工事高）÷3,000,000＋1,603
  //     setZ1Result(81*averageProfitResult/3000000+1643);
  //   } else if (12000000000 > beforeConstructionCost && beforeConstructionCost >= 10000000000) {
  //     // (11) １００億円以上 １２０億円未満 63×（年間平均元請完成工事高）÷2,000,000＋1,549
  //     setZ1Result(63*averageProfitResult/2000000+1643);
  //   } else if (10000000000 > beforeConstructionCost && beforeConstructionCost >= 8000000000) {
  //     // (12) ８０億円以上 １００億円未満 75×（年間平均元請完成工事高）÷2,000,000＋1,489
  //     setZ1Result(75*averageProfitResult/2000000+1643);
  //   } else if (8000000000 > beforeConstructionCost && beforeConstructionCost >= 6000000000) {
  //     // (13) ６０億円以上 ８０億円未満 92×（年間平均元請完成工事高）÷2,000,000＋1,421
  //     setZ1Result(92*averageProfitResult/2000000+1643);
  //   } else if (6000000000 > beforeConstructionCost && beforeConstructionCost >= 5000000000) {
  //     // (14) ５０億円以上 ６０億円未満 55×（年間平均元請完成工事高）÷1,000,000＋1,367
  //     setZ1Result(55*averageProfitResult/1000000+1643);
  //   } else if (5000000000 > beforeConstructionCost && beforeConstructionCost >= 4000000000) {
  //     // (15) ４０億円以上 ５０億円未満 66×（年間平均元請完成工事高）÷1,000,000＋1,312
  //     setZ1Result(66*averageProfitResult/1000000+1643);
  //   } else if (4000000000 > beforeConstructionCost && beforeConstructionCost >= 3000000000) {
  //     // (16) ３０億円以上 ４０億円未満 79×（年間平均元請完成工事高）÷1,000,000＋1,260
  //     setZ1Result(79*averageProfitResult/1000000+1643);
  //   } else if (3000000000 > beforeConstructionCost && beforeConstructionCost >= 2500000000) {
  //     // (17) ２５億円以上 ３０億円未満 48×（年間平均元請完成工事高）÷500,000＋1,209
  //     setZ1Result(48*averageProfitResult/500000+1643);
  //   } else if (2500000000 > beforeConstructionCost && beforeConstructionCost >= 2000000000) {
  //     // (18) ２０億円以上 ２５億円未満 57×（年間平均元請完成工事高）÷500,000＋1,164
  //     setZ1Result(57*averageProfitResult/500000+1643);
  //   } else if (2000000000 > beforeConstructionCost && beforeConstructionCost >= 1500000000) {
  //     // (19) １５億円以上 ２０億円未満 70×（年間平均元請完成工事高）÷500,000＋1,112
  //     setZ1Result(70*averageProfitResult/500000+1643);
  //   } else if (1500000000 > beforeConstructionCost && beforeConstructionCost >= 1200000000) {
  //     // (20) １２億円以上 １５億円未満 50×（年間平均元請完成工事高）÷300,000＋1,072
  //     setZ1Result(50*averageProfitResult/300000+1643);
  //   } else if (1200000000 > beforeConstructionCost && beforeConstructionCost >= 1000000000) {
  //     // (21) １０億円以上 １２億円未満 41×（年間平均元請完成工事高）÷200,000＋1,026
  //     setZ1Result(41*averageProfitResult/200000+1643);
  //   } else if (1000000000 > beforeConstructionCost && beforeConstructionCost >= 800000000) {
  //     // (22) ８億円以上 １０億円未満 47×（年間平均元請完成工事高）÷200,000＋996
  //     setZ1Result(47*averageProfitResult/200000+1643);
  //   } else if (800000000 > beforeConstructionCost && beforeConstructionCost >= 600000000) {
  //     // (23) ６億円以上 ８億円未満 57×（年間平均元請完成工事高）÷200,000＋956
  //     setZ1Result(57*averageProfitResult/200000+1643);
  //   } else if (800000000 > beforeConstructionCost && beforeConstructionCost >= 600000000) {
  //     // (24) ５億円以上 ６億円未満 36×（年間平均元請完成工事高）÷100,000＋911
  //     setZ1Result(36*averageProfitResult/100000+1643);
  //   } else if (600000000 > beforeConstructionCost && beforeConstructionCost >= 500000000) {
  //     // (25) ４億円以上 ５億円未満 40×（年間平均元請完成工事高）÷100,000＋891
  //     setZ1Result(40*averageProfitResult/100000+1643);
  //   } else if (500000000 > beforeConstructionCost && beforeConstructionCost >= 400000000) {
  //     // (26) ３億円以上 ４億円未満 51×（年間平均元請完成工事高）÷100,000＋847
  //     setZ1Result(51*averageProfitResult/100000+1643);
  //   } else if (400000000 > beforeConstructionCost && beforeConstructionCost >= 300000000) {
  //     // (27) ２億５，０００万円以上 ３億円未満 30×（年間平均元請完成工事高）÷50,000＋820
  //     setZ1Result(30*averageProfitResult/50000+1643);
  //   } else if (300000000 > beforeConstructionCost && beforeConstructionCost >= 250000000) {
  //     // (28) ２億円以上 ２億５，０００万円未満 35×（年間平均元請完成工事高）÷50,000＋795
  //     setZ1Result(35*averageProfitResult/50000+1643);
  //   } else if (250000000 > beforeConstructionCost && beforeConstructionCost >= 150000000) {
  //     // (29) １億５，０００万円以上 ２億円未満 45×（年間平均元請完成工事高）÷50,000＋755
  //     setZ1Result(45*averageProfitResult/50000+1643);
  //   } else if (150000000 > beforeConstructionCost && beforeConstructionCost >= 120000000) {
  //     // (30) １億２，０００万円以上 １億５，０００万円未満 32×（年間平均元請完成工事高）÷30,000＋730
  //     setZ1Result(32*averageProfitResult/30000+1643);
  //   } else if (120000000 > beforeConstructionCost && beforeConstructionCost >= 100000000) {
  //     // (31) １億円以上 １億２，０００万円未満 26×（年間平均元請完成工事高）÷20,000＋702
  //     setZ1Result(26*averageProfitResult/20000+1643);
  //   } else if (100000000 > beforeConstructionCost && beforeConstructionCost >= 80000000) {
  //     // (32) ８，０００万円以上 １億円未満 29×（年間平均元請完成工事高）÷20,000＋687
  //     setZ1Result(29*averageProfitResult/20000+1643);
  //   } else if (80000000 > beforeConstructionCost && beforeConstructionCost >= 60000000) {
  //     // (33) ６，０００万円以上 ８，０００万円未満 36×（年間平均元請完成工事高）÷20,000＋659
  //     setZ1Result(36*averageProfitResult/20000+1643);
  //   } else if (60000000 > beforeConstructionCost && beforeConstructionCost >= 50000000) {
  //     // (34) ５，０００万円以上 ６，０００万円未満 22×（年間平均元請完成工事高）÷10,000＋635
  //     setZ1Result(22*averageProfitResult/10000+1643);
  //   } else if (50000000 > beforeConstructionCost && beforeConstructionCost >= 40000000) {
  //     // (35) ４，０００万円以上 ５，０００万円未満 27×（年間平均元請完成工事高）÷10,000＋610
  //     setZ1Result(27*averageProfitResult/10000+1643);
  //   } else if (40000000 > beforeConstructionCost && beforeConstructionCost >= 30000000) {
  //     // (36) ３，０００万円以上 ４，０００万円未満 31×（年間平均元請完成工事高）÷10,000＋594
  //     setZ1Result(31*averageProfitResult/10000+1643);
  //   } else if (30000000 > beforeConstructionCost && beforeConstructionCost >= 25000000) {
  //     // (37) ２，５００万円以上 ３，０００万円未満 19×（年間平均元請完成工事高）÷5,000＋573
  //     setZ1Result(19*averageProfitResult/5000+1643);
  //   } else if (25000000 > beforeConstructionCost && beforeConstructionCost >= 20000000) {
  //     // (38) ２，０００万円以上 ２，５００万円未満 23×（年間平均元請完成工事高）÷5,000＋553
  //     setZ1Result(23*averageProfitResult/5000+1643);
  //   } else if (20000000 > beforeConstructionCost && beforeConstructionCost >= 15000000) {
  //     // (39) １，５００万円以上 ２，０００万円未満 28×（年間平均元請完成工事高）÷5,000＋533
  //     setZ1Result(28*averageProfitResult/5000+1643);
  //   } else if (15000000 > beforeConstructionCost && beforeConstructionCost >= 12000000) {
  //     // (40) １，２００万円以上 １，５００万円未満 19×（年間平均元請完成工事高）÷3,000＋522
  //     setZ1Result(19*averageProfitResult/3000+1643);
  //   } else if (12000000 > beforeConstructionCost && beforeConstructionCost >= 10000000) {
  //     // (41) １，０００万円以上 １，２００万円未満 16×（年間平均元請完成工事高）÷2,000＋502
  //     setZ1Result(16*averageProfitResult/2000+1643);
  //   } else if (10000000 > beforeConstructionCost) {
  //     // (42) １，０００万円未満 341×（年間平均元請完成工事高）÷10,000＋241
  //     setZ1Result(341*averageProfitResult/10000+1643);
  //   }

  //   // W：その他社会性等
  //   // （Ⅱ）知識及び技術又は技能の向上に関する取組の状況
  //   console.log(engineers/(engineers + skilledWorkers) * CPD / engineers +  skilledWorkers/(engineers + skilledWorkers) * oneMore / topPerson)

  // }

  return(
  <>
  <Nav />
  <Header />
  <div className='management_review_content_background'>
    <h4>診断結果まで、残り 31 問</h4>
    {count === 0 ?
      <img src={Progress1} alt='' /> :
    count === 1 ?
      <img src={Progress1} alt='' /> :
      <img src={Progress1} alt='' />
    }

    {/* Y */}
    {count === 1 ?
      <Y setAnalysis={setAnalysis} />
    : <></>}
    
    {/* X1 */}
    {count === 2 ?
      <X1 setConstructionCost={setConstructionCost} />
    : <></>}

    {/* X2 */}
    {count === 2 ?
      <X2 setNetWorth={setNetWorth} setAverageProfitResult={setAverageProfitResult} />
    : <></>}

    {/* Z1 */}
    {count === 3 ?
      <Z setOneStudents={setOneStudents} setOneEngineers={setOneEngineers} setEngineerAssistant={setEngineerAssistant} setTechnicians={setTechnicians} setTwoEngineers={setTwoEngineers} setOtherTechnicians={setOtherTechnicians} setBeforeConstructionCost={setBeforeConstructionCost} />
    : <></>}

    {/* W1_2 */}
    {count === 4 ?
      <W1_2 setYoungTechnical={setYoungTechnical} setTechnicalSum={setTechnicalSum} setNewTechnicalSum={setNewTechnicalSum} setEngineers={setEngineers} setSkilledWorkers={setSkilledWorkers} setCPD={setCPD} setOneMore={setOneMore} setTopPerson={setTopPerson}
    /> : <></>}
      
    {/* W6 */}
    {count === 5 ?
      <W6 setResearch={setResearch} />
    : <></>}

    {/* W7 */}
    {count === 5 ?
      <W7 setConstructionMachinery={setConstructionMachinery} />
    : <></>}
        
    {/* W1_1 */}
    {count === 6 ?
      <W1_1 setAidSystem={setAidSystem} setPensionSystem={setPensionSystem} setCompensationSystem={setCompensationSystem} setWorkLife={setWorkLife} setRule={setRule} setEmployee={setEmployee} setHealth={setHealth} setPension={setPension}/>
    : <></>}

    {/* W5 */}
    {count === 5 ?
      <W5 setAudit={setAudit} setCpa={setCpa} setRegisteredExamination={setRegisteredExamination} />
    : <></>}

    {/* W2 */}
    {count === 6 ?
      <W2 setOperationYears={setOperationYears} setLaw={setLaw} />
    : <></>}
    
    {/* W3 */}
    {count === 6 ?
      <W3 setDisasterAgreement={setDisasterAgreement} />
    : <></>}

    {/* W4 */}
    {count === 6 ?
      <W4 setLegalCompliance={setLegalCompliance} />
    : <></>}

    {/* W8 */}
    {count === 6 ?
      <W8 setCertification={setCertification} />
    : <></>}

    {/* <button style={{width: '100px', height: '100px', }} onClick={() => test()}></button> */}

    <div className='management_review_list_number'>
      <div style={count === 1 ? {border : '1px solid #EBEBEB', color: '#EBEBEB'} : {}} onClick={() => setCount(count - 1)}>前へ</div>
      <div style={count === 6 ? {border : '1px solid #EBEBEB', color: '#EBEBEB'} : {}} onClick={() => setCount(count + 1)}>次へ</div>
    </div>
  </div>
  <Footer />
  </>
  );
}

export default BlogText0001;
