import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from './../../nav/index';
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/manufacturing/4/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'react-router-dom';

const BlogText0001 = () => {

  const meta = {
    title: '【初めての補助金】ものづくり補助金の採択率を徹底解説',
    description: '本記事は、ものづくり補助金を申請したいけど、「対象事業者に含まれているかわからない！」という方へ向けて、ものづくり補助金の対象者の条件について解説させていただきます。本サイトでは、初めての方でも理解ができるようわかりやすくまとめさせていただきましたので、ぜひご参考にいただけますと大変助かります。加えて、本記事は公募要領を用いて説明させていただいておりますので、本記事を理解していただいた方は実際に公募要領をご一読いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2022/07/21</p>
          <h1 className='media_title'>【初めての補助金】<br />ものづくり補助金の採択率を徹底解説</h1>
          <img src={TopImage} className='media_img' alt='補助金' />

          <p className='media_text_text'>
            <br />
            <br />
            <span className='media_text_text_box_title'>こんな方へ向けて</span><br />
            <br />
            本記事は、ものづくり補助金を申請したいけど、「対象事業者に含まれているかわからない！」という方へ向けて、ものづくり補助金の対象者の条件について解説させていただきます。<br />
            <br />
            本サイトでは、初めての方でも理解ができるようわかりやすくまとめさせていただきましたので、ぜひご参考にいただけますと大変助かります。<br />
            <br />
            加えて、本記事は公募要領を用いて説明させていただいておりますので、本記事を理解していただいた方は実際に公募要領をご一読いただければと思います。<br />
            <br />
            それでは、解説に進ませていただきます！<br />
          </p>

          <div className='media_contents'>
            <span>本記事の構成</span><br /><br />
            <ol>
              <li><AnchorLink href='#1' offset='100'>対象事業者の概要</AnchorLink></li>
              <li><AnchorLink href='#2' offset='100'>ものづくり補助金の対象事業者</AnchorLink></li>
              <li><AnchorLink href='#3' offset='100'>類型の概要</AnchorLink></li>
              <li><AnchorLink href='#4' offset='100'>類型の詳細及び補助率</AnchorLink></li>
              <li><AnchorLink href='#5' offset='100'>ものづくり補助金の類型要件</AnchorLink></li>
              <li><AnchorLink href='#6' offset='100'>まとめ</AnchorLink></li>
            </ol>
          </div>
          
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">類型の確認</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">通常枠</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">回復型賃上げ・雇用拡大枠</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">デジタル枠</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">グリーン枠</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">グローバル展開型</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">【一般型】</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">グローバル展開型</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">ものづくり補助金の採択率</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>

          <>
          <div id='1' className='media_text_title'>
            <h2>１.類型の確認</h2>
          </div>
          <p className='media_text_text'>
            <br />
            <br />
            対象事業者の確認が終わったところで、次は実際どの類型（枠）で申請するかを検討します。<br />
            <br />
            と、その前に類型の説明になりますが、簡単にいうと<strong>補助金を細分化し適正な要件を課すもの</strong>だと思ってください。<br />
            <br />
            具体的には、こういう事業者さまはこの枠、これを目的にしている事業者さまはこの枠という形で分けられており、そこで選択された類型によって要件、補助率、上限金額が細かく提示されています。<br />
            <br />
            そのため、申請するに当たって、どの枠なのか、どの要件を満たす必要があるかなどを判断するために理解しなくてはいけない要素となっております。<br />
            <br />
            本補助金の類型は、<strong>「通常枠」</strong>、<strong>「回復型賃上げ・雇用拡大枠」</strong>、<strong>「デジタル枠」</strong>、<strong>「グリーン枠」</strong>、<strong>「グローバル展開型」</strong>の５つございます。<br />
            <br />
            ここでは、類型の概要を説明させていただきます。<br />
            <br />
            上記で触れさせていただきました類型ごとの詳しい要件、補助率、上限金額については、以下の記事にて解説をしておりますので、ご確認いただけばと思います。<br />
            <br />
            <Link to='/media/1002'>
              <span className='media_text_text_link'>=＞【徹底解説】類型の要件と補助率に関する詳細</span>
            </Link>
            <br />
            <br />
            <br />
          </p>
          <h3 className='media_text_text_title'>通常枠</h3><br />
          <p className='media_text_text'>
            革新的な製品・サービス開発又は生産プロセス・サービス提供方法の改善に必要な設備・システム投資等を支援<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>回復型賃上げ・雇用拡大枠</h3><br />
          <p className='media_text_text'>
            業況が厳しいながら賃上げ・雇用拡大に取り組む事業者（※）が行う、革新的な製品・サービス開発又は生産プロセス・サービス提供方法の改善に必要な設備・システム投資等を支援<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>デジタル枠</h3><br />
          <p className='media_text_text'>
            DX（デジタルトランスフォーメーション）に資する革新的な製品・サービス開発又はデジタル技術を活用した生産プロセス・サービス提供方法の改善による生産性向上に必要な設備・システム投資等を支援<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>グリーン枠</h3><br />
          <p className='media_text_text'>
            温室効果ガスの排出削減に資する革新的な製品・サービス開発又は炭素生産性向上を伴う生産プロセス・サービス提供方法の改善による生産性向上に必要な設備・システム投資等を支援<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>グローバル展開型</h3><br />
          <p className='media_text_text'>
            海外事業の拡大・強化等を目的とした「革新的な製品・サービス開発」又は「生産プロセス・サービス提供方法の改善」に必要な設備・システム投資等を支援（①海外直接投資、②海外市場開拓、③インバウンド市場開拓、④海外事業者との共同事業のいずれかに合致するもの）<br />
          </p>
          </>
          
          <>
          <div id='2' className='media_text_title'>
            <h2>２.ものづくり補助金の採択率</h2>
          </div>
          <p className='media_text_text'>
            <br />
            <br />
            ものづくり補助金の採択率は、比較的最近高くなっております。<br />
            本記事では、【一般型】【低リスク感染型ビジネス枠】の２つを紹介させていただきます。<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>【一般型】</h3><br /> 
          <p className='media_text_text'>
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>項目</th><th>申請件数</th><th>採択件数</th><th>採択率</th>
              </tr>
              <tr>
                <td><strong>第１次</strong></td>
                <td>2,287</td>
                <td>1,429</td>
                <td>62.48％</td>
              </tr>
              <tr>
                <td><strong>第２次</strong></td>
                <td>5,721</td>
                <td>3,267</td>
                <td>57.11％</td>
              </tr>
              <tr>
                <td><strong>第３次</strong></td>
                <td>6,923</td>
                <td>2,637</td>
                <td>38.09％</td>
              </tr>
              <tr>
                <td><strong>第４次</strong></td>
                <td>10,041</td>
                <td>3,132</td>
                <td>31.19％</td>
              </tr>
              <tr>
                <td><strong>第５次</strong></td>
                <td>5,139</td>
                <td>2,291</td>
                <td>44.58％</td>
              </tr>
              <tr>
                <td><strong>第６次</strong></td>
                <td>4,875</td>
                <td>2,326</td>
                <td>47.71％</td>
              </tr>
              <tr>
                <td><strong>第７次</strong></td>
                <td>5,414</td>
                <td>2,729</td>
                <td>50.41％</td>
              </tr>
              <tr>
                <td><strong>第７次</strong></td>
                <td>4,584</td>
                <td>2,753</td>
                <td>60.06％</td>
              </tr>
              <tr>
                <td><strong>第７次</strong></td>
                <td>3,552</td>
                <td>2,223</td>
                <td>62.58％</td>
              </tr>
              <tr>
                <td><strong>第７次</strong></td>
                <td>4,584</td>
                <td>2,753</td>
                <td>60.06％</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <h3 className='media_text_text_title'>グローバル展開型</h3><br /> 
          <p className='media_text_text'>
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>項目</th><th>申請件数</th><th>採択件数</th><th>採択率</th>
              </tr>
              <tr>
                <td><strong>第１次</strong></td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td><strong>第２次</strong></td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td><strong>第３次</strong></td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td><strong>第４次</strong></td>
                <td>271</td>
                <td>46</td>
                <td>17.0%</td>
              </tr>
              <tr>
                <td><strong>第５次</strong></td>
                <td>160</td>
                <td>46</td>
                <td>28.3%</td>
              </tr>
              <tr>
                <td><strong>第６次</strong></td>
                <td>105</td>
                <td>36</td>
                <td>34.3%</td>
              </tr>
              <tr>
                <td><strong>第７次</strong></td>
                <td>93</td>
                <td>39</td>
                <td>41.9%</td>
              </tr>
              <tr>
                <td><strong>第８次</strong></td>
                <td>69</td>
                <td>27</td>
                <td>39.1%</td>
              </tr>
            </tbody>
          </table>
          </>

          <>
          <div id='3' className='media_text_title'>
            <h2>３.まとめ</h2>
          </div>
          <p className='media_text_text'>
            <br />
            <br />
            本記事では、ものづくり補助金を申請したい事業者さまへ向けて、ものづくり補助金の概要について記事にさせていただきました。<br />
            <br />
            公募要領を各所に添付させていただきましたが、ご覧いただけば分かる通り難しい内容となっております。<br />
            そこで、もし、本格的にものづくり補助金の導入を検討されていましたら、弊社含め補助金に詳しい事業者へご相談ください。<br />
            <br />
            ご相談は、画面右上の補助金に関する相談をするから問い合わせにて、お待ちしております。<br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
        
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


