import './styles.css';
import Box1 from './box1/index';
import Box2 from './box2/index';

const Help = () => {
  
  return (
    <>
    <div className='AdBusiness_what_box'>
      <Box1 />
      <div className='AdBusiness_what_bottom'>
        <Box2 />
      </div>
    </div>
    </>
  );
}

export default Help;
