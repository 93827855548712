import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/10/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」大規模賃金引上要件は、1年で45円引き上げる！',
    description: '本記事では、賃金引上要件の類型を詳しく解説しております。その他、具体例や判断に必要な考え方についても説明しております。本記事は、「大規模賃金引上枠への申請を考えている」方や「賃金引上要件を詳しく知りたい」という方に向けて、執筆させていただきました。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」<br />賃金引上要件は、1年で45円引き上げる！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は、<strong>日本最大級の補助金である「事業再構築補助金」</strong>について解説させていただきます。<br />
            <br />
            本記事は、「<strong>大規模賃金引上枠への申請を考えている</strong>」方や「<strong>賃金引上要件を詳しく知りたい</strong>」という方に向けて、執筆させていただきました。<br />
            <br />
            事業再構築補助金の賃金引上要件をチェックしたい方はぜひご覧ください！<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>本記事でこんな悩みが解決！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>賃金引上要件を満たす方法</span></li>
              <li><span>認定支援機関についての概要</span></li>
              <li><span>認定支援機関の選び方、よくある質問</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、補助金の説明書である「公募要領」を参考にして作成されたものとなっていますので、本記事の内容を理解したうえで公募要領部を読んでみてください！<br />
            <br />
            なお、<strong>画面右側のお問い合わせから質問等をお受けしております</strong>ので、申請サポートのご依頼や不明点のご相談はお気軽にご連絡ください。<br />
            <br />
            それでは、解説に進ませていただきます！<br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">賃金引上要件とは</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">要件の対象者</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">賃金引上要件を満たす方法</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">賃金引上要件のポイント</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">事業内最低賃金の定義</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">補助金額を返還する可能性がある</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#4" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>１.賃金引上要件とは</h2>
          </div>
          <p className="media_text_text">
            <br />
            賃金引上要件とは、<strong>補助事業実施期間の終了年度から、3～5年の事業計画期間終了まで、事業内の最低賃金を45円以上引き上げる</strong>という要件です。<br />
            <br />
            少し理解が難しい要件となっておりますので、本記事でしっかりと確認しましょう！<br />
          </p>
          <h3 className='media_text_text_title'>要件の対象者</h3><br />
          <p className="media_text_text">
            売上高減少要件を満たす必要があるのは、「大規模賃金引上枠」の申請を考えている事業者さまのみとなります。<br />
            <br />
            大規模賃金引上枠の対象者や他要件は、別記事で記載しておりますので、下記よりご確認ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0017' target="_blank" rel="nofollow noopener noreferrer">
              詳細記事：「事業再構築補助金」大規模賃金引上枠は、５つの要件を確認！
            </a>
          </p>
          <h3 className='media_text_text_title'>賃金引上要件を満たす方法</h3><br />
          <p className="media_text_text">
            賃金引上要件は、公募要領では下記の様に定義されています。<br />
            <br />
            <strong>【公募要領】</strong><br />
            補助事業実施期間の終了時点を含む事業年度から 3～5 年の事業計画期間終了までの間、事業場内最低賃金を年額 45 円以上の水準で引き上げること<br />
            <br />
            文章だと少し難しいため、下記に簡易的なフロー図を記載いたしました。<br />
            下図の赤くなっている期間内に、事業内最低賃金を45円以上引き上げることが要件となっています。<br />
            <br />
            なお、事業内最低賃金とは最低賃金と異なるため、現時点で最低賃金+45円以上の給与を支払っていても、そこからまた45円以上引き上げる必要があります。<br />
            <br />
            <strong>【提出書類】</strong><br />
            賃上げ表明書<br />
            <br />
            大規模賃金要件を満たすための書類として、上記の賃上げ表明書を提出する必要があります。こちらの書類を記載したうえで、従業員・給与担当者の押印を行ってください。<br />
            <br />
            なお、賃上げ表明書は以下のURLよりダウンロードいただき、ご使用ください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              詳細記事：賃上げ表明書のダウンロード
            </a>
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>賃金引上要件とは</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>補助事業実施期間の終了年度～事業計画終了まで、事業内最低賃金を45円増額する。</span></li>
              <li><span>上記の証明として、「賃上げ表明書」を提出する必要がある。</span></li>
            </ul>
          </div>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>２.賃金引上要件のポイント</h2>
          </div>
          <p className="media_text_text">
            <br />
            こちらでは、<strong>上記では伝えられなかった賃金引上要件のポイントを説明します</strong>。<br />
            <br />
            最低賃金の金額にも関わってくる部分ですので、しっかりと読み込み、交付申請以降のトラブルを抑えましょう！<br />
          </p>
          <h3 className='media_text_text_title'>事業内最低賃金の定義</h3><br />
          <p className="media_text_text">
            公募要領によると、事業内最低賃金の定義は以下になっています。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>事業再構築補助金の目的</span><br />
            補助事業実施期間の終了時点を含む事業年度の前年度の終了月の事業場内最低賃金を基準とします。ただし、当該事業場内最低賃金が、申請時点の事業場内最低賃金を下回る場合には、申請時点の事業場内最低賃金を基準とします。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            上記の記載をまとめると、最低賃金の基準は以下2つの内、金額が高い方となっております。<br />
            <br />
            ・<strong>申請時点の事業内最低賃金</strong><br />
            ・<strong>補助事業を終了した年度の前期決算月における事業内最低賃金</strong><br />
            <br />
            金額が高い方が事業内最低賃金として認められるため、申請後に事業内最低賃金を引き下げた事業者様はご注意ください！<br />
          </p>
          <h3 className='media_text_text_title'>補助金額を返還する可能性がある</h3><br />
          <p className="media_text_text">
            賃金引上要件は、将来的に一定の要件を満たすことを約束して申請するものとなっています。<br />
            そのため、賃金引上要件は以下の場合に補助金の返還を求められます。<br />
            公募要領を参考にしながら、確認していきましょう。<br />
            <br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>事業再構築補助金の目的</span><br />
            ・申請時点で、申請要件を満たす賃金引上げ計画を従業員等に表明することが必要です。交付後に表明していないことが発覚した場合は、補助金額の返還を求めます。<br />
            ・予見できない大きな事業環境の変化に直面するなどの正当な理由なく、事業計画期間終了時点において、事業場内最低賃金を年額４５円以上の水準で引き上げることが出来なかった場合、通常枠の従業員規模毎の補助上限額との差額分について補助金を返還する必要があります。
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            上記をまとめると、以下2点の場合に補助金額を返還する必要があります。<br />
            <br />
            ・<strong>賃金引上計画を従業員に表明していなかった場合</strong><br />
            →補助金額全額の返還<br />
            <br />
            ・<strong>補助事業実施期間から3~5年の事業計画終了時点で、正当な理由なく年額45円以上の事業内最低賃金引上を実施できなかった場合</strong><br />
            →通常枠で申請した際の、補助金額との差分<br />
            <br />
            賃金引上要件は補助金額が大きい分、非常に難易度が高い要件となっておりますので、申請する際はしっかりと確認したうえ、申請を進めていただければと思います！<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>３.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、<strong>賃金引上要件について解説をさせていただきました</strong>。<br />
            <br />
            やや複雑なものとはなっておりますが、少しずつ進めていくと意外と簡単なものですので、諦めずに申請まで頑張ってください。<br />
            <br />
            <strong>ご不明点、疑問点につきましては、右上の問い合わせからお待ちしております</strong>ので、ご気軽にご連絡いただければと思います。<br />
            <br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


