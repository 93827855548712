import DocumentMeta from 'react-document-meta';
import './styles.css';
import Nav from "./../../home/nav/index";
import TopImage from "./../../../image/company/0003.png";
import Footer from './../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: 'インターンでは、どんな仕事をしてる？ vol.3 | トランク合同会社',
    description: 'この記事ではトランク合同会社にインターン生として入社した方の感想や今後入社する予定のインターン生に向けたメッセージをインタビュー形式で紹介しています。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />

      <div className='media_company'>
        <div className='media_company_article'>
          <p className='media_company_date'>更新日: 2023/10/24</p>
          <h1 className="media_company_title">インターン生へのインタビュー記事 vol.3 | 多賀 薫平</h1>
          <img src={TopImage} className="media_company_title_img" alt='ものづくり補助金' />

          <p className="media_company_text_text">
            <br />
            社会に出て経験をたくさん積みたいインターン生を募集していますので、興味のある方、話を聞きたい方は、右上の問い合わせより、ご連絡ください。<br />
          </p>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>こんな事が書いてあります！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>トランク合同会社で出来ること</span></li>
              <li><span>どんなやりがいがある？</span></li>
              <li><span>インターン生同士の交流</span></li>
            </ul>
          </div>
          </>

          <p className="media_company_text_text">
            この記事では、弊社で勤務しているインターン生がどんな業務を行っているのかをインタビュー形式で紹介しています！<br />
          </p>
          
          <>
          <div id="1" className="media_company_text_title">
            <h2>１.自己紹介</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            初めまして、インターン生の多賀薫平です！<br />
            <br />
            2024卒に卒業予定であり、就職先は豊島株式会社です。現在は法政大学 体育会に所属しています。<br />
            <br />
            6月末からトランク合同会社にてインターン生として勤務しています。<br />
          </p>
          </>
      
          <>
          <div id="2" className="media_company_text_title">
            <h2>２.トランク合同会社ってどんか会社？</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            トランク合同会社は、中小企業の課題を発見し解決に至るまでの広い範囲の業務をサポートする会社です。<br />
            <br />
            主に取り組んでいるサービスは、２つあります。<br />
            １つ目が、補助金申請サポート事業です。補助金の申請を検討されている中小企業等の事業者さまへ向けて、補助金選定～補助金振込まで一括サポートをしています。<br />
            <br />
            ２つ目は、ウェブサイト制作事業です。中小企業の事業者に対して、オリジナル政策のウェブサイト制作・運用を行っています。<br />
          </p>
          </>
         
          <>
          <div id="3" className="media_company_text_title">
            <h2>３.入社に至った経緯は？</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            就職活動を終えたタイミングで「このまま社会人になるのはまずい」と思い、長期インターンを探していました。<br />
            <br />
            その時に、インターン生リーダーのに紹介されたことがきっかけに入社しました。社員の人柄やメリハリのある社風が良いという話を聞いていたので、その点が魅力的だと思い入社を決断しました。<br />
          </p>
          </>
          
          <>
          <div id="4" className="media_company_text_title">
            <h2>４.入社する前は何をしていたの？</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            入社する前は普通の大学生で、授業と部活動に取り組んでいました。<br />
            アルバイトやインターン経験もなく、毎日部活動と授業に追われる大学生でした。<br />
          </p>
          </>
          
          <>
          <div id="5" className="media_company_text_title">
            <h2>５.どういった仕事をしているの？</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            トランク合同会社でのインターン生の仕事は、事業再構築補助金のヒアリング業務を行っています。<br />
            <br />
            ヒアリング業務では、事業計画書に落とし込めるよう事業者様のやりたいことを細かくヒアリングする必要があるため、計画書策定を見越したヒアリングを常に行わなければいけない業務となっています。<br />
          </p>
          </>
          
          <>
          <div id="6" className="media_company_text_title">
            <h2>６.社員との関係性</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            社員さんとの繋がりはあります。社員さんとランチに行って、仕事のことからプライベートの相談まで活発に交流しています。<br />
            <br />
            仕事中も質問したいことも気軽に質問でき、社員とインターン生も距離が近く、相談がしやすい職場環境です。<br />
          </p>
          </>
          
          <>
          <div id="6" className="media_company_text_title">
            <h2>６.インターンを希望する学生に向けて一言お願いします</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            他のインターン先では味わえない業務がたくさん詰まっていると思うので、スキルアップを目指したい学生におすすめです！<br />
          </p>
          </>
          
        </div>
        
      </div>

      <div className='media_company_'></div>

      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
