import './styles.css';
import simulatorIcon from './../../../../../image/simulator/simulator_icon_white.png';
import { useState, useEffect } from 'react';

const SimulatorResult = (props) => {

  const [ maximumAmount, setMaximumAmount ] = useState(0);
  const [ subsidyInvest, setSubsidyInvest ] = useState(0);

  useEffect(() => {

    if (props.people === 0 || props.people === 1) {
      setMaximumAmount(1000);
    } else if (props.people === 2 || props.people === 3) {
      setMaximumAmount(1500);
    } else if (props.people === 4) {
      setMaximumAmount(2000);
    } else if (props.people === 5 || props.people === 6) {
      setMaximumAmount(3000);
    }

    console.log(props.people);

    if (props.invest === 0 || props.invest === 1) {
      setSubsidyInvest(Math.round(500 / 3 * 2));
    } else if (props.invest === 2) {
      setSubsidyInvest(Math.round(1000 / 3 * 2));
    } else if (props.invest === 3) {
      setSubsidyInvest(Math.round(1500 / 3 * 2));
    } else if (props.invest === 4) {
      setSubsidyInvest(Math.round(2000 / 3 * 2));
    } else if (props.invest === 5) {
      setSubsidyInvest(Math.round(3000 / 3 * 2));
    } else if (props.invest === 6) {
      setSubsidyInvest(10000);
    }

  }, [props.people, props.invest]);

  return(
  <>
  <div className='simulator_result_type_title'>
    <img src={simulatorIcon} alt='' />
    <h4 style={{color : '#EB6232'}}>物価高騰対策・回復再生応援枠</h4>
  </div>
  <div className='simulator_result_type_desc'>
    <div style={{display : 'block', height : ''}}>
      <div>
        <h5>物価高騰対策・回復再生応援枠とは？</h5>
      </div>
      <p>昨今の物価高騰等により、売上減少等の影響を強く受けている事業者を支援する枠<br />主な申請要件：2022年以降の売上が2019～2021年と比較して10%以上減少していること</p>
    </div>
    <div>
      <div>
        <h5>補助率</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104'}}>3分の2</h4>
    </div>
    <div>
      <div>
        <h5>交付申請金額</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104'}}>{subsidyInvest === 10000 ? "〜" + maximumAmount : subsidyInvest >= maximumAmount ? maximumAmount : subsidyInvest}万円</h4>
    </div>
    <div>
      <div>
        <h5>補助上限金額</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104'}}>{maximumAmount}万円</h4>
    </div>
    <p>物価高騰対策・回復再生応援枠は金額により補助率が変化するため、詳細な内容を知りたい場合はお問い合わせください</p>
  </div>
  </>
  );
}

// 色を変える、診断する、終わり

export default SimulatorResult;
