import './styles.css';

const Help = () => {
  
  return (
    <>
    <div className='hojokin_result'>
      <h4>当社では以下のような実績がございます</h4>
      <div className='hojokin_result_box'>
        <div>
          <h5>顧客満足度</h5>
          <h4>98%</h4>
        </div>
        <div>
          <h5>採択率</h5>
          <h4>90%</h4>
        </div>
        <div>
          <h5>資金調達額</h5>
          <h4>2億円</h4>
        </div>
      </div>
    </div>
    </>
  );
}

export default Help;
