// https://www.kkj.go.jp/doc/ja/api_guide.pdf
// https://www.kkj.go.jp/api/?Query=%E3%82%BB%E3%83%9F%E3%83%8A%E3%83%BC
// https://kkj.go.jp/api/?Query=1&CFT_Issue_Date=2023-08-26/2023-08-27&Count=1000

import './styles.css';
import DocumentMeta from 'react-document-meta';
import Nav from "../items/nav/index";
import Search from "../items/search/index";
import Footer from '../items/footer/index';
import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";

const BlogText0001 = () => {

  const [ projectName, setProjectName ] = useState("");
  const [ date, setDate ] = useState("");
  const [ prefectureName, setPrefectureName ] = useState("");
  const [ cityName, setCityName ] = useState("");
  const [ organizationName, setOrganizationName ] = useState("");
  const [ certification, setCertification ] = useState("");
  const [ cftIssueDate, setCftIssueDate ] = useState("");
  const [ periodEndTime, setPeriodEndTime ] = useState("");
  const [ category, setCategory ] = useState("");
  const [ procedureType, setProcedureType ] = useState("");
  const [ location, setLocation ] = useState("");
  const [ tenderSubmissionDeadline, setTenderSubmissionDeadline ] = useState("");
  const [ openingTendersEvent, setOpeningTendersEvent ] = useState("");
  const [ itemCode, setItemCode ] = useState("");
  const [ projectDescription, setProjectDescription ] = useState("");
  const [ attachment, setAttachment ] = useState([]);
  const [ externalDocumentURI, setExternalDocumentURI ] = useState([]);

  useEffect(() => {
    var subsidyId = new URL(window.location).href.slice(new URL(window.location).href.indexOf('/id=') + 4, new URL(window.location).href.length);

    firebase.firestore().collection("bid").doc(subsidyId).get()
    .then((doc) => {
      setProjectName(doc.data().ProjectName);
      setDate(new Date(doc.data().Date.seconds * 1000).toISOString());
      setPrefectureName(doc.data().PrefectureName);
      setCityName(doc.data().CityName);
      setOrganizationName(doc.data().OrganizationName);
      setCertification(doc.data().Certification);
      setCftIssueDate(doc.data().CftIssueDate);
      setPeriodEndTime(doc.data().PeriodEndTime);
      setCategory(doc.data().Category);
      setProcedureType(doc.data().ProcedureType);
      setLocation(doc.data().Location);
      setTenderSubmissionDeadline(doc.data().TenderSubmissionDeadline);
      setOpeningTendersEvent(doc.data().OpeningTendersEvent);
      setItemCode(doc.data().ItemCode);
      setProjectDescription(doc.data().ProjectDescription);
      setAttachment(doc.data().Attachment);
      if (doc.data().ExternalDocumentURI !== '' && doc.data().ExternalDocumentURI !== undefined) {
        setExternalDocumentURI(doc.data().ExternalDocumentURI.slice(10, doc.data().ExternalDocumentURI.length - 4));
      } else {
        console.log(doc.data().ExternalDocumentURI);
        setExternalDocumentURI('');
      }
    });
  }, []);

  const meta = {
    title: `${organizationName}の入札案件 | ${projectName}`,
    description: 'この記事は、国の案件をお探しの方へ向けて、国、地方等の入札情報をまとめているサイトです。掲載されている入札情報は、全部で数千件を超えており、日本でも有数の公官需専門サイトです。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <div className='bid_api'>
        <Search />
        <div className='bid_api_header_left'>
          <div></div>
          <h3>公官需に関する情報を分かりやすく伝える</h3>
        </div>
        <div className='bid_api_media'>
          <h2>{projectName}</h2>
          <div>
            <p style={{backgroundColor : '#D70A0A'}}>1</p>
            <h3 style={{color : "#D70A0A"}}>入札情報の詳細</h3>
          </div>
          <table className='bid_api_media_table'>
            <tbody>
              {date === '' ? <div></div> :
                <tr>
                  <td className='bid_api_media_q'><p>公告取得日時</p></td>
                  <td className='bid_api_media_a'><p>{date.slice(0, 4)}年{date.slice(5, 7)}月{date.slice(8, 10)}日</p></td>
                </tr>
              }
              {cftIssueDate === '' ? <></> :
                <tr>
                  <td className='bid_api_media_q'><p>公告日</p></td>
                  <td className='bid_api_media_a'><p>{cftIssueDate.slice(0, 4)}年{cftIssueDate.slice(5, 7)}月{cftIssueDate.slice(8, 10)}日</p></td>
                </tr>
              }
              {prefectureName === '' ? <></> :
                <tr>
                  <td className='bid_api_media_q'><p>都道府県</p></td>
                  <td className='bid_api_media_a'><p>{prefectureName}</p></td>
                </tr>
              }
              {cityName === '' ? <></> :
                <tr>
                  <td className='bid_api_media_q'><p>市区町村名</p></td>
                  <td className='bid_api_media_a'><p>{cityName}</p></td>
                </tr>
              }
              {organizationName === '' ? <></> :
                <tr>
                  <td className='bid_api_media_q'><p>機関名</p></td>
                  <td className='bid_api_media_a'><p>{organizationName}</p></td>
                </tr>
              }
              {prefectureName === '' ? <></> :
                <tr>
                  <td className='bid_api_media_q'><p>都道府県</p></td>
                  <td className='bid_api_media_a'><p>{prefectureName}</p></td>
                </tr>
              }
              {certification === '' ? <></> :
                <tr>
                  <td className='bid_api_media_q'><p>入札資格</p></td>
                  <td className='bid_api_media_a'><p>{certification}</p></td>
                </tr>
              }
              {periodEndTime === '' ? <></> :
                <tr>
                  <td className='bid_api_media_q'><p>納入期限日</p></td>
                  <td className='bid_api_media_a'><p>{periodEndTime}</p></td>
                </tr>
              }
              {category === '' ? <></> :
                <tr>
                  <td className='bid_api_media_q'><p>カテゴリー</p></td>
                  <td className='bid_api_media_a'><p>{category}</p></td>
                </tr>
              }
              {procedureType === '' ? <></> :
                <tr>
                  <td className='bid_api_media_q'><p>公示種別</p></td>
                  <td className='bid_api_media_a'><p>{procedureType}</p></td>
                </tr>
              }
              {location === '' ? <></> :
                <tr>
                  <td className='bid_api_media_q'><p>履行場所</p></td>
                  <td className='bid_api_media_a'><p>{location}</p></td>
                </tr>
              }
              {tenderSubmissionDeadline === '' ? <></> :
                <tr>
                  <td className='bid_api_media_q'><p>入札開始日</p></td>
                  <td className='bid_api_media_a'><p>{tenderSubmissionDeadline}</p></td>
                </tr>
              }
              {openingTendersEvent === '' ? <></> :
                <tr>
                  <td className='bid_api_media_q'><p>開札日</p></td>
                  <td className='bid_api_media_a'><p>{openingTendersEvent}</p></td>
                </tr>
              }
              {itemCode === '' ? <></> :
                <tr>
                  <td className='bid_api_media_q'><p>品目分類番号</p></td>
                  <td className='bid_api_media_a'><p>{itemCode}</p></td>
                </tr>
              }
            </tbody>
          </table>
          {externalDocumentURI !== '' ?
            <a href={externalDocumentURI}><button>掲載元のURL</button></a>
          : <></>}
          {attachment.map((info, index) =>
            <a href={info.uri}><button key={index}>{info.name}</button></a>
          )}
          <div>
            <p style={{backgroundColor : '#D70A0A'}}>2</p>
            <h3 style={{color : "#D70A0A"}}>公告内容</h3>
          </div>
          <p>{projectDescription}</p>
        </div>
      </div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
