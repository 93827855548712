import './styles.css';
import React from "react";
import { AiOutlineFileSearch } from 'react-icons/ai';
import { BsPlusSquare } from 'react-icons/bs';
import { BsBookmarkPlus } from 'react-icons/bs';
import { AiOutlineLink } from 'react-icons/ai';
import { AiOutlineMessage } from 'react-icons/ai';
import { BiMailSend } from 'react-icons/bi';

const PhotoFunction = () => {

  return (
    <>
    <h4 className='photo_point_title'>作品撮り機能</h4>
    <div className='photo_point'>
      <div>
        <div><AiOutlineFileSearch className='photo_point_icons' /></div>
        <h4>作品撮り投稿</h4>
        <p>作品撮りにハッシュタグをつけて、簡単にアップロードできます。</p>
      </div>
      <div>
        <div><BsPlusSquare className='photo_point_icons' /></div>
        <h4>作品撮り閲覧</h4>
        <p>作品の検索できる機能で、メンズ、レディースなど、全7種類の検索が可能です。</p>
      </div>
      <div>
        <div><BsBookmarkPlus className='photo_point_icons' /></div>
        <h4>プロフィール</h4>
        <p>自分のプロフィールを登録することができ、他の方の情報も見ることができます。</p>
      </div>
      <div>
        <div><AiOutlineLink className='photo_point_icons' /></div>
        <h4>SNS連携</h4>
        <p>Instagram、Titok等のSNSリンクを掲載でき、他の方のSNSも閲覧できます。</p>
      </div>
      <div>
        <div><AiOutlineMessage className='photo_point_icons' /></div>
        <h4>スカウト</h4>
        <p>スカウト募集をすることができ、美容院様からスカウトを受け取ることができます。</p>
      </div>
      <div>
        <div><BiMailSend className='photo_point_icons' /></div>
        <h4>いいね・保存</h4>
        <p>作品撮りをいいねする事ができ、保存されるため見返す事ができます。</p>
      </div>
    </div>
    </>
  );
}

export default PhotoFunction;
