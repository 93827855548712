
import React from 'react';
import { useState } from 'react';
import './styles.css';
import firebase from 'firebase/compat/app'

const Taskheader = (props) => {

  const [ createName, setCreateName ] = useState('');
  const [ createMemo, setCreateMemo ] = useState('');
  const [ createPerson, setCreatePerson ] = useState('辻');

  function create() {
    props.setAddPrev(1000);

    firebase.firestore().collection('tasks').doc()
    .set({
      name : createName,
      memo : createMemo,
      person : createPerson === '辻' ?  [ '辻', 'https://ca.slack-edge.com/T03LR3Y18SZ-U03MHQVPG64-e465f85b0cef-512', ] : [ '伊藤', 'https://ca.slack-edge.com/T03LR3Y18SZ-U03LXL4C9JQ-16b421d1759b-512', ],
      checkBox : false,
      checkBox0 : [ false, false, false, false, false, false, false, false, ],
      checkBox1 : [ false, false, false, false, false, false, false, false, ],
      checkBox2 : [ false, false, false, false, false, false, false, false, ],
      checkBox3 : [ false, false, false, false, false, false, false, false, ],
      checkBox4 : [ false, false, false, false, false, false, false, false, ],
      checkBox5 : [ false, false, false, false, false, false, false, false, ],
      checkBox6 : [ false, false, false, false, false, false, false, false, ],
      checkBox7 : [ false, false, false, false, false, false, false, false, ],
      checkBox8 : [ false, false, false, false, false, false, false, false, ],
      checkBox9 : [ false, false, false, false, false, false, false, false, ],
      checkBox100 : [ 0, 0, 0, 0, 0, 0, 0, 0, ],
      order : 100,
      subsidy : 3,
      tag : 'シート',
      time : new Date(),
      uid : '',
    });
  }

  return (
    <>
    <div className='task_create'>
      <div className='task_create_background' onClick={() => props.setAddPrev(1000)}></div>
      <div className='task_content'>
        <div className='task_content_title'>
          <input placeholder='案件名' onChange={(e) => setCreateName(e.target.value)} onKeyDown={(e) => e.keyCode === 13 ? create() : ''} defaultValue={createName} />
          {createPerson === '辻' ?
            <img src='https://ca.slack-edge.com/T03LR3Y18SZ-U03MHQVPG64-e465f85b0cef-512' onClick={() => setCreatePerson('伊藤')} alt='' />
            :
            <img src='https://ca.slack-edge.com/T03LR3Y18SZ-U03LXL4C9JQ-16b421d1759b-512' onClick={() => setCreatePerson('辻')} alt='' />
          }
        </div>
        <textarea placeholder='メモ' onChange={(e) => setCreateMemo(e.target.value)} defaultValue={createMemo} />
        <button className='task_content_add_btn' onClick={() => create()}>追加</button>
      </div>
    </div>
    </>
  );
};

export default Taskheader;
