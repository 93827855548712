const BlogText0001 = (props) => {

  return(
  <div className='management_review_content_1'>
    <h4>自社の状況に関する質問（2問）</h4>
    <div>
      <h5>Q1.公認会計士等の数（登録経理試験１級合格者等を含む）</h5>
      <p>公認会計士等の数（登録経理試験１級合格者等を含む）の合計をご記載ください。</p>
      <input onChange={(e) => props.setCpa(e.target.value)} />
    </div>
    <div>
      <h5>Q2.登録経理試験２級合格者</h5>
      <p>登録経理試験２級合格者の合計をご記載ください。</p>
      <input onChange={(e) => props.setRegisteredExamination(e.target.value)} />
    </div>
  </div>
  );
}

export default BlogText0001;
