import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/manufacturing/5/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const BlogText0001 = () => {

  const meta = {
    title: '【初めての補助金】事業再構築補助金の必要書類を徹底解説',
    description: '本記事は、事業再構築補助金を申請したいけど、「対象事業者に含まれているかわからない！」という方へ向けて、事業再構築補助金の対象者の条件について解説させていただきます。本サイトでは、初めての方でも理解ができるようわかりやすくまとめさせていただきましたので、ぜひご参考にいただけますと大変助かります。加えて、本記事は公募要領を用いて説明させていただいておりますので、本記事を理解していただいた方は実際に公募要領をご一読いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2022/07/21</p>
          <h1 className="media_title">【初めての補助金】<br />事業再構築補助金の必要書類を徹底解説</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <p className="media_text_text">
            <br />
            <br />
            <span className='media_text_text_box_title'>こんな方へ向けて</span><br />
            <br />
            本記事は、事業再構築補助金を申請したいけど、「対象事業者に含まれているかわからない！」という方へ向けて、事業再構築補助金の対象者の条件について解説させていただきます。<br />
            <br />
            本サイトでは、初めての方でも理解ができるようわかりやすくまとめさせていただきましたので、ぜひご参考にいただけますと大変助かります。<br />
            <br />
            加えて、本記事は公募要領を用いて説明させていただいておりますので、本記事を理解していただいた方は実際に公募要領をご一読いただければと思います。<br />
            <br />
            それでは、解説に進ませていただきます！<br />
          </p>

          <div className='media_contents'>
            <span>本記事の構成</span><br /><br />
            <ol>
              <li><AnchorLink href="#1" offset="100">対象事業者の概要</AnchorLink></li>
              <li><AnchorLink href="#2" offset="100">事業再構築補助金の対象事業者</AnchorLink></li>
              <li><AnchorLink href="#3" offset="100">類型の概要</AnchorLink></li>
              <li><AnchorLink href="#4" offset="100">類型の詳細及び補助率</AnchorLink></li>
              <li><AnchorLink href="#5" offset="100">事業再構築補助金の類型要件</AnchorLink></li>
              <li><AnchorLink href="#6" offset="100">まとめ</AnchorLink></li>
            </ol>
          </div>

          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">必要な添付資料</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">事業の具体的な内容等</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">賃金引上げの誓約書</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">決算書</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">従業員数の確認資料</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">労働者名簿</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#1" offset="100">加点関係の追加提出書類</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">「再生事業者」に係る確認書</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">課税所得の状況を示す確定申告書類</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">炭素生産性向上計画及び温室効果ガス排出削減の取組状況</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">海外事業の準備状況を示す書類</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">審査における加点を希望する場合に必要な追加書類</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#1" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>

          <>
          <div id='1' className="media_text_title">
            <h2>１.必要な添付資料</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            ものづくり補助金の申請に必要な資料をまとめさせて頂きました。<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>事業の具体的な内容等</h3><br />
          <p className="media_text_text">
            <br />
            補助金を計上したい事業の内容を資料にして提出してください。<br />
            ※ word等で作成したのち、PDFに変換し電子申請システムに添付してください。<br />
            ※ 申請時に見積書等の用意が必要がありませんが、採択後の交付申請時に提出が必要となりますので、お早めにご用意ください。<br />
            <br />
            <br />
          </p>
          <h3 className='media_text_text_title'>賃金引上げの誓約書</h3><br />
          <p className="media_text_text">
            <br />
            申請に当たって、賃金引き上げの誓約書の提出が必要となります。<br />
            ※ 賃金引上げの誓約書を提出するにあたって、直近月の事業内最低賃金及び、決算における給与支給額が明記された資料を提出する必要があります。なお、提出する額を引き上げる旨の制約を提出してください。<br />
            <br />
            <br />
          </p>
          <h3 className='media_text_text_title'>決算書</h3><br />
          <p className="media_text_text">
            <br />
            直近2年分の賃貸対照表と損益計算書をご提出ください。<br />
            ※１ 設立2年目に満たない中小企業等は、1期分の決算書をご提出ください。<br />
            ※２ 設立まもない中小企業等は、事業計画書及び収支予算書を添付してください。<br />
            ※３ 個人事業主の場合は、確定申告書等を添付してください。<br />
            ※４ 従来から作成している場合のみ製造原価報告書及び販売管理費明細をご提出ください。<br />
            <br />
            <br />
          </p>
          <h3 className='media_text_text_title'>従業員数の確認資料</h3><br />
          <p className="media_text_text">
            <br />
            従業員数の確認資料を提出してください。<br />
            法人の場合は、法人事業概況説明書の写し<br />
            個人事業主の場合：所得税青色申告決算書または所得税白色申告収支内訳書の写し<br />
            <br />
            <br />
          </p>
          <h3 className='media_text_text_title'>労働者名簿</h3><br />
          <p className="media_text_text">
            <br />
            応募申請時の従業員数が21名以上で、従業員数の確認資料における期末の従業員数が20名以下の場合のみ労働者名簿を提出してください。<br />
            <br />
            <br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>２.加点関係の追加提出書類</h2>
          </div>
          <h3 className='media_text_text_title'>「再生事業者」に係る確認書</h3><br />
          <p className="media_text_text">
            <br />
            再生事業者のみ「再生事業者」に係る確認書をご提出ください。<br />
            <br />
            <br />
          </p>
          <h3 className='media_text_text_title'>課税所得の状況を示す確定申告書類</h3><br />
          <p className="media_text_text">
            <br />
            回復型賃上げ・雇用拡大枠のみ課税所得の状況を示す確定申告書類をご提出ください。<br />
            加えて、課税所得の状況を証明する書類として、確定申告等を添付してください。<br />
            <br />
            <br />
          </p>
          <h3 className='media_text_text_title'>炭素生産性向上計画及び温室効果ガス排出削減の取組状況</h3><br />
          <p className="media_text_text">
            <br />
            グリーン枠の申請者のみ炭素生産性向上計画及び温室効果ガス排出削減の取組状況を提出してください。<br />
            ※ 【様式２】を用いて、３～５年の事業計画期間内に、事業場単位での炭素生産性を年率平均１％以上増加させる具体的な計画内容と、これまでに自社で実施した温室効果ガス排出削減の取組内容の有無やその効果等を、詳細に記載し、添付してください。<br />
            <br />
            <br />
          </p>
          <h3 className='media_text_text_title'>海外事業の準備状況を示す書類</h3><br />
          <p className="media_text_text">
            <br />
            （９） 海外事業の準備状況を示す書類（グローバル展開型のみ）【★★】<br />
            ・①類型（海外直接投資）：海外子会社等の事業概要・財務諸表・株主構成が分かる資料<br />
            ・②類型（海外市場開拓）：具体的な想定顧客が分かる海外市場調査報告書<br />
            ・③類型（インバウンド市場開拓）：具体的な想定顧客が分かるインバウンド市場調査報告書<br />
            ・④類型（海外事業者との共同事業）：共同研究契約書又は業務提携契約書（検討中の案を含む）<br />
            ※１ （１）の事業の具体的な内容等とは別に、Word 等で作成の上、PDF 形式に変換した電子ファイルを電子申請システムの所定の場所に添付してください（様式自由、ページ数の制限なし）。<br />
            ※２ ④類型の各契約書を除き、提出資料は日本語で作成されたもの、もしくは日本語訳をおつけいただいたものに限ります。<br />
            ※３ 申請類型以外の類型に関する資料についても、添付のあったものについては、審査の対象といたします。<br />
            <br />
            <br />
          </p>
          <h3 className='media_text_text_title'>審査における加点を希望する場合に必要な追加書類</h3><br />
          <p className="media_text_text">
            <br />
            （１０） 審査における加点を希望する場合に必要な追加書類【★★】<br />
            ・成長性加点：経営革新計画承認書（当該計画の写しを含む）<br />
            ・政策加 点：開業届＊又は履歴事項全部証明書（創業・第二創業の場合）<br />
            ＊ 所轄税務署の収受印もしくは電子申請の受付刻印のある「個人事業の開業・廃業等届出書」を指します。<br />
            ：デジタル技術の活用及びDX推進の取組状況【様式３】（デジタル枠に申請する場合のみ）<br />
            ・災害等加点：（連携）事業継続力強化計画認定書（当該計画の写しを含む）<br />
            ・賃上げ加点： 特定適用事業所該当通知書（被用者保険の適用拡大の場合）<br />
            ※ 経営革新計画及び事業継続力強化計画については、応募締切日時点で認定（承認）を受けた計画期間が終了していない場合のみ加点対象となります。<br />
            <br />
            <br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>３.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            本記事では、事業再構築補助金を申請したい事業者さまへ向けて、事業再構築補助金の概要について記事にさせていただきました。<br />
            <br />
            公募要領を各所に添付させていただきましたが、ご覧いただけば分かる通り難しい内容となっております。<br />
            そこで、もし、本格的に事業再構築補助金の導入を検討されていましたら、弊社含め補助金に詳しい事業者へご相談ください。<br />
            <br />
            ご相談は、画面右上の補助金に関する相談をするから問い合わせにて、お待ちしております。<br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
        
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


