// 通常枠

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/16/top.png";
import Image1 from "./../../../../../image/media/business/16/1.png";
import Image2 from "./../../../../../image/media/business/16/2.png";
import Image3 from "./../../../../../image/media/business/16/3.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」通常枠は、３つの要件だけを確認する！',
    description: '本記事では、通常枠について解説をしております。満たさなければいけない要件の解説、及びポイントについてもまとめさせていただきました。本記事は、「通常枠の申請を考えているけど、要件や補助金額が分からない」という方へ向けて、記事を執筆させていただきました。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };


  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」通常枠は、３つの要件だけを確認する！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は、<strong>日本最大級の補助金である「事業再構築補助金」</strong>について解説させていただきます。<br />
            <br />
            本記事は、「<strong>通常枠の申請を考えているけど、要件や補助金額が分からない</strong>」という方へ向けて、記事を執筆させていただきました。<br />
            <br />
            事業再構築補助金の通常枠申請を検討されている方はぜひご覧ください！<br />
            <br />
            なお、<strong>申請枠が決まってない方は、別記事にて申請枠ごと情報を解説させていただきましたので、以下のリンクからご参照ください</strong>。<br />
            <a href='https://trankllc.com/media/subsidy/business/0002' target="_blank" rel="nofollow noopener noreferrer">
              参考記事：【初めての方へ】事業再構築補助金の対象者条件を徹底解説
            </a>
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>通常枠で必要となる要件</span></li>
              <li><span>通常枠を申請する際の提出書類</span></li>
              <li><span>通常枠の過去採択率</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、<strong>補助金の説明書である「公募要領」を参考にして作成されたものとなっています</strong>ので、本記事の内容を理解したうえで公募要領部を再度ご確認ください！<br />
            <br />
            最初は難しいと感じるかもしれませんが、<strong>読み進めていくうちに理解度がどんどん上がっていくと思います</strong>ので、ご一読いただければと思います。<br />
            <br />
            なお、申請サポートのご依頼や不明点のご相談がございましたら、右側のお問い合わせよりご連絡ください。<br />
            <br />
            それでは、解説に進ませていただきます！<br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">通常枠とは</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">通常枠の対象者</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">通常枠の概要</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">通常枠の過去採択率</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">通常枠に申請するための要件</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">事業再構築要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">売上等減少要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">認定支援機関要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">付加価値額要件</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">通常枠のポイント</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">通常枠は最も対象となる事業者が多い</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">最も申請件数が多いため、競争率が高い</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#4" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>１.通常枠とは</h2>
          </div>
          <p className="media_text_text">
            <br />
            通常枠は、<strong>事業再構築補助金において最もスタンダードな申請枠</strong>となっており、第6回公募では約８割が通常枠での申請となっております。<br />
            <br />
            通常枠の解説は、以下のような流れで説明していきますので、１つずつ理解しながら進めていただければと思います。<br />
            ① <strong>通常枠の概要</strong><br />
            ② <strong>通常枠の過去採択率</strong><br />
          </p>
          <h3 className='media_text_text_title'>通常枠の対象者</h3><br />
          <p className="media_text_text">
            通常枠の対象者は、「<strong>日本国内に本社を有する中小・中堅企業及び個人事業主</strong>」となります。<br />
            <br />
            上記のようにほとんどの事業者様が対象となっておりますので、新規事業を行う際は、ぜひ通常枠でのご検討をしてみてください。<br />
          </p>
          <h3 className='media_text_text_title'>通常枠の概要</h3><br />
          <p className="media_text_text">
            通常枠の概要として、以下に情報をまとめて記載させていただきましたので、自社情報と照らしながらご確認いただければと思います。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>項目</th><th>要件</th>
              </tr>
              <tr>
                <td>補助金額</td>
                <td>
                  【従業員20人以下】　100万円～2,000万円<br />
                  【従業員21～50人】　100万円～4,000万円<br />
                  【従業員50～100人】　100万円～6,000万円<br />
                  【従業員101人以上】　100万円～8,000万円<br />
                </td>
              </tr>
              <tr>
                <td>補助率(※)</td>
                <td>
                  中小企業者等　2/3以内(6,000万円を超える部分は1/2)<br />
                  中堅企業等　1/2以内(4,000万円を超える部分は1/3)<br />
                </td>
              </tr>
              <tr>
                <td>補助事業実施期間</td>
                <td>
                  交付決定日から12か月以内か、採択発表日から14か月後の日の短い方
                </td>
              </tr>
              <tr>
                <td>補助対象経費</td>
                <td>
                  建築費、機械装置・システム構築費(リース料を含む)、技術導入費、専門家経費、運搬費、クラウドサービス利用費、外注費、知的財産権等関連経費、広告宣伝・販売促進費、研修費
                </td>
              </tr>
              <tr>
                <td>申請要件</td>
                <td>
                  ・事業再構築要件<br />
                  ・売上等減少要件<br />
                  ・認定支援機関要件<br />
                  ・付加価値額要件<br />
                </td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            (※)補助率…事業にかかる全ての経費のうち、補助金額で補填することの出来る割合<br />
            <br />
            <img src={Image1} className='media_img' alt='' />
            <br />
            なお、補助対象経費は以下の記事にて説明させていただいておりますので、詳細に知りたい方はこちらの記事をご参照ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0003' target="_blank" rel="nofollow noopener noreferrer">
              参考記事：【何に使えるの？】事業再構築補助金の対象経費をわかりやすく解説！
            </a>
          </p>
          <h3 className='media_text_text_title'>通常枠の過去採択率</h3><br />
          <p className="media_text_text">
            本部分では、通常枠の直近採択率と過去の平均採択率を記載させていただきます。<br />
            <br />
            直近採択率と平均採択率を比較すると<strong>以前よりはるかに採択率が上がっている</strong>ため、初めて申請される方も十分に採択の可能性があります。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>項目</th><th>申請件数</th><th>採択件数</th><th>採択率</th>
              </tr>
              <tr>
                <td>第6次</td><td>11,653</td><td>5,297</td><td>45.5%</td>
              </tr>
              <tr>
                <td>合計(第1次～6次)</td><td>90,124</td><td>33,643</td><td>37.3%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            なお、通常枠の詳細な採択率や他申請枠の採択率は下記の記事にて記載しておりますので、興味のある方は是非ご覧ください！<br />
            <a href='https://trankllc.com/media/subsidy/business/0004' target="_blank" rel="nofollow noopener noreferrer">
              参考記事：【採択率はどれくらい？】事業再構築補助金の過去採択を徹底解剖！
            </a>
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>２.通常枠の要件</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、通常枠の以下の４つの要件について、解説させていただきます。<br />
            ① <strong>事業再構築要件</strong><br />
            ② <strong>売上等減少要件</strong><br />
            ③ <strong>認定支援機関要件</strong><br />
            ④ <strong>付加価値額要件</strong><br />
          </p>
          <h3 className='media_text_text_title'>事業再構築要件</h3><br />
          <p className="media_text_text">
            事業再構築要件とは、<strong>新分野展開、事業転換、業種転換、業態転換又は事業再編のいずれかに該当する事業計画でなければいけない</strong>という要件です。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・新分野展開、事業転換、業種転換、業態転換又は事業再編のいずれかに該当する事業計画を策定すること<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・事業計画書<br />
            <br />
            <strong>参考記事</strong><br />
            上記５点の（新分野展開、事業転換、業種転換、業態転換又は事業再編）についての詳しい記事を別途作成させていただきましたので、ぜひご参照いただければと思います。<br />
            <a href='https://trankllc.com/media/subsidy/business/0007' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【類型・転換って何？】事業再構築補助金の再構築要件を、例を用いて解説！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>売上高等減少要件</h3><br />
          <p className="media_text_text">
            売上等減少要件とは、<strong>新型コロナウイルスの影響によって、売上が減少していることを証明する要件</strong>となっております。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・2019年1月～2020年3月の連続する6か月の内、任意の3か月(コロナ以前)と2020年4月以降の同月(コロナ以後)で比較した際、総売上高が10%以上減少していること<br />
            <br />
            <strong>法人の場合【要件を満たすために提出する書類】</strong><br />
            ・コロナ以前の、選択した任意3か月の売上が分かる年度確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の法人事業概況説明書の控え(両面)<br />
            ・受信通知(1枚)(e-Taxで申告している場合のみ)<br />
            ・コロナ以後の、選択した任意3か月の売上が分かる確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の法人事業概況説明書の控え(両面)<br />
            <br />
            <strong>個人の場合【要件を満たすために提出する書類】</strong><br />
            ・コロナ以前の、選択した任意3か月の売上が分かる年度確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の月別売上の記載がある所得税青色申告決算書の控え(両面)<br />
            (白色申告の方は、対象月の売上が分かる売上台帳や、確定申告の基礎となる書類)<br />
            ・受信通知(1枚)(e-Taxで申告している場合のみ)<br />
            ・コロナ以前の、選択した任意3か月の売上が分かる年度確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の月別売上の記載がある所得税青色申告決算書の控え(両面)<br />
            (白色申告の方は、対象月の売上が分かる売上台帳や、確定申告の基礎となる書類)<br />
            <br />
            なお、上記書類の図例を下記に記載いたしますので、こちらも参考にしてご準備ください。<br />
            <br />
            <img src={Image2} className='media_img' alt='' /><br />
            <br />
            所得税青色申告決算書の控え<br />
            <img src={Image3} className='media_img' alt='' />
            <br />
            売上減少を計算する際の具体的な手順は、下記の記事でまとめて記載しておりますので、下記リンクよりご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0007' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【10%・15%】事業再構築補助金の売上高等減少要件を具体例で解説！
            </a><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>要件</th><th>書類名</th>
              </tr>
              <tr>
                <td>事業再構築要件<br />付加価値額要件</td><td>事業計画書</td>
              </tr>
              <tr>
                <td>売上等減少要件</td>
                <td>
                  ・確定申告書別表一(1枚)<br />
                  ・法人事業概況説明書の控え(両面)<br />
                  ・受信通知(1枚)(e-Taxで申請している場合)<br />
                </td>
              </tr>
              <tr>
                <td>認定支援機関要件</td><td>認定経営革新等支援機関による確認書</td>
              </tr>
              <tr>
                <td>要件以外の提出書類</td>
                <td>
                  ・決算書<br />
                  ・「ミラサポplus」で作成した事業財務情報のPDF<br />
                </td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            なお、枠ごとの申請書類につきましては、下記の記事にて詳しく説明しておりますので、ご参照ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0007' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【何を出せばいいの？】事業再構築補助金の提出書類一覧表を大公開！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>認定支援機関要件</h3><br />
          <p className="media_text_text">
            認定支援機関要件とは、<strong>国に中小企業支援の知識・経験がある者として認められた認定支援機関に事業計画書を確認してもらわなければいけない</strong>という要件です。<br />
            <br />
            認定支援機関とは、<strong>中小企業支援の知識・経験がある者として、国の認定を受けた機関であり、士業や商工会、金融機関が多く認可されています</strong>。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・認定支援機関と相談したうえで事業計画書を策定する。<br />
            ※認定経営革新等支援機関による確認書は、認定支援機関に相談したことを証明する書類ですので、必ず認定支援機関に作成をお願いしてください。<br />
            <br />
            <strong>【要件を満たすために必要な書類】</strong><br />
            ・認定経営革新等支援機関による確認書<br />
            ・金融機関による確認書(補助金額が3,000万円を超える場合)<br />
            <br />
            認定支援機関要件は、下記の記事にて詳しく解説しておりますので、詳細が知りたい方はぜひご参照ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0009' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【どこに依頼する？】事業再構築補助金の認定支援機関要件を機関別に解説！
            </a><br />
            <br />
            おすすめの認定支援機関や認定支援機関別の採択率は、下記の記事でまとめて記載しておりますので、下記リンクよりご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0004' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【採択率はどれくらい？】事業再構築補助金の過去採択を徹底解剖！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>付加価値額要件</h3><br />
          <p className="media_text_text">
            付加価値額要件とは、<strong>付加価値額が上昇する事業計画を策定しなければいけない</strong>という要件です。<br />
            <br />
            付加価値額とは、補助金独自の考え方であり、以下の方法により算出できます。<br />
            <span>付加価値額=営業利益+減価償却費+人件費</span><br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・補助事業終了後3~5年で、付加価値額の年平均率を3.0%以上増加させること<br />
            ・補助事業終了後3~5年で、従業員1人当たりの付加価値額の年平均率3.0%以上増加させること<br />
            ※上記のどちらかを満たす事業計画を策定することが要件となります。<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・事業計画書<br />
            <br />
            上記の要件を満たした事業計画書を策定することが必要になりますので、認定支援機関と共にご確認をお願いいたします。<br />
            <br />
            <strong>【通常枠に申請するための要件と必要書類一覧表】</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>要件</th>
                <th>書類名</th>
              </tr>
              <tr>
                <td>
                  事業再構築要件<br />
                  付加価値額要件
                </td>
                <td>
                  ・事業計画書
                </td>
              </tr>
              <tr>
                <td>
                  緊急対策要件
                </td>
                <td>
                  ・確定申告書別表一(1枚)<br />
                  ・法人事業概況説明書の控え(両面)<br />
                  ・受信通知(1枚)(e-Taxで申請している場合)<br />
                  ・原油価格・物価高騰等の経済環境の変化の影響を受けていることの宣誓書
                </td>
              </tr>
              <tr>
                <td>認定支援機関要件</td><td>・認定経営革新等支援機関による確認書</td>
              </tr>
              <tr>
                <td>要件以外の提出書類</td>
                <td>
                  ・決算書<br />
                  ・「ミラサポplus」で作成した事業財務情報のPDF
                </td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            なお、こちらの申請書類は以下の記事でさらに詳しく解説しておりますので、下記URLの記事もご確認のうえ、参考程度としてご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0004' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【何を出せばいいの？】事業再構築補助金の提出書類一覧表を大公開！
            </a><br />
          </p>
          </>
          
          <>
          <div id='4' className="media_text_title">
            <h2>４.通常枠の特徴</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、上述でご説明させていただいたことを踏まえて、<strong>通常枠の特徴</strong>を説明させていただきますので、申請枠を迷っている事業者さまは、参考にしていただければと思います！<br />
          </p>
          <h3 className='media_text_text_title'>他の枠と比較して申請が容易</h3><br />
          <p className="media_text_text">
            通常枠の特徴として、<strong>他の申請枠と比較して要件が少ない</strong>ため、申請が可能な事業者が多いことがあげられます。<br />
            <br />
            それに伴い、<strong>提出が必要な書類も少ない</strong>ため、締め切りまで時間がない場合は、通常枠でのご検討をおススメします。<br />
            <br />
            しかし、申請が容易な事から「申請者が多い」「採択率が比較的低い」という特徴もあるため、<strong>加点書類の提出や事業計画書の精査を行うことが重要</strong>になります。<br />
          </p>
          <h3 className='media_text_text_title'>補助金額の上限が高い</h3><br />
          <p className="media_text_text">
            通常枠の補助金額は上限が8,000万円となっており、比較的補助金額が大きい申請枠となっております。<br />
            <br />
            従業員数による定めもあるものの、従業員数が21人～50人でも最大4,000万円、51～100人では最大6,000万円と、<strong>他の申請枠に比べ申請金額が大きい補助金</strong>となっています。<br />
            <br />
            そのため、従業員数が21人～99人であり、事業にかかる経費が高額になる事業者様は、ぜひ通常枠での申請をご検討ください。<br />
          </p>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>４.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、<strong>通常枠の要件</strong>について解説させていただきました。<br />
            <br />
            やや複雑なものとはなっておりますが、少しずつ進めていくと理解できる内容かと思いますので、諦めずに申請まで行っていただければと思います。<br />
            <br />
            なお、ご不明点、疑問点につきましては、<strong>画面右側のお問い合わせからお待ちしております</strong>ので、ご気軽にご連絡いただければと思います。<br />
            <br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


