import firebase from "firebase/compat/app"

const BlogText0001 = () => {
  
  function oneToTwo() {

    console.log('ok');
    
    firebase.firestore().collection('event').doc('info').get()
    .then( async (doc) => {
      var present = doc.data().present;
      
      var round1List = [];
      var round2List = [];
      var entryPeople = [];
      
      await firebase.firestore().collection('event').doc(doc.data().present).get()
      .then((doc) => {
        entryPeople = doc.data().entryPeople;
        round1List = doc.data().round1;
        round2List = doc.data().round2;
      })

      var winList = [];

      for (let i = 0; i < round1List.length / 2; i++) {

        var one = round1List[i * 2];
        var two = round1List[i * 2 + 1];
        var oneResult = 0;
        var twoResult = 0;
        
        await firebase.firestore().collection('event').doc(present).collection('tournament').doc(one).get()
        .then((doc) => {
          oneResult = doc.data().round1_vote.length;
        });
        await firebase.firestore().collection('event').doc(present).collection('tournament').doc(two).get()
        .then((doc) => {
          twoResult = doc.data().round1_vote.length;
        });

        if (oneResult >= twoResult) {
          winList.push(one);
        } else {
          winList.push(two);
        }
      }

      var listAll = winList.concat(round2List);
      var listUpdate = [...new Set(listAll)];

      if (listUpdate.length === 1) {
        firebase.firestore().collection('event').doc(present)
        .update({
          round2: listUpdate,
        });
      } else {
        listUpdate.reverse();
        firebase.firestore().collection('event').doc(present)
        .update({
          round2: listUpdate.length % 2 === 0 ? listUpdate : listUpdate.slice(0, listUpdate.length - 1),
          round3: listUpdate.length % 2 === 0 ? [] : listUpdate.slice(listUpdate.length - 1, listUpdate.length),
        });
      }

      firebase.firestore().collection('event').doc('info')
      .update({
        now_round: 1,
      });
    });
  }


  return (
    <>
    <button onClick={() => oneToTwo()}>1回戦の集計 {'=>'} 2回戦の用意</button>
    </>
  );
}

export default BlogText0001;