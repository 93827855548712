import './styles.css';
import Logo from './../../../../image/icon.png';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const simulatorNav = (props) => {

  return(
  <>
  <div className='simulator_nav'>
    <Link to='/simulator'>
      <div className='simulator_nav_title'>
        <img src={Logo} alt='' />
        <div>
          <h1>事業再構築補助金 診断サイト</h1>
          <h2>補助金に申請できるのかを簡単に診断</h2>
        </div>
      </div>
    </Link>
    <div className='simulator_nav_link'>
      <Link to='/saikoutiku'>
        <p>サービス内容</p>
      </Link>
      <Link to='/media/subsidy/business/0000'>
        <p>補助金解説</p>
      </Link>
      <Link to='/company'>
        <p>運営会社</p>
      </Link>
      <AnchorLink href="#1" offset="200">
        <button>問い合わせ</button>
        </AnchorLink>
    </div>
  </div>
  </>
  );
}

export default simulatorNav;
