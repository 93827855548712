// 加点書類

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/22/top.png";
import Image1 from "./../../../../../image/media/business/22/1.png";
import Image2 from "./../../../../../image/media/business/22/2.png";
import Image3 from "./../../../../../image/media/business/22/3.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」10分で取れる加点書類が2つある！',
    description: '本記事では、加点書類の説明と加点書類の取り方について解説させていただきました。具体的な書類の添付についてもご案内させていただいております。本記事は、申請において、「加点書類を出したほうが良いのか悩んでいる」方や「どんな書類が必要なのか知りたい」という方へ向けて、記事を執筆させていただきいました。<br />',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」10分で取れる加点書類が2つある！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は、日本最大級の補助金である事業再構築補助金の「加点書類」について解説させていただきます。<br />
            <br />
            本記事は、申請において、「加点書類を出したほうが良いのか悩んでいる」方や「どんな書類が必要なのか知りたい」という方へ向けて、記事を執筆させていただきました。<br />
            <br />
            事業再構築補助金の事業計画書をチェックしたい方はぜひご覧ください！<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>加点書類とは</span></li>
              <li><span>加点書類の一覧</span></li>
              <li><span>申請手順</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、事業再構築補助金事務局が発表している、「公募要領」を参考にして作成されたものとなっていますので、<strong>本記事をご一読いただいたのちに再度公募要領をご覧いただければと思います</strong>。<br />
            <br />
            なお、右上のボタンからお問い合わせをお受けしておりますので、<strong>申請サポートのご依頼や不明点のご相談はお気軽にご連絡ください</strong>。<br />
            <br />
            それでは、記事の解説に進ませていただきます！<br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">事業再構築補助金の加点項目とは？</AnchorLink></li>
                <li><AnchorLink href="#2" offset="100">加点書類の種類</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>１.事業再構築補助金の加点項目とは？</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、加点項目の概要と加点項目の影響について、解説させていただきます。<br />
          </p>
          <h3 className='media_text_text_title'>加点項目とは</h3><br />
          <p className="media_text_text">
            事業再構築補助金の加点項目とは、項目を取得することによって、審査で加点されるというものです。<br />
            <br />
            大きく８つの加点項目があり、取得できる数が多いほど採択されやすくなります。<br />
          </p>
          <h3 className='media_text_text_title'>採択率への影響</h3><br />
          <p className="media_text_text">
            現在、事業再構築補助金事務局から加点書類数別による、採択率等の発表は行われておりません。<br />
            <br />
            しかし、弊社がこれまで支援させていただいたお客様の中でも、加点書類が多いほど採択率は高くなる傾向にあるため、補助金申請時には加点書類も合わせて確認しましょう。<br />
            <br />
            「補足」<br />
            一方で、同じ経済産業省が管轄するものづくり補助金では、以下のように加点項目による差がありますので、提出が可能な書類は積極的に作成していきましょう。<br />
            <br />
            加点項目が0個の事業者の採択率は29.3%である一方で、3個の事業者は79.5%と加点項目3個の差で約50%上昇することが発表されています。<br />
            <br />
            <a href='https://portal.monodukuri-hojo.jp/dataportal.html' target="_blank" rel="nofollow noopener noreferrer">
              リンク：ものづくり補助金公式HP
            </a>
            <img src={Image1} className='media_img' alt='' />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>２.加点書類の種類</h2>
          </div>
          <p className="media_text_text">
            <br />
            では、実際に加点項目にはどんな種類があり、どのような事業者が対象となるかといった点について、解説させていただきます。<br />
          </p>
          <h3 className='media_text_text_title'>加点項目一覧</h3><br />
          <p className="media_text_text">
            加点項目は上記の通り、8つの種類となっており、対象となる事業者は是非提出していきましょう。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>加点項目</th>
              </tr>
              <tr>
                <td>大きく売上が減少しており業況が厳しい事業者に対する加点</td>
              </tr>
              <tr>
                <td>経済産業省が行うEBPM の取組への協力に対する加点</td>
              </tr>
              <tr>
                <td>パートナーシップ構築宣言を行っている事業者に対する加点(成長枠・グリーン成長枠のみ)</td>
              </tr>
              <tr>
                <td>事業再生を行う者に対する加点</td>
              </tr>
              <tr>
                <td>特定事業者であり、中小企業者でない者に対する加点</td>
              </tr>
              <tr>
                <td>サプライチェーン加点</td>
              </tr>
              <tr>
                <td>健康経営優良法人に認定された事業者に対する加点</td>
              </tr>
              <tr>
                <td>大幅な賃上げを実施する事業者に対する加点(成長枠・グリーン成長枠のみ)</td>
              </tr>
              <tr>
                <td>ワーク・ライフ・バランス等の取組に対する加点</td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>大きく売上が減少しており業況が厳しい事業者に対する加点</h3><br />
          <p className="media_text_text">
            事業再構築補助金においては、大きく売上が減少している事業者に加点される仕組みとなっており、公募要領には、下記のような記載がされています。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>補助事業の具体的な取組内容</span><br />
            ①2022 年 1 月以降のいずれかの月の売上高が対 2020 年又は 2019 年同月比で 30％以上減少していること（又は、2022 年 1 月以降のいずれかの月の付加価値額が、対 2020 年又は 2019 年同月比で 45％以上減少していること）。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a><br />
          </div>
          <p className="media_text_text">
            <br />
            この内容を簡略化すると、<br />
            2022年1月～現在の月において2019・2020年と同じ月で比べた際に、以下のいずれかの要件を満たす必要があるというものです。<br />
            ・売上高が30%以上減少<br />
            ・付加価値額が45%以上減少<br />
            ※　付加価値額：営業利益＋人件費＋減価償却費<br />
            <br />
            【該当するケース】<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>売上高</th><th>1月</th>
              </tr>
              <tr>
                <td>2020年</td>
                <td>20,000,000</td>
              </tr>
              <tr>
                <td>2021年</td>
                <td>10,000,000</td>
              </tr>
              <tr>
                <td>減少率</td>
                <td>50%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            これに該当する方は月別売上高の減少を証明する書類が必要となります。<br />
            申請に使用する月が含まれる年度の確定申告書別表1・法人事業概況説明書・e-Taxの受信通知が必要となりますので、お早めにご準備ください。<br />
          </p>
          <h3 className='media_text_text_title'>経済産業省が行う EBPM の取組への協力に対する加点</h3><br />
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>補助事業の具体的な取組内容</span><br />
            ③ データに基づく政策効果検証・事業改善を進める観点から、経済産業省が行う EBPM の取組に対して、採否に関わらず、継続的な情報提供が見込まれるものであるか。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a><br />
          </div>
          <p className="media_text_text">
            この項目は経済産業省が管轄するEBPM（政策の効果検証）の取組において情報提供をする事業者に対する加点となります。<br />
            <br />
            こちらは、電子申請時に表示される「EBPMへの取組に協力しますか」というチェックボックスにチェックを行うことで加点になるため、忘れずにチェックを行いましょう。<br />
            <a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              リンク：最低賃金確認書のダウンロードリンク
            </a><br />
            <br />
            <img src={Image2} className='media_img' alt='' />
          </p>
          <h3 className='media_text_text_title'>パートナーシップ構築宣言を行っている事業者に対する加点(成長枠・グリーン成長枠のみ)</h3><br />
          <p className="media_text_text">
            <br />
            この加点項目は、中小企業庁が管轄する「パートナーシップ構築宣言」の登録を申請時点で行っている事業者が対象となる項目です。<br />
            <br />
            パートナーシップ構築宣言に関しては、どの事業者でもご申請いただくことができる加点項目となりますので、ぜひご登録いただければと思います！<br />
            <br />
            登録の仕方や申請方法や下記URLのHPから参照ください！<br />
            <a href='https://www.biz-partnership.jp/' target="_blank" rel="nofollow noopener noreferrer">
              リンク：パートナーシップ構築宣言
            </a>
          </p>
          <h3 className='media_text_text_title'>事業再生を行う者に対する加点</h3><br />
          <p className="media_text_text">
            この加点項目は、下記に該当している事業者のみが対象となりますので、申請前に一度確認してみてください。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>補助事業の具体的な取組内容</span><br />
            中小企業活性化協議会（旧：中小企業再生支援協議会）等から支援を受けており、応募申請時において以下のいずれかに該当していること。<br />
            （１） 再生計画等を「策定中」の者※２<br />
            （２） 再生計画等を「策定済」かつ応募締切日から遡って３年以内（令和2年7月1日以 降）に再生計画等が成立等した者<br />
            <br />
            ※１ 以下に掲げる計画に関する支援を受けている者（同計画に基づき事業譲渡を受ける（又は受けた）者を含む）。 1. 中小企業活性化協議会（旧：中小企業再生支援協議会）が策定を支援した再生計画<br />
            2. 独立行政法人中小企業基盤整備機構が策定を支援した再生計画<br />
            3. 産業復興相談センターが策定を支援した再生計画<br />
            4. 株式会社整理回収機構が策定を支援した再生計画<br />
            5. 「私的整理に関するガイドライン」に基づいて策定した再建計画<br />
            6.中小企業の事業再生等のための私的整理手続（中小企業版私的整理手続）に基づいて策定した再生計画（令和４年４月１５日から適用開始）<br />
            7. 産業競争力強化法に基づき経済産業大臣の認定を受けた認証紛争解決事業者（事業再生ＡＤＲ事業者）が策定を支援した事業再生計画<br />
            8. 独立行政法人中小企業基盤整備機構が出資した中小企業再生ファンドが策定を支援した再生計画<br />
            9. 株式会社東日本大震災事業者再生支援機構が同機構法第 19 条の規定による支援決定を行った事業再生計画<br />
            10. 株式会社地域経済活性化支援機構が株式会社地域経済活性化支援機構法第 25 条の規定による再生支援決定を行った事業再生計画<br />
            11. 特定調停法に基づく調停における調書（同法第 17 条第１項の調停条項によるものを除く。）又は同法第 20 条に規定する決定において特定された再生計画<br />
            <br />
            ※２ ※１のうち、１.から７.のみが対象。<br />
            また、１.から７.における「策定中」の定義は以下のとおり。<br />
            １．から３．「再生計画策定支援（第2次対応）決定」以後<br />
            ４．企業再生検討委員会による「再生計画着手承認」以後<br />
            ５. 同ガイドラインに基づく「一時停止の要請」以後<br />
            ６．同手続きに基づく「一時停止の要請」以後<br />
            ７．事業再生 ADR 制度の「制度利用申請正式受理」以後<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a><br />
          </div>
          <h3 className='media_text_text_title'>特定事業者であり、中小企業者でない者に対する加点</h3><br />
          <p className="media_text_text">
            この項目も上述した「事業再生を行う者に対する加点」と同様、一部の事業者のみが対象となりますので、内容に一度目を通してみましょう。<br />
            <br />
            以下のいずれかに該当し、【中小企業者】及び【「中小企業者等」に含まれる「中小企業者」以外の法人】に該当しないこと。<br />
            １．従業員数（常勤）が下表の数字以下となる会社又は個人（「産業競争力強化法等の一部を 改正する等の法律」第４条による改正後の「中小企業等経営強化法」第２条第５項に規定する者を指す。）のうち、資本金の額又は出資の総額が 10 億円未満であるもの<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>業種</th><th>常勤従業員数</th>
              </tr>
              <tr>
                <td>製造業、建設業、運輸業</td>
                <td>500人</td>
              </tr>
              <tr>
                <td>卸売業</td>
                <td>400人</td>
              </tr>
              <tr>
                <td>サービス業又は小売業<br />（ソフトウェア業、情報サービス業、旅館業を除く）</td>
                <td>300人</td>
              </tr>
              <tr>
                <td>その他の業種（上記以外）</td>
                <td>500人</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            ２．生活衛生同業組合、生活衛生同業小組合、生活衛生同業組合連合会<br />
            ※その直接又は間接の構成員の３分の２以上が、常時３００人（卸売業を主たる事業とする事業者については、４００人）以下の従業員を使用する者であって１０億円未満の金額をその資本金の額又は出資の総額とするものであるもの。）<br />
            <br />
            ３．酒造組合、酒造組合連合会、酒造組合中央会、酒販組合、酒販組合連合会、酒販組合中央会 （酒造組合、酒造組合連合会、酒造組合中央会の場合）<br />
            ※その直接又は間接の構成員たる酒類製造業者の３分の２以上が、常時５００人以 下の従業員を使用する者で あるものであって１０億円未満の金額をその資本金の額 又は出資の総額とするものであるもの。 （酒販組合、酒販組合連合会、酒販組合中央会の場合）<br />
            ※その直接又は間接の構成員たる酒類販売業者の３分の２以上が、常時３００人 （酒類卸売業者については、４００人）以下の従業員を使用する者であって１０億 円未満の金額をその資本金の額又は出資の総額とするものであるもの<br />
            <br />
            ４．内航海運組合、内航海運組合連合会<br />
            ※その直接又は間接の構成員たる内航海運事業を営む者の３分の２以上が常時５００人以下の従業員を使用する者であって１０億円未満の金額をその資本金の額又は出資の総額とするものであるもの。<br />
            <br />
            ５．技術研究組合<br />
            ※直接又は間接の構成員の３分の２以上が以下の事業者のいずれかであるもの。<br />
            ・上記１．記載の事業者<br />
            ・企業組合、協同組合<br />
          </p>
          <h3 className='media_text_text_title'>サプライチェーン加点</h3><br />
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>補助事業の具体的な取組内容</span><br />
            複数の事業者が連携して事業に取り組む場合であって、同じサプライチェーンに属する事業者が、以下を満たし、連携して申請すること。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a><br />
          </div>
          <p className="media_text_text">
            この加点項目は、複数の事業者が連携して申請する際に活用いただける項目です。<br />
            要件としては、「複数の事業者が連携して申請すること」「同じサプライチェーンに属していること」「取引関係があること」の３つとなります。<br />
            <br />
            この加点項目を申請される際には、電子申請の際に該当箇所にチェックをすることで得られる加点となります。<br />
          </p>
          <h3 className='media_text_text_title'>健康経営優良法人に認定された事業者に対する加点</h3><br />
          <p className="media_text_text">
            この加点項目は、健康経営優良法人に認定されている事業者が対象となります。認定されている事業者は下記のURLから確認できますので、申請前にご確認ください。<br />
            ※健康経営優良法人認定事務局ポータルサイト（https://www.kenko-keiei.jp/）<br />
          </p>
          <h3 className='media_text_text_title'>大幅な賃上げを実施する事業者に対する加点(成長枠・グリーン成長枠のみ)</h3><br />
          <p className="media_text_text">
            この加点項目は、事業実施期間終了後3～5年で以下の賃上げを実施する事業者が対象となります。なお、理由がなく賃上げ等が実施できなかった場合、補助金の返還や補助金額の減額を求められる場合があります。<br />
            <br />
            【加点対象】<br />
            給与支給総額年率平均３%増加：加点1ポイント<br />
            給与支給総額年率平均４％増加：加点2ポイント<br />
            給与支給総額年率平均５％：加点3ポイント<br />
          </p>
          <h3 className='media_text_text_title'>ワーク・ライフ・バランス等の取組に対する加点</h3><br />
          <p className="media_text_text">
            この加点項目は、応募申請時点で以下の項目に該当している事業者が対象となります。申請前に、1度確認を行いましょう。<br />
            <br />
            1. 女性の職業生活における活躍の推進に関する法律（女性活躍推進法）に基づく認定（えるぼし１段階目～３段階目又はプラチナえるぼしのいずれかの認定）を受けている者又は従業員数100人以下であって、「女性の活躍推進データベース」に女性活躍推進法に基づく一般事業主行動計画を公表している者 ※厚生労働省「女性の活躍推進企業データベース」 （https://positive-ryouritsu.mhlw.go.jp/positivedb/）<br />
            <br />
            2. 次世代育成支援対策推進法（次世代法）に基づく認定（くるみん、トライくるみん又はプラチナくるみんのいずれかの認定）を受けた者又は従業員数100人以下であって、「一般事業主行動計画公表サイト（両立支援のひろば）」に次世代法に基づく一般事業主行動計画を公表している者 ※厚生労働省「一般事業主行動計画公表サイト（両立支援のひろば）」 （https://ryouritsu.mhlw.go.jp/hiroba/search_int.php）<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>３.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            この度は事業再構築補助金の「加点項目」に関する記事をお読みいただき、ありがとうございました。<br />
            <br />
            加点項目を得ることは事業再構築補助金において非常に重要となりますので、提出できる書類は提出することをオススメいたします。<br />
            <br />
            なお、当社では加点項目に関するご相談も受け付けておりますので、お気軽にご連絡ください。<br />
            <br />
            この度は、最後まで記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


