import React, { useState, useEffect } from "react";
import './styles.css';
import Header from './header/index';
import Create from './create/index';
import Add from './add/index';
import Mypage from './mypage/index';
import Setting from './setting/index';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMove } from 'react-sortable-hoc';
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore'
import { AiOutlineHome } from 'react-icons/ai'
import firebase from 'firebase/compat/app'
import { v4 as uuidv4 } from 'uuid';

const Help = () => {

  uuidv4();

  const [ myPage, setMyPage ] = useState(true);

  const [ nav, setNav ] = useState([]);
  const [ navIndex, setNavIndex ] = useState(0);
  const [ items, setItems ] = useState([]);
  
  const [ checkBoxPreview, setCheckBoxPreview ] = useState(false);
  const [ checkBoxData, setCheckBoxData ] = useState([0, 0, '', ]);

  const [ setting, setSetting ] = useState(false);

  const [ addPrev, setAddPrev ] = useState(1000);
  const [ createInfo, setCreateInfo ] = useState(1000);

  useEffect(() => {
    navList();
    getList('');
  }, []);

  function navList() {
    const docRef = collection(firebase.firestore(), 'tasks')
    const queryRef = query(docRef, where('tag', '==', 'nav'))
    const queryRefs = query(queryRef, orderBy('order'))
    const unsub = onSnapshot(queryRefs, snapshot => {
      var results = []
      snapshot.docs.forEach(doc => {
        results.push({...doc.data(), id: doc.id })
      })
      setNav(results);
    })
    return () => unsub();
  }

  const onDropNav = ({ removedIndex, addedIndex }) => {
    const updater = (items) =>
    arrayMove(items, removedIndex, addedIndex).map((item, idx) => {
      firebase.firestore().collection('tasks').doc(item.id).update({ order : idx, })
      return { ...item, order: idx };
    });
    setNav(updater);
  };

  function getList(v) {

    const docRef = collection(firebase.firestore(), 'tasks')
    const queryRef = query(docRef, where('tag', '==', v))
    const queryRefs = query(queryRef, orderBy('order'))
    const unsub = onSnapshot(queryRefs, snapshot => {
      var results = []
      snapshot.docs.forEach(doc => {
        results.push({...doc.data(), id: doc.id })
      })
      setItems(results);
    })
    return () => unsub()
  }

  const onDrop = ({ removedIndex, addedIndex }) => {
    const updater = (items) =>
    arrayMove(items, removedIndex, addedIndex).map((item, idx) => {
      firebase.firestore().collection('tasks').doc(item.id).update({ order : idx, })
      return { ...item, order: idx };
    });
    setItems(updater);
  };

  function createSheet() {

    firebase.firestore().collection('tasks').doc()
    .set({
      name : 'シート',
      color : 'FF7847',
      checkBox : false,
      order : 100,
      tag : 'nav',
      count : 3,
      column : [ 3, 3, 3, 3, 3, 3, 3, 3, ],
      row0 : [ '１つ目', '２つ目', '３つ目', '４つ目', '５つ目', '６つ目', '７つ目', '８つ目', ],
      column0 : [ '１つ目', '２つ目', '３つ目', '４つ目', '５つ目', '６つ目', '７つ目', '８つ目', ],
      column1 : [ '１つ目', '２つ目', '３つ目', '４つ目', '５つ目', '６つ目', '７つ目', '８つ目', ],
      column2 : [ '１つ目', '２つ目', '３つ目', '４つ目', '５つ目', '６つ目', '７つ目', '８つ目', ],
      column3 : [ '１つ目', '２つ目', '３つ目', '４つ目', '５つ目', '６つ目', '７つ目', '８つ目', ],
      column4 : [ '１つ目', '２つ目', '３つ目', '４つ目', '５つ目', '６つ目', '７つ目', '８つ目', ],
      column5 : [ '１つ目', '２つ目', '３つ目', '４つ目', '５つ目', '６つ目', '７つ目', '８つ目', ],
      column6 : [ '１つ目', '２つ目', '３つ目', '４つ目', '５つ目', '６つ目', '７つ目', '８つ目', ],
      column7 : [ '１つ目', '２つ目', '３つ目', '４つ目', '５つ目', '６つ目', '７つ目', '８つ目', ],
    })
  }

  function createList(v) {
    firebase.firestore().collection('tasks').doc()
    .set({
      name : 'new task',
      memo : '',
      person : [ '辻', 'https://ca.slack-edge.com/T03LR3Y18SZ-U03MHQVPG64-e465f85b0cef-512', ],
      checkBox : false,
      checkBox0 : [ false, false, false, false, false, false, false, false, ],
      checkBox1 : [ false, false, false, false, false, false, false, false, ],
      checkBox2 : [ false, false, false, false, false, false, false, false, ],
      checkBox3 : [ false, false, false, false, false, false, false, false, ],
      checkBox4 : [ false, false, false, false, false, false, false, false, ],
      checkBox5 : [ false, false, false, false, false, false, false, false, ],
      checkBox6 : [ false, false, false, false, false, false, false, false, ],
      checkBox7 : [ false, false, false, false, false, false, false, false, ],
      checkBox8 : [ false, false, false, false, false, false, false, false, ],
      checkBox9 : [ false, false, false, false, false, false, false, false, ],
      checkBox100 : [ 0, 0, 0, 0, 0, 0, 0, 0, ],
      order : 100,
      subsidy : 3,
      tag : v,
      time : new Date(),
      uid : '',
    })
  }

  return (
    <>
    <Header setMyPage={setMyPage} />

    <div className="task_content_nav">
      {nav.length !== 0 ?
      <Container onDrop={onDropNav}>
        {nav.map(( data, index ) => (
          <Draggable key={index}>
            <div className="task_content_nav_details" style={{backgroundColor : `#${data.color}`}} onClick={() => {setMyPage(false); setNavIndex(index); getList(data.name); }}>
              {data.name.slice( 0 , 1 )}
            </div>
          </Draggable>
        ))}
      </Container>
      : <></>}
      <div className="task_content_nav_details" onClick={() => createSheet()} style={{backgroundColor : 'white', color : '#222222'}}>
        ＋
      </div>
    </div>

    {myPage ? 
    <Mypage /> :
    <div className='task'>
      {/* タイトル */}
      <div className='task_content_box_title' onClick={() => setCheckBoxPreview(false)}>
        <div className="task_content_box_title_title">
          <AiOutlineHome className='task_content_box_title_icon' />
          <p>トランク合同会社</p>
        </div>
        <div className="task_content_box_title_sub">
          <h2 onClick={() => {setSetting(!setting)}}>{nav[navIndex] === undefined ? '' : nav[navIndex].name}</h2>
        </div>
      </div>

      {setting ?
      <Setting nav={nav[navIndex]} setSetting={setSetting} />
      : 
      <>
      <div className='task_table'>

        {/* 案件 */}
        <div className='task_table_title' onClick={() => setCheckBoxPreview(false)}>
          <p>案件シート</p>
          <div onClick={() => createList(nav[navIndex] === undefined ? '' : nav[navIndex].name)}>リスト追加</div>
        </div>

        {/* タイトル */}
        <div className='task_table_topic' onClick={() => setCheckBoxPreview(false)}>
          <p>タイトル</p>
          <div>
            {nav[navIndex] !== undefined && 0 < nav[navIndex].count ?
              <p style={{width: `calc((850px / ${nav[navIndex].count}) - 3px)`}}>{nav[navIndex].row0[0]}</p>
            : <></>}
            {nav[navIndex] !== undefined && 1 < nav[navIndex].count ?
              <p style={{width: `calc((850px / ${nav[navIndex].count}) - 3px)`}}>{nav[navIndex].row0[1]}</p>
            : <></>}
            {nav[navIndex] !== undefined && 2 < nav[navIndex].count ?
              <p style={{width: `calc((850px / ${nav[navIndex].count}) - 3px)`}}>{nav[navIndex].row0[2]}</p>
            : <></>}
            {nav[navIndex] !== undefined && 3 < nav[navIndex].count ?
              <p style={{width: `calc((850px / ${nav[navIndex].count}) - 3px)`}}>{nav[navIndex].row0[3]}</p>
            : <></>}
            {nav[navIndex] !== undefined && 4 < nav[navIndex].count ?
              <p style={{width: `calc((850px / ${nav[navIndex].count}) - 3px)`}}>{nav[navIndex].row0[4]}</p>
            : <></>}
            {nav[navIndex] !== undefined && 5 < nav[navIndex].count ?
              <p style={{width: `calc((850px / ${nav[navIndex].count}) - 3px)`}}>{nav[navIndex].row0[5]}</p>
            : <></>}
            {nav[navIndex] !== undefined && 6 < nav[navIndex].count ?
              <p style={{width: `calc((850px / ${nav[navIndex].count}) - 3px)`}}>{nav[navIndex].row0[6]}</p>
            : <></>}
            {nav[navIndex] !== undefined && 7 < nav[navIndex].count ?
              <p style={{width: `calc((850px / ${nav[navIndex].count}) - 3px)`}}>{nav[navIndex].row0[7]}</p>
            : <></>}
          </div>
        </div>

        {items.length !== 0 ?
          <Container onDrop={onDrop}>
            {items.map(( data, index ) => (
              <Draggable key={index}>
                <>
                <div className='task_table_content'
                  style={{opacity: checkBoxPreview && checkBoxData[0] !== index ? .4 : 1}}>

                  {nav[navIndex] !== undefined ?
                    <>
                      {nav[navIndex].name !== 'リード' ?
                      <div className='task_table_content_left'>
                        <div onClick={() => firebase.firestore().collection('tasks').doc(data.id).update({ subsidy : data.subsidy === 1 ? 2 : data.subsidy === 2 ? 3 : 1, })}
                        style={{borderColor : '#222222', color : '#222222'}}>
                          {index + 1}
                          </div>
                        <p onClick={() => {setCreateInfo(index)}}>{data.name}</p>
                        <img src={data.person[1]} alt='' />
                      </div>
                      :
                      <div className='task_table_content_left'>
                        <div onClick={() => firebase.firestore().collection('tasks').doc(data.id).update({ subsidy : data.subsidy === 1 ? 2 : data.subsidy === 2 ? 3 : 1, })}
                        style={data.subsidy === 1 ? {borderColor : '#FF7847', color : '#FF7847'} : data.subsidy === 2 ? {borderColor : '#FFD04F', color : '#FFD04F'} : {borderColor : '#68CEEB', color : '#68CEEB'}}>
                          {data.subsidy === 1 ? '事' : data.subsidy === 2 ? 'も' : '小'}
                          </div>
                        <p onClick={() => {setCreateInfo(index)}}>{data.name}</p>
                        <img src={data.person[1]} alt='' />
                      </div>}
                    </>
                  : <></>}

                  {nav[navIndex] !== undefined ?
                  <>
                    {nav[navIndex].checkBox ?
                      <div className='task_table_content_right'>
                        {nav[navIndex] !== undefined && 0 < nav[navIndex].count ?
                          <div onClick={() => {setCheckBoxData([ index, 0, data.id, ]); setCheckBoxPreview(true); }}
                            style = {
                              data.checkBox0.slice(0, nav[navIndex].column[0]).includes(true) && data.checkBox0.slice(0, nav[navIndex].column[0]).includes(false) ? 
                                {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                              !data.checkBox0.slice(0, nav[navIndex].column[0]).includes(true) ? 
                                {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                                {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                            }>
                          {!data.checkBox0.slice(0, nav[navIndex].column[0]).includes(false) ? '完了' : data.checkBox0.slice(0, nav[navIndex].column[0]).includes(true) && data.checkBox0.slice(0, nav[navIndex].column[0]).includes(false) ? '着手中' : '未着手'}
                        </div> : <></>}
                        {nav[navIndex] !== undefined && 1 < nav[navIndex].count ?
                          <div onClick={() => {setCheckBoxData([ index, 1, data.id, ]); setCheckBoxPreview(true)}}
                            style = {
                              data.checkBox1.slice(0, nav[navIndex].column[1] + 1).includes(true) && data.checkBox1.slice(0, nav[navIndex].column[1]).includes(false) ? 
                                {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                              !data.checkBox1.slice(0, nav[navIndex].column[1]).includes(true) ? 
                                {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                                {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                            }>
                            {!data.checkBox1.slice(0, nav[navIndex].column[1]).includes(false) ? '完了' : data.checkBox1.slice(0, nav[navIndex].column[1]).includes(true) && data.checkBox1.slice(0, nav[navIndex].column[1]).includes(false) ? '着手中' : '未着手'}
                        </div> : <></>}
                        {nav[navIndex] !== undefined && 2 < nav[navIndex].count ?
                          <div onClick={() => {setCheckBoxData([ index, 2, data.id, ]); setCheckBoxPreview(true)}}
                            style = {
                              data.checkBox2.slice(0, nav[navIndex].column[2]).includes(true) && data.checkBox2.slice(0, nav[navIndex].column[2]).includes(false) ? 
                                {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                              !data.checkBox2.slice(0, nav[navIndex].column[2]).includes(true) ? 
                                {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                                {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                            }>
                            {!data.checkBox2.slice(0, nav[navIndex].column[2]).includes(false) ? '完了' : data.checkBox2.slice(0, nav[navIndex].column[2]).includes(true) && data.checkBox2.slice(0, nav[navIndex].column[2]).includes(false) ? '着手中' : '未着手'}
                        </div> : <></>}
                        {nav[navIndex] !== undefined && 3 < nav[navIndex].count ?
                        <div onClick={() => {setCheckBoxData([ index, 3, data.id, ]); setCheckBoxPreview(true)}}
                          style = {
                            data.checkBox3.slice(0, nav[navIndex].column[3]).includes(true) && data.checkBox3.slice(0, nav[navIndex].column[3]).includes(false) ? 
                              {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                            !data.checkBox3.slice(0, nav[navIndex].column[3]).includes(true) ? 
                              {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                              {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                          }>
                          {!data.checkBox3.slice(0, nav[navIndex].column[3]).includes(false) ? '完了' : data.checkBox3.slice(0, nav[navIndex].column[3]).includes(true) && data.checkBox3.slice(0, nav[navIndex].column[3]).includes(false) ? '着手中' : '未着手'}
                          </div>
                        : <></>}
                        {nav[navIndex] !== undefined && 4 < nav[navIndex].count ?
                        <div onClick={() => {setCheckBoxData([ index, 4, data.id, ]); setCheckBoxPreview(true)}}
                          style = {
                            data.checkBox4.slice(0, nav[navIndex].column[4]).includes(true) && data.checkBox4.slice(0, nav[navIndex].column[4]).includes(false) ? 
                              {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                            !data.checkBox4.slice(0, nav[navIndex].column[4]).includes(true) ? 
                              {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                              {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                          }>
                          {!data.checkBox4.slice(0, nav[navIndex].column[4]).includes(false) ? '完了' : data.checkBox4.slice(0, nav[navIndex].column[4]).includes(true) && data.checkBox4.slice(0, nav[navIndex].column[4]).includes(false) ? '着手中' : '未着手'}
                          </div>
                        : <></>}
                        {nav[navIndex] !== undefined && 5 < nav[navIndex].count ?
                        <div onClick={() => {setCheckBoxData([ index, 5, data.id, ]); setCheckBoxPreview(true)}}
                          style = {
                            data.checkBox5.slice(0, nav[navIndex].column[5]).includes(true) && data.checkBox5.slice(0, nav[navIndex].column[5]).includes(false) ? 
                              {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                            !data.checkBox5.slice(0, nav[navIndex].column[5]).includes(true) ? 
                              {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                              {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                          }>
                          {!data.checkBox5.slice(0, nav[navIndex].column[5]).includes(false) ? '完了' : data.checkBox5.slice(0, nav[navIndex].column[5]).includes(true) && data.checkBox5.slice(0, nav[navIndex].column[5]).includes(false) ? '着手中' : '未着手'}
                          </div>
                        : <></>}
                        {nav[navIndex] !== undefined && 6 < nav[navIndex].count ?
                        <div onClick={() => {setCheckBoxData([ index, 6, data.id, ]); setCheckBoxPreview(true)}}
                          style = {
                            data.checkBox6.slice(0, nav[navIndex].column[6]).includes(true) && data.checkBox6.slice(0, nav[navIndex].column[6]).includes(false) ? 
                              {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                            !data.checkBox6.slice(0, nav[navIndex].column[6]).includes(true) ? 
                              {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                              {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                          }>
                          {!data.checkBox6.slice(0, nav[navIndex].column[6]).includes(false) ? '完了' : data.checkBox6.slice(0, nav[navIndex].column[6]).includes(true) && data.checkBox6.slice(0, nav[navIndex].column[6]).includes(false) ? '着手中' : '未着手'}
                          </div>
                        : <></>}
                        {nav[navIndex] !== undefined && 7 < nav[navIndex].count ?
                        <div onClick={() => {setCheckBoxData([ index, 7, data.id, ]); setCheckBoxPreview(true)}}
                          style = {
                            data.checkBox7.slice(0, nav[navIndex].column[7]).includes(true) && data.checkBox7.slice(0, nav[navIndex].column[7]).includes(false) ? 
                              {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                            !data.checkBox7.slice(0, nav[navIndex].column[7]).includes(true) ? 
                              {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                              {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                          }>
                          {!data.checkBox7.slice(0, nav[navIndex].column[7]).includes(false) ? '完了' : data.checkBox7.slice(0, nav[navIndex].column[7]).includes(true) && data.checkBox7.slice(0, nav[navIndex].column[7]).includes(false) ? '着手中' : '未着手'}
                          </div>
                        : <></>}
                        {nav[navIndex] !== undefined && 8 < nav[navIndex].count ?
                        <div onClick={() => {setCheckBoxData([ index, 8, data.id, ]); setCheckBoxPreview(true)}}
                          style = {
                            data.checkBox8.slice(0, nav[navIndex].column[8]).includes(true) && data.checkBox8.slice(0, nav[navIndex].column[8]).includes(false) ? 
                              {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                            !data.checkBox8.slice(0, nav[navIndex].column[8]).includes(true) ? 
                              {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                              {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                          }>
                          {!data.checkBox8.slice(0, nav[navIndex].column[8]).includes(false) ? '完了' : data.checkBox8.slice(0, nav[navIndex].column[8]).includes(true) && data.checkBox8.slice(0, nav[navIndex].column[8]).includes(false) ? '着手中' : '未着手'}
                          </div>
                        : <></>}
                        {nav[navIndex] !== undefined && 9 < nav[navIndex].count ?
                        <div onClick={() => {setCheckBoxData([ index, 9, data.id, ]); setCheckBoxPreview(true)}}
                          style = {
                            data.checkBox9.slice(0, nav[navIndex].column[9]).includes(true) && data.checkBox9.slice(0, nav[navIndex].column[9]).includes(false) ? 
                              {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                            !data.checkBox9.slice(0, nav[navIndex].column[9]).includes(true) ? 
                              {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                              {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                          }>
                          {!data.checkBox9.slice(0, nav[navIndex].column[9]).includes(false) ? '完了' : data.checkBox9.slice(0, nav[navIndex].column[9]).includes(true) && data.checkBox9.slice(0, nav[navIndex].column[9]).includes(false) ? '着手中' : '未着手'}
                          </div>
                        : <></>}
                      </div>
                      :
                      // <h4>最悪</h4>
                      <div className='task_table_content_right'>
                        {nav[navIndex] !== undefined && 0 < nav[navIndex].count ?
                        <div
                          onClick={() => firebase.firestore().collection('tasks').doc(data.id).update({
                            checkBox100 : data.checkBox100[0] === 0 ? 
                              [ 1, data.checkBox100[1], data.checkBox100[2], data.checkBox100[3], data.checkBox100[4], data.checkBox100[5], data.checkBox100[6], data.checkBox100[7], ] : 
                            data.checkBox100[0] === 1 ? 
                              [ 2, data.checkBox100[1], data.checkBox100[2], data.checkBox100[3], data.checkBox100[4], data.checkBox100[5], data.checkBox100[6], data.checkBox100[7], ] : 
                              [ 0, data.checkBox100[1], data.checkBox100[2], data.checkBox100[3], data.checkBox100[4], data.checkBox100[5], data.checkBox100[6], data.checkBox100[7], ]
                          })}
                          style = {
                            data.checkBox100[0] === 0 ? 
                              {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                            data.checkBox100[0] === 1 ? 
                              {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                              {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                          }>
                          {data.checkBox100[0] === 0 ? '未着手' : data.checkBox100[0] === 1 ? '着手中' : '完了'}
                        </div> : <></> }
                        {nav[navIndex] !== undefined && 1 < nav[navIndex].count ?
                        <div
                          onClick={() => firebase.firestore().collection('tasks').doc(data.id).update({
                            checkBox100 : data.checkBox100[1] === 0 ? 
                              [ data.checkBox100[0], 1, data.checkBox100[2], data.checkBox100[3], data.checkBox100[4], data.checkBox100[5], data.checkBox100[6], data.checkBox100[7], ] : 
                            data.checkBox100[1] === 1 ? 
                              [ data.checkBox100[0], 2, data.checkBox100[2], data.checkBox100[3], data.checkBox100[4], data.checkBox100[5], data.checkBox100[6], data.checkBox100[7], ] : 
                              [ data.checkBox100[0], 0, data.checkBox100[2], data.checkBox100[3], data.checkBox100[4], data.checkBox100[5], data.checkBox100[6], data.checkBox100[7], ]
                          })}
                          style = {
                            data.checkBox100[1] === 0 ? 
                              {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                            data.checkBox100[1] === 1 ? 
                              {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                              {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                          }>
                          {data.checkBox100[1] === 0 ? '未着手' : data.checkBox100[1] === 1 ? '着手中' : '完了'}
                        </div> : <></>}
                        {nav[navIndex] !== undefined && 2 < nav[navIndex].count ?
                        <div
                          onClick={() => firebase.firestore().collection('tasks').doc(data.id).update({
                            checkBox100 : data.checkBox100[2] === 0 ? 
                              [ data.checkBox100[0], data.checkBox100[1], 1, data.checkBox100[3], data.checkBox100[4], data.checkBox100[5], data.checkBox100[6], data.checkBox100[7], ] : 
                            data.checkBox100[2] === 1 ? 
                              [ data.checkBox100[0], data.checkBox100[1], 2, data.checkBox100[3], data.checkBox100[4], data.checkBox100[5], data.checkBox100[6], data.checkBox100[7], ] : 
                              [ data.checkBox100[0], data.checkBox100[1], 0, data.checkBox100[3], data.checkBox100[4], data.checkBox100[5], data.checkBox100[6], data.checkBox100[7], ]
                          })}
                          style = {
                            data.checkBox100[2] === 0 ? 
                              {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                            data.checkBox100[2] === 1 ? 
                              {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                              {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                          }>
                          {data.checkBox100[2] === 0 ? '未着手' : data.checkBox100[2] === 1 ? '着手中' : '完了'}
                        </div> : <></>}
                        {nav[navIndex] !== undefined && 3 < nav[navIndex].count ?
                        <div
                          onClick={() => firebase.firestore().collection('tasks').doc(data.id).update({
                            checkBox100 : data.checkBox100[3] === 0 ? 
                              [ data.checkBox100[0], data.checkBox100[1], data.checkBox100[2], 1, data.checkBox100[4], data.checkBox100[5], data.checkBox100[6], data.checkBox100[7], ] : 
                            data.checkBox100[3] === 1 ? 
                              [ data.checkBox100[0], data.checkBox100[1], data.checkBox100[2], 2, data.checkBox100[4], data.checkBox100[5], data.checkBox100[6], data.checkBox100[7], ] : 
                              [ data.checkBox100[0], data.checkBox100[1], data.checkBox100[2], 0, data.checkBox100[4], data.checkBox100[5], data.checkBox100[6], data.checkBox100[7], ]
                          })}
                          style = {
                            data.checkBox100[3] === 0 ? 
                              {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                            data.checkBox100[3] === 1 ? 
                              {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                              {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                          }>
                          {data.checkBox100[3] === 0 ? '未着手' : data.checkBox100[3] === 1 ? '着手中' : '完了'}
                        </div> : <></>}
                        {nav[navIndex] !== undefined && 4 < nav[navIndex].count ?
                        <div
                          onClick={() => firebase.firestore().collection('tasks').doc(data.id).update({
                            checkBox100 : data.checkBox100[4] === 0 ? 
                              [ data.checkBox100[0], data.checkBox100[1], data.checkBox100[2], data.checkBox100[3], 1, data.checkBox100[5], data.checkBox100[6], data.checkBox100[7], ] : 
                            data.checkBox100[4] === 1 ? 
                              [ data.checkBox100[0], data.checkBox100[1], data.checkBox100[2], data.checkBox100[3], 2, data.checkBox100[5], data.checkBox100[6], data.checkBox100[7], ] : 
                              [ data.checkBox100[0], data.checkBox100[1], data.checkBox100[2], data.checkBox100[3], 0, data.checkBox100[5], data.checkBox100[6], data.checkBox100[7], ]
                          })}
                          style = {
                            data.checkBox100[4] === 0 ? 
                              {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                            data.checkBox100[4] === 1 ? 
                              {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                              {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                          }>
                          {data.checkBox100[4] === 0 ? '未着手' : data.checkBox100[4] === 1 ? '着手中' : '完了'}
                        </div> : <></>}
                        {nav[navIndex] !== undefined && 5 < nav[navIndex].count ?
                        <div
                          onClick={() => firebase.firestore().collection('tasks').doc(data.id).update({
                            checkBox100 : data.checkBox100[5] === 0 ? 
                              [ data.checkBox100[0], data.checkBox100[1], data.checkBox100[2], data.checkBox100[3], data.checkBox100[4], 1, data.checkBox100[6], data.checkBox100[7], ] : 
                            data.checkBox100[5] === 1 ? 
                              [ data.checkBox100[0], data.checkBox100[1], data.checkBox100[2], data.checkBox100[3], data.checkBox100[4], 2, data.checkBox100[6], data.checkBox100[7], ] : 
                              [ data.checkBox100[0], data.checkBox100[1], data.checkBox100[2], data.checkBox100[3], data.checkBox100[4], 0, data.checkBox100[6], data.checkBox100[7], ]
                          })}
                          style = {
                            data.checkBox100[5] === 0 ? 
                              {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                            data.checkBox100[5] === 1 ? 
                              {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                              {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                          }>
                          {data.checkBox100[5] === 0 ? '未着手' : data.checkBox100[5] === 1 ? '着手中' : '完了'}
                        </div> : <></>}
                        {nav[navIndex] !== undefined && 6 < nav[navIndex].count ?
                        <div
                          onClick={() => firebase.firestore().collection('tasks').doc(data.id).update({
                            checkBox100 : data.checkBox100[6] === 0 ? 
                              [ data.checkBox100[0], data.checkBox100[1], data.checkBox100[2], data.checkBox100[3], data.checkBox100[4], data.checkBox100[5], 1, data.checkBox100[7], ] : 
                            data.checkBox100[6] === 1 ? 
                              [ data.checkBox100[0], data.checkBox100[1], data.checkBox100[2], data.checkBox100[3], data.checkBox100[4], data.checkBox100[5], 2, data.checkBox100[7], ] : 
                              [ data.checkBox100[0], data.checkBox100[1], data.checkBox100[2], data.checkBox100[3], data.checkBox100[4], data.checkBox100[5], 0, data.checkBox100[7], ]
                          })}
                          style = {
                            data.checkBox100[6] === 0 ? 
                              {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                            data.checkBox100[6] === 1 ? 
                              {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                              {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                          }>
                          {data.checkBox100[6] === 0 ? '未着手' : data.checkBox100[6] === 1 ? '着手中' : '完了'}
                        </div> : <></>}
                        {nav[navIndex] !== undefined && 7 < nav[navIndex].count ?
                        <div
                          onClick={() => firebase.firestore().collection('tasks').doc(data.id).update({
                            checkBox100 : data.checkBox100[7] === 0 ? 
                              [ data.checkBox100[0], data.checkBox100[1], data.checkBox100[2], data.checkBox100[3], data.checkBox100[4], data.checkBox100[5], data.checkBox100[6], 1, ] : 
                            data.checkBox100[7] === 1 ? 
                              [ data.checkBox100[0], data.checkBox100[1], data.checkBox100[2], data.checkBox100[3], data.checkBox100[4], data.checkBox100[5], data.checkBox100[6], 2, ] : 
                              [ data.checkBox100[0], data.checkBox100[1], data.checkBox100[2], data.checkBox100[3], data.checkBox100[4], data.checkBox100[5], data.checkBox100[6], 0, ]
                          })}
                          style = {
                            data.checkBox100[7] === 0 ? 
                              {backgroundColor : '#D1505F', width: `calc((850px / ${nav[navIndex].count}) - 3px)`} : 
                            data.checkBox100[7] === 1 ? 
                              {backgroundColor : '#F1AE54', width: `calc((850px / ${nav[navIndex].count}) - 3px )`} : 
                              {backgroundColor : '#5AC47D', width: `calc((850px / ${nav[navIndex].count}) - 3px )`}
                          }>
                          {data.checkBox100[7] === 0 ? '未着手' : data.checkBox100[7] === 1 ? '着手中' : '完了'}
                        </div> : <></>}
                      </div>}
                  </>
                  : <></>}
                </div>
                </>
              </Draggable>
            ))}
          </Container>
        : <></>}

        {checkBoxPreview ?
        <div className="task_checkbox"
          style={{width : `calc((850px / ${nav[navIndex].count}) - 13px )`, padding : '0 5px',
          margin : `calc(190px + 60.5px * ${(checkBoxData[0])}) 0 0 calc(310px + 3px + (850px / ${nav[navIndex].count}) * ${(checkBoxData[1])})`}}>

            {checkBoxData[1] === 0 ?
            <>
              {nav[navIndex] !== undefined && 0 < nav[navIndex].column[0] ? 
                <div style={items[checkBoxData[0]].checkBox0[0] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox0 : [ !items[checkBoxData[0]].checkBox0[0], items[checkBoxData[0]].checkBox0[1], items[checkBoxData[0]].checkBox0[2], items[checkBoxData[0]].checkBox0[3], items[checkBoxData[0]].checkBox0[4], items[checkBoxData[0]].checkBox0[5], items[checkBoxData[0]].checkBox0[6], items[checkBoxData[0]].checkBox0[7], ]})}
                >{nav[navIndex].column0[0]}</div> : <></>}
              {nav[navIndex] !== undefined && 1 < nav[navIndex].column[0] ? 
                <div style={items[checkBoxData[0]].checkBox0[1] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox0 : [ items[checkBoxData[0]].checkBox0[0], !items[checkBoxData[0]].checkBox0[1], items[checkBoxData[0]].checkBox0[2], items[checkBoxData[0]].checkBox0[3], items[checkBoxData[0]].checkBox0[4], items[checkBoxData[0]].checkBox0[5], items[checkBoxData[0]].checkBox0[6], items[checkBoxData[0]].checkBox0[7], ]})}
                >{nav[navIndex].column0[1]}</div> : <></>}
              {nav[navIndex] !== undefined && 2 < nav[navIndex].column[0] ? 
                <div style={items[checkBoxData[0]].checkBox0[2] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox0 : [ items[checkBoxData[0]].checkBox0[0], items[checkBoxData[0]].checkBox0[1], !items[checkBoxData[0]].checkBox0[2], items[checkBoxData[0]].checkBox0[3], items[checkBoxData[0]].checkBox0[4], items[checkBoxData[0]].checkBox0[5], items[checkBoxData[0]].checkBox0[6], items[checkBoxData[0]].checkBox0[7], ]})}
                  >{nav[navIndex].column0[2]}</div> : <></>}
              {nav[navIndex] !== undefined && 3 < nav[navIndex].column[0] ? 
                <div style={items[checkBoxData[0]].checkBox0[3] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox0 : [ items[checkBoxData[0]].checkBox0[0], items[checkBoxData[0]].checkBox0[1], items[checkBoxData[0]].checkBox0[2], !items[checkBoxData[0]].checkBox0[3], items[checkBoxData[0]].checkBox0[4], items[checkBoxData[0]].checkBox0[5], items[checkBoxData[0]].checkBox0[6], items[checkBoxData[0]].checkBox0[7], ]})}
                >{nav[navIndex].column0[3]}</div> : <></>}
              {nav[navIndex] !== undefined && 4 < nav[navIndex].column[0] ? 
                <div style={items[checkBoxData[0]].checkBox0[4] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox0 : [ items[checkBoxData[0]].checkBox0[0], items[checkBoxData[0]].checkBox0[1], items[checkBoxData[0]].checkBox0[2], items[checkBoxData[0]].checkBox0[3], !items[checkBoxData[0]].checkBox0[4], items[checkBoxData[0]].checkBox0[5], items[checkBoxData[0]].checkBox0[6], items[checkBoxData[0]].checkBox0[7], ]})}
                  >{nav[navIndex].column0[4]}</div> : <></>}
              {nav[navIndex] !== undefined && 5 < nav[navIndex].column[0] ? 
                <div style={items[checkBoxData[0]].checkBox0[5] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox0 : [ items[checkBoxData[0]].checkBox0[0], items[checkBoxData[0]].checkBox0[1], items[checkBoxData[0]].checkBox0[2], items[checkBoxData[0]].checkBox0[3], items[checkBoxData[0]].checkBox0[4], !items[checkBoxData[0]].checkBox0[5], items[checkBoxData[0]].checkBox0[6], items[checkBoxData[0]].checkBox0[7], ]})}
                >{nav[navIndex].column0[5]}</div> : <></>}
              {nav[navIndex] !== undefined && 6 < nav[navIndex].column[0] ? 
                <div style={items[checkBoxData[0]].checkBox0[6] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox0 : [ items[checkBoxData[0]].checkBox0[0], items[checkBoxData[0]].checkBox0[1], items[checkBoxData[0]].checkBox0[2], items[checkBoxData[0]].checkBox0[3], items[checkBoxData[0]].checkBox0[4], items[checkBoxData[0]].checkBox0[5], !items[checkBoxData[0]].checkBox0[6], items[checkBoxData[0]].checkBox0[7], ]})}
                >{nav[navIndex].column0[6]}</div> : <></>}
              {nav[navIndex] !== undefined && 7 < nav[navIndex].column[0] ? 
                <div style={items[checkBoxData[0]].checkBox0[7] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox0 : [ items[checkBoxData[0]].checkBox0[0], items[checkBoxData[0]].checkBox0[1], items[checkBoxData[0]].checkBox0[2], items[checkBoxData[0]].checkBox0[3], items[checkBoxData[0]].checkBox0[4], items[checkBoxData[0]].checkBox0[5], items[checkBoxData[0]].checkBox0[6], !items[checkBoxData[0]].checkBox0[7], ]})}
                >{nav[navIndex].column0[7]}</div> : <></>}
              </> : <></>}
            {checkBoxData[1] === 1 ?
            <>
              {nav[navIndex] !== undefined && 0 < nav[navIndex].column[1] ? 
                <div style={items[checkBoxData[0]].checkBox1[0] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox1 : [ !items[checkBoxData[0]].checkBox1[0], items[checkBoxData[0]].checkBox1[1], items[checkBoxData[0]].checkBox1[2], items[checkBoxData[0]].checkBox1[3], items[checkBoxData[0]].checkBox1[4], items[checkBoxData[0]].checkBox1[5], items[checkBoxData[0]].checkBox1[6], items[checkBoxData[0]].checkBox1[7], ]})}
                >{nav[navIndex].column1[0]}</div> : <></>}
              {nav[navIndex] !== undefined && 1 < nav[navIndex].column[1] ? 
                <div style={items[checkBoxData[0]].checkBox1[1] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox1 : [ items[checkBoxData[0]].checkBox1[0], !items[checkBoxData[0]].checkBox1[1], items[checkBoxData[0]].checkBox1[2], items[checkBoxData[0]].checkBox1[3], items[checkBoxData[0]].checkBox1[4], items[checkBoxData[0]].checkBox1[5], items[checkBoxData[0]].checkBox1[6], items[checkBoxData[0]].checkBox1[7], ]})}
                  >{nav[navIndex].column1[1]}</div> : <></>}
              {nav[navIndex] !== undefined && 2 < nav[navIndex].column[1] ? 
                <div style={items[checkBoxData[0]].checkBox1[2] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox1 : [ items[checkBoxData[0]].checkBox1[0], items[checkBoxData[0]].checkBox1[1], !items[checkBoxData[0]].checkBox1[2], items[checkBoxData[0]].checkBox1[3], items[checkBoxData[0]].checkBox1[4], items[checkBoxData[0]].checkBox1[5], items[checkBoxData[0]].checkBox1[6], items[checkBoxData[0]].checkBox1[7], ]})}
                  >{nav[navIndex].column1[2]}</div> : <></>}
              {nav[navIndex] !== undefined && 3 < nav[navIndex].column[1] ? 
                <div style={items[checkBoxData[0]].checkBox1[3] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox1 : [ items[checkBoxData[0]].checkBox1[0], items[checkBoxData[0]].checkBox1[1], items[checkBoxData[0]].checkBox1[2], !items[checkBoxData[0]].checkBox1[3], items[checkBoxData[0]].checkBox1[4], items[checkBoxData[0]].checkBox1[5], items[checkBoxData[0]].checkBox1[6], items[checkBoxData[0]].checkBox1[7], ]})}
                  >{nav[navIndex].column1[3]}</div> : <></>}
              {nav[navIndex] !== undefined && 4 < nav[navIndex].column[1] ? 
                <div style={items[checkBoxData[0]].checkBox1[4] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox1 : [ items[checkBoxData[0]].checkBox1[0], items[checkBoxData[0]].checkBox1[1], items[checkBoxData[0]].checkBox1[2], items[checkBoxData[0]].checkBox1[3], !items[checkBoxData[0]].checkBox1[4], items[checkBoxData[0]].checkBox1[5], items[checkBoxData[0]].checkBox1[6], items[checkBoxData[0]].checkBox1[7], ]})}
                  >{nav[navIndex].column1[4]}</div> : <></>}
              {nav[navIndex] !== undefined && 5 < nav[navIndex].column[1] ? 
                <div style={items[checkBoxData[0]].checkBox1[5] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox1 : [ items[checkBoxData[0]].checkBox1[0], items[checkBoxData[0]].checkBox1[1], items[checkBoxData[0]].checkBox1[2], items[checkBoxData[0]].checkBox1[3], items[checkBoxData[0]].checkBox1[4], !items[checkBoxData[0]].checkBox1[5], items[checkBoxData[0]].checkBox1[6], items[checkBoxData[0]].checkBox1[7], ]})}
                  >{nav[navIndex].column1[5]}</div> : <></>}
              {nav[navIndex] !== undefined && 6 < nav[navIndex].column[1] ? 
                <div style={items[checkBoxData[0]].checkBox1[6] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox1 : [ items[checkBoxData[0]].checkBox1[0], items[checkBoxData[0]].checkBox1[1], items[checkBoxData[0]].checkBox1[2], items[checkBoxData[0]].checkBox1[3], items[checkBoxData[0]].checkBox1[4], items[checkBoxData[0]].checkBox1[5], !items[checkBoxData[0]].checkBox1[6], items[checkBoxData[0]].checkBox1[7], ]})}
                  >{nav[navIndex].column1[6]}</div> : <></>}
              {nav[navIndex] !== undefined && 7 < nav[navIndex].column[1] ? 
                <div style={items[checkBoxData[0]].checkBox1[7] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox1 : [ items[checkBoxData[0]].checkBox1[0], items[checkBoxData[0]].checkBox1[1], items[checkBoxData[0]].checkBox1[2], items[checkBoxData[0]].checkBox1[3], items[checkBoxData[0]].checkBox1[4], items[checkBoxData[0]].checkBox1[5], items[checkBoxData[0]].checkBox1[6], !items[checkBoxData[0]].checkBox0[7], ]})}
                  >{nav[navIndex].column1[7]}</div> : <></>}
            </> : <></>}
            {checkBoxData[1] === 2 ?
            <>
              {nav[navIndex] !== undefined && 0 < nav[navIndex].column[2] ? 
                <div style={items[checkBoxData[0]].checkBox2[0] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox2 : [ !items[checkBoxData[0]].checkBox2[0], items[checkBoxData[0]].checkBox2[1], items[checkBoxData[0]].checkBox2[2], items[checkBoxData[0]].checkBox2[3], items[checkBoxData[0]].checkBox2[4], items[checkBoxData[0]].checkBox2[5], items[checkBoxData[0]].checkBox2[6], items[checkBoxData[0]].checkBox2[7], ]})}
                >{nav[navIndex].column2[0]}</div> : <></>}
              {nav[navIndex] !== undefined && 1 < nav[navIndex].column[2] ? 
                <div style={items[checkBoxData[0]].checkBox2[1] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox2 : [ items[checkBoxData[0]].checkBox2[0], !items[checkBoxData[0]].checkBox2[1], items[checkBoxData[0]].checkBox2[2], items[checkBoxData[0]].checkBox2[3], items[checkBoxData[0]].checkBox2[4], items[checkBoxData[0]].checkBox2[5], items[checkBoxData[0]].checkBox2[6], items[checkBoxData[0]].checkBox2[7], ]})}
                >{nav[navIndex].column2[1]}</div> : <></>}
              {nav[navIndex] !== undefined && 2 < nav[navIndex].column[2] ? 
                <div style={items[checkBoxData[0]].checkBox2[2] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox2 : [ items[checkBoxData[0]].checkBox2[0], items[checkBoxData[0]].checkBox2[1], !items[checkBoxData[0]].checkBox2[2], items[checkBoxData[0]].checkBox2[3], items[checkBoxData[0]].checkBox2[4], items[checkBoxData[0]].checkBox2[5], items[checkBoxData[0]].checkBox2[6], items[checkBoxData[0]].checkBox2[7], ]})}
                >{nav[navIndex].column2[2]}</div> : <></>}
              {nav[navIndex] !== undefined && 3 < nav[navIndex].column[2] ? 
                <div style={items[checkBoxData[0]].checkBox2[3] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox2 : [ items[checkBoxData[0]].checkBox2[0], items[checkBoxData[0]].checkBox2[1], items[checkBoxData[0]].checkBox2[2], !items[checkBoxData[0]].checkBox2[3], items[checkBoxData[0]].checkBox2[4], items[checkBoxData[0]].checkBox2[5], items[checkBoxData[0]].checkBox2[6], items[checkBoxData[0]].checkBox2[7], ]})}
                >{nav[navIndex].column2[3]}</div> : <></>}
              {nav[navIndex] !== undefined && 4 < nav[navIndex].column[2] ? 
                <div style={items[checkBoxData[0]].checkBox2[4] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox2 : [ items[checkBoxData[0]].checkBox2[0], items[checkBoxData[0]].checkBox2[1], items[checkBoxData[0]].checkBox2[2], items[checkBoxData[0]].checkBox2[3], !items[checkBoxData[0]].checkBox2[4], items[checkBoxData[0]].checkBox2[5], items[checkBoxData[0]].checkBox2[6], items[checkBoxData[0]].checkBox2[7], ]})}
                >{nav[navIndex].column2[4]}</div> : <></>}
              {nav[navIndex] !== undefined && 5 < nav[navIndex].column[2] ? 
                <div style={items[checkBoxData[0]].checkBox2[5] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox2 : [ items[checkBoxData[0]].checkBox2[0], items[checkBoxData[0]].checkBox2[1], items[checkBoxData[0]].checkBox2[2], items[checkBoxData[0]].checkBox2[3], items[checkBoxData[0]].checkBox2[4], !items[checkBoxData[0]].checkBox2[5], items[checkBoxData[0]].checkBox2[6], items[checkBoxData[0]].checkBox2[7], ]})}
                >{nav[navIndex].column2[5]}</div> : <></>}
              {nav[navIndex] !== undefined && 6 < nav[navIndex].column[2] ? 
                <div style={items[checkBoxData[0]].checkBox2[6] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox2 : [ items[checkBoxData[0]].checkBox2[0], items[checkBoxData[0]].checkBox2[1], items[checkBoxData[0]].checkBox2[2], items[checkBoxData[0]].checkBox2[3], items[checkBoxData[0]].checkBox2[4], items[checkBoxData[0]].checkBox2[5], !items[checkBoxData[0]].checkBox2[6], items[checkBoxData[0]].checkBox2[7], ]})}
                >{nav[navIndex].column2[6]}</div> : <></>}
              {nav[navIndex] !== undefined && 7 < nav[navIndex].column[2] ? 
                <div style={items[checkBoxData[0]].checkBox2[7] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox2 : [ items[checkBoxData[0]].checkBox2[0], items[checkBoxData[0]].checkBox2[1], items[checkBoxData[0]].checkBox2[2], items[checkBoxData[0]].checkBox2[3], items[checkBoxData[0]].checkBox2[4], items[checkBoxData[0]].checkBox2[5], items[checkBoxData[0]].checkBox2[6], !items[checkBoxData[0]].checkBox2[7], ]})}
                >{nav[navIndex].column2[7]}</div> : <></>}
            </> : <></>}
            {checkBoxData[1] === 3 ?
            <>
              {nav[navIndex] !== undefined && 0 < nav[navIndex].column[3] ? 
                <div style={items[checkBoxData[0]].checkBox3[0] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox3 : [ !items[checkBoxData[0]].checkBox3[0], items[checkBoxData[0]].checkBox3[1], items[checkBoxData[0]].checkBox3[2], items[checkBoxData[0]].checkBox3[3], items[checkBoxData[0]].checkBox3[4], items[checkBoxData[0]].checkBox3[5], items[checkBoxData[0]].checkBox3[6], items[checkBoxData[0]].checkBox3[7], ]})}
                >{nav[navIndex].column3[0]}</div> : <></>}
              {nav[navIndex] !== undefined && 1 < nav[navIndex].column[3] ? 
                <div style={items[checkBoxData[0]].checkBox3[1] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox3 : [ items[checkBoxData[0]].checkBox3[0], !items[checkBoxData[0]].checkBox3[1], items[checkBoxData[0]].checkBox3[2], items[checkBoxData[0]].checkBox3[3], items[checkBoxData[0]].checkBox3[4], items[checkBoxData[0]].checkBox3[5], items[checkBoxData[0]].checkBox3[6], items[checkBoxData[0]].checkBox3[7], ]})}
                >{nav[navIndex].column3[1]}</div> : <></>}
              {nav[navIndex] !== undefined && 2 < nav[navIndex].column[3] ? 
                <div style={items[checkBoxData[0]].checkBox3[2] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox3 : [ items[checkBoxData[0]].checkBox3[0], items[checkBoxData[0]].checkBox3[1], !items[checkBoxData[0]].checkBox3[2], items[checkBoxData[0]].checkBox3[3], items[checkBoxData[0]].checkBox3[4], items[checkBoxData[0]].checkBox3[5], items[checkBoxData[0]].checkBox3[6], items[checkBoxData[0]].checkBox3[7], ]})}
                >{nav[navIndex].column3[2]}</div> : <></>}
              {nav[navIndex] !== undefined && 3 < nav[navIndex].column[3] ? 
                <div style={items[checkBoxData[0]].checkBox3[3] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox3 : [ items[checkBoxData[0]].checkBox3[0], items[checkBoxData[0]].checkBox3[1], items[checkBoxData[0]].checkBox3[2], !items[checkBoxData[0]].checkBox3[3], items[checkBoxData[0]].checkBox3[4], items[checkBoxData[0]].checkBox3[5], items[checkBoxData[0]].checkBox3[6], items[checkBoxData[0]].checkBox3[7], ]})}
                >{nav[navIndex].column3[3]}</div> : <></>}
              {nav[navIndex] !== undefined && 4 < nav[navIndex].column[3] ? 
                <div style={items[checkBoxData[0]].checkBox3[4] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox3 : [ items[checkBoxData[0]].checkBox3[0], items[checkBoxData[0]].checkBox3[1], items[checkBoxData[0]].checkBox3[2], items[checkBoxData[0]].checkBox3[3], !items[checkBoxData[0]].checkBox3[4], items[checkBoxData[0]].checkBox3[5], items[checkBoxData[0]].checkBox3[6], items[checkBoxData[0]].checkBox3[7], ]})}
                >{nav[navIndex].column3[4]}</div> : <></>}
              {nav[navIndex] !== undefined && 5 < nav[navIndex].column[3] ? 
                <div style={items[checkBoxData[0]].checkBox3[5] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox3 : [ items[checkBoxData[0]].checkBox3[0], items[checkBoxData[0]].checkBox3[1], items[checkBoxData[0]].checkBox3[2], items[checkBoxData[0]].checkBox3[3], items[checkBoxData[0]].checkBox3[4], !items[checkBoxData[0]].checkBox3[5], items[checkBoxData[0]].checkBox3[6], items[checkBoxData[0]].checkBox3[7], ]})}
                >{nav[navIndex].column3[5]}</div> : <></>}
              {nav[navIndex] !== undefined && 6 < nav[navIndex].column[3] ? 
                <div style={items[checkBoxData[0]].checkBox3[6] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox3 : [ items[checkBoxData[0]].checkBox3[0], items[checkBoxData[0]].checkBox3[1], items[checkBoxData[0]].checkBox3[2], items[checkBoxData[0]].checkBox3[3], items[checkBoxData[0]].checkBox3[4], items[checkBoxData[0]].checkBox3[5], !items[checkBoxData[0]].checkBox3[6], items[checkBoxData[0]].checkBox3[7], ]})}
                >{nav[navIndex].column3[6]}</div> : <></>}
              {nav[navIndex] !== undefined && 7 < nav[navIndex].column[3] ? 
                <div style={items[checkBoxData[0]].checkBox3[7] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox3 : [ items[checkBoxData[0]].checkBox3[0], items[checkBoxData[0]].checkBox3[1], items[checkBoxData[0]].checkBox3[2], items[checkBoxData[0]].checkBox3[3], items[checkBoxData[0]].checkBox3[4], items[checkBoxData[0]].checkBox3[5], items[checkBoxData[0]].checkBox3[6], !items[checkBoxData[0]].checkBox3[7], ]})}
                >{nav[navIndex].column3[7]}</div> : <></>}
            </> : <></>}
            {checkBoxData[1] === 4 ?
            <>
              {nav[navIndex] !== undefined && 0 < nav[navIndex].column[4] ? 
                <div style={items[checkBoxData[0]].checkBox4[0] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox4 : [ !items[checkBoxData[0]].checkBox4[0], items[checkBoxData[0]].checkBox4[1], items[checkBoxData[0]].checkBox4[2], items[checkBoxData[0]].checkBox4[3], items[checkBoxData[0]].checkBox4[4], items[checkBoxData[0]].checkBox4[5], items[checkBoxData[0]].checkBox4[6], items[checkBoxData[0]].checkBox4[7], ]})}
                >{nav[navIndex].column4[0]}</div> : <></>}
              {nav[navIndex] !== undefined && 1 < nav[navIndex].column[4] ? 
                <div style={items[checkBoxData[0]].checkBox4[1] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox4 : [ items[checkBoxData[0]].checkBox4[0], !items[checkBoxData[0]].checkBox4[1], items[checkBoxData[0]].checkBox4[2], items[checkBoxData[0]].checkBox4[3], items[checkBoxData[0]].checkBox4[4], items[checkBoxData[0]].checkBox4[5], items[checkBoxData[0]].checkBox4[6], items[checkBoxData[0]].checkBox4[7], ]})}
                >{nav[navIndex].column4[1]}</div> : <></>}
              {nav[navIndex] !== undefined && 2 < nav[navIndex].column[4] ? 
                <div style={items[checkBoxData[0]].checkBox4[2] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox4 : [ items[checkBoxData[0]].checkBox4[0], items[checkBoxData[0]].checkBox4[1], !items[checkBoxData[0]].checkBox4[2], items[checkBoxData[0]].checkBox4[3], items[checkBoxData[0]].checkBox4[4], items[checkBoxData[0]].checkBox4[5], items[checkBoxData[0]].checkBox4[6], items[checkBoxData[0]].checkBox4[7], ]})}
                >{nav[navIndex].column4[2]}</div> : <></>}
              {nav[navIndex] !== undefined && 3 < nav[navIndex].column[4] ? 
                <div style={items[checkBoxData[0]].checkBox4[3] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox4 : [ items[checkBoxData[0]].checkBox4[0], items[checkBoxData[0]].checkBox4[1], items[checkBoxData[0]].checkBox4[2], !items[checkBoxData[0]].checkBox4[3], items[checkBoxData[0]].checkBox4[4], items[checkBoxData[0]].checkBox4[5], items[checkBoxData[0]].checkBox4[6], items[checkBoxData[0]].checkBox4[7], ]})}
                >{nav[navIndex].column4[3]}</div> : <></>}
              {nav[navIndex] !== undefined && 4 < nav[navIndex].column[4] ? 
                <div style={items[checkBoxData[0]].checkBox4[4] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox4 : [ items[checkBoxData[0]].checkBox4[0], items[checkBoxData[0]].checkBox4[1], items[checkBoxData[0]].checkBox4[2], items[checkBoxData[0]].checkBox4[3], !items[checkBoxData[0]].checkBox4[4], items[checkBoxData[0]].checkBox4[5], items[checkBoxData[0]].checkBox4[6], items[checkBoxData[0]].checkBox4[7], ]})}
                >{nav[navIndex].column4[4]}</div> : <></>}
              {nav[navIndex] !== undefined && 5 < nav[navIndex].column[4] ? 
                <div style={items[checkBoxData[0]].checkBox4[5] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox4 : [ items[checkBoxData[0]].checkBox4[0], items[checkBoxData[0]].checkBox4[1], items[checkBoxData[0]].checkBox4[2], items[checkBoxData[0]].checkBox4[3], items[checkBoxData[0]].checkBox4[4], !items[checkBoxData[0]].checkBox4[5], items[checkBoxData[0]].checkBox4[6], items[checkBoxData[0]].checkBox4[7], ]})}
                >{nav[navIndex].column4[5]}</div> : <></>}
              {nav[navIndex] !== undefined && 6 < nav[navIndex].column[4] ? 
                <div style={items[checkBoxData[0]].checkBox4[6] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox4 : [ items[checkBoxData[0]].checkBox4[0], items[checkBoxData[0]].checkBox4[1], items[checkBoxData[0]].checkBox4[2], items[checkBoxData[0]].checkBox4[3], items[checkBoxData[0]].checkBox4[4], items[checkBoxData[0]].checkBox4[5], !items[checkBoxData[0]].checkBox4[6], items[checkBoxData[0]].checkBox4[7], ]})}
                >{nav[navIndex].column4[6]}</div> : <></>}
              {nav[navIndex] !== undefined && 7 < nav[navIndex].column[4] ? 
                <div style={items[checkBoxData[0]].checkBox4[7] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox4 : [ items[checkBoxData[0]].checkBox4[0], items[checkBoxData[0]].checkBox4[1], items[checkBoxData[0]].checkBox4[2], items[checkBoxData[0]].checkBox4[3], items[checkBoxData[0]].checkBox4[4], items[checkBoxData[0]].checkBox4[5], items[checkBoxData[0]].checkBox4[6], !items[checkBoxData[0]].checkBox4[7], ]})}
                >{nav[navIndex].column4[7]}</div> : <></>}
            </> : <></>}
            {checkBoxData[1] === 5 ?
            <>
              {nav[navIndex] !== undefined && 0 < nav[navIndex].column[5] ? 
                <div style={items[checkBoxData[0]].checkBox5[0] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox5 : [ !items[checkBoxData[0]].checkBox5[0], items[checkBoxData[0]].checkBox5[1], items[checkBoxData[0]].checkBox5[2], items[checkBoxData[0]].checkBox5[3], items[checkBoxData[0]].checkBox5[4], items[checkBoxData[0]].checkBox5[5], items[checkBoxData[0]].checkBox5[6], items[checkBoxData[0]].checkBox5[7], ]})}
                >{nav[navIndex].column5[0]}</div> : <></>}
              {nav[navIndex] !== undefined && 1 < nav[navIndex].column[5] ? 
                <div style={items[checkBoxData[0]].checkBox5[1] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox5 : [ items[checkBoxData[0]].checkBox5[0], !items[checkBoxData[0]].checkBox5[1], items[checkBoxData[0]].checkBox5[2], items[checkBoxData[0]].checkBox5[3], items[checkBoxData[0]].checkBox5[4], items[checkBoxData[0]].checkBox5[5], items[checkBoxData[0]].checkBox5[6], items[checkBoxData[0]].checkBox5[7], ]})}
                >{nav[navIndex].column5[1]}</div> : <></>}
              {nav[navIndex] !== undefined && 2 < nav[navIndex].column[5] ? 
                <div style={items[checkBoxData[0]].checkBox5[2] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox5 : [ items[checkBoxData[0]].checkBox5[0], items[checkBoxData[0]].checkBox5[1], !items[checkBoxData[0]].checkBox5[2], items[checkBoxData[0]].checkBox5[3], items[checkBoxData[0]].checkBox5[4], items[checkBoxData[0]].checkBox5[5], items[checkBoxData[0]].checkBox5[6], items[checkBoxData[0]].checkBox5[7], ]})}
                >{nav[navIndex].column5[2]}</div> : <></>}
              {nav[navIndex] !== undefined && 3 < nav[navIndex].column[5] ? 
                <div style={items[checkBoxData[0]].checkBox5[3] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox5 : [ items[checkBoxData[0]].checkBox5[0], items[checkBoxData[0]].checkBox5[1], items[checkBoxData[0]].checkBox5[2], !items[checkBoxData[0]].checkBox5[3], items[checkBoxData[0]].checkBox5[4], items[checkBoxData[0]].checkBox5[5], items[checkBoxData[0]].checkBox5[6], items[checkBoxData[0]].checkBox5[7], ]})}
                >{nav[navIndex].column5[3]}</div> : <></>}
              {nav[navIndex] !== undefined && 4 < nav[navIndex].column[5] ? 
                <div style={items[checkBoxData[0]].checkBox5[4] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                    firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox5 : [ items[checkBoxData[0]].checkBox5[0], items[checkBoxData[0]].checkBox5[1], items[checkBoxData[0]].checkBox5[2], items[checkBoxData[0]].checkBox5[3], !items[checkBoxData[0]].checkBox5[4], items[checkBoxData[0]].checkBox5[5], items[checkBoxData[0]].checkBox5[6], items[checkBoxData[0]].checkBox5[7], ]})}
                >{nav[navIndex].column5[4]}</div> : <></>}
              {nav[navIndex] !== undefined && 5 < nav[navIndex].column[5] ? 
                <div style={items[checkBoxData[0]].checkBox5[5] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox5 : [ items[checkBoxData[0]].checkBox5[0], items[checkBoxData[0]].checkBox5[1], items[checkBoxData[0]].checkBox5[2], items[checkBoxData[0]].checkBox5[3], items[checkBoxData[0]].checkBox5[4], !items[checkBoxData[0]].checkBox5[5], items[checkBoxData[0]].checkBox5[6], items[checkBoxData[0]].checkBox5[7], ]})}
              >{nav[navIndex].column5[5]}</div> : <></>}
              {nav[navIndex] !== undefined && 6 < nav[navIndex].column[5] ? 
                <div style={items[checkBoxData[0]].checkBox5[6] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox5 : [ items[checkBoxData[0]].checkBox5[0], items[checkBoxData[0]].checkBox5[1], items[checkBoxData[0]].checkBox5[2], items[checkBoxData[0]].checkBox5[3], items[checkBoxData[0]].checkBox5[4], items[checkBoxData[0]].checkBox5[5], !items[checkBoxData[0]].checkBox5[6], items[checkBoxData[0]].checkBox5[7], ]})}
              >{nav[navIndex].column5[6]}</div> : <></>}
              {nav[navIndex] !== undefined && 7 < nav[navIndex].column[5] ? 
                <div style={items[checkBoxData[0]].checkBox5[7] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox5 : [ items[checkBoxData[0]].checkBox5[0], items[checkBoxData[0]].checkBox5[1], items[checkBoxData[0]].checkBox5[2], items[checkBoxData[0]].checkBox5[3], items[checkBoxData[0]].checkBox5[4], items[checkBoxData[0]].checkBox5[5], items[checkBoxData[0]].checkBox5[6], !items[checkBoxData[0]].checkBox5[7], ]})}
              >{nav[navIndex].column5[7]}</div> : <></>}
            </> : <></>}
            {checkBoxData[1] === 6 ?
            <>
              {nav[navIndex] !== undefined && 0 < nav[navIndex].column[6] ? 
                <div style={items[checkBoxData[0]].checkBox6[0] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox6 : [ !items[checkBoxData[0]].checkBox6[0], items[checkBoxData[0]].checkBox6[1], items[checkBoxData[0]].checkBox6[2], items[checkBoxData[0]].checkBox6[3], items[checkBoxData[0]].checkBox6[4], items[checkBoxData[0]].checkBox6[5], items[checkBoxData[0]].checkBox6[6], items[checkBoxData[0]].checkBox6[7], ]})}
              >{nav[navIndex].column6[0]}</div> : <></>}
              {nav[navIndex] !== undefined && 1 < nav[navIndex].column[6] ? 
                <div style={items[checkBoxData[0]].checkBox6[1] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox6 : [ items[checkBoxData[0]].checkBox6[0], !items[checkBoxData[0]].checkBox6[1], items[checkBoxData[0]].checkBox6[2], items[checkBoxData[0]].checkBox6[3], items[checkBoxData[0]].checkBox6[4], items[checkBoxData[0]].checkBox6[5], items[checkBoxData[0]].checkBox6[6], items[checkBoxData[0]].checkBox6[7], ]})}
              >{nav[navIndex].column6[1]}</div> : <></>}
              {nav[navIndex] !== undefined && 2 < nav[navIndex].column[6] ? 
                <div style={items[checkBoxData[0]].checkBox6[2] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox6 : [ items[checkBoxData[0]].checkBox6[0], items[checkBoxData[0]].checkBox6[1], !items[checkBoxData[0]].checkBox6[2], items[checkBoxData[0]].checkBox6[3], items[checkBoxData[0]].checkBox6[4], items[checkBoxData[0]].checkBox6[5], items[checkBoxData[0]].checkBox6[6], items[checkBoxData[0]].checkBox6[7], ]})}
              >{nav[navIndex].column6[2]}</div> : <></>}
              {nav[navIndex] !== undefined && 3 < nav[navIndex].column[6] ? 
                <div style={items[checkBoxData[0]].checkBox6[3] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox6 : [ items[checkBoxData[0]].checkBox6[0], items[checkBoxData[0]].checkBox6[1], items[checkBoxData[0]].checkBox6[2], !items[checkBoxData[0]].checkBox6[3], items[checkBoxData[0]].checkBox6[4], items[checkBoxData[0]].checkBox6[5], items[checkBoxData[0]].checkBox6[6], items[checkBoxData[0]].checkBox6[7], ]})}
              >{nav[navIndex].column6[3]}</div> : <></>}
              {nav[navIndex] !== undefined && 4 < nav[navIndex].column[6] ? 
                <div style={items[checkBoxData[0]].checkBox6[4] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox6 : [ items[checkBoxData[0]].checkBox6[0], items[checkBoxData[0]].checkBox6[1], items[checkBoxData[0]].checkBox6[2], items[checkBoxData[0]].checkBox6[3], !items[checkBoxData[0]].checkBox6[4], items[checkBoxData[0]].checkBox6[5], items[checkBoxData[0]].checkBox6[6], items[checkBoxData[0]].checkBox6[7], ]})}
              >{nav[navIndex].column6[4]}</div> : <></>}
              {nav[navIndex] !== undefined && 5 < nav[navIndex].column[6] ? 
                <div style={items[checkBoxData[0]].checkBox6[5] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox6 : [ items[checkBoxData[0]].checkBox6[0], items[checkBoxData[0]].checkBox6[1], items[checkBoxData[0]].checkBox6[2], items[checkBoxData[0]].checkBox6[3], items[checkBoxData[0]].checkBox6[4], !items[checkBoxData[0]].checkBox6[5], items[checkBoxData[0]].checkBox6[6], items[checkBoxData[0]].checkBox6[7], ]})}
              >{nav[navIndex].column6[5]}</div> : <></>}
              {nav[navIndex] !== undefined && 6 < nav[navIndex].column[6] ? 
                <div style={items[checkBoxData[0]].checkBox6[6] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox6 : [ items[checkBoxData[0]].checkBox6[0], items[checkBoxData[0]].checkBox6[1], items[checkBoxData[0]].checkBox6[2], items[checkBoxData[0]].checkBox6[3], items[checkBoxData[0]].checkBox6[4], items[checkBoxData[0]].checkBox6[5], !items[checkBoxData[0]].checkBox6[6], items[checkBoxData[0]].checkBox6[7], ]})}
              >{nav[navIndex].column6[6]}</div> : <></>}
              {nav[navIndex] !== undefined && 7 < nav[navIndex].column[6] ? 
                <div style={items[checkBoxData[0]].checkBox6[7] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox6 : [ items[checkBoxData[0]].checkBox6[0], items[checkBoxData[0]].checkBox6[1], items[checkBoxData[0]].checkBox6[2], items[checkBoxData[0]].checkBox6[3], items[checkBoxData[0]].checkBox6[4], items[checkBoxData[0]].checkBox6[5], items[checkBoxData[0]].checkBox6[6], !items[checkBoxData[0]].checkBox6[7], ]})}
              >{nav[navIndex].column6[7]}</div> : <></>}
            </> : <></>}
            {checkBoxData[1] === 7 ?
            <>
              {nav[navIndex] !== undefined && 0 < nav[navIndex].column[7] ? 
                <div style={items[checkBoxData[0]].checkBox7[0] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox7 : [ !items[checkBoxData[0]].checkBox7[0], items[checkBoxData[0]].checkBox7[1], items[checkBoxData[0]].checkBox7[2], items[checkBoxData[0]].checkBox7[3], items[checkBoxData[0]].checkBox7[4], items[checkBoxData[0]].checkBox7[5], items[checkBoxData[0]].checkBox7[6], items[checkBoxData[0]].checkBox7[7], ]})}
              >{nav[navIndex].column7[0]}</div> : <></>}
              {nav[navIndex] !== undefined && 1 < nav[navIndex].column[7] ? 
                <div style={items[checkBoxData[0]].checkBox7[1] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox7 : [ items[checkBoxData[0]].checkBox7[0], !items[checkBoxData[0]].checkBox7[1], items[checkBoxData[0]].checkBox7[2], items[checkBoxData[0]].checkBox7[3], items[checkBoxData[0]].checkBox7[4], items[checkBoxData[0]].checkBox7[5], items[checkBoxData[0]].checkBox7[6], items[checkBoxData[0]].checkBox7[7], ]})}
              >{nav[navIndex].column7[1]}</div> : <></>}
              {nav[navIndex] !== undefined && 2 < nav[navIndex].column[7] ? 
                <div style={items[checkBoxData[0]].checkBox7[2] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox7 : [ items[checkBoxData[0]].checkBox7[0], items[checkBoxData[0]].checkBox7[1], !items[checkBoxData[0]].checkBox7[2], items[checkBoxData[0]].checkBox7[3], items[checkBoxData[0]].checkBox7[4], items[checkBoxData[0]].checkBox7[5], items[checkBoxData[0]].checkBox7[6], items[checkBoxData[0]].checkBox7[7], ]})}
              >{nav[navIndex].column7[2]}</div> : <></>}
              {nav[navIndex] !== undefined && 3 < nav[navIndex].column[7] ? 
                <div style={items[checkBoxData[0]].checkBox7[3] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox7 : [ items[checkBoxData[0]].checkBox7[0], items[checkBoxData[0]].checkBox7[1], items[checkBoxData[0]].checkBox7[2], !items[checkBoxData[0]].checkBox7[3], items[checkBoxData[0]].checkBox7[4], items[checkBoxData[0]].checkBox7[5], items[checkBoxData[0]].checkBox7[6], items[checkBoxData[0]].checkBox7[7], ]})}
              >{nav[navIndex].column7[3]}</div> : <></>}
              {nav[navIndex] !== undefined && 4 < nav[navIndex].column[7] ? 
                <div style={items[checkBoxData[0]].checkBox7[4] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox7 : [ items[checkBoxData[0]].checkBox7[0], items[checkBoxData[0]].checkBox7[1], items[checkBoxData[0]].checkBox7[2], items[checkBoxData[0]].checkBox7[3], !items[checkBoxData[0]].checkBox7[4], items[checkBoxData[0]].checkBox7[5], items[checkBoxData[0]].checkBox7[6], items[checkBoxData[0]].checkBox7[7], ]})}
              >{nav[navIndex].column7[4]}</div> : <></>}
              {nav[navIndex] !== undefined && 5 < nav[navIndex].column[7] ? 
                <div style={items[checkBoxData[0]].checkBox7[5] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox7 : [ items[checkBoxData[0]].checkBox7[0], items[checkBoxData[0]].checkBox7[1], items[checkBoxData[0]].checkBox7[2], items[checkBoxData[0]].checkBox7[3], items[checkBoxData[0]].checkBox7[4], !items[checkBoxData[0]].checkBox7[5], items[checkBoxData[0]].checkBox7[6], items[checkBoxData[0]].checkBox7[7], ]})}
              >{nav[navIndex].column7[5]}</div> : <></>}
              {nav[navIndex] !== undefined && 6 < nav[navIndex].column[7] ? 
                <div style={items[checkBoxData[0]].checkBox7[6] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox7 : [ items[checkBoxData[0]].checkBox7[0], items[checkBoxData[0]].checkBox7[1], items[checkBoxData[0]].checkBox7[2], items[checkBoxData[0]].checkBox7[3], items[checkBoxData[0]].checkBox7[4], items[checkBoxData[0]].checkBox7[5], !items[checkBoxData[0]].checkBox7[6], items[checkBoxData[0]].checkBox7[7], ]})}
              >{nav[navIndex].column7[6]}</div> : <></>}
              {nav[navIndex] !== undefined && 7 < nav[navIndex].column[7] ? 
                <div style={items[checkBoxData[0]].checkBox7[7] ? { backgroundColor : '#5AC47D' } : { backgroundColor : '#D1505F' }}
                  onClick={() => 
                  firebase.firestore().collection('tasks').doc(checkBoxData[2]).update({ checkBox7 : [ items[checkBoxData[0]].checkBox7[0], items[checkBoxData[0]].checkBox7[1], items[checkBoxData[0]].checkBox7[2], items[checkBoxData[0]].checkBox7[3], items[checkBoxData[0]].checkBox7[4], items[checkBoxData[0]].checkBox7[5], items[checkBoxData[0]].checkBox7[6], !items[checkBoxData[0]].checkBox7[7], ]})}
              >{nav[navIndex].column7[7]}</div> : <></>}
            </> : <></>}
          </div> : <></>}
        </div>
      </>}
    </div>
    }

    {addPrev !== 1000 ?
      <Add nav={nav} setAddPrev={setAddPrev} />
    : <></> }

    {/* メモ */}
    {createInfo !== 1000 ?
      <Create items={items} nav={nav} createInfo={createInfo} setCreateInfo={setCreateInfo} />
    : <></> }

    </>
  );
};

export default Help;
  
// function makeshoukibo(v) {
//   firebase.firestore().collection('subsidy').doc(v)
//   .set({
//     check : [ false, false, false, false, false, false, false, false, false, ],
//     gbizRequest : 'いいえ',

//     gbizMailAddress : '',
//     gbizPassWord : '',

//     ceoName : [ '', '', ],
//     ceoBirthday : [ '', '', '', ],
//     ceoNumber : '',

//     companyType : '',
//     companyName : [ '', '' ],
//     companyCapital : '',
//     companyMake : [ '', '', '', ],
//     companyEmployee : '',
//     companyAddress : [ '', '' ],
//     companyNumber : '',
//     companyFax : '',
//     companyWebPage : '',

//     companyInvoice : '',
//     companyStartUp : '',
//     companyResult : '',
//     companyReport : '',
//     companyTime : '',

//     subsidyType : '',

//     FilePath1 : false,
//     FilePath2 : false,
//     FilePath3 : false,
//     FilePath4 : false,
//     FilePath5 : false,
//     FilePath6 : false,
//     FilePath7 : false,
//     FilePath8 : false,
//     FilePath9 : false,
//     FilePath10 : false,
//     FilePath11 : false,
//     FilePath12 : false,
//     FilePath13 : false,
//     FilePath14 : false,
//     FilePath15 : false,
//     FilePath16 : false,

//     hearingCost : '',
//     hearingHistory : '2010年　代表の○○という経験を活かして「地元を活性したい」という思いで飲食店を始めました。\n2015年　○○県が主催する○○コンテストに出品し、対象を受賞しました。\n2019年　コロナが蔓延してしまったため、テイクアウトサービスを実施しました。\n2022年　新メニューの開発を行い、新たに△△の販売を始めました。',
//     hearingCustomer : '当社の顧客は,ファミリー層に多くご来店いただけております。\n昼単価：○○円、顧客数/日：○○人\n昼単価：○○円、顧客数/日：○○人',
//     hearingStrong : '当社の強みは、２つあります。\n・従業員の教育、技術が優れている点\n代表が10年以上の飲食店経験を活かして教育を行っており、お客様から非常に好評をいただいております。\n実際にグルナビでは、☆4の高評価を得ることが出来ています。\n・〇〇駅徒歩3分と、立地が優れている点\n△△県の○○駅から非常にアクセスが良いため、地元の人のみならず、観光客にも訪れてもらっています。',
//     hearingWeak : '当社の弱みは、２つあります。\n・web上の宣伝が弱い\n現在、立地の良さを活かした集客を行なっておりますが、HPを作成していないため、ネット上での集客が行えておりません。\n・厨房設備が古い点\n10年前に購入した設備を使っているため、毎日のメンテナンスが必要・設備を稼働させるまでに時間がかかるといった課題が存在し、無駄な時間がかかります。',
//     hearingInvestment : '事業に要する費用を項目別に記載してください。\n・○○費用　△△円\n現在○○という課題が存在するため、この費用を用いて△△することで、課題解決を行います。\n(例)HP作成費用　75万円\n現在は立地の良さを活かした集客のみですが、HPを作成することでオンライン上の顧客獲得を行います。\n・○○費用　△△円',

//     time : new Date(),
//   });
// }