import './styles.css';
import Nav from './../nav/index';
import Header from './header/index';
import Type from './result/index';
import CrudeOil from './type/crudeOil';
import Growth from './type/growth';
import Shrink from './type/shrink';
import Green from './type/green';
// import Expenses from './type/expenses';
import Doc from './type/doc';
import Footer from './../footer/index';
import Contact from './contact/index';
import Strong from './strong/index';
import Blog from './type/blog';
import { useState, useEffect } from 'react';

const SimulatorResult = () => {
  
  const [ result1, setResult1 ] = useState(false); // 物価高騰
  const [ result2, setResult2 ] = useState(false); // 成長枠
  const [ result3, setResult3 ] = useState(false); // 産業構造転換枠
  const [ result4, setResult4 ] = useState(false); // グリーン成長枠

  const [ people, setPeople ] = useState(0); // 従業員数
  const [ invest, setInvest ] = useState(0); // 投資金額

  useEffect(() => {

    window.scrollTo(0, 0);

    if (new URL(window.location).href.slice(new URL(window.location).href.indexOf('data=') + 5, new URL(window.location).href.indexOf('data=') + 6) === 'T') { setResult1(true); };
    if (new URL(window.location).href.slice(new URL(window.location).href.indexOf('data=') + 7, new URL(window.location).href.indexOf('data=') + 8) === 'T') { setResult2(true); };
    if (new URL(window.location).href.slice(new URL(window.location).href.indexOf('data=') + 9, new URL(window.location).href.indexOf('data=') + 10) === 'T') { setResult3(true); };
    if (new URL(window.location).href.slice(new URL(window.location).href.indexOf('data=') + 11, new URL(window.location).href.indexOf('data=') + 12) === 'T') { setResult4(true); };

    setPeople(parseInt( new URL(window.location).href.slice(50, 51) ));
    setInvest(parseInt( new URL(window.location).href.slice(52, 53) ));

  }, []);


  return(
  <>
  <Nav />
  <Header />
  <Type result1={result1} result2={result2} result3={result3} result4={result4} />
  <Strong />
  <div style={{backgroundColor : '#F8F6F7', paddingBottom : '50px'}}>
    {result1 ? <CrudeOil people={people} invest={invest} /> : <></>}
    {result2 ? <Growth people={people} invest={invest} /> : <></>}
    {result3 ? <Shrink people={people} invest={invest} /> : <></>}
    {result4 ? <Green people={people} invest={invest} /> : <></>}
  </div>
  {/* <Expenses /> */}
  {/* <Rule /> */}
  <Doc />
  {/* <Contact /> */}
  <Blog />
  <Contact />
  <Footer />
  </>
  );
}

export default SimulatorResult;
