import './styles.css';
import Photo1 from './../../image/masato/ 1.jpeg';
import Photo2 from './../../image/masato/ 2.jpeg';
import Photo3 from './../../image/masato/ 3.jpeg';
import Photo4 from './../../image/masato/ 4.jpeg';
import Photo5 from './../../image/masato/ 5.jpeg';
import Photo6 from './../../image/masato/ 6.jpeg';
import Photo7 from './../../image/masato/ 7.jpeg';
import Photo8 from './../../image/masato/ 8.jpeg';
import Photo9 from './../../image/masato/ 9.jpeg';
import Photo10 from './../../image/masato/ 10.jpeg';
import Photo11 from './../../image/masato/ 11.jpeg';
import Photo12 from './../../image/masato/ 12.jpeg';
import Photo13 from './../../image/masato/ 13.jpeg';
import Photo14 from './../../image/masato/ 14.jpeg';
import Photo15 from './../../image/masato/ 15.jpeg';
import Photo16 from './../../image/masato/ 16.jpeg';
import Photo17 from './../../image/masato/ 17.jpeg';
import Photo18 from './../../image/masato/ 18.jpeg';
import Photo19 from './../../image/masato/ 19.jpeg';
import Photo20 from './../../image/masato/ 20.jpeg';
import Photo21 from './../../image/masato/ 21.jpeg';
import Photo22 from './../../image/masato/ 22.jpeg';
import Photo23 from './../../image/masato/ 23.jpeg';
import Photo24 from './../../image/masato/ 24.jpeg';
import Photo25 from './../../image/masato/ 25.jpeg';
import Photo26 from './../../image/masato/ 26.jpeg';
import Photo27 from './../../image/masato/ 27.jpeg';
import Photo28 from './../../image/masato/ 28.jpeg';
import Photo29 from './../../image/masato/ 29.jpeg';
import Photo30 from './../../image/masato/ 30.jpeg';
import Photo31 from './../../image/masato/ 31.jpeg';
import Photo32 from './../../image/masato/ 32.jpeg';
import Photo33 from './../../image/masato/ 33.jpeg';
import Photo34 from './../../image/masato/ 34.jpeg';
import Photo35 from './../../image/masato/ 35.jpeg';
import Photo36 from './../../image/masato/ 36.jpeg';
import Photo37 from './../../image/masato/ 37.jpeg';
import Photo38 from './../../image/masato/ 38.jpeg';
import Photo39 from './../../image/masato/ 39.jpeg';
import Photo40 from './../../image/masato/ 40.jpeg';
import Photo41 from './../../image/masato/ 41.jpeg';
import Photo42 from './../../image/masato/ 42.jpeg';
import Photo43 from './../../image/masato/ 43.jpeg';
import Photo44 from './../../image/masato/ 44.jpeg';
import Photo45 from './../../image/masato/ 45.jpeg';
import Photo46 from './../../image/masato/ 46.jpeg';
import Photo47 from './../../image/masato/ 47.jpeg';
import Photo48 from './../../image/masato/ 48.jpeg';
import Photo49 from './../../image/masato/ 49.jpeg';
import Photo50 from './../../image/masato/ 50.jpeg';
import Photo51 from './../../image/masato/ 51.jpeg';
import Photo52 from './../../image/masato/ 52.jpeg';
import Photo53 from './../../image/masato/ 53.jpeg';
import Photo54 from './../../image/masato/ 54.jpeg';
import Photo55 from './../../image/masato/ 55.jpeg';
import Photo56 from './../../image/masato/ 56.jpeg';
import Photo57 from './../../image/masato/ 57.jpeg';
import Photo58 from './../../image/masato/ 58.jpeg';
import Photo59 from './../../image/masato/ 59.jpeg';
import Photo60 from './../../image/masato/ 60.jpeg';
import Photo61 from './../../image/masato/ 61.jpeg';
import Photo62 from './../../image/masato/ 62.jpeg';
import Photo63 from './../../image/masato/ 63.jpeg';
import Photo64 from './../../image/masato/ 64.jpeg';
import Photo65 from './../../image/masato/ 65.jpeg';
import Photo66 from './../../image/masato/ 66.jpeg';
import Photo67 from './../../image/masato/ 67.jpeg';
import Photo68 from './../../image/masato/ 68.jpeg';
import Photo69 from './../../image/masato/ 69.jpeg';
import Photo70 from './../../image/masato/ 70.jpeg';
import Photo71 from './../../image/masato/ 71.jpeg';
import Photo72 from './../../image/masato/ 72.jpeg';
import Photo73 from './../../image/masato/ 73.jpeg';
import Photo74 from './../../image/masato/ 74.jpeg';
import Photo75 from './../../image/masato/ 75.jpeg';
import Photo76 from './../../image/masato/ 76.jpeg';
import Photo77 from './../../image/masato/ 77.jpeg';
import Photo78 from './../../image/masato/ 78.jpeg';
import Photo79 from './../../image/masato/ 79.jpeg';
import Photo80 from './../../image/masato/ 80.jpeg';
import Photo81 from './../../image/masato/ 81.jpeg';
import Photo82 from './../../image/masato/ 82.jpeg';
import Photo83 from './../../image/masato/ 83.jpeg';
import Photo84 from './../../image/masato/ 84.jpeg';
import Photo85 from './../../image/masato/ 85.jpeg';

const Home = () => {

  return (
    <>
    <div className='masato_nav'>
      <div className='masato_nav_text'>
        <h2>MASATO HIRAYAMA</h2>
        <a href='https://trankllc.com/contact'>
          <h3>contact</h3>
        </a>
      </div>
      <div className='masato_nav_line'></div>
      <div class="grid">
        <div class="grid-item">
          <img src={Photo1} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo2} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo3} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo4} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo5} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo6} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo7} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo8} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo9} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo10} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo11} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo12} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo13} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo14} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo15} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo16} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo17} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo18} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo19} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo20} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo21} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo22} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo23} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo24} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo25} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo26} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo27} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo28} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo29} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo30} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo31} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo32} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo33} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo34} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo35} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo36} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo37} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo38} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo39} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo40} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo41} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo42} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo43} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo44} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo45} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo46} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo47} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo48} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo49} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo50} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo51} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo52} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo53} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo54} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo55} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo56} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo57} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo58} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo59} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo60} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo61} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo62} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo63} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo64} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo65} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo66} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo67} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo68} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo69} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo70} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo71} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo72} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo73} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo74} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo75} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo76} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo77} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo78} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo79} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo80} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo81} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo82} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo83} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo84} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
        <div class="grid-item">
          <img src={Photo85} alt='平山 雅人,masato,hirayama,トランク合同会社' />
        </div>
      </div>
    </div>
    </>
  );
}

export default Home;