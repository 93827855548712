import './styles.css';
import comment from './../../../../image/help/comment.png';
import Service4_1 from './../../../../image/help/service4_1.png';
import Service4 from './../../../../image/help/service4.png';

const Help = () => {
  
  return (
    <>
    <div className='help_service_title'>
      <div className='help_service_title_left'>
        <h6>4</h6>
        <h3>補助金受給までの対応範囲</h3>
      </div>
      <div className='help_service_title_right'>
        <img src={Service4_1} alt='' />
        <div>
          <img src={comment} alt='' />
          <p>補助金から申請、受給までさまざまな工程が多いため、サポートしていない業者が多いですが、弊社では補助金の受給までフルサポートいたします。</p>
        </div>
      </div>
    </div>
    <p className='help_service_title_text'>補助金から申請、受給までさまざまな工程が多いため、サポートしていない業者が多いですが、弊社では補助金の受給までフルサポートいたします。</p>
    <div className='help_service1_img'>
      <img src={Service4} alt='' />
    </div>
    </>
  );
}

export default Help;
