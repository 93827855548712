import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index.js";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/manufacturing/3/top.png";
import Footer from './../../../../tabs/footer/index.js';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const BlogText0001 = () => {

  const meta = {
    title: '【初めての補助金】ものづくり補助金の対象経費を徹底解説',
    description: '本記事は、ものづくり補助金を申請したいけど、「どの経費が申請できるのか」が、全くわからないという方へ向けて執筆させていただきました。公募要領や他の記事を読んだけど、「やっぱり難しい」という方は、ぜひ最後まで目を通していただければと思います。加えて、本記事は公募要領の引用と説明を交互に挟んで解説しておりますので、ぜひ本記事をご一読いただいたのちに公募要領の確認も行っていただけるとより理解が深まるかと思います。加えて、本記事は公募要領を基盤として、執筆しておりますので、本記事をご一読いただいたのちに改めて公募要領を確認することをおすすめします！',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2022/07/21</p>
          <h1 className="media_title">【初めての補助金】<br />ものづくり補助金の対象経費を徹底解説</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <p className="media_text_text">
            <br />
            <br />
            <span className='media_text_text_box_title'>こんな方へ向けて</span><br />
            <br />
            本記事は、ものづくり補助金を申請したいけど、「どの経費が申請できるのか」が、全くわからないという方へ向けて執筆させていただきました。<br />
            <br />
            公募要領や他の記事を読んだけど、「やっぱり難しい」という方は、ぜひ最後まで目を通していただければと思います。<br />
            <br />
            加えて、本記事は公募要領の引用と説明を交互に挟んで解説しておりますので、ぜひ本記事をご一読いただいたのちに公募要領の確認も行っていただけるとより理解が深まるかと思います。<br />
            加えて、本記事は公募要領を基盤として、執筆しておりますので、本記事をご一読いただいたのちに改めて公募要領を確認することをおすすめします！<br />
            <br />
            それでは、解説に進ませていただきます！<br />
          </p>
          
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">ものづくり補助金の対象経費</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">機械装置等費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">技術導入費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">専門家経費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">運搬費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">クラウドサービス利用費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">原材料費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">外注費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">知的財産権等関連経費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">海外旅費</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#1" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>

          <>
          <div id='1' className="media_text_title">
            <h2>１.ものづくり補助金の対象経費</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            ものづくり補助金は、さまざまな業種が申請できる補助金となっている分、どこまでが補助対象なのかを理解しきれていない方が多いのではないでしょうか。<br />
            <br />
            そこで、補助対象になる条件をみていただいたのちに補助対象の項目について深く説明させていただければと思います。<br />
            <br />
          </p>
          <div className='media_text_text_quote'>
            対象経費は、原則、交付決定を受けた日付以降に契約（発注）を行い、補助事業実施期間内に支払いを完了したものとなります。ただし、事務局から事前着手の承認を受けた場合には、令和３年12月20日以降に発生した経費についても補助対象とすることが可能です。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span><br />
            </a>
          </div>
          <p className="media_text_text">
            <br />
            基本的に、対象経費は採択を受けた次の日以降に発注手続きを行なったものが対象となりますので、採択発表以前に購入してしまった経費等は対象外となります。<br />
            <br />
            しかし、上記に記載されているように例外があり、事前着手手続きを行なった事業者さまは採択発表前に購入したものを経費にすることができます。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>機械装置等費</h3><br />
          <div className='media_text_text_quote'>
            ① 専ら補助事業のために使用される機械・装置、工具・器具（測定工具・検査工具、電子計算機、デジタル複合機等）の購入、製作、借用に要する経費<br />
            ② 専ら補助事業のために使用される専用ソフトウェア・情報システムの購入・構築、借用に要する経費<br />
            ③ ①若しくは②と一体で行う、改良・修繕又は据付けに要する経費<br />
          </div>
          <p className="media_text_text">
            <br />
            事業推進に必要な機械・装置、工具・器具の購入やソフトウェア・情報システム導入、構築に使用される必要になります。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>技術導入費</h3><br />
          <div className='media_text_text_quote'>
            本事業の実施に必要な知的財産権等の導入に要する経費<br />
          </div>
          <p className="media_text_text">
            <br />
            知的財産権等の取得にかかる必要を計上できるものとなっております。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>専門家経費</h3><br />
          <div className='media_text_text_quote'>
            本事業の実施のために依頼した専門家に支払われる経費<br />
          </div>
          <p className="media_text_text">
            <br />
            専門家に支払われる経費を計上できるものとなっております。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>運搬費</h3><br />
          <div className='media_text_text_quote'>
            運搬料、宅配・郵送料等に要する経費<br />
          </div>
          <p className="media_text_text">
            <br />
            運搬料、宅配・郵送料等にかかる経費を計上できるものとなっております。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>クラウドサービス利用費</h3><br />
          <div className='media_text_text_quote'>
            クラウドサービスの利用に関する経費<br />
          </div>
          <p className="media_text_text">
            <br />
            クラウドサービスの利用に関する経費を計上できるものとなっております。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>原材料費</h3><br />
          <div className='media_text_text_quote'>
            試作品の開発に必要な原材料及び副資材の購入に要する経費<br />
          </div>
          <p className="media_text_text">
            <br />
            試作品の開発にかかる経費、及びその原価料にかかる経費を計上できるものとなっております。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>外注費</h3><br />
          <div className='media_text_text_quote'>
            新製品・サービスの開発に必要な加工や設計（デザイン）・検査等の一部を外注（請負、委託等）する場合の経費<br />
          </div>
          <p className="media_text_text">
            <br />
            新製品・サービスの開発に必要な加工や設計、検査の一部を外注する場合の経費を計上できるものとなっております。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>知的財産権等関連経費</h3><br />
          <div className='media_text_text_quote'>
            新製品・サービスの事業化に当たって必要となる特許権等の知的財産権等の取得に要する弁理士の手続代行費用、外国特許出願のための翻訳料等の知的財産権等取得に関連する経費<br />
          </div>
          <p className="media_text_text">
            <br />
            事業推進にあたって、必要となる特許等の知的財産権の取得にかかる費用を計上できるものとなっております。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>海外旅費</h3><br />
          <div className='media_text_text_quote'>
            海外事業の拡大・強化等を目的とした、本事業に必要不可欠な海外渡航及び宿泊等に要する経費<br />
          </div>
          <p className="media_text_text">
            <br />
            事業推進に必要な海外渡航及び宿泊にかかる費用を計上できるものとなっております。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>２.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            本記事では、ものづくり補助金を申請したい事業者さまへ向けて、ものづくり補助金の概要について記事にさせていただきました。<br />
            <br />
            募集要項を各所に添付させていただきましたが、ご覧いただけば分かる通り難しい内容となっております。<br />
            そこで、もし、本格的にものづくり補助金の導入を検討されていましたら、弊社含め補助金に詳しい事業者へご相談ください。<br />
            <br />
            ご相談は、画面右上の補助金に関する相談をするから問い合わせにて、お待ちしております。<br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
        
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;