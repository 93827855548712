import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index.js";
import TopImage from "./../../../../../image/media/small/3/top.png";
import Footer from './../../../../tabs/footer/index.js';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const BlogText0001 = () => {

  const meta = {
    title: '【初めての補助金】小規模事業者持続化補助金の対象経費を徹底解説',
    description: '本記事は、小規模事業者持続化補助金を申請したいけど、「どの経費が申請できるのか」が、全くわからないという方へ向けて執筆させていただきました。公募要領や他の記事を読んだけど、「やっぱり難しい」という方は、ぜひ最後まで目を通していただければと思います。加えて、本記事は公募要領の引用と説明を交互に挟んで解説しておりますので、ぜひ本記事をご一読いただいたのちに公募要領の確認も行っていただけるとより理解が深まるかと思います。加えて、本記事は公募要領を基盤として、執筆しておりますので、本記事をご一読いただいたのちに改めて公募要領を確認することをおすすめします！',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2022/07/21</p>
          <h1 className="media_title">【初めての補助金】<br />小規模事業者持続化補助金の対象経費を徹底解説</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <p className="media_text_text">
            <br />
            <br />
            <span className='media_text_text_box_title'>こんな方へ向けて</span><br />
            <br />
            本記事は、小規模事業者持続化補助金を申請したいけど、「どの経費が申請できるのか」が、全くわからないという方へ向けて執筆させていただきました。<br />
            <br />
            公募要領や他の記事を読んだけど、「やっぱり難しい」という方は、ぜひ最後まで目を通していただければと思います。<br />
            <br />
            加えて、本記事は公募要領の引用と説明を交互に挟んで解説しておりますので、ぜひ本記事をご一読いただいたのちに公募要領の確認も行っていただけるとより理解が深まるかと思います。<br />
            加えて、本記事は公募要領を基盤として、執筆しておりますので、本記事をご一読いただいたのちに改めて公募要領を確認することをおすすめします！<br />
            <br />
            それでは、解説に進ませていただきます！<br />
          </p>

          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">小規模事業者持続化補助金の対象経費</AnchorLink></li>
                <li><AnchorLink href="#1" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>

          <>
          <div id='1' className="media_text_title">
            <h2>１.小規模事業者持続化補助金の対象経費</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            小規模事業者持続化補助金は、さまざまな業種が申請できる補助金となっている分、どこまでが補助対象なのかを理解しきれていない方が多いのではないでしょうか。<br />
            <br />
            そこで、補助対象になる条件をみていただいたのちに補助対象の項目について深く説明させていただければと思います。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>機械装置等費</h3><br />
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>公募要領</span><br />
            ①通常の事業活動のための設備投資の費用、単なる取替え更新の機械装置等の購入は補助対象となりません。<br />
            ②契約期間が補助事業期間を越えるソフトウェア使用権を購入する場合は、按分等の方式により算出された補助事業期間分のみ補助対象となります。<br />
            ③単価５０万円（税抜き）以上の機械装置等の購入は「処分制限財産」に該当し、補助事業が終了し、補助金の支払を受けた後であっても、一定の期間において処分（補助事業目的外での使用、譲渡、担保提供、廃棄等）が制限されます。<br />
            処分制限期間内に当該財産を処分する場合には、必ず補助金事務局等へ承認を申請し、承認を受けた後でなければ処分できません。補助金事務局等は、財産処分を承認した補助事業者に対し、当該承認に際し、残存簿価等から算出される金額の返還のため、交付した補助金の全部または一部に相当する金額を納付させることがあります。承認を得ずに処分を行うと、交付規程違反により補助金交付取消・返還命令（加算金付き）の対象となります。<br />
            なお、1 件あたり１００万円（税込）超の機械装置等の購入をする場合、２社以上からの見積りが必要です。<br />
            ④中古品の購入は、下記の条件を満たした場合のみ、補助対象経費として認めます。<br />
            （ア）購入単価が５０万円（税抜き）未満のものであること<br />
            ※単価が５０万円（税抜き）以上の中古品を単価５０万円（税抜き）未満になるように分割して購入する場合は、その中古品全体が補助対象外となります。<br />
            （イ） 中古品の購入にあたっては２社以上の中古品販売事業者（個人からの購入や、オークション（インターネットオークションを含みます）による購入は不可）から同等品について見積（見積書、価格表等）を取得することが必要となります。<br />
            ※中古品購入の場合は、購入金額に関わらず、すべて、2 者以上からの見積りが必要です。<br />
            ※実績報告書の提出時に、これら複数の見積書を必ず添付してください。（理由書の提出による随意契約での購入は、中古品の場合は、補助対象経費として認められません）
            （ウ） 修理費用は、補助対象経費として認められません。また、購入品の故障や不具合等により補助事業計画の取り組みへの使用ができなかった場合には、補助金の対象にできませんのでご注意ください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            事業推進に必要な機械装置の購入にかかる費用を計上することができます。<br />
            <br />
            ①単なる機械装置の取り替えは、対象外となります。<br />
            ②実施期間を超える経費に関しては、実施期間内にかかる費用のみが補助対象となります。<br />
            ③100万円以上の機械装置等を購入する場合は、2社以上の見積りが必要になります。なお、50万円以上の機械装置等を処分する際は事務局の承認が必要となります。<br />
            ④中古の機械装置等の購入については、50万円未満のものであれば2社以上の見積りがあった場合に限り対象に含まれます。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>広報費</h3><br />
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>公募要領</span><br />
            パンフレット・ポスター・チラシ等を作成および広報媒体等を活用するために支払われる経費<br />
            ①補助事業計画に基づく商品・サービスの広報を目的としたものが補助対象であり、単なる会社のＰＲや営業活動に活用される広報費は、補助対象となりません。（販路開拓に繋がる商品・サービスの名称や宣伝文句が付記されていないものは補助対象となりません。）<br />
            ②ウェブに関する広報については、ウェブサイト関連費にて計上してください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            広告媒体等に活用するための費用を計上できる項目となっております。<br />
            <br />
            ①事業推進に関する広告（〇〇サービスのチラシなど）は対象内ですが、会社のPR活動に関わる経費は対象外となります。<br />
            ②ウェブに関する広報は、ウェブサイト関連費用にて対象とされます。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>ウェブサイト関連費</h3><br />
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>公募要領</span><br />
            ①ウェブサイト関連費は、補助金交付申請額の１／４を上限とします。<br />
            ②また、交付すべき補助金の額の確定時に認められる補助金総額の１／４が上限となります。例えば、補助金確定額を５０万円とした場合、そのうち１２．５万円までがウェブサイト関連費として計上可能です。<br />
            ③ウェブサイト関連費のみによる申請はできません。<br />
            ④ウェブサイトに関連する経費については、すべてこちらで計上してください。<br />
            ⑤ウェブサイトを５０万円（税抜き）以上の費用で作成・更新する場合、当該ウェブサイトは「処分制限財産」に該当し、補助事業が終了し、補助金の支払を受けた後であっても、一定の期間（通常は取得日から５年間）において処分（補助事業目的外での使用、譲渡、担保提供、廃棄等）が制限されることがあります。<br />
            処分制限期間内に当該財産を処分する場合には、必ず補助金事務局等へ承認を申請し、承認を受けた後でなければ処分できません。補助金事務局等は、財産処分を承認した補助事業者に対し、当該承認に際し、残存簿価等から算出される金額の返還のため、交付した補助金の全部または一部に相当する金額を納付させることがあります。承認を得ずに処分を行うと、交付規程違反により補助金交付取消・返還命令（加算金付き）の対象となります。<br />
            ※なお、補助金の交付を受けた補助事業の目的を遂行するために必要なホームページの改良や機能強化は、補助金事務局等への事前承認申請等が必要となる「処分」には該当しません。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            販路開拓を行うためのウェブサイトやECサイト等の構築、更新等を行うためにかかる費用を計上する項目となります。<br />
            <br />
            ①②ウェブサイト関連費は、補助額の1/4が上限となっております。<br />
            ③ウェブサイト関連費のみの申請は、不可とされています。<br />
            ④ウェブサイトに関連する経費は、全てこちらで申請してください。<br />
            ⑤ウェブサイトは、処分制限財産に適用されるため本補助金にて作成したウェブサイトを廃止する場合は、事務局へご連絡ください。なお、ホームページの改良、強化につきましては、事務局への連絡は不要となります。<br />
          </p>
          <br />
          <h3 className='media_text_text_title'>展示会等出展費</h3><br />
          <div className='media_text_text_box'>
            新商品等を展示会等に出展または商談会に参加するために要する経費<br />
            <br />
            ①展示会出展の出展料等に加えて、関連する運搬費（レンタカー代、ガソリン代、駐車場代等は除く）・通訳料・翻訳料も補助対象となります。<br />
            ②国（国以外の機関が、国から受けた補助金等により実施する場合を含む）により出展料の一部助成を受ける場合の出展料は、補助対象となりません。<br />
            ③展示会等の出展については、出展申込みは交付決定前でも構いませんが、請求書の発行日や出展料等の支払日が交付決定日より前となる場合は補助対象となりません。<br />
            ④販売のみを目的とし、販路開拓に繋がらないものは補助対象となりません。<br />
            ⑤補助事業期間外に開催される展示会等の経費は補助対象となりません。<br />
            ⑥選考会、審査会（○○賞）等への参加・申込費用は補助対象となりません。<br />
            ⑦海外展示会等の出展費用の計上にあたり外国語で記載の証拠書類等を実績報告時に提出する場合には、当該書類の記載内容を日本語で要約・説明する書類もあわせてご提出ください（実績報告の際に提出する証拠書類の翻訳料は補助対象となりません）。<br />
            ⑧出展等にあたり必要な機械装置等の購入は、①機械装置等費に該当します（文房具等の事務用品等の消耗品代は補助対象となりません）。<br />
            ⑨飲食費を含んだ商談会等参加費の計上は補助対象となりません。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            新商品の展示会に出店するために使用される費用は、本項目にて計上できます。<br />
            <br />
            ①展示会出店の直接的な費用でなくても、移動費や翻訳料金は計上することができます。<br />
            ②本補助金以外に助成金等を既に支給されている場合は、本補助金を使用することはできません。<br />
            ③本項目を計上する場合は、展示会開催日、及びその他の関連費用は交付決定日以降のもののみ対象となります。<br />
            ④販路開拓に繋がらない展示会等は、計上することができません。<br />
            ⑤補助事業期間内に含まれない展示会は対象となりません。<br />
            ⑥選考会、審査会の費用の計上はできません。<br />
            ⑦海外へ出店する展示会費用も計上することができますが、その場合提出資料には日本語で資料に対する要約をした資料を追加でご用意ください。<br />
            ⑧出店に関わる機械装置等の購入は、①機械装置等費に該当します。<br />
            ⑨飲食費を含んだ商談会参加費は、対象外となります。<br />
          </p>
          <br />
          <h3 className='media_text_text_title'>旅費</h3><br />
          <div className='media_text_text_box'>
            新商品等を展示会等に出展または商談会に参加するために要する経費<br />
            <br />
            補助事業計画（様式２）に基づく販路開拓（展示会等の会場との往復を含む。）等を行うための旅費<br />
            <br />
            ①補助事業計画に基づく販路開拓を行うための出張である旨を記載した出張報告の作成等により、必要性が確認できるものが補助対象となります（補助事業計画に明記されていない出張の場合は、補助対象外経費となります）。通常の営業活動に要する経費とみなされる場合は対象外となります。<br />
            ②補助対象経費は国が定める旅費の支給基準を踏まえた基準により算出することとします。旅費の支給基準は、別紙「参考資料」の P.８を参照ください。<br />
            ③移動に要する経費については、公共交通機関を用いた最も経済的および合理的な経路により算出された実費となります。<br />
            ④海外旅費の計上にあたり外国語で記載の証拠書類を実績報告時に提出する場合には、当該書類の記載内容を日本語で要約・説明する書類もあわせてご提出ください（実績報告の際に提出する証拠書類の翻訳費用は補助対象外です）。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            販路拡大に必要な旅費を計上する項目となります。<br />
            <br />
            ①販路開拓に必要な経費のみ計上することができます。<br />
            ②旅費の計上は、支給基準を満たした場合のみ補助されます。<br />
            ③移動にかかる経費は、最も合理的な手段を使用した場合のみ補助されます。<br />
            ④海外旅費の計上申請をする場合は、外国語に加えて日本語で要約した書類も含めてご提出ください。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>開発費</h3><br />
          <div className='media_text_text_box'>
            新商品の試作品や包装パッケージの試作開発にともなう原材料、設計、デザイン、製造、改良、加工するために支払われる経費<br />
            <br />
            ①購入する原材料等の数量はサンプルとして使用する必要最小限にとどめ、補助事業終了時には使い切ることが必要です。<br />
            ②原材料費を補助対象経費として計上する場合は、受払簿（任意様式）を作成し、その受け払いを明確にしておく必要があります。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            試作品開発にかかる原材料費、設計、デザイン等の費用を計上する項目です。<br />
            <br />
            ①購入する原材料の数量は、補助事業期間内に使い切ることが必要です。<br />
            ②原材料費を申請する場合は、受払簿（任意様式）を作成する必要があります。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>資料購入費</h3><br />
          <div className='media_text_text_box'>
            補助事業遂行に必要不可欠な図書等を購入するために支払われる経費<br />
            <br />
            ①取得単価（消費税込）が１０万円未満のものに限ります。<br />
            ②購入する部数・冊数は１種類につき１部（１冊）を限度とします（同じ図書の複数購入は対象外です）。<br />
            ③補助事業遂行に必要不可欠な図書等の購入費用は「資料購入費」です。<br />
            ④中古書籍の購入は、「同等の中古書籍」の２社以上（個人は不可）からの見積（古書販売業者のネット通販サイトのコピーでも可）が実績報告時に提出できる場合に限り、補助対象となり得ます。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            補助事業実施に必要な図書等を購入する費用を計上できます。<br />
            <br />
            ①10万円未満の図書のみが対象となります。<br />
            ②同一の図書を購入することはできません。<br />
            ③事業推進に必要な図書の購入費用は資料購入費に含まれます。<br />
            ④中古書店の購入は、同等の中古書店の２社以上の見積りを提出することで補助対象となります。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>雑役務費</h3><br />
          <div className='media_text_text_box'>
            補助事業計画（様式２）に基づいた販路開拓を行うために必要な業務・事務を補助するために補助事業期間に臨時的に雇い入れた者のアルバイト代、派遣労働者の派遣料、交通費として支払われる経費<br />
            <br />
            ①実績報告の際に、作業日報や労働契約書等の提出が必要となります。作業日報や労働契約書等については、詳細な説明や資料を求めることがあります。<br />
            ②臨時の雇い入れとみなされない場合（例えば、あるアルバイト従業員への支払給料を雑役務費として計上した後、当該アルバイト従業員に社会保険を適用させ正規型の従業員として雇い入れる場合等）には、補助対象となりません。<br />
            ③通常業務に従事させるための雇い入れも補助対象となりません。<br />
            ④補助事業計画に明記されていない経費の場合は、補助対象となりません。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            事業推進に必要な臨時の労働に支払われる費用を計上することができます。<br />
            <br />
            ①実績報告の際に、作業日報、労働契約書等の提出が必要となります。<br />
            ②臨時の雇い入れに入る場合は、補助対象となります。<br />
            ③臨時ではない場合（常時雇用）の場合は、補助対象となりません。<br />
            ④補助事業計画に明記されていない経費は、補助対象になりません。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>借料</h3><br />
          <div className='media_text_text_box'>
            補助事業遂行に直接必要な機器・設備等のリース料・レンタル料として支払われる経費<br />
            <br />
            ①借用のための見積書、契約書等が確認できるもので、本事業に要する経費のみ補助対象となります。契約期間が補助事業期間を越える場合は、按分等の方式により算出された補助事業期間分のみ補助対象となります。<br />
            ②自主事業など補助事業以外にも使用するもの、通常の生産活動のために使用するものは補助対象となりません。<br />
            ③事務所等に係る家賃は補助対象となりません。ただし、既存の事務所賃料ではなく、新たな販路開拓の取り組みの一環として新たに事務所を賃借する場合は、対象となることがあります。なお、審査時に床面積の按分資料が必要となることがあります（別紙「応募時提出資料・様式集」の P.４２を参照）。<br />
            ④商品・サービス PR イベントの会場を借りるための費用は、「⑨借料」に該当します。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            事業推進に必要な機械・設備等のリース料・レンタル料は補助対象がなります。<br />
            <br />
            ①本事業の実施期間内にかかる費用のみが補助対象となります。なお、借用した場合、見積書・契約書等が必要になります。<br />
            ②補助事業以外に必要とされる費用は、対象外となります。<br />
            ③販路開拓に必要な借料のみが補助対象となります。<br />
            ④イベント会場の借料等は本項目に計上することができます。<br />
          </p>
          <br />
          <br />
          <h3 className='media_text_text_title'>設備処分費</h3><br />
          <div className='media_text_text_box'>
            販路開拓の取組を行うための作業スペースを拡大する等の目的で、当該事業者自身が所有する死蔵の設備機器等を廃棄・処分する、または借りていた設備機器等を返却する際に修理・原状回復するのに必要な経費<br />
            <br />
            ①販路開拓の取組実行のためのスペースを確保する等の目的で、「死蔵の設備機器等の廃棄・処分」等を行うことが必要です（交付決定後の計画変更による「設備処分費」の事後の追加計上や、経費の配分変更による「設備処分費」の増額変更は認められません）。<br />
            ②申請時における「設備処分費」の補助対象経費への計上額は、補助対象経費総額の１／２を上限（設備処分費以外の補助対象経費合計額を超えない）とします。<br />
            ③また、事業終了後に提出する実績報告の際、「設備処分費」の補助対象経費への計上額は、交付すべき補助金の額の確定時に認められる補助対象経費の総額の１／２が上限（ただし、申請・交付決定時の計上額の範囲内）となります。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            販路拡大の取り組みを行うためのスペースを確保する際にかかる設備等の廃棄費用等が含まれます。<br />
            <br />
            ①販路拡大の取組実行のためのスペースを確保する目的で、「死蔵の設備機器等の廃棄・処分」を行うことが必要です。<br />
            ②③設備処分費の上限は、経費全体の1/2となります。<br />
          </p>
          <h3 className='media_text_text_title'>委託・外注費</h3><br />
          <div className='media_text_text_box'>
            上記①から⑩に該当しない経費であって、補助事業遂行に必要な業務の一部を第三者に委託（委任）・外注するために支払われる経費（自ら実行することが困難な業務に限ります。）<br />
            <br />
            ①デザイン会社がデザインを外注するなど、補助事業者が事業として実施している業務、個人事業主であればホームページ等に記載の事業や法人であれば定款記載の事業等については、自ら実行することが困難な業務に含まれません。<br />
            ②委託内容、金額等が明記された契約書等を締結し、委託する側である補助事業者に成果物等が帰属する必要があります。<br />
            ③例えば市場調査の実施にともなう記念品代、謝礼等は補助対象となりません。<br />
            ④実績報告の際に成果物が分かる資料の提出が必要になります。特にインボイス対応のためのコンサルティングを受けた場合、成果物が分かる資料が不足していることが多々ありますので、コンサルティング内容の実施報告書など実施内容が確認できる資料を提出してください。<br />
            ⑤店舗改装において５０万円（税抜き）以上の外注工事を行う場合等、「処分制限財産」に該当し、補助事業が終了し、補助金の支払を受けた後であっても、一定の期間において処分（補助事業目的外での使用、譲渡、担保提供、廃棄等）が制限されることがあります。<br />
            処分制限期間内に当該財産を処分する場合には、必ず補助金事務局等へ承認を申請し、承認を受けた後でなければ処分できません。補助金事務局等は、財産処分を承認した補助事業者に対し、当該承認に際し、残存簿価等から算出される金額の返還のため、交付した補助金の全部または一部に相当する金額を納付させることがあります。承認を得ずに処分を行うと、交付規程違反により補助金交付取消・返還命令（加算金付き）の対象となります。<br />
            ⑥補助金応募書類・実績報告書等の作成・送付・手続きに係る費用については、補助対象となりません。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            ①~⑩に該当しない経費であって、外注・委託するためにかかる経費を計上します。<br />
            <br />
            ①自社で取り扱いのあるサービスは対象外になります。<br />
            ②外注・委託した場合、成果物が帰属する必要があります。<br />
            ③記念品や謝礼等は補助対象となりません。<br />
            ④成果物がわかる資料を提出する必要があります。<br />
            ⑤店舗改装において、50万円以上の外注工事を行う場合等は、「処分制限財産」に含まれるため一定期間の間処分することができません。なお、処分する場合は事務局の承認が必要となります。<br />
            ⑥補助金応募・実績報告等にかかる費用は、補助対象となりません。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>２.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            <br />
            本記事では、小規模事業者持続化補助金を申請したい事業者さまへ向けて、小規模事業者持続化補助金の概要について記事にさせていただきました。<br />
            <br />
            募集要項を各所に添付させていただきましたが、ご覧いただけば分かる通り難しい内容となっております。<br />
            そこで、もし、本格的に小規模事業者持続化補助金の導入を検討されていましたら、弊社含め補助金に詳しい事業者へご相談ください。<br />
            <br />
            ご相談は、画面右上の補助金に関する相談をするから問い合わせにて、お待ちしております。<br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <div className='media_topic'>
          <div className='media_topic_service_top'>
            <h3>memorii help</h3>
            <h4>補助金/助成金の申請サポート</h4>
            <p>初めての方へも丁寧に補助金/助成金のご説明をさせていただき、さらに、電子申請、交付申請のサポートまで、行わせて頂くサービスとなっております。</p>
          </div>
          <div className='media_recommend_title'>おすすめの記事</div>
          <div className='media_recommend_content'></div>
          <div className='media_recommend_content'></div>
          <div className='media_recommend_content'></div>
          <div className='media_recommend_content'></div>
        </div>
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;