// all：https://api.jgrants-portal.go.jp/exp/v1/public/subsidies?keyword=%E8%A3%9C%E5%8A%A9%E9%87%91&sort=created_date&order=DESC&acceptance=0
// item：https://api.jgrants-portal.go.jp/exp/v1/public/subsidies/id/a0W5h00000MyJ39EAF,

import './styles.css';
import DocumentMeta from 'react-document-meta';
import firebase from "firebase/compat/app";
import { useLayoutEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import Nav from "./../items/nav/index";
import Search from "./../items/search/index";
import Footer from "./../items/footer/index";
import 全国 from "./../../../../../image/map/全国.jpg";
import 北海道 from "./../../../../../image/map/北海道.jpg";
import 青森県 from "./../../../../../image/map/青森県.jpg";
import 岩手県 from "./../../../../../image/map/岩手県.jpg";
import 宮城県 from "./../../../../../image/map/宮城県.jpg";
import 秋田県 from "./../../../../../image/map/秋田県.jpg";
import 山形県 from "./../../../../../image/map/山形県.jpg";
import 福島県 from "./../../../../../image/map/福島県.jpg";
import 東京都 from "./../../../../../image/map/東京都.jpg";
import 茨城県 from "./../../../../../image/map/茨城県.jpg";
import 栃木県 from "./../../../../../image/map/栃木県.jpg";
import 群馬県 from "./../../../../../image/map/群馬県.jpg";
import 埼玉県 from "./../../../../../image/map/埼玉県.jpg";
import 千葉県 from "./../../../../../image/map/千葉県.jpg";
import 神奈川県 from "./../../../../../image/map/神奈川県.jpg";
import 新潟県 from "./../../../../../image/map/新潟県.jpg";
import 富山県 from "./../../../../../image/map/富山県.jpg";
import 石川県 from "./../../../../../image/map/石川県.jpg";
import 福井県 from "./../../../../../image/map/福井県.jpg";
import 山梨県 from "./../../../../../image/map/山梨県.jpg";
import 長野県 from "./../../../../../image/map/長野県.jpg";
import 岐阜県 from "./../../../../../image/map/岐阜県.jpg";
import 静岡県 from "./../../../../../image/map/静岡県.jpg";
import 愛知県 from "./../../../../../image/map/愛知県.jpg";
import 京都府 from "./../../../../../image/map/京都府.jpg";
import 大阪府 from "./../../../../../image/map/大阪府.jpg";
import 三重県 from "./../../../../../image/map/三重県.jpg";
import 滋賀県 from "./../../../../../image/map/滋賀県.jpg";
import 兵庫県 from "./../../../../../image/map/兵庫県.jpg";
import 奈良県 from "./../../../../../image/map/奈良県.jpg";
import 和歌山県 from "./../../../../../image/map/和歌山県.jpg";
import 鳥取県 from "./../../../../../image/map/鳥取県.jpg";
import 島根県 from "./../../../../../image/map/島根県.jpg";
import 岡山県 from "./../../../../../image/map/岡山県.jpg";
import 広島県 from "./../../../../../image/map/広島県.jpg";
import 山口県 from "./../../../../../image/map/山口県.jpg";
import 徳島県 from "./../../../../../image/map/徳島県.jpg";
import 香川県 from "./../../../../../image/map/香川県.jpg";
import 愛媛県 from "./../../../../../image/map/愛媛県.jpg";
import 高知県 from "./../../../../../image/map/高知県.jpg";
import 福岡県 from "./../../../../../image/map/福岡県.jpg";
import 佐賀県 from "./../../../../../image/map/佐賀県.jpg";
import 長崎県 from "./../../../../../image/map/長崎県.jpg";
import 大分県 from "./../../../../../image/map/大分県.jpg";
import 熊本県 from "./../../../../../image/map/熊本県.jpg";
import 宮崎県 from "./../../../../../image/map/宮崎県.jpg";
import 鹿児島県 from "./../../../../../image/map/鹿児島県.jpg";
import 沖縄県 from "./../../../../../image/map/沖縄県.jpg";


const SubisyPotal = () => {

  const history = useHistory();

  const [ list, setList ] = useState([]);
  const [ page, setPage ] = useState(0);

  const [ metaArea, setMetaArea ] = useState([]);
  const [ metaTitle, setmetaTitle ] = useState([]);
  // const [text, setText] = useState("")

  const meta = {
    title: `${metaArea[0] === '' ? '全国' : metaArea[0]}の補助金 ${metaTitle[0] === '' ? '' : `| ${metaTitle}`} | 補助金まとめサイト`,
    description: `この記事では、${metaArea}の${metaTitle}に関する補助金をまとめています。全国の補助金をまとめているサイトととなっておりますので、ぜひご活用いただければと思います。運営：トランク合同会社`,
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  useLayoutEffect(() => {

    var link = new URL(window.location).href;

    var prefecture = decodeURIComponent(link.slice(link.indexOf('prefecture=') + 11, link.indexOf('+keyword'))).split(',');
    var keyWord = decodeURIComponent(link.slice(link.indexOf('+keyword=') + 9, link.indexOf('+page'))).split(',');
    setPage(link.slice(link.indexOf('page=') + 5, link.length));

    // const isAllIncludes = (arr, target) => arr.some(el => target.includes(el));


    setMetaArea(prefecture);
    setmetaTitle(keyWord);

    firebase.firestore().collection("article").limit(50)
    .where('area', 'in', [ '全国', ...prefecture ]).get()
    .then(snapShot => {
      var tmp = [];
      snapShot.forEach((doc) => {
        tmp.push(doc.data());
      });
      setList(tmp);
    });

    // if (prefecture[0] === '') {
    //   firebase.firestore().collection("article").limit(50).get()
    //   .then(snapShot => {
    //     var tmp = [];
    //     snapShot.forEach((doc) => {
    //       if (keyWord.length !== 0 && doc.data().title !== undefined) {
    //         if (isAllIncludes(keyWord, doc.data().title)) {
    //           tmp.push(doc.data());
    //         }
    //       } else {
    //         tmp.push(doc.data());
    //       }
    //     });
    //     setList(tmp);
    //   });
    // } else {
    //   firebase.firestore().collection("article").limit(50)
    //   .where('area', 'in', [ '全国', ...prefecture ]).get()
    //   .then(snapShot => {
    //     var tmp = [];
    //     snapShot.forEach((doc) => {
    //       if (keyWord.length !== 0 && doc.data().title !== undefined) {
    //         if (isAllIncludes(keyWord, doc.data().title)) {
    //           tmp.push(doc.data());
    //         }
    //       } else {
    //         tmp.push(doc.data());
    //       }
    //     });
    //     setList(tmp);
    //   });
    // }
  }, []);
  

  function nextPage(v) {
    history.push(`https://trankllc.com/media/potal/subsidy/prefecture/prefecture=${metaArea}+keyword=${metaTitle}+page=${v}`);
    window.location.reload();
  }

  // 農業，林業 / 漁業 / 鉱業，採石業，砂利採取業 / 建設業 / 製造業 / 電気・ガス・熱供給・水道業 / 情報通信業 / 運輸業，郵便業 / 学術研究，専門・技術サービス業 / 医療，福祉

  // function test() {
  //   firebase.firestore().collection("article").get()
  //   .then(snapShot => {
  //     snapShot.forEach((doc) => {
  //       if (doc.data().industry !== null) {
  //         firebase.firestore().collection("article").doc(doc.id)
  //         .update({
  //           industry : '全ての業種',
  //         })
  //       }
  //     });
  //   });
  // }

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <div className='subsidy_potal_list_background'>
        <Search />
        <div style={{height: '80px'}}></div>
        <div className='bid_list_search_terms'>
          <h5>検索ワード：{metaArea[0] === '' ? '全国' : metaArea[0]}{metaTitle[0] === '' ? '' : `、 ${metaTitle[0]}`} | ヒット：{list.length}件</h5>
          <h4>{page * 20 + 1}件目~{page * 20 + 20}件目</h4>
        </div>
        {list.length === 0 ? <>表示</> :
          list.map((data, index) =>
          <>
          {page * 20 <= index && index < page * 20 + 20 ? 
            <a href={`https://trankllc.com/media/subsidies/id=${data.id}`} key={index} className='subsidy_potal_list_case'>
              <div>
                {data.area === '' || data.area === null || data.area === '全国' ? <img src={全国} alt='' />
                : data.area === '北海道' ? <img src={北海道} alt='' />
                : data.area === '青森県' ? <img src={青森県} alt='' />
                : data.area === '岩手県' ? <img src={岩手県} alt='' />
                : data.area === '宮城県' ? <img src={宮城県} alt='' />
                : data.area === '秋田県' ? <img src={秋田県} alt='' />
                : data.area === '山形県' ? <img src={山形県} alt='' />
                : data.area === '福島県' ? <img src={福島県} alt='' />
                : data.area === '東京都' ? <img src={東京都} alt='' />
                : data.area === '茨城県' ? <img src={茨城県} alt='' />
                : data.area === '栃木県' ? <img src={栃木県} alt='' />
                : data.area === '群馬県' ? <img src={群馬県} alt='' />
                : data.area === '埼玉県' ? <img src={埼玉県} alt='' />
                : data.area === '千葉県' ? <img src={千葉県} alt='' />
                : data.area === '神奈川県' ? <img src={神奈川県} alt='' />
                : data.area === '新潟県' ? <img src={新潟県} alt='' />
                : data.area === '富山県' ? <img src={富山県} alt='' />
                : data.area === '石川県' ? <img src={石川県} alt='' />
                : data.area === '福井県' ? <img src={福井県} alt='' />
                : data.area === '山梨県' ? <img src={山梨県} alt='' />
                : data.area === '長野県' ? <img src={長野県} alt='' />
                : data.area === '岐阜県' ? <img src={岐阜県} alt='' />
                : data.area === '静岡県' ? <img src={静岡県} alt='' />
                : data.area === '愛知県' ? <img src={愛知県} alt='' />
                : data.area === '京都府' ? <img src={京都府} alt='' />
                : data.area === '大阪府' ? <img src={大阪府} alt='' />
                : data.area === '三重県' ? <img src={三重県} alt='' />
                : data.area === '滋賀県' ? <img src={滋賀県} alt='' />
                : data.area === '兵庫県' ? <img src={兵庫県} alt='' />
                : data.area === '奈良県' ? <img src={奈良県} alt='' />
                : data.area === '和歌山県' ? <img src={和歌山県} alt='' />
                : data.area === '鳥取県' ? <img src={鳥取県} alt='' />
                : data.area === '島根県' ? <img src={島根県} alt='' />
                : data.area === '岡山県' ? <img src={岡山県} alt='' />
                : data.area === '広島県' ? <img src={広島県} alt='' />
                : data.area === '山口県' ? <img src={山口県} alt='' />
                : data.area === '徳島県' ? <img src={徳島県} alt='' />
                : data.area === '香川県' ? <img src={香川県} alt='' />
                : data.area === '愛媛県' ? <img src={愛媛県} alt='' />
                : data.area === '高知県' ? <img src={高知県} alt='' />
                : data.area === '福岡県' ? <img src={福岡県} alt='' />
                : data.area === '佐賀県' ? <img src={佐賀県} alt='' />
                : data.area === '長崎県' ? <img src={長崎県} alt='' />
                : data.area === '大分県' ? <img src={大分県} alt='' />
                : data.area === '熊本県' ? <img src={熊本県} alt='' />
                : data.area === '宮崎県' ? <img src={宮崎県} alt='' />
                : data.area === '鹿児島県' ? <img src={鹿児島県} alt='' />
                : data.area === '沖縄県' ?
                <img src={沖縄県} alt='' />
                : <img src={全国} alt='' />
                }
                <div>
                  <p className='subsidy_potal_list_case_date'>{data.start === '' ? '受付日：-' : `受付日：${data.start.slice(0, 4)}年${data.start.slice(5, 7)}月${data.start.slice(8, 10)}日`}</p>
                  <h3>{data.title}</h3>
                  <h6>
                    {data.area === '' || data.area === null ? '' : `場所：${data.area}　`}
                    {data.industry === ''|| data.industry === null ? '' : `業種：${data.industry}　`}
                    {data.limit === ''|| data.limit === null ? '' : `上限金額：${data.limit}円　`}
                    {data.rate === ''|| data.rate === null ? '' : `割合：${data.rate}　`}
                    {data.recommend === ''|| data.recommend === null ? '' : `使用用途：${data.recommend}　`}
                    {data.purpose === ''|| data.purpose === null ? '' : `目的：${data.purpose}　`}
                  </h6>
                </div>
                <h4>＞</h4>
              </div>
            </a>
          : <></> }
          </>
        )}
        <div className='bid_list_number'>
          <div style={page * 1 === 0 ? {border : '1px solid #EBEBEB', color: '#EBEBEB'} : {}} 
            onClick={() => page * 1 === 0 ? '' : nextPage(page * 1 - 1)}>前</div>
          <div style={{backgroundColor : '#D70A0A', color: 'white'}} onClick={() => nextPage(page * 1 + 1) }>{page * 1 + 1}</div>
          <div style={list.length / 20 < page * 1 + 1 ? {border : '1px solid #EBEBEB', color: '#EBEBEB'} : {}} 
            onClick={() => list.length / 20 < page * 1 + 1 ? '' : nextPage(page * 1 + 1)}>次</div>
        </div>
      </div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default SubisyPotal;
