import DocumentMeta from 'react-document-meta';
import './styles.css';
import Nav from "./../../home/nav/index";
import TopImage from "./../../../image/company/0001.png";
import Footer from './../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: 'インターンでは、どんな仕事をしてる？ vol.1 | トランク合同会社',
    description: 'この記事ではトランク合同会社にインターン生として入社した方の感想や今後入社する予定のインターン生に向けたメッセージをインタビュー形式で紹介しています。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />

      <div className='media_company'>
        <div className='media_company_article'>
          <p className='media_company_date'>更新日: 2023/10/24</p>
          <h1 className="media_company_title">インターンでは、どんな仕事をしてる？ vol.1 | 杉山 太陽</h1>
          <img src={TopImage} className="media_company_title_img" alt='ものづくり補助金' />

          <p className="media_company_text_text">
            <br />
            社会に出て経験をたくさん積みたいインターン生を募集していますので、興味のある方、話を聞きたい方は、右上の問い合わせより、ご連絡ください。<br />
          </p>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>こんな事が書いてあります！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>どんな仕事に携わっている？</span></li>
              <li><span>業務で難しいと感じることはある？</span></li>
              <li><span>実際の社内の雰囲気は？</span></li>
            </ul>
          </div>
          </>

          <p className="media_company_text_text">
            この記事では、弊社で勤務しているインターン生がどんな業務を行っているのかをインタビュー形式で紹介しています！<br />
          </p>
          
          <>
          <div id="1" className="media_company_text_title">
            <h2>１.自己紹介</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            インターン生の杉山太陽です！<br />
            <br />
            明治大学 国際学部に在籍しており、現在は休学しながら今年の夏からインターン生としてトランク合同会社で働かせていただいております。<br />
          </p>
          </>
      
          <>
          <div id="2" className="media_company_text_title">
            <h2>２.どんな業務に携わっている？</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            現在は<strong>中小企業の資金調達をサポートする事業</strong>に携わっています。<br />
            <br />
            この業務では、「事業へ投資をする際に使われる補助金」を申請をしたいという事業者様をサポートする仕事で、主に「<strong>①ヒアリング</strong>→<strong>②事業計画書策定</strong>→<strong>③国への書類提出</strong>」までを一貫してお手伝いをしています。<br />
          </p>
          </>
         
          <>
          <div id="3" className="media_company_text_title">
            <h2>３.具体的な仕事内容は？</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            最近、私は事業計画書の策定業務に携わっています。<br />
            事業者様が希望する事業の概要説明、マーケティング分析、事業の妥当性、長期戦略、収益計画などをお客さまと共に事業計画書に落とし込んでいきます。<br />
            <br />
            この仕事では、事業者様からの要望と補助金の審査項目を同時に考えることが必要となっており、日々鍛えられています。<br />
          </p>
          </>
          
          <>
          <div id="4" className="media_company_text_title">
            <h2>４.難しいと感じることは何ですか？</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            <strong>事業計画書の執筆を事前知識がない段階から書き進めることには難しさを感じています</strong>。<br />
            <br />
            社員の方からのフォローをいただくことはできますが、インターン生は事業計画書の前提知識がない状態から計画書を書き進めることになります。<br />
            <br />
            1から作ることは流石に難易度が高すぎるため、これまで社員さんが作成された過去資料を参考にしながら内容を理解し、自分がある程度まで書き進めた内容に関しては社員の方からフィードバックをもらうという形で現在は作業を進めています。<br />
          </p>
          </>
          
          <>
          <div id="5" className="media_company_text_title">
            <h2>５.インターン生同士の関係性は？</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            <strong>インターン生・社員さんとの会話は多い会社だと思います。</strong><br />
            <br />
            インターン生は年齢が近いこともあり、勤務時間以外でもよく話すことが多いですし、社員さんもインターン生に対して非常に近い距離感で接してくださっているため、仕事中は常に質問ができる環境にいますし、ランチに連れて行っていただくこともよくあります！<br />
          </p>
          </>
          
          <>
          <div id="6" className="media_company_text_title">
            <h2>６.インターンを希望する学生に向けて一言お願いします</h2>
          </div>
          <p className="media_company_text_text">
            <br />
            トランク合同会社は社員さんとの距離が近く、事業計画書の執筆、その他の業務の裁量権も大きいことから、自己成長を追い求めることができる最高の環境だと思っています。<br />
            <br />
            事業者様に直接見せるための計画書作成や書類提出の業務は難易度が高く勉強が必要とされる仕事だとは思いますが、その分自分の成長できる範囲も大きくなると思っています。<br />
            <br />
            将来コンサルティング業界を志望する方や中小企業について関心がある方などはぜひ応募を検討してみてください！<br />
          </p>
          </>
          
        </div>
        
      </div>

      <div className='media_company_'></div>

      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
