import DocumentMeta from 'react-document-meta';
import './styles.css';
import Slider from './slider/index.js';
import Nav from './nav/index.js';
import Header from './header/index.js';
import Solve from './solve/index.js';
import Structure from './structure/index.js';
import Function from './function/index.js';
import Qa from './Q&A/index.js';
import Opinion from './opinion/index.js';
// import Instagram from './instagram/index.js';
import React from "react";
import Footer from "./../../tabs/footer/index.js";
import { Link } from 'react-router-dom';

const Photo = () => {

  const meta = {
    title: '【美容学生】作品撮り専門サイト -1000枚以上の掲載あり',
    description: '美容学生の作品撮りを手軽に確認でき、仲間から刺激を受けられるサイト。そして、作成者との繋がりをより深められるマッチング媒体。',
    canonical: 'https://trankllc.com',
    meta: {charset: 'utf-8',}
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <Header />
      <Solve />
      <div className='photo_download'>
        <h4>美容学生のための機能が満載<br />App Storeで簡単にダウンロード</h4>
        <a href='https://apps.apple.com/jp/app/memorii-photo/id1578278781' rel="noreferrer">
          <div>ダウンロード</div>
        </a>
      </div>
      <Structure />
      <Slider />
      <Function />
      <Qa />
      <div className='photo_salon'>
        <h4>新卒採用に満足していない企業様へ<br />求人掲載サービスも展開中</h4>
        <Link to='/contact'>
          <div>問い合わせ</div>
        </Link>
      </div>
      <Opinion />
      {/* <div className='photo_download'>
        <h4>基本的な機能はもちろん<br />美容学生のための機能も満載</h4>
        <a href='https://apps.apple.com/jp/app/memorii-photo/id1578278781' rel="noreferrer">
          <div>ダウンロード</div>
        </a>
      </div> */}
      {/* <Instagram /> */}
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default Photo;


