// 補助率引上要件

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/29/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」補助率引上要件で補助金額を引き上げる！',
    description: '本記事では、事業再構築要件の補助率引上要件について解説させていただきました。要件の詳細、補助率、活用例などの詳細も解説しております。本記事は、「事業再構築の定義が分からない」方や「類型を詳しく知りたい」という方に向けて、執筆しておりますので、補助金の初申請を行う方はぜひご覧いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」補助率引上要件で補助金額を引き上げる！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は<strong>事業再構築補助金の補助率引上要件</strong>について、解説させていただきます。<br />
            <br />
            本記事は、初めての方へ理解ができるよう執筆させていただいておりますので、ぜひ最後まで目を通していただけますと幸いです。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>本記事でこんな悩みが解決！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>補助率引上要件とは何？</span></li>
              <li><span>補助率引上要件はどうすれば満たされる？</span></li>
              <li><span>補助率引上要件で何ができる？</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、<strong>経済産業省から展開されている「事業再構築指針の手引き」を参考にして作成したもの</strong>となっています。<br />
            <a href='https://www.meti.go.jp/covid-19/jigyo_saikoutiku/pdf/shishin_tebiki.pdf' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：「事業再構築指針の手引き」に関する記事
            </a><br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">補助率引上要件の概要</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">概要</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">要件</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">申請可能な枠</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.補助率引上要件の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では概要、要件、補助金額と基本的な知識を順を追って解説しておりますので、要件を理解したい方はぜひご覧いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>概要</h3><br />
          <p className="media_text_text">
            <br />
            補助率引上要件は従来の申請枠で提示されている補助率を引き上げるための要件となっており、従来提示されている補助金額に投資金額の1/6が追加で補填されるというものになります。<br />
            <br />
            そのため、申請枠で提示されている補助率以上の金額を取得したいと考えている事業者様を対象としております。<br />
          </p>
          <h3 className='media_text_text_title'>要件</h3><br />
          <p className="media_text_text">
            <br />
            上記のように申請金額が上乗せされるという特徴を持っている一方で要件であるため、申請さえすれば追加で支給されるものではございません。<br />
            <br />
            要件は具体的に以下の2点を達成する必要がございます。また、その要件を判断する時期は以下の通りでございます。<br />
            <br />
            <strong>補助率引上げを受ける場合の追加要件</strong><br />
            ① 補助事業期間内に給与支給総額を年平均 6％以上増加させること<br />
            ② 補助事業期間内に事業場内最低賃金を年額 45 円以上の水準で引上げること<br />
            <br />
            <strong>比べる時期</strong><br />
            比べる時期は、採択日～補助事業完了期限日のいずれかの時点が含まれる事業年度とその前年度を比べることで要件を確認します。<br />
            <br />
            例えば、2023年の5月に採択、10月に事業実施完了の場合は、前年度の2022年度の給与支給総額、事業場内最低賃金と比較します。<br />
            <br />
            ※最後に本要件はどちらかを満たすものではなく、どちらも満たす必要がございます。<br />
          </p>
          <h3 className='media_text_text_title'>申請可能な枠</h3><br />
          <p className="media_text_text">
            <br />
            補助率引上要件は全ての枠で有効なわけでなく、以下の３つの枠の場合のみ要件を活用することができますので、３つの申請枠を検討している方は、ご活用いただければと思います。<br />
            <br />
            <strong>申請可能な枠</strong><br />
            ① 成長枠<br />
            ② グリーン成長枠（エントリー）<br />
            ③ グリーン成長枠（スタンダード）<br />
            <br />
            補足事項として、上記３つの枠のご説明をさせていただきます。<br />
            <br />
            <strong>① 成長枠</strong><br />
            市場が拡大している枠への転換を検討されている方向けの申請枠<br />
            <a href='https://trankllc.com/media/subsidy/business/0025' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：成長枠の解説
            </a><br />
            <br />
            <strong>② グリーン成長枠（エントリー）</strong><br />
            カーボンニュートラル等の事業の展開を検討されている方向けの申請枠<br />
            <br />
            <strong>③ グリーン成長枠（スタンダード）</strong><br />
            カーボンニュートラル等の事業の展開を検討されている方向けの申請枠<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では補助率引上枠についての解説をさせていただきました。<br />
            ご覧いただいて分かる通り、少し厳しめの条件と活用できるタイミングが少ない要件だと思います。<br />
            <br />
            しかし、これから事業を大幅に拡大するという方は、従業員数の拡大、賃金の引上も前向きに取り組むと思いますので、そのような方はぜひ補助金額の増加へ向けてご検討いただければと思います。<br />
            <br />
            当社は上述でも記載させていただきました通り、補助金申請会社になりますので、ご質問や疑問点がございましたらご気軽にご連絡いただければと思います。<br />
            <br />
            最後まで記事を拝見してくださり、誠にありがとうございました。<br />
            ご連絡の程、大変お待ちしております。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
