import './styles.css';
import Header1 from './../../../../image/help/header1.png';
import Header2 from './../../../../image/help/header2.png';
import Header3 from './../../../../image/help/header3.png';
import Header4 from './../../../../image/help/header4.png';

const SubsidyHeader = () => {
  
  return (
    <>
    <div className='help_header'>
      <h1><span className='header_1'>補助金</span><span className='header_2'>申請</span><span className='header_3'>の</span><span className='header_4'>エキスパート</span></h1>
      <p>補助金を申請したい事業者さまをサポートするための会社です。補助金に関するご相談、申請方法、その後の流れ等の全ての工程をサポートしておりますので、ぜひご気軽にご連絡ください。</p>
      <h6>補助対象の経費</h6>
      <div>
        <div>
          <img src={Header1} alt='' />
          <h5>設備投資費</h5>
        </div>
        <div>
          <img src={Header2} alt='' />
          <h5>システム開発</h5>
        </div>
        <div>
          <img src={Header3} alt='' />
          <h5>広告・宣伝費</h5>
        </div>
        <div>
          <img src={Header4} alt='' />
          <h5>サービス導入費</h5>
        </div>
      </div>
    </div>
    </>
  );
}

export default SubsidyHeader;
