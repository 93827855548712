// 不動産業・物品賃貸業
// https://docs.google.com/document/d/1tFWKgiJ7mPeKU0JDzdBVoEp0kW_hJydla64B9NNl6LI/edit

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "../../nav/index";
import Topic from "../topic";
import TopImage from "./../../../../../image/media/business/47/top.png";
import Image1 from "./../../../../../image/media/business/47/1.png";
import Footer from '../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」不動産業の採択事例を大公開！',
    description: '本記事では、事業再構築補助金の不動産業 採択事例を紹介しております。飲食業を営んでいて事業再構築補助金の申請を検討されている方は、本記事をご参考に検討を行っていただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」不動産業の採択事例を大公開！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            新型コロナウイルスの影響や物価の高騰、少子高齢化など不動産業界全体の現状や将来性は厳しい状況にあります。<br />
            <br />
            苦境に立たされた事業者の中には、減少した売上げを取り戻すために新事業にチャレンジしようと事業再構築補助金を検討している方も多いのではないでしょうか。<br />
            <br />
            本記事では、<br />
            「補助金に興味はあるけれど、いまいち理解ができていない」<br />
            「どんな事業が採択されているのか知りたい」<br />
            など、事業再構築補助金を検討中の不動産業者向けに、補助金の活用方法や申請傾向、採択事例について詳しく解説します。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業再構築補助金の対象となる不動産業者</span></li>
              <li><span>不動産業における事業再構築補助金の活用方法</span></li>
              <li><span>過去の不動産業者採択例</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            ※本記事は「第10回事業再構築補助金　公募要領」をもとに作成しています。
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo.pdf' target="_blank" rel="nofollow noopener noreferrer">
              リンク：事業再構築補助金 公募要領
            </a><br />
            <br />
            当社では、事業再構築補助金に関する初回無料相談を実施しております。<br />
            「より詳しい話が聞きたい」「質問したいことがある」という方は、お気軽にお問い合わせください。<br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">不動産事業における活用方法</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金の対象となる経費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">不動産事業での事業再構築類型の具体例</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金での申請例</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">虎の巻から見る不動産事業者の特徴</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">o	不動産事業者の申請傾向</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">o	具体的な採択事例</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">o	不動産事業の申請で抑えるべきポイント</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.不動産業における活用方法</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築補助金の活用方法は業種によって様々です。<br />
            まずは、不動産業における事業再構築補助金の活用方法について、以下の3項目に分けて解説します。<br />
            <strong>1.事業再構築補助金の対象となる経費</strong><br />
            <strong>2.不動産業での事業再構築類型の具体例</strong><br />
            <strong>3.事業再構築補助金での申請例</strong><br />
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金の対象となる経費</h3><br />
          <p className="media_text_text">
            まずは経費について。<br />
            事業再構築補助金の対象経費は細かく分類されており、具体的には以下の11種類に分類されます。<br />
            <br />
            ①建物費<br />
            ②機械装置・システム構築費<br />
            ③技術導入費<br />
            ④専門家経費<br />
            ⑤運搬費<br />
            ⑥クラウドサービス利用費<br />
            ⑦外注費<br />
            ⑧知的財産権等関連経費<br />
            ⑨広告宣伝・販売促進費<br />
            ⑩研修費<br />
            ⑪廃業費<br />
            <br />
            事業再構築補助金の対象経費は非常に多くの種類がありますが、一方で、対象外経費の種類も細かく分類されているため注意が必要です。<br />
            <br />
            不動産業の場合、関りが深い経費として「建物費」が挙げられますが、建物の単なる購入や賃貸は対象外となります。<br />
            <br />
            対象経費になる建物費については以下のとおりです。<br />
            ・建物の建築費<br />
            ・建物の改修費<br />
            ・修繕費<br />
            ・撤去費用など<br />
            <br />
            これらの建物費の中でも事業再構築補助金で多く利用されているのは改修費です。<br />
            一方で、建物の建築費については、必要性が認められた場合に限られるなどハードルが高く、対象外となる場合があるため注意が必要です。<br />
            <br />
            当社では、事業再構築補助金の複雑な経費に関する無料相談を実施しております。<br />
            経費の確認や金額の算出など対応しておりますので、詳細を確認したい方はお気軽にお声掛けください。<br />
          </p>
          <h3 className='media_text_text_title'>不動産業での事業再構築類型の具体例</h3>
          <p className="media_text_text">
            続いては、事業再構築の「類型」について。<br />
            事業再構築補助金は申請にあたっての要件を設けており、そのなかのひとつに「事業再構築の類型に該当する」という要件があります。<br />
            申請を行う事業者は「類型」に関する要件を満たしておかなければなりません。<br />
            事業再構築補助金の類型には、「新市場進出」「事業転換」「業種転換」の3つがあり、具体的には以下のとおり。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th>類型を満たすための要件</th><th>産業分類に基づく変更</th>
              </tr>
              <tr>
                <td>新市場進出</td>
                <td>
                  ①製品又は提供方法を大きく変更する<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全体の10%以上となる計画を策定する。
                </td>
                <td>無</td>
              </tr>
              <tr>
                <td>事業転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。<br />
                </td>
                <td>主な事業を変更(中・小・細分類)</td>
              </tr>
              <tr>
                <td>業種転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3～5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。
                </td>
                <td>主な業種を変更<br />(大分類)</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            上記3類型を不動産業に当てはめると次のとおりです。<br />
            <br />
            <strong>【不動産業での具体例】</strong><br />
            不動産業という業種を変更することなく、これまで携わってきた事業の販売方法やサービスの提供方法を変更する場合に該当します。<br />
            たとえば、「不動産仲介業者が新たな販路拡大のため、空き家に特化したマッチングアプリを開発する」など。<br />
            <br />
            日本産業分類は「6821：不動産代理業・仲介業」のままですが、近年問題となっている空き家事業へ販路拡大する点が新たな市場への進出を行うという要件を満たしています。<br />
            <br />
            <strong>【不動産業：事業転換】</strong><br />
            事業転換とは、不動産業という業種はそのままで、主な事業を変更する場合に該当します。<br />
            具体例として、「不動産売買から自社物件を活用したシェアオフィスの賃貸業への進出」などが挙げられます。<br />
            <br />
            日本産業分類では中分類が「68：不動産取引業→69：不動産賃貸業・管理業」へと変更。<br />
            事業転換は業種に変更がなく、中分類・小分類・細分類のいずれかの事業が変更した場合に該当するため、上記具体例は事業転換の要件を満たすことになります。<br />
            <br />
            <strong>【不動産業：業種転換】</strong><br />
            業種転換とは、生活関連事業から別業種に進出する場合に該当します。<br />
            具体例として、「自社の所有物件を活用し、外国人旅行客向けのホテル事業を開始する」などが挙げられます。<br />
            <br />
            日本産業分類は「K：不動産業、物品賃貸業→M：宿泊業、飲食サービス業」に変更。<br />
            大分類が変更されていることから業種転換の要件を満たします。<br />
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金での申請例</h3>
          <p className="media_text_text">
            続いては、事業再構築補助金事務局にて公表されている例をもとに、事業再構築補助金申請時の補助金額や対象経費についてわかりやすく説明します。<br />
            <br />
            事業再構築補助金は、申請枠や従業員数により補助率・補助金額が変わってきます。<br />
            そのため、申請の際はご自身の会社状況を把握し、いくらまで補助を受けられるのかしっかり確認したうえで望むようにしましょう。<br />
            <br />
            <strong>【事業内容】</strong><br />
            不動産賃貸業の業績が悪化したため、自社テナントの空きスペースを改装して近隣住民向けのフィットネスジム事業を開始。<br />
            <br />
            ・事業場所：福岡県福岡市<br />
            ・従業員数：18名<br />
            ・売上高：5億円<br />
            <br />
            <strong>【日本産業分類から類型を決定】</strong><br />
            不動産賃貸業からフィットネスジムの事業へ進出する場合、日本産業分類は以下のように変化します。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>日本産業分類</th><th>大分類</th><th>中分類</th><th>小分類</th><th>細分類</th>
              </tr>
              <tr>
                <td>既存事業</td><td>K：不動産業、物品賃貸業</td><td>69：不動産賃貸業・管理業</td><td>691：不動産賃貸業</td><td>6911：貸事務所業</td>
              </tr>
              <tr>
                <td>新規事業</td><td>N：生活関連サービス業、娯楽業</td><td>80：娯楽業</td><td>804：スポーツ施設提供業</td><td>8084：フィットネスクラブ</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            大分類が「K：不動産業、物品賃貸業→N：生活関連サービス業、娯楽業」へと業種が変わったことから、本事業は「業種転換」で申請します。<br />
            <br />
            <strong>【売上高から申請枠を決定】</strong><br />
            下表は2021年と2022年の売上を比較したものとなります。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'></th><th>6月</th><th>7月</th><th>8月</th><th>合計</th>
              </tr>
              <tr>
                <td>2021年</td><td>5,300</td><td>5,500</td><td>7,500</td><td>23,000</td>
              </tr>
              <tr>
                <td>2022年</td><td>6,000</td><td>5,500</td><td>6,000</td><td>17,500</td>
              </tr>
              <tr>
                <td>増減率(%)</td><td>-25%</td><td>-27%</td><td>-20%</td><td>-24%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            2022年の売上が2021年の同月に比べて22％も減少していることがわかります。<br />
            このケースでは、「物価高騰対策・回復再生応援枠」の要件を満たしているため、本事業は「物価高騰対策・回復再生応援枠」にて申請します。<br />
            <br />
            ※2022年1月以降の連続する6か月間のうち、任意の3か月の合計売上高が対2019～2021年の同3か月の合計売上高と比較して10％減少していることが、「物価高騰対策・回復再生応援枠」の要件となります。<br />
            <br />
            <strong>【補助金額の計算】</strong><br />
            申請枠が決定したので、補助金額の計算をしていきましょう。<br />
            なお、本事業にかかる経費については以下のとおりです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>建物の改修費</th><th>￥5,000,000</th>
              </tr>
              <tr>
                <td>マシーンなどの設備費</td><td>￥1,500,000</td>
              </tr>
              <tr>
                <td>広告費</td><td>￥1,500,000</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            上記経費はすべて対象経費となるため、申請金額は合計で7,800,000円になります。<br />
            <br />
            第10回の事業再構築補助金公募要領によると、中小企業の補助率は2/3。<br />
            さらに、従業員数が6〜20人の場合には、600万円までの補助率が3/4になります。<br />
            <br />
            本事業の従業員数18人で要件を満たすため、それぞれの補助率で計算すると以下の金額が算出されます。<br />
            <br />
            （補助率　2/3）1,800,000×2/3＝1,200,000<br />
            （従業員数による補助率　3/4）6,000,000×3/4＝4,500,000<br />
            <br />
            申請金額7,800,000円に対し補助金額は最大で5,700,000円となります。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.虎の巻から見る不動産事業者の特徴</h2>
          </div>
          <p className="media_text_text">
            <br />
            「虎の巻」とは、事業再構築補助金に関する情報をまとめた資料のことで、経済産業省より公表されています。<br />
            <br />
            事業検討の考え方<br />
            有望な事業に共通する特徴<br />
            <br />
            など、公募要領だけでは得られない情報が満載です。<br />
            事業再構築補助金に関する詳細が非常にわかりやすくまとめられているため、申請を検討している経営者の方にはぜひご一読いただきたい資料のひとつです。<br />
            <br />
            本記事においても虎の巻をもとに、<br />
            ・不動産業者の申請傾向<br />
            ・具体的な採択事例<br />
            ・不動産業の申請で抑えるべきポイント<br />
            について解説します。<br />
          </p>
          <h3 className='media_text_text_title'>不動産事業者の申請傾向</h3>
          <p className="media_text_text">
            <img src={Image1} className='media_img' alt='' />
            上図は、不動産業の申請傾向をまとめたもので、縦が申請件数、横が採択率を表しています。<br />
            図の右側（オレンジ部分）に分類されている事業ほど有望度が高く、採択されやすい事業ということになります。<br />
            <br />
            不動産業では、宿泊関連事業やカフェ関連事業への申請件数の多さが目立ち、自社所有の物件や空きテナントを活用するケースが多いのが特徴です。<br />
            <br />
            ただし採択率に関してはそれほど高くありません。<br />
            そのため、宿泊関連事業やカフェ関連事業で採択されるためには、価値のあるサービスが提供できることはもちろんのこと、他の事業者との差別化を図ることも非常に重要になるといえるでしょう。<br />
            <br />
            採択率の高いテーマには、コワーキングスペース事業やスタジオ関連事業などがあります。<br />
            どちらもコロナ禍で需要が増えてきた事業であり、今後の成長にも期待ができることから採択率が高い傾向にあります。<br />
          </p>
          <h3 className='media_text_text_title'>具体的な採択事例</h3>
          <p className="media_text_text">
            ①レコード制作事業から、ライブコマースを配信できる「配信型スタジオ事業」への進出した事例<br />
            <br />
            新型コロナウイルスや物価高騰の影響でレコード制作事業の売上が減少。<br />
            市場が拡大しているライブコマースに着目し、配信型スタジオ事業へ進出し事業の回復を目指すとして採択された事業です。<br />
            <br />
            ライブコマースは新型コロナウイルスの緊急事態宣言を機に多くの企業が取り組みだした事業です。<br />
            市場が拡大している分野であり、今後の成長が見込まれることから採択されたと考えられます。<br />
            <br />
            ②メタバースを活用した英会話システムの運営企業への転換<br />
            <br />
            小学生を対象にした、メタバース（仮想空間）で英語学習ができる英会話システムの運営事業へ進出するとして採択された事例です。<br />
            <br />
            事業再構築補助金では、成長が見込まれる分野への進出や新型コロナウイルスに対応したサービスの提供などが評価されやすい傾向にあります。<br />
            <br />
            本事業は、市場が拡大しているメタバースの導入と、非対面によるサービスの提供といずれも満たしていることから評価を受け採択されたと考えられます。<br />
          </p>
          <h3 className='media_text_text_title'>宿泊事業の申請で抑えるべきポイント</h3>
          <p className="media_text_text">
            <strong>①市場ニーズのリサーチ</strong><br />
            <br />
            事業再構築補助金で採択されるためには、市場ニーズのリサーチが重要なポイントです。<br />
            なぜなら、市場ニーズのない事業は売上につながらないため評価されないからです。<br />
            どれだけ素晴らしい内容の事業計画書を作成しても、市場規模が小さければ投資回収ができないとの理由から不採択になる可能性があります。<br />
            <br />
            事業計画を立てる際は、進出する市場のデータや既存業者に関するリサーチを徹底する必要があります。<br />
            リサーチ内容をもとに、市場規模や将来の動向を根拠づけて論理的に説明できるようにしなければなりません。<br />
            非常に重要なポイントであるため、市場ニーズのリサーチは徹底的に行いましょう。<br />
            <br />
            <strong>②差別化を図る</strong><br />
            <br />
            事業再構築補助金では他社との差別化も重要なポイントのひとつです。<br />
            不動産業の場合、<br />
            <br />
            デジタル技術・DX推進<br />
            AI活用システム・サービス展開<br />
            オンライン配信関連事業<br />
            <br />
            これらの事業が採択されやすい傾向にありますが、単に採択率が高いからという理由で申請しても不採択になる可能性が高いです。<br />
            なぜなら、有望度の高い事業はその分ライバルも多いからです。<br />
            <br />
            他社との差別化ができていなければ、魅力のない事業と評価されかねません。<br />
            既存事業の強みをどのように活かせるか、他社にはないサービスをどのようにして提供できるかなど、事業計画の段階から見つめ直すことが重要です。<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            不動産業では、既存事業の強みを活かした事業への進出が採択されやすい傾向にあります。<br />
            <br />
            事業再構築補助金を活用することで売上の回復をはじめ、新たな事業へチャレンジできるなど、たくさんのメリットが得られます。<br />
            <br />
            事業再構築補助金を検討している方は、本記事や虎の巻を参考に事業計画を立ててみてください。<br />
            <br />
            【事業再構築補助金でお困りの方へ】<br />
            本記事を運営するトランク合同会社では、事業再構築補助金のサポートを行っております。<br />
            「内容が複雑で理解ができない」「補助金を活用したいけれど、申請が難しそう」など、お悩みを抱えている方はぜひご相談ください。<br />
            <br />
            当社は豊富な実績を有しており、採択に向けた適切なアドバイスが可能です。<br />
            今なら初回無料相談を実施しておりますので、興味がある方はお気軽にお問い合わせください。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


