import './styles.css';
import Blog1 from './../../../image/company/0001.png';
import Blog2 from './../../../image/company/0002.png';
import Blog3 from './../../../image/company/0003.png';

const Footer = () => {
  
  return (
    <>
    <div className='test_homeimage_border'>
      <h4>BLOG</h4>
      <div>
        <a href='https://trankllc.com/media/company/0001'>
          <div>
            <img src={Blog1} alt='' />
            <h4>インターン業務ではどんな仕事をしてるの？</h4>
            <p>インターンとして、概要説明、マーケティング分析、事業の妥当性、長期戦略、収益計画などをお客さまと共に事業計画書に落とし込んでいく仕事をしてます。</p>
          </div>
        </a>
        <a href='https://trankllc.com/media/company/0002'>
          <div>
            <img src={Blog2} alt='' />
            <h4>インターン業務ではどんな仕事をしてるの？</h4>
            <p>インターンとして、概要説明、マーケティング分析、事業の妥当性、長期戦略、収益計画などをお客さまと共に事業計画書に落とし込んでいく仕事をしてます。</p>
          </div>
        </a>
        <a href='https://trankllc.com/media/company/0003'>
          <div className='test_homeimage_border_third'>
            <img src={Blog3} alt='' />
            <h4>なぜ、トランク合同会社を選んだの？</h4>
            <p>イ社員の人柄やメリハリのある社風が良いという話をインターン生に聞いていた事をきっかけにスキルアップがしたく入社しました。</p>
          </div>
        </a>
      </div>
    </div>
    </>
  );
}

export default Footer;
