// https://www.kkj.go.jp/doc/ja/api_guide.pdf
// https://www.kkj.go.jp/api/?Query=%E3%82%BB%E3%83%9F%E3%83%8A%E3%83%BC

import './styles.css';

import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';

const BlogText0001 = () => {

  const history = useHistory();

  const [ open, setOpen ] = useState(false);

  const [ keyWord, setKeyWord ] = useState('');
  const [ prefecture, setPrefecture ] = useState('');
  const [ works, setWorks ] = useState('');
  const [ format, setFormat ] = useState('');

  // 詳細検索
  const [ keyWordDetails, setKeyWordDetails ] = useState('');
  const [ prefectureDetails, setPrefectureDetails ] = useState('');
  const [ dateDetails, setDateDetails ] = useState('');
  const [ worksDetails, setWorksDetails ] = useState('');
  const [ formatDetails, setFormatDetails ] = useState('');

  useEffect(() => {

    var subsidyId = new URL(window.location).href;

    console.log(subsidyId);
    console.log(subsidyId.indexOf('/bid/article/id'));
    if (subsidyId.indexOf('/bid/article/id') === -1) {
      var startDate = new Date('2000-1-1');
      var endDate = new Date();
  
      var link = new URL(window.location).href;
  
      var keyWord = decodeURIComponent(link.slice(link.indexOf('keyword=') + 8, link.indexOf('+date'))).split(',');
      var date = link.slice(link.indexOf('date=') + 5, link.indexOf('+prefecture')).split(',');
      var prefecture = decodeURIComponent(link.slice(link.indexOf('prefecture=') + 11, link.indexOf('+work'))).split(',');
      var work = decodeURIComponent(link.slice(link.indexOf('work=') + 5, link.indexOf('+format'))).split(',');
      var format = decodeURIComponent(link.slice(link.indexOf('format=') + 7, link.indexOf('+page')));
  
      setKeyWord(keyWord);
      setKeyWordDetails(keyWord);
  
      setPrefecture(prefecture);
      setPrefectureDetails(prefecture);
  
      setWorks(work);
      setWorksDetails(work);
  
      setFormat(format);
      setFormatDetails(format);
  
      setDateDetails([ startDate, endDate ])
  
      if (date[0] !== "") {
        startDate = new Date(date[0]);
      }
  
      if (date[1] !== "") {
        endDate = new Date(date[1]);
      }
    }
    
  }, []);

  function link() {

    var data = new Date().toISOString().slice(0, 10);
    history.push(`/bid/results/keyword=${keyWord}+date=2000-01-01,${data}+prefecture=${prefecture}+work=+format=+page=0`);

    window.location.reload();
  }

  function searchDetails() {

    var endDate = new Date().toISOString().slice(0, 10);

    if (dateDetails === '') {
      history.push(`/bid/results/keyword=${keyWordDetails}+date=2000-1-1,${endDate}+prefecture=${prefectureDetails}+work=${worksDetails}+format=${formatDetails}+page=0`);
    } else {
      history.push(`/bid/results/keyword=${keyWordDetails}+date=${dateDetails[0]},${dateDetails[1]}+prefecture=${prefectureDetails}+work=${worksDetails}+format=${formatDetails}&page=0`);
    }

    window.location.reload();
  }

  return (
    <>
    <div className='bid_list_search_bar'>
      <select  onChange={(e) => setPrefecture(e.target.value)} value={prefecture}>
        <option value="">都道府県</option>
        <option value='北海道'>北海道地方</option>
        <option value='青森県,岩手県,宮城県,秋田県,山形県,福島県'>東北地方</option>
        <option value='東京都,茨城県,栃木県,群馬県,埼玉県,千葉県,神奈川県'>関東地方</option>
        <option value='新潟県,富山県,石川県,福井県,山梨県,長野県,岐阜県,静岡県,愛知県'>中部地方</option>
        <option value='京都府,大阪府,三重県,滋賀県,兵庫県,奈良県,和歌山県'>近畿地方</option>
        <option value='鳥取県,島根県,岡山県,広島県,山口県'>中国地方</option>
        <option value='徳島県,香川県,愛媛県,高知県'>四国地方</option>
        <option value='福岡県,佐賀県,長崎県,大分県,熊本県,宮崎県,鹿児島県,沖縄県'>九州地方</option>
      </select>
      <input placeholder='キーワード（土木、施工、システム開発など）' onChange={(e) => setKeyWord(e.target.value)} value={keyWord} />
      <button onClick={() => link()}>検索</button>
      <p onClick={() =>  setOpen(true)}>詳しく検索</p>
    </div>

    {open ?
      <div className='bid_list_details'>
        <div className='bid_list_details_background' onClick={() => setOpen(false)}></div>
        <div className='bid_list_details_box'>

          <div className='bid_list_details_box_title'>
            <h4>入札情報 詳しく検索</h4>
            <button onClick={() => searchDetails()}>検索</button>
          </div>

          <div className='bid_list_details_box_down'>
            <div className='bid_list_details_box_down_title'>
              <p>キーワード</p>
              <p>期間（前）〜</p>
              <p>〜期間（後）</p>
            </div>
            <div className='bid_list_details_box_down_content'>
              <input onChange={(e) => setKeyWordDetails(e.target.value)} value={keyWordDetails} />
              <input type='date' onChange={(e) => setDateDetails([ e.target.value, dateDetails[1] ])} value={dateDetails} />
              <input type='date' onChange={(e) => setDateDetails([ dateDetails[0], e.target.value  ])} value={dateDetails} />
            </div>
          </div>

          <div className='bid_list_details_box_down'>
            <div className='bid_list_details_box_down_title'>
              <p>都道府県</p>
              <p>業種</p>
              <p>入札形式</p>
            </div>
            <div className='bid_list_details_box_down_content'>
              <select onChange={(e) => setPrefectureDetails(e.target.value)} value={prefecture}>
                <option value="">都道府県</option>
                <option value='北海道'>北海道地方</option>
                <option value='青森県,岩手県,宮城県,秋田県,山形県,福島県'>東北地方</option>
                <option value='東京都,茨城県,栃木県,群馬県,埼玉県,千葉県,神奈川県'>関東地方</option>
                <option value='新潟県,富山県,石川県,福井県,山梨県,長野県,岐阜県,静岡県,愛知県'>中部地方</option>
                <option value='京都府,大阪府,三重県,滋賀県,兵庫県,奈良県,和歌山県'>近畿地方</option>
                <option value='鳥取県,島根県,岡山県,広島県,山口県'>中国地方</option>
                <option value='徳島県,香川県,愛媛県,高知県'>四国地方</option>
                <option value='福岡県,佐賀県,長崎県,大分県,熊本県,宮崎県,鹿児島県,沖縄県'>九州地方</option>
              </select>
              <select onChange={(e) => setWorksDetails(e.target.value)} value={works}>
                <option value="">業種</option>
                <option value='開発,ソフト,情報,通信'>情報通信・Web・ソフトウェア関連</option>
                <option value='建設,工事,内装,建築'>建設・設備工事</option>
                <option value='メディア,出版,映像,広告,イベント'>メディア（出版・映像・広告・イベント）関連</option>
                <option value='コンサル,翻訳,通訳'>専門サービス業（BPO・コンサルティング・翻訳通訳等）</option>
                <option value='医療,医薬,福祉'>医療・医薬・福祉関連</option>
                <option value='調査,検査'>調査・検査業務</option>
                <option value='物品,購入'>建設関連物品</option>
                <option value='建物,道路,園地,管理'>建物・道路・園地管理関連</option>
                <option value='清掃,警備'>清掃・警備業務</option>
                <option value='派遣,業務'>派遣業務</option>
                <option value='工場,機械,機器'>工場用機械・機器（工具含む）</option>
                <option value='飲食'>飲食関連</option>
                <option value='日用,家庭用'>日用・家庭用物品</option>
                <option value='防犯,防災,消防関連'>防犯、防災・消防関連</option>
                <option value='物流'>物流関連業務</option>
                <option value='廃棄,回収,リサイクル'>廃棄物回収・リサイクル関連</option>
                <option value='インフラ,電力,ガス,水道,関連'>インフラ（電力・ガス・水道）関連</option>
                <option value='オフィス,事務用物品'>オフィス・事務用物品</option>
                <option value='教育・学習支援・保育関連'>教育・学習支援・保育関連</option>
                <option value='自動車,バス,鉄道,船舶,航空機関連'>自動車・バス・鉄道・船舶・航空機関連</option>
                <option value='包装,衣類'>包装・衣類関連</option>
                <option value='旅行,宿泊,インバウンド'>旅行・宿泊・インバウンド関連業務</option>
                <option value='修理,保守,点検'>修理・保守点検業務</option>
                <option value='土地,不動産'>土地・不動産関連</option>
                <option value='賃貸借'>賃貸借</option>
                <option value='金融,保険関連'>金融・保険関連</option>
                <option value='環境,衛生,実験,研究'>環境・衛生・実験研究関連</option>
                <option value='農業,畜産,造園,物品'>農業・畜産・造園関連物品</option>
                <option value='研修,講習業務'>研修・講習業務</option>
                <option value='売却,売払'>売却・売払</option>
              </select>
              <select onChange={(e) => setFormatDetails(e.target.value)} value={format}>
                <option value="">入札形式</option>
                <option value="一般競争入札">一般競争入札</option>
                <option value="企画競争(入札・コンペ・プロポーザル)">企画競争(入札・コンペ・プロポーザル)</option>
                <option value="指名競争入札">指名競争入札</option>
                <option value="公募">公募</option>
                <option value="資料提供招請">資料提供招請</option>
                <option value="意見招請">意見招請</option>
                <option value="随意契約">随意契約</option>
                <option value="見積(オープンカウンター)">見積(オープンカウンター)</option>
                <option value="不明">不明</option>
                <option value="希望制指名競争入札">希望制指名競争入札</option>
              </select>
            </div>
          </div>

          {/* mini var */}

          <div className='bid_list_details_box_down_mini'>
            <div className='bid_list_details_box_down_title_mini'>
              <p>期間（前）〜</p>
              <p>〜期間（後）</p>
            </div>
            <div className='bid_list_details_box_down_content'>
              <input type='date' onChange={(e) => setDateDetails([ e.target.value, dateDetails[1] ])} value={dateDetails} />
              <input type='date' onChange={(e) => setDateDetails([ dateDetails[0], e.target.value  ])} value={dateDetails} />
            </div>
          </div>

          <div className='bid_list_details_box_down_mini'>
            <div className='bid_list_details_box_down_title_mini'>
              <p>キーワード</p>
              <p>都道府県</p>
            </div>
            <div className='bid_list_details_box_down_content'>
              <input onChange={(e) => setKeyWordDetails(e.target.value)} value={keyWordDetails} />
              <select onChange={(e) => setPrefectureDetails(e.target.value)} value={prefecture}>
                <option value="">都道府県</option>
                <option value='北海道'>北海道地方</option>
                <option value='青森県,岩手県,宮城県,秋田県,山形県,福島県'>東北地方</option>
                <option value='東京都,茨城県,栃木県,群馬県,埼玉県,千葉県,神奈川県'>関東地方</option>
                <option value='新潟県,富山県,石川県,福井県,山梨県,長野県,岐阜県,静岡県,愛知県'>中部地方</option>
                <option value='京都府,大阪府,三重県,滋賀県,兵庫県,奈良県,和歌山県'>近畿地方</option>
                <option value='鳥取県,島根県,岡山県,広島県,山口県'>中国地方</option>
                <option value='徳島県,香川県,愛媛県,高知県'>四国地方</option>
                <option value='福岡県,佐賀県,長崎県,大分県,熊本県,宮崎県,鹿児島県,沖縄県'>九州地方</option>
              </select>
            </div>
          </div>
          
          <div className='bid_list_details_box_down_mini'>
            <div className='bid_list_details_box_down_title_mini'>
              <p>業種</p>
              <p>入札形式</p>
            </div>
            <div className='bid_list_details_box_down_content'>
            <select onChange={(e) => setWorksDetails(e.target.value)} value={works}>
              <option value="">業種</option>
              <option value="情報通信・Web・ソフトウェア関連">情報通信・Web・ソフトウェア関連</option>
              <option value="建設・設備工事">建設・設備工事</option>
              <option value="メディア（出版・映像・広告・イベント）関連">メディア（出版・映像・広告・イベント）関連</option>
              <option value="専門サービス業（BPO・コンサルティング・翻訳通訳等）">専門サービス業（BPO・コンサルティング・翻訳通訳等）</option>
              <option value="医療・医薬・福祉関連">医療・医薬・福祉関連</option>
              <option value="調査・検査業務">調査・検査業務</option>
              <option value="建設コンサルタント業務">建設コンサルタント業務</option>
              <option value="建設関連物品">建設関連物品</option>
              <option value="建物・道路・園地管理関連">建物・道路・園地管理関連</option>
              <option value="清掃・警備業務">清掃・警備業務</option>
              <option value="派遣業務">派遣業務</option>
              <option value="工場用機械・機器（工具含む）">工場用機械・機器（工具含む）</option>
              <option value="飲食関連">飲食関連</option>
              <option value="日用・家庭用物品">日用・家庭用物品</option>
              <option value="防犯、防災・消防関連">防犯、防災・消防関連</option>
              <option value="物流関連業務">物流関連業務</option>
              <option value="廃棄物回収・リサイクル関連">廃棄物回収・リサイクル関連</option>
              <option value="インフラ（電力・ガス・水道）関連">インフラ（電力・ガス・水道）関連</option>
              <option value="オフィス・事務用物品">オフィス・事務用物品</option>
              <option value="教育・学習支援・保育関連">教育・学習支援・保育関連</option>
              <option value="自動車・バス・鉄道・船舶・航空機関連">自動車・バス・鉄道・船舶・航空機関連</option>
              <option value="包装・衣類関連">包装・衣類関連</option>
              <option value="旅行・宿泊・インバウンド関連業務">旅行・宿泊・インバウンド関連業務</option>
              <option value="修理・保守点検業務">修理・保守点検業務</option>
              <option value="土地・不動産関連">土地・不動産関連</option>
              <option value="賃貸借">賃貸借</option>
              <option value="金融・保険関連">金融・保険関連</option>
              <option value="環境・衛生・実験研究関連">環境・衛生・実験研究関連</option>
              <option value="農業・畜産・造園関連物品">農業・畜産・造園関連物品</option>
              <option value="研修・講習業務">研修・講習業務</option>
              <option value="売却・売払">売却・売払</option>
              <option value="その他">その他</option>
            </select>
            <select onChange={(e) => setFormatDetails(e.target.value)} value={format}>
              <option value="">入札形式</option>
              <option value="一般競争入札">一般競争入札</option>
              <option value="企画競争(入札・コンペ・プロポーザル)">企画競争(入札・コンペ・プロポーザル)</option>
              <option value="指名競争入札">指名競争入札</option>
              <option value="公募">公募</option>
              <option value="資料提供招請">資料提供招請</option>
              <option value="意見招請">意見招請</option>
              <option value="随意契約">随意契約</option>
              <option value="見積(オープンカウンター)">見積(オープンカウンター)</option>
              <option value="不明">不明</option>
              <option value="希望制指名競争入札">希望制指名競争入札</option>
            </select>
            </div>
          </div>

          {/* mini mini var */}

          <div className='bid_list_details_box_down_mini_mini'>
            <p>キーワード</p>
            <input onChange={(e) => setKeyWordDetails(e.target.value)} value={keyWordDetails} />
          </div>

          <div className='bid_list_details_box_down_mini_mini'>
            <p>期間（前）〜</p>
            <input type='date' onChange={(e) => setDateDetails([ e.target.value, dateDetails[1] ])} value={dateDetails} />
          </div>

          <div className='bid_list_details_box_down_mini_mini'>
            <p>〜期間（後）</p>
            <input type='date' onChange={(e) => setDateDetails([ dateDetails[0], e.target.value  ])} value={dateDetails} />
          </div>

          <div className='bid_list_details_box_down_mini_mini'>
            <p>都道府県</p>
            <select onChange={(e) => setPrefectureDetails(e.target.value)} value={prefecture}>
              <option value="">都道府県</option>
              <option value='北海道'>北海道地方</option>
              <option value='青森県,岩手県,宮城県,秋田県,山形県,福島県'>東北地方</option>
              <option value='東京都,茨城県,栃木県,群馬県,埼玉県,千葉県,神奈川県'>関東地方</option>
              <option value='新潟県,富山県,石川県,福井県,山梨県,長野県,岐阜県,静岡県,愛知県'>中部地方</option>
              <option value='京都府,大阪府,三重県,滋賀県,兵庫県,奈良県,和歌山県'>近畿地方</option>
              <option value='鳥取県,島根県,岡山県,広島県,山口県'>中国地方</option>
              <option value='徳島県,香川県,愛媛県,高知県'>四国地方</option>
              <option value='福岡県,佐賀県,長崎県,大分県,熊本県,宮崎県,鹿児島県,沖縄県'>九州地方</option>
            </select>
          </div>
          
          <div className='bid_list_details_box_down_mini_mini'>
            <p>業種</p>
            <select onChange={(e) => setWorksDetails(e.target.value)} value={works}>
              <option value="">業種</option>
              <option value="情報通信・Web・ソフトウェア関連">情報通信・Web・ソフトウェア関連</option>
              <option value="建設・設備工事">建設・設備工事</option>
              <option value="メディア（出版・映像・広告・イベント）関連">メディア（出版・映像・広告・イベント）関連</option>
              <option value="専門サービス業（BPO・コンサルティング・翻訳通訳等）">専門サービス業（BPO・コンサルティング・翻訳通訳等）</option>
              <option value="医療・医薬・福祉関連">医療・医薬・福祉関連</option>
              <option value="調査・検査業務">調査・検査業務</option>
              <option value="建設コンサルタント業務">建設コンサルタント業務</option>
              <option value="建設関連物品">建設関連物品</option>
              <option value="建物・道路・園地管理関連">建物・道路・園地管理関連</option>
              <option value="清掃・警備業務">清掃・警備業務</option>
              <option value="派遣業務">派遣業務</option>
              <option value="工場用機械・機器（工具含む）">工場用機械・機器（工具含む）</option>
              <option value="飲食関連">飲食関連</option>
              <option value="日用・家庭用物品">日用・家庭用物品</option>
              <option value="防犯、防災・消防関連">防犯、防災・消防関連</option>
              <option value="物流関連業務">物流関連業務</option>
              <option value="廃棄物回収・リサイクル関連">廃棄物回収・リサイクル関連</option>
              <option value="インフラ（電力・ガス・水道）関連">インフラ（電力・ガス・水道）関連</option>
              <option value="オフィス・事務用物品">オフィス・事務用物品</option>
              <option value="教育・学習支援・保育関連">教育・学習支援・保育関連</option>
              <option value="自動車・バス・鉄道・船舶・航空機関連">自動車・バス・鉄道・船舶・航空機関連</option>
              <option value="包装・衣類関連">包装・衣類関連</option>
              <option value="旅行・宿泊・インバウンド関連業務">旅行・宿泊・インバウンド関連業務</option>
              <option value="修理・保守点検業務">修理・保守点検業務</option>
              <option value="土地・不動産関連">土地・不動産関連</option>
              <option value="賃貸借">賃貸借</option>
              <option value="金融・保険関連">金融・保険関連</option>
              <option value="環境・衛生・実験研究関連">環境・衛生・実験研究関連</option>
              <option value="農業・畜産・造園関連物品">農業・畜産・造園関連物品</option>
              <option value="研修・講習業務">研修・講習業務</option>
              <option value="売却・売払">売却・売払</option>
              <option value="その他">その他</option>
            </select>
          </div>
          
          <div className='bid_list_details_box_down_mini_mini'>
            <p>入札形式</p>
            <select onChange={(e) => setFormatDetails(e.target.value)} value={format}>
              <option value="">入札形式</option>
              <option value="一般競争入札">一般競争入札</option>
              <option value="企画競争(入札・コンペ・プロポーザル)">企画競争(入札・コンペ・プロポーザル)</option>
              <option value="指名競争入札">指名競争入札</option>
              <option value="公募">公募</option>
              <option value="資料提供招請">資料提供招請</option>
              <option value="意見招請">意見招請</option>
              <option value="随意契約">随意契約</option>
              <option value="見積(オープンカウンター)">見積(オープンカウンター)</option>
              <option value="不明">不明</option>
              <option value="希望制指名競争入札">希望制指名競争入札</option>
            </select>
          </div>

        </div>
      </div>
    : <></>}
    </>
  );
}

export default BlogText0001;
