import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index.js";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/4/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」申請枠・業種ごとに採択率を徹底分析！',
    description: '本記事では、申請枠ごとの採択率について解説させていただいております。その他、分析として業種別、委託先別の採択率もまとめております。本記事は、「どの枠が採択されやすいのか？」を知りたいという方へ向けて、本記事を執筆させていただきました。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}
      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」申請枠・業種ごとに採択率を徹底分析！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は、<strong>日本最大級の補助金である「事業再構築補助金」</strong>について解説させていただきます。<br />
            <br />
            本記事は、「<strong>どの枠が採択されやすいのか？</strong>」を知りたいという方へ向けて、本記事を執筆させていただきました。<br />
            <br />
            事業再構築補助金の採択率を知りたい方はぜひご覧ください！<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業再構築補助金の枠別採択率</span></li>
              <li><span>業種別の採択率概要</span></li>
              <li><span>認定支援機関別の採択状況</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、事業再構築補助金事務局が発表している、「第1～6回公募の結果について」を参考にして作成されたものとなっています。<br />
            <br />
            そのため、さらに詳細なデータが欲しいという方は、下記リンクから「第1～6回公募の結果について」、「事業再構築～虎の巻～」を参考にしてみてください！<br />
            <br />
            【事業再構築補助金第1～6回公募の結果について】<br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/pdf/result/koubo_kekka_gaiyou01.pdf' target="_blank" rel="nofollow noopener noreferrer">
              事業再構築補助金第１公募の結果について
            </a><br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/pdf/result/koubo_kekka_gaiyou02.pdf' target="_blank" rel="nofollow noopener noreferrer">
              事業再構築補助金第２公募の結果について
            </a><br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/pdf/result/koubo_kekka_gaiyou03.pdf' target="_blank" rel="nofollow noopener noreferrer">
              事業再構築補助金第３公募の結果について
            </a><br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/pdf/result/koubo_kekka_gaiyou04.pdf' target="_blank" rel="nofollow noopener noreferrer">
              事業再構築補助金第４公募の結果について
            </a><br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/pdf/result/koubo_kekka_gaiyou05.pdf' target="_blank" rel="nofollow noopener noreferrer">
              事業再構築補助金第５公募の結果について
            </a><br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/pdf/result/koubo_kekka_gaiyou06.pdf' target="_blank" rel="nofollow noopener noreferrer">
              事業再構築補助金第６公募の結果について
            </a><br />
            <br />
            【事業再構築補助金～虎の巻～】<br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/pdf/jigyokeikaku_guidebook.pdf' target="_blank" rel="nofollow noopener noreferrer">
              事業再構築補助金～虎の巻～
            </a><br />
            <br />
            なお、申請サポートのご依頼や不明点のご相談は、画面右側のお問い合わせよりお受けしておりますので、お気軽にご連絡ください。<br />
            <br />
            それでは、記事の解説に進ませていただきます！<br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">事業再構築補助金の枠別採択率</AnchorLink></li>
                <li><AnchorLink href="#2" offset="100">業種別の応募・採択割合</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">認定支援機関別の応募・採択件数</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.事業再構築補助金の枠別採択率</h2>
          </div>
          <p className="media_text_text">
            <br />
            この部分では、以下に記載されている<strong>申請枠の採択率</strong>について解説させていただきます。<br />
            ※第8回時点で公募要領に記載されている申請枠のみの解説となります。<br />
            <br />
            <strong>① 通常枠</strong><br />
            <strong>② 大規模賃金引上枠</strong><br />
            <strong>③ 回復・再生応援枠</strong><br />
            <strong>④ 最低賃金枠</strong><br />
            <strong>⑤ グリーン成長枠</strong><br />
            <strong>⑥ 原油価格・物価高騰等緊急対策枠</strong><br />
          </p>
          <h3 className='media_text_text_title'>通常枠</h3><br />
          <p className="media_text_text">
            通常枠は、<strong>最もスタンダードな申請枠</strong>となっており、過去の申請では最も応募件数、申請件数ともに多い枠です。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>項目</th><th>申請件数</th><th>採択件数</th><th>採択率</th>
              </tr>
              <tr>
                <td><strong>第１次</strong></td>
                <td>16,968</td>
                <td>5,104</td>
                <td>30.1%</td>
              </tr>
              <tr>
                <td><strong>第２次</strong></td>
                <td>14,859</td>
                <td>5,388</td>
                <td>36.3%%</td>
              </tr>
              <tr>
                <td><strong>第３次</strong></td>
                <td>15,423</td>
                <td>5,713</td>
                <td>37.0%</td>
              </tr>
              <tr>
                <td><strong>第４次</strong></td>
                <td>15,036</td>
                <td>5,700</td>
                <td>37.9%%</td>
              </tr>
              <tr>
                <td><strong>第５次</strong></td>
                <td>16,185</td>
                <td>6,441</td>
                <td>40.0%%</td>
              </tr>
              <tr>
                <td><strong>第６次</strong></td>
                <td>11,653</td>
                <td>5,297</td>
                <td>45.5%%</td>
              </tr>
              <tr>
                <td><strong>合計</strong></td>
                <td>90,124</td>
                <td>33,643</td>
                <td>37.3%</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3 className='media_text_text_title'>大規模賃金引上枠</h3><br />
          <p className="media_text_text">
            多くの従業員雇用を行いながら、<strong>継続的な賃金引上げを行うとともに生産性を向上させるという枠</strong>になっており、第3回から創設された枠となっています。<br />
            <br />
            毎公募で一般枠よりも10～20%ほど高い採択率となっておりますので、賃金引上を行う予定の事業者様は、ぜひご検討いただければと思います。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>項目</th><th>申請件数</th><th>採択件数</th><th>採択率</th>
              </tr>
              <tr>
                <td><strong>第３次</strong></td>
                <td>20</td>
                <td>12</td>
                <td>60%</td>
              </tr>
              <tr>
                <td><strong>第４次</strong></td>
                <td>12</td>
                <td>6</td>
                <td>50%</td>
              </tr>
              <tr>
                <td><strong>第５次</strong></td>
                <td>13</td>
                <td>8</td>
                <td>61.5%</td>
              </tr>
              <tr>
                <td><strong>第６次</strong></td>
                <td>9</td>
                <td>5</td>
                <td>55.6%</td>
              </tr>
              <tr>
                <td><strong>合計</strong></td>
                <td>54</td>
                <td>31</td>
                <td>57.4%</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3 className='media_text_text_title'>回復・再生応援枠</h3><br />
          <p className="media_text_text">
            <strong>新型コロナウイルスの影響により大きく売上が減少し、引き続き業績が厳しい事業者さまを重点的に支援する枠</strong>となっております。<br />
            ※緊急事態宣言特別枠に代わり、第6回から新設された枠となっております。<br />
            <br />
            回復・再生応援枠や過去の類似枠は、通常枠と比較して2倍ほどの採択率となっており、約2/3ほどの確率で推移していますので、条件に当てはまる方はぜひご検討ください！<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>項目</th><th>申請件数</th><th>採択件数</th><th>採択率</th>
              </tr>
              <tr>
                <td><strong>第１次</strong></td>
                <td>5,181</td>
                <td>2,866</td>
                <td>55.3%</td>
              </tr>
              <tr>
                <td><strong>第２次</strong></td>
                <td>5,893</td>
                <td>3,924</td>
                <td>66.6%</td>
              </tr>
              <tr>
                <td><strong>第３次</strong></td>
                <td>4,351</td>
                <td>2,901</td>
                <td>66.7%</td>
              </tr>
              <tr>
                <td><strong>第４次</strong></td>
                <td>4,217</td>
                <td>2,806</td>
                <td>66.5%</td>
              </tr>
              <tr>
                <td><strong>第５次</strong></td>
                <td>4,509</td>
                <td>3,006</td>
                <td>66.7%</td>
              </tr>
              <tr>
                <td><strong>第６次</strong></td>
                <td>2,933</td>
                <td>1,954</td>
                <td>66.6%</td>
              </tr>
              <tr>
                <td><strong>合計</strong></td>
                <td>27,084</td>
                <td>17,457</td>
                <td>64.5%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            ※ 第1.2回は特別枠、第3～5回は緊急事態宣言特別枠の数値を用いて算出
          </p>
          <br />
          <h3 className='media_text_text_title'>最低賃金枠</h3><br />
          <p className="media_text_text">
            <strong>最低賃金引上げの影響により、継続して雇用を行い続けるのが困難な事業者の皆様を支援する枠と</strong>なっており、第3回から創設されたものとなっております。<br />
            <br />
            <strong>最低賃金枠の採択率は全枠の中で最も高い採択率</strong>となっておりますので、条件を満たす方はぜひ最低賃金枠での提出をご検討ください。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>項目</th><th>申請件数</th><th>採択件数</th><th>採択率</th>
              </tr>
              <tr>
                <td><strong>第３次</strong></td>
                <td>469</td>
                <td>375</td>
                <td>80.0%</td>
              </tr>
              <tr>
                <td><strong>第４次</strong></td>
                <td>391</td>
                <td>290</td>
                <td>74.2%</td>
              </tr>
              <tr>
                <td><strong>第５次</strong></td>
                <td>306</td>
                <td>243</td>
                <td>79.4%</td>
              </tr>
              <tr>
                <td><strong>第６次</strong></td>
                <td>252</td>
                <td>216</td>
                <td>85.7%</td>
              </tr>
              <tr>
                <td><strong>合計</strong></td>
                <td>1,418</td>
                <td>1,124</td>
                <td>79.2%</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3 className='media_text_text_title'>グリーン成長枠</h3><br />
          <p className="media_text_text">
            <strong>研究・技術開発又は人材育成を行いながら、グリーン成長戦略「実行計画」14分野においての課題解決に資する取り組みを行う事業者の皆様を支援する枠</strong>となっており、第6回から新設されました。<br />
            <br />
            グリーン成長枠は初回ということもあり、下記のような採択率となっておりますが、国の政策を大きく反映している枠であるため、今後は採択率が上昇すると考えられます。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>項目</th><th>申請件数</th><th>採択件数</th><th>採択率</th>
              </tr>
              <tr>
                <td><strong>第6次</strong></td>
                <td>493</td>
                <td>197</td>
                <td>40.0%</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3 className='media_text_text_title'>原油価格・物価高騰等緊急対策枠（緊急対策枠）</h3><br />
          <p className="media_text_text">
            <strong>原油価格・物価高騰等の経済変化の影響を受け、売上が減少してしまっている事業者さまを支援するための枠</strong>となっており、第7回から新設された枠となっております。<br />
            <br />
            緊急対策枠の採択結果は現時点で発表されていないため、採択率は未発表です。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>２.業種別の応募・採択割合</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、第６回公募の結果を参考に、応募件数・採択件数の業種別割合をまとめさせていただきました。<br />
            <br />
            申請を検討されている事業者さまは、「どの業種が多く応募しているのか」、「どの業種が採択されやすくなっているのか」をご確認いただければと思います。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>業界項目</th><th>応募件数割合</th><th>採択件数割合</th><th>ポイント差</th>
              </tr>
              <tr>
                <td><strong>建設業</strong></td>
                <td>11.5%</td>
                <td>11.6%</td>
                <td>0.1</td>
              </tr>
              <tr>
                <td><strong>製造業</strong></td>
                <td>17.9%</td>
                <td>23.7%</td>
                <td>5.8</td>
              </tr>
              <tr>
                <td><strong>情報通信業</strong></td>
                <td>4.6%</td>
                <td>4.3%</td>
                <td>-0.3</td>
              </tr>
              <tr>
                <td><strong>運輸業、郵便業</strong></td>
                <td>1.5%</td>
                <td>1.5%</td>
                <td>0.0</td>
              </tr>
              <tr>
                <td><strong>卸売業、小売業</strong></td>
                <td>15.9%</td>
                <td>14.8%</td>
                <td>-1.1</td>
              </tr>
              <tr>
                <td><strong>不動産業、物品賃貸業</strong></td>
                <td>4.3%</td>
                <td>3.3%</td>
                <td>-1.0</td>
              </tr>
              <tr>
                <td><strong>学術研究、専門・技術サービス業</strong></td>
                <td>6.9%</td>
                <td>5.6%</td>
                <td>-1.3</td>
              </tr>
              <tr>
                <td><strong>宿泊業、飲食サービス業</strong></td>
                <td>15.4%</td>
                <td>15.8%</td>
                <td>0.4</td>
              </tr>
              <tr>
                <td><strong>生活関連サービス業、娯楽業</strong></td>
                <td>7.9%</td>
                <td>6.8%</td>
                <td>-1.1</td>
              </tr>
              <tr>
                <td><strong>教育、学習支援業</strong></td>
                <td>1.9%</td>
                <td>2.0%</td>
                <td>0.1</td>
              </tr>
              <tr>
                <td><strong>医療、福祉</strong></td>
                <td>3.4%</td>
                <td>3.3%</td>
                <td>-0.1</td>
              </tr>
              <tr>
                <td><strong>サービス業（ほかに分類されないもの）</strong></td>
                <td>6.6%</td>
                <td>5.7%</td>
                <td>-0.9</td>
              </tr>
              <tr>
                <td><strong>その他</strong></td>
                <td>2.2%</td>
                <td>1.6%</td>
                <td>-0.6</td>
              </tr>
              <tr>
                <td><strong>合計</strong></td>
                <td>100.0%</td>
                <td>100.0%</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3 className='media_text_text_title'>多く応募している業種（上位3業種）</h3><br />
          <p className="media_text_text">
            事業再構築補助金に多く応募されている業種は、以下の3業種となります。<br />
            <br />
            多く応募されている分、過去の採択事例等も多く出てくると思いますので、申請される事業者さまは、ぜひ調べてみてください。<br />
            <br />
            ① <strong>製造業</strong><br />
            ② <strong>卸売業、小売業</strong><br />
            ③ <strong>宿泊業、飲食サービス業</strong><br />
          </p>
          <h3 className='media_text_text_title'>多く採択されている業種（上位3業種）</h3><br />
          <p className="media_text_text">
            事業再構築補助金で多く採択されている業種は、以下の3業種となります。<br />
            各業種の採択例も記載しておりますので、該当する業種の方は是非ご覧ください。<br />
            <br />
            以下のリンクからその他の採択事例も参照いただけますので、ぜひ参考にしてみてください。<br />
            <a href='https://www.kyushu.meti.go.jp/seisaku/chusho/jirei/business_reconstruction/top.html#shukuhaku' target="_blank" rel="nofollow noopener noreferrer">
              リンク：事業再構築 採択事例
            </a><br />
            <br />
            ① <strong>製造業  採択例</strong><br />
            ・飲食店を行っていた業者が、食材加工品の製造・販売への業種転換を行う。<br />
            ・店舗用に什器製造を行っていた業者が、3Dシミュレーターを導入して個人用のオーダーメード高級家具製造を新たに行う。<br />
            ・日本酒・焼酎の製造を行っていた業者が、既存ノウハウを活かして新たなジャパニーズウイスキーの開発製造を行う。<br />
            <br />
            ② <strong>宿泊業、飲食サービス業  採択例</strong><br />
            ・ワーケーション用の宿泊設備を新たに整備し、インターネット環境やミニキッチンを整備することで、差別化を行いワーケーション需要のさらなる獲得を行う。<br />
            ・老舗料亭・旅館を営む業者が、ファミリー層などの新規顧客層獲得を目指し、屋上で焼肉料理を提供する事業を新たに展開する。<br />
            <br />
            ③ <strong>卸売業、小売業  採択例</strong><br />
            ・生鮮殻付牡蠣卸売業の売上が減少したことを踏まえ、「冷凍ハーフシェル牡蠣」を新たに加工製造する事で、海外市場への転換を行う。<br />
            ・家電機器の小売業を行っていた事業者が、IoT機器で利便性の向上を体感できるショールームを整備することで、新たに住宅設備機器、リフォームまで取り扱いを拡大する。<br />
            ・老舗の、お持ち製造を行っていた事業者が、小売店舗の集客力に業績が左右されるという課題を解決するために、「創作和菓子カフェ事業」を展開して新規顧客層の獲得を目指す。<br />
          </p>
          <h3 className='media_text_text_title'>採択率が高い業種（上位3業種）</h3><br />
          <p className="media_text_text">
            以下３業種は、応募割合に比べて採択割合が高い、「比較的採択されやすい業種」となっています。<br />
            <br />
            ① <strong>製造業</strong><br />
            ② <strong>宿泊業、飲食サービス業</strong><br />
            ③ <strong>建設業・教育、学習支援業（同率2業種）</strong><br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>３.認定支援機関別の応募・採択件数</h2>
          </div>
          <p className="media_text_text">
            <br />
            この部分では、<strong>認定支援機関別の応募件数や採択状況</strong>をまとめております。お時間に余裕のある事業者さまは、以下の表を参考に認定支援機関をお選びいただければと思います。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className=''>認定支援機関項目</th><th>応募案件数</th><th>採択案件数</th><th>採択率（%）</th>
              </tr>
              <tr>
                <td><strong>銀行</strong></td>
                <td>213</td>
                <td>107</td>
                <td>50.2%</td>
              </tr>
              <tr>
                <td><strong>地銀</strong></td>
                <td>2,259</td>
                <td>1,453</td>
                <td>64.3%</td>
              </tr>
              <tr>
                <td><strong>信用金庫</strong></td>
                <td>2,226</td>
                <td>1,245</td>
                <td>55.9%</td>
              </tr>
              <tr>
                <td><strong>信用組合</strong></td>
                <td>187</td>
                <td>94</td>
                <td>50.3%</td>
              </tr>
              <tr>
                <td><strong>商工中金</strong></td>
                <td>91</td>
                <td>54</td>
                <td>59.3%</td>
              </tr>
              <tr>
                <td><strong>税理士</strong></td>
                <td>1,733</td>
                <td>627</td>
                <td>36.2%</td>
              </tr>
              <tr>
                <td><strong>税理士法人</strong></td>
                <td>1,213</td>
                <td>495</td>
                <td>40.8%</td>
              </tr>
              <tr>
                <td><strong>公認会計士</strong></td>
                <td>445</td>
                <td>183</td>
                <td>41.1%</td>
              </tr>
              <tr>
                <td><strong>商工会</strong></td>
                <td>854</td>
                <td>405</td>
                <td>47.4%</td>
              </tr>
              <tr>
                <td><strong>商工会議所</strong></td>
                <td>1,215</td>
                <td>524</td>
                <td>43.1%</td>
              </tr>
              <tr>
                <td><strong>中小企業診断士</strong></td>
                <td>1,275</td>
                <td>720</td>
                <td>56.5%</td>
              </tr>
              <tr>
                <td><strong>行政書士</strong></td>
                <td>163</td>
                <td>65</td>
                <td>39.9%</td>
              </tr>
              <tr>
                <td><strong>民間コンサルティング会社</strong></td>
                <td>2,408</td>
                <td>1,344</td>
                <td>55.8%</td>
              </tr>
              <tr>
                <td><strong>一般社団法人</strong></td>
                <td>126</td>
                <td>69</td>
                <td>54.8%</td>
              </tr>
              <tr>
                <td><strong>公益財団法人</strong></td>
                <td>145</td>
                <td>84</td>
                <td>57.9%</td>
              </tr>
              <tr>
                <td><strong>コンサルタント</strong></td>
                <td>199</td>
                <td>70</td>
                <td>35.2%</td>
              </tr>
              <tr>
                <td><strong>その他</strong></td>
                <td>271</td>
                <td>120</td>
                <td>44.3%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            当社としましては、以下の2つの要件を満たしている認定支援機関をおすすめしております。<br />
            ① <strong>１公募での応募案件数が1,000件を超えている</strong><br />
            ② <strong>採択率が50.0%以上ある</strong><br />
            <br />
            具体的には、以下の認定支援機関になります。<br />
            ① <strong>地銀</strong><br />
            ② <strong>信用金庫</strong><br />
            ③ <strong>中小企業診断士</strong><br />
            ④ <strong>民間コンサルティング会社</strong><br />
            <br />
            なお、弊社では認定支援機関様のご紹介も行っておりますので、認定支援機関選びにお困りの方は、ぜひお気軽にご連絡いただければと思います。<br />
          </p>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>４.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、事業再構築補助金を申請したい事業者さまへ向けて、<strong>事業再構築補助金の採択率</strong>についてまとめさせていただきました。<br />
            <br />
            業種によっては、「<strong>採択率が低いなという印象を抱かれた方</strong>」もいると思いますが、<strong>加点書類等の用意等を行うことで採択率が上げる事も可能です</strong>ので、諦めずに挑戦していただければと思います。<br />
            <br />
            なお、本格的に事業再構築補助金の導入を検討されていましたら、<strong>弊社含め補助金に詳しい事業者へご相談いただければと思います</strong>。<br />
            <br />
            ご相談は、画面右側の問い合わせにて、お待ちしております。<br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


