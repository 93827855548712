import './styles.css';
import Nav from './nav/index';
import Header from './header/index';
import Mvvc from './mvvc/index';
import Service from './service/index';
import Blog from './blog/index';
import Recruit from './recruit/index';
import Footers from './footer/index';
import DocumentMeta from 'react-document-meta';

const Footer = () => {

  const meta = {
    title: 'トランク合同会社',
    description: '美容業界で現在抱えている「顧客数減少」「人材不足」「労働環境」など、様々な課題を解決する会社です。主に、web制作、アプリ制作、補助金、助成金を軸としており、お客さまの要望に幅広くお応えしております。',
    canonical: 'https://trankllc.com',
    meta: {charset: 'utf-8',}
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <Header />
      <Mvvc />
      <Service />
      <Blog />
      <Recruit />
      <Footers />
    </DocumentMeta>
    </>
  );
}

export default Footer;
