import './styles.css';
import simulatorIcon from './../../../../../image/simulator/simulator_icon_white.png';
import { Link } from 'react-router-dom';

const SimulatorResult = () => {

  return(
  <>
  <div className='simulator_result_type_title'>
    <img src={simulatorIcon} alt='' />
    <h4>関連記事</h4>
  </div>
  <div className='simulator_result_type_desc'>
    <div>
      <div>
        <h5 className='simulator_result_type_desc_width'>事業再構築補助金の概要</h5>
      </div>
      <Link to='/media/subsidy/business/0001'>
        <h4 style={{backgroundColor : 'white', color : '#EC6104'}}>記事を確認</h4>
      </Link>
    </div>
    <div>
      <div>
        <h5 className='simulator_result_type_desc_width'>事業再構築補助金の申請要件</h5>
      </div>
      <Link to='/media/subsidy/business/0002'>
        <h4 style={{backgroundColor : 'white', color : '#EC6104'}}>記事を確認</h4>
      </Link>
    </div>
    <div>
      <div>
        <h5 className='simulator_result_type_desc_width'>事業再構築補助金の経費分類</h5>
      </div>
      <Link to='/media/subsidy/business/0003'>
        <h4 style={{backgroundColor : 'white', color : '#EC6104'}}>記事を確認</h4>
      </Link>
    </div>
    <div>
      <div>
        <h5 className='simulator_result_type_desc_width'>事業再構築補助金の提出書類</h5>
      </div>
      <Link to='/media/subsidy/business/0005'>
        <h4 style={{backgroundColor : 'white', color : '#EC6104'}}>記事を確認</h4>
      </Link>
    </div>
    <div>
      <div>
        <h5 className='simulator_result_type_desc_width'>市場縮小要件</h5>
      </div>
      <Link to='/media/subsidy/business/0006'>
        <h4 style={{backgroundColor : 'white', color : '#EC6104'}}>記事を確認</h4>
      </Link>
    </div>
    <div>
      <div>
        <h5 className='simulator_result_type_desc_width'>事業再構築補助金の加点書類</h5>
      </div>
      <Link to='/media/subsidy/business/0022'>
        <h4 style={{backgroundColor : 'white', color : '#EC6104'}}>記事を確認</h4>
      </Link>
    </div>
    <div>
      <div>
        <h5 className='simulator_result_type_desc_width'>事業再構築補助金の実績報告と交付申請</h5>
      </div>
      <Link to='/media/subsidy/business/0006'>
        <h4 style={{backgroundColor : 'white', color : '#EC6104'}}>記事を確認</h4>
      </Link>
    </div>
    <div>
      <div>
        <h5 className='simulator_result_type_desc_width'>事業再構築補助金の交付申請</h5>
      </div>
      <Link to='/media/subsidy/business/0023'>
        <h4 style={{backgroundColor : 'white', color : '#EC6104'}}>記事を確認</h4>
      </Link>
    </div>
    <div>
      <div>
        <h5 className='simulator_result_type_desc_width'>事業再構築補助金の実績報告</h5>
      </div>
      <Link to='/media/subsidy/business/0024'>
        <h4 style={{backgroundColor : 'white', color : '#EC6104'}}>記事を確認</h4>
      </Link>
    </div>
  </div>

  </>
  );
}

export default SimulatorResult;
