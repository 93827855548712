import './styles.css';
import simulatorIcon from './../../../../../image/simulator/simulator_icon_white.png';
import { useState, useEffect } from 'react';

const SimulatorResult = (props) => {

  const [ maximumAmount, setMaximumAmount ] = useState(0);
  const [ subsidyInvest, setSubsidyInvest ] = useState(0);

  useEffect(() => {

    if (props.people === 0 || props.people === 1 || props.people === 2 || props.people === 3) {
      setMaximumAmount(2000);
    } else if (props.people === 4) {
      setMaximumAmount(4000);
    } else if (props.people === 5) {
      setMaximumAmount(5000);
    } else if (props.people === 6) {
      setMaximumAmount(7000);
    }

    if (props.invest === 0 || props.invest === 1) {
      setSubsidyInvest(Math.round(500 / 2 * 1));
    } else if (props.invest === 2) {
      setSubsidyInvest(Math.round(1000 / 2 * 1));
    } else if (props.invest === 3) {
      setSubsidyInvest(Math.round(1500 / 2 * 1));
    } else if (props.invest === 4) {
      setSubsidyInvest(Math.round(2000 / 2 * 1));
    } else if (props.invest === 5) {
      setSubsidyInvest(Math.round(3000 / 2 * 1));
    } else if (props.invest === 6) {
      setSubsidyInvest(10000);
    }

  }, [props.people, props.invest]);

  // 【従業員数 20 人以下】 100 万円 ～ 2,000 万円
  // 【従業員数 21~50 人】 100 万円 ～ 4,000 万円
  // 【従業員数 51～100 人】 100 万円 ～ 5,000 万円
  // 【従業員数 101 人以上】 100 万円 ～ 7,000 万円

  return(
  <>
  <div className='simulator_result_type_title'>
    <img src={simulatorIcon} alt='' />
    <h4>成長枠</h4>
  </div>
  <div className='simulator_result_type_desc'>
    <div style={{display : 'block', height : ''}}>
      <div>
        <h5>成長枠とは？</h5>
      </div>
      <p>成長している業種への転換を行うことで、売上増加を目指す事業者を支援する枠<br />主な申請要件：左のリストに新規事業が含まれること<span style={{fontSize : '12px'}}>(https://jigyou-saikouchiku.go.jp/pdf/seichowaku_list.pdf)</span></p>
    </div>
    <div>
      <div>
        <h5>補助率</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104'}}>2分の1</h4>
    </div>
    <div>
      <div>
        <h5>交付申請金額</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104'}}>{subsidyInvest === 10000 ? "〜" + maximumAmount : subsidyInvest >= maximumAmount ? maximumAmount : subsidyInvest}万円</h4>
    </div>
    <div>
      <div>
        <h5>補助上限金額</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104'}}>{maximumAmount}万円</h4>
    </div>
    <p>成長枠は追加の要件で補助上限や補助率が異なるため、詳細な内容を知りたい場合はお問い合わせください</p>
  </div>
  </>
  );
}

export default SimulatorResult;
