import './styles.css';
import Image1 from './../../../../image/hojokin/customer1.png';
import Image2 from './../../../../image/hojokin/customer2.png';
import Image3 from './../../../../image/hojokin/customer3.png';
import Image4 from './../../../../image/adbusiness/ex4.png';
import Image5 from './../../../../image/hojokin/customer5.png';
import Image6 from './../../../../image/hojokin/customer6.png';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import firebase from "firebase/compat/app"

const Help = (props) => {

  function btn() {
    firebase.firestore().collection('analytics').doc(props.uid)
    .update({
      button4: true,
    })
  }

  return (
    <>
    <div className='hojokin_results'>
      <h5>実際の効果は？</h5>
      <h4>サービスの使用事例とお客さまの声</h4>
      <div className='hojokin_results_items' >
        <div className='hojokin_results_item'>
          <div>
            <img src={Image1} alt='' />
            <div>
              <h4>製造技術を活用した、建設機械部品の完全受注生産事業への進出</h4>
              <div></div>
              <p>既存事業：製造業、金属加工品製造事業<br />新規事業：製造業、建設機械製品製造業<br />従業員数：17名<br />投資金額：2,300万円(設備導入費)<br /><strong>自己負担額：800万円(-65.2%)</strong></p>
            </div>
          </div>
        </div>
        <div className='hojokin_results_item'>
          <div>
            <img src={Image2} alt='' />
            <div>
              <h4>コンサルティングノウハウを活かした、食品製造事業の開始</h4>
              <div></div>
              <p>既存事業：飲食店コンサルティング事業<br />新規事業：飲食事業、パン製造・販売事業<br />従業員数：4名<br />投資金額：1,600万円(内装工事費、設備導入費)<br />自己負担額：600万円(-62.5%)</p>
            </div>
          </div>
        </div>
        <div className='hojokin_results_item'>
          <div>
            <img src={Image3} alt='' />
            <div>
              <h4>コワーキングスペース事業の実施によるコロナに対応した事業展開</h4>
              <div></div>
              <p>既存事業：卸売業、アンティーク家具販売事業<br />新規事業：情報通信業、コワーキング事業<br />従業員数：3名<br />投資金額：2,500万円(内装工事費、設備導入費)<br />自己負担額：850万円(-66.0%)</p>
            </div>
          </div>
        </div>
        <div className='hojokin_results_item'>
          <div>
            <img src={Image4} alt='' />
            <div>
              <h4>クラウド型システム販売事業による物価高騰という脅威からの脱却</h4>
              <div></div>
              <p>既存事業：小売業、健康食品販売事業<br />新規事業：情報通信業、システム販売事業<br />従業員数：0名<br />投資金額：800万円(開発費、広告宣伝費)<br />自己負担額：200万円(-75.0%)</p>
            </div>
          </div>
        </div>
        <div className='hojokin_results_item'>
          <div>
            <img src={Image5} alt='' />
            <div>
              <h4>コロナ禍の運動不足を解決する、フィットネスクラブ事業への進出</h4>
              <div></div>
              <p>既存事業：設備工事業、電気工事業<br />新規事業：サービス業、フィットネス事業<br />従業員数：7名<br />投資金額：3,000万円(内装工事費、外注費等)<br />自己負担額：1,250万円(-58.3%)</p>
            </div>
          </div>
        </div>
        <div className='hojokin_results_item'>
          <div>
            <img src={Image6} alt='' />
            <div>
              <h4>顧客拡大を目的とした、イタリアンFC事業への新規参入</h4>
              <div></div>
              <p>既存事業：飲食事業、居酒屋・鍋事業<br />新規事業：飲食事業、イタリアン料理事業<br />従業員数：40名<br />投資金額：5,700万円(内装工事費、設備導入費)<br />自己負担額：2,700万円(-52.6%)</p>
            </div>
          </div>
        </div>
      </div>
      <AnchorLink href="#1" offset="170"><div className='hojokin_results_btn' onClick={() => btn()}>申請できるかを相談する</div></AnchorLink>
    </div>
    </>
  );
}

export default Help;