const BlogText0001 = (props) => {

  return(
    <div className='management_review_content_1'>
      <h4>自社の状況に関する質問（2問）</h4>
      <h5>Q1.営業年数</h5>
      <input onChange={(e) => props.setOperationYears(e.target.value)} />
      <h5>Q2.民事再生法又は会社更生法の適用の有無</h5>
      <select onChange={(e) => props.setLaw(e.target.value)}>
        <option value="">選択</option>
        <option value='はい'>はい</option>
        <option value='いいえ'>いいえ</option>
      </select>
    </div>
  );
}

export default BlogText0001;
