import './styles.css';
import Service2 from './../../../../image/help/service2.png';
import comment from './../../../../image/help/comment.png';
import Service2_1 from './../../../../image/help/service2_1.png';

const Help = () => {
  
  return (
    <>
    <div className='help_service_title'>
      <div className='help_service_title_left'>
        <h6>2</h6>
        <h3>料金、質ともに上位</h3>
      </div>
      <div className='help_service_title_right'>
        <img src={Service2_1} alt='' />
        <div>
          <img src={comment} alt='' />
          <p>一般的な他者さまの価格、採択率等を比較したものとなっておりますが、お値段、質ともに業界上位の価格を提示させていただいております。</p>
        </div>
      </div>
    </div>
    <p className='help_service_title_text'>一般的な他者さまの価格、採択率等を比較したものとなっておりますが、お値段、質ともに業界上位の価格を提示させていただいております。</p>
    <div className='help_service1_img'>
      <img src={Service2} alt='' />
    </div>
    </>
  );
}

export default Help;
