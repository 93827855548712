import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/24/top.png";
import Image1 from "./../../../../../image/media/business/24/1.png";
import Image2 from "./../../../../../image/media/business/24/2.png";
import Image3 from "./../../../../../image/media/business/24/3.png";
import Image4 from "./../../../../../image/media/business/24/4.png";
import Image5 from "./../../../../../image/media/business/24/5.png";
import Image6 from "./../../../../../image/media/business/24/6.png";
import Image7 from "./../../../../../image/media/business/24/7.png";
import Image8 from "./../../../../../image/media/business/24/8.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」実績報告は、事前準備が1番大切！',
    description: '本記事では、実績報告の手続きに必要な書類と手続きの注意点について解説させていただいております。なお、対象経費ごとの説明もご案内しております。本記事は、「実績報告ってどうやれば良いかわからない。」という方へ向けて、記事を執筆させていただきました。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」実績報告は、事前準備が1番大切！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。<strong>補助金コンサルタントのトランク合同会社</strong>です。<br />
            今回は、日本最大級の補助金である事業再構築補助金の「実績報告」について解説させていただきます。<br />
            <br />
            本記事は、「<strong>実績報告ってどうやれば良いかわからない。</strong>」という方へ向けて、記事を執筆させていただきました。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>実績報告とは</span></li>
              <li><span>実績報告の流れ</span></li>
              <li><span>実績報告における必須書類・注意点</span></li>
            </ul>
          </div>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">事業再構築補助金の実績報告とは？</AnchorLink></li>
                <li><AnchorLink href="#2" offset="100">補助事業実施期間の流れと注意点</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">書類の準備における流れ・注意点</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">書類のアップロードにおける流れ・注意点</AnchorLink></li>
                <li><AnchorLink href="#5" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.事業再構築補助金の実績報告とは？</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、<strong>交付申請が完了した事業者さまへ向けて、その後の手続きである「実績報告」についてご紹介します</strong>。<br />
            <br />
            少しでも概要、流れを知っていると、実績報告がスムーズになりますので、１つずつ確認いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>実績報告の概要</h3><br />
          <p className="media_text_text">
            実績報告とは、<strong>導入設備の試運転や工事施工など補助事業が終了した後に、補助事業で行った内容をまとめる手続きとなります</strong>。<br />
            <br />
            具体的な書類、その流れについては、下記にて解説させていただきます。<br />
          </p>
          <h3 className='media_text_text_title'>実績報告の流れ</h3><br />
          <p className="media_text_text">
            実績報告の流れとしては、大きく①補助事業の実施、②書類の用意/収集、③書類の作成、③書類のアップロードの４点となります。<br />
            <br />
            各プロセスにおける概要や注意点についても以下に記しておりますので、ご確認ください。<br />
            <br />
            <strong>① 補助事業の実施</strong><br />
            「交付決定通知書」を受け取ったのち、実際に採択された事業計画を実施していきます。<br />
            <br />
            交付申請がお済みでない方は、以下の記事をご参照ください。<br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/documents.php' target="_blank" rel="nofollow noopener noreferrer">
              【交付申請とは】事業再構築補助金の交付申請を徹底解剖！
            </a><br />
            ↓<br />
            <strong>② 書類の用意/収集</strong><br />
            実績報告では、実施した実績を報告するため、交付申請で取得した見積書や発注書、納品書、請求書などの書類が経費ごとに必要となりますので、書類を用意していきます。<br />
            ↓<br />
            <strong>③ 書類の作成</strong><br />
            書類準備が完了後、実際にお支払いいただいた金額を精査するため、実績報告書や経費明細表などの書類の作成を行います。<br />
            ※詳しくは、以下にて解説しています。<br />
            ↓<br />
            <strong>④ 書類のアップロード</strong><br />
            書類の作成が完了したら、電子申請システム「J-grants」にアップロードを行います。<br />
            ※詳しくは、以下にて解説しています。<br />
            <br />
            なお、書類のアップロードの前に地域中央会事務局による確認が入る場合がありますので、その際は必要書類一式をzipファイルにまとめて、地域中央会事務局宛てにメールでお送りいただければと思います。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.補助事業実施期間の流れと注意点</h2>
          </div>
          <p className="media_text_text">
            <br />
            実績報告では、上述した通りさまざまな書類を用意しなければいけません。そこで、<strong>書類の用意がスムーズにいく方法をまとめさせていただきました</strong>。<br />
            <br />
            実施の流れのほかに、注意事項もまとめておりますので、事前に確認いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>具体的な流れ</h3><br />
          <p className="media_text_text">
            <strong>① 必要経費の確認</strong><br />
            交付申請時に「どの業者にいくらで設備の発注や工事を依頼する予定だったか。」を確認します。<br />
            <br />
            <strong>② 発注</strong><br />
            経費項目、金額の確認のの地、実際に発注や工事業者へ依頼を進めていきます。<br />
            <br />
            <strong>発注段階での注意点</strong><br />
            ① 代金の支払いは必ず納品後に行うこと。<br />
            ② 見積書と同じ内容で発注をすること。<br />
            ③ 納品日を予め確認しておくこと。<br />
            <br />
            <strong>③ 納品</strong><br />
            発注が終わると設備の納品や工事の着手を行います。<br />
            <br />
            その際に、大きく分けて以下の３点をご対応いただく必要がございます。<br />
            <br />
            (１) <strong>納品前後の写真撮影</strong><br />
            実績報告では実際に導入したかどうかチェックするために経費ごとに写真を撮影する必要がございます。<br />
            <br />
            具体的に必要な写真は、以下に記載させていただきました。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>経費区分</th><th>必要なシーン</th>
              </tr>
              <tr>
                <td>建物費</td><td>工事着工前・工事作業中・完了後の３枚</td>
              </tr>
              <tr>
                <td>機械装置・システム開発費</td><td>設置前・搬入時・据付時・設置後の４枚（設備ごと）</td>
              </tr>
              <tr>
                <td>外注費</td><td>外注前の加工品等の写真</td>
              </tr>
              <tr>
                <td>広告宣伝・販売促進費</td>
                <td>
                  配布物（ポスター・チラシ・パンフレット等）の写真
                  展示会・セミナー開催当日の会場の様子がわかる写真
                </td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            <strong>(２) 納品書の発行依頼（建物費・機械装置システム開発費・外注費・広告宣伝費のみ）</strong><br />
            納品書をメーカーやディーラーに発行を依頼します。<br />
            <br />
            その際は、納品書へ手書きで「検収　＜納品日＞＜代表者役職＞＜氏名＞」と記載してください。<br />
            <img src={Image1} className='media_img' alt='' />
            <br />
            <strong>(３) 納品後のシール貼り付け</strong><br />
            取得した資産に下記のようにシールを型番近くに貼り付けていただきます。<br />
            複数台ある場合には、機－１、機－２，機－３のように番号を振り分けをする必要がありますので、ご対応いただければと思います。<br />
            <img src={Image2} className='media_img' alt='' />
            <br />
            <strong>④ 代金の支払い</strong><br />
            納品が終了し次第、請求書の発行を依頼を行い、支払いを完了させます。<br />
            <br />
            代金支払い時にも注意点がございますので、下記にまとめさせていただきました。<br />
            <strong>① 原則銀行振込で対応すること</strong><br />
            <strong>② 支払い証書を保管すること</strong><br />
            <strong>③ 通帳の取引のあるページを予めコピーしておくこと</strong><br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.書類の準備における流れ・注意点</h2>
          </div>
          <p className="media_text_text">
            <br />
            書類の準備に関して、経費ごとに複数の書類が必要となりますので、予め確認しておきましょう。<br />
          </p>
          <h3 className='media_text_text_title'>経費にかかわらず必要となるもの</h3><br />
          <p className="media_text_text">
            計上した経費に関わらず全事象者様にとって必要となる書類がありますので、以下に記述します。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>必要書類</th><th>詳細・注意事項など</th>
              </tr>
              <tr>
                <td>出納帳</td><td>〈参考様式19〉を用いて作成</td>
              </tr>
              <tr>
                <td>通帳コピー</td><td>表紙・中表紙・取引部分が記載されているページの３点</td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>建物費</h3><br />
          <p className="media_text_text">
            事業再構築補助金で建物費を計上している場合には最低でも11種類の書類が必要となります。建物費を計上されている事業者様は予め確認しておきましょう。
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>必要書類（太字は必須）</th><th>詳細・注意事項など</th>
              </tr>
              <tr>
                <td>見積依頼書</td><td>交付申請時に提出したものでOK</td>
              </tr>
              <tr>
                <td>見積書・相見積書</td><td>交付申請時に提出したものでOK</td>
              </tr>
              <tr>
                <td>発注書</td><td>必ず交付決定以降の日にちで作成</td>
              </tr>
              <tr>
                <td>売買契約書</td><td>発注日と同じ日付でOK</td>
              </tr>
              <tr>
                <td>着手前・作業中・完了後の写真</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>納品書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>検収書</td><td>納品書に手書きで検収と書いてあれば不要</td>
              </tr>
              <tr>
                <td>工事完了後の図面</td><td></td>
              </tr>
              <tr>
                <td>工事完了後の工事内訳書または</td><td></td>
              </tr>
              <tr>
                <td>請求書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>支払い証書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>領収書</td><td></td>
              </tr>
              <tr>
                <td>預り金元帳</td><td>＜参考様式19＞で代用可能</td>
              </tr>
              <tr>
                <td>源泉所得税の納付書のコピー</td><td></td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>機械装置・システム開発費</h3><br />
          <p className="media_text_text">
            事業再構築補助金で建物費を計上している場合には、最低でも8種類の書類が必要となります。建物費を計上されている事業者様は予め確認しておきましょう。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>必要書類（太字は必須）</th><th>交付申請時に提出したものでOK</th>
              </tr>
              <tr>
                <td>見積依頼書</td><td>交付申請時に提出したものでOK</td>
              </tr>
              <tr>
                <td>見積書・相見積書</td><td>交付申請時に提出したものでOK</td>
              </tr>
              <tr>
                <td>発注書</td><td>必ず交付決定以降の日にちで作成</td>
              </tr>
              <tr>
                <td>売買契約書</td><td>発注日と同じ日付でOK</td>
              </tr>
              <tr>
                <td>着手前・作業中・完了後の写真</td><td>上述の通り<br />＜参考様式17＞に貼付し、PDF化</td>
              </tr>
              <tr>
                <td>送付伝票の写真</td><td>メーカー直接搬入時には不要</td>
              </tr>
              <tr>
                <td>システム等のスクリーンショット画像</td><td>＜参考様式17＞に貼付し、PDF化</td>
              </tr>
              <tr>
                <td>納品書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>請求書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>支払い証書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>領収書</td><td></td>
              </tr>
              <tr>
                <td>預り金元帳</td><td>＜参考様式19＞で代用可能</td>
              </tr>
              <tr>
                <td>＜参考様式19＞で代用可能</td><td></td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>技術導入費</h3><br />
          <p className="media_text_text">
            技術導入費を計上されている事業者様は以下のような書類が必要となります。最低でも3種類の書類が必要となりますので、予め確認しましょう。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>必要書類（太字は必須）</th><th>交付申請時に提出したものでOK</th>
              </tr>
              <tr>
                <td>見積書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>契約書</td><td></td>
              </tr>
              <tr>
                <td>指導契約書</td><td>＜参考様式5＞で代用可能です。 </td>
              </tr>
              <tr>
                <td>専門家業務報告書</td><td>＜参考様式11＞で代用可能です。</td>
              </tr>
              <tr>
                <td>着手前・作業中・完了後の写真</td><td>上述の通り<br />＜参考様式17＞に貼付し、PDF化</td>
              </tr>
              <tr>
                <td>請求書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>領収書</td><td></td>
              </tr>
              <tr>
                <td>預り金元帳</td><td>＜参考様式19＞で代用可能です。</td>
              </tr>
              <tr>
                <td>源泉所得税納付書のコピー</td><td></td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>専門家経費</h3><br />
          <p className="media_text_text">
            専門家経費を計上されている事業者様は以下のような書類が必要となりますので、予め確認しましょう。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>必要書類（太字は必須）</th><th>交付申請時に提出したものでOK</th>
              </tr>
              <tr>
                <td>見積書・相見積書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>契約書</td><td></td>
              </tr>
              <tr>
                <td>専門家就任承諾書</td><td>＜参考様式10＞で代用可能です。 </td>
              </tr>
              <tr>
                <td>専門家業務報告書</td><td>＜参考様式11＞で代用可能です。</td>
              </tr>
              <tr>
                <td>旅費明細書</td><td></td>
              </tr>
              <tr>
                <td>宿泊先の領収書</td><td></td>
              </tr>
              <tr>
                <td>宿泊証明書</td><td>＜参考様式14＞で代用可能です。</td>
              </tr>
              <tr>
                <td>航空券、切符領収書など<br />旅費の証明となる書類</td><td></td>
              </tr>
              <tr>
                <td>請求書</td><td></td>
              </tr>
              <tr>
                <td>支払い証書</td><td></td>
              </tr>
              <tr>
                <td>領収書</td><td></td>
              </tr>
              <tr>
                <td>預り金元帳</td><td>＜参考様式19＞で代用可能です。</td>
              </tr>
              <tr>
                <td>源泉所得税納付書のコピー</td><td></td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>運搬費</h3><br />
          <p className="media_text_text">
            運搬費を計上されている事業者様は以下のような書類が必要となります。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>必要書類（太字は必須）</th><th>交付申請時に提出したものでOK</th>
              </tr>
              <tr>
                <td>見積書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>移送先・発送先リスト</td><td></td>
              </tr>
              <tr>
                <td>請求書</td><td>上述の通り </td>
              </tr>
              <tr>
                <td>支払い証書</td><td></td>
              </tr>
              <tr>
                <td>領収書</td><td></td>
              </tr>
              <tr>
                <td>預り金元帳</td><td>＜参考様式19＞で代用可能です。</td>
              </tr>
              <tr>
                <td>源泉所得税納付書のコピー</td><td></td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>クラウドサービス利用費</h3><br />
          <p className="media_text_text">
            クラウドサービス利用費を計上されている事業者様は以下のような書類が必要となりますので予め確認しましょう。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>必要書類（太字は必須）</th><th>交付申請時に提出したものでOK</th>
              </tr>
              <tr>
                <td>見積書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>発注書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>売買契約書</td><td>上述の通り </td>
              </tr>
              <tr>
                <td>請求書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>支払い証書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>領収書</td><td></td>
              </tr>
              <tr>
                <td>ログイン後の登録者情報の画面のスクリーンショット</td><td>＜参考様式17＞に貼付し、PDF化</td>
              </tr>
              <tr>
                <td>開発したアプリケーション等のトップページのスクリーンショット</td><td>＜参考様式17＞に貼付し、PDF化</td>
              </tr>
              <tr>
                <td>預り金元帳</td><td>＜参考様式19＞でも代用可能</td>
              </tr>
              <tr>
                <td>源泉所得税納付書のコピー</td><td></td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>外注費</h3><br />
          <p className="media_text_text">
            外注費を計上されている事業者様は以下の書類を準備しましょう。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>必要書類（太字は必須）</th><th>交付申請時に提出したものでOK</th>
              </tr>
              <tr>
                <td>見積書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>発注書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>売買契約書</td><td>上述の通り </td>
              </tr>
              <tr>
                <td>請求書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>支払い証書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>領収書</td><td></td>
              </tr>
              <tr>
                <td>外注前の加工品の写真</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>納品後の加工品の写真</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>預り金元帳</td><td>＜参考様式19＞でも代用可能</td>
              </tr>
              <tr>
                <td>源泉所得税納付書のコピー</td><td></td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>知的財産権等関連経費</h3><br />
          <p className="media_text_text">
            知的財産権等関連経費を計上されている事業者様は以下のような書類が必要となりますので予め確認しましょう。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>必要書類（太字は必須）</th><th>交付申請時に提出したものでOK</th>
              </tr>
              <tr>
                <td>見積書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>発注書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>売買契約書</td><td>上述の通り </td>
              </tr>
              <tr>
                <td>請求書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>支払い証書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>領収書</td><td></td>
              </tr>
              <tr>
                <td>預り金元帳</td><td>＜参考様式19＞でも代用可能</td>
              </tr>
              <tr>
                <td>源泉所得税納付書のコピー</td><td></td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>広告宣伝・販売促進費</h3><br />
          <p className="media_text_text">
            広告宣伝・販売促進費を計上されている事業者は以下の書類を準備いただく必要がございますので、予め確認しましょう。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>必要書類（太字は必須）</th><th>交付申請時に提出したものでOK</th>
              </tr>
              <tr>
                <td>見積書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>発注書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>売買契約書</td><td>上述の通り </td>
              </tr>
              <tr>
                <td>納品書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>請求書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>支払い証書</td><td></td>
              </tr>
              <tr>
                <td>領収書</td><td></td>
              </tr>
              <tr>
                <td>補助対象物件受払簿</td><td></td>
              </tr>
              <tr>
                <td>配布物の写真</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>展示会など開催した会場の様子がわかる写真</td><td></td>
              </tr>
              <tr>
                <td>預り金元帳</td><td>＜参考様式19＞でも代用可能</td>
              </tr>
              <tr>
                <td>源泉所得税納付書のコピー</td><td></td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>研修費</h3><br />
          <p className="media_text_text">
            研修費を計上されている事業者様は以下のような書類が必要となりますので、予め確認しましょう。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_3_1'>必要書類（太字は必須）</th><th>交付申請時に提出したものでOK</th>
              </tr>
              <tr>
                <td>見積書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>申込書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>契約書</td><td>上述の通り </td>
              </tr>
              <tr>
                <td>請求書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>支払い証書</td><td>上述の通り</td>
              </tr>
              <tr>
                <td>研修修了が確認できる書類</td><td></td>
              </tr>
              <tr>
                <td>預り金元帳</td><td>＜参考様式19＞でも代用可能</td>
              </tr>
              <tr>
                <td>源泉所得税納付書のコピー</td><td></td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>本項目のまとめ</h3><br />
          <p className="media_text_text">
            上述の通り、計上する経費ごとに必要となる書類の内容が異なりますので、予め確認いただき準備いただけるとスムーズに進みます！<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>ポイント</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>経費ごとに必要となる書類が異なること</span></li>
              <li><span>書類の数が膨大となること</span></li>
              <li><span>予め内容を確認した上で、実績報告を進めていくこと</span></li>
            </ul>
          </div>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>4.書類の作成における流れ・注意点</h2>
          </div>
          <p className="media_text_text">
            <br />
            上記した書類の準備が完了次第、国が定めている書類を作成していきましょう。まずは、電子申請システムにログインし、申請状況が出るかと思います。そこで青字の「様式集」をクリックしてください。<br />
            <br />
            <img src={Image3} className='media_img' alt='' />
          </p>
          <h3 className='media_text_text_title'>様式第６</h3><br />
          <p className="media_text_text">
            様式第６には別紙１～３まで作成します。（別紙４はクラウドサービス利用費を計上する事業者様のみ）<br />
            <br />
            <strong>① 別紙１</strong><br />
            別紙１で注意いただくポイントとしては、「2.事業実施期間」の開始年月日を事前着手申請をしている場合も必ず交付決定日、完了年月日は交付決定日以降の日付を記入する必要があることなので、注意してください。<br />
            <br />
            <strong>② 別紙２</strong><br />
            別紙２には交付申請の際に用いた補助事業計画書の金額を予算額に入力いただき、その後実績値に実際に用いた金額を入力してください。<br />
            ※通常同じ数値となるケースが多いです。<br />
            <br />
            <img src={Image4} className='media_img' alt='' />
            <br />
            ③別紙３<br />
            別紙３は下記図のように計上した経費ごとにそれぞれ金額を入力してください。<br />
            <br />
            <img src={Image5} className='media_img' alt='' />
          </p>
          <h3 className='media_text_text_title'>様式第７取得財産等管理台帳</h3><br />
          <p className="media_text_text">
            様式第７に関しては、単価５０万円以上の物件や機械装置などを記入していきます。下図のとおり、区分や設備の種類を選択し、入力したら完成です。<br />
            <br />
            これにて、実績報告に関する書類作成は完了となります。<br />
            <br />
            <img src={Image6} className='media_img' alt='' />
          </p>
          </>

          <>
          <div id='5' className="media_text_title">
            <h2>5.書類のアップロードにおける流れ・注意点</h2>
          </div>
          <p className="media_text_text">
            <br />
            実績報告の最後の作業となる「書類のアップロード」では「書類の整理作業」「アップロード作業」の２点を実施し、完了となります。<br />
          </p>
          <h3 className='media_text_text_title'>書類の整理作業</h3><br />
          <p className="media_text_text">
            このフェーズでは、まず3.書類の準備で用意した書類一式をPDFに変換してください。その後、まとめた書類一式の右上に「建－１」や「機－１」と番号を記入してください。<br />
            <img src={Image7} className='media_img' alt='' />
          </p>
          <h3 className='media_text_text_title'>アップロード作業</h3><br />
          <p className="media_text_text">
          アップロード作業に関しては、https://jigyou-saikouchiku.go.jp/documents.php内にある「J-grants」入力ガイド（Zipファイル）をダウンロードいただき、その中にある実績報告マニュアルを参照の上、アップロードを進めてください。<br />
          <img src={Image8} className='media_img' alt='' />
          </p>
          </>

          <>
          <div id='6' className="media_text_title">
            <h2>6.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>本記事のまとめ</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>実績報告は「多数の書類」と「多数のステップ」で構成されているため、事前の準備が必須</span></li>
              <li><span>実績報告は、①補助事業の実施、②書類の準備、③書類の作成、④書類のアップロードの４点で構成されている</span></li>
              <li><span>自社で必要となる経費や金額を細かく精緻化していく必要がある</span></li>
            </ul>
          </div>
          <p className="media_text_text">
            また、実績報告でお困りの事業者様は当社を含む民間コンサルティング会社にご相談いただき、解決を目指していきましょう！！<br />
            <br />
            本記事をお読みいただき、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


