// 産業構造転換枠

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "../../nav/index";
import Topic from "../topic";
import TopImage from "./../../../../../image/media/business/26/top.png";
import Image1 from "./../../../../../image/media/business/26/1.png";
import Image2 from "./../../../../../image/media/business/26/2.png";
import Footer from '../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」市場縮小で悩んでいる企業様は産業構造転換枠！',
    description: '本記事では、産業構造転換枠の申請要件、補助率、補助金額について解説をしています。何を満たせば申請できるのか？や成長枠の特徴を知りたいという方は向けの記事となっておりますので、補助金を申請したいという方はぜひいご覧いただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」市場縮小で悩んでいる企業様は産業構造転換枠！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。<strong>補助金申請サポートをしているトランク合同会社</strong>です。<br />
            <br />
            本記事では日本最大級の補助金である事業再構築補助金の「産業構造転換枠」に関して、噛み砕いて解説させていただきます。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>産業構造転換枠とは？</span></li>
              <li><span>産業構造転換枠の要件がわからない！</span></li>
              <li><span>産業構造転換枠の要件、補助率など詳しい情報が知りたい！</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は<strong>経済産業省から出ている事業再構築補助金の公募要領</strong>を元に執筆しております。<br />
            公募要領は当社を含むプロも目を通す書類ですので、申請を個人で行う方は必ずご一読いだければと思います。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo.pdf' target="_blank" rel="nofollow noopener noreferrer">
              リンク：事業再構築補助金 公募要領
            </a><br />
            <br />
            初めての補助金申請は理解するのに時間がかかると思いますが、出来る限り噛み砕いて説明していますので、ゆっくりお読みいただければと思います。<br />
            <br />
            もし、本記事を読んでもわからない。本記事の中身がわからない。などの方は、<strong>右上の問い合わせよりご気軽にご連絡いただければご回答させていただきます</strong>。<br />
            <br />
            前段はこれくらいとして、本題の中身に入らせていただきます！<br />
            よろしくお願いいたします。<br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">産業構造転換枠の概要</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">産業構造転換枠の概要</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">産業構造転換枠の補助金額</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">産業構造転換枠の補助率</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">産業構造転換枠の補助対象経費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">産業構造転換枠の要件</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">各要件の解説</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">事業再構築要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">認定支援機関要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">付加価値額要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">市場縮小要件</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">産業構造転換枠のポイント</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.産業構造転換枠の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            産業構造転換枠とは、<strong>過去10年~今後10年の業種/業態の市場規模が10%以上減少している事業者様を対象として申請枠</strong>となっております。<br />
            <br />
            産業構造転換枠の概要を公募要領より以下に記載いたしました。<br />
            <br />
            以下に記載されております補助金額、補助率、補助対象経費、要件を確認せずに申請してしまうと要件不備で不採択、もしくは、事務局より指摘が入ってしまうので、あらかじめご確認いただくことをオススメします。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>項目</th><th>要件</th>
              </tr>
              <tr>
                <td>概要</td>
                <td>国内市場縮小等の構造的な課題に直面している業種・業態の中小企業等が取り組む事業再構築を支援。</td>
              </tr>
              <tr>
                <td>補助金額</td>
                <td>
                  【従業員数 20 人以下】 100 万円 ～ 2,000 万円<br />
                  【従業員数 21~50 人】 100 万円 ～ 4,000 万円<br />
                  【従業員数 51～100 人】 100 万円 ～ 5,000 万円<br />
                  【従業員数 101 人以上】 100 万円 ～ 7,000 万円<br />
                  ※廃業を伴う場合には、廃業費を最大 2,000 万円上乗せ<br />
                </td>
              </tr>
              <tr>
                <td>補助率</td>
                <td>
                  中小企業者等 2/3<br />
                  中堅企業等 1/2
                </td>
              </tr>
              <tr>
                <td>補助対象経費</td>
                <td>
                  建物費、機械装置・システム構築費（リース料を含む）、技術導入費、専門 家経費、運搬費、クラウドサービス利用費、外注費、知的財産権等関連経費、 広告宣伝・販売促進費、研修費、廃業費<br />
                </td>
              </tr>
              <tr>
                <td>要件</td>
                <td>
                  ・事業再構築要件<br />
                  ・認定支援機関要件<br />
                  ・付加価値額要件<br />
                  ・市場縮小要件
                </td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>産業構造転換枠の概要</h3><br />
          <p className="media_text_text">
            <strong>国内市場縮小等の構造的な課題に直面している業種・業態の中小企業等が取り組む事業再構築を支援</strong>する枠となっております。<br />
            <br />
            そのため、要件として<strong>市場縮小要件</strong>という要件があり、<strong>過去10年、もしくは、今後10年で市場が拡大している業種・業態に属すること</strong>が要件とされております。<br />
            <br />
            具体的な判断方法等の詳しい解説については、次章の市場縮小要件にて記載いたしましたので、ご確認いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>産業構造転換枠の補助金額</h3><br />
          <p className="media_text_text">
            産業構造転換枠の補助金額は、産業構造転換枠の概要の表で記載した通り、以下のように定義されております。<br />
            <strong>【従業員数 20 人以下】 100 万円 ～ 2,000 万円</strong><br />
            <strong>【従業員数 21~50 人】 100 万円 ～ 4,000 万円</strong><br />
            <strong>【従業員数 51～100 人】 100 万円 ～ 5,000 万円</strong><br />
            <strong>【従業員数 101 人以上】 100 万円 ～ 7,000 万円</strong><br />
            <br />
            この際に、確認していただきたい点は、<strong>従業員数の算出方法</strong>になります。<br />
            例えば、役員は従業員に含まれない。アルバイトは含まれる。など算出方法がさまざまですので、お間違いないよう計算いただければと思います。<br />
            <a href='https://www.chusho.meti.go.jp/faq/faq/faq01_teigi.htm#q3' target="_blank" rel="nofollow noopener noreferrer">
              参考サイト：従業員数の算出方法
            </a><br />
          </p>
          <h3 className='media_text_text_title'>産業構造転換枠の補助率</h3><br />
          <p className="media_text_text">
            補助率は投資金額の何割を国が補填してくれるかを示す数値になります。<br />
            <br />
            上記に示されている補助率と上限金額の2点の確認を怠ると想定している金額が補助されず事業の遂行が厳しくなってしまうため、確実にご確認していただければと思います。<br />
            <br />
            <strong>１）補助金額の計算例：従業員数5名、投資金額2,000万円の場合、補助金額は1,000万円</strong><br />
            産業構造転換枠を申請した事業者様が従業員数5名、投資金額3,000万円の場合、中小企業のため、補助率は2/3であるため、2,000万円の2/3で補助金額は2,000万円、自己負担額は1,000万円となります。<br />
            <br />
            <strong>２）補助金額の計算例：従業員数5名、投資金額6,000万円の場合、補助金額は2,000万円</strong><br />
            産業構造転換枠を申請した事業者様が従業員数5名、投資金額6,000万円の場合、中小企業のため、補助率は2/3であるため、6,000万円の2/3で補助金額は4,000万円のはずですが、従業員数 20 人以下の場合2,000万円が上限とされているため、補助金額は2,000万円となり、自己負担額は4000万円となります。<br />
            <br />
            <strong>上限金額以上金額（投資金額）については補助されません</strong>ので、補助率だけでなく補助上限の確認も行った上で申請、及び財務計画を立てていただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>産業構造転換枠の補助対象経費</h3><br />
          <p className="media_text_text">
            産業構造転換枠の補助対象経費は、産業構造転換枠の概要で示した通り、以下のものとなっております。<br />
            <strong>建物費、機械装置・システム構築費（リース料を含む）、技術導入費、専門 家経費、運搬費、クラウドサービス利用費、外注費、知的財産権等関連経費、 広告宣伝・販売促進費、研修費、廃業費</strong><br />
            <br />
            上記の費用が具体的に何に使えるのか？については、以下の記事にて詳しく記載しておりますので、ご参照いただければと思います。<br />
            <a href='https://trankllc.com/media/subsidy/business/0003' target="_blank" rel="nofollow noopener noreferrer">
              参考：事業再構築補助金 12個の経費分類を具体例つきで解説！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>産業構造転換枠の要件</h3><br />
          <p className="media_text_text">
            産業構造転換枠の要件は、産業構造転換枠の概要で示した通り、以下のものとなっております。<br />
            <strong>・事業再構築要件</strong><br />
            <strong>・認定支援機関要件</strong><br />
            <strong>・付加価値額要件</strong><br />
            <strong>・市場縮小要件</strong><br />
            <br />
            各要件については、次章で1つずつ解説しておりますので、ご確認いただければと思います。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.各要件の解説</h2>
          </div>
          <p className="media_text_text">
            <br />
            上述の表で、産業構造転換枠の要件を示した通り、本枠での申請を検討されている方は、以下の４点の要件を満たす必要がございますので、1つずつご確認いただければと考えております。<br />
            <br />
            ご不明点等ございましたら、右上のお問い合わせよりご連絡いただければご質問にお答えさせていただきますので、ご連絡いただければと思います。<br />
            <br />
            <strong>①	事業再構築要件</strong><br />
            <strong>②	認定支援機関要件</strong><br />
            <strong>③	付加価値額要件</strong><br />
            <strong>④	市場縮小要件</strong><br />
          </p>
          <h3 className='media_text_text_title'>事業再構築要件</h3><br />
          <p className="media_text_text">
            事業再構築要件とは、<strong>新分野展開、事業転換、業種転換、業態転換又は事業再編のいずれかに該当する事業計画でなければいけない</strong>という要件です。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・新分野展開、事業転換、業種転換、業態転換又は事業再編のいずれかに該当する事業計画を策定すること<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・事業計画書<br />
            <br />
            <strong>参考記事</strong><br />
            上記５点の（新分野展開、事業転換、業種転換、業態転換又は事業再編）についての詳しい記事を別途作成させていただきましたので、ぜひご参照いただければと思います。<br />
            <a href='https://trankllc.com/media/subsidy/business/0007' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【類型・転換って何？】事業再構築補助金の再構築要件を、例を用いて解説！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>認定支援機関要件</h3><br />
          <p className="media_text_text">
            認定支援機関要件とは、<strong>国に中小企業支援の知識・経験がある者として認められた認定支援機関に事業計画書を確認してもらわなければいけない</strong>という要件です。<br />
            <br />
            認定支援機関とは、<strong>中小企業支援の知識・経験がある者として、国の認定を受けた機関であり、士業や商工会、金融機関が多く認可されています</strong>。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・認定支援機関と相談したうえで事業計画書を策定する。<br />
            ※認定経営革新等支援機関による確認書は、認定支援機関に相談したことを証明する書類ですので、必ず認定支援機関に作成をお願いしてください。<br />
            <br />
            <strong>【要件を満たすために必要な書類】</strong><br />
            ・認定経営革新等支援機関による確認書<br />
            ・金融機関による確認書(補助金額が3,000万円を超える場合)<br />
            <br />
            認定支援機関要件は、下記の記事にて詳しく解説しておりますので、詳細が知りたい方はぜひご参照ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0009' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【どこに依頼する？】事業再構築補助金の認定支援機関要件を機関別に解説！
            </a><br />
            <br />
            おすすめの認定支援機関や認定支援機関別の採択率は、下記の記事でまとめて記載しておりますので、下記リンクよりご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0004' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【採択率はどれくらい？】事業再構築補助金の過去採択を徹底解剖！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>付加価値額要件</h3><br />
          <p className="media_text_text">
            付加価値額要件とは、<strong>付加価値額が上昇する事業計画を策定しなければいけない</strong>という要件です。<br />
            <br />
            付加価値額とは、補助金独自の考え方であり、以下の方法により算出できます。<br />
            <span>付加価値額=営業利益+減価償却費+人件費</span><br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・補助事業終了後3~5年で、付加価値額の年平均率を3.0%以上増加させること<br />
            ・補助事業終了後3~5年で、従業員1人当たりの付加価値額の年平均率3.0%以上増加させること<br />
            ※上記のどちらかを満たす事業計画を策定することが要件となります。<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・事業計画書<br />
            <br />
            上記の要件を満たした事業計画書を策定することが必要になりますので、認定支援機関と共にご確認をお願いいたします。<br />
          </p>
          <h3 className='media_text_text_title'>市場縮小要件</h3><br />
          <p className='media_text_text'>
            <strong>市場縮小要件では思い切った事業再構築による売上を拡大するという目的を達成するために追加された要件</strong>となっております。<br />
            <br />
            また、市場が縮小されているという点が肝ですので<strong>事業計画書でも市場が縮小しているという点を必ず明示する</strong>ことを忘れずに計画書を作成ください。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            公的データを用いて市場が過去10年で10%以上減少している事を示すという事で要件を満たすことができます。データでは国データを主軸に検索していただくことで推移の取得が可能になります。<br />
            <br />
            <strong>例外の場合</strong><br />
            以下のような場合は、例外的に産業構造転換枠の対象となります。<br />
            <img src={Image1} className='media_img' alt='' />
            ①2019 年の市場規模が 2009 年に比べて 10％以上縮小しており、一貫して下降トレンドにある。<br />
            ②2019 年の市場規模が 2009 年に比べて 10％以上縮小しており、前年に比して拡大している年もあるが、全体として下降トレンドにあると認められる。<br />
            <img src={Image2} className='media_img' alt='' />
            ③2019 年の市場規模が 2009 年に比べて 10％以上縮小しているが、2019 年だけ何かしらの事情で縮小しているだけである可能性があり、下降トレンドにあるとは判断できない。<br />
            ④2019 年の市場規模が 2009 年に比べて 10％以上縮小しているが、近年（2015 年から 2019 年にかけて）上昇トレンドにある。<br />
            ⑤	2019 年の市場規模が 2009 年に比べて 10％以上縮小していない。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/tenkanwaku_list.pdf' target="_blank" rel="nofollow noopener noreferrer">
              参考書類：産業構造転換枠の要件を満たすことができる業種・業態の一覧
            </a><br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.産業構造転換枠のポイント</h2>
          </div>
          <p className="media_text_text">
            <br />
            上述で記載した通り、産業構造転換枠は<strong>過去10年の縮小を示すことで市場縮小要件を満たすことができた場合、産業構造転換枠へ申請することができる枠</strong>となっております。<br />
            <br />
            市場が縮小しており売上の増加が見込みづらい事業者様は事業再構築補助金を使用して市場が成長している業界への事業展開を検討してみてはいかがでしょうか。<br />
            <br />
            当社では業界選定からお悩み相談を受付しておりますので、ご気軽にご連絡いただければと思います。<br />
          </p>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>4.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、産業構造転換枠に関する解説をさせていただきました。<br />
            産業構造枠だけではなく、事業再構築補助金について知見を深めていただければ幸いです。<br />
            <br />
            事業再構築補助金は複雑な部分が多々ありますが、弊社含めコンサルタントが補助することもできますので、ぜひ専門の機関への相談を行ってみてください。<br />
            <br />
            当社ではお悩み相談だけでなく、<strong>再構築の申請~受け取りまでサポートできる体制を整えております</strong>ので、右上のお問い合わせよりご気軽にご連絡ください。<br />
            <br />
            <strong>最後まで記事を拝見してくださり、誠にありがとうございました。</strong><br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


