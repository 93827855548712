const BlogText0001 = (props) => {

  return (
  <div className='management_review_content_1'>
    <h4>自社の状況に関する質問（1問）</h4>
    <h5>Q9.法令遵守の状況</h5>
    <select onChange={(e) => props.setLegalCompliance(e.target.value)}>
      <option value="">選択</option>
      <option value='0'>無</option>
      <option value='15'>指示をされた場合</option>
      <option value='-30'>営業の全部若しくは一部の停止を命ぜられた場合</option>
    </select>
  </div>
  );
}

export default BlogText0001;
