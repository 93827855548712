import './styles.css';
import Icon from "./../../../../image/home/icon.png";

const Home = () => {

  return (
    <>
    <div className='footer_help_nav'>
      <a href='https://trankllc.com/'>
        <div>
          <img src={Icon} alt='' />
          <p>trankllc</p>
        </div>
      </a>
      <div>
        <p></p>
        <a href='https://trankllc.com/company'>
          <p>company</p>
        </a>
        <a href='https://trankllc.com/contact'>
          <p>contact</p>
        </a>
      </div>
    </div>
    </>
  );
}

export default Home;