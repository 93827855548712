import './styles.css';

const SimulatorHeader = () => {

  return(
  <>
  <div className='simulator_result_header'>
    <div>
      <h4>事業再構築補助金　診 断 結 果</h4>
    </div>
  </div>
  </>
  );
}

export default SimulatorHeader;
