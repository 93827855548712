const BlogText0001 = (props) => {

  return(
  <>
  {/* （Ⅰ）若年の技術者及び技能労働者の育成及び確保の状況 */}
  <div className='management_review_content_1'>
    <h4>従業員に関する質問（11問）</h4>
    <div>
      <h5>Q4.若年技術職員</h5>
      <p>審査基準日時点における技術職員名簿に記載された若年技術職員の人数をご記載ください。</p>
      <input onChange={(e) => props.setYoungTechnical(e.target.value)} />
    </div>
    <div>
      <h5>Q4.技術職員の合計</h5>
      <p>技術職員名簿に記載された技術職員の人数の合計をご記載ください。</p>
      <input onChange={(e) => props.setTechnicalSum(e.target.value)} />
    </div>
    <div>
      <h5>Q4.新規技術職員の人数</h5>
      <p>審査基準日において、若年技術職員のうち審査対象年において新規に技術職員となった人数をご記載ください。</p>
      <input onChange={(e) => props.setNewTechnicalSum(e.target.value)} />
    </div>
  </div>

  {/* （Ⅱ）知識及び技術又は技能の向上に関する取組の状況 */}
  <div className='management_review_content_1'>
    <h4>自社の状況に関する質問</h4>
    <div>
      <h5>Q1.技術者数</h5>
      <p>技術者数は監理技術者になる資格を有する者、主任技術者になる資格を有する者、一級及び二級技士補の数の合計をご記載ください。</p>
      <input onChange={(e) => props.setEngineers(e.target.value)} placeholder="12" type="number" />
    </div>
    <div>
      <h5>Q2.技能者数</h5>
      <p>技能者数は、審査基準日以前３年間に、建設工事の施工に従事した者であって、作業員名簿を作成する場合に建設工事に従事する者として氏名が記載される人数をご記載ください。<br />※主任技術者や監理技術者として管理に係る業務のみに従事する者を除く。</p>
      <input onChange={(e) => props.setSkilledWorkers(e.target.value)} placeholder="12" type="number" />
    </div>
    <div>
      <h5>Q3.ＣＰＤ単位数</h5>
      <p>審査基準日以前１年間に技術者が取得したＣＰＤ単位数の合計値</p>
      <input onChange={(e) => props.setCPD(e.target.value)} placeholder="12" type="number" />
    </div>
    <div>
      <h5>Q4.１以上上位であった者</h5>
      <p>審査基準日における許可を受けた建設業に従事する職員のうち、審査基準日以前３年間に能力評価基準により受けた評価の区分が審査基準日の３年前の日以前に受けた最新の評価の区分より１以上上位であった者の人数をご記載ください。</p>
      <input onChange={(e) => props.setOneMore(e.target.value)} placeholder="12" type="number" />
    </div>
    <div>
      <h5>Q4.最上位の区分に該当するとされた者の数</h5>
      <p>技能者数から審査基準日の３年前の日以前に能力評価基準により評価が最上位の区分に該当するとされた者の人数をご記載ください。</p>
      <input onChange={(e) => props.setTopPerson(e.target.value)} placeholder="12" type="number" />
    </div>
  </div>
  </>
  );
}

export default BlogText0001;
