import './styles.css';
import Strong1 from './../../../../../../image/bid/strong/1.png';
import Strong2 from './../../../../../../image/bid/strong/2.png';
import Strong3 from './../../../../../../image/bid/strong/3.png';

const BlogText0001 = () => {

  return (
    <>
    <div className='bid_strong_items'>
      <div>
        <img src={Strong1} alt='' />
        <h4>情報は毎日更新しており<br />案件情報を漏れなく確認出来る</h4>
        <p>常に最新の動向を追えることで、貴重な案件を逃すことなくビジネスチャンスを拡大します。信頼性の高い情報提供をお届けすることが出来ます。</p>
      </div>
      <div>
        <img src={Strong2} alt='' />
        <h4>情報量は全部で3000件と<br />自社に合った情報を確認出来る</h4>
        <p>幅広い案件情報の中から、自社のニーズに合致するものを効率的に見つけることができます。案件数は、3000件以上もあり、活用できます。</p>
      </div>
      <div>
        <img src={Strong3} alt='' />
        <h4>詳細な絞り込み、閲覧は<br />完全無料で永続的に使い放題</h4>
        <p>高度な絞り込み機能や詳細情報の閲覧が、完全無料でご利用いただけます。利用制限なしで、長期間にわたりサービスをご活用いただけます。</p>
      </div>
    </div>
    </>
  );
}

export default BlogText0001;
