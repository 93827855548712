import './styles.css';
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header1 from './../../../../image/homepage/header/pc/1.png';
import Header2 from './../../../../image/homepage/header/pc/2.png';
import Header3 from './../../../../image/homepage/header/pc/3.png';
import Header4 from './../../../../image/homepage/header/pc/4.png';
import Header5 from './../../../../image/homepage/header/pc/5.png';
import Header6 from './../../../../image/homepage/header/pc/6.png';
import { BiSolidLeftArrow } from "react-icons/bi"
import { BiSolidRightArrow } from "react-icons/bi"

export default class SlickGoTo extends React.Component {

  state = {
    slideIndex: 0,
    updateCount: 0
  };

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 4000,
      slidesToShow: 1,
      arrows: false,
      slidesToScroll: 1,
      autoplay: true,
      afterChange: () => this.setState(state => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next })
    };
  
    return (
      <>
      <div className='test_homeimage_image_box'>
        <div className='test_homeimage_image_box_frame'>
          <div className='test_homeimage_image_box_frame_arrow' onClick={() => this.slider.slickPrev()}>
            <BiSolidLeftArrow className='test_homeimage_image_box_frame_arrow_icon' style={{margin: "17.5px 19px 17.5px 16px"}} />
          </div>
          <div className='test_homeimage_image_box_frame_frame'></div>
          <div className='test_homeimage_image_box_frame_arrow' onClick={() => this.slider.slickNext()}>
            <BiSolidRightArrow className='test_homeimage_image_box_frame_arrow_icon' style={{margin: "17.5px 16px 17.5px 19px"}} />
          </div>
        </div>
        <Slider ref={slider => (this.slider = slider)} {...settings} className='test_homeimage_image'>
          <img src={Header1} className='test_homeimage_image_img' alt='' />
          <img src={Header2} className='test_homeimage_image_img' alt='' />
          <img src={Header3} className='test_homeimage_image_img' alt='' />
          <img src={Header4} className='test_homeimage_image_img' alt='' />
          <img src={Header5} className='test_homeimage_image_img' alt='' />
          <img src={Header6} className='test_homeimage_image_img' alt='' />
        </Slider>
      </div>

      <div className='test_homeimage_bar'>
        <div style={{backgroundColor : '#479D58'}} onClick={() => this.slider.slickGoTo(0)}>
          <p>コンサルティング部がドローン企業と提携</p>
        </div>
        <div style={{backgroundColor : '#2E81B0'}} onClick={() => this.slider.slickGoTo(1)}>
          <p>公開から1年！ユーザー数が2000人を突破</p>
        </div>
        <div style={{backgroundColor : '#DA3E35'}} onClick={() => this.slider.slickGoTo(2)}>
          <p>2023年10月にサービス提供開始</p>
        </div>
        <div style={{backgroundColor : '#CD445B'}} onClick={() => this.slider.slickGoTo(3)}>
          <p>美容学生に向けキャンペーン実施中</p>
        </div>
        <div style={{backgroundColor : '#373198'}} onClick={() => this.slider.slickGoTo(4)}>
          <p>コーポレートサイトをリニューアル</p>
        </div>
        <div style={{backgroundColor : '#3C3C3C'}} onClick={() => this.slider.slickGoTo(5)}>
          <p>Shake Shackの展示会に作品が出典</p>
        </div>
      </div>
      </>
    );
  }
}

