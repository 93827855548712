import React, { useState, useEffect } from 'react';
import './styles.css';
import { FiMapPin } from "react-icons/fi";
import firebase from 'firebase/compat/app';
import { Link } from 'react-router-dom';

const Home = () => {

// 変数が変わるときに使う,useState import react で最初に定義が必要。
  const [info, setInfo] = useState([]); 
  const [area, setArea] = useState("");
  const [name, setName] = useState("");
  const [genru, setGenru] = useState("");

  // firebase.firestore().collection('filipin').doc()
  // .set({
  //   age: 39,
  // }).then(() => {
  //   console.log("終わった")
  // });

  // function nameSerch () {
  //   firebase.firestore().collection('posts').get()
  //   .then(snapshot => {
  //     var tmp = [];
  //     snapshot.forEach(doc => {
  //       if (doc.data().companyId) {
  //         firebase.firestore().collection('posts').doc(doc.id)
  //         .update({
  //           post_student: false,
  //         })
  //       } else {
  //         firebase.firestore().collection('posts').doc(doc.id)
  //         .update({
  //           post_student: true,
  //         })
  //       }
  //     })
  //   })
  // };

  // function genruSerch () {
  //   console.log(name)
  //   firebase.firestore().collection('filipin').where("genru", "==", genru).get()
  //   .then(snapshot => {
  //     var tmp = [];
  //     snapshot.forEach(doc => {
  //       // console.log(`${doc.id}: ${doc.data().age}`);
  //       console.log(doc.data())
  //       console.log(doc.id)
  //       tmp.push({ ...doc.data(), id: doc.id })
  //     })
  //     console.log(tmp);
  //     setInfo(tmp);
  //   })
  // };

  return (
    <>
    <button>jgjhjkhkjkhj</button>
    </>
  );
}

export default Home;
