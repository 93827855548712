const BlogText0001 = (props) => {

  return(
  <>
  {/* W1 */}
  <div className='management_review_content_1'>
    <h4>自社の状況に関する質問</h4>
    <h5>Q1.建設業退職金共済制度加入してますか？</h5>
    <select onChange={(e) => props.setAidSystem(e.target.value)}>
      <option value="">選択</option>
      <option value='15'>はい</option>
      <option value='0'>いいえ</option>
    </select>
    <h5>Q2.退職一時金制度若しくは企業年金制度導入の有無</h5>
    <select onChange={(e) => props.setPensionSystem(e.target.value)}>
      <option value="">選択</option>
      <option value='15'>はい</option>
      <option value='0'>いいえ</option>
    </select>
    <h5>Q3.法定外労働災害補償制度加入の有無</h5>
    <select onChange={(e) => props.setCompensationSystem(e.target.value)}>
      <option value="">選択</option>
      <option value='15'>はい</option>
      <option value='0'>いいえ</option>
    </select>
    <h5>Q6.ワーク・ライフ・バランスに関する取組の状況（Ⅲ）</h5>
    <select onChange={(e) => props.setWorkLife(e.target.value)}>
      <option value="">選択</option>
      <option value='5'>プラチナえるぼし</option>
      <option value='4'>えるぼし（第３段階）</option>
      <option value='3'>えるぼし（第３段階）</option>
      <option value='2'>えるぼし（第２段階）</option>
      <option value='5'>えるぼし（第１段階）</option>
      <option value='4'>プラチナくるみん</option>
      <option value='3'>くるみん</option>
      <option value='2'>トライくるみん</option>
      <option value='4'>ユースエール</option>
      <option value='0'>無</option>
    </select>
    <h5>Q7.建設工事に従事する者の就業規則を蓄積するために必要な措置の実施状況（Ⅳ）</h5>
    <select onChange={(e) => props.setRule(e.target.value)}>
      <option value="">選択</option>
      <option value='15'>民間工事を含む全ての建設工事で該当措置を実施</option>
      <option value='10'>全ての公共工事で該当措置を実施</option>
      <option value='0'>上記以外</option>
    </select>
    <h5>Q8.雇用保険加入の有無</h5>
    <select onChange={(e) => props.setEmployee(e.target.value)}>
      <option value="">選択</option>
      <option value='-40'>はい</option>
      <option value='0'>いいえ</option>
    </select>
    <h5>Q9.健康保険加入の有無</h5>
    <select onChange={(e) => props.setHealth(e.target.value)}>
      <option value="">選択</option>
      <option value='-40'>はい</option>
      <option value='0'>いいえ</option>
    </select>
    <h5>Q10.厚生年金保険加入の有無</h5>
    <select onChange={(e) => props.setPension(e.target.value)}>
      <option value="">選択</option>
      <option value='-40'>はい</option>
      <option value='0'>いいえ</option>
    </select>
  </div>
  </>
  );
}

export default BlogText0001;
