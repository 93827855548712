import firebase from "firebase/compat/app"
import First from './first'
import Entry from './entry'
import Round1 from './round1'
import Round2 from './round2'
import Round3 from './round3'
import Round4 from './round4'

const BlogText0001 = () => {

  return (
    <>
    <First />
    <Entry />
    <Round1 />
    <Round2 />
    <Round3 />
    <Round4 />
    </>
  );
}

export default BlogText0001;