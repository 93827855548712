const BlogText0001 = (props) => {

  return(
    <div className='management_review_content_1'>
      <h4>自社の状況に関する質問（1問）</h4>
      <div>
        <h5>Q9.国又は国際標準化機構が定めた規格による認証または登録の状況</h5>
        <p>国又は国際標準化機構が定めた規格による認証又は登録の状況については。審査基準日において、一般財団法人持続性推進機構によってエコアクション２１の認証を受けている場合又は財団法人日本適合性認定協会もしくは同協会と相互認証している認定機関に認定されている審査期間によって国際標準化機構第９００１号（ＩＳＯ９００１）若しくは第１４００１号（ＩＳＯ１４００１）の規格による登録を受けている場合</p>
        <select onChange={(e) => props.setCertification(e.target.value)}>
          <option value="">選択</option>
          <option value='10'>第９００１号　及び　第１４００１号</option>
          <option value='8'>第９００１号　及び　エコアクション２１</option>
          <option value='5'>第９００１号</option>
          <option value='5'>第１４００１号</option>
          <option value='3'>エコアクション２１</option>
          <option value='0'>無</option>
        </select>
      </div>
    </div>
  );
}

export default BlogText0001;
