import './styles.css';
import { AiFillQuestionCircle } from "react-icons/ai"

const SimulatorHeader = (props) => {

  // グリーン成長枠
  // 物価高騰
  // 成長枠
  // 産業構造転換枠

  const possible = {background : '#EB6232', border : '#EB6232',};
  const impossible = {border : '1px solid #EB6232', color : '#EB6232', backgroundColor : 'white'};

  return(
  <>
  <div className='simulator_result_result_title'>
    <h4>申請枠に関する診断結果</h4>
  </div>
  <div className='simulator_result_result_desc'>
    <div>
      <div>
        <h5>物価高騰対策・回復再生応援枠</h5>
        <AiFillQuestionCircle className='simulator_question' />
      </div>
      <h4 style={props.result1 ? possible : impossible}>{props.result1 ? '申請可' : '申請不可'}</h4>
    </div>
    <div>
      <div>
        <h5>成長枠</h5>
        <AiFillQuestionCircle className='simulator_question' />
      </div>
      <h4  style={props.result2 ? possible : impossible}>{props.result2 ? '申請可' : '申請不可'}</h4>
    </div>
    <div>
      <div>
        <h5>産業構造転換枠</h5>
        <AiFillQuestionCircle className='simulator_question' />
      </div>
      <h4 style={props.result3 ? possible : impossible}>{props.result3 ? '申請可' : '申請不可'}</h4>
    </div>
    <div>
      <div>
        <h5>グリーン成長枠</h5>
        <AiFillQuestionCircle className='simulator_question' />
      </div>
      <h4 style={props.result4 ? possible : impossible}>{props.result4 ? '申請可' : '申請不可'}</h4>
    </div>
  </div>
  </>
  );
}

export default SimulatorHeader;
