import './styles.css';
import Icon from './../../../../image/icon.png';

const Nav = () => {
  
  return (
    <>
    <div className='media_nav'>
      <a className='media_nav_icon' href='https://trankllc.com/media/subsidy/business/0000'>
        <img src={Icon} loading="lazy" alt='memorii' />
        <h2>事業再構築補助金の教科書</h2>
      </a>
      <a className='media_nav_contact' href='https://trankllc.com/contact'>
        <p>問い合わせ</p>
      </a>
    </div>
    </>
  );
}

export default Nav;
