// 交付申請

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/23/top.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」交付申請の流れは、全部で５つ！',
    description: '本記事では、交付申請の手続きに必要な書類と手続きの注意点について解説させていただいております。なお、対象経費ごとの説明もご案内しております。本記事は、交付申請を初めて行う方へ向けて、わかりやすく解説させていただきましたので、理解しながらゆっくりお読みいただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」交付申請の流れは、全部で５つ！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。<strong>補助金コンサルタントのトランク合同会社</strong>です。<br />
            今回は、日本最大級の補助金である<strong>事業再構築補助金の「交付申請」について解説させていただきます</strong>。<br />
            <br />
            本記事は、<strong>交付申請を初めて行う方へ向けて</strong>、<strong>わかりやすく</strong>解説させていただきましたので、理解しながらゆっくりお読みいただければと思います。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>交付申請とは</span></li>
              <li><span>交付申請で用意すべき書類・ポイント</span></li>
              <li><span>交付申請におけるリスク・注意点</span></li>
            </ul>
          </div>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">事業再構築補助金の交付申請とは？</AnchorLink></li>
                <li><AnchorLink href="#2" offset="100">交付申請で用意すべき書類・ポイント</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">事業者さまによって、必要となる書類</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.事業再構築補助金の交付申請とは？</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、<strong>交付申請の概要と交付申請の流れについてご説明させていただきます</strong>。<br />
            <br />
            これから交付申請を行う方は、流れを把握しているだけでも手続きが少しスムーズになりますので、ご確認いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>交付申請の概要</h3><br />
          <p className="media_text_text">
            まず、<strong>交付申請とは、採択された事業計画書に記載されている経費項目や金額を改めて精査し、実態を書類にまとめ国に報告する業務</strong>のことです。<br />
            <br />
            なお、上記の申請が終わると交付決定（経費項目や金額が確定された状態）がされますので、その点も覚えておいてください。<br />
            ※交付決定前に着手をすると補助対象外となりますので、お気をつけください。<br />
            <br />
            <strong>注意事項</strong><br />
            交付申請は期日が決められておりますので、お早めにご対応ください。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>公募</th><th>締め切り</th>
              </tr>
              <tr>
                <td>５次締切</td><td>2023年6月23日</td>
              </tr>
              <tr>
                <td>６次締切</td><td>2023年9月29日</td>
              </tr>
            </tbody>
          </table>
          <h3 className='media_text_text_title'>交付申請の流れ</h3><br />
          <p className="media_text_text">
            交付申請は、以下のような流れとなっております。<br />
            <br />
            ① <strong>申請時の内容を確認</strong><br />
            電子申請システム「J-grants」より「交付申請別紙ファイル」をダウンロードいただき、何にいくら要する予定であったかを確認します。<br />
            ↓<br />
            ② <strong>必要経費の見積書の発行依頼</strong><br />
            交付申請では経費ごと（建物費、システム開発費など）に見積書の発行が必須なため、それぞれの経費に関してメーカーや工事業者に見積書の発行を依頼します。<br />
            ↓<br />
            ③ <strong>見積書の内容の精査</strong><br />
            取得した見積書と①でダウンロードいただいた「交付申請別紙ファイル」を見比べて、項目や金額にズレがないかを確認します。<br />
            ※価格が上がることは基本的にタブーとなっております。<br />
            ↓<br />
            ④ <strong>書類の作成</strong><br />
            本記事の「２.交付申請で用意すべき書類・ポイント」に記載してある書類を作成します。<br />
            ↓<br />
            ⑤ <strong>書類の提出</strong><br />
            電子申請システム「J-grants」よりご準備いただいたファイルをアップロードしてください。<br />
            <br />
            なお、提出後、内容に問題がある場合は地域事務局より修正の連絡がある場合がございますが、修正があるからといって補助金が降りないなどリスクはございませんのでご安心ください。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.交付申請で用意すべき書類・ポイント</h2>
          </div>
          <p className="media_text_text">
            <br />
            交付申請は、<strong>必須となる書類が経費によって異なります</strong>ので、以下の２つに分けてご説明させていただきます。<br />
            <br />
            ・本項目：<strong>必須となる書類</strong><br />
            ・次項目：<strong>事業者さまによって、必要となる書類</strong><br />
            <br />
            なお、必須書類の説明以外にご注意いただきたいポイントもご紹介していきますので、１つずつご確認いただければと思います。<br />
            <br />
            書類のダウンロードを行いたい事業者さまは、以下のページからダウンロードをすることができますので、ご確認ください。<br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/documents.php' target="_blank" rel="nofollow noopener noreferrer">
              事業再構築 採択事業者向け資料
            </a>
          </p>
          <h3 className='media_text_text_title'>交付申請別紙①</h3><br />
          <p className="media_text_text">
            <strong>電子申請システム「J-grants」よりダウンロードいただき（参考URL参照）、申請時から変更がある箇所（設備の金額、名称など）を修正してください</strong>。<br />
            <br />
            ※金額が上がるのは基本的にNGですが、合理的な理由（原料価格高騰など）があれば認められるケースもございます。<br />
            <br />
            以下のリンクにて、交付申請別紙①のダウンロード方法の案内がありますので、ご参照いただけれればと思います。<br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/pdf/documents/kofushinseisyo_besshisyutsuryoku_tejyunsyo.pdf' target="_blank" rel="nofollow noopener noreferrer">
              事業再構築 交付申請別紙①のダウンロード方法
            </a>
          </p>
          <h3 className='media_text_text_title'>履歴事項全部証明書</h3><br />
          <p className="media_text_text">
            <strong>過去３ヶ月以内に取得した履歴事項全部証明書が必要となります</strong>。採択発表後、最寄りの法務局等でご取得ください。<br />
          </p>
          <h3 className='media_text_text_title'>決算書</h3><br />
          <p className="media_text_text">
            <strong>直近決算分の決算書が必要となります</strong>。<br />
            決算書には、以下の４点の記載が必須となりますので、その点もご確認ください。<br />
            <br />
            <strong>① 損益計算書</strong><br />
            <strong>② 貸借対照表</strong><br />
            <strong>③ 販管費明細</strong><br />
            <strong>④ 製造原価明細書</strong><br />
          </p>
          <h3 className='media_text_text_title'>見積依頼書（参考様式６）</h3><br />
          <p className="media_text_text">
            <strong>取得する資産（設備費・システム構築費）の見積書および相見積書が必要となります。</strong><br />
            <br />
            発行の際は、以下の情報の記載があるかをお確かめください。<br />
            ※発行依頼する際に事前にお伝えしておくと、スムーズに進めることができます。<br />
            <br />
            <strong>① 設備名：申請時の設備と全く同じ名前</strong><br />
            <strong>② 金額：可能であれば申請時の金額以下</strong><br />
            <strong>③ 発行日：採択発表以降の日時</strong><br />
            <strong>④ 有効期限：６ヶ月（長期化した際のためにです）</strong><br />
            <br />
            また、見積書と相見積書においては、以下の決まりがございますので、こちらの確認もお願いします。<br />
            <strong>① 相見積書をの金額が一番低い会社へ委託すること</strong><br />
            <strong>② 相見積書を用意していただく会社は、全く同じ型式・型番であること</strong><br />
            <br />
            <strong>見積書・相見積書が発行され次第、見積依頼書（参考様式６）の作成を進めましょう</strong>。<br />
          </p>
          <h3 className='media_text_text_title'>参考様式２１取得財産に係る誓約書</h3><br />
          <p className="media_text_text">
            <strong>「参考様式２１取得財産に係る誓約書」の作成が必要となります</strong>。<br />
            本書類は、"補助事業以外で設備を使用しない。"ことを誓う誓約書となっております。<br />
          </p>
          <h3 className='media_text_text_title'>本項目のまとめ</h3><br />
          <p className="media_text_text">
            上述に資料の説明とポイントをまとめさせていただきましたが、いかがでしょうか。<br />
            <br />
            意外と少ないように感じてしまいますが、設備の多さによって見積書が多くなる場合等もございますので、なるべくお早めに用意を進めていただく事をおすすめします。<br />
            <br />
            以下にて、書類の一覧を記載させていただきます。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>交付申請で用意すべき書類</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>交付申請別紙①</span></li>
              <li><span>履歴事項全部証明書</span></li>
              <li><span>決算書</span></li>
              <li><span>見積依頼書（参考様式６）</span></li>
              <li><span>参考様式２１取得財産に係る誓約書</span></li>
            </ul>
          </div>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.事業者さまによって、必要となる書類</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、<strong>事業者さまによって、必要となる書類</strong>について、解説させていただきます。<br />
            <br />
            なお、本項目でも同様にご注意いただきたいポイントもご紹介していますので、ご一読いただければと思います。<br />
            <br />
            書類のダウンロードを行いたい事業者さまは、以下のページからダウンロードをすることができますので、ご確認ください。<br />
            リンク：<a href='https://jigyou-saikouchiku.go.jp/documents.php' target="_blank" rel="nofollow noopener noreferrer">
              事業再構築 採択事業者向け資料
            </a>
          </p>
          <h3 className='media_text_text_title'>交付申請書別紙２</h3><br />
          <p className="media_text_text">
            <strong>技術導入費、専門家経費、クラウドサービス利用費、外注費、知的財産権等関連経費を計上する事業者さまに限り必要な書類となります</strong>。<br />
            <br />
            計上項目については、事業計画書に自社の事業計画書に記載があると思いますので、ご確認ください。<br />
          </p>
          <h3 className='media_text_text_title'>海外旅費の詳細</h3><br />
          <p className="media_text_text">
            <strong>海外旅費を計上する補助事業者が必要となる書類になります</strong>。<br />
            <br />
            書類形式等はございませんので、領収書等をまとめて事務局へご報告いただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>事前着手承認のお知らせメールデータ</h3><br />
          <p className="media_text_text">
            <strong>事前着手制度を活用された事業者さまのみ必要となる書類です</strong>。<br />
            <br />
            書類形式等はございませんので、メールデータのスクリーンショット等を事務局へご提出ください。<br />
          </p>
          <h3 className='media_text_text_title'>住所など事業主に関する変更がある場合</h3><br />
          <p className="media_text_text">
            事業再構築補助金の申請から交付申請の期間において、<strong>住所の変更や代表者の代替わりなどが生じた際には届け出を出す必要があります</strong>。変更する内容によって対応が変わりますのでその際には事業再構築補助金サポートセンターまでお問い合わせください。<br />
            <br />
            お問い合わせ先：０５７０ － ０１２ － ０８８<br />
          </p>
          <h3 className='media_text_text_title'>従業員数に変更がある場合</h3><br />
          <p className="media_text_text">
            事業再構築補助金の申請から交付申請の間において、<strong>従業員数に変更が合った場合は、補助上限が変更する等の可能性がございますので、確認が必要となります</strong>。<br />
            <br />
            <strong>具体例（最低賃金枠）①</strong><br />
            例えば、従業員数６－２０人（事業者の従業員数は 6 名）で採択された事業者さまが、従業員数が、5 名となった場合などがあげられます。<br />
            この場合、補助金額上限は１，０００万円から５００万円に変更となります。<br />
            <br />
            <strong>具体例（大規模賃金引上枠）②</strong><br />
            例えば、従業員数が交付申請時に１００人以下である場合は、交付決定の通知を出すことはできません。なお、交付決定後に従業員数が虚偽であることが発覚した場合は、交付決定の取消しとなります。<br />
            <br />
            「最低賃金枠」や「賃金引き上げ枠」で申請された事業者さまは、特に注意が必要です。<br />
          </p>
          <h3 className='media_text_text_title'>海外メーカーから資産を取得する場合</h3><br />
          <p className="media_text_text">
            事業再構築補助金において機械装置などを海外メーカーから取得される事業者様の中で、<strong>見積書・請求書が外貨建てである場合は、経費明細等に記載する金額は、円建てで記入する必要があります</strong>ので、確認が必要となります。<br />
            <br />
            <strong>具体的な算出方法</strong><br />
            なお、換算基準は、交付申請時前１か月以内の特定日とされており。使用する換算レートは、公表仲値（電信仲値相場＝TTM）を用い、三菱UFJ銀行公表の仲値の使用が原則となります。<br />
            <br />
            また、取引のある金融機関の公表仲値を使用することも可能です。換算に使用したTTM は、「年月日」「公表金融機関名」が明記されたものが必要となりますので、この点もご確認ください。<br />
          </p>
          <h3 className='media_text_text_title'>本項目のまとめ</h3><br />
          <p className="media_text_text">
            以上が、<strong>事業者さまによって、必要となる書類</strong>の一覧になります。<br />
            <br />
            こちらは、必須ではありませんので、自分が該当する点のみ作成いただき、効率的に交付申請を行なっていただければと思います。<br />
            <br />
            以下にて、書類の一覧を記載させていただきます。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>事業者さまによって、必要となる書類</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>交付申請書別紙２</span></li>
              <li><span>海外旅費の詳細</span></li>
              <li><span>事前着手承認のお知らせメールデータ</span></li>
              <li><span>住所など事業主に関する変更がある場合</span></li>
              <li><span>従業員数に変更がある場合</span></li>
              <li><span>海外メーカーから資産を取得する場合</span></li>
            </ul>
          </div>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>4.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、交付申請を行いたい事業者さまへ向けて、交付申請の概要、流れ、必要書類について記事を執筆させていただきました。<br />
            <br />
            必要書類のリンクも記載記載させていただいておりますので、必要資料については、国のホームページよりダウンロードいただければと思います。<br />
            <br />
            もし、交付申請について、不明点・疑問等ございましたら、弊社などのコンサルティング会社へご相談いただければと思います。<br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


