import './styles.css';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import firebase from "firebase/compat/app"

const Help = (props) => {

  function btn() {
    firebase.firestore().collection('analytics').doc(props.uid)
    .update({
      button1: true,
    })
  }
  
  return (
    <>
    <div className='AdBusiness_header_bar'>
      <h4>事業再構築補助金の申請に興味がある方は、ぜひお問い合わせください。</h4>
      <AnchorLink href="#1" offset="170"><div onClick={() => btn()}>お問い合わせ</div></AnchorLink>
    </div>
    </>
  );
}

export default Help;
