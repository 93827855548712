import './styles.css';
import React from "react";
import { AiFillCaretDown } from 'react-icons/ai';

const PhotoSolve = () => {

  return (
    <>
    <h4 className='photo_solve_title'>こんな悩みがある、美容学生いませんか？</h4>
    <div className='photo_solve_content'>
      <div>
        <div className='photo_solve_content_problem'>ライバルの作品を見て、<br />刺激を貰いたい...</div>
        <AiFillCaretDown className='photo_solve_content_arrow_icons' />
        <div className='photo_solve_content_solve'>美容学生の作品が<br />たくさん見れる！</div>
      </div>
      <div>
        <div className='photo_solve_content_problem'>自分の世界観を<br />もっと共有したい...</div>
        <AiFillCaretDown className='photo_solve_content_arrow_icons' />
        <div className='photo_solve_content_solve'>いいね機能があり<br />世界観が評価される！</div>
      </div>
      <div className='photo_solve_content_none'>
        <div className='photo_solve_content_problem'>同世代の凄い人と<br />作品を作りたい...</div>
        <AiFillCaretDown className='photo_solve_content_arrow_icons' />
        <div className='photo_solve_content_solve'>良いなと思った人に<br />snsで連絡が出来る！</div>
      </div>
    </div>
    </>
  );
}

export default PhotoSolve;
