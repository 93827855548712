// 緊急対策枠

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/20/top.png";
import Image1 from "./../../../../../image/media/business/20/1.png";
import Image2 from "./../../../../../image/media/business/20/2.png";
import Image3 from "./../../../../../image/media/business/20/3.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」緊急対策枠は、３つの要件を確認！',
    description: '本記事では、緊急対策枠について解説をしております。満たさなければいけない要件の解説、及びポイントについてもまとめさせていただきました。本記事は、「緊急対策枠での申請を考えているけど、要件や補助率がわからない」という方へ向けて、本記事を執筆させていただきました。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」緊急対策枠は、３つの要件を確認！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は、<strong>日本最大級の補助金である「事業再構築補助金」</strong>について解説させていただきます。<br />
            <br />
            本記事は、「<strong>緊急対策枠での申請を考えているけど、要件や補助率がわからない</strong>」という方へ向けて、本記事を執筆させていただきました。<br />
            <br />
            事業再構築補助金の緊急対策枠申請を検討されている方はぜひご覧ください！<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>緊急対策枠で受け取れる金額</span></li>
              <li><span>緊急対策枠の過去採択率</span></li>
              <li><span>緊急対策枠を申請する際の提出書類</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、補助金の説明書である「公募要領」を参考にして作成されたものとなっていますので、<strong>本記事の内容を理解したうえで公募要領部を読んでみてください</strong>！<br />
            <br />
            最初は難しいと感じるかもしれませんが、１点ずつ確認していきましょう。<br />
            <br />
            なお、右上のボタンからお問い合わせをお受けしておりますので、<strong>申請サポートのご依頼や不明点のご相談はお気軽にご連絡ください</strong>。<br />
            <br />
            それでは、解説に進ませていただきます！<br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">緊急対策枠とは</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">緊急対策枠の対象者</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">緊急対策枠の概要</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">緊急対策枠の過去採択率</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">緊急対策枠に申請するための要件</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">事業再構築要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">売上高等減少要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">認定支援機関要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">最低賃金要件</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">付加価値額要件</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">緊急対策枠のポイント</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">採択率が最も高い</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">回復・再生応援枠で不採択でも、通常枠での再審査が可能</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#4" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>１.緊急対策枠とは</h2>
          </div>
          <p className="media_text_text">
            <br />
            緊急対策枠とは、「<strong>昨今の原油価格・物価高騰等の影響を大きく受けている事業者</strong>」を支援する枠となっています。<br />
            <br />
            そのため、新型コロナウイルスの影響ではなく、物価高騰による売上の減少を示す必要がございます。<br />
          </p>
          <h3 className='media_text_text_title'>緊急対策枠の対象者</h3><br />
          <p className="media_text_text">
            緊急対策枠の対象者は、「<strong>昨今の原油価格・物価高騰といった、外部環境の影響を受けている</strong>」事業者となります。<br />
            <br />
            具体的には、<strong>2022年1月以降の売上が2019年～2021年の同時期と比較して、減少していることで要件を満たします</strong>。以下の「緊急対策要件」で詳しく説明しているので、是非ご確認ください！<br />
          </p>
          <h3 className='media_text_text_title'>緊急対策枠の概要</h3><br />
          <p className="media_text_text">
            本部分では、補助金額の上限等を含めた、緊急対策枠の基本情報を記載させていただきますので、自社情報と照らし合わせて、補助金額の概要や対象経費をご確認ください！<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>要件</th><th>書類名</th>
              </tr>
              <tr>
                <td>補助金額</td>
                <td>
                  【従業員5人以下】　100万円～1,000万円<br />
                  【従業員6～20人】　100万円～2,000万円<br />
                  【従業員21～50人】　100万円～3,000万円<br />
                  【従業員51人以上】　100万円～4,000万円
                </td>
              </tr>
              <tr>
                <td>補助率(※)</td>
                <td>
                  中小企業者等　3/4以内 （※１）<br />
                  中堅企業等　2/3以内 （※２）<br />
                  （※１）従業員数 5 人以下の場合 500 万円を超える部分、従業員数 6～20 人の場合 1,000 万円を超える部分、従業員数 21人以上の場合 1,500 万円を 超える部分は２／３）<br />
                  （※２）従業員数 5 人以下の場合 500 万円を超える部分、従業員数 6～20 人の 場合 1,000 万円を超える部分、従業員数 21人以上の場合 1,500 万円を 超える部分は１／２）<br />
                </td>
              </tr>
              <tr>
                <td>補助事業実施期間</td>
                <td>交付決定日から12か月以内か、採択発表日から14か月後の日の短い方</td>
              </tr>
              <tr>
                <td>補助対象経費</td>
                <td>
                  建築費、機械装置・システム構築費(リース料を含む)、技術導入費、専門家経費、運搬費、クラウドサービス利用費、外注費、知的財産権等関連経費、広告宣伝・販売促進費、研修費<br />
                </td>
              </tr>
              <tr>
                <td>申請要件</td>
                <td>
                  ・事業再構築要件<br />
                  ・緊急対策枠<br />
                  ・認定支援機関要件<br />
                  ・付加価値額要件
                </td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            (※)補助率…事業にかかる全ての経費のうち、補助金額で補填することの出来る割合<br />
            <br />
            <img src={Image1} className='media_img' alt='' />
            <br />
            なお、補助対象経費は以下の記事で説明させていただいておりますので、詳細に知りたい方はこちらの記事をお読みください！<br />
            <a href='https://trankllc.com/media/subsidy/business/0007' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【10%・15%】事業再構築補助金の売上高等減少要件を具体例で解説！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>緊急対策枠の過去採択率</h3><br />
          <p className="media_text_text">
            緊急対策枠の採択率は現在発表されておりませんので、随時更新いたします。<br />
            <br />
            なお、他申請枠の採択率を記載した記事がございますので、興味がございましたらご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0007' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【何を出せばいいの？】事業再構築補助金の提出書類一覧表を大公開！
            </a><br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>事業再構築補助金の緊急対策枠とは？</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>最低賃金の上昇により、人件費の捻出が厳しくなった事業者様が対象</span></li>
              <li><span>補助金額上限は少ないものの、補助率が3/4/4</span></li>
            </ul>
          </div>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>２.緊急対策枠に申請するための要件</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、通常枠の以下の４つの要件について、解説させていただきます。<br />
            ① <strong>事業再構築要件</strong><br />
            ② <strong>緊急対策枠</strong><br />
            ③ <strong>認定支援機関要件</strong><br />
            ④ <strong>付加価値額要件</strong><br />
          </p>
          <h3 className='media_text_text_title'>事業再構築要件</h3><br />
          <p className="media_text_text">
            事業再構築要件とは、<strong>新分野展開、事業転換、業種転換、業態転換又は事業再編のいずれかに該当する事業計画でなければいけない</strong>という要件です。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・新分野展開、事業転換、業種転換、業態転換又は事業再編のいずれかに該当する事業計画を策定すること<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・事業計画書<br />
            <br />
            <strong>参考記事</strong><br />
            上記５点の（新分野展開、事業転換、業種転換、業態転換又は事業再編）についての詳しい記事を別途作成させていただきましたので、ぜひご参照いただければと思います。<br />
            <a href='https://trankllc.com/media/subsidy/business/0007' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【類型・転換って何？】事業再構築補助金の再構築要件を、例を用いて解説！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>緊急対策要件</h3><br />
          <p className="media_text_text">
            緊急対策要件とは、<strong>2022年1月以降の連続する6か月の内、任意の3か月(物価高騰後)と2019年～2021年の同月(物価高騰前)で比較した際、総売上高が10%以上減少していること</strong>で要件を満たすことできます。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・2022年1月以降の連続する6か月の内、任意の3か月(物価高騰後)と2019年～2021年の同月(物価高騰前)で比較した際、総売上高が10%以上減少していること<br />
            <br />
            売上減少を計算する際の具体的な手順は、下記の記事でまとめて記載しておりますので、下記リンクよりご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0008' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【10%・15%】事業再構築補助金の売上高等減少要件を具体例で解説！
            </a><br />
            <br />
            <strong>【法人の場合 要件を満たすために提出する書類】</strong><br />
            <br />
            ・物価高騰前の、選択した任意3か月の売上が分かる年度確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の法人事業概況説明書の控え(両面)<br />
            ・受信通知(1枚)(e-Taxで申告している場合のみ)<br />
            ・物価高騰後の、選択した任意3か月の売上が分かる確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の法人事業概況説明書の控え(両面)<br />
            <br />
            なお、上記書類の図例を下記に記載いたしますので、こちらも参考にしてご準備ください。<br />
            <br />
            <strong>【個人の場合 要件を満たすために提出する書類】</strong><br />
            <br />
            ・物価高騰前の、選択した任意3か月の売上が分かる年度確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の月別売上の記載がある所得税青色申告決算書の控え(両面)<br />
            (白色申告の方は、対象月の売上が分かる売上台帳や、確定申告の基礎となる書類)<br />
            ・受信通知(1枚)(e-Taxで申告している場合のみ)<br />
            ・物価高騰後の、選択した任意3か月の売上が分かる年度確定申告書別表一の控え(1枚)<br />
            ・上記の確定申告書と同年度の月別売上の記載がある所得税青色申告決算書の控え(両面)<br />
            (白色申告の方は、対象月の売上が分かる売上台帳や、確定申告の基礎となる書類)<br />
            <br />
            <img src={Image2} className='media_img' alt='' />
            <br />
            所得税青色申告決算書の控え<br />
            <img src={Image3} className='media_img' alt='' /><br />
            <br />
            ・原油価格・物価高騰等の影響を受けている宣誓書<br />
            なお、上記宣誓書に関しましては、下記URLより「足許で原油価格・物価高騰等の経済環境の変化の影響を受けていることの宣誓書」をダウンロードいただき、作成を行ってください。<br />
            <a href='https://jigyou-saikouchiku.go.jp/download.php' target="_blank" rel="nofollow noopener noreferrer">
              リンク：足許で原油価格・物価高騰等の経済環境の変化の影響を受けていることの宣誓書のダウンロード
            </a><br />
          </p>
          <h3 className='media_text_text_title'>認定支援機関要件</h3><br />
          <p className="media_text_text">
            認定支援機関要件とは、<strong>国に中小企業支援の知識・経験がある者として認められた認定支援機関に事業計画書を確認してもらわなければいけない</strong>という要件です。<br />
            <br />
            認定支援機関とは、<strong>中小企業支援の知識・経験がある者として、国の認定を受けた機関であり、士業や商工会、金融機関が多く認可されています</strong>。<br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・認定支援機関と相談したうえで事業計画書を策定する。<br />
            ※認定経営革新等支援機関による確認書は、認定支援機関に相談したことを証明する書類ですので、必ず認定支援機関に作成をお願いしてください。<br />
            <br />
            <strong>【要件を満たすために必要な書類】</strong><br />
            ・認定経営革新等支援機関による確認書<br />
            ・金融機関による確認書(補助金額が3,000万円を超える場合)<br />
            <br />
            認定支援機関要件は、下記の記事にて詳しく解説しておりますので、詳細が知りたい方はぜひご参照ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0009' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【どこに依頼する？】事業再構築補助金の認定支援機関要件を機関別に解説！
            </a><br />
            <br />
            おすすめの認定支援機関や認定支援機関別の採択率は、下記の記事でまとめて記載しておりますので、下記リンクよりご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0004' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【採択率はどれくらい？】事業再構築補助金の過去採択を徹底解剖！
            </a><br />
          </p>
          <h3 className='media_text_text_title'>付加価値額要件</h3><br />
          <p className="media_text_text">
            付加価値額要件とは、<strong>付加価値額が上昇する事業計画を策定しなければいけない</strong>という要件です。<br />
            <br />
            付加価値額とは、補助金独自の考え方であり、以下の方法により算出できます。<br />
            <span>付加価値額=営業利益+減価償却費+人件費</span><br />
            <br />
            <strong>【要件を満たす方法】</strong><br />
            ・補助事業終了後3~5年で、付加価値額の年平均率を3.0%以上増加させること<br />
            ・補助事業終了後3~5年で、従業員1人当たりの付加価値額の年平均率3.0%以上増加させること<br />
            ※上記のどちらかを満たす事業計画を策定することが要件となります。<br />
            <br />
            <strong>【要件を満たすために提出する書類】</strong><br />
            ・事業計画書<br />
            <br />
            上記の要件を満たした事業計画書を策定することが必要になりますので、認定支援機関と共にご確認をお願いいたします。<br />
            <br />
            <strong>【通常枠に申請するための要件と必要書類一覧表】</strong><br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>要件</th>
                <th>書類名</th>
              </tr>
              <tr>
                <th>
                  事業再構築要件<br />
                  付加価値額要件
                </th>
                <td>
                  ・事業計画書
                </td>
              </tr>
              <tr>
                <td>
                  緊急対策要件
                </td>
                <td>
                  ・確定申告書別表一(1枚)<br />
                  ・法人事業概況説明書の控え(両面)<br />
                  ・受信通知(1枚)(e-Taxで申請している場合)<br />
                  ・原油価格・物価高騰等の経済環境の変化の影響を受けていることの宣誓書
                </td>
              </tr>
              <tr>
                <td>認定支援機関要件</td><td>・認定経営革新等支援機関による確認書</td>
              </tr>
              <tr>
                <td>要件以外の提出書類</td>
                <td>
                  ・決算書<br />
                  ・「ミラサポplus」で作成した事業財務情報のPDF
                </td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            なお、こちらの申請書類は以下の記事でさらに詳しく解説しておりますので、下記URLの記事もご確認のうえ、参考程度としてご覧ください。<br />
            <a href='https://trankllc.com/media/subsidy/business/0004' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：【何を出せばいいの？】事業再構築補助金の提出書類一覧表を大公開！
            </a><br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>３.緊急対策枠のポイント</h2>
          </div>
          <p className="media_text_text">
            <br />
            緊急対策枠の特徴を記載していますので、申請を考えている事業者様や、他の申請枠と迷っている事業者様は是非参考にご覧ください！<br />
          </p>
          <h3 className='media_text_text_title'>売上の減少を比較する時期に注意</h3><br />
          <p className="media_text_text">
            緊急対策枠は、他の申請枠と異なり「<strong>昨今の原油・物価価格高騰</strong>」に苦しむ事業者を支援するものとなっています。<br />
            <br />
            そのため、売上減少を比較する時期が「コロナ前・後」ではなく「原油・物価価格高騰前・後」であることに注意しましょう。<br />
            <br />
            それに伴い、<strong>比較する時期も異なってきますので、緊急対策枠への申請を考えている事業者様は、十分比較時期を注意して申請を行ってくださ</strong>い。<br />
          </p>
          <h3 className='media_text_text_title'>緊急対策枠で不採択でも、通常枠での再審査が可能</h3><br />
          <p className="media_text_text">
            緊急対策枠で申請を行った場合は、<strong>不採択になったとしても通常枠として再審査を行うことが可能となっています</strong>。<br />
            <br />
            また、<strong>緊急対策枠要件は通常枠の加点項目を兼ねておりますので、通常枠の審査では加点がされたうえで審査されます</strong>。<br />
            <br />
            申請する際に「通常枠での再審査を望みますか」というチェックボックスが表示されますので、追加の審査を望まれる事業者様は忘れずにチェックしましょう！<br />
            <br />
            ただし、通常枠で申請を行う際には2点の注意点がございますので、以下の点をご確認のうえ、申請準備を行ってください。<br />
            <br />
            ※ ①通常枠での審査を望まれる場合は、売上減少要件を満たしている書類を提出する必要があります。<br />
            ※ ②通常枠の再審査で採択された場合、補助上限金額や補助率は通常枠になります。<br />
          </p>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>４.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、緊急対策枠についての解説をさせていただきます。<br />
            <br />
            やや複雑なものとはなっておりますが、順を追って確認いただければと思います！<br />
            <br />
            なお、ご不明点、疑問点につきましては、<strong>画面右側の問い合わせからお待ちしておりますので、ご気軽にご連絡いただければと思います</strong>。<br />
            <br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


