import './styles.css';
import Point1 from "./../../../../image/hojokin/point1.png";
import Point2 from "./../../../../image/hojokin/point2.png";
import Point3 from "./../../../../image/hojokin/point3.png";
import Point4 from "./../../../../image/hojokin/point4.png";
import Point5 from "./../../../../image/hojokin/point5.png";
import Point6 from "./../../../../image/hojokin/point6.png";
import Point1Mini from "./../../../../image/hojokin/point1_mini.png";
import Point2Mini from "./../../../../image/hojokin/point2_mini.png";
import Point3Mini from "./../../../../image/hojokin/point3_mini.png";
import Point4Mini from "./../../../../image/hojokin/point4_mini.png";
import Point5Mini from "./../../../../image/hojokin/point5_mini.png";
import Point6Mini from "./../../../../image/hojokin/point6_mini.png";

const Help = () => {
  
  return (
    <>
    <div className='hojokin_point_items'>
      <h5>当社のこだわり</h5>
      <div>
        <h4>事業者さまに合わせた補助金を選定</h4>
        <hr />
        <div>
          <img src={Point1} alt='' className='image_big' />
          <img src={Point1Mini} alt='' className='image_mini' />
          <div>
            <h4>当社では経済産業省関連の補助金を取り扱っているため、事業者さまに合った補助金をご提案させていただきます。</h4>
            <p>当社では「事業再構築補助金」「ものづくり補助金」「小規模事業者持続化補助金」「IT導入補助金」など、経済産業省関連の補助金を幅広く取り扱っているため、お客さまのご状況、投資内容に合わせた補助金を選定し、採択までサポートさせていただきます。</p>
          </div>
        </div>
      </div>
      <div>
        <h4>こだわった事業計画書の策定</h4>
        <hr />
        <div>
          <div>
            <h4>当社は補助金の採択における重要な要素である事業計画書を当社の経験と実績を元に策定し、責任をもって、採択までサポートいたします。</h4>
            <p>当社では各公募（申請ごと）の結果を振り返り、毎回の採択傾向を分析しているため、結果へ直結できる事業計画書の作成を常に心がけております。さらに、当社はコンサルティング会社だからこそ、多数の分析を入れ、あらゆる方面から事業の良さを存分にアピールした事業計画書を仕上げます。</p>
          </div>
          <img src={Point2} alt='' className='image_big' />
          <img src={Point2Mini} alt='' className='image_mini' />
        </div>
      </div>
      <div>
        <h4>幅広い業種・業態の経験</h4>
        <hr />
        <div>
          <img src={Point3} alt='' className='image_big' />
          <img src={Point3Mini} alt='' className='image_mini' />
          <div>
            <h4>当社では幅広い業種・業態での申請経験があるため、業種・業態に合わせた計画書の策定を行っております。</h4>
            <p>当社では毎公募20件前後の申請を行っているため、さまざまな業種・業態の実績が豊富にございます。そのため、ニッチ分野で事業を行われている事業者さまやニッチ分野へ進出するお客さまは、ぜひ当社へご相談いただければと思います。</p>
          </div>
        </div>
      </div>
      <div>
        <h4>ご負担を最小限にした申請フロー</h4>
        <hr />
        <div>
          <div>
            <h4>当社では事業者さまの事業運営にご負担がないように必要最低限の部分以外は全て弊社にて行わせていただきます。</h4>
            <p>当社ではお客さまが補助金を申請されるに当たって、現在行われている事業に影響が出ないよう最小限の時間で申請できる体制を整えているため、既存事業が忙しく補助金の申請を諦めようとしている方もご安心いただければと思います。</p>
          </div>
          <img src={Point4} alt='' className='image_big' />
          <img src={Point4Mini} alt='' className='image_mini' />
        </div>
      </div>
      <div>
        <h4>各事業者さまに1名の担当者</h4>
        <hr />
        <div>
          <img src={Point5} alt='' className='image_big' />
          <img src={Point5Mini} alt='' className='image_mini' />
          <div>
            <h4>当社では情報の齟齬や業務スピードの低下を抑えるため、事業者さまに1名の担当者を付けて対応させていただいております。</h4>
            <p>当社では情報の齟齬（そご）やスピードに無駄を作らないために、事業者さまと担当者が1対1の形を取らせていただいているため、別の担当者がこの部分だけ行うなど業務を切り出すことなく、お任せいただいた担当者がヒアリングから申請まで責任を持って対応させていただきます。</p>
          </div>
        </div>
      </div>
      <div>
        <h4>補助金の申請~入金までのサポート</h4>
        <hr />
        <div>
          <div>
            <h4>当社では事業者さまが補助金の申請から受け取りまでを安心して行えるよう、申請から受け取りまでを一括してサポートしております。</h4>
            <p>補助金は申請までではなく、採択されてからも報告業務が多くございます。そこで、当社では補助金の使用で既存の事業に影響が出ないよう、申請後のサポートも当社にて行わせていただけますので、不安な方もぜひご相談いただければと思います。</p>
          </div>
          <img src={Point6} alt='' className='image_big' />
          <img src={Point6Mini} alt='' className='image_mini' />
        </div>
      </div>
    </div>
    </>
  );
}

export default Help;
