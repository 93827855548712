import DocumentMeta from 'react-document-meta';
import firebase from "firebase/compat/app"
import './styles.css';
import Nav from './../tabs/nav/index';
import Footer from './../tabs/footer/index';
import Progress1 from './../../image/contact/progress1.png';
import Progress2 from './../../image/contact/progress2.png';
import Progress3 from './../../image/contact/progress3.png';
import { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

const Contact = () => {

  const [ progress, setProgress ] = useState(1);

  const [ name, setName ] = useState('');
  const [ company, setCompany ] = useState('');
  const [ mailAddress, setMailAddress ] = useState('');
  const [ tellPhone, setTellPhone ] = useState('');
  const [ content, setContent ] = useState('');

  const [ alert, setAlert ] = useState(0);
  

  const meta = {
    title: 'お問い合わせ|トランク合同会社',
    canonical: 'https://trankllc.com',
    meta: {charset: 'utf-8', name: "robots", content: "noindex"}
  };

  function confirmation() {
    if (company === '') {
      setAlert(1);
    } else if (name === '') {
      setAlert(2);
    } else if (tellPhone === '') {
      setAlert(3);
    } else if (mailAddress === '') {
      setAlert(4);
    } else if (content === '') {
      setAlert(5);
    } else {
      setAlert(0);
      setProgress(2);
    }
  }

  function update() {
    firebase.firestore().collection('mails').doc()
    .set({
      name: name,
      company: company,
      mailAddress: mailAddress,
      tellPhone: tellPhone,
      content: content,
      time: new Date(),
    }).then(() => {
      setProgress(3);
    })
  }

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <div className='contact_header'>
        <h1>Contact us</h1>
        <h2>お問い合わせ</h2>
      </div>
      {
        progress === 1 ?
        <div className='contact_content'>
          <div className='contact_content_progress'>
            <div>
              <p style={{fontWeight : 'bold'}}>入力</p>
              <p>確認</p>
              <p>完了</p>
            </div>
            {
              progress === 1 ?
              <img src={Progress1} alt='' />
              : progress === 2 ?
              <img src={Progress2} alt='' />
              :
              <img src={Progress3} alt='' />
            }
          </div>
          <div className='contact_content_box'>
            <h3>会社名</h3>
            <input value={company} onChange={e => setCompany(e.target.value)} />
          </div>
          <div className='contact_content_box'>
            <h3>氏名</h3>
            <input value={name} onChange={e => setName(e.target.value)} />
          </div>
          <div className='contact_content_box'>
            <h3>電話番号</h3>
            <input value={tellPhone} onChange={e => setTellPhone(e.target.value)} />
          </div>
          <div className='contact_content_box'>
            <h3>メールアドレス</h3>
            <input value={mailAddress} onChange={e => setMailAddress(e.target.value)} />
          </div>
          <div className='contact_content_box'>
            <h3>お問い合わせ内容</h3>
            <TextareaAutosize className='contact_content_box_area' onChange={e => setContent(e.target.value)} />
            <div></div>
          </div>
          <p className='contact_content_alart' style={alert === 1 ? {display : 'block'} : {display : 'none'}}>※会社名を入力してください。</p>
          <p className='contact_content_alart' style={alert === 2 ? {display : 'block'} : {display : 'none'}}>※氏名を入力してください。</p>
          <p className='contact_content_alart' style={alert === 3 ? {display : 'block'} : {display : 'none'}}>※電話番号を入力してください。</p>
          <p className='contact_content_alart' style={alert === 4 ? {display : 'block'} : {display : 'none'}}>※メールアドレスを入力してください。</p>
          <p className='contact_content_alart' style={alert === 5 ? {display : 'block'} : {display : 'none'}}>※お問い合わせ内容を入力してください。</p>
          <button className='contact_content_btn' onClick={() => confirmation()}  style={{margin : '0 calc((100% - 200px) / 2)'}}>確認</button>
        </div>
        : progress === 2 ?
        <div className='contact_content'>
          <div className='contact_content_progress'>
            <div>
              <p>入力</p>
              <p style={{fontWeight : 'bold'}}>確認</p>
              <p >完了</p>
            </div>
            <img src={Progress2} alt='' />
          </div>
          <div className='contact_content_box'>
            <h3>会社名</h3>
            <p>{company}</p>
          </div>
          <div className='contact_content_box'>
            <h3>氏名</h3>
            <p>{name}</p>
          </div>
          <div className='contact_content_box'>
            <h3>電話番号</h3>
            <p>{tellPhone}</p>
          </div>
          <div className='contact_content_box'>
            <h3>メールアドレス</h3>
            <p>{mailAddress}</p>
          </div>
          <div className='contact_content_box'>
            <h3>お問い合わせ内容</h3>
            <p>{content}</p>
          </div>
          <div className='contact_content_second_btn'>
            <button className='contact_content_btn' onClick={() => setProgress(1)}>編集</button>
            <button className='contact_content_btn' onClick={() => update()}>送信</button>
          </div>
        </div>
        :
        <div className='contact_content'>
          <div className='contact_content_progress'>
            <div>
              <p>入力</p>
              <p>確認</p>
              <p style={{fontWeight : 'bold'}}>完了</p>
            </div>
            {
              progress === 1 ?
              <img src={Progress1} alt='' />
              : progress === 2 ?
              <img src={Progress2} alt='' />
              :
              <img src={Progress3} alt='' />
            }
          </div>
          <p className='contact_content_third'>
            弊社にご関心をお持ちいただき、ありがとうございます。<br />
            お問合せ内容の確認後、３営業日以内に担当者よりご連絡いたします。<br />
            引き続き何卒よろしくお願いいたします。
          </p>
        </div>
      }
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default Contact;
