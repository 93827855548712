import './styles.css';

const Help = () => {
  
  return (
    <>
    <div className='hojokin_flow'>
      <h4>サービスの利用開始までの流れ</h4>
      <div>
        <h5>01　お問い合わせ</h5>
        <h6>本サイトの下記の部分よりお問い合わせを行うことが可能ですので、まずはお問い合わせいただけますと幸いです。</h6>
      </div>
      <div>
        <h5>02　サービス説明</h5>
        <h6>お問い合わせいただきましたら、当社の担当よりご連絡を行わせたいただき、補助金のご説明、サービスのご説明を行わせていただきます。</h6>
      </div>
      <div>
        <h5>03　ご契約書の締結</h5>
        <h6>弊社にてお任せいただけることが決まりましたら、ご契約書に押印を行い、契約書のご締結を行わせていただきます。</h6>
      </div>
      <div>
        <h5>04　ご入金</h5>
        <h6>当社で提示させていただいております着手金をお振込いただき、申請へと入らせていただきます。</h6>
      </div>
      <div>
        <h5>05　申請準備</h5>
        <h6>申請準備ではまず、ヒアリングから行いますので、日程を調整し、担当者とのヒアリングを行なっていただきます。</h6>
      </div>
    </div>
    </>
  );
}

export default Help;
