// ミラサポ

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/34/top.png";
import Image1 from "./../../../../../image/media/business/34/1.png";
import Image2 from "./../../../../../image/media/business/34/2.png";
import Image3 from "./../../../../../image/media/business/34/3.png";
import Image4 from "./../../../../../image/media/business/34/4.png";
import Image5 from "./../../../../../image/media/business/34/5.png";
import Image6 from "./../../../../../image/media/business/34/6.png";
import Image7 from "./../../../../../image/media/business/34/7.png";
import Image8 from "./../../../../../image/media/business/34/8.png";
import Image9 from "./../../../../../image/media/business/34/9.png";
import Image10 from "./../../../../../image/media/business/34/10.png";
import Image11 from "./../../../../../image/media/business/34/11.png";
import Image12 from "./../../../../../image/media/business/34/12.png";
import Footer from './../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」ミラサポを15分で作成する方法！%増加！',
    description: '本記事では、事業再構築補助金の必須書類である「ミラサポ」の作成方法を解説しております。さらに、初めての方では分からない最短での用意する方法も解説しておりますので、ぜひご参考にしていただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」ミラサポを15分で作成する方法！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は<strong>事業再構築補助金の給与総額増加要件</strong>について、解説させていただきます。<br />
            <br />
            本記事はミラサポの作成方法について、詳しく解説させていただきましたので、ぜひ最後までご覧いただければと思います。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>本記事でこんな悩みが解決！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>ミラサポで必要な事前準備</span></li>
              <li><span>ミラサポのログイン方法</span></li>
              <li><span>ミラサポの作成方法</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、<strong>経済産業省から展開されている「事業再構築指針の手引き」を参考にして作成したもの</strong>となっています。<br />
            <a href='https://www.meti.go.jp/covid-19/jigyo_saikoutiku/pdf/shishin_tebiki.pdf' target="_blank" rel="nofollow noopener noreferrer">
              詳しい記事：「事業再構築指針の手引き」に関する記事
            </a><br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">ミラサポとは？</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">ミラサポ作成に必要なもの</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">GbizIDの取得方法</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">ミラサポ作成の手順</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">ミラサポへログインする</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">ミラサポの入力画面へ移動</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">ミラサポの基本情報の編集</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">ミラサポの情報編集</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">ミラサポのPDF書き出し</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.ミラサポとは？</h2>
          </div>
          <p className="media_text_text">
            <br />
            ミラサポとは、事業財務情報を一覧で見るために提示されているツールの1つとなります。<br />
            <br />
            事業再構築補助金でもミラサポの提出が必要となりますので、本記事では作成方法を具体的にご説明させていただければと思います。<br />
          </p>
          <h3 className='media_text_text_title'>ミラサポ作成に必要なもの</h3><br />
          <p className="media_text_text">
            ミラサポを作成するには、経済産業省のサイトにログインする必要があるのですが、その際にGbizIDが必要になるため事前にGbizIDを取得いただければと思います。<br />
            <br />
            GbizIDは事業再構築補助金の申請には欠かせないものですので、持ち合わせていない方は次項にて取得方法を説明しておりますので、ご確認ください。<br />
            <br />
            <span>↓GbizIDを持っていない方</span><br />
          </p>
          <h3 className='media_text_text_title'>GbizIDの取得方法</h3><br />
          <p className="media_text_text">
            GbizIDの取得方法は、経済産業省より取得マニュアルが出ておりますので、以下のサイトを参考に進めていただければと思います。<br />
            <a href='https://gbiz-id.go.jp/top/manual/pdf/QuickManual_Prime.pdf' target="_blank" rel="nofollow noopener noreferrer">
              参考書類：GbizIDの取得方法
            </a><br />
            <strong>※gBizメンバーではなく、gBizIDプライムをご取得ください。（gBizメンバーでは事業再構築の申請を行うことが出来ません。）</strong><br />
            <br />
            結構大変なのかな？と思っている方もいるかと思いますが、以下の3stepで終わる簡単なものですので、ご安心ください。<br />
            <br />
            <span>GbizIDの取得方法 3step</span><br />
            <strong>① 以下のリンクからGbizの取得を申請</strong><br />
            　
            <a href='https://gbiz-id.go.jp/app/rep/reg/apply/show' target="_blank" rel="nofollow noopener noreferrer">
              参考書類：GbizIDの手順①
            </a><br />
            <strong>② 登録申請書を印刷して、「作成日を記入」「代表者印の押印」を記載した上以下の住所へ送付。</strong><br />
            　〒530-8532 GビズID運用センター宛<br />
            <strong>③ 登録完了メールを待つ（2週間程度）</strong><br />
          </p>
          </>
          
          <>
          <div id='2' className="media_text_title">
            <h2>2.ミラサポ作成の手順</h2>
          </div>
          <p className="media_text_text">
            <br />
            ミラサポの手順は以下の通りとなっており、
          </p>
          <h3 className='media_text_text_title'>ミラサポへログインする</h3><br />
          <p className="media_text_text">
            <strong>手順①</strong><br />
            以下のURLからGbizでログインすることで、ミラサポのページへ入ることが出来ます。<br />
            <a href='https://gbiz-id.go.jp/oauth/login' target="_blank" rel="nofollow noopener noreferrer">
              参考書類：Gbizのログインページ
            </a><br />
            <br />
            <img src={Image1} className='media_img' alt='' />
          </p>
          <h3 className='media_text_text_title'>ミラサポの入力画面へ移動</h3><br />
          <p className="media_text_text">
            <strong>手順②</strong><br />
            ミラサポにログインすると以下のサイトへ飛ぶと思いますので、本サイトの<strong>電子申請サポートをクリックしてください</strong>。<br />
            ※赤の四角で囲われている部分<br />
            <br />
            <img src={Image2} className='media_img' alt='' /><br />
            <br />
            <strong>手順③</strong><br />
            電子申請サポートをクリックすると以下のサイトへ飛ぶので、<strong>データを追加するをクリックしてください</strong>。<br />
            ※赤の四角で囲われている部分<br />
            <br />
            <img src={Image3} className='media_img' alt='' /><br />
            <br />
            <strong>手順④</strong><br />
            データを追加するをクリックすると、<strong>タイトル未入力という箱が追加されますので、そちらをクリックしてください</strong>。<br />
            ※赤の四角で囲われている部分<br />
            <br />
            <img src={Image4} className='media_img' alt='' /><br />
          </p>
          <h3 className='media_text_text_title'>ミラサポの基本情報の編集</h3><br />
          <p className="media_text_text">
            <strong>手順⑤</strong><br />
            タイトル未入力をクリックすると、以下の画面に行きますので、<strong>この画面のデータ名編集ボタン、編集ボタンをクリックして、どちらも内容を変更してください。</strong><br />
            ※赤の四角で囲われている部分<br />
            <br />
            <img src={Image5} className='media_img' alt='' /><br />
          </p>
          <h3 className='media_text_text_title'>ミラサポの情報編集</h3><br />
          <p className="media_text_text">
            <strong>手順⑥</strong><br />
            基本情報の編集が終わったら、<strong>事業財務情報をクリックして財務情報を入力します</strong>。<br />
            ※赤の四角で囲われている部分<br />
            <br />
            <img src={Image6} className='media_img' alt='' /><br />
            <br />
            <strong>手順⑦</strong><br />
            <strong>事業財務情報を編集するために編集をクリックしてください</strong>。<br />
            ※赤の四角で囲われている部分<br />
            <br />
            <img src={Image7} className='media_img' alt='' /><br />
            <br />
            <strong>手順⑧</strong><br />
            <strong>編集ボタンをクリックすると情報を入力することが出来ますので、情報をご入力ください</strong>。<br />
            この入力情報は米印があるもののみを記載すれば問題ございません。<br />
            <br />
            <img src={Image8} className='media_img' alt='' /><br />
            <br />
            <strong>手順⑨</strong><br />
            <strong>入力が終わったら保存ボタンで保存してください。</strong>。<br />
            ※赤の四角で囲われている部分<br />
            <br />
            <img src={Image9} className='media_img' alt='' /><br />
            <br />
          </p>
          <h3 className='media_text_text_title'>ミラサポのPDF書き出し</h3><br />
          <p className='media_text_text'>
            <strong>手順⑩</strong><br />
            保存ボタンをクリックしましたら、<strong>印刷・PDFをクリックしてください</strong>。<br />
            ※赤の四角で囲われている部分<br />
            <br />
            <img src={Image10} className='media_img' alt='' /><br />
            <br />
            <strong>手順11</strong><br />
            <strong>OKをクリックしてください</strong>。<br />
            ※赤の四角で囲われている部分<br />
            <br />
            <img src={Image11} className='media_img' alt='' /><br />
            <br />
            <strong>手順12</strong><br />
            <strong>保存をクリックしてください</strong>。<br />
            ※赤の四角で囲われている部分<br />
            <br />
            <img src={Image12} className='media_img' alt='' /><br />
            <br />
            お疲れ様でした。<br />
            このファイルを事業再構築補助金の申請時に添付していただければと思います。<br />
            <br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.まとめ</h2>
          </div>
          <p className='media_text_text'>
            <br />
            本記事ではミラサポの作成方法を解説させていただきました。<br />
            最後までご覧いただき、ありがとうございました。また、大変お疲れ様でした。<br />
            <br />
            スクリーンショットを書く箇所分、貼り付けておりますので、ぜひゆっくり見返しながら作成していただければと思います。<br />
            <br />
            もし、疑問点等ございましたら、お申し付けください。<br />
            ご連絡お待ちしております。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;

