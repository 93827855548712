import { useHistory  } from 'react-router-dom';
import './styles.css';
import React, { useState } from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll';

const SimulatorSimulator = (props) => {
  const history = useHistory();

  const [ select1, setselect1 ] = useState(0);
  const [ select2, setselect2 ] = useState(0);
  const [ select3, setselect3 ] = useState(0);
  const [ select4, setselect4 ] = useState(0);
  const [ select5, setselect5 ] = useState(0);
  const [ select6, setselect6 ] = useState(0);

  function start() {

    var data1 = 'F';
    var data2 = 'F';
    var data3 = 'F';
    var data4 = 'F';

    if (select1 !== 0 && select1 !== 15) { data3 = 'T' } // 産業構造転換枠
    if (select2 !== 0 && select2 !== 20 && select2 !== 22) { data2 = 'T' } // 成長枠
    if (select2 === 20) { data4 = 'T' } // グリーン関連
    if (select4 === 2) { data1 = 'T' } // 物価高騰

    history.push(`/simulatorResult/data=${data1}+${data2}+${data3}+${data4}+${select5}+${select6}`);
  }

  return(
  <>
  <div className='simulator_simulator' id='1'>

    <h4 className='simulator_simulator_title_h4'>6個の質問で補助金額を診断</h4>

    <div className='simulator_simulator_content'>
      <h4>No.1 現在行っている事業はどの業種に該当しますか？</h4>
      <div>
        <AnchorLink href="#2" offset="0">
          <button onClick={() => setselect1(1)}
            style={select1 === 1 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>製造業</button>
        </AnchorLink>
        <AnchorLink href="#2" offset="0">
          <button onClick={() => setselect1(2)} 
            style={select1 === 2 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>印刷、製版業</button>
        </AnchorLink>
        <AnchorLink href="#2" offset="50">
          <button onClick={() => setselect1(3)} 
            style={select1 === 3 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>石油卸、燃料小売業</button>
        </AnchorLink>
        <AnchorLink href="#2" offset="50">
          <button onClick={() => setselect1(4)} 
            style={select1 === 4 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>写真機小売、焼付業</button>
        </AnchorLink>
        <AnchorLink href="#2" offset="50">
          <button onClick={() => setselect1(5)} 
            style={select1 === 5 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>出版業</button>
        </AnchorLink>
        <AnchorLink href="#2" offset="50">
          <button onClick={() => setselect1(6)} 
            style={select1 === 6 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>自動車事故整備業</button>
        </AnchorLink>
        <AnchorLink href="#2" offset="50">
          <button onClick={() => setselect1(7)} 
            style={select1 === 7 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>洗濯、理容、美容業</button>
        </AnchorLink>
      </div>
      <AnchorLink href="#2" offset="50">
        <button onClick={() => setselect1(15)} className='simulator_simulator_content_noInclude'
          style={select1 === 15 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>含まれていない</button>
      </AnchorLink>
    </div>

    <div className='simulator_simulator_content' id='2'>
      <h4>No.2 新しく行っている/行う予定の事業は、どの業種に該当しますか？</h4>
      <div>
        <AnchorLink href="#3" offset="50">
          <button onClick={() => setselect2(1)}
            style={select2 === 1 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>製造業</button>
        </AnchorLink>
        <AnchorLink href="#3" offset="50">
          <button onClick={() => setselect2(2)}
            style={select2 === 2 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>電気、ガス、熱供給、水道業</button>
        </AnchorLink>
        <AnchorLink href="#3" offset="50">
          <button onClick={() => setselect2(3)}
            style={select2 === 3 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>情報通信業</button>
        </AnchorLink>
        <AnchorLink href="#3" offset="50">
          <button onClick={() => setselect2(4)}
            style={select2 === 4 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>卸売業、小売業</button>
        </AnchorLink>
        <AnchorLink href="#3" offset="50">
          <button onClick={() => setselect2(5)}
            style={select2 === 5 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>物品賃貸業</button>
        </AnchorLink>
        <AnchorLink href="#3" offset="50">
          <button onClick={() => setselect2(6)}
            style={select2 === 6 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>サービス業</button>
        </AnchorLink>
        <AnchorLink href="#3" offset="50">
          <button onClick={() => setselect2(7)}
            style={select2 === 7 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>娯楽業</button>
        </AnchorLink>
      </div>
      <AnchorLink href="#3" offset="50">
        <button onClick={() => setselect2(21)} className='simulator_simulator_content_noInclude'
          style={select2 === 21 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>未定</button>
      </AnchorLink>
      <AnchorLink href="#3" offset="50">
        <button onClick={() => setselect2(22)} className='simulator_simulator_content_noInclude'
          style={select2 === 22 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>含まれていない</button>
      </AnchorLink>
    </div>

    <div className='simulator_simulator_content' id='3'>
      <h4>No.3 創業してから何期目の会社ですか？</h4>
      <div>
        <AnchorLink href="#4" offset="50">
          <button onClick={() => setselect3(1)}
            style={select3 === 1 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>1年未満</button>
        </AnchorLink>
        <AnchorLink href="#4" offset="50">
          <button onClick={() => setselect3(2)}
            style={select3 === 2 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>2年~3年</button>
        </AnchorLink>
        <AnchorLink href="#4" offset="50">
          <button onClick={() => setselect3(3)}
            style={select3 === 3 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>3年~5年</button>
        </AnchorLink>
        <AnchorLink href="#4" offset="50">
          <button onClick={() => setselect3(4)}
            style={select3 === 4 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>6年~10年</button>
        </AnchorLink>
        <AnchorLink href="#4" offset="50">
          <button onClick={() => setselect3(5)}
            style={select3 === 5 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>10年~30年</button>
        </AnchorLink>
        <AnchorLink href="#4" offset="50">
          <button onClick={() => setselect3(6)}
            style={select3 === 6 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>40年~50年</button>
        </AnchorLink>
        <AnchorLink href="#4" offset="50">
          <button onClick={() => setselect3(7)} className='simulator_simulator_content_noInclude'
            style={select3 === 7 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>創業していない</button>
        </AnchorLink>
      </div>
    </div>

    <div className='simulator_simulator_content' id='4'>
      <h4>No.4 2021年以前と比べ、売上は減少していますか？</h4>
      <div>
        <AnchorLink href="#5" offset="50">
          <button onClick={() => setselect4(1)}
            style={select4 === 1 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>増加している</button>
        </AnchorLink>
        <AnchorLink href="#5" offset="50">
          <button onClick={() => setselect4(2)}
            style={select4 === 2 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>減少している</button>
        </AnchorLink>
      </div>
    </div>

    <div className='simulator_simulator_content' id='5'>
      <h4>No.5 現在の従業員数は何名ですか？(役員を除く)</h4>
      <div>
        <AnchorLink href="#6" offset="50">
          <button onClick={() => setselect5(1)}
            style={select5 === 1 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>5名以下</button>
        </AnchorLink>
        <AnchorLink href="#6" offset="50">
          <button onClick={() => setselect5(2)}
            style={select5 === 2 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>6名~10名</button>
        </AnchorLink>
        <AnchorLink href="#6" offset="50">
          <button onClick={() => setselect5(3)}
            style={select5 === 3 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>11名~20名</button>
        </AnchorLink>
        <AnchorLink href="#6" offset="50">
          <button onClick={() => setselect5(4)}
            style={select5 === 4 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>21名~50名</button>
        </AnchorLink>
        <AnchorLink href="#6" offset="50">
          <button onClick={() => setselect5(5)}
            style={select5 === 5 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>51名~100名</button>
        </AnchorLink>
        <AnchorLink href="#6" offset="50">
          <button onClick={() => setselect5(6)}
            style={select5 === 6 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>100名以上</button>
        </AnchorLink>
      </div>
    </div>

    <div className='simulator_simulator_content' id='6'>
      <h4>No.6 新規事業にかかる金額はいくらですか？(予定でも可)</h4>
      <div>
        <AnchorLink href="#7" offset="50">
          <button onClick={() => setselect6(1)}
            style={select6 === 1 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>500万円以下</button>
        </AnchorLink>
        <AnchorLink href="#7" offset="50">
          <button onClick={() => setselect6(2)}
            style={select6 === 2 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>501万円~1000万円</button>
        </AnchorLink>
        <AnchorLink href="#7" offset="50">
          <button onClick={() => setselect6(3)}
            style={select6 === 3 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>1001万円~1500万円</button>
        </AnchorLink>
        <AnchorLink href="#7" offset="50">
          <button onClick={() => setselect6(4)}
            style={select6 === 4 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>1501万円~2000万円</button>
        </AnchorLink>
        <AnchorLink href="#7" offset="50">
          <button onClick={() => setselect6(5)}
            style={select6 === 5 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>2001万円~3000万円</button>
        </AnchorLink>
        <AnchorLink href="#7" offset="50">
          <button onClick={() => setselect6(6)}
            style={select6 === 6 ? {backgroundColor : '#EB6231', color : 'white'} : {backgroundColor : 'white', color : '#222222'}}>3001万円以上</button>
        </AnchorLink>
        </div>
    </div>
    <button onClick={() => start()} className='simulator_simulator_content_start' id='7'>診断を開始する</button>
  </div>
  </>
  );
}

export default SimulatorSimulator;

