import './styles.css';
import Icon from "./../../../image/home/icon.png";

const Home = () => {

  return (
    <>
    <div className="nav">
      <a href='https://trankllc.com/' rel="nofollow noopener noreferrer">
        <div className="nav_icon">
          <img src={Icon} alt='' />
          <p>trankllc</p>
        </div>
      </a>
      <div className="nav_text">
        <a href='https://trankllc.com/media/subsidy/business/0000' target="_blank" rel="nofollow noopener noreferrer">
          <p>blog</p>
        </a>
        <a href='https://trankllc.com/company' rel="nofollow noopener noreferrer">
          <p>about</p>
        </a>
        <a href='https://trankllc.com/contact' rel="nofollow noopener noreferrer">
          <p>contact</p>
        </a>
      </div>
    </div>
    </>
  );
}

export default Home;