// 生活関連事業
// https://docs.google.com/document/d/1SNT79dJOQLhFOw179BNHJhytwKih-4NQLE8RGkm9uyY/edit#heading=h.rnx1wvhnxe1p

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "../../nav/index";
import Topic from "../topic";
import TopImage from "./../../../../../image/media/business/44/top.png";
import Image1 from "./../../../../../image/media/business/44/1.png";
import Footer from '../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」生活関連事業の採択事例を大公開！',
    description: '本記事では、事業再構築補助金の飲食業 採択事例を紹介しております。飲食業を営んでいて事業再構築補助金の申請を検討されている方は、本記事をご参考に検討を行っていただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」生活関連事業の採択事例を大公開！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            外出自粛や休業要請など、新型コロナウイルスによる影響を受けた業種として、クリーニング店や理髪店、美容室、エステサロンといった対面型のサービスを提供する生活関連事業が挙げられます。<br />
            <br />
            最近になって新型コロナウイルスもようやく落ち着きを見せ始めてはいるものの、離れた客足が戻らずに苦戦を強いられている生活関連事業者は多いのではないでしょうか？<br />
            <br />
            本記事では、事業再構築補助金を検討中の生活関連事業者向けに、補助金の活用方法や申請傾向、採択事例について詳しく解説します。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業再構築補助金の対象となる生活関連事業者</span></li>
              <li><span>生活関連事業における事業再構築補助金の活用方法</span></li>
              <li><span>過去の生活関連事業者採択例</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            事業再構築補助金に関する役立つ情報を解説していますので、ぜひ最後までご覧ください。<br />
            <br />
            また、本記事の掲載元である当社では初回無料相談を実施していますので、より詳細な話を聞いてみたいという方は、お気軽にお問い合わせください。<br />
            <br />
            ※本記事は「第10回事業再構築補助金　公募要領」をもとに作成しています。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo.pdf' target="_blank" rel="nofollow noopener noreferrer">
              リンク：事業再構築補助金 公募要領
            </a><br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">生活関連事業における活用方法</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金の対象となる経費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">生活関連事業での事業再構築類型の具体例</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金での申請例</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#1" offset="100">虎の巻から生活関連事業者の特徴</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">生活関連事業者の申請傾向</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">具体的な採択事例</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">生活関連事業の申請で抑えるべきポイント</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#1" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.生活関連事業における活用方法</h2>
          </div>
          <p className="media_text_text">
            <br />
            まずは、生活関連事業における事業再構築補助金の活用方法について、以下の3項目に分けて解説します。<br />
            <strong>1.事業再構築補助金の対象となる経費</strong><br />
            <strong>2.生活関連事業での事業再構築類型の具体例</strong><br />
            <strong>3.事業再構築補助金での申請例</strong><br />
            いずれも重要なポイントですので、じっくりとご覧ください。<br />
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金の対象となる経費</h3><br />
          <p className="media_text_text">
            まずは、事業再構築補助金の対象経費について。<br />
            対象経費については、幅広く設けられており、具体的には以下の11種類に分けられています。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>経費項目</th><th>詳細</th><th>具体例</th>
              </tr>
              <tr>
                <td>①建物費</td>
                <td>補助事業にかかる建物の建設や改修にかかる経費。</td>
                <td>・建物の建設、改修費<br />・建物の撤去、原状回復費</td>
              </tr>
              <tr>
                <td>②機械装置・システム構築費</td>
                <td>補助事業で使用する機械装置やシステム開発にかかる経費。</td>
                <td>・製造設備の導入費・専用ソフト購入費<br />・機械装置のリース費</td>
              </tr>
              <tr>
                <td>③技術導入費</td>
                <td>補助事業の遂行に必要な知的財産権等の導入に要する経費。</td>
                <td>・知的財産権等の導入費</td>
              </tr>
              <tr>
                <td>④専門家経費</td>
                <td>補助事業遂行のために専門家に支払われる経費。</td>
                <td>・専門家へのコンサルティング業務の依頼費<br />・それに伴う旅費など</td>
              </tr>
              <tr>
                <td>⑤運搬費</td>
                <td>運搬料、宅配・郵送料等に要する経費。</td>
                <td>・運搬料<br />・郵送料など</td>
              </tr>
              <tr>
                <td>⑥クラウドサービス利用費</td>
                <td>クラウドサービスの利用に関する経費。</td>
                <td>・クラウドサービス利用費<br />・WEBプラットフォーム利用費</td>
              </tr>
              <tr>
                <td>⑦外注費</td>
                <td>本事業に必要な加工や設計・検査等の一部を外注する際にかかる経費。</td>
                <td>・製品開発に要する加工費<br />・設計費など</td>
              </tr>
              <tr>
                <td>⑧知的財産権等関連経費</td>
                <td>特許権等の知的財産権等の取得に要する経費。</td>
                <td>・弁理士の手続代行費<br />・外国特許出願のための翻訳料など</td>
              </tr>
              <tr>
                <td>⑨広告宣伝・販売促進費</td>
                <td>製品・サービスに関する広告の作成や媒体掲載にかかる経費。</td>
                <td>・広告媒体の掲載費<br />・WEB広告費など</td>
              </tr>
              <tr>
                <td>⑩研修費</td>
                <td>教育訓練や講座受講等に係る経費。</td>
                <td>・従業員の教育費費</td>
              </tr>
              <tr>
                <td>⑪廃業費（産業構造転換枠に申請し、既存事業の廃止を行う場合のみ）</td>
                <td>既存事業の廃止にかかる経費</td>
                <td>・廃止手続費<br />・解体費<br />・移転・移設費用など</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            このように、設備投資に活用できる対象経費は幅広く設けられています。<br />
            一方で、対象外経費も細かく決められているため、申請の際は事前に対象経費に含まれるのかしっかり確認しておく必要があります。<br />
            <br />
            たとえば、建物費の場合だと、新事業用の建物の建設や改修は対象経費となりますが、建物の購入や賃貸は対象外となります。<br />
            <br />
            他にも、スマホやタブレット、PCなど、既存事業にも使用できてしまう汎用性の高いものは対象外となります。<br />
            <br />
            これは、事業再構築補助金で申請する経費が新事業の設備投資を支援するものであるため、既存事業と明確に区分できなけらばならないからです。<br />
            <br />
            経費にできると思っていたものが実は対象外経費だったということにならないよう、申請の際は十分に注意しましょう。<br />
            <br />
            もし、自社で対象経費を調べてみたもののよくわからないという場合は、当社にお問い合わせください。<br />
            当社では、じっくりお話をお聞きしたうえで、経費の確認や金額の算出のお手伝いをさせていただいております。<br />
          </p>
          <h3 className='media_text_text_title'>生活関連事業での事業再構築類型の具体例</h3>
          <p className="media_text_text">
            続いては、事業再構築補助金の類型について。<br />
            事業再構築補助金に申請するためには、申請要件を満たしておく必要があります。<br />
            要件は、「新市場進出」「事業転換」「業種転換」の3つ類型があり、具体的には以下のとおり。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th>類型を満たすための要件</th><th>産業分類に基づく変更</th>
              </tr>
              <tr>
                <td>新市場進出</td>
                <td>
                  ①製品又は提供方法を大きく変更する<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全体の10%以上となる計画を策定する。
                </td>
                <td>無</td>
              </tr>
              <tr>
                <td>事業転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。<br />
                </td>
                <td>主な事業を変更(中・小・細分類)</td>
              </tr>
              <tr>
                <td>業種転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3～5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。
                </td>
                <td>主な業種を変更<br />(大分類)</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            上記3類型を生活関連業に当てはめると次のとおりです。<br />
            <br />
            <strong>【生活関連業：新市場進出】</strong><br />
            新市場進出とは、生活関連事業という業種はそのままで、販売方法やサービスの提供方法などを変えて事業を開始する場合に該当します。<br />
            たとえば、「美容室を改装して、安心安全にリラックスして利用できる完全個室型美容室へ変更する」など。<br />
            <br />
            日本産業分類は生活関連業のままですが、サービスの提供方法を変えているという点で要件を満たしています。<br />
            <br />
            <strong>【生活関連業：事業転換】</strong><br />
            事業転換とは、生活関連事業という業種はそのままで、主な事業を変更する場合に該当します。<br />
            たとえば、「ネイルサロンの空きスペースを活用し、美容脱毛サロンを開始する」など。<br />
            <br />
            日本産業分類の細分類が「7894：ネイルサービス業→7892：エステティック業」へと変更しており、要件を満たしています。<br />
            <br />
            <strong>【生活関連業：業種転換】</strong><br />
            業種転換とは、生活関連事業から別業種に進出する場合に該当します。<br />
            「エステサロンにゆっくりくつろげるカフェを併設する」といった例が挙げられます。<br />
            <br />
            日本産業分類は「N：生活関連サービス業、娯楽業→M：宿泊業、飲食サービス業」に変更。<br />
            大分類が変更されていることから業種転換の要件を満たします。<br />
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金での申請例</h3>
          <p className="media_text_text">
            続いては、先ほど解説した対象経費や要件を用いて、具体的な申請例について解説します。<br />
            補助金額の計算方法も記載しておりますので、自社で事業再構築補助金を活用する際の参考にしてみてください。<br />
            <br />
            <strong>【事業内容】</strong><br />
            <br />
            新型コロナウイルスによる影響で美容室の売上が減少。<br />
            美容室の規模を縮小し、空きスペースに美容脱毛サロンを併設し、新たな顧客の獲得を目指す。<br />
            <br />
            事業場所：兵庫県神戸市<br />
            従業員数：10名<br />
            売上高：8,000万円<br />
            <br />
            <strong>【日本産業分類から類型を決定】</strong><br />
            <br />
            既存事業と新事業の日本産業分類を比較すると以下のとおり。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>日本産業分類</th><th>大分類</th><th>中分類</th><th>小分類</th><th>細分類</th>
              </tr>
              <tr>
                <td>既存事業</td><td>N：生活関連サービス業、娯楽業</td><td>78：洗濯・理容・美容・浴場業</td><td>783：美容業</td><td>7831：美容業</td>
              </tr>
              <tr>
                <td>新規事業</td><td>N：生活関連サービス業、娯楽業</td><td>78：洗濯・理容・美容・浴場業</td><td>89：その他の洗濯・理容・美容・浴場業</td><td>7892：エステティック業</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            小分類を見ると「783：美容業→789：その他の洗濯・理容・美容・浴場業」へと変更しているため、今回のケースは「事業転換」ということになります。<br />
            <br />
            <strong>【売上高から申請枠を決定】</strong><br />
            <br />
            下表は2021年と2022年の売上を比較したものとなります。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'></th><th>6月</th><th>7月</th><th>8月</th><th>合計</th>
              </tr>
              <tr>
                <td>2021年</td><td>8,500</td><td>8,000</td><td>8,500</td><td>25,000</td>
              </tr>
              <tr>
                <td>2022年</td><td>6,500</td><td>6,200</td><td>6,000</td><td>18,700</td>
              </tr>
              <tr>
                <td>増減率(%)</td><td>-24%</td><td>-22%</td><td>-30%</td><td>-25%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            2022年の売上が前年より25％も減少しているため、「物価高騰対策・回復再生応援枠」の要件を満たします。<br />
            本事業は、「物価高騰対策・回復再生応援枠」にて申請します。<br />
            <br />
            ※2022年1月以降の連続する6か月間のうち、任意の3か月の合計売上高が対2019～2021年の同3か月の合計売上高と比較して10％減少していることが、「物価高騰対策・回復再生応援枠」の要件となります。<br />
            <br />
            <strong>【補助金額の計算】</strong><br />
            申請枠が決定したので、経費の計算および補助金額の計算を行います。<br />
            なお、本事業にかかる経費は以下のとおりです。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>建物の改装工事費用</th><th>￥5,000,000</th>
              </tr>
              <tr>
                <td>美容脱毛機器の導入費</td><td>￥2,000,000</td>
              </tr>
              <tr>
                <td>広告費</td><td>￥1,400,000</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            上記経費はすべて事業再構築補助金の対象経費に含まれます。<br />
            そのため、改修工事費、脱毛機器の導入費、広告費を合わせた8,400,000円が本事業の申請金額となります。<br />
            <br />
            第10回の事業再構築補助金公募要領によると、中小企業の補助率は2/3です。<br />
            また、従業員数が6〜20人の場合には、600万円までの補助率が3/4になるため（本事業の従業員数は10人）、これらをもとに補助金額を計算すると以下のとおりです。<br />
            <br />
            （補助率　2/3）2,400,000×2/3＝1,600,000<br />
            （従業員数による補助率　3/4）6,000,000×3/4＝4,500,000<br />
            <br />
            申請金額8,400,000円に対し補助金額は最大で6,100,000円となります。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.虎の巻から生活関連事業者の特徴</h2>
          </div>
          <p className="media_text_text">
            <br />
            「虎の巻」とは、経済産業省が公表している事業再構築補助金に関する資料のことで、<br />
            ・事業検討の考え方<br />
            ・有望な事業に共通する特徴<br />
            など、事業計画から申請に役立つ情報がわかりやすくまとめられています。<br />
            <br />
            これから事業再構築補助金を申請する経営者の方には、ぜひご一読いただきたい資料のひとつです。<br />
            <br />
            本記事においても虎の巻をもとに、<br />
            １.生活関連事業者の申請傾向<br />
            ２.具体的な採択事例<br />
            ３.生活関連事業の申請で抑えるべきポイント<br />
            について解説します。<br />
          </p>
          <h3 className='media_text_text_title'>１.生活関連事業者の申請傾向</h3>
          <p className="media_text_text">
            <img src={Image1} className='media_img' alt='' />
            上図は横軸が採択率、縦軸が申請件数となっており、右上のオレンジ部分に行くほど有望度の高い事業ということになります。<br />
            <br />
            生活関連事業における有望度の高い事業には、<br />
            ①教室、研修（採択例：趣味や習い事のオンラインスクール）<br />
            ②クリーニング、洗濯代行（採択例：衛生面を考慮した非接触型のクリーニングのデリバリーサービス事業）<br />
            ③葬儀サービス（採択例：核家族化やコロナ禍によるニーズの変化に対応した小規模葬儀への転換）<br />
            などが挙げられます。<br />
            <br />
            生活関連事業の特徴として、密集を避けた小規模でのサービス提供や非接触型サービスへの切り替えが申請件数、採択率ともに高い傾向にあることが上図から見て取れます。<br />
          </p>
          <h3 className='media_text_text_title'>具体的な採択事例</h3>
          <p className="media_text_text">
            <strong>①理容室から完全個室の美容サロンへの転換</strong><br />
            理容師不足と、男性の美容室利用者数の増加など、ニーズの変化に対応するため美容サロンへ事業転換した事例です。<br />
            ニーズの変化への対応に加え、コロナ禍でも安心・安全に施術を受けてもらえるよう完全個室型にするなど、衛生面にも配慮。<br />
            <br />
            一時期に比べて新型コロナウイルスは収束しつつあるとはいえ、まだまだ不安を抱える方は多く、密集を避けた完全個室の需要は高くなっています。<br />
            事業再構築補助金の生活関連事業において採択率も高いため、事業計画の候補のひとつとして検討してみるのもおすすめです。<br />
            <br />
            <strong>②新たな顧客獲得に向けた衣類クリーニングの新サービスの提供</strong><br />
            新型コロナウイルスの影響による外出自粛やテレワークの普及に伴い、売上が減少。<br />
            新たな顧客獲得に向けてオンラインクリーニングと無人受付サービスにチャレンジするとして採択された事例です。<br />
            <br />
            生活関連事業者の多くが対面でのサービス提供を主としており、事業再構築補助金では、非対面サービスの提供へチャレンジする事業者が多く見受けられます。<br />
            <br />
            従来のサービスの販売方法を変えることで、新たな販路拡大が期待でき、既存のノウハウを活用できるため、事業の見通しが立ちやすい点でメリットがあり、事業再構築補助金においても採択率が高い傾向にあります。<br />
          </p>
          <h3 className='media_text_text_title'>生活関連事業の申請で抑えるべきポイント</h3>
          <p className="media_text_text">
            <strong>①綿密な事業計画を立てる</strong><br />
            事業再構築補助金で採択されるためには、綿密な事業計画を立てる必要があります。<br />
            <br />
            ・売上見込みの算出根拠を分析して明確にする<br />
            ・自社の強みや市場ニーズをしっかり分析し具体化する<br />
            ・競合他社との比較から、自社の優位性を明確にできるようにする<br />
            ・課題リスクと解決方法を具体的に示す<br />
            など、事業計画書の作成においては、各項目を徹底的に調査し、分析を行うことが必要不可欠です。<br />
            <br />
            「補助金がもらえたらいいな」などの安易な気持ちで申請しても採択される確率は非常に低いでしょう。<br />
            申請の際は、綿密な事業計画を立てることが採択されるための重要なポイントとなります。<br />
            <br />
            <strong>②自社のノウハウを活用する</strong><br />
            事業再構築補助金は、「思い切った事業再構築に意欲を有する中小企業等の挑戦を支援することで、日本経済の構造転換を促すこと」を目的としています。<br />
            <br />
            一見すると、未知の分野へチャレンジしなければならないように思いますが、そうではありません。<br />
            <br />
            ポストコロナ・ウィズコロナ時代の環境変化を受けて、｢自社の強みを活かして、どのような新しい価値を生み出せるか｣ということが採択されるための重要なポイントとなります。<br />
            <br />
            生活関連業の場合だと、「フィットネスジムの新規顧客の獲得を目指し、従来の接触型のサービスに加えて、非接触型のオンラインサービスに参入する」などが例として挙げられます。<br />
            <br />
            この場合、サービスの提供方法を変えたものの、自社のノウハウをそのまま活かせるため、実現性も高く、かつ事業の見通しも立てやすいことから採択されやすくなります。<br />
            <br />
            生活関連事業で申請する際は、自社のノウハウを活用することも重要なポイントとなるため、しっかりと計画を立てましょう。<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、生活関連事業における事業再構築補助金の活用方法や、採択事例についてお伝えしました。<br />
            <br />
            これから事業再構築補助金にチャレンジしようと検討中の方はぜひ本記事を参考にしてみてください。<br />
            事業再構築補助金を活用することで、新型コロナウイルスで減少した売上の回復をはじめ、新たな事業へチャレンジできるなど、多くのメリットが得られる可能性が広がります。<br />
            <br />
            とはいえ、事業再構築補助金の申請には、市場調査や綿密な計画の策定など、膨大な時間と労力を要します。<br />
            そのため、「補助金を活用したいけれど、申請準備で仕事に影響を及ぼしたくない」「採択率を上げて申請したい」という方は、専門家のサポートを受けるのもひとつの方法です。<br />
            <br />
            本記事を運営するトランク合同会社では、事業再構築補助金の採択実績を豊富に有しており、事業者様の負担を最小限に押さえてサポートすることが可能です。<br />
            <br />
            初回無料相談を実施しておりますので、事業再構築補助金に興味をお持ちの方は、下記フォームよりお気軽にお問い合わせください。<br />
            <br />
            この度は、記事を拝見いただき誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


