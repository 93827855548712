import DocumentMeta from 'react-document-meta';
import './styles.css';
import Nav from './nav/index';
import Footer from './../tabs/footer/index';

const Contact = () => {

  const meta = {
    title: '会社概要|トランク合同会社',
    canonical: 'https://trankllc.com',
    meta: {charset: 'utf-8', name: "robots", content: "noindex"}
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <div className='company_header'>
        <h1>About us</h1>
        <h2>会社概要</h2>
      </div>
      <div className='company_content'>
        <div>
          <h3>会社名</h3>
          <h4>トランク合同会社</h4>
        </div>
        <div>
          <h3>会社設立日</h3>
          <h4>2019年5月25日</h4>
        </div>
        <div>
          <h3>URL</h3>
          <h4>https://trankllc.com</h4>
        </div>
        <div>
          <h3>代表社員</h3>
          <h4>大林　亜治英</h4>
        </div>
        <div>
          <h3>事業内容</h3>
          <h4>ITサービス、補助金申請サービス</h4>
        </div>
        <div>
          <h3>郵便番号</h3>
          <h4>〒231-0005</h4>
        </div>
        <div>
          <h3>住所</h3>
          <h4 style={{margin: '0'}}>神奈川県横浜市中区本町４丁目４１ Ｄ’グラフォート横浜 405</h4>
        </div>
        <div>
          <h3>資本金</h3>
          <h4 style={{margin: '0'}}>1,000,000</h4>
        </div>
        <div>
          <h3>営業時間</h3>
          <h4>10:00~18:00</h4>
        </div>
      </div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default Contact;
