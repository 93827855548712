import './styles.css';
import simulatorIcon from './../../../../../image/simulator/simulator_icon_white.png';

const SimulatorResult = () => {

  return(
  <>
  <div className='simulator_result_type_title'>
    <img src={simulatorIcon} alt='' />
    <h4>必要書類一覧</h4>
  </div>
  <div className='simulator_result_type_desc'>
    <div>
      <div>
        <h5>事業計画書</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104'}} className='simulator_result_type_desc_none'>全員</h4>
    </div>
    <div>
      <div>
        <h5>認定経営革新等支援機関・金融機関による確認書</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104'}} className='simulator_result_type_desc_none'>全員</h4>
    </div>
    <div>
      <div>
        <h5>直近決算書2年分</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104'}} className='simulator_result_type_desc_none'>全員</h4>
    </div>
    <div>
      <div>
        <h5>ミラサポplus「電子申請サポート」の事業財務情報</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104'}} className='simulator_result_type_desc_none'>全員</h4>
    </div>
    <div>
      <div>
        <h5>従業員数を示す書類</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104'}} className='simulator_result_type_desc_none'>全員</h4>
    </div>
    <div>
      <div>
        <h5>建物の新築が必要であることを説明する書類</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104'}} className='simulator_result_type_desc_none'>全員</h4>
    </div>
    <div>
      <div>
        <h5>市場拡大要件を満たすことを説明する書類</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104'}} className='simulator_result_type_desc_none'>成長枠</h4>
    </div>
    <div>
      <div>
        <h5>給与総額増加要件を満たすことを説明する書類</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104', width : '190px', }} className='simulator_result_type_desc_none'>成長枠、グリーン成長枠</h4>
    </div>
    <div>
      <div>
        <h5>市場縮小要件を満たすことを説明する書類</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104'}} className='simulator_result_type_desc_none'>産業構造転換枠</h4>
    </div>
    <div>
      <div>
        <h5>売上減少を示す書類</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104', width : '250px',}} className='simulator_result_type_desc_none'>物価高騰対策・回復再生応援枠</h4>
    </div>
    <div>
      <div>
        <h5>研究開発・技術開発計画書又は人材育成計画書</h5>
      </div>
      <h4 style={{backgroundColor : 'white', color : '#EC6104'}} className='simulator_result_type_desc_none'>グリーン成長枠</h4>
    </div>
  </div>

  </>
  );
}

export default SimulatorResult;
