// https://www.kkj.go.jp/doc/ja/api_guide.pdf
// https://www.kkj.go.jp/api/?Query=%E3%82%BB%E3%83%9F%E3%83%8A%E3%83%BC

import './styles.css';
import DocumentMeta from 'react-document-meta';
import Nav from './../items/nav/index';
import Slider from './slider/index';
import Search from './search/index';
import Strong from './strong/index';
import QA from './QA/index';
import Footer from './../items/footer/index';
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import Image from './../../../../../image/bid/strong/2.png';

const BidHome = () => {

  const [ list, setList ] = useState([]);

  const meta = {
    title: '入札情報の窓口 | 完全無料の入札情報まとめサイト',
    description: 'この記事は、国の案件をお探しの方へ向けて、国、地方等の入札情報をまとめているサイトです。掲載されている入札情報は、全部で数千件を超えており、日本でも有数の公官需専門サイトです。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };


  useEffect(() => {
    firebase.firestore().collection("bid")
    .orderBy("Date", 'asc').limit(10)
    .get().then(snapShot => {
      var tmp = [];
      snapShot.forEach((doc) => {
        tmp.push(doc.data());
      });
      setList(tmp);
    });
  }, []);

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <div className='bid_header_slider_postion'>
        <Slider className='bid_header_slider' />
        <a href='https://trankllc.com/help'>
          <div className='bid_header_slider_postion_ad'></div>
        </a>
      </div>
      <Search />
      <div className='search_speace'></div>
      <div id='1' className='bid_strong_title'><span>01</span><p>'入札情報の窓口' の魅力</p></div>
      <Strong />
      <div id='2' className='bid_strong_title' style={{marginBottom : '60px'}}><span>02</span><p>本日の案件情報</p></div>
      {list.map((data, index) =>
        <a href={`http://trankllc.com/bid/article/id=${data.Key.slice(10, data.Key.length - 4)}`}>
          <div className='bid_list_case' key={index}>
            <img src={Image} alt='' />
            <div>
              <p className='bid_list_case_date'>{data.OpeningTendersEvent === '' ? '公告取得日時：-' : `公告取得日時：${data.OpeningTendersEvent.slice(0, 4)}年${data.OpeningTendersEvent.slice(5, 7)}月${data.OpeningTendersEvent.slice(8, 10)}日`}</p>
              <h3>{data.ProjectName}</h3>
              <div>
                <p>
                  {data.PrefectureName === '' ? '' : `場所：${data.PrefectureName}　`}
                  {data.OrganizationName === '' ? '' : `募集機関：${data.OrganizationName}　`}
                  {data.Certification === '' ? '' : `入札資格：${data.Certification}　`}
                  {data.CftIssueDate === '' ? '' : `公告日：${data.CftIssueDate.slice(0, 4)}年${data.CftIssueDate.slice(5, 7)}月${data.CftIssueDate.slice(8, 10)}日　`}
                  {data.Category === '' ? '' : `カテゴリー：${data.Category}　`}
                  {data.ProcedureType === '' ? '' : `入札形式：${data.ProcedureType}　`}
                  {data.PeriodEndTime === '' ? '' : `納入期日：${data.PeriodEndTime}　`}
                  {data.Location === '' ? '' : `履行場所：${data.Location}　`}
                </p>
              </div>
            </div>
            <h4>＞</h4>
          </div>
        </a>
      )}
      <div id='3' className='bid_strong_title'><span>03</span><p>'入札情報の窓口'に関する質問</p></div>
      <QA />
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BidHome;
