import './styles.css';

const Footer = () => {
  
  return (
    <>
    <div className='test_homeimage_mvvc'>
      <div className='test_homeimage_mvvc_left'></div>
      <div className='test_homeimage_mvvc_right'>
        <div>
          <h4>哲学①：ニッチ分野の発展をサポート</h4>
          <p>ニッチ分野のプロフェッショナルたちが最新の技術や情報を活用して、より多くの顧客に満足を提供できるようサポートすることに注力しています。</p>
        </div>
        <div>
          <h4>哲学②：第一にクライアントの成功</h4>
          <p>クライアントが成功すれば、私たちも成功するというシンプルな信念のもと、日々の業務を行っており、クライアントのニーズや課題を深く理解し、最適なソリューションを提供することに全力を尽くしています。</p>
        </div>
        <div>
          <h4>哲学③：デジタル時代のリーダーシップ</h4>
          <p>デジタル技術の進化で、さまざま業界に多くの変革を起こせると考えています。最新のテクノロジーを活用して、業界の新しいスタンダードを築くことで、美容業界の未来を形成する役割を果たすことを志しています。</p>
        </div>
      </div>
    </div>
    </>
  );
}

export default Footer;
