import './styles.css';
import Point1BackGround from "./../../../../image/hojokin/point1_background.png";
import Point2BackGround from "./../../../../image/hojokin/point2_background.png";
import Point3BackGround from "./../../../../image/hojokin/point3_background.png";
import Point4BackGround from "./../../../../image/hojokin/point4_background.png";
import Point5BackGround from "./../../../../image/hojokin/point5_background.png";
import Point6BackGround from "./../../../../image/hojokin/point6_background.png";

const Help = () => {

  return (
    <>
    <div className='hojokin_point'>
      <div className='hojokin_point_title'>
        <div>
          <h5>当社が選ばれる理由</h5>
          <div></div>
        </div>
        <p>当社が選ばれる理由は、<strong>「高いクオリティー」「業界平均以下の価格」「最速納品」</strong>がありますが、その他に、当社では担当者が1事業者様に1名つくという体制を整えており、<strong>事業者様と担当者が一緒に採択を目指す</strong>という体制を整えている点も当社の魅力の1つです。</p>
      </div>
      <div className='hojokin_point_box'>
        <div>
          <h4>最適な補助金選定</h4>
          <p>当社では経済産業省関連の補助金を取り扱っているため、事業者さまに合った補助金をご提案させていただきます。</p>
          <img src={Point1BackGround} alt='' />
        </div>
        <div>
          <h4>こだわりの事業計画書</h4>
          <p>当社は補助金の採択における重要な要素である事業計画書を当社の経験とデータを元に策定し、責任をもって、採択までサポートいたします。</p>
          <img src={Point2BackGround} alt='' />
        </div>
        <div>
          <h4>幅広い業種経験</h4>
          <p>当社では幅広い業種・業態での申請経験があるため、業種・業態に合わせた計画書の策定についても対応しております。</p>
          <img src={Point3BackGround} alt='' />
        </div>
        <div>
          <h4>最小限の負担</h4>
          <p>当社では事業者さまの事業運営にご負担がないように必要最低限の部分以外は全て弊社にて行わせていただきます。</p>
          <img src={Point4BackGround} alt='' />
        </div>
        <div>
          <h4>事業者さまに1名の担当者</h4>
          <p>当社では情報の齟齬や業務スピードの低下を抑えるため、事業者さまに1名の担当者を付けて対応させていただいております。</p>
          <img src={Point5BackGround} alt='' />
        </div>
        <div>
          <h4>補助金受け取りまでの対応</h4>
          <p>当社では事業者さまが補助金の申請から受け取りまでを安心して行えるよう、申請から受け取りまでを一括してサポートしております。</p>
          <img src={Point6BackGround} alt='' />
        </div>
      </div>
    </div>
    </>
  );
}

export default Help;
