import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './styles.css';

const simulator = (props) => {

  return(
  <>
  <div className='simulator_header'>
    <h2>事業再構築補助金シミュレーター</h2>
    <h3>申請枠・交付申請金額・対象経費を3分で診断</h3>
    <p>複雑な事業再構築補助金の申請枠や最大の補助金額を、6個の質問に回答するだけで簡単に診断することが出来ます。<br />初回相談は完全無料で行っておりますので、ご不明点はお気軽にお問合せ下さい。</p>
    <div className='simulator_header_btn'>
      <AnchorLink href="#1" offset="0">
        <button className='simulator_header_btn1'>診断開始</button>
      </AnchorLink>
      <Link to='/contact'>
        <button className='simulator_header_btn2'>問い合わせ</button>
      </Link>
    </div>
  </div>
  </>
  );
}

export default simulator;
