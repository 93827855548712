import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "./../../nav/index.js";
import Topic from "./../topic";
import TopImage from "./../../../../../image/media/business/3/top.png";
import Footer from './../../../../tabs/footer/index.js';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」12個の分類を具体例つきで解説！',
    description: '本記事では、補助対象となる経費を公募要領と見比べながら解説しております。具体例や注意点などに関する詳しい記載もございます。「どこまでの経費が対象になるか知りたい」方や「この経費が計上できるか分からない」という方に向けて、執筆させていただきました。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」12個の経費分類を具体例つきで解説！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            今回は、<strong>日本最大級の補助金である「事業再構築補助金」</strong>について解説させていただきます。<br />
            <br />
            本記事は、「<strong>どこまでの経費が対象になるか知りたい</strong>」方や「<strong>この経費が計上できるか分からない</strong>」という方に向けて、執筆させていただきました。<br />
            <br />
            事業再構築補助金の対象経費について知りたい方は、ぜひ最後まで読んで見てください！<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業再構築補助金の対象経費</span></li>
              <li><span>対象経費の具体例</span></li>
              <li><span>経費申請に係る注意点</span></li>
            </ul>
          </div>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">対象経費の概要</AnchorLink></li>
                <li><AnchorLink href="#2" offset="100">建物費</AnchorLink></li>
                <li><AnchorLink href="#3" offset="100">機械装置・システム構築費</AnchorLink></li>
                <li><AnchorLink href="#4" offset="100">技術導入費</AnchorLink></li>
                <li><AnchorLink href="#5" offset="100">専門家経費</AnchorLink></li>
                <li><AnchorLink href="#6" offset="100">運搬費</AnchorLink></li>
                <li><AnchorLink href="#7" offset="100">クラウドサービス</AnchorLink></li>
                <li><AnchorLink href="#8" offset="100">外注費</AnchorLink></li>
                <li><AnchorLink href="#9" offset="100">知的財産権等関連経費</AnchorLink></li>
                <li><AnchorLink href="#10" offset="100">広告宣伝・販売促進費</AnchorLink></li>
                <li><AnchorLink href="#11" offset="100">研修費</AnchorLink></li>
                <li><AnchorLink href="#12" offset="100">経費全体に係る注意点</AnchorLink></li>
                <li><AnchorLink href="#13" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>１.対象経費の概要</h2>
          </div>
          <p className="media_text_text">
            <br />
            対象経費の項目を確認していく前に、確認いただきたい事項が３点ございますので、ご説明させていただきます。<br />
            <br />
            ① <strong>補助金額は、交付決定で確定する。</strong><br />
            補助金は、申請後に事務局に投資金額を審査され、そこで、許可が降りた金額が補助金額となりますので、採択決定により金額が確定するわけではございません。<br />
            <br />
            ② <strong>補助事業期間に用いる経費のみ、補助対象となる。</strong><br />
            事業再構築補助金では、補助事業期間に用いる経費が対象となります。そのため、補助金の採択前に使用した経費や、補助事業期間以降の経費は補助対象外となります。<br />
            <br />
            事前着手制度という制度を申請することで、採択前に補助事業を実施することができますので、詳しく知りたい方は、弊社当の補助金コンサルタントにご確認ください。<br />
            <br />
            ③ <strong>補助事業に必要不可欠な経費のみが、補助対象となる。</strong><br />
            補助金は、企業ではなく「事業」を対象として支払われるものとなっていますので、申請した「事業」を行うために必要不可欠な経費のみが補助対象となります。<br />
            <br />
            申請した事業以外の経費や、必要不可欠でないとみなされる経費は交付申請時に認可されませんので、ご確認の上で申請を行ってください。<br />
          </p>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>対象経費の確認事項３点</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>補助金額は、交付決定で確定する。</span></li>
              <li><span>補助事業期間に用いる経費のみ、補助対象となる。</span></li>
              <li><span>補助事業に必要不可欠な経費のみが、補助対象となる。</span></li>
            </ul>
          </div>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>２.建物費</h2>
          </div>
          <p className="media_text_text">
            <br />
            <strong>補助事業のみに使用する建物に関しての経費となります</strong>。<br />
            <br />
            なお、ここでいう建物の定義は「減価償却資産の耐用年数等に関する省令」中の「建物」と「建物付属設備」となっており、構築物は対象外となりますのでご注意ください。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>公募要領</span><br />
            ①専ら補助事業のために使用される事務所、生産施設、加工施設、販 売施設、検査施設、共同作業場、倉庫その他事業計画の実施に不可 欠と認められる建物の建設・改修に要する経費 ②補助事業実施のために必要となる建物の撤去に要する経費 ③補助事業実施のために必要となる賃貸物件等の原状回復に要する経 費 ➃貸工場・貸店舗等に一時的に移転する際に要する経費（貸工場・貸 店舗等の賃借料、貸工場・貸店舗等への移転費等）<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            公募要領内の説明を簡潔にまとめると、以下の4点が建物費として認められる内容になります。
            <br />
            ① <strong>補助事業のために使用される事務所や施設、倉庫等の建設・改修に係る経費（内装費を含む）</strong><br />
            ② <strong>補助事業のために必要となる、既存建物の設備等に係る撤去費用</strong><br />
            ③ <strong>補助事業のために行う、現在使用している賃貸物件の原状回復に要する経費</strong><br />
            ④ <strong>貸工場・店舗等に一時移転を行う際に係る経費（一時移転先の賃借料・移転費等）</strong><br />
            <br />
            そのため、建設・改修を行う場合は補助事業の実施に必要不可欠でなくてはならず、既存建物の単なる購入や賃貸は対象外となりますのでご注意ください。<br />
            <br />
            また、建物費を計上する場合は、複数の業者様からの入札・相見積もりが必要となっております。<br />
          </p>
          <h3 className='media_text_text_title'>注意ポイント！</h3><br />
          <p className="media_text_text">
            ・ <strong>②、③といった、撤去や回復だけの経費のみでは対象となりません。あくまで事業拡大につながる資産への投資が対象となります。</strong><br />
            ・ <strong>一時移転に係る経費は、補助対象経費総額の1/2以内が上限となっています。</strong><br />
            ・ <strong>新築建物に係る経費は「誠に必要不可欠であり、代替手段が存在しない」場合のみ認められます。申請する際には、別途説明書類の記載が必要となります。</strong><br />
            <br />
          </p>
          <h3 className='media_text_text_title'>具体例</h3><br />
          <p className="media_text_text">
            ・<strong>建設業者が、プラスチック加工製品の製造を始める際の工場建設費</strong><br />
            ・<strong>衣服販売店が、健康・美容関連商品販売店への展開を行うための改装費用</strong><br />
            ・<strong>宿泊業を営んでいたホテルがコワーキングスペースを開始する際の撤去・改装費用</strong><br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/cases/zentai.pdf' target="_blank" rel="nofollow noopener noreferrer">
              参考記事：https://jigyou-saikouchiku.go.jp/pdf/cases/zentai.pdf
            </a>
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>３.機械装置・システム構築費</h2>
          </div>
          <p className="media_text_text">
            <br />
            <strong>補助事業に使用される設備・ソフトウェアに係る経費となります</strong>。<br />
            <br />
            なお、設備の定義は「減価償却資産の耐用年数等に関する省令」中の「機械及び装置」、「器具及び備品」、「工具」となっており、船舶や航空機、車両及び運搬具は対象外となります。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>公募要領</span><br />
            ①専ら補助事業のために使用される機械装置、工具・器具（測定工 具・検査工具等）の購入、製作、借用に要する経費 ②専ら補助事業のために使用される専用ソフトウェア・情報システム 等の購入・構築、借用に要する経費 ③①又は②と一体で行う、改良・修繕、据付け又は運搬に要する経費<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a><br />
          </div>
          <p className="media_text_text">
            <br />
            公募要領内の説明を簡潔にまとめると、以下の3点が機械装置・システム構築費として認められる内容になります。<br />
            <br />
            <strong>① 補助事業のために使用される機械装置や工具・器具の購入や製作、借用に係る経費</strong><br />
            <strong>② 補助事業のために使用される専用のソフトウェアや情報システムの購入・構築、借用に係る経費</strong><br />
            <strong>③ ①、②と共に行う改良・修繕、据付や運搬に係る経費</strong><br />
            <br />
            なお、上記の製作に使用する部品購入費も対象となりますので、開発を行う場合は部品費用も踏まえて対象経費を算出することが必要になります。<br />
            <br />
            また、自社開発のみならず、外注先に機械装置・システム構築を依頼した際の委託費用も「外注費」ではなく「機械装置・システム構築費」としての計上になります。<br />
          </p>
          <h3 className='media_text_text_title'>注意ポイント！</h3><br />
          <p className="media_text_text">
            ・  単価50万円以上の機械装置・システムを購入する際には、事業計画書に名称、分類、取得予定価格を記載する必要がありますので、早めに見積書の取得を行いましょう。<br />
            ・  借用とはリース・レンタルを指し、補助事業実施期間の使用料のみが経費対象となります。<br />
            ・  中古品の購入を行う際は、型式や年式が記載された3社以上の相見積もり書が必要となります。<br />
            <br />
          </p>
          <h3 className='media_text_text_title'>具体例</h3><br />
          <p className="media_text_text">
            ・車載製品を製造していた企業が、新たに医療分野製品を製造する際の製造機器費用<br />
            ・宿泊施設を営んでいた業者がキャンプ場施設を新たに経営する際の予約管理システム構築費用<br />
            ・アパレルショップを経営していた事業者がネット販売を開始する際のECサイト・注文管理システムの構築費<br />
            参考記事：<a href='https://jigyou-saikouchiku.go.jp/pdf/cases/zentai.pdf' target="_blank" rel="nofollow noopener noreferrer">
              https://jigyou-saikouchiku.go.jp/pdf/cases/zentai.pdf
            </a><br />
          </p>
          </>

          <>
          <div id='4' className="media_text_title">
            <h2>４.技術導入費</h2>
          </div>
          <p className="media_text_text">
            <br />
            <strong>補助事業を行うために他者から知的財産権等を導入する必要がある際、その導入に係る経費となります</strong>。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>公募要領</span><br />
            ①専ら補助事業のために使用される機械装置、工具・器具（測定工 具・検査工具等）の購入、製作、借用に要する経費 ②専ら補助事業のために使用される専用ソフトウェア・情報システム 等の購入・構築、借用に要する経費 ③①又は②と一体で行う、改良・修繕、据付け又は運搬に要する経費<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a><br />
          </div>
          <p className="media_text_text">
            <br />
            なお、主な知的財産権は特許権、意匠権、著作権、商標権、や実用新案権などがあげられます。<br />
          </p>
          <h3 className='media_text_text_title'>注意ポイント！</h3><br />
          <p className="media_text_text">
            ・知的財産権を他者から取得する際には、書面による契約の締結があげられます。<br />
            ・技術導入費を払う相手に、専門家経費・外注費を追加で支払うことはできません。<br />
          </p>
          <h3 className='media_text_text_title'>具体例</h3><br />
          <p className="media_text_text">
            ・非鉄業者が新たに鉄加工品を製造する際に、他者の特許権を使用するライセンス料金<br />
            ・美容院が新たに他メーカーの物販事業を始める際の、商標権ライセンスを取得する際の費用<br />
            参考記事：<a href='https://jigyou-saikouchiku.go.jp/pdf/cases/zentai.pdf' target="_blank" rel="nofollow noopener noreferrer">
              https://jigyou-saikouchiku.go.jp/pdf/cases/zentai.pdf
            </a><br />
          </p>
          </>

          <>
          <div id='5' className="media_text_title">
            <h2>5.専門家経費</h2>
          </div>
          <p className="media_text_text">
            <br />
            <strong>補助事業を行うために専門家に技術指導やアドバイスを依頼する必要がある場合、依頼費や旅費に係る経費となります</strong>。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>公募要領</span><br />
            本事業遂行のために依頼した専門家に支払われる経費<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a><br />
          </div>
          <p className="media_text_text">
            <br />
            なお、本補助事業に関る専門家の依頼費用は以下のように定められております。<br />
            <br />
            ・大学教授、弁護士、弁理士、公認会計士、医師等：1日5万円以下<br />
            ・准教授、技術士、中小企業診断士、ITコーディネータ等：1日4万円以下<br />
          </p>
          <h3 className='media_text_text_title'>注意ポイント！</h3><br />
          <p className="media_text_text">
            ・補助金申請時の認定支援機関や、事業計画書の作成依頼者に対する費用は対象外となります。<br />
            ・専門家経費を払う相手に、技術導入費・外注費を追加で払うことはできません。<br />
          </p>
          </>

          <>
          <div id='6' className="media_text_title">
            <h2>6.運搬費</h2>
          </div>
          <p className="media_text_text">
            <br />
            <strong>補助事業を遂行するために必要な、材料や設備等の運搬に係る経費となります</strong>。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>公募要領</span><br />
            運搬料、宅配・郵送料等に要する経費<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a><br />
          </div>
          <br />
          <h3 className='media_text_text_title'>注意ポイント！</h3><br />
          <p className="media_text_text">
            ・購入する機械装置の運搬料については、機械装置・システム構築費に含まれます。<br />
          </p>
          <h3 className='media_text_text_title'>具体例</h3><br />
          <p className="media_text_text">
            ・自社開発装置の材料費にかかる運搬費用<br />
            ・製品の耐久性を専門家に調査してもらう際の、製品配送費<br />
          </p>
          </>

          <>
          <div id='7' className="media_text_title">
            <h2>7.クラウドサービス</h2>
          </div>
          <p className="media_text_text">
            <br />
            <strong>補助事業に利用するクラウドサービス・webプラットフォーム等に係る経費となります</strong>。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>公募要領</span><br />
            クラウドサービスの利用に関する経費<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a><br />
          </div>
          <p className="media_text_text">
            <br />
            なお、計上できる具体的な経費項目は、主に以下の3点に当てはまるものとなっています。<br />
            <br />
            ① <strong>サーバーの領域を借りる費用（サーバーの物理的なディスク内のエリアを借入する費用）</strong><br />
            ② <strong>サーバー上のサービスを利用する費用</strong><br />
            ③ <strong>クラウドサービス利用に付帯する経費（ルータ使用料、プロバイダ契約料、通信料等）</strong><br />
            <br />
            なお、クラウドサービス費として計上できる利用費は補助事業のみで使用されたものであり、自社の他事業と共有している場合は対象となりませんので、ご注意ください。<br />
          </p>
          <br />
          <h3 className='media_text_text_title'>注意ポイント！</h3><br />
          <p className="media_text_text">
            ・サーバー購入費や、サーバー自体のレンタル費等は補助対象になりませんので、ご注意ください。<br />
            ・補助事業実施期間中の経費のみが対象となりますので、ご確認ください。なお、契約期間が補助事業実施期間を超える場合、按分等の方式で算出する必要があります。<br />
            ・③にパソコンやタブレットといった、本体費用は含まれませんのでご注意ください。<br />
          </p>
          <h3 className='media_text_text_title'>具体例</h3><br />
          <p className="media_text_text">
            ・宿泊施設を営んでいた業者がキャンプ場施設を新たに経営する際の、外部予約管理システムにかかる利用費<br />
            ・アパレルショップを経営していた事業者がネット販売を開始する際の、ECサイト・注文管理システムにかかるAWS費<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/cases/zentai.pdf' target="_blank" rel="nofollow noopener noreferrer">
              参考記事：https://jigyou-saikouchiku.go.jp/pdf/cases/zentai.pdf
            </a>
          </p>
          </>

          <>
          <div id='8' className="media_text_title">
            <h2>8.外注費</h2>
          </div>
          <p className="media_text_text">
            <br />
            <strong>補助事業遂行のために、外部に一部業務を委託する経費となります</strong>。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>公募要領</span><br />
            本事業遂行のために必要な加工や設計（デザイン）・検査等の一部を外注（請負、委託等）する場合の経費<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a><br />
          </div>
          <p className="media_text_text">
            <br />
            なお、外注先とは書面による契約の締結が必要ですので、外注費を計上予定の場合はお早めにご準備ください。<br />
          </p>
          <h3 className='media_text_text_title'>注意ポイント！</h3><br />
          <p className="media_text_text">
            ・機械装置等の制作を外注する場合は、「機械装置・システム構築費」での計上となります。<br />
            ・外注費を払う相手に、専門家経費・技術導入費を追加で払うことはできません。<br />
            ・販売・レンタルするための量産品の加工を外注する費用は、対象になりませんのでご注意ください。<br />
          </p>
          <h3 className='media_text_text_title'>具体例</h3><br />
          <p className="media_text_text">
            ・航空機部品業者が新たに半導体関連部品を製造する際の、部品検査を外注した際の費用<br />
            ・イベント運営会社が、バーチャル上でライブなどを見ることのできる新サービス開始時の、サービスのUI設計を外注した時の費用<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/cases/zentai.pdf' target="_blank" rel="nofollow noopener noreferrer">
              参考記事：https://jigyou-saikouchiku.go.jp/pdf/cases/zentai.pdf
            </a>
          </p>
          </>

          <>
          <div id='9' className="media_text_title">
            <h2>９.知的財産権等関連経費</h2>
          </div>
          <p className="media_text_text">
            <br />
            <strong>補助事業で新製品・サービスを開始する際に必要となる、特許権をはじめとした知的財産権を取得するために係る経費となります</strong>。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>公募要領</span><br />
            新製品・サービスの開発成果の事業化にあたり必要となる特許権等の知的財産権等の取得に要する弁理士の手続代行費用や外国特許出願のための翻訳料など知的財産権等取得に関連する経費<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a>
          </div>
          <p className="media_text_text">
            <br />
            なお、補助事業実施期間内に出願手続きを完了していない場合、補助対象にはなりませんので、お早めに出願までの段取りを行いましょう。<br />
          </p>
          <h3 className='media_text_text_title'>注意ポイント！</h3><br />
          <p className="media_text_text">
            ・日本の特許庁に納品する経費（出願料、審査請求料、特許料等）は対象になりませんので、ご注意ください。<br />
            ・拒絶査定に対する審判請求料や、訴訟を行う際にかかる経費は対象になりませんのでご注意ください。<br />
          </p>
          <h3 className='media_text_text_title'>具体例</h3><br />
          <p className="media_text_text">
            ・新製品の特許を取得する際に、弁護士に依頼した代行費用<br />
            ・食品メーカーがISO認証を行う際の審査出願料等<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/cases/zentai.pdf' target="_blank" rel="nofollow noopener noreferrer">
              参考記事：https://jigyou-saikouchiku.go.jp/pdf/cases/zentai.pdf
            </a>
          </p>
          </>

          <>
          <div id='10' className="media_text_title">
            <h2>10.広告宣伝・販売促進費</h2>
          </div>
          <p className="media_text_text">
            <br />
            <strong>補助事業で新製品・サービスを販売する際に行う、広告を含めたプロモーション活動に係る経費となります</strong>。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>公募要領</span><br />
            本事業で開発又は提供する製品・サービスに係る広告（パンフレット、動画、写真等）の作成及び媒体掲載、展示会出展（海外展示会を含む）、セミナー開催、市場調査、営業代行利用、マーケティングツール 活用等に係る経費<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a><br />
          </div>
          <p className="media_text_text">
            <br />
            大きく分けて以下の3点が広告宣伝・販売促進費として認められる内容となります。<br />
            <br />
            ① <strong>チラシの配布やGoogle広告への掲載など、広告宣伝に係るものの作成・掲載費</strong><br />
            ② <strong>展示会やセミナーといった、対面型広告活動の参加・開催費</strong><br />
            ③ <strong>営業代行やマーケティングツールといった、他業者を用いた宣伝活動を行った際の費用</strong><br />
          </p>
          <h3 className='media_text_text_title'>注意ポイント！</h3><br />
          <p className="media_text_text">
            ・補助事業以外の製品やサービス、会社全体のPRに係る広告活動は対象外となりますので、セミナー等を開催する際は特にご注意ください。<br />
            ・補助事業実施期間内に広告活動を行うことが補助対象になる条件ですので、スケジュール調整にはご注意ください。<br />
          </p>
          <h3 className='media_text_text_title'>具体例</h3><br />
          <p className="media_text_text">
            ・建設業者が新たにプラスチック加工製品を製造した際の、チラシによるPR活動に用いたチラシ作成費用<br />
            ・輸送業者が新たに飲食店を開始した際の、地域パンフレット掲載費用<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/cases/zentai.pdf' target="_blank" rel="nofollow noopener noreferrer">
              参考記事：https://jigyou-saikouchiku.go.jp/pdf/cases/zentai.pdf
            </a>
          </p>
          </>

          <>
          <div id='11' className="media_text_title">
            <h2>11.研修費</h2>
          </div>
          <p className="media_text_text">
            <br />
            <strong>補助事業を遂行するために必須となる、社員に向けた教育訓練や講座受講等に係る経費となります</strong>。<br />
          </p>
          <div className='media_text_text_box'>
            <span className='media_text_text_box_title'>公募要領</span><br />
            本事業の遂行のために必要な教育訓練や講座受講等に係る経費<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo007.pdf' target="_blank" rel="nofollow noopener noreferrer">
              <span className='media_text_text_box_site'>引用：事業再構築 公募要領|中小企業庁</span>
            </a><br />
          </div>
          <p className="media_text_text">
            <br />
            研修費を計上する際には、事業計画書内に以下5点の記載を行うことが必要となります。<br />
            <strong>① 研修名</strong><br />
            <strong>② 研修実施主体</strong><br />
            <strong>③ 研修内容</strong><br />
            <strong>④ 研修受講費</strong><br />
            <strong>⑤ 研修受講者についての情報</strong><br />
            <br />
            なお、研修費は上限額が補助対象経費総額の1/3となっております。全体に占める研修費の割合が1/3を超える場合は、超える部分が補助対象外となりますのでご注意ください。<br />
          </p>
          <h3 className='media_text_text_title'>注意ポイント！</h3><br />
          <p className="media_text_text">
            ・研修受講費以外の経費（入学金、交通費、滞在費等）は補助対象外になりますので、ご注意ください。<br />
          </p>
          <h3 className='media_text_text_title'>具体例</h3><br />
          <p className="media_text_text">
            ・建設業者が新たに壁面工事を開始する際の、同業者から行う研修費用
            ・飲食店が新たにFC加入する際の、FC研修費用
          </p>
          </>

          <>
          <div id='12' className="media_text_title">
            <h2>12.対象経費に係る注意点</h2>
          </div>
          <p className="media_text_text">
            <br />
            本項目では、<strong>補助額を滞りなく受け取るために必要となる、手続きや提出書類をまとめてみました</strong>ので、最後にご確認ください！<br />
            <br />
            <strong>① 補助事業の経費は、交付決定を受けた日付以降に契約を行い、補助事業実施期間内の銀行振込によって支払いを完了したものが対象となります</strong>。<br />
            （事前着手制度により申請したものを除く）<br />
            <br />
            <strong>② 補助事業に係る経費は、算出段階ではすべて税抜で算出を行ってください</strong>。<br />
            <br />
            <strong>③ 対象経費の支払先とは、書面による契約の締結を行ってください</strong>。<br />
            →交付申請以降で必要となりますので、こちらの書面は大切に保管してください。<br />
            <br />
            <strong>④ 契約先1件当たりの見積額合計が50万円以上になる場合、原則として、同一条件による相見積もり書を取得してください。</strong><br />
            ※見積もり書が取得できない場合は、契約先を選定した理由書・価格の妥当性を示す書類の2書類を用意する必要があります。<br />
            <br />
            <strong>⑤ 資産性のない経費のみの計上や、1つの経費区分だけに大半の経費を計上している場合は、「補助対象経費理由書」という書類の提出が必要となります</strong>。<br />
            例）広告宣伝費や専門家経費など、会社の資産として残らない分野に大多数の経費が偏っている場合など。<br />
          </p>
          </>

          <>
          <div id='13' className="media_text_title">
            <h2>１３.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            本記事では、事業再構築補助金を申請したい事業者さまへ向けて、対象経費についてご説明させていただきました。<br />
            <br />
            少し難易度が高いと感じた方もいると思いますが、ご質問等をお問い合わせよりお受けしておりますので、詰まってしまった際は、ぜひご相談いただければと思います。<br />
            <br />
            この度は、記事を拝見してくださり、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;