// 小売事業
// https://docs.google.com/document/d/1kMFdU4D2VUtUrFAGQ8Ude7gE8Q2k6k9NtBeja22Z338/edit

import DocumentMeta from 'react-document-meta';
import './../../styles.css';
import Nav from "../../nav/index";
import Topic from "../topic";
import TopImage from "./../../../../../image/media/business/43/top.png";
import Image1 from "./../../../../../image/media/business/43/1.png";
import Footer from '../../../../tabs/footer/index';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"

const BlogText0001 = () => {

  const meta = {
    title: '「事業再構築補助金」小売事業の採択事例を大公開！',
    description: '本記事では、事業再構築補助金の飲食業 採択事例を紹介しております。飲食業を営んでいて事業再構築補助金の申請を検討されている方は、本記事をご参考に検討を行っていただければと思います。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      {/* <Recommend /> */}

      <div className='media'>
        <div className='media_article'>
          <p className='media_date'>2022/07/21 更新日: 2023/7/25</p>
          <h1 className="media_title">「事業再構築補助金」小売事業の採択事例を大公開！</h1>
          <img src={TopImage} className="media_title_img" alt='補助金' />

          <>
          <p className="media_text_text">
            <br />
            <br />
            対面販売がメインの小売事業において、新型コロナの影響による客足減少は大きな痛手だったのではないでしょうか。<br />
            <br />
            そのため、落ち込んだ売上をなんとか回復しようと、事業再構築補助金を検討している方も多いかと思います。<br />
            <br />
            本記事では、こうした小売事業者がかかえる事業再構築補助金に関する疑問について解説します。<br />
          </p>
          </>

          <>
          <div className='media_introduction'>
            <div>
              <AiFillCheckCircle className='media_introduction_icon' />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>事業再構築補助金の対象となる小売業者</span></li>
              <li><span>小売業における事業再構築補助金の活用方法</span></li>
              <li><span>過去の小売事業者採択例</span></li>
            </ul>
          </div>
          </>

          <>
          <p className="media_text_text">
            本記事は、最新の「第10回事業再構築補助金　公募要領」を元に作成しています。<br />
            <a href='https://jigyou-saikouchiku.go.jp/pdf/koubo.pdf' target="_blank" rel="nofollow noopener noreferrer">
              リンク：事業再構築補助金 公募要領
            </a><br />
            <br />
            また、当社では無料相談も行っております。<br />
            事業再構築補助金について詳細な話しを聞いてみたいという方はお気軽にお問い合わせください。<br />
          </p>
          </>

          <>
          <div className='media_contents'>
            <div className='media_contents_title'>
              本記事の目次
            </div>
            <div className='media_contents_contents'>
              <ol>
                <li><AnchorLink href="#1" offset="100">小売事業における活用方法</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金の対象となる経費</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">小売事業での事業再構築類型の具体例</AnchorLink></li>
                  <li><AnchorLink href="#1" offset="100">事業再構築補助金での申請例</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#2" offset="100">虎の巻から見る小売事業者の特徴</AnchorLink></li>
                <ul>
                  <li><AnchorLink href="#2" offset="100">o	小売事業者の申請傾向</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">o	具体的な採択事例</AnchorLink></li>
                  <li><AnchorLink href="#2" offset="100">o	小売事業の申請で抑えるべきポイント</AnchorLink></li>
                </ul>
                <li><AnchorLink href="#3" offset="100">まとめ</AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className="media_text_title">
            <h2>1.小売事業における活用方法</h2>
          </div>
          <p className="media_text_text">
            <br />
            小売事業における事業再構築補助金の活用方法について、<br />
            <strong>1.事業再構築補助金の対象となる経費</strong><br />
            <strong>2.小売事業での事業再構築類型の具体例</strong><br />
            <strong>3.事業再構築補助金での申請例</strong><br />
            これら3つの項目に分けて解説していきます。<br />
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金の対象となる経費</h3><br />
          <p className="media_text_text">
            第10回公募要領によると、事業再構築補助金における対象経費は以下の11項目となります。<br />
            <br />
            ①建物費②機械装置・システム構築費③技術導入費④専門家経費⑤運搬費⑥クラウドサービス利用費⑦外注費⑧知的財産権等関連経費⑨広告宣伝・販売促進費⑩研修費⑪廃業費<br />
            <br />
            これら11項目の中でも、とくに小売事業に関係の深い経費は以下の通り。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>経費項目</th><th>詳細</th><th>具体例</th>
              </tr>
              <tr>
                <td>建物費</td>
                <td>事業で使用する事務所・施設の建設や改修にかかる経費</td>
                <td>・在庫保管用の倉庫建設費<br />・店舗の改装費</td>
              </tr>
              <tr>
                <td>機械装置・システム費</td>
                <td>事業で使用する機械装置や新たに開発した設備・システムの開発経費</td>
                <td>・ECサイト構築費用<br />・業務改善ツールの導入費</td>
              </tr>
              <tr>
                <td>外注費</td>
                <td>事業に必要な加工・設計等を外部に委託する経費</td>
                <td>・製品開発に要する加工費<br />・設計費</td>
              </tr>
              <tr>
                <td>広告宣伝・販売促進費</td>
                <td>事業に必要な加工・設計等を外部に委託する経費</td>
                <td>・インターネット広告費<br />・店舗ロゴの作成費</td>
              </tr>
              <tr>
                <td>研修費</td>
                <td>事業の教育訓練・講座受講に係る経費</td>
                <td>・従業員の教育費</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            小売事業の場合、既存の対面販売を維持しつつECサイトを構築して販路を拡大するケースが多く見受けられます。<br />
            <br />
            そのため、小売事業で申請される方はECサイト構築費をはじめ、それに伴う広告宣伝費などを経費として活用するケースが多いのが特徴です。<br />
            <br />
            その他には、<br />
            ・ネット販売用の商品を保管する倉庫の建設費（建物費）<br />
            ・接客の非対面化に向けたセルフレジの導入費（機械装置・システム構築費）<br />
            なども小売事業の経費として活用できます。<br />
            <br />
            【対象外経費に注意】<br />
            事業再構築補助金の経費は無制限に認められるわけではないため、注意が必要です。<br />
            <br />
            たとえば、不動産（土地・建物）の購入費や公道を走行する自動車、汎用品となり得るスマホやパソコン、タブレット、家具など。<br />
            <br />
            一見、補助対象経費に含まれそうですが、事業拡大につながる資産への投資とは判断されません。<br />
            <br />
            この他にも、対象外経費は数多くありますので、事前にしっかりと確認しておくようにしましょう。<br />
            <br />
            当社では複雑な対象経費の確認や金額算出を初回相談無料で行っておりますので、詳しく知りたい方はお気軽にお問い合わせください。<br />
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金での申請例</h3>
          <p className="media_text_text">
            事業再構築補助金の申請は、公募要領に定められている要件を満たさなければなりません。<br />
            <br />
            申請要件を満たすには、以下3つの類型のいずれか1つに当てはまっている必要があります。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th>類型を満たすための要件</th><th>産業分類に基づく変更</th>
              </tr>
              <tr>
                <td>新市場進出</td>
                <td>
                  ①製品又は提供方法を大きく変更する<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全体の10%以上となる計画を策定する。
                </td>
                <td>無</td>
              </tr>
              <tr>
                <td>事業転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3〜5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。<br />
                </td>
                <td>主な事業を変更(中・小・細分類)</td>
              </tr>
              <tr>
                <td>業種転換</td>
                <td>
                  ①過去に製造等を行った経験がない<br />
                  ②新たな市場への進出を行う<br />
                  ③3～5年の事業計画期間終了後に、新製品の売上高が全売上高で最も大きくなる計画を策定する。
                </td>
                <td>主な業種を変更<br />(大分類)</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            <br />
            <strong>【小売事業での具体例】</strong><br />
            上記3つの類型（新市場進出・事業転換・業種転換）をわかりやすく小売事業に当てはめてみましょう。<br />
            <br />
            以下の具体例をご覧ください。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>類型</th><th>類型を満たすための要件</th><th>産業分類に基づく変更</th>
              </tr>
              <tr>
                <td>新市場進出</td>
                <td>583：食肉小売業→583：食肉小売業</td>
                <td>精肉店が事業拡大のためECサイトを構築し通信販売を開始</td>
              </tr>
              <tr>
                <td>事業転換</td>
                <td>571：呉服・服地・寝具小売業→573：婦人・子供服小売業</td>
                <td>宿泊業から創作料理の技術と立地を活かした小売事業への事業転換</td>
              </tr>
              <tr>
                <td>業種転換</td>
                <td>J：卸売業・小売業→M：宿泊業・飲食サービス業</td>
                <td>鮮魚店が人脈を活かして仕入れた新鮮な魚を提供する居酒屋へ転換</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            これまでのノウハウを活かした事業展開からまったく新しい分野への展開など、事業再構築補助金は小売事業においても幅広く活用できます。
          </p>
          <h3 className='media_text_text_title'>事業再構築補助金での申請例</h3>
          <p className="media_text_text">
            次は、事業再構築補助金の採択事例をもとに、対象経費や補助金額について具体例を用いて解説します。<br />
            <br />
            なお、補助金額は事業規模や従業員数、申請枠によって違いがあります。<br />
            <br />
            そのため、申請の際は下記申請例を参考に、ご自身の事業に該当する申請枠や補助金額、補助率を入念に確認してから望むようにしましょう。<br />
            <br />
            【事業内容】<br />
            <br />
            これまで対面販売を中心としてきたが、コロナ禍により客足が減少。<br />
            新たな販路を開拓するため、自社ECサイトを構築しオーダーメイド家具のオンライン販売体制を整え、収益の改善を見込む。<br />
            <br />
            ●	事業場所：山梨県甲府市<br />
            ●	従業員数：35人<br />
            ●	売上高：10億円<br />
            <br />
            【日本産業分類から類型を決定】<br />
            日本産業分類は下表のとおり。<br />
            既存事業と新規事業で変わらないため、本事業の再構築類型は「新市場進出」として申請を進めます。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'>日本産業分類</th><th>大分類</th><th>中分類</th><th>小分類</th><th>細分類</th>
              </tr>
              <tr>
                <td>既存事業</td><td>I：卸売業，小売業</td><td>60：その他の小売業</td><td>601：家具・建具・畳小売業</td><td>6011：家具小売業</td>
              </tr>
              <tr>
                <td>新規事業</td><td>I：卸売業，小売業</td><td>60：その他の小売業</td><td>601：家具・建具・畳小売業</td><td>6011：家具小売業</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            【売上高から申請枠を決定】<br />
            下表の月別売上高をご覧ください。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th className='media_table_0001_1_1'></th><th>6月</th><th>7月</th><th>8月</th><th>合計</th>
              </tr>
              <tr>
                <td>2021年</td><td>10,500</td><td>9,500</td><td>10,000</td><td>30,000</td>
              </tr>
              <tr>
                <td>2022年</td><td>8,300</td><td>8,000</td><td>8,300</td><td>24,600</td>
              </tr>
              <tr>
                <td>増減率(%)</td><td>-21%</td><td>-16%</td><td>-17%</td><td>-17%</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            2022年の6月〜8月の売上高合計を2021年の同月と比較すると17％減少していることから、物価高騰対策・回復再生応援枠の要件を満たしていることがわかります。<br />
            そのため、本事業は「物価高騰対策・回復再生応援枠」にて申請します。<br />
            <br />
            【補助金額の計算】<br />
            それでは、補助金額の計算をしていきましょう。<br />
            まずは、本事業にかかる経費について。<br />
          </p>
          <table className='media_table'>
            <tbody>
              <tr>
                <th>プレハブ倉庫の建築費（在庫保管用）</th><th>￥7,500,000</th>
              </tr>
              <tr>
                <td>ECサイト構築費</td><td>￥3,000,000</td>
              </tr>
              <tr>
                <td>広告費</td><td>￥2,200,000</td>
              </tr>
            </tbody>
          </table>
          <p className="media_text_text">
            上記経費はすべて補助対象となりますので、本事業の申請金額は合計12,200,000円になります。<br />
            <br />
            補助率については、第10回公募では中小企業は2/3となります。<br />
            また、従業員21〜50人の場合は800万円までが3/4になるため（本事業の従業員数は35人）、これらをもとに計算すると以下の通り。<br />
            <br />
            （補助率　2/3）4,200,000×2/3＝2,800,000<br />
            （従業員数による補助率　3/4）8,000,000×3/4＝6,000,000<br />
            <br />
            補助金額の最大は8,800,000円となります。<br />
          </p>
          </>

          <>
          <div id='2' className="media_text_title">
            <h2>2.虎の巻から見る小売事業者の特徴</h2>
          </div>
          <p className="media_text_text">
            <br />
            虎の巻とは、経済産業省より事業再構築補助金の申請数や採択率のデータをまとめた「事業再構築虎の巻」（https://jigyou-saikouchiku.go.jp/pdf/jigyokeikaku_guidebook.pdf）のことです。<br />
            <br />
            虎の巻には、業種ごとの採択傾向データや採択事例、計画の立て方について詳細に記載されており、申請を検討する際、非常に役立つ資料のひとつです。<br />
            <br />
            「事業計画の立て方を知りたい」「計画中の事業がどれくらい採用されているのか知りたい」<br />
            このような疑問をお持ちの方は、ぜひ一読することをおすすめします。<br />
            <br />
            本記事においても虎の巻をもとに、<br />
            1.	小売事業者の申請傾向<br />
            2.	具体的な採択事例<br />
            3.	小売事業の申請で抑えるべきポイント<br />
            について解説します。<br />
          </p>
          <h3 className='media_text_text_title'>小売事業者の申請傾向</h3>
          <p className="media_text_text">
            <img src={Image1} className='media_img' alt='' />
            小売事業者の申請傾向は上図の通りです。<br />
            表の縦軸が申請件数、横軸が採択率ですので、右上のオレンジ部分が有望度の高い事業ということになります。<br />
            <br />
            小売事業の場合、EC関連や中食、酒類などが有望度の高い事業に分類されています。<br />
            とくにEC関連は申請件数が最も多く採択率も高めで、事業再構築補助金における小売事業の代表的な事業と言えるでしょう。<br />
            <br />
            「有望度中」の中で目立つのが、高齢者向け事業です。<br />
            <br />
            採択事例には、「高齢者向けの給食事業」などがあり、主に高齢者の衣食住や日常生活を支えるサービスを提供するのが特徴です。<br />
            申請件数はそれほど多くないものの、採択率は最も高く、狙い目の分野と言えるでしょう。<br />
          </p>
          <h3 className='media_text_text_title'>具体的な採択事例</h3>
          <p className="media_text_text">
            小売事業における事業再構築補助金の採択事例を紹介します。<br />
            <br />
            <strong>①鮮魚店が食品ロス削減のために独自の「かまぼこ」を開発しSDGsに取り組むという事例</strong><br />
            <br />
            地域密着の鮮魚店が、廃棄となっていた魚の切れ端を活用し、独自のかまぼこを開発した事例です。<br />
            <br />
            廃棄になる材料を活用することでコストをかけずに売上げアップを狙う事業だと考えることができます。<br />
            <br />
            また、廃棄物を減らすことによるSDGsの実現にも期待できる事業といえるでしょう。<br />
            <br />
            このような食品加工・販売は、事業再構築補助金の小売事業分野において「有望度中」に分類されています。<br />
            <br />
            申請件数こそ多くないものの採択率は非常に高く、食品関連の小売事業者にとっては展開しやすい分野でもあるため、検討してみる価値があるのではないでしょうか。<br />
            <br />
            <strong>②中古車販売からレンタカー事業の業種転換を行った事例</strong><br />
            <br />
            若者の車離れに加えてコロナ禍の影響もあり、自動車の販売台数は年々減少傾向にあります。<br />
            一方で、レンタカー市場は右肩上がり。<br />
            レンタカー台数は20年前と比べると3倍、ここ10年でも1.7倍と好調です。<br />
            車は購入費・維持費の負担が大きく、「買うもの」ではなく「借りるもの」という方向へシフトしていき、需要が拡大したのでしょう。<br />
            <br />
            時代のニーズにマッチしたレンタカー事業は、売上のV字回復が期待できるため申請でのアピールポイントとなります。<br />
            <br />
            このほかに、自動車関連では、リースや損害保険等の自動車サービス、メンテナンスサービスなどの採択事例があり、小売事業では有望度の高い分野に該当します。<br />
          </p>
          <h3 className='media_text_text_title'>宿泊事業の申請で抑えるべきポイント</h3>
          <p className="media_text_text">
            <strong>・アフターコロナへの対応</strong><br />
            宿泊業では、コロナを機に団体客から個人客へと顧客層の変化が見られます。<br />
            そのため、コロナ終息後のインバウンドの回復を見据えた対応が申請において重要なポイントとなるでしょう。<br />
            <br />
            団体客メインのサービスから高単価の個人・インバウンド向けに転換するなどのアフターコロナに対応した計画書の作成を心がけましょう。<br />
            <br />
            <strong>・差別化の実現</strong><br />
            宿泊業では、飲食関連への展開も採択率が高くなっています。<br />
            <br />
            とはいえ、差別化が図れていない事業計画では不採択になるケースも多いため、注意が必要です。<br />
            採択に近づくためには、地元企業との提携で地域の名産品の活用・提供し、差別化と地域振興を実現していくなどの計画を記載することが重要になるでしょう。<br />
          </p>
          </>

          <>
          <div id='3' className="media_text_title">
            <h2>3.まとめ</h2>
          </div>
          <p className="media_text_text">
            <br />
            事業再構築補助金を活用した小売事業者はEC関連事業を筆頭に様々な事業へチャレンジしています。<br />
            <br />
            入念な事業計画を立てて補助金を活用することで、経営のV字回復にも期待できるでしょう。<br />
            <br />
            とはいえ、事業再構築補助金は申請要件が難しいうえに計画書の作成も一筋縄ではいかず、申請するだけでも一苦労です。<br />
            <br />
            本記事を運営している会社であるトランク合同会社は、小売事業者様の事業再構築補助金採択例を豊富に有しており、各事業者様の特徴を前面に押し出した申請サポートを行っています。<br />
            <br />
            「新事業に集中したいから手続きにはできるだけ負担をかけたくない」「採択率を上げたいので専門家にサポートしてほしい」という方は是非ご相談ください。<br />
            <br />
            この度は、記事を拝見いただき誠にありがとうございました。<br />
          </p>
          </>
          
        </div>

        <Topic />
      </div>

      <div className='media_'></div>
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;


