const BlogText0001 = (props) => {

  return(
  <>
  <div className='management_review_content_1'>
    <h4>自社の状況に関する質問（1問）</h4>
    <h5>Q9.防災活動への貢献の状況</h5>
    <select onChange={(e) => props.setDisasterAgreement(e.target.value)}>
      <option value="">選択</option>
      <option value='はい'>はい</option>
      <option value='いいえ'>いいえ</option>
    </select>
  </div>
  </>
  );
}

export default BlogText0001;
